import React from 'react'
import styled from 'styled-components'
import th from '../../utils/themeHelper'

const InfoFieldContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  align-content: baseline;
  margin: 0.5em 0;
  font-family: ${props => (props.isItalic ? th('fonts.italic') : th('fonts.regular'))};
  font-size: 15px;
  word-wrap: ${props => (props.breakAnywhere ? 'anywhere' : 'break-word')};
`

const IconContainer = styled.i`
  line-height: 18px;

  & > svg {
    min-width: 20px;
    width: 20px;
    height: 20px;
    color: ${th('primary.base')};
    margin-right: 8px;
  }
`

const InfoField = ({ breakAnywhere, isItalic, icon: Icon, children }) => {
  return (
    <InfoFieldContainer breakAnywhere={breakAnywhere} isItalic={isItalic}>
      <IconContainer>
        <Icon />
      </IconContainer>
      {children}
    </InfoFieldContainer>
  )
}

export default InfoField
