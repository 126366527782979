import React from 'react'
import DayPickerInput from 'react-day-picker/DayPickerInput'

// library styles
import 'react-day-picker/lib/style.css'

// styles
import { Wrapper } from './styles'
import { Error, Label, RequiredTag, SubLabel } from '../InputLabels/InputLabels'

const DatePicker = ({
  disableAfterToday,
  disableBeforeToday,
  disabled,
  field = {},
  form,
  label,
  requiredField,
  subLabel,
  ...props
}) => {
  const errors = form.errors || {}
  const displayableLabel = requiredField ? `${label} *` : label
  const hasError = !!errors[field.name]

  const today = new Date()
  const dayPickerProps = disableBeforeToday
    ? { disabledDays: { before: today } }
    : disableAfterToday
    ? { disabledDays: { after: today } }
    : {}

  return (
    <Wrapper disabled={disabled} hasError={hasError} requiredField={requiredField}>
      {label && !hasError && <Label htmlFor={field.name}>{displayableLabel}</Label>}
      {subLabel && <SubLabel subLabel={subLabel}>{subLabel}</SubLabel>}

      <RequiredTag hasError={hasError} label={label} requiredField={requiredField} />

      {errors[field.name] && <Error>{errors[field.name]}</Error>}
      <DayPickerInput
        {...field}
        {...props}
        dayPickerProps={dayPickerProps}
        inputProps={{ disabled }}
        onDayChange={day => {
          field.onChange(field.name, day)
          form.setFieldValue(field.name, day)
        }}
      />
    </Wrapper>
  )
}

export default DatePicker
