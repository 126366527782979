import { call, put, takeLatest } from 'redux-saga/effects'

// redux
import * as notificationsModule from '../modules/notifications'
import * as notificationsApi from '../apiModules/notifications'

function* fetchNotifications() {
  try {
    const response = yield call(notificationsApi.fetchNotifications)
    yield put(notificationsModule.fetchNotificationsSuccess(response))
  } catch (err) {
    yield put(notificationsModule.fetchNotificationsFailure(err))
  }
}

function* dismissNotification({ payload }) {
  try {
    yield call(notificationsApi.dismissNotification, payload)
    yield put(notificationsModule.dismissNotificationSuccess(payload))
  } catch (err) {
    yield put(notificationsModule.dismissNotificationFailure(err))
  }
}

export const notificationsSagas = [
  takeLatest(notificationsModule.DISMISS_NOTIFICATION, dismissNotification),
  takeLatest(notificationsModule.FETCH_NOTIFICATIONS, fetchNotifications)
]
