import React from 'react'
import { withTheme } from 'styled-components'
import { Link } from '@reach/router'
import styled from 'styled-components'
import th from 'utils/themeHelper'
import l from 'config/localization'
import webPImage from '../../utils/webPImage'
import spacing from 'utils/spacing'
import { Calendar, Location } from '../Icons/Icons'
import InfoField from '../shared/InfoField'

const Card = styled.div`
  background: white;
  padding: 30px;
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
  border: 1px solid ${th('borders.default')};
  border-right-width: 0;
  flex: 2;
  color: ${th('text.dark')};

  @media screen and (min-width: 769px) {
    min-width: 300px;
  }

  @media screen and (max-width: 769px) {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;
  }

  & + & {
    margin-top: ${spacing.default}px;
  }
`

const Title = styled.h4`
  margin: 0;
`

const DetailsSection = styled.div`
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: space-between;
  margin-top: ${spacing.default}px;
  margin-bottom: ${spacing.default}px;

  @media screen and (max-width: 769px) {
    flex-direction: column;
    margin-bottom: 5px;
  }
`

const Details = styled.div`
  width: 70%;
`

const CardImage = styled.div`
  border-bottom: 1px solid ${th('borders.default')};
  border-right: 1px solid ${th('borders.default')};
  border-top: 1px solid ${th('borders.default')};
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
  background-image: url(${({ src }) => src});
  background-size: cover;
  background-position: center center;
  flex: 1 0 130px;

  @media screen and (max-width: 769px) {
    min-height: 200px;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
`

const LinkWrapper = styled(Link)`
  margin-bottom: 2em;
  display: flex;
  position: relative;
  width: 100%;

  @media screen and (max-width: 769px) {
    flex-direction: column-reverse;
  }
`

const OpportunityCard = ({
  imgSrc,
  title,
  start_date,
  start_time,
  end_time,
  location,
  id,
  shortUrl,
  isPvtCampaign,
  isPvtOpp,
  shareKey,
  doesSupportWebP
}) => {
  const times = `${start_time} - ${end_time}`
  const displayTime = start_date ? `${start_date} at ${times}` : 'Anytime'
  const imageUrl = doesSupportWebP ? webPImage(imgSrc) : imgSrc

  let oppLink = `/${l('ROUTE_OPPS')}/${id}?title=${shortUrl}`
  if (isPvtCampaign && isPvtOpp) {
    oppLink += `&pvtShareKey=${shareKey}`
  }

  return (
    <LinkWrapper to={oppLink}>
      <Card>
        <Title>{title}</Title>

        <DetailsSection>
          <Details>
            <InfoField icon={Calendar}>{displayTime}</InfoField>
            <InfoField icon={Location}>{location ? location : 'Anywhere'}</InfoField>
          </Details>
        </DetailsSection>
      </Card>
      <CardImage src={imageUrl} />
    </LinkWrapper>
  )
}

OpportunityCard.defaultProps = {
  title: 'Cleanup',
  location: '101 Church Street, Claremont, Cape Town, South Africa',
  hostName: 'RMB',
  date: '15 July at 15:00 - 16:00',
  attending: 25,
  id: 1
}

export default withTheme(OpportunityCard)
