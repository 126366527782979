import React from 'react'
import Linkify from 'react-linkify'
import styled from 'styled-components'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  font-size: ${props => (props.bigFont ? '18px' : '15px')};
  line-height: 1.35em;
`

// Force all user generated content that is linkified to use
// nofollow so that SEO spammers don't find incentive to
// abuse this as a link farm.
const properties = {
  rel: 'nofollow'
}

const Description = ({ children, bigFont }) => {
  return (
    <Linkify properties={properties}>
      <Wrapper bigFont={bigFont}>{children || ''}</Wrapper>
    </Linkify>
  )
}

export default Description
