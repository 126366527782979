import { takeLatest, call, put } from 'redux-saga/effects'

// redux
import * as feedbackApi from '../apiModules/feedback'
import * as feedbackModule from '../modules/feedback'
import * as authModule from '../modules/authentication'

// utils
import isLoggedIn from '../../utils/auth'

function* fetchHostCheckInInfo({ payload }) {
  try {
    const response = yield call(feedbackApi.fetchHostCheckInInfo, payload)
    yield put(feedbackModule.fetchHostCheckInInfoSuccess(response))
    if (!isLoggedIn()) {
      yield put(authModule.attemptAutoLogin({ signInToken: payload.autologin }))
    }
  } catch (err) {
    yield put(feedbackModule.fetchHostCheckInInfoFailure(err.response.data))
  }
}

function* hostFeedback({ payload }) {
  try {
    const { feedback } = payload
    const formDataVals = new FormData()

    Object.keys(feedback).map(value => {
      if (value === 'photos') {
        for (let i = 0; i < feedback[value].length; i++) {
          formDataVals.append(`${value}_${i}`, feedback[value][i])
        }
      } else if (feedback[value]) {
        formDataVals.append(value, feedback[value])
      }
    })

    const response = yield call(feedbackApi.hostFeedback, formDataVals)
    yield put(feedbackModule.hostFeedbackSuccess(response))
  } catch (err) {
    yield put(feedbackModule.hostFeedbackFailure(err))
  }
}

function* fetchApplicationInfo({ payload }) {
  try {
    const response = yield call(feedbackApi.fetchApplicationInfo, payload)
    yield put(feedbackModule.fetchApplicationInfoSuccess(response))
    if (!isLoggedIn()) {
      yield put(authModule.attemptAutoLogin({ signInToken: payload.autologin }))
    }
  } catch (err) {
    yield put(feedbackModule.fetchApplicationInfoFailure(err))
  }
}

function* volunteerFeedback({ payload }) {
  try {
    const formDataVals = new FormData()
    const { feedback } = payload

    Object.keys(feedback).map(value => {
      if (value === 'photos' && feedback[value] && feedback[value].length > 0) {
        for (let i = 0; i < feedback[value].length; i++) {
          formDataVals.append(`${value}_${i}`, feedback[value][i])
        }
      } else if (value && feedback[value]) {
        formDataVals.append(value, feedback[value])
      }
    })

    const response = yield call(feedbackApi.volunteerFeedback, formDataVals)
    yield put(feedbackModule.volunteerFeedbackSuccess(response))
  } catch (err) {
    yield put(feedbackModule.volunteerFeedbackFailure(err))
  }
}

export const feedbackSagas = [
  takeLatest(feedbackModule.FETCH_HOST_CHECK_IN_INFO, fetchHostCheckInInfo),
  takeLatest(feedbackModule.HOST_FEEDBACK, hostFeedback),
  takeLatest(feedbackModule.FETCH_APPLICATION_INFO, fetchApplicationInfo),
  takeLatest(feedbackModule.VOLUNTEER_FEEDBACK, volunteerFeedback)
]
