import darken from 'polished/lib/color/darken'
import styled from 'styled-components'
import th from 'utils/themeHelper'

export const ButtonBase = styled.button`
  outline: none;
  background: ${props => (props.disabled ? th('backgrounds.mid') : th('secondary.base'))};
  font-size: 12px;
  font-family: ${th('fonts.bold')};
  color: white;
  display: block;
  border-radius: 4px;
  padding: ${th('spacing.tiny', 'px')} ${th('spacing.default', 'px')};
  border-width: 1px;
  border-style: solid;
  border-color: ${props => (props.disabled ? th('backgrounds.mid') : th('secondary.base'))};
  cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};
  width: ${props => (props.wide ? '100%' : 'auto')};
  height: 35px;
  transition: 0.3s background 0.3s border;

  &:hover,
  &:focus {
    background: ${({ theme, disabled }) =>
      disabled ? th('backgrounds.mid') : darken(0.05, theme.secondary.base)};
    border-color: ${props => (props.disabled ? th('backgrounds.mid') : th('secondary.base'))};
    transition: 0.3s background 0.3s border;
  }
`
