import styled from 'styled-components'
import th from 'utils/themeHelper'

export const Wrapper = styled.div`
  max-width: 500px;

  & .DayPickerInput {
    display: block;
  }

  & input {
    appearance: none;
    outline: none;
    border-radius: 3px;
    border-style: solid;
    border-width: ${({ hasError, requiredField }) => (hasError || requiredField ? '2px' : '1px')};
    border-color: ${({ disabled, hasError, theme, requiredField }) =>
      disabled
        ? theme.borders.medium
        : hasError
        ? theme.borders.error
        : requiredField
        ? theme.borders.required
        : theme.borders.default};
    color: ${th('text.dark')};
    background: ${props => (props.disabled ? th('backgrounds.light') : 'white')};
    font-size: 15px;
    font-family: ${th('fonts.light')};
    width: ${props => (props.disabled ? '150px' : '100%')};
    height: 45px;
    padding: 15px;

    &:hover {
      border-color: ${({ disabled, hasError, theme }) =>
        disabled ? theme.borders.medium : hasError ? theme.borders.error : theme.borders.dark};
    }

    &:focus {
      border-color: ${({ hasError, theme }) =>
        hasError ? theme.borders.error : theme.secondary.base};
    }

    &::placeholder {
      color: ${th('text.light')};
    }
  }
`
