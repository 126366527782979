import React, { Fragment } from 'react'

const ConditionalDisplay = ({ displayWhen, children, fallback }) => {
  const allTrue = displayWhen.every(v => !!v)
  if (!allTrue && !fallback) return null

  if (!allTrue && fallback) return fallback

  return <Fragment>{children}</Fragment>
}

export default ConditionalDisplay
