import React, { Fragment } from 'react'
import { navigate, Redirect } from '@reach/router'
import { connect } from 'react-redux'

// utils and config

import constants from 'config/constants'
import { getLocalStorageValues } from 'utils/localStorageManager'
import isLoggedIn from 'utils/auth'
import logos from 'utils/logos'
import l, { c } from 'config/localization'

// redux
import * as authModule from '../../redux/modules/authentication'

// assets
import userIcon from 'assets/images/user.svg'

// styles
import {
  MainNavWrapper,
  MainNav,
  DashboardNavLeft,
  NavLeft,
  NavRight,
  NavCallToAction,
  NavLink,
  FlatNavLink,
  DashNavLink,
  DashNavLinkColorWrapper,
  DashNavLinkWrapper,
  LogoLink,
  SmallLogo,
  DashboardLogo,
  FullLogo,
  SearchIcon,
  NavOutterWrapper,
  NavInnerWrapper,
  AccountMenuContainer,
  AvatarWrapper,
  Avatar,
  ArrowDownIcon,
  ArrowUpIcon,
  Username,
  AccountMenu,
  MenuNavLink,
  Clickable
} from './styles'

// Load components synchronously
import ConditionalDisplay from 'components/ConditionalDisplay/ConditionalDisplay'
import Link from 'components/Link'
import CookieHeader from 'components/CookieHeader'
import FlashMessage from 'components/FlashMessage'
import LargeButton from 'components/LargeButton/LargeButton'

const { smallLogoPath, smallAltLogoPath, fullAltLogoPath } = logos
const isPricingPage = window.location.pathname.match('/pricing')

const HeaderDropdownArea = ({
  displayName,
  fallbackIcon,
  hasAvatar,
  headerAvatar,
  isOpen,
  toggleHeaderDropdown
}) => {
  return (
    <Clickable onClick={toggleHeaderDropdown}>
      <AvatarWrapper>
        <Avatar src={headerAvatar || fallbackIcon} hasAvatar={hasAvatar} alt="User avatar" />
      </AvatarWrapper>

      <Username>{displayName}</Username>

      {isOpen ? <ArrowUpIcon /> : <ArrowDownIcon />}
    </Clickable>
  )
}

class Header extends React.Component {
  constructor(props) {
    super(props)

    this.state = { isOpen: false }
    this.setWrapperRef = this.setWrapperRef.bind(this)
    this.handleClickOutside = this.handleClickOutside.bind(this)
    this.toggleHeaderDropdown = this.toggleHeaderDropdown.bind(this)
  }

  setWrapperRef(node) {
    this.wrapperRef = node
  }

  handleClickOutside(event) {
    if (this.state.isOpen && this.wrapperRef && !this.wrapperRef.contains(event.target)) {
      this.setState(state => ({ ...state, isOpen: false }))
    }
  }

  componentDidMount() {
    document.addEventListener('click', this.handleClickOutside)

    if (isLoggedIn()) {
      this.props.fetchMyRoles()
    }
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.handleClickOutside)
  }

  toggleHeaderDropdown() {
    this.setState(state => ({ ...state, isOpen: !this.state.isOpen }))
  }

  render() {
    const { isOpen } = this.state
    const user = getLocalStorageValues()
    const HeaderNavLink = props => <NavLink {...props} />
    const {
      loggedIn,
      logout,
      dashboard,
      searchPage,
      isLoading,
      roleData = [],
      userData
    } = this.props

    if (userData && userData.on_probation) {
      return <Redirect to={`/probation`} noThrow />
    }

    const { orgDetails = [] } = user
    const hasOrgs = orgDetails && orgDetails.length > 0
    const headerAvatar = user.avatar_url || userIcon
    const hasAvatar = !!user.avatar_url
    const displayName = user.f_name
    const profileLink = `/users/${user.id}`
    const hasGodRole = roleData.some(role => role.role_type === constants.ROLES.GOD_MODE)
    const showOrgOptions = hasGodRole || hasOrgs

    const joinPath = isPricingPage ? `/join${window.location.search}` : '/join'
    const loginPath = isPricingPage ? `/login${window.location.search}` : '/login'

    return (
      <Fragment>
        <MainNavWrapper>
          <MainNav isLoading={isLoading} ref={this.setWrapperRef} isFixed={dashboard}>
            <NavOutterWrapper isFixed={dashboard}>
              <NavInnerWrapper>
                <ConditionalDisplay displayWhen={[dashboard]}>
                  <DashboardNavLeft>
                    <Link to="/">
                      <DashboardLogo
                        isFixed={dashboard}
                        src={smallLogoPath}
                        alt="Brownie Points Logo"
                      />
                    </Link>

                    <DashNavLinkWrapper>
                      <DashNavLinkColorWrapper>
                        <DashNavLink to={`/search/${l('ROUTE_OPPS')}`}>
                          <SearchIcon />
                          Find a {l('OPP')}
                        </DashNavLink>
                      </DashNavLinkColorWrapper>
                    </DashNavLinkWrapper>
                  </DashboardNavLeft>
                </ConditionalDisplay>

                <ConditionalDisplay displayWhen={[!dashboard]}>
                  <LogoLink searchPage={searchPage} href="/">
                    <FullLogo src={fullAltLogoPath} alt="Brownie Points Logo" />
                    <SmallLogo src={smallAltLogoPath} alt="Brownie Points Logo" />
                  </LogoLink>
                </ConditionalDisplay>

                <ConditionalDisplay displayWhen={[!dashboard, !searchPage]}>
                  <NavLeft>
                    <HeaderNavLink href={`/search/${l('ROUTE_OPPS')}`} search>
                      <SearchIcon />
                      Find a {l('OPP')}
                    </HeaderNavLink>
                  </NavLeft>
                </ConditionalDisplay>

                <ConditionalDisplay displayWhen={[searchPage, loggedIn]}>
                  <NavCallToAction>
                    <HeaderNavLink href={`/search/${l('ROUTE_OPPS')}`} search>
                      <SearchIcon />
                      Search {l('OPPS')}
                    </HeaderNavLink>
                  </NavCallToAction>
                </ConditionalDisplay>

                <ConditionalDisplay displayWhen={[loggedIn]}>
                  <AccountMenuContainer>
                    <HeaderDropdownArea
                      toggleHeaderDropdown={this.toggleHeaderDropdown}
                      isOpen={isOpen}
                      headerAvatar={headerAvatar}
                      hasAvatar={hasAvatar}
                      displayName={displayName}
                      fallbackIcon={userIcon}
                    />

                    <ConditionalDisplay displayWhen={[isOpen]}>
                      <AccountMenu>
                        <MenuNavLink href={'/dashboard'}>View Dashboard</MenuNavLink>

                        <ConditionalDisplay displayWhen={[showOrgOptions]}>
                          <MenuNavLink href={`/dashboard/${l('ROUTE_ORGS')}?ot=own`}>
                            My {c(l('ORGS'))}
                          </MenuNavLink>
                        </ConditionalDisplay>

                        <MenuNavLink href={profileLink}>My Profile</MenuNavLink>

                        <MenuNavLink href={`/dashboard/account`}>Account Settings</MenuNavLink>

                        <Clickable InMenu onClick={logout}>
                          Log out
                        </Clickable>
                      </AccountMenu>
                    </ConditionalDisplay>
                  </AccountMenuContainer>
                </ConditionalDisplay>

                <ConditionalDisplay displayWhen={[!loggedIn]}>
                  <NavRight>
                    <FlatNavLink href={'https://blog.browniepoints.africa'}>Blog</FlatNavLink>
                    <FlatNavLink href={'/about-us'}>Our Story</FlatNavLink>
                    <FlatNavLink href={'/pricing'}>Pricing</FlatNavLink>

                    <FlatNavLink padright href={loginPath}>
                      Log in
                    </FlatNavLink>

                    <LargeButton onClick={() => navigate(joinPath)}>Join</LargeButton>
                  </NavRight>
                </ConditionalDisplay>
              </NavInnerWrapper>
            </NavOutterWrapper>
          </MainNav>

          <CookieHeader />
          <FlashMessage />
        </MainNavWrapper>
      </Fragment>
    )
  }
}

const mapStateToProps = state => ({
  isLoading: state.authentication.isLoading,
  errorMessage: state.authentication.errorMessage,
  userData: state.authentication.userData
})

const mapDispatchToProps = dispatch => ({
  fetchMyRoles: () => dispatch(authModule.fetchMyRoles())
})

export default connect(mapStateToProps, mapDispatchToProps)(Header)
