import React from 'react'
import styled from 'styled-components'
import { Link } from '@reach/router'

// utils
import th from 'utils/themeHelper'

// icons
import { Location, LocationOff } from '../Icons/Icons'

// components
import LinkButton from '../SmallButtonPrimary/LinkButton'
import InfoField from '../shared/InfoField'

const Card = styled.div`
  background: white;
  padding: 30px;
  border-radius: 3px;
  border: 1px solid ${th('borders.default')};
  margin-bottom: 10px;
`

const Heading = styled.h4`
  margin: 0;
`

const Actions = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`

const ActionLink = styled(Link)`
  text-decoration: none;
  margin-left: 15px;

  &:first-child {
    margin-left: 0;
  }

  @media screen and (max-width: 769px) {
    margin-left: 0;
    margin-bottom: 12px;
  }
`

const Info = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 10px 0;

  @media screen and (max-width: 769px) {
    flex-direction: column;
  }
`

const InfoGroup = styled.div`
  flex: ${props => props.flex || 1};
`

const InfoGroupRow = styled.div`
  margin: 5px 0;
`

const TeamLeadInfoWrapper = styled.div`
  color: ${props => props.theme.text.light};
  font-size: 12px;
`

const LightText = styled.div`
  color: ${props => props.theme.text.light};
  font-family: ${props => props.theme.fonts.light};
`

const TeamLeadInfoHeader = styled.div`
  color: ${props => props.theme.secondary.base};
`

const Stat = styled.span`
  font-family: ${th('fonts.bold')};
  color: ${props => props.theme.primary.base};
`

const TeamCard = ({ team }) => {
  const {
    id,
    name,
    location,
    team_code,
    team_lead_email,
    team_lead_name,
    team_members,
    checkins
  } = team

  const locationName = location || 'No location specified'
  const LocationIcon = location ? Location : LocationOff

  return (
    <Card>
      <Heading>{name}</Heading>

      <label>{team_code}</label>

      <Info>
        <InfoGroup flex={3}>
          <LightText>
            <InfoField icon={LocationIcon}>{locationName}</InfoField>
          </LightText>
        </InfoGroup>

        <InfoGroup flex={1}>
          <div>
            <InfoGroupRow>
              <Stat>{checkins}</Stat> {checkins === 1 ? 'good deed' : 'good deeds'}
            </InfoGroupRow>

            <InfoGroupRow>
              <Stat>{team_members}</Stat> {team_members === 1 ? 'member' : 'members'}
            </InfoGroupRow>
          </div>
        </InfoGroup>

        <InfoGroup flex={1}>
          <TeamLeadInfoWrapper>
            <TeamLeadInfoHeader>Managed by</TeamLeadInfoHeader>
            <div>{team_lead_name}</div>
            <div>{team_lead_email}</div>
          </TeamLeadInfoWrapper>
        </InfoGroup>
      </Info>

      <Actions>
        <ActionLink to={`/dashboard/teams/${id}`}>
          <LinkButton>Members</LinkButton>
        </ActionLink>

        <ActionLink to={`/dashboard/teams/${id}/edit`}>
          <LinkButton>Edit details</LinkButton>
        </ActionLink>
      </Actions>
    </Card>
  )
}

export default TeamCard
