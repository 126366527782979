import React from 'react'
import styled from 'styled-components'
import { Link } from '@reach/router'

// assets
import { Envelope, Location } from 'components/Icons/Icons'
import DefaultLogo from 'assets/images/icon-group.svg'

// icons
import { Building } from '@styled-icons/bootstrap/Building'
import { CheckCircleFill } from '@styled-icons/bootstrap/CheckCircleFill'
import { FlagCheckered } from '@styled-icons/fa-solid/FlagCheckered'
import { PeopleFill } from '@styled-icons/bootstrap'
import { Settings } from 'styled-icons/ionicons-outline'

// config
import l from 'config/localization'

// utils
import stringUtils from 'utils/string'
import th from 'utils/themeHelper'

// styles
import { getPillColors } from 'components/SearchResultsContainer/styles'
import DashCardWrapper from './DashCardWrapper'

// Load components synchronously
import ConditionalDisplay from 'components/ConditionalDisplay/ConditionalDisplay'

const ActionItem = styled.div`
  margin-bottom: 10px;
  margin-top: 10px;
  margin-right: 8px;
  position: relative;

  &:last-of-type {
    margin-right: 0;
  }
`

const Actions = styled.div`
  align-items: center;
  display: flex;
  flex: 1;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin: 0 10px;
`

const ActionsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`

const Heading = styled.h4`
  display: inline-block;
  flex: 1;
  margin: 0;
  vertical-align: middle;
`

const Info = styled.div`
  display: block;
  margin: 10px 15px 0;
  flex: 1;
`

const InfoFieldLabel = styled.div`
  font-family: ${th('fonts.bold')};
  margin-right: 10px;
`

const InfoFieldValue = styled.div`
  font-family: ${th('fonts.regular')};
  margin-left: 30px;
`

const InfoFieldIcon = styled.div`
  margin-right: 10px;

  & > svg {
    height: 20px;
  }
`

const InfoGroup = styled.div`
  min-width: 30%;
`

const InfoGroupRow = styled.div`
  color: ${th('text.mid')};
  line-height: 1.25em;
  margin-bottom: 15px;
`

const InfoGroupRowInner = styled.div`
  display: flex;
  word-break: break-word;
`

const Logo = styled.div`
  background-image: url(${({ src }) => src});
  background-size: cover;
  background-position: center center;
  border: 1px solid ${th('borders.darkPill')};
  border-radius: 50%;
  height: 50px;
  margin-right: 15px;
  width: 50px;
`

const OrgCardLink = styled(Link)`
  border-color: ${th('backgrounds.mid')};
  border-radius: 4px;
  border-style: solid;
  border-width: 1px;
  color: ${th('text.dark')};
  display: block;
  font-size: 13px;
  margin: 0;
  padding: 8px 12px;
  position: relative;
  text-align: center;
  text-decoration: none;
  white-space: nowrap;

  & > svg {
    width: 15px;
    margin-right: 10px;
  }
`

const OrgMainDetailsWrapper = styled.div`
  align-items: center;
  display: flex;
  flex: 1;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin: 10px 15px 10px;
`

const Pill = styled.div`
  background: ${props => props.bgColor || th('backgrounds.default')};
  color: ${props => props.fontColor || th('text.dark')};
  border-radius: 3px;
  display: inline-block;
  font-size: 9px;
  margin-top: 2px;
  padding: 2px 5px;
  text-align: center;
  text-transform: uppercase;
  width: ${props => (props.fullWidth ? '100%' : 'auto')};
`

const DashOrganisationCard = ({
  hasGodAdminRole,
  id,
  location,
  logo,
  name,
  org_type,
  primary_email,
  status,
  superAdminIds,
  userData
}) => {
  const { bgColor, color } = getPillColors({ key: 'status', value: status })

  const canEdit = hasGodAdminRole
    ? true
    : superAdminIds && superAdminIds.length
    ? superAdminIds.includes(userData.id)
    : false

  return (
    <DashCardWrapper>
      <ActionsWrapper>
        <OrgMainDetailsWrapper>
          <Logo src={stringUtils.isBlank(logo) ? DefaultLogo : logo} />
          <Heading>{name}</Heading>
        </OrgMainDetailsWrapper>

        <Info>
          <InfoGroup>
            <InfoGroupRow>
              <InfoGroupRowInner>
                <InfoFieldIcon>
                  <Building />
                </InfoFieldIcon>
                <InfoFieldLabel>Organisation type</InfoFieldLabel>
              </InfoGroupRowInner>

              <InfoGroupRowInner>
                <InfoFieldValue>{org_type}</InfoFieldValue>
              </InfoGroupRowInner>
            </InfoGroupRow>

            <InfoGroupRow>
              <InfoGroupRowInner>
                <InfoFieldIcon>
                  <Location />
                </InfoFieldIcon>
                <InfoFieldLabel>Location</InfoFieldLabel>
              </InfoGroupRowInner>

              <InfoGroupRowInner>
                <InfoFieldValue>{location || 'Location not specified'}</InfoFieldValue>
              </InfoGroupRowInner>
            </InfoGroupRow>

            <InfoGroupRow>
              <InfoGroupRowInner>
                <InfoFieldIcon>
                  <Envelope />
                </InfoFieldIcon>
                <InfoFieldLabel>Primary Email</InfoFieldLabel>
              </InfoGroupRowInner>

              <InfoGroupRowInner>
                <InfoFieldValue>{primary_email || 'N/A'}</InfoFieldValue>
              </InfoGroupRowInner>
            </InfoGroupRow>

            <InfoGroupRow>
              <InfoGroupRowInner>
                <InfoFieldIcon>
                  <CheckCircleFill />
                </InfoFieldIcon>
                <InfoFieldLabel>Status</InfoFieldLabel>
              </InfoGroupRowInner>

              <InfoGroupRowInner>
                <InfoFieldValue>
                  <Pill bgColor={bgColor} fontColor={color}>
                    {status}
                  </Pill>
                </InfoFieldValue>
              </InfoGroupRowInner>
            </InfoGroupRow>
          </InfoGroup>
        </Info>

        <Actions>
          <ActionItem>
            <OrgCardLink to={`/dashboard/${l('ROUTE_ORGS')}/${id}/members`}>
              <PeopleFill />
              <span>Members</span>
            </OrgCardLink>
          </ActionItem>

          <ActionItem>
            <OrgCardLink to={`/dashboard/${l('ROUTE_ORGS')}/${id}/goals`}>
              <FlagCheckered />
              <span>Goals</span>
            </OrgCardLink>
          </ActionItem>

          <ConditionalDisplay displayWhen={[canEdit]}>
            <ActionItem>
              <OrgCardLink to={`/dashboard/${l('ROUTE_ORGS')}/${id}/`}>
                <Settings />
                <span>Manage</span>
              </OrgCardLink>
            </ActionItem>
          </ConditionalDisplay>
        </Actions>
      </ActionsWrapper>
    </DashCardWrapper>
  )
}

export default DashOrganisationCard
