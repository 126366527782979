import React, { Component } from 'react'
import Select, { createFilter, components } from 'react-select'
import styled from 'styled-components'

// config
import { isDgmt } from 'config/localization'

// utils
import th from 'utils/themeHelper'

// icons
import { ArrowDropDown } from '@styled-icons/material/ArrowDropDown'
import { Check } from '@styled-icons/feather'

// styles
import { Tag } from '../index'
import { Error, Label, RequiredTag, SubLabel, Success } from '../InputLabels/InputLabels'

// Load components synchronously
import ConditionalDisplay from 'components/ConditionalDisplay/ConditionalDisplay'

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 300px;
  max-width: 500px;
  position: relative;

  @media screen and (max-width: 600px) {
    margin-bottom: 5px;
    width: 100%;
    max-width: 100%;
  }
`

export const OuterWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 0;
  margin-left: 2px;
  position: relative;
`

export const SelectionLabelWrapper = styled.div`
  margin-bottom: -5px;
  margin-top: 15px;
`

export const SelectWrapper = styled.div`
  display: block;
  position: relative;
`

const DownIcon = styled(ArrowDropDown)`
  color: ${th('background.mid')};
  height: 30px;
  width: 30px;
`

const CheckWrapper = styled.div`
  align-items: center;
  background: white;
  display: flex;
  height: ${props => (props.requiredField ? '41px' : '43px')};
  justify-content: center;
  position: absolute;
  right: 2px;
  top: ${props => (props.requiredField ? '2px' : '1px')};
  width: 43px;
`

const CheckIcon = styled(Check)`
  color: ${th('background.mid')};
  height: 30px;
  width: 30px;
`

const borderColorBottom = ({ requiredField, menuOpen, hasError, theme }) => {
  if (menuOpen) {
    return theme.borders.default
  } else if (hasError) {
    return theme.borders.error
  } else if (requiredField) {
    return theme.borders.required
  } else {
    return theme.borders.default
  }
}

const borderColor = ({ requiredField, menuOpen, hasError, theme }) => {
  if (menuOpen) {
    return theme.borders.success
  } else if (hasError) {
    return theme.borders.error
  } else if (requiredField) {
    return theme.borders.required
  } else {
    return theme.borders.default
  }
}

const borderColorHover = ({ requiredField, menuOpen, hasError, theme }) => {
  if (menuOpen) {
    return theme.borders.success
  } else if (hasError) {
    return theme.borders.error
  } else if (requiredField) {
    return theme.borders.required
  } else {
    return theme.borders.dark
  }
}

const borderWidth = ({ requiredField, menuOpen, hasError }) => {
  if (menuOpen) {
    return '1px'
  } else if (hasError) {
    return '2px'
  } else if (requiredField) {
    return '2px'
  } else {
    return '1px'
  }
}

const SingleSelectOptionIcon = ({ padding, src }) => {
  return (
    <SingleSelectOptionIconInner>
      <SingleSelectPaddedIcon padding={padding} src={src} />
    </SingleSelectOptionIconInner>
  )
}

const SelectedTag = styled.img`
  border-radius: 50%;
  height: 30px;
  margin-right: 4px;
  padding: 5px;
  width: 30px;
`

const SingleSelectPaddedIcon = styled.img`
  padding: 5px;
  height: 30px;
  width: 30px;
`

const SingleSelectOptionIconInner = styled.span`
  border: 1px solid ${th('borders.dark')};
  border-radius: 50%;
  display: inline-block;
  height: 30px;
  line-height: 1;
  margin-right: 10px;
  overflow: hidden;

  vertical-align: middle;
  width: 30px;
`

const DisabledInput = styled.input`
  background-color: ${th('backgrounds.light')};
  border-style: solid;
  border-width: ${borderWidth};
  border-color: ${borderColor};
  font-size: 15px;
  height: 45px;
  padding: 15px;
  cursor: not-allowed;
`

const StyledSelect = styled(Select)`
  .Select__control {
    border-style: solid;
    border-width: ${borderWidth};
    border-color: ${borderColor};
    border-bottom-color: ${borderColorBottom};
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
    border-bottom-left-radius: ${({ menuOpen }) => (menuOpen ? '0px' : '3px')};
    border-bottom-right-radius: ${({ menuOpen }) => (menuOpen ? '0px' : '3px')};
    appearance: none;
    height: 45px;
    font-family: ${th('fonts.light')};

    &:hover {
      border-color: ${borderColorHover};
      border-bottom-color: ${borderColorHover};
    }
  }

  .Select__control.Select__control--is-focused {
    box-shadow: none;

    border-color: ${borderColor};
    border-bottom-color: ${borderColorBottom};
    &:hover {
      border-color: ${borderColor};
      border-bottom-color: ${borderColorBottom};
    }
  }

  .Select__placeholder {
    color: ${props => (props.allOptsSelected ? th('text.light') : th('text.dark'))};
  }

  .Select__single-value {
    color: ${th('text.dark')};
  }

  .Select__dropdown-indicator {
    color: ${th('background.mid')};
    &:focus,
    &:hover {
      color: ${th('background.mid')};
    }
  }

  .Select__menu {
    box-shadow: none;
    margin: 0;
    border-style: solid;
    border-width: ${borderWidth};
    border-color: ${() => {
      if (isDgmt) {
        return th('borders.medium')
      }

      return th('secondary.base')
    }};
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;
    border-top: none;
    z-index: 2;
  }

  .Select__menu-list {
    padding: 0;
  }

  .Select__option {
    display: flex;
    flex-wrap: wrap;
    align-items: center;

    &:last-child {
      border-bottom-left-radius: 3px;
      border-bottom-right-radius: 3px;
    }
  }

  .Select__option--is-focused {
    background: ${th('secondary.lightest')};
  }

  .Select__multi-value {
    height: 35px;
    color: red;
  }

  .Select__multi-value__label {
    display: flex;
    align-items: center;
    color: ${th('primary.dark')};
    background: ${th('primary.lightest')};
    border: 1px solid ${th('primary.dark')};
    border-right: none;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  .Select__multi-value__remove {
    color: ${th('primary.dark')};
    background: ${th('primary.lightest')};
    border: 1px solid ${th('primary.dark')};
    border-left: none;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;

    &:hover {
      color: ${th('primary.dark')};
      background: ${th('primary.lightest')};
    }
  }

  .Select__clear-indicator {
    margin-right: 10px;
    &:after {
      margin-top: 2px;
      content: 'Remove';
      font-size: 13px;
    }
  }
`

const SelectOption = props => {
  const icon = props.data.icon || props.data.image

  if (!icon) {
    return <components.Option {...props}>{props.data.label}</components.Option>
  }

  return (
    <components.Option {...props}>
      <SingleSelectOptionIcon padding={props.data.padding} src={icon} />
      <span>{props.data.label}</span>
    </components.Option>
  )
}

const SingleValue = props => {
  const icon = props.data.icon || props.data.image

  if (!icon) {
    return <components.SingleValue {...props}>{props.data.label}</components.SingleValue>
  }

  return (
    <components.SingleValue {...props}>
      <SingleSelectOptionIcon padding={props.data.padding} src={icon} />
      <span>{props.data.label}</span>
    </components.SingleValue>
  )
}

const IndicatorSeparator = () => {
  return null
}

const DropdownIndicator = props => {
  return (
    components.DropdownIndicator && (
      <components.DropdownIndicator {...props}>
        <DownIcon />
      </components.DropdownIndicator>
    )
  )
}

const deriveValueName = ({ collectionName, values }) => {
  const isFormArrayFieldName = collectionName.match(/\[\d+\]/)
  if (!isFormArrayFieldName) return values[collectionName]

  const arrayIndex = collectionName.replace(/.+\[(\d+)\].+/, '$1')
  const prefix = collectionName.replace(/\[.+/, '')
  const collectionNameSuffix = collectionName.replace(/.+\./, '')

  const prefixedValue = values[prefix] || {}
  const indexedValue = prefixedValue[arrayIndex] || {}
  const collectionNameValue = indexedValue[collectionNameSuffix]

  return collectionNameValue
}

const addToCollection = ({ collectionName, form, option }) => {
  if (!form) return `${option.value}`

  const { values } = form
  const valueName = deriveValueName({ collectionName, values })
  const optsValue = valueName || ''

  const isArray = Array.isArray(optsValue)
  const isObject = typeof optsValue === 'object'
  const isString = typeof optsValue === 'string'

  if (isString && !optsValue.length) return `${option.value}`
  if (isString && optsValue.length) return `${optsValue},${option.value}`
  if (isArray && optsValue.length) {
    return `${optsValue.map(o => o.value).join(',')},${option.value}`
  }
  if (isArray && !optsValue.length) return `${option.value}`
  if (isObject) return `${option.value}`

  return ''
}

const removeFromCollection = ({ collectionName, form, value }) => {
  if (!form) return undefined

  const { values } = form
  const valueName = deriveValueName({ collectionName, values })
  const optsValue = valueName || ''

  const isArray = Array.isArray(optsValue)
  const isString = typeof optsValue === 'string'

  if (isArray) {
    const collectionWithoutRemovedTag = optsValue
      .filter(c => c.value !== value && c.value !== `${value}`)
      .map(c => c.value)
    return collectionWithoutRemovedTag.join(',')
  }

  if (isString) {
    const collectionWithoutRemovedTag = optsValue
      .split(',')
      .filter(c => c !== value && c !== `${value}`)
    return collectionWithoutRemovedTag.join(',')
  }

  return ''
}

const getSelectedOptString = ({ collectionName, values }) => {
  const valueName = deriveValueName({ collectionName, values })
  const optsValue = valueName || ''

  const isArray = Array.isArray(optsValue)
  const isObject = typeof optsValue === 'object'
  const isString = typeof optsValue === 'string'

  if (isString) return optsValue
  if (isArray) return optsValue.length ? `${optsValue.map(o => o.value).join(',')}` : ''
  if (isObject) return `${optsValue.value}`

  return `${optsValue.value}`
}

const getLabelHtmlFor = ({ field, fieldName }) => {
  return Object.keys(field).length > 0
    ? field.name || `${field.name}_collection`
    : fieldName || `${fieldName}_collection`
}

class MultiSelect extends Component {
  constructor(props) {
    super(props)

    this.state = { menuOpen: false, removeableIds: [] }
    this.removeTag = this.removeTag.bind(this)
    this.onChange = this.onChange.bind(this)
  }

  onChange(option) {
    const { field, form, fieldName } = this.props
    const collectionName = `${Object.keys(field).length > 0 ? field.name : fieldName}_collection`
    const collection = addToCollection({ collectionName, form, option })

    if (form) {
      form.setFieldValue(Object.keys(field).length > 0 ? field.name : fieldName, '')
      form.setFieldError(collectionName, undefined)
      form.setFieldValue(collectionName, collection)
    }
  }

  removeTag(value) {
    const { field, form, fieldName } = this.props
    const collectionName = `${Object.keys(field).length > 0 ? field.name : fieldName}_collection`
    const collectionWithoutRemovedTag = removeFromCollection({ collectionName, form, value })

    if (form) {
      form.setFieldValue(collectionName, collectionWithoutRemovedTag)
    }
  }

  handleRemove({ tag }) {
    const { immutable } = tag
    const { removeableIds } = this.state
    const { hasHandler } = this.props

    // Do nothing if tag is marked as immutable
    if (immutable) return true

    // Coerce into an array in case the removeableIds is an integer
    const removeableIdsArray = Array.isArray(removeableIds) ? removeableIds : [removeableIds]

    if (hasHandler && !removeableIdsArray.includes(tag.value)) {
      this.setState({ removeableIds: removeableIdsArray.push(tag.value) }, () => {
        this.props.handleRemove(tag.value)
      })
    } else {
      this.removeTag(tag.value)
    }
  }

  render() {
    const { menuOpen } = this.state
    const {
      disabled,
      field = {},
      form,
      label,
      subLabel,
      success,
      className,
      requiredField,
      fieldName,
      options = [],
      searchable = false,
      ...props
    } = this.props

    const { errors, touched, values } = form
    const displayableLabel = disabled ? label : requiredField ? `${label} *` : label
    const htmlFor = getLabelHtmlFor({ field, fieldName })

    const collectionName = `${htmlFor}_collection`
    const selectedOptsString = getSelectedOptString({ collectionName, values })
    const selectedOptsValueArray = selectedOptsString.split(',')
    const selectedOpts = options.filter(o => selectedOptsValueArray.includes(`${o.value}`))
    const selectedOptValues = selectedOpts.map(o => o.value)
    const filteredOpts = options.filter(o => !selectedOptValues.includes(o.value))
    const hasTouchedField = touched[htmlFor]
    const hasError = !!errors[collectionName]
    const anySelected = selectedOptsString.length > 0
    const allOptsSelected = anySelected && !filteredOpts.length
    const anotherPlaceholder = (props.placeholder || '').replace('at least one', 'another')
    const placeholder = allOptsSelected
      ? 'All options selected'
      : anySelected
      ? anotherPlaceholder
      : props.placeholder

    if (disabled) {
      const value = {}

      return (
        <Wrapper className={className}>
          {label && <Label htmlFor={htmlFor}>{displayableLabel}</Label>}
          {subLabel && <SubLabel subLabel={subLabel}>{subLabel}</SubLabel>}

          <ConditionalDisplay displayWhen={[!selectedOpts.length]}>
            <DisabledInput value={value.label || 'No options selected'} onChange={() => {}} />
          </ConditionalDisplay>

          <ConditionalDisplay displayWhen={[selectedOpts.length]}>
            <OuterWrapper>
              {selectedOpts.map((tag, index) => (
                <Tag canHaveSiblings key={`removable_${index}`} immutable>
                  {tag.icon && <SelectedTag src={tag.icon} />}
                  {tag.label}
                </Tag>
              ))}
            </OuterWrapper>
          </ConditionalDisplay>
        </Wrapper>
      )
    }

    return (
      <Wrapper className={className}>
        {hasError ? (
          <Error>{errors[collectionName]}</Error>
        ) : label ? (
          <Label htmlFor={htmlFor}>{displayableLabel}</Label>
        ) : null}

        {subLabel && <SubLabel subLabel={subLabel}>{subLabel}</SubLabel>}

        <RequiredTag hasError={hasError} label={label} requiredField={requiredField} />

        <SelectWrapper>
          <StyledSelect
            requiredField={requiredField}
            classNamePrefix="Select"
            components={{
              IndicatorSeparator,
              DropdownIndicator,
              Option: SelectOption,
              SingleValue
            }}
            filterOption={createFilter({ ignoreAccents: false })}
            menuOpen={menuOpen}
            {...field}
            {...props}
            touched={!!hasTouchedField}
            hasError={!!hasError}
            isSearchable={searchable}
            allOptsSelected={allOptsSelected}
            onMenuOpen={() => this.setState({ menuOpen: true })}
            onMenuClose={() => this.setState({ menuOpen: false })}
            onChange={opt => this.onChange(opt)}
            value={''}
            placeholder={placeholder}
            options={filteredOpts}
          />

          <ConditionalDisplay displayWhen={[allOptsSelected]}>
            <CheckWrapper requiredField={requiredField}>
              <CheckIcon />
            </CheckWrapper>
          </ConditionalDisplay>
        </SelectWrapper>

        <ConditionalDisplay displayWhen={[success, hasTouchedField, !hasError]}>
          <Success>{success}</Success>
        </ConditionalDisplay>

        <ConditionalDisplay displayWhen={[selectedOpts.length]}>
          <SelectionLabelWrapper>
            <Label>Your current selections:</Label>
          </SelectionLabelWrapper>

          <OuterWrapper>
            {selectedOpts.map((tag, index) => (
              <Tag
                canHaveSiblings
                key={`removable_${index}`}
                handleClick={() => this.handleRemove({ tag })}
                immutable={tag.immutable}
              >
                {tag.icon && <SelectedTag src={tag.icon} />}
                {tag.label}
              </Tag>
            ))}
          </OuterWrapper>
        </ConditionalDisplay>
      </Wrapper>
    )
  }
}

export default MultiSelect
