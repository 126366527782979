import React from 'react'
import { DashboardWrapper, ContentContainer } from './styles'
import DashboardTopNav from '../DashboardTopNav'
import DashboardFooter from '../DashboardFooter'

export default () => (
  <DashboardWrapper>
    <DashboardTopNav />
    <ContentContainer />
    <DashboardFooter />
  </DashboardWrapper>
)
