import React, { Component } from 'react'
import styled from 'styled-components'
import lighten from 'polished/lib/color/lighten'

// config
import { isSef } from 'config/localization'

// utils
import th from '../../utils/themeHelper'

const snapAgent = navigator.userAgent === 'ReactSnap'
const isDev = process.env.NODE_ENV === 'development'

const HeaderContainer = styled.div`
  text-align: center;
  color: white;
  padding: 10px;
  background: ${lighten(0.1, '#484848')};
  font-size: 15px;

  @media screen and (min-width: 600px) {
    font-size: initial;
    margin-top: ${isSef ? '-5px' : '0'};
    padding: 10px;
  }

  @media screen and (min-width: 730px) {
    padding: 10px;
  }

  @media screen and (min-width: 1024px) {
    padding: 15px 70px;
  }
`

const CookieLink = styled.a`
  color: white;
  text-decoration: underline;
  font-size: 15px;

  @media screen and (min-width: 600px) {
    font-size: initial;
  }
`

const DismissCookieBannerButton = styled.span`
  background: ${th('secondary.base')};
  border-radius: 6px;
  color: white;
  cursor: pointer;
  display: block;
  margin: 10px auto 0;
  max-width: max-content;
  padding: 5px 15px;

  @media screen and (min-width: 600px) {
    display: inline;
    margin-left: 20px;
    margin-top: 0;
  }
`

class CookieHeader extends Component {
  state = {
    hideCookieBanner: false
  }

  acceptCookie() {
    window.localStorage.setItem('accepts_cookies', true)
    this.setState({
      hideCookieBanner: true
    })
  }

  render() {
    const hasAcceptedCookies = window.localStorage.getItem('accepts_cookies')
    const { hideCookieBanner } = this.state

    if (snapAgent || hasAcceptedCookies || hideCookieBanner || isDev) {
      return null
    }

    return (
      <HeaderContainer {...this.props}>
        This website uses cookies. Please{' '}
        <CookieLink
          href="https://www.cookiepolicygenerator.com/live.php?token=ZmUNb0HAWi6oaCLpqFi4TSHGEuL05x0D"
          target="_blank"
          rel="noopener"
        >
          read our cookie policy.
        </CookieLink>
        <DismissCookieBannerButton onClick={() => this.acceptCookie()}>
          Accept
        </DismissCookieBannerButton>
      </HeaderContainer>
    )
  }
}

export default CookieHeader
