import React from 'react'

// assets
import orgIcon from 'assets/images/icon-group.svg'

// styles
import {
  HostOrgDetails,
  MastheadWrapper,
  MastheadWrapperInner,
  MobileOpportunityTitle,
  OpportunityPartnerDetails,
  OpportunityPartnersHeading,
  OpportunityPartnersWrapper,
  OpportunityInner,
  OpportunityOuter,
  OpportunityTitle,
  Outer,
  OrgLink,
  OrgLogo,
  OrgLogoWrapper,
  SingleOpportunityPartner
} from './styles'

// partials
import BeneficiarySection from '../../scenes/Opportunities/SignUpForm/_beneficiarySection'

// Load components synchronously
import ConditionalDisplay from 'components/ConditionalDisplay/ConditionalDisplay'

const Masthead = ({
  align = 'initial',
  beneficiaryOrg,
  children,
  image,
  isHostFeedback,
  opportunity = {},
  roundBorders,
  signUpFormOpen
}) => {
  const hasOpportunity = opportunity && opportunity.id

  if (!!hasOpportunity) {
    return (
      <OpportunityOuter signUpFormOpen={signUpFormOpen}>
        <MobileOpportunityTitle>{opportunity.title}</MobileOpportunityTitle>

        <OpportunityInner beneficiaryOrg={beneficiaryOrg}>
          <BeneficiarySection
            opportunity={opportunity}
            beneficiaryOrg={opportunity.Beneficiary}
            isMobile
          />
        </OpportunityInner>

        <MastheadWrapper image={image} roundBorders={roundBorders}>
          <OpportunityTitle>{opportunity.title}</OpportunityTitle>
        </MastheadWrapper>
      </OpportunityOuter>
    )
  }

  const { opportunityPartners = [] } = opportunity
  const hasOppPartners = opportunityPartners.length > 0

  return (
    <Outer>
      <MastheadWrapper image={image} isHostFeedback={isHostFeedback} roundBorders={roundBorders}>
        <MastheadWrapperInner align={align} isHostFeedback={isHostFeedback}>
          {children}
        </MastheadWrapperInner>

        <ConditionalDisplay displayWhen={[hasOppPartners]}>
          <OpportunityPartnerDetails>
            <OpportunityPartnersHeading>in partnership with</OpportunityPartnersHeading>

            <OpportunityPartnersWrapper>
              {opportunityPartners.map(partner => (
                <SingleOpportunityPartner key={`opportunity_partner_${partner.id}`}>
                  <HostOrgDetails>
                    <OrgLink href={partner.org_link}>
                      <OrgLogoWrapper>
                        <OrgLogo src={partner.org_logo || orgIcon} />
                      </OrgLogoWrapper>
                    </OrgLink>

                    <span>
                      <OrgLink ishost href={partner.org_link}>
                        {partner.org_name}
                      </OrgLink>
                    </span>
                  </HostOrgDetails>
                </SingleOpportunityPartner>
              ))}
            </OpportunityPartnersWrapper>
          </OpportunityPartnerDetails>
        </ConditionalDisplay>
      </MastheadWrapper>
    </Outer>
  )
}

export default Masthead
