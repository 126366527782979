import axios from 'axios'

// config
import baseUrl from '../config'

export const donateToOrganisation = async data => {
  return await axios({
    withCredentials: true,
    method: 'POST',
    url: `${baseUrl}/org-donations`,
    data: { ...data },
    responseType: 'json',
    config: { headers: { 'Content-Type': 'multipart/form-data' } }
  })
}

export const fetchOrgBoundOrgDonation = async params => {
  const id = params.donationId || params.orgDonationId
  const response = await axios.get(`${baseUrl}/org-donations/${id}`, {
    withCredentials: true,
    params
  })
  return response.data
}
