import axios from 'axios'

// config
import baseUrl from '../config'

// utils
import { setApiStatus } from 'utils/localStorageManager'

export const confirmEmail = data => {
  return axios
    .post(`${baseUrl}/confirm-email`, data)
    .then(responseData => responseData)
    .catch(err => err)
}

export const login = data => {
  return axios({
    method: 'post',
    url: `${baseUrl}/login`,
    data,
    withCredentials: true
  }).catch(err => err)
}

export const loginAsTimekeeper = data => {
  return axios({
    method: 'post',
    url: `${baseUrl}/login-as-timekeeper`,
    data,
    withCredentials: true
  }).catch(err => err)
}

export const requestMagicLink = data => {
  return axios
    .post(`${baseUrl}/request-magic-link`, data)
    .then(responseData => responseData)
    .catch(err => err)
}

export const magicLogin = data => {
  return axios
    .post(`${baseUrl}/magic-login`, { ...data })
    .then(data => data)
    .catch(err => err)
}

export const register = data => {
  return axios({
    method: 'post',
    url: `${baseUrl}/register`,
    data,
    withCredentials: true
  }).catch(err => err)
}

export const resetPassword = data => {
  return axios({
    method: 'post',
    url: `${baseUrl}/reset-password`,
    data,
    withCredentials: true
  })
    .then(responseData => responseData)
    .catch(err => err)
}

export const requestConfirmationEmail = data => {
  return axios
    .post(`${baseUrl}/request-confirmation-email`, data)
    .then(responseData => responseData)
    .catch(err => err)
}

export const requestSefAdminInvite = data => {
  return axios
    .post(`${baseUrl}/request-sef-admin-invite`, data)
    .then(responseData => responseData)
    .catch(err => err)
}

export const requestPasswordReset = data => {
  return axios
    .post(`${baseUrl}/request-password-reset`, data)
    .then(responseData => responseData)
    .catch(err => err)
}

export const generateApiKey = () => {
  return axios
    .post(`${baseUrl}/cli-api-keys`, { withCredentials: true })
    .then(data => data)
    .catch(err => err)
}

export const acceptHostAgreement = () => {
  return axios
    .post(`${baseUrl}/accept-host-agreement`, { withCredentials: true })
    .then(data => data)
    .catch(err => err)
}

export const fetchMyRoles = async opts => {
  const params = {}

  if (opts) {
    Object.keys(opts).map(opt => (params[opt] = opts[opt]))
  }

  return await axios
    .get(`${baseUrl}/my-roles`, { params, withCredentials: true })
    .then(response => {
      setApiStatus('up')
      return response.data
    })
    .catch(error => {
      const isNetworkError = error.code === 'ERR_NETWORK' || error.message === 'Network Error'
      setApiStatus(isNetworkError ? 'down' : 'up')
    })
}

export const fetchOnboardingChallenges = async opts => {
  const params = {}

  if (opts) {
    Object.keys(opts).map(opt => (params[opt] = opts[opt]))
  }

  const response = await axios.get(`${baseUrl}/onboarding-challenges`, {
    withCredentials: true,
    params
  })
  return response.data
}

export const dismissOnboardingChallenge = async data => {
  return axios
    .post(`${baseUrl}/dismiss-onboarding-challenge`, { data, withCredentials: true })
    .then(data => data)
}

export const logout = token => {
  return axios.delete(`${baseUrl}/logout`, { data: { token }, withCredentials: true })
}
