import React, { Component } from 'react'
import styled from 'styled-components'
import th from 'utils/themeHelper'

const ErrorText = styled.p`
  color: ${th('text.error')};
`

class ErrorMessage extends Component {
  constructor(props) {
    super(props)
    this.ref = React.createRef()
  }

  componentDidMount() {
    if (typeof window !== 'undefined' && this.props.scrollOnMount) {
      window.scrollTo(0, 0)
    }
  }

  render() {
    return <ErrorText ref={this.ref}>{this.props.text}</ErrorText>
  }
}

export default ErrorMessage
