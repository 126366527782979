import axios from 'axios'

// config
import baseUrl from '../config'

export const fetchStats = async params => {
  return await axios(`${baseUrl}/stats`, {
    withCredentials: true,
    method: 'GET',
    responseType: 'json',
    params
  })
}
