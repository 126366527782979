import React, { Component, Fragment } from 'react'
import Autosuggest from 'react-autosuggest'
import { withTheme } from 'styled-components'

// icons
import { PlusSign } from 'components/Icons/Icons'

// styles
import {
  Icon,
  Outer,
  PlusIconWrapper,
  SearchInputLabel,
  SpinnerIcon,
  SuggestionContainer,
  SuggestionItem
} from './styles'

// Load components synchronously
import { Error, RequiredTag, SubLabel } from 'components/InputLabels/InputLabels'
import LinkButton from 'components/SmallButtonPrimary/LinkButton'
import Tag from 'components/Tag/Tag'

const autosuggestTheme = {
  container: 'react-autosuggest__container',
  containerOpen: 'react-autosuggest__container--open',
  input: 'react-autosuggest__input',
  inputOpen: 'react-autosuggest__input--open',
  inputFocused: 'react-autosuggest__input--focused',
  suggestionsContainer: 'react-autosuggest__suggestions-container',
  suggestionsContainerOpen: 'react-autosuggest__suggestions-container--open',
  suggestionsList: 'react-autosuggest__suggestions-list',
  suggestion: 'react-autosuggest__suggestion',
  suggestionFirst: 'react-autosuggest__suggestion--first',
  suggestionHighlighted: 'react-autosuggest__suggestion--highlighted',
  sectionContainer: 'react-autosuggest__section-container',
  sectionContainerFirst: 'react-autosuggest__section-container--first',
  sectionTitle: 'react-autosuggest__section-title'
}

class SearchInput extends Component {
  state = { isExpanded: null }

  expandTextArea = () => {
    this.setState({ isExpanded: true })
  }

  render() {
    const { isExpanded } = this.state
    const {
      disabled,
      expandable,
      expandButtonText,
      theme,
      field = {},
      label,
      subLabel,
      locationValue = '',
      clearLocation,
      requiredField,
      form: { errors = {} } = {},
      isLoading,
      suggestions = [],
      showExpandButtonLabel,
      value
    } = this.props

    const displayableLabel = requiredField ? `${label} *` : label
    const hasError = !!errors[field.name]
    const trimmedVal =
      locationValue !== null && locationValue.length > 15
        ? locationValue.slice(0, 55).concat('...')
        : locationValue

    if (expandable && requiredField) {
      return (
        <ComponentError>
          TextArea component cannot accept both the <code>expandable</code> prop and the{' '}
          <code>requiredField</code> prop together.
        </ComponentError>
      )
    }

    if (expandable && !isExpanded && !field.value) {
      return (
        <div>
          {showExpandButtonLabel && (
            <div>
              {label && (
                <SearchInputLabel htmlFor={field.name}>{displayableLabel}</SearchInputLabel>
              )}
              {subLabel && <SubLabel subLabel={subLabel}>{subLabel}</SubLabel>}
            </div>
          )}

          <LinkButton disabled={disabled} onClick={() => this.expandTextArea()}>
            <PlusIconWrapper>
              <PlusSign />
            </PlusIconWrapper>

            <span>{expandButtonText}</span>
          </LinkButton>
        </div>
      )
    }

    return (
      <Fragment>
        {label && <SearchInputLabel htmlFor={field.name}>{displayableLabel}</SearchInputLabel>}
        {subLabel && <SubLabel subLabel={subLabel}>{subLabel}</SubLabel>}

        <RequiredTag hasError={hasError} label={label} requiredField={requiredField} />

        <Outer disabled={disabled} hasError={hasError} requiredField={requiredField}>
          <Autosuggest
            suggestions={suggestions}
            onSuggestionsFetchRequested={this.props.onSuggestionsFetchRequested}
            onSuggestionsClearRequested={this.props.onSuggestionsClearRequested}
            getSuggestionValue={this.props.getSuggestionValue}
            renderInputComponent={inputProps => (
              <input {...inputProps} autoComplete="new-password" />
            )}
            renderSuggestion={(suggestion, { isHighlighted }) => (
              <SuggestionItem isHighlighted={isHighlighted}>
                {this.props.getSuggestionValue(suggestion)}
              </SuggestionItem>
            )}
            renderSuggestionsContainer={({ containerProps, children }) => (
              <SuggestionContainer {...containerProps}>{children}</SuggestionContainer>
            )}
            inputProps={{
              disabled,
              value: value || '',
              onChange: this.props.onChange,
              onBlur: this.props.onBlur,
              onKeyDown: this.props.onKeyDown,
              placeholder: locationValue ? '' : this.props.placeholder
            }}
            {...this.props}
            theme={{ ...theme, ...autosuggestTheme }}
          />

          {isLoading && <SpinnerIcon />}
          {!isLoading && !locationValue && <Icon size={16} color={theme.secondary.base} />}
        </Outer>

        {locationValue && locationValue.length > 0 && (
          <Tag
            requiredField={requiredField}
            handleClick={() => {
              if (!disabled) clearLocation()
            }}
          >
            {trimmedVal}
          </Tag>
        )}

        {errors[field.name] && <Error>{errors[field.name]}</Error>}
      </Fragment>
    )
  }
}

SearchInput.defaultProps = {
  suggestions: [],
  onKeyDown: e => {
    if (e.keyCode === 13) {
      e.preventDefault()
    }
  }
}

export default withTheme(SearchInput)
