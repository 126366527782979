import React, { PureComponent } from 'react'
import styled from 'styled-components'
import ReactCrop from 'react-image-crop'

// imported CSS
import 'react-image-crop/dist/ReactCrop.css'

// utils
import th from 'utils/themeHelper'

// config
import { isDgmt } from 'config/localization'
import constants from 'config/constants'

// Load components synchronously
import ConditionalDisplay from 'components/ConditionalDisplay/ConditionalDisplay'
import SmallButtonLabelSecondary from '../SmallButtonLabelSecondary/SmallButtonLabelSecondary'
import LinkButton from 'components/SmallButtonPrimary/LinkButton'

// styles
import { Error, RequiredTag, SubLabel } from '../InputLabels/InputLabels'

// icons
import { PlusSign } from 'components/Icons/Icons'

const PlusIconWrapper = styled.span`
  display: inline-block;
  margin-right: 5px;
  width: 10px;

  svg {
    margin-top: -2px;
  }
`

const InputWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  appearance: none;
  outline: none;
  border-radius: 3px;
  border-style: solid;
  border-color: ${({ disabled, requiredField, hasError, theme }) =>
    disabled
      ? theme.borders.medium
      : hasError
      ? theme.borders.error
      : requiredField
      ? theme.borders.required
      : theme.borders.default};
  border-width: ${({ requiredField, hasError }) =>
    hasError ? '2px' : requiredField ? '2px' : '1px'};
  color: ${th('text.light')};
  font-family: ${th('fonts.light')};
  background: white;
  width: 100%;
  height: 45px;
  padding: 5px;
  text-align: center;

  &:hover {
    border-color: ${({ disabled, hasError, theme }) =>
      disabled ? theme.borders.medium : hasError ? theme.borders.error : theme.borders.dark};
  }

  &:focus {
    border-color: ${({ disabled, hasError, theme }) => {
      if (isDgmt) {
        return disabled
          ? theme.borders.medium
          : hasError
          ? theme.borders.error
          : theme.borders.medium
      }

      return disabled ? theme.borders.medium : hasError ? theme.borders.error : theme.secondary.base
    }};
  }
`

const Label = styled.label`
  display: block;
  font-size: 13px;
  color: ${th('text.dark')};
  font-family: ${th('fonts.bold')};
  padding: ${props => (props.innerLabel ? '0 2px 2px 0' : '2px')};
  margin-bottom: ${props => (props.innerLabel ? '0' : '4px')};
`

const Placeholder = styled.div`
  align-items: center;
  color: ${th('text.light')};
  display: flex;
  font-size: 12px;
  line-height: 12px;
  padding-left: 5px;
  text-align: left;
  overflow: hidden;

  @media screen and (min-width: 600px) {
    font-size: 15px;
    line-height: 1em;
    padding-left: 10px;
  }
`

const PreviewImage = styled.img`
  border-radius: ${props => (props.circularCrop ? '50%' : '0')};
  height: ${props => (props.circularCrop ? '250px' : 'auto')};
  margin: 15px 0;
  max-width: 100%;

  @media screen and (min-width: 769px) {
    max-width: ${props => (props.circularCrop ? '250px' : '500px')};
  }
`

const SubLabelWrapper = styled.div`
  width: 100%;
  margin-top: ${props => (props.subLabel ? '4px' : '0')};
  display: block;
`

const ValueLabel = styled.div`
  color: ${th('text.dark')};
  font-size: 15px;
  line-height: 35px;
  overflow: hidden;
  padding-left: 10px;
`

const Wrapper = styled.div`
  display: inline-block;
  position: relative;
  width: 100%;

  @media screen and (min-width: 600px) {
    min-width: ${props => (props.fullWidth ? '100%' : '500px')};
    width: ${props => (props.fullWidth ? '100%' : '500px')};
  }
`

const handleFileChange = async ({ e, field, onChange, setFieldError, setFieldValue, setState }) => {
  const filesFromInput = [...e.target.files]
  const hasAnyFile = filesFromInput && filesFromInput.length > 0

  if (hasAnyFile) {
    const file = filesFromInput[0]
    const isRealFile = file && typeof file !== 'string'

    if (isRealFile && file.size > constants.MAX_FILE_SIZE) {
      setFieldError(field.name, 'File too large. Please keep files under 2mb')
      setFieldValue(field.name, undefined)
      setFieldValue('img_preview_b64', undefined)

      setState({ fileName: null, src: null })
    } else {
      setFieldError(field.name, undefined)

      const reader = new FileReader()
      reader.addEventListener('load', () => {
        setState({ fileName: file.name, src: reader.result })
      })
      reader.readAsDataURL(file)

      if (onChange) {
        onChange(e)
      }
    }
  }
}

class FileUpload extends PureComponent {
  state = {
    crop: {
      unit: 'px'
    },
    fileName: null,
    isExpanded: null,
    src: null
  }

  expandInputArea = () => {
    this.setState({ isExpanded: true })
  }

  onCropChange = crop => {
    this.setState({ crop })
  }

  onCropComplete = ({
    circularCrop,
    crop,
    cropDetailsLabel,
    field,
    setFieldError,
    setFieldValue
  }) => {
    this.makeClientCrop({
      circularCrop,
      crop,
      cropDetailsLabel,
      field,
      setFieldError,
      setFieldValue
    })
  }

  // If you setState the crop in here you should return false.
  onImageLoaded = image => {
    this.imageRef = image
  }

  async makeClientCrop({
    circularCrop,
    crop,
    cropDetailsLabel,
    field,
    setFieldError,
    setFieldValue
  }) {
    if (this.imageRef && crop.width && crop.height) {
      const { fileName = 'newFile.jpg' } = this.state
      const croppedResult = await this.getCroppedImg({
        circularCrop,
        crop,
        cropDetailsLabel,
        field,
        fileName,
        image: this.imageRef,
        setFieldError,
        setFieldValue
      })

      setFieldValue('img_preview_b64', croppedResult)

      return croppedResult
    }
  }

  // The cropped image blob is only generated for a preview on the client.
  // The crop object gets sent to the API to crop the actual image in node
  // because canvas generates HUGE image sizes
  getCroppedImg({
    circularCrop,
    crop,
    cropDetailsLabel,
    field,
    fileName,
    image,
    setFieldError,
    setFieldValue
  }) {
    const canvas = document.createElement('canvas')
    const pixelRatio = window.devicePixelRatio
    const scaleX = image.naturalWidth / image.width
    const scaleY = image.naturalHeight / image.height
    const ctx = canvas.getContext('2d')

    canvas.width = crop.width * pixelRatio * scaleX
    canvas.height = crop.height * pixelRatio * scaleY

    ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0)
    ctx.imageSmoothingQuality = 'high'

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width * scaleX,
      crop.height * scaleY
    )

    return new Promise(resolve => {
      canvas.toBlob(
        blob => {
          if (!blob) {
            // Canvas is empty

            return
          }

          blob.name = fileName

          const cropDetailsLabelToUse = cropDetailsLabel || 'crop_details'

          setFieldError(field.name, undefined)
          setFieldValue(`${field.name}_filename`, fileName)
          setFieldValue('has_attached_image', true)
          setFieldValue(cropDetailsLabelToUse, {
            ...crop,
            circularCrop,
            imageWidth: image.width,
            imageHeight: image.height
          })

          window.URL.revokeObjectURL(this.fileUrl)
          this.fileUrl = window.URL.createObjectURL(blob)
          resolve(this.fileUrl)
        },
        'image/png',
        1
      )
    })
  }

  render() {
    const { fileName, isExpanded, src } = this.state
    const cropFromState = { ...this.state.crop }
    const {
      accept,
      buttonText = 'Choose a file',
      circularCrop,
      cropDetailsLabel,
      cropOpts = {},
      expandable,
      expandButtonText,
      field,
      form: { errors },
      fullWidth,
      label,
      onChange,
      placeholder = 'Upload a file',
      previewImage,
      requiredField,
      setFieldError,
      setFieldValue,
      subLabel,
      whiteButton,
      ...props
    } = this.props

    const cropAspect = cropOpts.aspect || cropFromState.aspect || 1
    const aspect = cropOpts.aspect === 'none' ? null : cropAspect
    const crop = { ...cropFromState, aspect }

    // You're not allowed to put a value prop on input[type="file"]
    // eslint-disable-next-line no-unused-vars
    const { value, ...fieldWithoutValue } = field
    const displayableLabel = requiredField ? `${label} *` : label
    const hasError = errors[field.name]

    // show expand button when needed, unless the field has a value already
    if (!isExpanded && expandable && !field.value) {
      return (
        <div>
          <Label htmlFor={field.name}>{displayableLabel}</Label>
          {subLabel && <SubLabel subLabel={subLabel}>{subLabel}</SubLabel>}

          <LinkButton disabled={props.disabled} onClick={() => this.expandInputArea()}>
            <PlusIconWrapper>
              <PlusSign />
            </PlusIconWrapper>

            <span>{expandButtonText}</span>
          </LinkButton>
        </div>
      )
    }

    return (
      <Wrapper fullWidth={fullWidth}>
        {hasError ? (
          <Error>{hasError}</Error>
        ) : label ? (
          <Label htmlFor={field.name}>{displayableLabel}</Label>
        ) : null}

        {subLabel && (
          <SubLabelWrapper subLabel={subLabel}>
            <SubLabel subLabel={subLabel}>{subLabel}</SubLabel>
          </SubLabelWrapper>
        )}

        <RequiredTag hasError={hasError} label={label} requiredField={requiredField} />

        <InputWrapper
          disabled={props.disabled}
          requiredField={requiredField}
          tabIndex="-1"
          hasError={!!hasError}
        >
          <input
            accept={accept}
            {...fieldWithoutValue}
            {...props}
            id={field.name}
            onChange={async e => {
              await handleFileChange({
                e,
                field,
                onChange,
                setFieldError,
                setFieldValue,
                setState: this.setState.bind(this)
              })
            }}
            style={{ display: 'none' }}
            type="file"
          />

          {src && <ValueLabel>{fileName}</ValueLabel>}
          {!src && <Placeholder>{placeholder}</Placeholder>}

          <ConditionalDisplay displayWhen={[src]}>
            <SmallButtonLabelSecondary
              disabled={props.disabled}
              requiredField={requiredField}
              fileUpload
              type="button"
              onClick={() => {
                this.setState({ fileName: null, src: null })
                setFieldError(field.name, undefined)
                setFieldValue(field.name, undefined)
                setFieldValue('img_preview_b64', undefined)
              }}
              whiteButton={whiteButton}
            >
              Remove {accept === 'application/pdf' ? 'File' : 'Image'}
            </SmallButtonLabelSecondary>
          </ConditionalDisplay>

          <ConditionalDisplay displayWhen={[!src]}>
            <SmallButtonLabelSecondary
              disabled={props.disabled}
              fileUpload
              htmlFor={field.name}
              requiredField={requiredField}
              whiteButton={whiteButton}
            >
              {buttonText}
            </SmallButtonLabelSecondary>
          </ConditionalDisplay>
        </InputWrapper>

        {/* For ReactCrop upload crop previews with a single image */}
        <ConditionalDisplay displayWhen={[src, previewImage]}>
          <ReactCrop
            circularCrop={circularCrop}
            crop={crop}
            disabled={props.disabled}
            keepSelection
            onChange={this.onCropChange}
            onComplete={crop =>
              this.onCropComplete({
                circularCrop,
                crop,
                cropDetailsLabel,
                field,
                setFieldError,
                setFieldValue
              })
            }
            onImageLoaded={image => {
              this.onImageLoaded(image)

              // So that a crop circle can apply upon image load
              setTimeout(() => {
                const imageClientWidth = image.clientWidth || 100
                const cropWidth = Math.floor(imageClientWidth / 2)
                const x = Math.floor(imageClientWidth / 4)
                const newCrop = { ...crop, width: cropWidth, height: cropWidth, x, y: 5 }

                // update FileUpload state
                this.setState({ crop: newCrop })

                // update crop_details for the form so if submitted untouched there
                // will be default crop_details values
                setFieldValue('crop_details', {
                  ...newCrop,
                  circularCrop,
                  imageWidth: image.width,
                  imageHeight: image.height
                })
              }, 100)
            }}
            src={src}
          />
        </ConditionalDisplay>

        {/* Preview for single image upload */}
        <ConditionalDisplay displayWhen={[!src, previewImage]}>
          <PreviewImage circularCrop={circularCrop} src={previewImage} />
        </ConditionalDisplay>
      </Wrapper>
    )
  }
}

export default FileUpload
