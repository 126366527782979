import axios from 'axios'

// config
import baseUrl from '../config'

export const fetchInstagramBioLinks = async () => {
  const response = await axios.get(`${baseUrl}/instagram-bio-links`)
  return response.data
}

export const createInstagramBioLink = async data => {
  const response = await axios({
    withCredentials: true,
    method: 'post',
    url: `${baseUrl}/instagram-bio-links`,
    data
  })

  return response.data
}

export const deleteInstagramBioLink = async bioLinkId => {
  const response = await axios.delete(`${baseUrl}/instagram-bio-links/${bioLinkId}`, {
    withCredentials: true,
    data: { bioLinkId }
  })
  return response
}
