import axios from 'axios'

// config
import baseUrl from '../config'

export const addDonationSubscription = (data = {}) => {
  const url = `${baseUrl}/donation-subscriptions`
  return axios.post(url, { withCredentials: true, data })
}

export const updateDonationSubscriptions = async (data = {}) => {
  const url = `${baseUrl}/donation-subscriptions`
  const result = await axios.put(url, { withCredentials: true, data })

  return result.data
}

export const fetchDonationSubscriptions = async params => {
  const url = `${baseUrl}/donation-subscriptions`
  const result = await axios.get(url, { withCredentials: true, params })

  return result.data
}
