import React from 'react'
import { Outer, CloseIcon, Wrapper, OuterWrapper } from './styles'

const Tag = props => (
  <OuterWrapper {...props}>
    <Wrapper {...props}>
      <Outer requiredField={props.requiredField}>
        {props.children}
        {!props.immutable && <CloseIcon size={15} onClick={props.handleClick} />}
      </Outer>
    </Wrapper>
  </OuterWrapper>
)

export default Tag
