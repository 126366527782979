import { call, put, takeLatest } from 'redux-saga/effects'
import { navigate } from '@reach/router'
import * as platformSubscriptionsApi from '../apiModules/platformSubscriptions'
import * as platformSubscriptionsModule from '../modules/platformSubscriptions'

function* generatePaymentIdentifier({ payload }) {
  try {
    const response = yield call(platformSubscriptionsApi.generatePaymentIdentifier, payload)
    yield put(platformSubscriptionsModule.generatePaymentIdentifierSuccess(response))
  } catch (err) {
    yield put(platformSubscriptionsModule.generatePaymentIdentifierFailure())
  }
}

function* recordPayment({ payload }) {
  try {
    yield call(platformSubscriptionsApi.confirmSuccessfulPlatformPayment, payload)
    yield put(platformSubscriptionsModule.recordPaymentSuccess(payload))

    if (payload.mockPayment) {
      navigate(`${window.location.search}&isSuccess=true`, { replace: true })
      window.location.reload()
    }
  } catch (err) {
    yield put(platformSubscriptionsModule.generatePaymentIdentifierFailure(err))
  }
}

function* recordPaymentFailure({ payload }) {
  try {
    yield call(platformSubscriptionsApi.recordFailedPayment, payload)
    yield put(platformSubscriptionsModule.generatePaymentIdentifierFailure())
  } catch (err) {
    yield put(platformSubscriptionsModule.generatePaymentIdentifierFailure())
  }
}

function* cancelPlatformSubscription({ payload }) {
  try {
    yield call(platformSubscriptionsApi.cancelPlatformSubscription, payload)
    window.location.reload()
  } catch (err) {
    yield put(platformSubscriptionsModule.cancelPlatformSubscriptionFailure())
  }
}

function* fetchPlatformSubscription({ payload }) {
  try {
    const result = yield call(platformSubscriptionsApi.fetchPlatformSubscription, payload)
    yield put(platformSubscriptionsModule.fetchPlatformSubscriptionSuccess(result))
  } catch (err) {
    yield put(platformSubscriptionsModule.fetchPlatformSubscriptionFailure())
  }
}

export const platformSubscriptionSagas = [
  takeLatest(platformSubscriptionsModule.GENERATE_PAYMENT_IDENTIFIER, generatePaymentIdentifier),
  takeLatest(platformSubscriptionsModule.RECORD_PAYMENT_FAILURE, recordPaymentFailure),
  takeLatest(platformSubscriptionsModule.RECORD_PAYMENT, recordPayment),
  takeLatest(platformSubscriptionsModule.FETCH_PLATFORM_SUBSCRIPTION, fetchPlatformSubscription),
  takeLatest(platformSubscriptionsModule.CANCEL_PLATFORM_SUBSCRIPTION, cancelPlatformSubscription)
]
