import axios from 'axios'

// config
import baseUrl from '../config'

export const fetchFaq = async id => {
  const response = await axios.get(`${baseUrl}/faqs/${id}`, { withCredentials: true })
  return response.data
}

export const fetchFaqsForOrgAdmin = async params => {
  const { organisationId } = params

  const responseData = await axios(`${baseUrl}/faqs-for-organisation/${organisationId}/all`, {
    withCredentials: true,
    method: 'GET',
    params,
    responseType: 'json'
  })

  return responseData
}

export const fetchFaqs = async params => {
  const { organisationId } = params
  const response = await axios.get(`${baseUrl}/faqs-for-organisation/${organisationId}`)

  return response.data
}

export const createFaq = async data => {
  const response = await axios({
    withCredentials: true,
    method: 'post',
    url: `${baseUrl}/faqs`,
    data
  })

  return response.data
}

export const updateFaq = async data => {
  const response = await axios({
    withCredentials: true,
    method: 'put',
    url: `${baseUrl}/faqs/${data.faqId}`,
    data
  })

  return response.data
}

export const deleteFaq = async ({ faqId }) => {
  return await axios.delete(`${baseUrl}/faqs/${faqId}`, { withCredentials: true })
}
