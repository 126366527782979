import React from 'react'
import {
  DashboardWrapper,
  DashboardContainer,
  DashboardContentContainer,
  TitleSkeleton,
  ContentSkeleton
} from './styles'

export default () => (
  <DashboardWrapper>
    <DashboardContainer>
      <DashboardContentContainer>
        <TitleSkeleton />
        <ContentSkeleton />
      </DashboardContentContainer>
    </DashboardContainer>
  </DashboardWrapper>
)
