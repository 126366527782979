import axios from 'axios'

// config
import baseUrl from '../config'

// utils
import cleanPayload from 'utils/cleanPayload'

export const fetchPersonalGoals = async (payload = {}) => {
  const cleanedPayload = cleanPayload(payload)

  const data = await axios(`${baseUrl}/goals`, {
    withCredentials: true,
    method: 'GET',
    responseType: 'json',
    params: { ...cleanedPayload }
  })

  return data
}

export const createPersonalGoal = async data => {
  return await axios({
    withCredentials: true,
    method: 'post',
    url: `${baseUrl}/goals`,
    data,
    config: { headers: { 'Content-Type': 'multipart/form-data' } }
  })
}

export const fetchPersonalGoal = async ({ personalGoalId }) => {
  const response = await axios(`${baseUrl}/goals/${personalGoalId}`, {
    withCredentials: true,
    method: 'GET',
    responseType: 'json'
  })

  return response.data
}

export const updatePersonalGoal = async personalGoal => {
  const response = await axios({
    withCredentials: true,
    method: 'put',
    url: `${baseUrl}/goals/${personalGoal.get('personalGoalId')}`,
    data: personalGoal,
    config: { headers: { 'Content-Type': 'multipart/form-data' } }
  })
  return response.data
}

export const deletePersonalGoal = async personalGoalId => {
  return await axios.delete(`${baseUrl}/goals`, { data: { personalGoalId }, withCredentials: true })
}
