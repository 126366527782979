import React, { Fragment } from 'react'
import { Formik, Field } from 'formik'

// config
import constants from 'config/constants'
import l, { c, isDgmt, isSef, isSefEmployee } from 'config/localization'

// utils
import checkRoles from 'utils/sef/checkRoles'
import logos from 'utils/logos'

// partials
import DgmtSidebar from './DgmtSidebar'
import SefSidebar from './SefSidebar'

// assets and styles
import { SolidHeart } from 'components/Icons/Icons'
import { ReactComponent as OppIcon } from 'assets/images/svgs/icon-org-page-opportunity.svg'
import { ReactComponent as DonationIcon } from 'assets/images/cause-type-icons/money_icon_dark.svg'
import { ReactComponent as OrgIcon } from 'assets/images/svgs/icon-org-page-organisation.svg'
import { ReactComponent as BpEventsIcon } from 'assets/images/svgs/icon-bp-events.svg'
import { ReactComponent as EmailActivityIcon } from 'assets/images/svgs/icon-email-activity.svg'
import { ReactComponent as GoalIcon } from 'assets/images/icons/opp-goal.svg'

import {
  BrowniePointsLogo,
  DashboardHeading,
  DashboardMobileHeading,
  DashboardHomePageMobileContainer,
  DashboardSideNavOuter,
  DashboardSettingsWrapper,
  DashSettingsIcon,
  DashSidebarConfigOption,
  FooterLink,
  NavItems,
  SidebarFooterLogoWrapper
} from './styles'

import { PeopleFill as People, Receipt } from '@styled-icons/bootstrap'
import { PeopleCircle } from '@styled-icons/ionicons-solid/PeopleCircle'
import { DashboardSideNav, DashboardNavItem } from '../Dashboard'
import { Wrench } from 'components/Icons/Icons'
import { GiftFill } from '@styled-icons/bootstrap/GiftFill'
import { Calendar2Range } from '@styled-icons/bootstrap/Calendar2Range'
import { JournalText } from '@styled-icons/bootstrap/JournalText'
import { Rocket } from '@styled-icons/entypo/Rocket'
import { NotificationsActive } from '@styled-icons/material/NotificationsActive'
import { Dashboard } from '@styled-icons/boxicons-solid'
import { TrophyFill } from '@styled-icons/bootstrap/TrophyFill'
import { Team } from '@styled-icons/remix-fill'
import { OpenBook } from '@styled-icons/entypo/OpenBook'

// Load components synchronously
import CheckboxInput from 'components/CheckboxInput'
import ConditionalDisplay from 'components/ConditionalDisplay/ConditionalDisplay'
import LinkButton from 'components/SmallButtonPrimary/LinkButton'
import SmallButtonPrimary from 'components/SmallButtonPrimary/SmallButtonPrimary'

const { dashSidebarLogo } = logos

const DashboardSidebar = props => {
  const {
    dashboardSettingsVisible,
    displayBpNavbar,
    displayFormBar,
    displayGsHeader,
    fetchNotifications,
    locationInfo,
    notifications = [],
    toggleDashSidebarSettings,
    roles = [],
    updateUser,
    user = {}
  } = props

  if (displayFormBar) return null
  if (!displayGsHeader && !displayBpNavbar) return null

  const showPremiumFeatures = user.showPremiumFeatures
  const hasMembershipPending = roles.some(r => r.role_type === constants.ROLES.MEMBER_PENDING)
  const hasGodRole = roles.some(r => r.role_type === constants.ROLES.GOD_MODE)
  const isSuperAdmin = roles.some(
    r => r.roleable === constants.ROLEABLES.ORG && r.role_type === constants.ROLES.SUPERADMIN
  )

  if (isDgmt) {
    return <DgmtSidebar {...props} />
  }

  if (isSef || isSefEmployee) {
    return <SefSidebar {...props} />
  }

  const { isPlatformAdmin } = checkRoles({ roleData: roles })
  const hasPrivilegedRole = isPlatformAdmin || hasGodRole
  const hasPrivilegedOrSuperAdminRole = hasPrivilegedRole || isSuperAdmin

  // search codebase for required changes when changing this: TEMP_DISABLED_GOALS
  const enableGoals = false

  const showTeamsIcon = hasPrivilegedOrSuperAdminRole || hasMembershipPending
  const hasAnyNotifications = notifications.filter(n => n.status === 'Unread').length

  const dashboardConfig = user.dashboardConfig || {}

  if (dashboardSettingsVisible) {
    return (
      <Fragment>
        <DashboardHeading>Select the dashboard navigation items you want to see</DashboardHeading>

        <DashboardSideNavOuter dashboardSettingsVisible displayMobile={locationInfo.homeActive}>
          <DashboardSettingsWrapper>
            <Formik
              initialValues={{
                user_sidebar_config_update: true,
                user_id: user.id,
                ...dashboardConfig
              }}
              enableReinitialize
              validateOnChange={false}
              validateOnBlur={false}
              onSubmit={values => {
                updateUser(values)
                toggleDashSidebarSettings()
              }}
              render={({ handleSubmit }) => {
                return (
                  <form onSubmit={handleSubmit}>
                    <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                      <DashSidebarConfigOption>
                        <Field disabled name="dash_sidenav_home" component={CheckboxInput} />
                        <Dashboard />
                        <span>Dashboard Home</span>
                      </DashSidebarConfigOption>

                      <DashSidebarConfigOption>
                        <Field name="dash_sidenav_notifs" component={CheckboxInput} />
                        <NotificationsActive />
                        <span>Notifications</span>
                      </DashSidebarConfigOption>

                      <ConditionalDisplay displayWhen={[hasPrivilegedRole]}>
                        <DashSidebarConfigOption>
                          <Field name="dash_sidenav_email_activity" component={CheckboxInput} />
                          <EmailActivityIcon />
                          <span>Email activity</span>
                        </DashSidebarConfigOption>
                      </ConditionalDisplay>

                      <ConditionalDisplay displayWhen={[false, hasPrivilegedRole]}>
                        <DashSidebarConfigOption>
                          <Field name="dash_sidenav_playbooks" component={CheckboxInput} />
                          <EmailActivityIcon />
                          <span>Playbooks</span>
                        </DashSidebarConfigOption>
                      </ConditionalDisplay>

                      <ConditionalDisplay displayWhen={[hasPrivilegedRole]}>
                        <DashSidebarConfigOption>
                          <Field name="dash_sidenav_bp_events" component={CheckboxInput} />
                          <BpEventsIcon />
                          <span>BP events</span>
                        </DashSidebarConfigOption>
                      </ConditionalDisplay>

                      <DashSidebarConfigOption>
                        <Field name="dash_sidenav_stories" component={CheckboxInput} />
                        <JournalText />
                        <span>Stories</span>
                      </DashSidebarConfigOption>

                      <ConditionalDisplay displayWhen={[enableGoals]}>
                        <DashSidebarConfigOption>
                          <Field name="dash_sidenav_goals" component={CheckboxInput} />
                          <GoalIcon />
                          <span>Goals</span>
                        </DashSidebarConfigOption>
                      </ConditionalDisplay>

                      <DashSidebarConfigOption>
                        <Field name="dash_sidenav_challenges" component={CheckboxInput} />
                        <TrophyFill />
                        <span>Challenges</span>
                      </DashSidebarConfigOption>

                      <DashSidebarConfigOption>
                        <Field name="dash_sidenav_contributions" component={CheckboxInput} />
                        <GiftFill />
                        <span>Contributions</span>
                      </DashSidebarConfigOption>

                      <DashSidebarConfigOption>
                        <Field name="dash_sidenav_likes" component={CheckboxInput} />
                        <SolidHeart />
                        <span>Likes</span>
                      </DashSidebarConfigOption>

                      <DashSidebarConfigOption>
                        <Field name="dash_sidenav_memberships" component={CheckboxInput} />
                        <DonationIcon />
                        <span>Memberships</span>
                      </DashSidebarConfigOption>

                      <DashSidebarConfigOption>
                        <Field name="dash_sidenav_opps" component={CheckboxInput} />
                        <OppIcon />
                        <span>{c(l('OPPS'))}</span>
                      </DashSidebarConfigOption>

                      <ConditionalDisplay displayWhen={[hasPrivilegedOrSuperAdminRole]}>
                        <DashSidebarConfigOption>
                          <Field name="dash_sidenav_camps" component={CheckboxInput} />
                          <Calendar2Range />
                          <span>{c(l('CAMPS'))}</span>
                        </DashSidebarConfigOption>
                      </ConditionalDisplay>

                      <DashSidebarConfigOption>
                        <Field name="dash_sidenav_orgs" component={CheckboxInput} />
                        <OrgIcon />
                        <span>{c(l('ORGS'))}</span>
                      </DashSidebarConfigOption>

                      <ConditionalDisplay displayWhen={[isSuperAdmin, showPremiumFeatures]}>
                        <DashSidebarConfigOption>
                          <Field name="dash_sidenav_boosts" component={CheckboxInput} />
                          <Rocket />
                          <span>Boosts</span>
                        </DashSidebarConfigOption>
                      </ConditionalDisplay>

                      <ConditionalDisplay displayWhen={[hasPrivilegedOrSuperAdminRole]}>
                        <DashSidebarConfigOption>
                          <Field name="dash_sidenav_people" component={CheckboxInput} />
                          <People />
                          <span>People</span>
                        </DashSidebarConfigOption>
                      </ConditionalDisplay>

                      <ConditionalDisplay displayWhen={[showTeamsIcon]}>
                        <DashSidebarConfigOption>
                          <Field name="dash_sidenav_teams" component={CheckboxInput} />
                          <Team />
                          <span>Teams</span>
                        </DashSidebarConfigOption>
                      </ConditionalDisplay>

                      <DashSidebarConfigOption>
                        <Field disabled name="dash_sidenav_squads" component={CheckboxInput} />
                        <PeopleCircle />
                        <span>
                          Squads{' '}
                          <span style={{ fontSize: '10px', textTransform: 'lowercase' }}>
                            (coming soon)
                          </span>{' '}
                        </span>
                      </DashSidebarConfigOption>

                      <ConditionalDisplay displayWhen={[isSuperAdmin]}>
                        <DashSidebarConfigOption>
                          <Field disabled name="dash_sidenav_billing" component={CheckboxInput} />
                          <Receipt />
                          <span>Billing</span>
                        </DashSidebarConfigOption>
                      </ConditionalDisplay>

                      <DashSidebarConfigOption>
                        <Field disabled name="dash_sidenav_account" component={CheckboxInput} />
                        <Wrench />
                        <span>Account</span>
                      </DashSidebarConfigOption>
                    </div>

                    <div style={{ marginBottom: '10px', marginTop: '20px' }}>
                      <DashSidebarConfigOption>
                        <Field
                          name="dash_show_completed_and_dismissed_onboarding_tasks"
                          component={CheckboxInput}
                        />

                        <span>Show completed and dismissed onboarding challenges</span>
                      </DashSidebarConfigOption>
                    </div>

                    <div style={{ display: 'flex', marginTop: '10px' }}>
                      <div style={{ marginRight: '5px' }}>
                        <SmallButtonPrimary type="submit">Save</SmallButtonPrimary>
                      </div>

                      <LinkButton onClick={toggleDashSidebarSettings} type="button">
                        Cancel
                      </LinkButton>
                    </div>
                  </form>
                )
              }}
            />
          </DashboardSettingsWrapper>
        </DashboardSideNavOuter>
      </Fragment>
    )
  }

  return (
    <Fragment>
      <DashboardHomePageMobileContainer displayMobile={locationInfo.homeActive}>
        <DashboardMobileHeading>Dashboard Menu</DashboardMobileHeading>
        <DashSettingsIcon toggleDashSidebarSettings={toggleDashSidebarSettings} />
      </DashboardHomePageMobileContainer>

      <DashboardSideNavOuter displayMobile={locationInfo.homeActive}>
        <DashboardSideNav displayMobile={locationInfo.homeActive}>
          <NavItems id="dashboard-menu-items-wrapper" displayMobile={locationInfo.homeActive}>
            <DashboardNavItem
              hiddenOnMobile={locationInfo.homeActive}
              Icon={Dashboard}
              hover="exception"
              id={locationInfo.homeActive ? 'active-dash-nav-item' : undefined}
              isActive={locationInfo.homeActive}
              label="Dashboard"
              to="/dashboard"
            />

            <DashboardNavItem
              hidden={!dashboardConfig.dash_sidenav_notifs}
              hover="exception"
              Icon={NotificationsActive}
              id={locationInfo.notificationsActive ? 'active-dash-nav-item' : undefined}
              isActive={locationInfo.notificationsActive}
              label="Notifications"
              onClick={() => fetchNotifications()}
              showNotificationDot={hasAnyNotifications}
              to={'/dashboard/notifications'}
            />

            <DashboardNavItem
              hidden={!dashboardConfig.dash_sidenav_playbooks || true}
              label="Playbooks"
              Icon={OpenBook}
              to={'/dashboard/playbooks'}
              isActive={locationInfo.playbooksPathActive}
              id={locationInfo.playbooksPathActive ? 'active-dash-nav-item' : undefined}
            />

            <ConditionalDisplay displayWhen={[hasPrivilegedRole]}>
              <DashboardNavItem
                hidden={!dashboardConfig.dash_sidenav_email_activity}
                label="Email activity"
                Icon={EmailActivityIcon}
                to={'/dashboard/email-activity'}
                isActive={locationInfo.emailActivityPathActive}
                id={locationInfo.emailActivityPathActive ? 'active-dash-nav-item' : undefined}
              />
            </ConditionalDisplay>

            <ConditionalDisplay displayWhen={[hasPrivilegedRole]}>
              <DashboardNavItem
                hidden={!dashboardConfig.dash_sidenav_bp_events}
                label="BP events"
                Icon={BpEventsIcon}
                to={'/dashboard/platform-events'}
                isActive={locationInfo.platformEventsPathActive}
                id={locationInfo.platformEventsPathActive ? 'active-dash-nav-item' : undefined}
              />
            </ConditionalDisplay>

            <DashboardNavItem
              hidden={!dashboardConfig.dash_sidenav_stories}
              label="Stories"
              Icon={JournalText}
              to={'/dashboard/stories'}
              isActive={locationInfo.activeStoriesPath}
              id={locationInfo.activeStoriesPath ? 'active-dash-nav-item' : undefined}
            />

            <ConditionalDisplay displayWhen={[enableGoals]}>
              <DashboardNavItem
                hidden={!dashboardConfig.dash_sidenav_goals}
                label="Goals"
                Icon={GoalIcon}
                to={'/dashboard/goals'}
                isActive={locationInfo.activeGoalsPath}
                id={locationInfo.activeGoalsPath ? 'active-dash-nav-item' : undefined}
              />
            </ConditionalDisplay>

            <DashboardNavItem
              hidden={!dashboardConfig.dash_sidenav_challenges}
              label="Challenges"
              Icon={TrophyFill}
              to={'/dashboard/challenges'}
              isActive={locationInfo.activeChallengesPath}
              id={locationInfo.activeChallengesPath ? 'active-dash-nav-item' : undefined}
            />

            <DashboardNavItem
              hidden={!dashboardConfig.dash_sidenav_contributions}
              label="Contributions"
              Icon={GiftFill}
              to={'/dashboard/contributions'}
              isActive={locationInfo.activeContributionsPath}
              id={locationInfo.activeContributionsPath ? 'active-dash-nav-item' : undefined}
            />

            <DashboardNavItem
              hidden={!dashboardConfig.dash_sidenav_likes}
              label="Likes"
              Icon={SolidHeart}
              to={'/dashboard/likes'}
              isActive={locationInfo.activeLikesPath}
              id={locationInfo.activeLikesPath ? 'active-dash-nav-item' : undefined}
            />

            <DashboardNavItem
              hidden={!dashboardConfig.dash_sidenav_memberships}
              label="Memberships"
              Icon={DonationIcon}
              to={'/dashboard/memberships'}
              isActive={locationInfo.activePersonalMembershipsPath}
              id={locationInfo.activePersonalMembershipsPath ? 'active-dash-nav-item' : undefined}
            />

            <DashboardNavItem
              hidden={!dashboardConfig.dash_sidenav_opps}
              label={c(l('OPPS'))}
              Icon={OppIcon}
              to={`/dashboard/${l('ROUTE_OPPS')}`}
              isActive={locationInfo.opportunitiesActive}
              id={locationInfo.opportunitiesActive ? 'active-dash-nav-item' : undefined}
            />

            <ConditionalDisplay displayWhen={[hasPrivilegedOrSuperAdminRole]}>
              <DashboardNavItem
                hidden={!dashboardConfig.dash_sidenav_camps}
                label={`${c(l('CAMPS'))}`}
                Icon={Calendar2Range}
                to={`/dashboard/${l('ROUTE_CAMPS')}`}
                isActive={locationInfo.campaignsActive}
                id={locationInfo.campaignsActive ? 'active-dash-nav-item' : undefined}
                marginLeft="-2px"
                size="24px"
              />
            </ConditionalDisplay>

            <DashboardNavItem
              hidden={!dashboardConfig.dash_sidenav_orgs}
              label={`${c(l('ORGS'))}`}
              Icon={OrgIcon}
              to={`/dashboard/${l('ROUTE_ORGS')}`}
              isActive={locationInfo.organisationsActive}
              id={locationInfo.organisationsActive ? 'active-dash-nav-item' : undefined}
            />

            <ConditionalDisplay displayWhen={[isSuperAdmin, showPremiumFeatures]}>
              <DashboardNavItem
                hidden={!dashboardConfig.dash_sidenav_boosts}
                label="Boosts"
                Icon={Rocket}
                to={'/dashboard/boosts'}
                isActive={locationInfo.boostsActive}
                id={locationInfo.boostsActive ? 'active-dash-nav-item' : undefined}
              />
            </ConditionalDisplay>

            <ConditionalDisplay displayWhen={[hasPrivilegedOrSuperAdminRole]}>
              <DashboardNavItem
                hidden={!dashboardConfig.dash_sidenav_people}
                label="People"
                Icon={People}
                hover="exception"
                to={'/dashboard/people'}
                isActive={locationInfo.peopleActive}
                id={locationInfo.peopleActive ? 'active-dash-nav-item' : undefined}
              />
            </ConditionalDisplay>

            <ConditionalDisplay displayWhen={[showTeamsIcon]}>
              <DashboardNavItem
                hidden={!dashboardConfig.dash_sidenav_teams}
                label="Teams"
                Icon={Team}
                to={'/dashboard/teams'}
                isActive={locationInfo.teamsActive}
                hover="exception"
                id={locationInfo.teamsActive ? 'active-dash-nav-item' : undefined}
                showNotificationDot={hasMembershipPending}
              />
            </ConditionalDisplay>

            <DashboardNavItem
              hidden={!dashboardConfig.dash_sidenav_squads}
              label="Squads"
              Icon={PeopleCircle}
              to={'/dashboard/squads'}
              isActive={locationInfo.squadsActive}
              hover="exception"
              id={locationInfo.squadsActive ? 'active-dash-nav-item' : undefined}
            />

            <ConditionalDisplay displayWhen={[isSuperAdmin]}>
              <DashboardNavItem
                label="Billing"
                Icon={Receipt}
                to={'/dashboard/billing'}
                isActive={locationInfo.billingActive}
                id={locationInfo.billingActive ? 'active-dash-nav-item' : undefined}
              />
            </ConditionalDisplay>

            <DashboardNavItem
              label="Account"
              hover="exception"
              Icon={Wrench}
              to={'/dashboard/account'}
              isActive={locationInfo.accountActive}
              id={locationInfo.accountActive ? 'active-dash-nav-item' : undefined}
            />
          </NavItems>
        </DashboardSideNav>

        <SidebarFooterLogoWrapper>
          <FooterLink to="/">
            <BrowniePointsLogo src={dashSidebarLogo} />
          </FooterLink>

          <DashSettingsIcon toggleDashSidebarSettings={toggleDashSidebarSettings} />
        </SidebarFooterLogoWrapper>
      </DashboardSideNavOuter>
    </Fragment>
  )
}

export default DashboardSidebar
