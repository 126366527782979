import { createActions, handleActions } from 'redux-actions'

// helpers
import { loadingError, startLoading } from './_helpers'

export const FETCH_EMAIL_ACTIVITY = 'emailActivitySagas/FETCH_EMAIL_ACTIVITY'
export const FETCH_EMAIL_ACTIVITY_FAILURE = 'emailActivitySagas/FETCH_EMAIL_ACTIVITY_FAILURE'
export const FETCH_EMAIL_ACTIVITY_SUCCESS = 'emailActivitySagas/FETCH_EMAIL_ACTIVITY_SUCCESS'

export const {
  emailActivitySagas: { fetchEmailActivity, fetchEmailActivitySuccess, fetchEmailActivityFailure }
} = createActions({
  [FETCH_EMAIL_ACTIVITY]: undefined,
  [FETCH_EMAIL_ACTIVITY_FAILURE]: undefined,
  [FETCH_EMAIL_ACTIVITY_SUCCESS]: undefined
})

const initialState = {
  data: {
    districtOptions: [],
    results: [],
    schoolOptions: [],
    subjectOptions: [],
    mostRecentRefresh: null
  },
  errorMessage: null,
  hasError: false,
  isLoading: false,
  isSubmitting: false,
  isSuccess: null
}

export default handleActions(
  {
    [FETCH_EMAIL_ACTIVITY]: startLoading,
    [FETCH_EMAIL_ACTIVITY_FAILURE]: loadingError,
    [FETCH_EMAIL_ACTIVITY_SUCCESS]: (state, { payload }) => ({
      ...state,
      isLoading: false,
      hasSubmitted: true,
      isSuccess: payload.forceRefresh ? true : null,
      hasError: false,
      data: payload.forceRefresh ? state.data : payload
    })
  },
  initialState
)
