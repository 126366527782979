import React, { Component } from 'react'
import { connect } from 'react-redux'
import { navigate } from '@reach/router'

// redux
import * as orgsModule from '../../redux/modules/organisations'

// assets
import { ExternalLinkAlt } from '@styled-icons/fa-solid'

// utils
import th from 'utils/themeHelper'

// icons
import { MessageSquareCheck } from 'components/Icons/Icons'

// styles
import {
  ButtonWrapper,
  ButtonsWrapper,
  CommitmentText,
  CloseIcon,
  CloseModalShortWrapper,
  ExternalLink,
  ModalContainer,
  ModalWrapper,
  ModalHeader,
  ScreenWrapper
} from './styles'

// Load components synchronously
import LinkButton from 'components/SmallButtonPrimary/LinkButton'

const checkDonationUrl = ({ externalDonationUrl }) => {
  if (process.env.NODE_ENV === 'development') {
    return externalDonationUrl.match(/^https?\:\/\/localhost:3000\/process-donation/)
  }

  if (process.env.NODE_ENV === 'staging') {
    return externalDonationUrl.match(/^https\:\/\/develop\.browniepoints\.africa/)
  }

  if (process.env.NODE_ENV === 'production') {
    return externalDonationUrl.match(/^https\:\/\/browniepoints\.africa/)
  }

  return false
}

class PendingModal extends Component {
  constructor(props) {
    super(props)
    this.escFunction = this.escFunction.bind(this)
    this.manageExternalRedirect = this.manageExternalRedirect.bind(this)
  }

  escFunction(event) {
    if (event.keyCode === 27) {
      this.props.hideModal()
    }
  }

  manageExternalRedirect() {
    const { extraInfoModalOpts } = this.props
    const { organisation } = extraInfoModalOpts

    // on org page, re-fetch stories
    if (organisation && organisation.url_name) {
      this.props.fetchOrgForPublicPage(organisation.url_name)
    }

    const urlSearch = window.location.search
      .replace('pending=true', 'hasRedirected=true')
      .replace(/\&$/, '')
    const path = window.location.pathname + urlSearch
    navigate(path, { replace: true })
  }

  componentDidMount() {
    document.addEventListener('keydown', this.escFunction, false)
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.escFunction, false)
  }

  render() {
    const { hideModal, extraInfoModalOpts } = this.props
    const { donationDetailId, externalDonationUrl = '' } = extraInfoModalOpts

    const currentPath = window.location.pathname.replace(/\/$/, '')
    const confirmPath = donationDetailId
      ? `${currentPath}/donations/${donationDetailId}`
      : `/dashboard/contributions?type=money`

    if (!externalDonationUrl) {
      return (
        <ScreenWrapper>
          <ModalWrapper>
            <ModalContainer>
              <CloseIcon dark size={30} onClick={hideModal} />

              <ModalHeader>Something went wrong</ModalHeader>

              <CommitmentText>
                Please reload this page and retry your donation attempt.
              </CommitmentText>

              <CloseModalShortWrapper>
                <LinkButton onClick={hideModal}>Dismiss</LinkButton>
              </CloseModalShortWrapper>
            </ModalContainer>
          </ModalWrapper>
        </ScreenWrapper>
      )
    }

    const isPayfastUrl = checkDonationUrl({ externalDonationUrl })
    if (isPayfastUrl) {
      return (
        <ScreenWrapper>
          <ModalWrapper>
            <ModalContainer>
              <CloseIcon dark size={30} onClick={hideModal} />

              <ModalHeader>Redirecting to complete payment.</ModalHeader>

              <CommitmentText>Please wait a few seconds.</CommitmentText>
            </ModalContainer>
          </ModalWrapper>
        </ScreenWrapper>
      )
    }

    const isOzowUrl = externalDonationUrl.match(/^https?\:\/\/pay\.ozow\.com/)
    if (isOzowUrl) {
      return (
        <ScreenWrapper>
          <ModalWrapper>
            <ModalContainer>
              <CloseIcon dark size={30} onClick={hideModal} />

              <ModalHeader>Redirecting to complete payment.</ModalHeader>

              <CommitmentText>
                You will be redirected to an external payment site now.
              </CommitmentText>
            </ModalContainer>
          </ModalWrapper>
        </ScreenWrapper>
      )
    }

    return (
      <ScreenWrapper>
        <ModalWrapper>
          <ModalContainer>
            <CloseIcon dark size={30} onClick={hideModal} />

            <ModalHeader>Complete your donation</ModalHeader>

            <CommitmentText>
              When you click the button below, you will be redirected to:{' '}
              <strong>{externalDonationUrl}</strong>
            </CommitmentText>

            <CommitmentText>
              Please make sure you trust the above website before completing any payments. Brownie
              Points has no affiliation with this website.
            </CommitmentText>

            <CommitmentText>
              If you have already made a donation payment, you can click the button to be redirected
              to your dashboard where you can request confirmation from the nonprofit.
            </CommitmentText>

            <ButtonsWrapper>
              <ButtonWrapper>
                <ExternalLink
                  href={externalDonationUrl}
                  target="_blank"
                  onClick={this.manageExternalRedirect}
                >
                  <LinkButton
                    overrideBg={th('primary.base')}
                    overrideColor="white"
                    icon={ExternalLinkAlt}
                    iconSize="15px"
                    iconMarginTop="-3px"
                  >
                    Visit External Website
                  </LinkButton>
                </ExternalLink>
              </ButtonWrapper>

              <LinkButton
                onClick={() => navigate(confirmPath)}
                icon={MessageSquareCheck}
                type="button"
              >
                Confirm Donation
              </LinkButton>
            </ButtonsWrapper>
          </ModalContainer>
        </ModalWrapper>
      </ScreenWrapper>
    )
  }
}

const mapDispatchToProps = dispatch => ({
  fetchOrgForPublicPage: orgName => dispatch(orgsModule.fetchOrgForPublicPage(orgName))
})

export default connect(null, mapDispatchToProps)(PendingModal)
