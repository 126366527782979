import axios from 'axios'

// redux config
import baseUrl from '../config'

export const fetchNotifications = async () => {
  const response = await axios(`${baseUrl}/notifications`, {
    withCredentials: true,
    method: 'GET',
    responseType: 'json'
  })

  return response.data
}

export const dismissNotification = async data => {
  const response = await axios({
    withCredentials: true,
    method: 'post',
    url: `${baseUrl}/notifications/${data.id}/dismiss`
  })

  return response.data
}
