import React from 'react'
import styled, { css } from 'styled-components'
import darken from 'polished/lib/color/darken'

// utils
import th from 'utils/themeHelper'

// components
import { CaretDown, CaretUp, Search } from '../Icons/Icons'
import Link from '../Link'

export const MainNavWrapper = styled.div`
  position: relative;
  min-height: 65px;
  z-index: 9999;
  width: 100%;
  position: fixed;
`

export const SearchIcon = styled(Search)`
  cursor: pointer;
  color: white;
  height: 18px;
  width: 18px;
  margin-left: 4px;
  margin-right: 4px;
`

export const NavInnerWrapper = styled.div`
  display: flex;
  flex: 1;
  height: 100%;
  justify-content: space-between;
`

export const NavOutterWrapper = styled.div`
  max-width: ${props => (props.isFixed ? '1390px' : '100%')};
  height: 100%;
  width: 100%;
`

export const MainNav = styled.nav`
  display: flex;
  align-items: center;
  height: 65px;
  justify-content: space-between;
  padding: 0 20px;
  border-width: 1px;
  border-style: solid;
  border-color: ${props => darken(0.1, props.theme.secondary.base)};
  background: ${props => darken(0.1, props.theme.secondary.base)};
  color: white;
  animation: ${props => (props.isLoading ? 'loader-spin 2000ms linear infinite' : 'none')};
  z-index: 99999;

  @-moz-keyframes loader-spin {
    0%,
    100% {
      border-color: ${props => darken(0.1, props.theme.secondary.base)};
      background: ${props => darken(0.1, props.theme.secondary.base)};
    }
    50% {
      border-color: ${props => darken(0.4, props.theme.secondary.base)};
      background: ${props => darken(0.4, props.theme.secondary.base)};
    }
  }

  @-webkit-keyframes loader-spin {
    0%,
    100% {
      border-color: ${props => darken(0.1, props.theme.secondary.base)};
      background: ${props => darken(0.1, props.theme.secondary.base)};
    }
    50% {
      border-color: ${props => darken(0.4, props.theme.secondary.base)};
      background: ${props => darken(0.4, props.theme.secondary.base)};
    }
  }

  @keyframes loader-spin {
    0%,
    100% {
      border-color: ${props => darken(0.1, props.theme.secondary.base)};
      background: ${props => darken(0.1, props.theme.secondary.base)};
    }
    50% {
      border-color: ${props => darken(0.4, props.theme.secondary.base)};
      background: ${props => darken(0.4, props.theme.secondary.base)};
    }
  }

  @media screen and (min-width: 600px) {
    align-items: flex-start;
    width: 100%;
    display: flex;
    margin-right: 144px;
    display: block;
  }

  @media screen and (min-width: 730px) {
    padding: 0 30px 0 88px;
  }

  @media screen and (min-width: 1024px) {
    padding: ${props => (props.isFixed ? '0 20px 0 88px' : '0 70px 0 20px')};
  }

  ${({ isFixed }) =>
    isFixed &&
    css`
      z-index: 99999;
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      height: 65px;
    `}
`

export const NavLeft = styled.div`
  display: none;
  flex-direction: row;
  align-items: center;

  @media screen and (min-width: 600px) {
    display: flex;
  }
`

export const NavCallToAction = styled.div`
  display: flex;
  align-items: center;
  flex: 1;
  justify-content: flex-end;

  @media screen and (min-width: 769px) {
    justify-content: flex-start;
    margin-left: 130px;
  }
`

export const DashboardNavLeft = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  @media screen and (max-width: 769px) {
    flex: 1;
  }
`

export const NavRight = styled.div`
  display: flex;
  align-items: center;

  @media screen and (min-width: 600px) {
    display: flex;
  }
`

export const FlatNavLink = styled.a`
  align-items: center;
  border-radius: 3px;
  border-width: 0;
  color: white;
  display: flex;
  flex-direction: row;
  margin-right: ${props => (props.padright ? '20px' : '8px')};
  padding: 12px;

  &:hover {
    background: ${props => (props.search ? 'white' : props.theme.backgrounds.light)};
    border-color: ${th('text.dark')};
    color: ${props => props.theme.backgrounds.dark};
  }

  &:hover ${SearchIcon} {
    color: ${props => props.theme.backgrounds.dark};
  }
`

export const NavLink = styled.a`
  color: ${props => (props.search ? 'white' : props.theme.backgrounds.dark)};
  display: flex;
  flex-direction: row;
  align-items: center;
  height: ${props => (props.search ? 'auto' : '60px')};
  padding: ${props => (props.search ? '5px 15px 5px 10px' : '0 10px')};
  border-width: ${props => (props.search ? '1px' : '0')};
  border-radius: 3px;
  border-style: solid;
  border-color: #f5f5f5;

  &:hover {
    background: ${props => (props.search ? 'white' : props.theme.backgrounds.light)};
    color: ${props => props.theme.backgrounds.dark};
    border-color: ${th('text.dark')};
  }

  &:hover ${SearchIcon} {
    color: ${props => props.theme.backgrounds.dark};
  }
`

export const LogoLink = styled.a`
  margin-top: 0;

  @media screen and (min-width: 869px) {
    position: ${props => (props.searchPage ? 'relative' : 'absolute')};
    left: ${props => (props.searchPage ? '0' : 'calc(50% - 60px)')};
    margin-top: 5px;
  }
`

export const DashNavLinkWrapper = styled.div`
  border-color: ${th('backgrounds.mid')};
  border-radius: 3px;
  border-style: solid;
  border-width: 1px;
  cursor: pointer;
  margin-left: 19px;
  color: white;

  @media screen and (max-width: 769px) {
    display: none;
  }

  &:hover,
  &:focus {
    background: initial;
    border-color: ${th('text.dark')};
  }
`

export const DashNavLink = styled(Link)`
  align-items: center;
  border-radius: 3px;
  display: flex;
  flex-direction: row;
  padding: 5px 15px 5px 10px;

  &:hover {
    background: ${props => (props.search ? 'white' : props.theme.backgrounds.light)};
    border-color: ${th('text.dark')};
    color: ${props => props.theme.backgrounds.dark};
  }

  &:hover ${SearchIcon} {
    color: ${props => props.theme.backgrounds.dark};
  }

  @media screen and (max-width: 769px) {
    display: none;
  }
`

export const DashNavLinkColorWrapper = styled.div`
  & > * {
    color: white;
  }
`

export const SmallLogo = styled.img`
  height: 40px;
  margin-top: 6px;

  @media screen and (min-width: 730px) {
    display: none;
  }
`

export const DashboardLogo = styled.img`
  height: 40px;
  margin-top: 3px;
  width: 28px;

  @media screen and (min-width: 730px) {
    margin-right: ${props => (props.isFixed ? '100px' : '0')};
  }
`

export const FullLogo = styled.img`
  height: 45px;

  @media screen and (min-width: 731px) {
    margin-top: 10px;
  }

  @media screen and (min-width: 869px) {
    margin-top: 5px;
  }

  @media screen and (max-width: 730px) {
    display: none;
  }
`

export const MobileOpenButton = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 75px;
  padding: 0 20px;
  color: ${props => props.theme.primary.base};
  cursor: pointer;

  @media screen and (min-width: 600px) {
    display: none !important;
  }
`

export const MobileNav = styled.div`
  background: white;
  display: none;
  flex-direction: column;
  position: fixed;
  top: 65px;
  left: 0;
  right: 0;

  @media screen and (max-width: 600px) {
    display: ${({ isOpen }) => (isOpen ? 'flex' : null)};
  }
`

export const DropdownIcon = styled(CaretDown)`
  color: ${props => props.theme.primary.dark};
  margin-top: -2px;
  height: 25px;
  width: 25px;
`

export const CloseMobileMenuIcon = styled(CaretUp)`
  cursor: pointer;
  color: ${th('primary.base')};
  height: 25px;
  width: 25px;
`

export const MobileMenuFooter = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 40px;
`

export const AccountMenuContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  margin-top: 0;
  height: 100%;
`

export const AccountMenu = styled.div`
  background-color: white;
  border: 1px solid ${th('borders.default')};
  border-radius: 4px;
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.2);
  position: absolute;
  right: 0;
  top: 55px;
  width: 250px;
  z-index: 9999;
`

export const ReminderNotification = styled.div`
  position: absolute;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  top: ${props => (props.inMenu ? '200px' : '13px')};
  left: ${props => (props.inMenu ? '145px' : '60px')};
  background: ${th('borders.error')};
`

export const MenuNavLink = styled(NavLink)`
  color: ${props => props.theme.backgrounds.dark};
  padding: 15px 0 15px 30px;
  position: relative;

  &:not(:last-child) {
    border-bottom: 1px solid ${th('borders.default')};
  }
`

export const AvatarWrapper = styled.div`
  background: ${props => props.theme.backgrounds.dark};
  border: 1px solid ${th('borders.default')};
  border-radius: 50%;
  display: inline-block;
  overflow: hidden;
  width: 30px;
  height: 30px;
  position: relative;
`

export const Avatar = styled.img`
  height: 100%;
  width: 40px;
  background: ${props => (props.hasAvatar ? th('backgrounds.dark') : 'white')};
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  object-fit: cover;
`

export const ArrowDownIcon = props => {
  const DownIcon = styled(CaretDown)`
    color: white;
    margin-top: -2px;
    height: 18px;
    width: 18px;
    pointer-events: none;

    @media screen and (max-width: 769px) {
      margin-left: 5px;
    }
  `

  return <DownIcon {...props} />
}

export const ArrowUpIcon = props => {
  const UpIcon = styled(CaretUp)`
    color: white;
    margin-top: -2px;
    height: 18px;
    pointer-events: none;
    width: 18px;

    @media screen and (max-width: 769px) {
      margin-left: 5px;
    }
  `

  return <UpIcon {...props} />
}

export const Text = styled.p`
  margin: 0;
`

export const Username = styled(Text)`
  margin: 0px 10px;

  @media screen and (max-width: 769px) {
    display: none;
  }
`

export const Clickable = styled.div`
  align-items: center;
  color: ${props => (props.InMenu ? props.theme.backgrounds.dark : 'white')};
  cursor: pointer;
  display: flex;
  height: 60px;
  padding: 15px 0 15px 30px;
  position: relative;

  &:hover {
    background: ${props => (props.InMenu ? props.theme.backgrounds.light : 'initial')};
  }
`
