import { takeLatest, call, put } from 'redux-saga/effects'

import * as donationConfirmationsApi from '../apiModules/donationConfirmations'
import * as donoConfirmModule from '../modules/donationConfirmations'
import * as authModule from '../modules/authentication'

// utils
import isLoggedIn from '../../utils/auth'

function* fetchMoneyDonationInfo({ payload }) {
  try {
    if (!isLoggedIn()) {
      yield put(authModule.attemptAutoLogin({ signInToken: payload.autologin }))
    }

    const response = yield call(donationConfirmationsApi.fetchMoneyDonationInfo, payload)
    yield put(donoConfirmModule.fetchMoneyDonationInfoSuccess(response))
  } catch (err) {
    yield put(donoConfirmModule.fetchMoneyDonationInfoFailure(err))
  }
}

function* donationConfirmationRequest({ payload }) {
  try {
    const response = yield call(donationConfirmationsApi.donationConfirmationRequest, payload)
    yield put(donoConfirmModule.donationConfirmationRequestSuccess(response))
  } catch (err) {
    yield put(donoConfirmModule.donationConfirmationRequestFailure(err))
  }
}

function* fetchGoodsDonationInfo({ payload }) {
  try {
    if (!isLoggedIn()) {
      yield put(authModule.attemptAutoLogin({ signInToken: payload.autologin }))
    }

    const response = yield call(donationConfirmationsApi.fetchGoodsDonationInfo, payload)
    yield put(donoConfirmModule.fetchGoodsDonationInfoSuccess(response))
  } catch (err) {
    yield put(donoConfirmModule.fetchGoodsDonationInfoFailure(err))
  }
}

function* goodsDonationConfirmationRequest({ payload }) {
  try {
    const response = yield call(donationConfirmationsApi.goodsDonationConfirmationRequest, payload)
    yield put(donoConfirmModule.goodsDonationConfirmationRequestSuccess(response))
  } catch (err) {
    yield put(donoConfirmModule.goodsDonationConfirmationRequestFailure(err))
  }
}

function* goodsOrgDonationConfirmationRequest({ payload }) {
  try {
    const response = yield call(
      donationConfirmationsApi.goodsOrgDonationConfirmationRequest,
      payload
    )
    yield put(donoConfirmModule.goodsOrgDonationConfirmationRequestSuccess(response))
  } catch (err) {
    yield put(donoConfirmModule.goodsOrgDonationConfirmationRequestFailure(err))
  }
}

function* fetchOrgMoneyDonationInfo({ payload }) {
  try {
    if (!isLoggedIn()) {
      yield put(authModule.attemptAutoLogin({ signInToken: payload.autologin }))
    }

    const response = yield call(donationConfirmationsApi.fetchOrgMoneyDonationInfo, payload)
    yield put(donoConfirmModule.fetchOrgMoneyDonationInfoSuccess(response))
  } catch (err) {
    yield put(donoConfirmModule.fetchOrgMoneyDonationInfoFailure(err))
  }
}

function* fetchDonationConfirmation({ payload }) {
  try {
    const response = yield call(donationConfirmationsApi.fetchDonationConfirmation, payload)
    yield put(donoConfirmModule.fetchDonationConfirmationSuccess(response))
  } catch (err) {
    yield put(donoConfirmModule.fetchDonationConfirmationFailure(err.response.status))
  }
}

function* fetchOrgDonationConfirmation({ payload }) {
  try {
    const response = yield call(donationConfirmationsApi.fetchOrgDonationConfirmation, payload)
    yield put(donoConfirmModule.fetchOrgDonationConfirmationSuccess(response))
  } catch (err) {
    yield put(donoConfirmModule.fetchOrgDonationConfirmationFailure(err.response.status))
  }
}

function* fetchGoodsDonationConfirmation({ payload }) {
  try {
    const response = yield call(donationConfirmationsApi.fetchGoodsDonationConfirmation, payload)
    yield put(donoConfirmModule.fetchGoodsDonationConfirmationSuccess(response))
  } catch (err) {
    yield put(donoConfirmModule.fetchGoodsDonationConfirmationFailure(err.response.status))
  }
}

function* fetchOrgGoodsDonationConfirmation({ payload }) {
  try {
    const response = yield call(donationConfirmationsApi.fetchOrgGoodsDonationConfirmation, payload)
    yield put(donoConfirmModule.fetchOrgGoodsDonationConfirmationSuccess(response))
  } catch (err) {
    yield put(donoConfirmModule.fetchOrgGoodsDonationConfirmationFailure(err.response.status))
  }
}

function* handleDonationConfirmation({ payload }) {
  try {
    const response = yield call(donationConfirmationsApi.handleDonationConfirmation, payload)
    yield put(donoConfirmModule.handleDonationConfirmationSuccess(response))
  } catch (err) {
    yield put(donoConfirmModule.handleDonationConfirmationFailure())
  }
}

function* handleGoodsDonationConfirmation({ payload }) {
  try {
    const response = yield call(donationConfirmationsApi.handleGoodsDonationConfirmation, payload)
    yield put(donoConfirmModule.handleGoodsDonationConfirmationSuccess(response))
  } catch (err) {
    yield put(donoConfirmModule.handleGoodsDonationConfirmationFailure())
  }
}

function* handleOrgDonationConfirmation({ payload }) {
  try {
    const response = yield call(donationConfirmationsApi.handleOrgDonationConfirmation, payload)
    yield put(donoConfirmModule.handleOrgDonationConfirmationSuccess(response))
  } catch (err) {
    yield put(donoConfirmModule.handleOrgDonationConfirmationFailure())
  }
}

function* handleOrgGoodsDonationConfirmation({ payload }) {
  try {
    const response = yield call(
      donationConfirmationsApi.handleOrgGoodsDonationConfirmation,
      payload
    )
    yield put(donoConfirmModule.handleOrgGoodsDonationConfirmationSuccess(response))
  } catch (err) {
    yield put(donoConfirmModule.handleOrgGoodsDonationConfirmationFailure())
  }
}

function* fetchOrgGoodsDonationInfo({ payload }) {
  try {
    if (!isLoggedIn()) {
      yield put(authModule.attemptAutoLogin({ signInToken: payload.autologin }))
    }

    const response = yield call(donationConfirmationsApi.fetchOrgGoodsDonationInfo, payload)
    yield put(donoConfirmModule.fetchOrgGoodsDonationInfoSuccess(response))
  } catch (err) {
    yield put(donoConfirmModule.fetchOrgGoodsDonationInfoFailure(err))
  }
}

function* requestOrgDonationConfirmation({ payload }) {
  try {
    if (!isLoggedIn()) {
      yield put(authModule.attemptAutoLogin({ signInToken: payload.autologin }))
    }

    const response = yield call(donationConfirmationsApi.requestOrgDonationConfirmation, payload)
    yield put(donoConfirmModule.requestOrgDonationConfirmationSuccess(response))
  } catch (err) {
    yield put(donoConfirmModule.requestOrgDonationConfirmationFailure(err))
  }
}

export const donationConfirmationsSagas = [
  takeLatest(donoConfirmModule.FETCH_GOODS_DONATION_INFO, fetchGoodsDonationInfo),
  takeLatest(donoConfirmModule.FETCH_MONEY_DONATION_INFO, fetchMoneyDonationInfo),
  takeLatest(donoConfirmModule.FETCH_ORG_GOODS_DONATION_INFO, fetchOrgGoodsDonationInfo),
  takeLatest(donoConfirmModule.FETCH_ORG_MONEY_DONATION_INFO, fetchOrgMoneyDonationInfo),

  takeLatest(
    donoConfirmModule.GOODS_DONATION_CONFIRMATION_REQUEST,
    goodsDonationConfirmationRequest
  ),
  takeLatest(donoConfirmModule.DONATION_CONFIRMATION_REQUEST, donationConfirmationRequest),
  takeLatest(
    donoConfirmModule.GOODS_ORG_DONATION_CONFIRMATION_REQUEST,
    goodsOrgDonationConfirmationRequest
  ),
  takeLatest(donoConfirmModule.REQUEST_ORG_DONATION_CONFIRMATION, requestOrgDonationConfirmation),

  takeLatest(donoConfirmModule.FETCH_DONATION_CONFIRMATION, fetchDonationConfirmation),
  takeLatest(donoConfirmModule.FETCH_GOODS_DONATION_CONFIRMATION, fetchGoodsDonationConfirmation),
  takeLatest(
    donoConfirmModule.FETCH_ORG_GOODS_DONATION_CONFIRMATION,
    fetchOrgGoodsDonationConfirmation
  ),
  takeLatest(donoConfirmModule.FETCH_ORG_DONATION_CONFIRMATION, fetchOrgDonationConfirmation),

  takeLatest(donoConfirmModule.HANDLE_GOODS_DONATION_CONFIRMATION, handleGoodsDonationConfirmation),
  takeLatest(donoConfirmModule.HANDLE_DONATION_CONFIRMATION, handleDonationConfirmation),
  takeLatest(
    donoConfirmModule.HANDLE_ORG_GOODS_DONATION_CONFIRMATION,
    handleOrgGoodsDonationConfirmation
  ),
  takeLatest(donoConfirmModule.HANDLE_ORG_DONATION_CONFIRMATION, handleOrgDonationConfirmation)
]
