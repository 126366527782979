import React, { Fragment } from 'react'
import styled from 'styled-components'
import { Link } from '@reach/router'

// icons
import { CalendarHeart } from '@styled-icons/boxicons-regular'
import { Favorite } from '@styled-icons/material-twotone'
import { LocationOn } from '@styled-icons/material-twotone'
import { LocationOff } from '@styled-icons/material-twotone'

// utils and config
import th from 'utils/themeHelper'

// Load components synchronously
import Description from 'components/Description'

// Making this a big enough number for the border to be round
// If there's a better way to do this, feel free to change
const BORDER_RADIUS = '50px'

export const CardOverlay = styled.div`
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0.1) 0%, rgba(0, 0, 0, 0.6) 100%),
    url(${({ src }) => src});
  background-position: center center;
  background-size: cover;
  display: flex;
  flex-direction: column;
  height: 160px;
  position: relative;
`

export const HasAppliedNotice = styled.div`
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  height: 160px;
  width: 100%;
`

export const HasAppliedNoticeWrapper = styled.div`
  align-items: center;
  background-color: ${th('text.dark')};
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  display: flex;
  height: 100%;
  opacity: 0.75;
  width: 100%;
`

export const CardBannerWrapper = styled.div`
  color: ${th('secondary.base')};
  height: 35%;
  margin: 0 auto;
  width: 100%;
`

export const CardBannerWrapperAbsolute = styled.div`
  display: flex;
  flex-direction: column;
  height: 150px;
  justify-content: center;
  width: 100%;
`

export const CardBannerContainer = styled.div`
  display: flex;
  height: 100%;
  justify-content: center;
  margin: 0 auto;
`

export const PrivateStoryNote = styled.div`
  align-items: center;
  background: ${th('primary.dark')};
  border: 1px solid ${th('borders.medium')};
  border-radius: 4px;
  bottom: 10px;
  color: white;
  display: flex;
  font-size: 12px;
  line-height: 1.35;
  min-height: 30px;
  min-width: max-content;
  padding: 2px 8px;
  position: absolute;
  right: 10px;
`

export const CardContent = styled.div`
  align-items: flex-end;
  display: flex;
  flex: 1;
  justify-content: flex-start;
  max-height: 75px;
  padding: 20px;
  position: relative;
`

export const CardContentButtonWrapper = styled.div`
  margin-right: 15px;
`

export const CardLinkWrapper = styled.div`
  background: ${props => (props.dark ? th('text.dark') : 'white')};
  border-radius: 5px;
  color: inherit;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
  padding: 0;
  text-decoration: none;
  width: 100%;
`

export const Title = styled.h4`
  color: white;
  display: -webkit-box;
  line-height: 1.25em;
  margin: 0 15px;
  overflow: hidden;

  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
`

export const CardBannerLabel = styled.h5`
  font-size: 13px;
  line-height: 13px;
`

export const Row = styled.div`
  align-items: center;
  display: flex;
  margin-top: 0.5em;
  position: relative;
`

export const BeneficiaryPill = styled.div`
  align-items: center;
  background: #00000099;
  border-radius: ${BORDER_RADIUS};
  display: flex;
  margin-right: 10px;
  margin-top: 5px;
  padding: 5px 15px;
  position: relative;
`

export const SpacedRow = styled(Row)`
  justify-content: space-between;
`

export const Icon = styled.img`
  height: ${({ size }) => size || 20}px;
  width: ${({ size }) => size || 20}px;
`

export const AssetWrapper = styled.div`
  align-items: center;
  display: inline-block;
  height: 20px;
  justify-content: center;
  width: 20px;
`

const CategoryIcon = styled.div`
  color: ${th('primary.base')};
  display: flex;
  height: 18px;
  position: relative;
  margin-left: 6px;
  width: 18px;

  @media screen and (min-width: 769px) {
    margin-left: 4px;
    margin-top: -5px;
    display: flex;
  }

  @media screen and (min-width: 1300px) {
    margin-left: 6px;
    margin-top: 0;
  }
`

const StoryDateIcon = styled.div`
  color: ${th('primary.base')};
  display: flex;
  height: 18px;
  position: relative;
  margin-left: 4px;
  margin-top: -1px;
  width: 18px;

  @media screen and (min-width: 769px) {
    display: flex;
  }

  @media screen and (min-width: 1300px) {
    margin-left: 6px;
    margin-right: 0;
  }
`

const TheIcon = styled.div`
  color: ${th('primary.base')};
  display: flex;
  height: 18px;
  position: relative;
  margin-left: 4px;
  margin-top: -1px;
  width: 18px;

  @media screen and (min-width: 769px) {
    display: flex;
  }
`

const StoryDateWrapper = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  min-height: 30px;
  white-space: nowrap;
`

const DateWrapper = styled.div`
  align-items: center;
  display: flex;
  justify-content: flex-start;
  margin-top: 0;
  white-space: nowrap;

  @media screen and (min-width: 769px) {
    position: absolute;
    right: 5px;
    top: 5px;
  }
`

const LocationWrapper = styled.div`
  align-items: center;
  display: flex;
  justify-content: flex-start;
  margin-top: 0;
  white-space: nowrap;

  @media screen and (min-width: 769px) {
    position: absolute;
    right: 5px;
    top: 5px;
  }
`

const CategoryWrapper = styled.div`
  align-items: center;
  display: flex;
  justify-content: flex-start;
  margin-left: 10px;
  margin-top: 0;
  white-space: nowrap;

  @media screen and (min-width: 769px) {
    margin: 0;
  }
`

const OutcomeWrapper = styled.div`
  align-items: center;
  display: flex;
  justify-content: flex-start;
  margin-top: 0;
  white-space: nowrap;
`

export const AdditionalOpportunityDetailsWrapper = styled.div`
  align-items: flex-start;
  display: flex;
  justify-content: flex-start;

  @media screen and (min-width: 769px) {
    flex: 1;
  }
`

export const AdditionalOpportunityDetailsLabel = styled.div`
  align-items: center;
  color: ${th('text.dark')};
  display: flex;
  font-size: 12px;
  margin: 0;
`

const IconLabel = styled.div`
  color: ${th('text.dark')};
  display: block;
  font-size: 12px;
  margin: 12px 2px;
`

const CategoryIconLabel = styled.div`
  color: ${th('text.dark')};
  display: block;
  font-size: 12px;
  margin: 0;
  text-decoration: underline;

  @media screen and (min-width: 769px) {
    margin-top: -5px;
  }

  @media screen and (min-width: 1300px) {
    margin: 0;
  }
`

const DateIconLabel = styled.div`
  color: ${th('text.dark')};
  display: block;
  font-size: 12px;
  margin: 12px 2px 10px;
  position: relative;
  text-decoration: underline;

  @media screen and (min-width: 769px) {
    margin: 0;
  }
`

export const CategoryTypeWrapper = styled.div`
  margin: 20px;
  padding: 1em;
`

const StoryDescriptionWrapper = styled.div`
  color: ${props => (props.dark ? 'white' : th('text.dark'))};
  display: block;
  font-weight: normal;
  font-size: ${props => (props.dark ? '16px' : '14px')};
  line-height: ${props => (props.displayFullStory ? '1.4em' : '1.35em')};
  margin: ${props =>
    props.dark ? '0 0 20px' : props.isUpdateStory ? '10px 0 10px 3px' : '0 0 0 3px'};

  @media screen and (min-width: 769px) {
    margin: ${props => {
      return props.dark
        ? '0 0 20px'
        : props.isFeatureStory
        ? '10px 0 5px 3px'
        : props.marginBottom
        ? '0 10% 20px 10px'
        : props.isUpdateStory
        ? '10px 0 0'
        : '10px 10% 0 10px'
    }};
  }
`

export const GoalTypeLabel = styled.div`
  display: inline;
  font-family: ${props => (props.bold ? th('fonts.bold') : th('fonts.regular'))};
  font-size: 14px;
  margin-right: ${props => (props.bold ? '4px' : '0')};
`

export const StoryOwnerLabel = styled.div`
  display: inline;
  font-family: ${th('fonts.bold')};
  font-size: 14px;
  margin-right: 4px;
`

export const FeatureStoryShowMoreButtonPadder = styled.div`
  margin: ${props => (props.dark ? '0 0 40px' : '20px 0 0')};

  @media screen and (min-width: 769px) {
    margin: ${props => (props.dark ? '0 0 20px' : '10px 0 0')};
  }
`

export const UpdateStoryShowMoreButtonPadder = styled.div`
  margin: ${props => (props.dark ? '0 0 20px' : '20px 0 0')};

  @media screen and (min-width: 769px) {
    margin: ${props => (props.dark ? '0 0 20px' : '10px 0 0')};
  }
`

export const PrivateStoryWarningMessage = styled.div`
  padding: 0 10px 10px;

  @media screen and (min-width: 769px) {
    padding: 5px 10px 0;
  }
`

export const ShowMoreButtonPadder = styled.div`
  margin: 0 10px;
`

export const StoryBeneficiaryPreposition = styled.div`
  font-family: ${th('fonts.regular')};
  margin: 0 4px;
`

export const StoryVideoWrapper = styled.div`
  display: flex;
  min-height: 400px;
  position: relative;
  width: 100%;
`

export const BeneficiaryLabel = styled.div`
  font-family: ${th('fonts.bold')};
`

export const AdditionalOpportunityDetailsLabelDetails = styled.div`
  display: block;
  line-height: 1.35em;
  margin: 0 10px 0 2px;
`

const ExpiryWrapper = styled.div`
  display: block;
  position: relative;
`

export const CampFeatureWrapper = styled.div`
  background: ${props => (props.dark ? th('text.dark') : '#ffd70099')};
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: ${props => (props.dark ? '10px 10px 0' : '10px')};

  @media screen and (min-width: 769px) {
    padding: ${props => (props.dark ? '0' : '10px')};
  }
`

export const FeatureStoryInnerWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 5px 0;
  min-height: 30px;

  @media screen and (min-width: 769px) {
    padding-bottom: ${props => (props.dark ? '10px' : '0')};
    padding-top: ${props => (props.dark ? '10px' : '0')};
  }
`

export const UpdateStoryOuterWrapper = styled.div`
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: ${props => (props.hasVisibleImage ? '0 0 10px' : '0')};
  min-height: 30px;

  @media screen and (min-width: 769px) {
    align-items: center;
    flex-direction: row;
  }
`

export const UpdateStoryInnerWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  padding-right: ${props => (props.paddingRight ? '15px' : '0')};
`

const ExpiryMessage = styled.div`
  align-items: center;
  background: #333333cc;
  color: white;
  display: flex;
  font-family: ${th('fonts.bold')};
  font-size: 15px;
  height: 100%;
  justify-content: center;
  padding: 1em;
  position: absolute;
  width: 100%;
`

export const VerificationWrapper = styled.div`
  align-items: center;
  background: #00000099;
  border-radius: ${BORDER_RADIUS};
  display: block;
  margin-right: 10px;
  margin-top: 5px;
  max-width: max-content;
  padding: 5px 15px;
  position: relative;
`

export const RatingRow = styled.div`
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  padding-bottom: 10px;
  padding-right: 15px;
`

export const KeyStoryDetails = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 0;
  min-height: 30px;
  width: 100%;

  @media screen and (min-width: 769px) {
    align-items: ${props => (props.isOppMasonry ? 'center' : 'flex-start')};
    flex-direction: column-reverse;
    margin: 0;
  }

  @media screen and (min-width: 1300px) {
    flex-direction: row;
  }
`

export const KeyStoryDetailsWrapper = styled.div`
  padding: 10px 10px 0;

  @media screen and (min-width: 769px) {
    padding: ${props =>
      props.isUpdateStory && !props.hasAnyText
        ? '10px 20px'
        : props.isUpdateStory
        ? '5px 10px 10px'
        : '5px 0 20px'};
  }

  @media screen and (min-width: 1300px) {
    padding: ${props => (props.isUpdateStory ? '10px' : '10px 0')};
  }
`

export const UpdateStoryDateWrapperOuter = styled.div`
  display: none;

  @media screen and (min-width: 769px) {
    align-items: flex-end;
    display: flex;
    flex-direction: row;
    justify-content: right;
    margin: 0 0 10px;
    padding: 5px;
  }

  @media screen and (min-width: 1300px) {
    flex-direction: column;
    margin: 0 10px 0 0;
    padding: 0;
    width: unset;
  }
`

export const StoryDateWrapperOuter = styled.div`
  display: ${props => (props.hideMobile ? 'none' : 'flex')};
  flex-direction: column;
  margin: 0;

  a {
    color: ${th('text.dark')};
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }

  @media screen and (min-width: 769px) {
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: right;
    margin: 0 0 10px;
    width: 100%;
    border-bottom: 1px solid ${th('borders.default')};
    padding: 5px;
  }

  @media screen and (min-width: 1300px) {
    align-items: flex-end;
    border-bottom: none;
    flex-direction: column;
    margin: 0 10px 0 0;
    padding: 0;
    width: unset;
  }
`

export const SentenceTerminationPeriodWrapper = styled.div`
  display: inline;
`

export const SentenceTerminationPeriod = () => {
  return <SentenceTerminationPeriodWrapper>.</SentenceTerminationPeriodWrapper>
}

export const StoryDetailsRow = styled.div`
  display: ${props => (props.hideMobile ? 'none' : 'flex')};
  flex-direction: ${props => props.flexDirection || 'column'};
  margin: 5px 0;

  @media screen and (min-width: 769px) {
    display: flex;
    flex-direction: column;
    margin: 0 10px;
  }
`

export const RatingLabel = styled.div`
  color: ${th('text.dark')};
  font-family: ${th('fonts.bold')};
  font-size: 15px;
  margin-bottom: 4px;
`

export const RatingsContainer = styled.div`
  align-items: flex-end;
  color: white;
  display: flex;
  flex: 1;
  justify-content: flex-start;
  margin: 0 10px 5px;

  @media screen and (min-width: 769px) {
    margin: 0 20px 10px;
  }
`

export const FeedbackImagesContainer = styled.div`
  display: block;
  overflow-y: auto;
`

export const UpdateStoryCardHeader = styled.h5`
  color: ${th('text.dark')};
  font-family: ${th('fonts.bold')};
  font-size: 15px;
  margin: 15px 15px 5px;
  text-transform: initial;

  @media screen and (min-width: 769px) {
    margin: 1px 0 1px 3px;
  }
`

export const FeatureStoryCardHeader = styled.h5`
  color: ${props => (props.dark ? 'white' : th('text.dark'))};
  font-family: ${th('fonts.bold')};
  font-size: ${props => (props.dark ? '18px' : '15px')};
  line-height: 1.25em;
  margin: ${props => (props.dark ? '0' : '10px 0 0 3px')};
  text-transform: initial;

  @media screen and (min-width: 769px) {
    margin: 0;
  }
`

export const StoryCardHeader = styled.h5`
  color: ${props => (props.dark ? 'white' : th('text.dark'))};
  font-family: ${th('fonts.bold')};
  font-size: ${props => (props.dark ? '18px' : '15px')};
  line-height: 1.25em;
  margin: ${props => (props.dark ? '0' : '10px 0 0 3px')};
  text-transform: initial;

  @media screen and (min-width: 769px) {
    margin: ${props => (props.dark ? '0' : '10px 10px 5px')};
  }
`

export const UpdatedCardNotice = styled.h5`
  color: ${th('text.dark')};
  font-family: ${th('fonts.bold')};
  font-size: 15px;
  line-height: 1.25em;
  margin: 15px 15px 5px;

  @media screen and (min-width: 769px) {
    margin: 15px 10px 5px 15px;
  }
`

export const OpportunityDescriptionNotice = styled.div`
  color: ${th('text.dark')};
  font-family: ${th('fonts.light')};
  font-size: 14px;
  line-height: 1.25em;
  margin: 5px 10% 15px 15px;
`

export const FeedbackImages = styled.div`
  border-top: 1px solid ${th('borders.medium')};
  display: flex;
  flex-wrap: nowrap;
  overflow-x: scroll;
  padding: 0;
  position: relative;
`

export const CardLink = styled(Link)`
  color: ${th('text.dark')};
  display: ${props => props.display || 'flex'};
  flex: ${props => props.flex || 'unset'};
  font-size: ${props => (props.isSmallText ? '12px' : '14px')};
  margin-top: ${props => (props.isSmallText ? '3px' : '1px')};

  &:hover {
    text-decoration: underline;
  }
`

export const StoryOwnerLink = styled.a`
  color: ${th('text.dark')};
  display: ${props => props.display || 'flex'};
  flex: ${props => props.flex || 'unset'};
  font-size: ${props => (props.isSmallText ? '12px' : '14px')};
  margin-top: ${props => (props.isSmallText ? '3px' : '1px')};

  &:hover {
    text-decoration: underline;
  }
`

export const CardOverlayTitleWrapper = styled.div`
  align-items: ${props => (props.showingOnlyTitle ? 'center' : 'flex-start')};
  display: flex;
  flex: 2;
  margin-top: 10px;
`

export const CardOverlayDetailsWrapper = styled.div`
  align-items: flex-end;
  flex: 1;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: ${props => (props.isVerifiedBeneficiary ? 'space-between' : 'flex-start')};
`

export const AvatarImage = styled.img`
  border: 1px solid #ddd;
  border-radius: 50%;
`

export const Avatar = styled.div`
  background-image: url(${({ src }) => src});
  background-size: cover;
  background-position: center center;
  border: 1px solid #ddd;
  border-radius: 50%;
  height: 30px;
  margin-right: 10px;
  min-width: 30px;
  overflow: hidden;
  padding: 0;
  width: 30px;

  & > svg,
  & > img {
    background: ${props => props.background || 'none'};
    height: ${props => props.svgHeight || '30px'};
    margin-left: ${props => props.svgMarginLeft || '0px'};
    margin-top: ${props => props.svgMarginTop || '0px'};
    width: ${props => props.svgWidth || '30px'};
  }
`

export const FeaturedStoryImage = styled.div`
  // if has show more button, subtract the 35px of vertical height that the button takes up
  min-height: ${props => (props.hasShowMoreButton ? '365px' : '400px')};
  max-height: ${props => (props.hasShowMoreButton ? '365px' : '400px')};
  width: 100%;
  margin: 0;
  flex: 1;
  background-image: url(${({ src }) => src});
  background-size: cover;
  background-position: center center;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  cursor: pointer;
`

export const FeedbackImage = styled.div`
  background-image: url(${({ src }) => src});
  background-size: cover;
  background-position: center center;
  flex: 1;
  height: 300px;
  margin: 0 5px;
  min-width: 40%;

  &:first-of-type {
    margin-left: 0;
    margin-right: 0;
  }

  &:last-of-type {
    margin-left: 0;
    margin-right: 0;
  }
`

export const VerificationStatus = styled.div`
  color: white;
  display: inline-block;
  font-size: 10px;
  margin-left: 5px;
  vertical-align: middle;
`

export const AdditionalDetails = styled.div`
  display: flex;
  margin-bottom: 10px;
`

export const PillsWrapper = styled.div`
  flex: 1;
  align-items: flex-end;
  display: flex;
  flex-direction: column;
`

export const VerificationIcon = styled.img`
  display: inline-block;
  width: 20px;
  height: 20px;
  vertical-align: middle;
`

export const StoryText = ({
  dark,
  displayFullStory,
  isFeatureStory,
  isUpdateStory,
  marginBottom,
  storyText
}) => {
  if (!storyText) return null

  return (
    <StoryDescriptionWrapper
      dark={dark}
      displayFullStory={displayFullStory}
      isFeatureStory={isFeatureStory}
      isUpdateStory={isUpdateStory}
      marginBottom={marginBottom}
    >
      <Description>{storyText}</Description>
    </StoryDescriptionWrapper>
  )
}

export const ExpiryNotice = ({ children, expired }) => {
  if (!expired) return <Fragment>{children}</Fragment>

  return (
    <ExpiryWrapper>
      <ExpiryMessage>NO LONGER ACTIVE</ExpiryMessage>
      <Fragment>{children}</Fragment>
    </ExpiryWrapper>
  )
}

export const IconWrapper = ({ infoType, value, endDate }) => {
  const multiDayOpp = infoType === 'Date' && value && endDate && value !== endDate
  const justCreated = value === 'just now'
  const dateValue = justCreated ? justCreated : value ? value.replace(/(\d\d?)th|st/, '$1') : null

  if (multiDayOpp) {
    return (
      <DateWrapper>
        <DateIconLabel>
          {value.replace(/(\d\d?)th|st/, '$1')} - {endDate.replace(/(\d\d?)th|st/, '$1')}
        </DateIconLabel>

        <TheIcon last>
          <CalendarHeart />
        </TheIcon>
      </DateWrapper>
    )
  }

  if (value && infoType === 'Date') {
    return (
      <StoryDateWrapper>
        <DateIconLabel>{dateValue}</DateIconLabel>

        <StoryDateIcon>
          <CalendarHeart />
        </StoryDateIcon>
      </StoryDateWrapper>
    )
  }

  if (infoType === 'Location') {
    const LocationIcon = value ? LocationOn : LocationOff
    const locationLabel = value || 'Remote'

    return (
      <LocationWrapper>
        <TheIcon>
          <LocationIcon />
        </TheIcon>

        <IconLabel>{locationLabel}</IconLabel>
      </LocationWrapper>
    )
  }

  if (infoType === 'Category') {
    return (
      <CategoryWrapper>
        <CategoryIconLabel>{value}</CategoryIconLabel>
        <CategoryIcon>
          <Favorite />
        </CategoryIcon>
      </CategoryWrapper>
    )
  }

  if (infoType === 'Outcome') {
    return (
      <OutcomeWrapper>
        <TheIcon>
          <Favorite />
        </TheIcon>
        <IconLabel>{value}</IconLabel>
      </OutcomeWrapper>
    )
  }

  return null
}

export const Logo = styled.img`
  object-fit: cover;
  height: 20px;
  width: 20px;
  border-radius: 50%;
  border: 1px solid ${th('borders.default')};
`

export const BeneficiaryName = styled.p`
  color: white;
  font-family: ${th('fonts.regular')};
  font-size: 10px;
  margin: 0 10px 0 4px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 150px;
`

export const IconButton = styled.button`
  border: none;
  outline: none;
  background-color: transparent;
`
