import { createActions, handleActions } from 'redux-actions'

export const FILTER_PLACES = 'places/FILTER_PLACES'
export const FETCH_PLACES = 'places/FETCH_PLACES'
export const FETCH_SUCCESS = 'places/FETCH_SUCCESS'
export const FETCH_NEARBY_CITIES = 'places/FETCH_NEARBY_CITIES'
export const FETCH_NEARBY_CITIES_SUCCESS = 'places/FETCH_NEARBY_CITIES_SUCCESS'
export const FETCH_NEARBY_CITIES_LIST = 'places/FETCH_NEARBY_CITIES_LIST'
export const CLEAR_PLACES = 'places/CLEAR_PLACES'
export const LOGOUT_SUCCESS = 'authentication/LOGOUT_SUCCESS'

export const {
  places: {
    filterPlaces,
    fetchNearbyCities,
    fetchNearbyCitiesSuccess,
    fetchNearbyCitiesList,
    fetchPlaces,
    fetchSuccess,
    clearPlaces
  }
} = createActions({
  [FILTER_PLACES]: selectedPlace => ({ selectedPlace }),
  [FETCH_PLACES]: undefined,
  [FETCH_SUCCESS]: undefined,
  [FETCH_NEARBY_CITIES]: undefined,
  [FETCH_NEARBY_CITIES_SUCCESS]: undefined,
  [FETCH_NEARBY_CITIES_LIST]: undefined,
  [CLEAR_PLACES]: undefined
})

const initialState = {
  data: [],
  searchQuery: '',
  selectedPlace: '',
  isLoading: false,
  nearbyCitiesData: [],
  isLoadingNearbyCities: false,
  errorMessage: null,
  hasError: false
}

export default handleActions(
  {
    [FILTER_PLACES]: (state, { payload }) => ({
      ...state,
      selectedPlace: payload.selectedPlace
    }),
    [FETCH_PLACES]: (state, { payload }) => ({
      ...state,
      searchQuery: payload,
      isLoading: true
    }),
    [FETCH_SUCCESS]: (state, { payload }) => ({
      ...state,
      data: payload,
      isLoading: false
    }),
    [FETCH_NEARBY_CITIES]: (state, { payload }) => ({
      ...state,
      searchQuery: payload,
      isLoadingNearbyCities: true
    }),
    [FETCH_NEARBY_CITIES_SUCCESS]: (state, { payload }) => ({
      ...state,
      nearbyCitiesData: payload,
      isLoadingNearbyCities: false
    }),
    [FETCH_NEARBY_CITIES_LIST]: (state, { payload }) => ({
      ...state,
      searchQuery: payload,
      isLoadingNearbyCities: true
    }),
    [CLEAR_PLACES]: state => ({
      ...state,
      searchQuery: '',
      isLoading: false,
      data: []
    }),
    [LOGOUT_SUCCESS]: () => initialState
  },
  initialState
)
