import React from 'react'
import Rater from 'react-rater'
import 'react-rater/lib/react-rater.css'
import Styles from './styles'

const Rate = ({ field: { name, value }, form, disabled, ...rest }) => {
  const handleChange = ({ rating }) => form.setFieldValue(name, rating)

  return (
    <Styles>
      <Rater total={5} rating={value} interactive={!disabled} onRate={handleChange} {...rest} />
    </Styles>
  )
}

export default Rate
