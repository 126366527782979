import React from 'react'
import { Question } from '@styled-icons/evil'
import { ButtonBase, IconContainer } from './styles'

// Load components synchronously
import Tooltip from 'components/Tooltip'

const SmallButtonDefault = ({ children, disabledHint, ...props }) => {
  const showQuestion = props.disabled && !props.noQuestion

  if (props.disabled && disabledHint) {
    return (
      <ButtonBase {...props} disabledHint={disabledHint}>
        <div>{children}</div>

        {showQuestion && (
          <Tooltip position="right" content={disabledHint} forceShow isWarning minWidth>
            <IconContainer>
              <Question />
            </IconContainer>
          </Tooltip>
        )}
      </ButtonBase>
    )
  }

  return <ButtonBase {...props}>{children}</ButtonBase>
}

export default SmallButtonDefault
