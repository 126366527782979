import { createActions, handleActions } from 'redux-actions'

export const COMPLETE_DONATION = 'ozow/COMPLETE_DONATION'
export const FAIL_DONATION = 'ozow/FAIL_DONATION'
export const COMPLETE_DONATION_SUCCESS = 'ozow/COMPLETE_DONATION_SUCCESS'
export const COMPLETE_DONATION_FAILURE = 'ozow/COMPLETE_DONATION_FAILURE'
export const LOGOUT_SUCCESS = 'authentication/LOGOUT_SUCCESS'

export const {
  ozow: { completeDonation, failDonation, completeDonationSuccess, completeDonationFailure }
} = createActions({
  [COMPLETE_DONATION]: undefined,
  [FAIL_DONATION]: undefined,
  [COMPLETE_DONATION_SUCCESS]: undefined,
  [COMPLETE_DONATION_FAILURE]: undefined
})

const initialState = {
  isLoading: false,
  isError: false
}

export default handleActions(
  {
    [COMPLETE_DONATION]: state => ({
      ...state,
      isLoading: true
    }),
    [FAIL_DONATION]: state => ({
      ...state,
      isLoading: true
    }),
    [COMPLETE_DONATION_SUCCESS]: state => ({
      ...state,
      isLoading: false,
      isError: false
    }),
    [COMPLETE_DONATION_FAILURE]: state => ({
      ...state,
      isLoading: false,
      isError: true
    }),
    [LOGOUT_SUCCESS]: () => initialState
  },
  initialState
)
