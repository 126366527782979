import React, { Fragment } from 'react'
import { Hero } from './styles'

// Load components synchronously
import Navbar from 'components/Navbar'

export default () => (
  <Fragment>
    <Navbar isLoading toggleMenuModal={() => {}} togglePricingDropdown={() => {}} />

    <Hero />
  </Fragment>
)
