import { navigate } from '@reach/router'
import { call, put, takeLatest } from 'redux-saga/effects'

import * as ApplicationsModule from '../modules/applications'
import * as authenticationModule from '../modules/authentication'

import {
  createApplication,
  createCampaignApplication,
  fetchApplication,
  fetchApplications,
  fetchOwnApplications,
  enrolStudents
} from '../apiModules/applications'
import { fetchOrgBoundOrgDonation } from '../apiModules/donations'

// utils and config
import constants from 'config/constants'
import { setLocalStorageValues } from 'utils/localStorageManager'
import UrlBuilder from 'utils/urlBuilder'

const determineIfOzowUrl = url => {
  if (!url) return false
  return url.match(/^https?\:\/\/pay\.ozow\.com/)
}

const determineIfPayfastPayment = url => {
  if (!url) return false

  // development environment URL
  if (process.env.NODE_ENV === 'development') {
    return url.match(/^https?\:\/\/localhost:3000\/process-donation/)
  }

  // staging and production environment URLs
  return url.match(/^https\:\/\/(www|develop)?(\.)?browniepoints.africa\/process-donation/)
}

const attemptRedirect = ({ isOzowUrl, isPayfastPayment, postApplicationRedirectUrl }) => {
  if (!postApplicationRedirectUrl) return true
  if (!isOzowUrl && !isPayfastPayment) return true

  navigate(postApplicationRedirectUrl)
}

function* create({ payload }) {
  try {
    const formDataVals = new FormData()

    Object.keys(payload).map(value => {
      if (payload[value]) {
        formDataVals.append(value, payload[value])
      }
    })

    const response = yield call(createApplication, formDataVals)
    const { postApplicationRedirectUrl } = response.data
    const isOzowUrl = determineIfOzowUrl(postApplicationRedirectUrl)
    const isPayfastPayment = determineIfPayfastPayment(postApplicationRedirectUrl)

    yield put(ApplicationsModule.createSuccess(response.data))

    if (postApplicationRedirectUrl) {
      UrlBuilder.displayPendingMessage()
    } else {
      UrlBuilder.displayUpdateSuccessMessage()
    }

    if (response.data.isNewUser) {
      yield put(authenticationModule.loginSuccess(response.data))
      yield setLocalStorageValues(response)
    }

    // Ensure user details are updated after user action
    yield put({ type: 'authentication/FETCH_MY_ROLES' })

    // Ensure page stats are updated after user action
    yield put({
      type: 'opportunities/UPDATE_SINGLE_OPP_STATS',
      payload: { ...payload, responseData: response.data }
    })

    yield put({ type: 'opportunities/FETCH_OPPORTUNITY', payload })

    // if money donation, attempt redirect
    attemptRedirect({ isOzowUrl, isPayfastPayment, postApplicationRedirectUrl })
  } catch (err) {
    if (err.response.status === 401) {
      yield put(
        ApplicationsModule.createFailure({
          errorMessage: 'Please ensure that your email and password are correct.'
        })
      )
    } else {
      yield put(ApplicationsModule.createFailure(true))
    }
  }
}

function* createForCampaign({ payload }) {
  try {
    const formDataVals = new FormData()

    Object.keys(payload).map(value => {
      if (payload[value]) {
        formDataVals.append(value, payload[value])
      }
    })

    const response = yield call(createCampaignApplication, formDataVals)
    yield put(ApplicationsModule.createForCampaignSuccess(response.data))
    yield put({ type: 'authentication/FETCH_MY_ROLES' })
  } catch (err) {
    yield put(ApplicationsModule.createForCampaignFailure(true))
  }
}

function* enrolForCampaign({ payload }) {
  try {
    const formDataVals = new FormData()

    Object.keys(payload).map(value => {
      if (payload[value]) {
        formDataVals.append(value, payload[value])
      }
    })

    const response = yield call(enrolStudents, formDataVals)
    yield put(ApplicationsModule.enrolForCampaignSuccess(response.data))
    window.location.reload()
  } catch (err) {
    if (err.response.status === 403) {
      yield put(ApplicationsModule.enrolForCampaignFailure())
    } else {
      window.location.reload()
    }
  }
}

function* fetchApp({ payload }) {
  try {
    const isOrgBoundDonation = [
      constants.STORY_CONTEXT_TYPES.PERSON_TO_ORG_DONATION,
      constants.STORY_CONTEXT_TYPES.ORG_TO_ORG_DONATION
    ].includes(payload.donationContext)

    if (isOrgBoundDonation) {
      // Donations being made via org page
      const response = yield call(fetchOrgBoundOrgDonation, payload)
      yield put(ApplicationsModule.fetchApplicationSuccess(response))
    } else {
      // Donations being made via opp page
      const response = yield call(fetchApplication, payload)
      yield put(ApplicationsModule.fetchApplicationSuccess(response))
    }
  } catch (err) {
    yield put(ApplicationsModule.fetchApplicationFailure(err))
  }
}

function* fetchApps() {
  try {
    const response = yield call(fetchApplications)
    yield put(ApplicationsModule.fetchSuccess(response))
  } catch (err) {
    yield put(ApplicationsModule.fetchFailure(err))
  }
}

function* fetchOwnApps() {
  try {
    const response = yield call(fetchOwnApplications)
    yield put(ApplicationsModule.fetchSuccess(response))
  } catch (err) {
    yield put(ApplicationsModule.fetchFailure(err))
  }
}

export const applicationSagas = [
  takeLatest(ApplicationsModule.CREATE, create),
  takeLatest(ApplicationsModule.CREATE_FOR_CAMPAIGN, createForCampaign),
  takeLatest(ApplicationsModule.ENROL_FOR_CAMPAIGN, enrolForCampaign),
  takeLatest(ApplicationsModule.FETCH_APPLICATION, fetchApp),
  takeLatest(ApplicationsModule.FETCH_APPLICATIONS, fetchApps),
  takeLatest(ApplicationsModule.FETCH_OWN_APPLICATIONS, fetchOwnApps)
]
