import styled from 'styled-components'
import th from 'utils/themeHelper'

export const ButtonBase = styled.button`
  align-items: center;
  border: 1px solid ${th('backgrounds.mid')};
  border-radius: 4px;
  background: ${th('backgrounds.mid')};
  color: ${th('text.dark')};
  cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};
  display: ${props => (props.disabledHint ? 'flex' : 'block')};
  font-family: ${th('fonts.bold')};
  font-size: 12px;
  height: 35px;
  outline: none;
  padding: ${th('spacing.tiny', 'px')} ${th('spacing.default', 'px')};
  transition: 0.3s box-shadow, 0.3s background;
  width: ${props => (props.wide ? '100%' : 'auto')};

  &:hover,
  &:focus {
    border: 1px solid ${th('borders.medium')};
    transition: 0.3s border;
  }
`

export const IconContainer = styled.i`
  line-height: 20px;
  margin-left: ${th('spacing.small', 'px')};

  & > svg {
    width: 20px;
    height: 20px;
  }
`
