import styled from 'styled-components'
import th from '../../utils/themeHelper'

const Label = styled.label`
  font-size: 14px;
  line-height: 1.25em;
  display: block;
  color: ${th('text.dark')};
  margin-bottom: 10px;
`

export default Label
