import { call, put, takeLatest } from 'redux-saga/effects'
import * as messagingApi from '../apiModules/messaging'
import * as messagingModule from '../modules/messaging'

function* fetchMessagingData({ payload }) {
  try {
    const response = yield call(messagingApi.fetchMessagingData, payload)
    yield put(messagingModule.fetchMessagingDataSuccess(response))
  } catch (err) {
    yield put(messagingModule.fetchMessagingDataFailure(err))
  }
}

function* triggerMessageDispatch({ payload }) {
  try {
    yield call(messagingApi.triggerMessageDispatch, payload)
    yield put(messagingModule.triggerMessageDispatchSuccess())
  } catch (err) {
    yield put(messagingModule.triggerMessageDispatchFailure(err))
  }
}

export const messagingSagas = [
  takeLatest(messagingModule.FETCH_MESSAGING_DATA, fetchMessagingData),
  takeLatest(messagingModule.TRIGGER_MESSAGE_DISPATCH, triggerMessageDispatch)
]
