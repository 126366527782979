import React, { Component } from 'react'
import { ThumbImg, Warning } from './styles'

// TODO(Mike): This may no longer be used anywhere. Check and remove
class Thumb extends Component {
  state = {
    loading: false,
    thumb: undefined
  }

  UNSAFE_componentWillReceiveProps({ file }) {
    if (!file || typeof file === 'string') {
      return
    }

    this.setState({ loading: true }, () => {
      const reader = new FileReader()

      reader.onloadend = () => {
        this.setState({ loading: false, thumb: reader.result })
      }

      reader.readAsDataURL(file)
    })
  }

  render() {
    const { file, error } = this.props
    const { loading, thumb } = this.state
    const thumbVal = typeof file === 'string' ? file : thumb

    if (error) {
      return <Warning>{error}</Warning>
    }

    if (!file) {
      return null
    }

    if (loading) {
      return <p>loading...</p>
    }

    return <ThumbImg src={thumbVal} alt={file} />
  }
}

export default Thumb
