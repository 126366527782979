import React from 'react'
import styled from 'styled-components'

// assets
import payfastIcon from 'assets/images/payment-method-icons/payfast_logo_vector_small.png'
import { Check } from '@styled-icons/material/Check'
import { CalendarEventFill } from '@styled-icons/bootstrap/CalendarEventFill'

// config and utils
import th from 'utils/themeHelper'

const PaymentFrequencySelectorWrapper = styled.div`
  margin: 0 auto;
  width: 70%;

  @media screen and (max-width: 769px) {
    width: 100%;
  }
`

const SelectedFrequencyIconWrapper = styled.div`
  background: #fff;
  border: 1px solid ${th('borders.dark')};
  position: absolute;
  border-radius: 50%;
  display: ${props => (props.active ? 'block' : 'none')};
  padding: 10px;
  right: -20px;

  svg {
    height: 20px;
    width: 20px;
  }
`

const FrequencyIconWrapper = styled.div`
  margin-right: 15px;

  svg {
    height: 50px;
    width: 50px;
  }
`

const PaymentFrequencySelectorWrapperInner = styled.div`
  align-items: center;
  display: flex;
`

const PaymentOption = styled.div`
  background: ${props =>
    props.active ? '#2A90443C' : props.disabled ? th('backgrounds.light') : 'white'};
  border-color: ${props => (props.active ? th('primary.base') : th('borders.default'))};
  border-style: solid;
  border-radius: 4px;
  border-width: 2px;
  display: flex;
  margin-bottom: 15px;
  max-width: 500px;
  padding: 10px;
  position: relative;

  &:hover {
    border-color: ${props =>
      props.active
        ? th('primary.base')
        : props.disabled
        ? th('borders.default')
        : th('primary.base')};
    cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};
  }
`

const PaymentOptionLabel = styled.div`
  font-family: ${th('fonts.bold')};
  font-size: 18px;
  margin-bottom: 8px;
`

const PaymentOptionText = styled.div`
  color: ${th('text.mid')};
  font-size: 14px;
  line-height: 1.35em;
  max-width: 700px;
`

const PaymentOptionLogo = styled.img`
  margin-right: 15px;
  width: 50px;
`

const PaymentFrequencySelector = ({
  chosenValue,
  donationPlatformOptions,
  hasPersonalSub,
  orgOpts,
  setFieldValue
}) => {
  // Auto-select payfast when selecting recurring donations
  const payfastOption = donationPlatformOptions.find(o => o.is_payfast) || []

  const monthlyBillingText =
    hasPersonalSub && !orgOpts.length
      ? 'You already have a recurring subscription.'
      : hasPersonalSub
      ? 'Add recurring subscription for an organisation'
      : 'You will be billed monthly.'

  return (
    <PaymentFrequencySelectorWrapper>
      <PaymentOption
        active={chosenValue === 'once_off'}
        onClick={() => {
          setFieldValue('donation_frequency', 'once_off')
          setFieldValue('chosen_donation_link_is_payfast', undefined)
          setFieldValue('chosen_donation_link_platform', undefined)
          setFieldValue('chosen_donation_link_id', undefined)
          setFieldValue('chosen_donation_link_url', undefined)
        }}
      >
        <PaymentFrequencySelectorWrapperInner>
          <SelectedFrequencyIconWrapper active={chosenValue === 'once_off'}>
            <Check />
          </SelectedFrequencyIconWrapper>

          <div>
            <PaymentOptionLogo src={payfastIcon} />
          </div>

          <div>
            <PaymentOptionLabel>Once-off donation</PaymentOptionLabel>
            <PaymentOptionText>You will only be billed once</PaymentOptionText>
          </div>
        </PaymentFrequencySelectorWrapperInner>
      </PaymentOption>

      <PaymentOption
        active={chosenValue === 'recurring'}
        disabled={!orgOpts.length}
        onClick={() => {
          if (!orgOpts.length) return true

          setFieldValue('donation_frequency', 'recurring')
          setFieldValue('chosen_donation_link_is_payfast', true)
          setFieldValue('chosen_donation_link_platform', payfastOption.value)
          setFieldValue('chosen_donation_link_id', payfastOption.id)
          setFieldValue('chosen_donation_link_url', payfastOption.url)
        }}
      >
        <SelectedFrequencyIconWrapper active={chosenValue === 'recurring'}>
          <Check />
        </SelectedFrequencyIconWrapper>

        <PaymentFrequencySelectorWrapperInner>
          <FrequencyIconWrapper>
            <CalendarEventFill />
          </FrequencyIconWrapper>

          <div>
            <PaymentOptionLabel>Membership</PaymentOptionLabel>
            <PaymentOptionText>{monthlyBillingText}</PaymentOptionText>
          </div>
        </PaymentFrequencySelectorWrapperInner>
      </PaymentOption>
    </PaymentFrequencySelectorWrapper>
  )
}

export default PaymentFrequencySelector
