import { call, put, takeLatest } from 'redux-saga/effects'

import * as accountActivityModule from '../modules/accountActivity'
import { fetchContributions as fetchContribs } from '../apiModules/accountActivity'

function* fetchContributions({ payload }) {
  try {
    const response = yield call(fetchContribs, payload)
    yield put(accountActivityModule.fetchContributionsSuccess(response))
  } catch (err) {
    yield put(accountActivityModule.fetchContributionsFailure(err))
  }
}

export const accountActivitySagas = [
  takeLatest(accountActivityModule.FETCH_CONTRIBUTIONS, fetchContributions)
]
