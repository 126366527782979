import { createActions, handleActions } from 'redux-actions'

export const UPDATE_TEAM_GROUP = 'teamGroups/UPDATE_TEAM_GROUP'
export const UPDATE_SUCCESS = 'teamGroups/UPDATE_SUCCESS'
export const UPDATE_FAILURE = 'teamGroups/UPDATE_FAILURE'
export const FETCH_TEAM_GROUP = 'teamGroups/FETCH_TEAM_GROUP'
export const SINGLE_FETCH_SUCCESS = 'teamGroups/SINGLE_FETCH_SUCCESS'
export const SINGLE_FETCH_FAILURE = 'teamGroups/SINGLE_FETCH_FAILURE'
export const DELETE_TEAM_GROUP = 'teamGroups/DELETE_TEAM_GROUP'
export const DELETE_SUCCESS = 'teamGroups/DELETE_SUCCESS'
export const DELETE_FAILURE = 'teamGroups/DELETE_FAILURE'

export const {
  teamGroups: {
    updateTeamGroup,
    updateSuccess,
    updateFailure,
    fetchTeamGroup,
    singleFetchSuccess,
    singleFetchFailure,
    deleteTeamGroup,
    deleteSuccess,
    deleteFailure
  }
} = createActions({
  [UPDATE_TEAM_GROUP]: undefined,
  [UPDATE_SUCCESS]: undefined,
  [UPDATE_FAILURE]: undefined,
  [FETCH_TEAM_GROUP]: undefined,
  [SINGLE_FETCH_SUCCESS]: undefined,
  [SINGLE_FETCH_FAILURE]: undefined,
  [DELETE_TEAM_GROUP]: undefined,
  [DELETE_SUCCESS]: teamGroupId => ({ teamGroupId }),
  [DELETE_FAILURE]: undefined
})

const initialState = {
  isLoading: null,
  isSubmitting: false,
  hasError: false,
  errorMessage: null,
  data: {}
}

export default handleActions(
  {
    [UPDATE_TEAM_GROUP]: state => ({
      ...state,
      isLoading: false,
      isSubmitting: true
    }),
    [UPDATE_SUCCESS]: (state, { payload }) => {
      return {
        ...state,
        isLoading: false,
        isSubmitting: false,
        data: payload
      }
    },
    [UPDATE_FAILURE]: (state, payload) => ({
      ...state,
      ...payload,
      isLoading: false,
      isSubmitting: false
    }),
    [FETCH_TEAM_GROUP]: state => {
      return {
        ...state,
        isLoading: true
      }
    },
    [SINGLE_FETCH_SUCCESS]: (state, { payload }) => ({
      ...state,
      isLoading: false,
      hasSubmitted: false,
      data: payload,
      teamGroupId: payload.id
    }),
    [SINGLE_FETCH_FAILURE]: (state, payload) => ({
      ...state,
      ...payload
    }),
    [DELETE_TEAM_GROUP]: state => ({
      ...state,
      isLoading: true,
      hasSubmitted: true
    }),
    [DELETE_SUCCESS]: state => ({
      ...state,
      isLoading: false,
      hasSubmitted: true,
      data: {}
    }),
    [DELETE_FAILURE]: state => ({
      ...state,
      isLoading: false,
      hasSubmitted: true
    })
  },
  initialState
)
