import React, { Component } from 'react'
import Select from 'react-select'
import styled from 'styled-components'
import generateSlots from 'utils/generateSlots'

// const TIME_SLOTS = generateSlots(0, 24, 30).map(slot => ({ value: `${slot}:00`, label: slot }))
const TIME_SLOTS_HH = generateSlots(0, 24, 60).map(slot => ({
  value: slot,
  label: slot.substring(0, 2)
}))
const MINUTE_INTERVALS = ['00', '05', '10', '15', '20', '25', '30', '35', '40', '45', '50', '55']
const TIME_SLOTS_MM = MINUTE_INTERVALS.map(minutes => ({ value: minutes, label: minutes }))

export const Wrapper = styled.div`
  display: flex;
  height: 100%;
`

export const TimeSeparator = styled.div`
  margin: auto;
`

const StyledSelect = styled(Select)`
  .Time__control {
    border: none;
    appearance: none;
    height: 100%;
    width: 30px;
  }

  .Time__control.Time__control--is-focused {
    border: none;
    appearance: none;
    outline: none;
    box-shadow: none;
  }

  .Time__menu.Time__menu {
    width: 70px;
  }

  .Time__value-container {
    padding: 0;
    div:last-child {
      margin: 0;
      padding: 0;
    }
  }

  .Time__menu {
    box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175);
    margin: 0;
  }
`

class TimePicker extends Component {
  state = {
    menuOpen: false,
    selectedHour: null,
    selectedMinute: null
  }

  componentDidMount = () => {
    const { value } = this.props
    const propsHour = value.replace(/:.+/, '')
    const propsMinute = value.replace(/.+:/, '')
    const selectedHour = TIME_SLOTS_HH.find(
      slot => slot.value === propsHour || slot.label === propsHour
    )
    const selectedMinute = TIME_SLOTS_MM.find(
      slot => slot.value === propsMinute || slot.label === propsMinute
    )
    this.setState({ selectedHour, selectedMinute })
  }

  handleChangeHour = selectedHour => {
    const minutes = this.state.selectedMinute == null ? '00' : this.state.selectedMinute.value
    const selectedMinute = { value: minutes, label: minutes }

    this.setState({ selectedHour, selectedMinute }, () => {
      this.props.onChange(`${selectedHour.label}:${minutes}`)
    })
  }

  handleChangeMinute = (selectedMinute, timeRangeEdge) => {
    // If no hour has yet been selected set the start hour as 9AM
    // If no hour has yet been selected set the end hour as 17PM
    const fallbackSlot = timeRangeEdge === 'start_time' ? TIME_SLOTS_HH[9] : TIME_SLOTS_HH[17]
    const selectedHour = this.state.selectedHour || fallbackSlot

    this.setState({ selectedHour, selectedMinute }, () => {
      this.props.onChange(`${selectedHour.label}:${selectedMinute.label}`)
    })
  }

  handleChange = (attr, time, timeRangeEdge) => {
    if (attr === 'hour') {
      this.handleChangeHour(time)
    }

    if (attr === 'minute') {
      this.handleChangeMinute(time, timeRangeEdge)
    }
  }

  componentDidUpdate = ({ value: prevValue }) => {
    if (prevValue && !this.props.value) {
      this.setState({ selectedHour: null, selectedMinute: null })
    }

    if (!prevValue && this.props.value) {
      const adjustedHourValue = this.props.value.replace(/:\d\d/, ':00')
      const selectedHour = TIME_SLOTS_HH.find(slot => slot.value === adjustedHourValue)
      const valueMinutes = this.props.value ? this.props.value.substring(3, 5) : null
      const selectedMinute = TIME_SLOTS_MM.find(slot => slot.value === valueMinutes)
      this.setState({ selectedHour, selectedMinute })
    }

    if (prevValue && this.props.value && prevValue !== this.props.value) {
      const adjustedHourValue = this.props.value.replace(/:\d\d/, ':00')
      const selectedHour = TIME_SLOTS_HH.find(slot => slot.value === adjustedHourValue)
      const valueMinutes = this.props.value ? this.props.value.substring(3, 5) : null
      const selectedMinute = TIME_SLOTS_MM.find(slot => slot.value === valueMinutes)
      this.setState({ selectedHour, selectedMinute })
    }
  }

  render() {
    const { selectedHour, selectedMinute, menuOpen } = this.state
    const { time_range_edge } = this.props

    return (
      <Wrapper>
        <StyledSelect
          components={{
            DropdownIndicator: () => null,
            IndicatorSeparator: () => null
          }}
          menuOpen={menuOpen}
          placeholder="HH"
          isSearchable={false}
          options={TIME_SLOTS_HH}
          classNamePrefix="Time"
          value={selectedHour}
          onChange={hour => this.handleChange('hour', hour, time_range_edge)}
          onMenuOpen={() => this.setState({ menuOpen: true })}
          onMenuClose={() => this.setState({ menuOpen: false })}
        />

        <TimeSeparator>:</TimeSeparator>

        <StyledSelect
          components={{
            DropdownIndicator: () => null,
            IndicatorSeparator: () => null
          }}
          menuOpen={menuOpen}
          placeholder="MM"
          isSearchable={false}
          options={TIME_SLOTS_MM}
          classNamePrefix="Time"
          value={selectedMinute}
          onChange={minute => this.handleChange('minute', minute, time_range_edge)}
          onMenuOpen={() => this.setState({ menuOpen: true })}
          onMenuClose={() => this.setState({ menuOpen: false })}
        />
      </Wrapper>
    )
  }
}

export default TimePicker
