import { call, put, takeLatest } from 'redux-saga/effects'

// redux
import * as statsModule from '../modules/stats'
import * as authenticationModule from '../modules/authentication'
import * as statsApi from '../apiModules/stats'
import * as authApi from '../apiModules/authentication'

// utils
import { clearLocalStorageValues } from 'utils/localStorageManager'

function* fetchStats({ payload }) {
  try {
    const response = yield call(statsApi.fetchStats, payload)
    yield put(statsModule.fetchSuccess(response.data))
  } catch (err) {
    if (err.response.status === 401) {
      yield call(authApi.logout)
      yield clearLocalStorageValues()
      yield put(authenticationModule.logoutSuccess())
      yield window.location.reload()
    }
  }
}

export const statsSagas = [takeLatest(statsModule.FETCH_STATS, fetchStats)]
