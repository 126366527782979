import styled, { css } from 'styled-components'

export const InformationBoxOuter = styled.div`
  position: relative;
  display: flex;
  padding: 0 0 10px;
  padding-left: 0;
  align-items: center;
  justify-content: flex-start;
  flex: 1 0 100%;

  @media (min-width: 550px) {
    flex: 1 0 50%;

    &:nth-child(2) {
      order: 3;
    }
    &:nth-child(3) {
      order: 2;
    }
    &:nth-child(4) {
      order: 4;
    }
  }

  @media (min-width: 800px) {
    flex: 0 0 25%;
    padding-left: 10px;
    &:nth-child(2) {
      order: 0;
    }
  }

  &:first-child {
    padding-left: 0;
  }

  p:last-child {
    margin-bottom: 0;
  }
`

const IconFillProperty = css`
  fill: ${props => props.fill};
`

export const IconWrapper = styled.div`
  padding-right: 10px;
  position: relative;
  left: ${props => (props.isCalendar ? '-7px' : 0)};

  & > svg {
    ${props => (props.fill ? IconFillProperty : null)};
  }
`
