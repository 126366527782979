import React, { Fragment } from 'react'
import { Link, navigate } from '@reach/router'

// utils
import stringUtils from 'utils/string'
import {
  deriveAttribution,
  deriveStoryAction,
  deterimeBeneficiaryRendering
} from 'utils/deriveStoryDetails'

// assets
import { ReactComponent as OrgIcon } from 'assets/images/svgs/icon-org-page-organisation.svg'

// styles
import {
  AdditionalOpportunityDetailsLabel,
  AdditionalOpportunityDetailsLabelDetails,
  AdditionalOpportunityDetailsWrapper,
  Avatar,
  AvatarImage,
  BeneficiaryLabel,
  CardLink,
  CardLinkWrapper,
  FeedbackImage,
  FeedbackImages,
  GoalTypeLabel,
  KeyStoryDetails,
  KeyStoryDetailsWrapper,
  IconWrapper,
  SentenceTerminationPeriod,
  ShowMoreButtonPadder,
  StoryBeneficiaryPreposition,
  StoryCardHeader,
  StoryDateWrapperOuter,
  StoryDetailsRow,
  StoryOwnerLabel,
  StoryOwnerLink,
  StoryText
} from './styles'

// Load components synchronously
import ConditionalDisplay from 'components/ConditionalDisplay/ConditionalDisplay'
import LinkButton from 'components/SmallButtonPrimary/LinkButton'
import MobileView from 'components/MobileView/MobileView'
import SingleCard from 'components/SingleCard/SingleCard'

// StoryCard partials
import EditStoryCard from './EditStoryCard'
import StoryPills from './StoryPills'
import StoryCardMessages from './StoryCardMessages'

const AvatarToShow = ({ storyOwnerAvatar }) => {
  if (storyOwnerAvatar) {
    return (
      <Avatar svgWidth={'35px'} svgHeight={'35px'} svgMarginLeft={'-3px'} svgMarginTop={'-4px'}>
        <AvatarImage src={storyOwnerAvatar} />
      </Avatar>
    )
  }

  return (
    <Avatar svgWidth={'22px'} svgHeight={'22px'} svgMarginLeft={'3px'} svgMarginTop={'3px'}>
      <OrgIcon />
    </Avatar>
  )
}

const KeyDetails = props => {
  const {
    isOppMasonry,
    isUserProfile,
    did_withdraw: applicationWithdrawn,
    owner_link: storyOwnerLink,
    owner_name: storyOwnerName,
    owner_avatar: storyOwnerAvatar,
    story_outcome_status: storyOutcomeStatus,
    story_outcome_amount: storyAmount,
    story_type: storyType,
    story_beneficiary: storyBeneficiary
  } = props

  const { beneficiaryName, beneficiaryLink } = deriveAttribution({ storyBeneficiary })
  const actionDescription = deriveStoryAction({
    applicationWithdrawn,
    beneficiaryName,
    isOppMasonry,
    storyAmount,
    storyOutcomeStatus,
    storyType
  })
  const displayBeneficiary = deterimeBeneficiaryRendering({ beneficiaryName, storyType })

  if (isUserProfile) {
    return (
      <StoryDetailsRow>
        <AdditionalOpportunityDetailsWrapper>
          <AdditionalOpportunityDetailsLabel>
            <AvatarToShow storyType={storyType} />

            <AdditionalOpportunityDetailsLabelDetails>
              <StoryOwnerLabel>{storyOwnerName}</StoryOwnerLabel>
              <GoalTypeLabel>{actionDescription}</GoalTypeLabel>

              <ConditionalDisplay displayWhen={[displayBeneficiary]}>
                <StoryBeneficiaryPreposition>{'for'}</StoryBeneficiaryPreposition>

                <CardLink isSmallText to={beneficiaryLink}>
                  <BeneficiaryLabel>{beneficiaryName}</BeneficiaryLabel>
                </CardLink>
              </ConditionalDisplay>

              <SentenceTerminationPeriod />
            </AdditionalOpportunityDetailsLabelDetails>
          </AdditionalOpportunityDetailsLabel>
        </AdditionalOpportunityDetailsWrapper>
      </StoryDetailsRow>
    )
  }

  if (!storyOwnerLink || !storyOwnerName) {
    return (
      <StoryDetailsRow>
        <AdditionalOpportunityDetailsWrapper>
          <AdditionalOpportunityDetailsLabel isOppMasonry={isOppMasonry}>
            <AvatarToShow storyType={storyType} />

            <AdditionalOpportunityDetailsLabelDetails>
              <StoryOwnerLabel>An anonymous philanthropist</StoryOwnerLabel>
              <GoalTypeLabel>{actionDescription}</GoalTypeLabel>

              <ConditionalDisplay displayWhen={[displayBeneficiary]}>
                <StoryBeneficiaryPreposition>{'for'}</StoryBeneficiaryPreposition>

                <CardLink isSmallText to={beneficiaryLink}>
                  <BeneficiaryLabel>{beneficiaryName}</BeneficiaryLabel>
                </CardLink>
              </ConditionalDisplay>

              <SentenceTerminationPeriod />
            </AdditionalOpportunityDetailsLabelDetails>
          </AdditionalOpportunityDetailsLabel>
        </AdditionalOpportunityDetailsWrapper>
      </StoryDetailsRow>
    )
  }

  return (
    <StoryDetailsRow>
      <AdditionalOpportunityDetailsWrapper>
        <AdditionalOpportunityDetailsLabel isOppMasonry={isOppMasonry}>
          <StoryOwnerLink href={storyOwnerLink}>
            <AvatarToShow storyOwnerAvatar={storyOwnerAvatar} />
          </StoryOwnerLink>

          <AdditionalOpportunityDetailsLabelDetails>
            <StoryOwnerLink display={'inline'} href={storyOwnerLink}>
              <StoryOwnerLabel>{storyOwnerName}</StoryOwnerLabel>
            </StoryOwnerLink>

            <GoalTypeLabel>{actionDescription}</GoalTypeLabel>

            <ConditionalDisplay displayWhen={[displayBeneficiary]}>
              <StoryBeneficiaryPreposition>{'for'}</StoryBeneficiaryPreposition>

              <CardLink isSmallText to={beneficiaryLink}>
                <BeneficiaryLabel>{beneficiaryName}</BeneficiaryLabel>
              </CardLink>
            </ConditionalDisplay>

            <SentenceTerminationPeriod />
          </AdditionalOpportunityDetailsLabelDetails>
        </AdditionalOpportunityDetailsLabel>
      </AdditionalOpportunityDetailsWrapper>
    </StoryDetailsRow>
  )
}

const TitleForStoryInfo = ({ isOppMasonry, linkTitle, path, storyTitle }) => {
  if (!storyTitle) return null
  if (isOppMasonry) return null

  if (!linkTitle) {
    return <StoryCardHeader>{storyTitle}</StoryCardHeader>
  }

  return (
    <CardLink to={path}>
      <StoryCardHeader>{storyTitle}</StoryCardHeader>
    </CardLink>
  )
}

const StoryInfo = ({
  isEditingStory,
  isOppMasonry,
  linkTitle,
  path = '',
  storyText = '',
  storyTitle
}) => {
  if (isEditingStory) return null

  // Derive a name to display and shorten the story text for the card
  // spliced string is 41 tokens for some reason
  const wordLimit = 100
  const paddedWordLimit = 41
  const { splicedString } = stringUtils.trimText({ string: storyText, wordLimit })

  const wordCount = storyText.trim().split(' ').length
  const isSpliced = wordCount > paddedWordLimit
  const displayShowMoreButton = isSpliced

  return (
    <Fragment>
      <TitleForStoryInfo
        isOppMasonry={isOppMasonry}
        linkTitle={linkTitle}
        path={path}
        storyTitle={storyTitle}
      />

      <StoryText marginBottom={displayShowMoreButton} storyText={splicedString} />

      <ConditionalDisplay displayWhen={[displayShowMoreButton]}>
        <ShowMoreButtonPadder>
          <LinkButton onClick={() => navigate(path)}>Show more</LinkButton>
        </ShowMoreButtonPadder>
      </ConditionalDisplay>
    </Fragment>
  )
}

const deriveStoryCategoryName = ({ categories, storyCategory }) => {
  const category = categories.find(c => c.id === storyCategory)
  return category ? category.name : null
}

class OrgUpdateStoryCard extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      isEditingStory: false,
      updatedStoryStatus: null,
      userRequestedFullStory: props.story_status
    }

    this.updateStoryStatus = this.updateStoryStatus.bind(this)
    this.handleStoryEditToggle = this.handleStoryEditToggle.bind(this)
  }

  displayShowMoreButton() {
    this.setState({ userRequestedFullStory: true })
  }

  handleStoryEditToggle({ isEditingStory }) {
    this.setState({ isEditingStory })
  }

  updateStoryStatus(value) {
    this.setState({ updatedStoryStatus: value })
  }

  render() {
    const { isEditingStory, updatedStoryStatus, userRequestedFullStory } = this.state
    const {
      categories = [],
      display_full_story: displayFullStory,
      displayPrivateStoryMessage,
      forceDisplayStory,
      hasGodRole,
      isOppMasonry,
      isOrgMasonry,
      isUserProfile,
      id: storyId,
      isHiddenStory,
      status,
      story_category_id: storyCategory,
      story_date: storyDate,
      story_target_url: storyTarget,
      story_end_date: storyEndDate,
      story_images: storyImages,
      story_likes: storyLikesCount,
      story_status: storyStatus,
      story_text: storyText,
      story_title: storyTitle,
      user_likes_story: userLikesStory
    } = this.props

    const hasStoryImages = storyImages && storyImages.length > 0

    const storyImageSrc = hasStoryImages ? storyImages[0].src : null

    if (displayPrivateStoryMessage) {
      return <StoryCardMessages.PrivateStoryMessage />
    }

    // Show after story is set to public
    if (updatedStoryStatus === 'Public') {
      return <StoryCardMessages.VisibleStoryMessage />
    }

    // Show after story is set to hidden
    if (isHiddenStory) {
      return <StoryCardMessages.HiddenStoryMessage />
    }

    if (isEditingStory) {
      return (
        <EditStoryCard
          updateStoryStatus={this.updateStoryStatus}
          forceRelative
          handleStoryEditToggle={this.handleStoryEditToggle}
          hasGodRole={hasGodRole}
          isOrgMasonry={isOrgMasonry}
          isUserProfile={isUserProfile}
          storyStatus={storyStatus}
          storyLikesCount={storyLikesCount}
          userLikesStory={userLikesStory}
          storyId={storyId}
          {...this.props}
        />
      )
    }

    const safeStoryText = storyText || ''
    const storyCategoryName = deriveStoryCategoryName({ categories, storyCategory })

    // Derive a name to display and shorten the story text for the card
    // spliced string is 41 tokens for some reason
    const wordLimit = 40
    const { splicedString } = stringUtils.trimText({
      string: safeStoryText,
      wordLimit: wordLimit
    })
    const visibleStoryText =
      displayFullStory || forceDisplayStory
        ? safeStoryText
        : userRequestedFullStory
        ? safeStoryText
        : splicedString
    const hasAnyText = visibleStoryText.length

    return (
      <SingleCard>
        <CardLinkWrapper>
          <FeedbackImages>
            <FeedbackImage src={storyImageSrc} />

            <StoryPills
              forceRelative
              hasGodRole={hasGodRole}
              position="absolute"
              handleStoryEditToggle={this.handleStoryEditToggle}
              isOrgMasonry={isOrgMasonry}
              isUserProfile={isUserProfile}
              storyStatus={storyStatus}
              storyLikesCount={storyLikesCount}
              userLikesStory={userLikesStory}
              storyId={storyId}
              {...this.props}
            />
          </FeedbackImages>

          <KeyStoryDetailsWrapper icons={[storyCategoryName, storyDate]}>
            <KeyStoryDetails
              isOppMasonry={isOppMasonry}
              icons={[storyCategoryName, storyDate]}
              hasAnyText={hasAnyText}
            >
              <KeyDetails isUserProfile={isUserProfile} {...this.props} />

              <StoryDateWrapperOuter hideMobile>
                <Link to={`/stories/${storyId}`}>
                  <IconWrapper infoType={'Date'} value={storyDate} endDate={storyEndDate} />
                </Link>

                <ConditionalDisplay displayWhen={[!isOppMasonry, storyCategoryName]}>
                  <IconWrapper infoType={'Category'} value={storyCategoryName} />
                </ConditionalDisplay>
              </StoryDateWrapperOuter>
            </KeyStoryDetails>

            <StoryInfo
              isOppMasonry={isOppMasonry}
              isEditingStory={isEditingStory}
              linkTitle={storyTarget}
              path={storyTarget}
              storyHasText
              storyText={safeStoryText}
              storyTitle={storyTitle}
              status={status}
              storyId={storyId}
            />

            <MobileView>
              <StoryDetailsRow flexDirection={'row'}>
                <IconWrapper infoType={'Date'} value={storyDate} endDate={storyEndDate} />

                <ConditionalDisplay displayWhen={[!isOppMasonry, storyCategoryName]}>
                  <IconWrapper infoType={'Category'} value={storyCategoryName} />
                </ConditionalDisplay>
              </StoryDetailsRow>
            </MobileView>
          </KeyStoryDetailsWrapper>
        </CardLinkWrapper>
      </SingleCard>
    )
  }
}

export default OrgUpdateStoryCard
