import React from 'react'
import styled from 'styled-components'
import { Question } from '@styled-icons/evil'

// utils
import th from 'utils/themeHelper'

// Load components synchronously
import Tooltip from 'components/Tooltip'

const ButtonBase = styled.button`
  align-items: center;
  border: 1px solid ${props => (props.disabled ? th('borders.default') : th('borders.error'))};
  border-radius: 4px;
  background: ${props => (props.disabled ? th('backgrounds.mid') : 'white')};
  color: ${props => (props.disabled ? th('text.dark') : th('borders.error'))};
  cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};
  display: ${props => (props.disabledHint ? 'flex' : 'block')};
  font-family: ${th('fonts.bold')};
  font-size: 12px;
  height: 35px;
  outline: none;
  padding: ${th('spacing.tiny', 'px')} ${th('spacing.default', 'px')};
  transition: 0.3s box-shadow, 0.3s background;
  width: ${props => (props.wide ? '100%' : 'auto')};

  &:hover,
  &:focus {
    background: ${props => (props.disabled ? th('backgrounds.mid') : th('backgrounds.warning'))};
    color: ${th('text.dark')};
    transition: 0.3s border;
  }
`

const IconContainer = styled.i`
  line-height: 20px;
  margin-left: ${th('spacing.small', 'px')};

  & > svg {
    width: 20px;
    height: 20px;
  }
`

const DeleteButton = ({ children, disabledHint, ...props }) => {
  const showQuestion = props.disabled && !props.noQuestion

  if (props.disabled && disabledHint) {
    return (
      <Tooltip position="top" content={disabledHint} forceShow isWarning>
        <ButtonBase {...props} disabledHint={disabledHint}>
          <div>{children}</div>

          {showQuestion && (
            <IconContainer>
              <Question />
            </IconContainer>
          )}
        </ButtonBase>
      </Tooltip>
    )
  }

  return <ButtonBase {...props}>{children}</ButtonBase>
}

export default DeleteButton
