import styled from 'styled-components'
import th from '../../utils/themeHelper'
import { Link } from '@reach/router'

export const OptionsWrapper = styled.div`
  display: flex;
  flex-direction: column;
`

export const ActionLink = styled(Link)`
  text-decoration: underline;
  color: ${th('text.dark')};
`

export const ExternalLink = styled.a`
  text-decoration: underline;
  color: ${th('text.dark')};
`

export const Wrapper = styled.div`
  display: block;
`

export const RadioOption = styled.div`
  display: block;
  margin-bottom: 20px;
  margin-top: 10px;
`

export const RadioOptionText = styled.div`
  display: block;
  margin-bottom: 1em;
  margin-top: 5px;
  margin-left: 25px;
  color: ${props => (props.disabled ? th('text.light') : th('text.dark'))};
`

export const Title = styled.div`
  font-size: 13px;
  padding: 2px;
  margin-bottom: 10px;
  color: ${th('text.dark')};
  text-align: left;
  font-family: ${th('fonts.bold')};
`

export const RadioInput = styled.input.attrs({
  type: 'radio'
})``

export const RadioButtonLabel = styled.label`
  font-family: ${th('fonts.bold')};
  font-size: 15px;
  background-color: ${props => (props.disabled ? th('backgrounds.light') : 'white')};
  color: ${props => (props.disabled ? th('text.light') : th('text.dark'))};
  padding: 10px;
  border-color: th('borders.default');
  border-style: solid;
  border-width: 0;
  border-radius: 3px;

  &:hover {
    cursor: pointer;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.15);
  }
`
