import axios from 'axios'

// config
import baseUrl from '../config'

export const createApplication = async data => {
  return await axios({
    withCredentials: true,
    method: 'POST',
    url: `${baseUrl}/applications`,
    data,
    responseType: 'json',
    config: { headers: { 'Content-Type': 'multipart/form-data' } }
  })
}

export const createCampaignApplication = async data => {
  return await axios({
    withCredentials: true,
    method: 'POST',
    url: `${baseUrl}/campaign-applications`,
    data,
    responseType: 'json',
    config: { headers: { 'Content-Type': 'multipart/form-data' } }
  })
}

export const enrolStudents = async data => {
  return await axios({
    withCredentials: true,
    method: 'POST',
    url: `${baseUrl}/campaign-enrolment`,
    data,
    responseType: 'json',
    config: { headers: { 'Content-Type': 'multipart/form-data' } }
  })
}

export const fetchApplication = async ({ applicationId }) => {
  const response = await axios.get(`${baseUrl}/application/${applicationId}`, {
    withCredentials: true
  })
  return response.data
}

export const fetchApplications = async () => {
  const response = await axios.get(`${baseUrl}/all-applications`, { withCredentials: true })
  return response.data
}

export const fetchOwnApplications = async () => {
  const response = await axios.get(`${baseUrl}/own-applications`, { withCredentials: true })
  return response.data
}
