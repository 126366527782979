import React from 'react'
import { Link as ReachRouterLink } from '@reach/router'

const Link = ({ to = '', ...props }) => {
  if (to.startsWith('http') || to.startsWith('mailto:')) {
    return <a href={to} {...props} />
  } else {
    return (
      <span style={{ maxWidth: props.fullWidth ? '100%' : 'max-content' }}>
        <ReachRouterLink to={to} {...props} />
      </span>
    )
  }
}

export default Link
