import React from 'react'
import styled from 'styled-components'
import { Link } from '@reach/router'

// icons
import { CalendarHeart } from '@styled-icons/boxicons-regular'
import { CheckCircleFill } from '@styled-icons/bootstrap/CheckCircleFill'
import { Settings } from 'styled-icons/ionicons-outline'
import { User as UserIcon } from '@styled-icons/boxicons-solid/User'

// styles
import { getPillColors } from 'components/SearchResultsContainer/styles'
import DashCardWrapper from './DashCardWrapper'

// config
import l from 'config/localization'

// utils
import th from 'utils/themeHelper'

const ActionItem = styled.div`
  margin-right: 10px;

  &:last-of-type {
    margin-right: 0;
  }
`

const Actions = styled.div`
  align-items: center;
  display: flex;
  flex: 1;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin: 15px 10px 10px;
`

const ActionsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`

const EditCampaignCardLink = styled(Link)`
  border-color: ${th('backgrounds.mid')};
  border-radius: 4px;
  border-style: solid;
  border-width: 1px;
  color: ${th('text.dark')};
  margin: 0;
  padding: 8px 12px;
  text-align: center;
  text-decoration: none;
  white-space: nowrap;

  & > svg {
    width: 15px;
    margin-right: 10px;
  }
`

const Heading = styled.h4`
  display: inline-block;
  flex: 1;
  margin: 0;
  vertical-align: middle;
`

const Info = styled.div`
  display: block;
  margin: 10px 15px 0;
  flex: 1;
`

const InfoFieldLabel = styled.div`
  font-family: ${th('fonts.bold')};
  margin-right: 10px;
`

const InfoFieldValue = styled.div`
  font-family: ${th('fonts.regular')};
  margin-left: 30px;
`

const InfoFieldIcon = styled.div`
  margin-right: 10px;

  & > svg {
    height: 20px;
  }
`

const InfoGroup = styled.div`
  min-width: 30%;
`

const InfoGroupRow = styled.div`
  color: ${th('text.mid')};
  line-height: 1.25em;
  margin-bottom: 15px;
`

const InfoGroupRowInner = styled.div`
  display: flex;
  word-break: break-word;
`

const OrgMainDetailsWrapper = styled.div`
  align-items: center;
  display: flex;
  flex: 1;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin: 0 15px 10px;
`

const Pill = styled.div`
  background: ${props => props.bgColor || th('backgrounds.default')};
  color: ${props => props.fontColor || th('text.dark')};
  border-radius: 3px;
  display: inline-block;
  font-size: 9px;
  margin-top: 2px;
  padding: 2px 5px;
  text-align: center;
  text-transform: uppercase;
  width: ${props => (props.fullWidth ? '100%' : 'auto')};
`

const DashCampaignCard = ({ id, name, opp_count, signup_count, status }) => {
  const { bgColor, color } = getPillColors({ key: 'status', value: status })

  return (
    <DashCardWrapper>
      <ActionsWrapper>
        <OrgMainDetailsWrapper>
          <Heading>{name}</Heading>
        </OrgMainDetailsWrapper>

        <Info>
          <InfoGroup>
            <InfoGroupRow>
              <InfoGroupRowInner>
                <InfoFieldIcon>
                  <CalendarHeart />
                </InfoFieldIcon>
                <InfoFieldLabel>Causes</InfoFieldLabel>
              </InfoGroupRowInner>

              <InfoGroupRowInner>
                <InfoFieldValue>{opp_count}</InfoFieldValue>
              </InfoGroupRowInner>
            </InfoGroupRow>

            <InfoGroupRow>
              <InfoGroupRowInner>
                <InfoFieldIcon>
                  <UserIcon />
                </InfoFieldIcon>
                <InfoFieldLabel>Signups</InfoFieldLabel>
              </InfoGroupRowInner>

              <InfoGroupRowInner>
                <InfoFieldValue>{signup_count}</InfoFieldValue>
              </InfoGroupRowInner>
            </InfoGroupRow>
          </InfoGroup>

          <InfoGroupRow>
            <InfoGroupRowInner>
              <InfoFieldIcon>
                <CheckCircleFill />
              </InfoFieldIcon>
              <InfoFieldLabel>Status</InfoFieldLabel>
            </InfoGroupRowInner>

            <InfoGroupRowInner>
              <InfoFieldValue>
                <Pill bgColor={bgColor} fontColor={color}>
                  {status}
                </Pill>
              </InfoFieldValue>
            </InfoGroupRowInner>
          </InfoGroupRow>
        </Info>

        <Actions>
          <ActionItem>
            <EditCampaignCardLink to={`/dashboard/${l('ROUTE_CAMPS')}/${id}/`}>
              <Settings />
              <span>Edit {l('CAMP')}</span>
            </EditCampaignCardLink>
          </ActionItem>
        </Actions>
      </ActionsWrapper>
    </DashCardWrapper>
  )
}

export default DashCampaignCard
