import React from 'react'
import styled from 'styled-components'
import th from 'utils/themeHelper'

const HeaderRow = styled.div`
  @media screen and (max-width: 769px) {
    width: 100%;
  }
`

const FormFieldHeader = styled.div`
  margin-bottom: 0.5em;
`

const ClearTextWrapper = styled.div`
  position: relative;
`

const ClearText = styled.p`
  @media screen and (min-width: 1024px) {
    left: 0;
    line-height: 1.25em;
    margin: 20px 0 0 465px;
    position: absolute;
  }

  color: ${th('text.dark')};
  cursor: pointer;
  font-family: ${th('fonts.bold')};
  text-align: right;
`

const FormFieldGroupHeader = ({ children, clearable, onClear, value }) => {
  if (!value.start_date) return null

  if (clearable) {
    return (
      <HeaderRow>
        <FormFieldHeader>{children}</FormFieldHeader>
        <ClearTextWrapper>
          <ClearText onClick={() => onClear()}>clear</ClearText>
        </ClearTextWrapper>
      </HeaderRow>
    )
  }

  return <FormFieldHeader>{children}</FormFieldHeader>
}

export default FormFieldGroupHeader
