import axios from 'axios'

// config
import baseUrl from '../config'

//
// Host capacity
//

export const fetchHostCheckInInfo = async params => {
  const response = await axios.get(`${baseUrl}/fetch-host-checkin-info`, {
    withCredentials: true,
    params
  })
  return response.data
}

export const hostFeedback = async data => {
  const response = await axios({
    withCredentials: true,
    method: 'post',
    url: `${baseUrl}/host-feedback`,
    data,
    config: { headers: { 'Content-Type': 'multipart/form-data' } }
  })
  return response.data
}

//
// Personal capacity
// For challenges, please see ./apiModules/opportunities
//

// fetch app info for volunteer type opportunity
export const fetchApplicationInfo = async params => {
  const response = await axios.get(`${baseUrl}/fetch-application-info`, {
    withCredentials: true,
    params
  })
  return response.data
}

// Submit volunteer feedback for volunteer opportunity
export const volunteerFeedback = async data => {
  const response = await axios({
    withCredentials: true,
    method: 'post',
    url: `${baseUrl}/volunteer-feedback`,
    data,
    config: { headers: { 'Content-Type': 'multipart/form-data' } }
  })
  return response.data
}
