import { createActions, handleActions } from 'redux-actions'
import UrlBuilder from 'utils/urlBuilder'

// squads
export const FETCH_SQUADS = 'teams/FETCH_SQUADS'
export const FETCH_SQUADS_SUCCESS = 'teams/FETCH_SQUADS_SUCCESS'
export const FETCH_SQUADS_FAILURE = 'teams/FETCH_SQUADS_FAILURE'

export const CREATE_SQUAD = 'teams/CREATE_SQUAD'
export const CREATE_SQUAD_SUCCESS = 'teams/CREATE_SQUAD_SUCCESS'
export const CREATE_SQUAD_FAILURE = 'teams/CREATE_SQUAD_FAILURE'

export const UPDATE_SQUAD = 'teams/UPDATE_SQUAD'
export const UPDATE_SQUAD_SUCCESS = 'teams/UPDATE_SQUAD_SUCCESS'
export const UPDATE_SQUAD_FAILURE = 'teams/UPDATE_SQUAD_FAILURE'

export const FETCH_SQUAD = 'teams/FETCH_SQUAD'
export const FETCH_SQUAD_SUCCESS = 'teams/FETCH_SQUAD_SUCCESS'
export const FETCH_SQUAD_FAILURE = 'teams/FETCH_SQUAD_FAILURE'

// teams
export const FETCH_TEAMS = 'teams/FETCH_TEAMS'
export const FETCH_TEAMS_SUCCESS = 'teams/FETCH_TEAMS_SUCCESS'
export const FETCH_TEAMS_FAILURE = 'teams/FETCH_TEAMS_FAILURE'

export const CREATE_TEAM = 'teams/CREATE_TEAM'
export const CREATE_SUCCESS = 'teams/CREATE_SUCCESS'
export const CREATE_FAILURE = 'teams/CREATE_FAILURE'

export const UPDATE_TEAM = 'teams/UPDATE_TEAM'
export const UPDATE_SUCCESS = 'teams/UPDATE_SUCCESS'
export const UPDATE_FAILURE = 'teams/UPDATE_FAILURE'

export const FETCH_TEAM = 'teams/FETCH_TEAM'
export const SINGLE_FETCH_SUCCESS = 'teams/SINGLE_FETCH_SUCCESS'
export const SINGLE_FETCH_FAILURE = 'teams/SINGLE_FETCH_FAILURE'

export const {
  teams: {
    // squads
    createSquad,
    createSquadSuccess,
    createSquadFailure,
    updateSquad,
    updateSquadSuccess,
    updateSquadFailure,
    fetchSquads,
    fetchSquadsSuccess,
    fetchSquadsFailure,
    fetchSquad,
    fetchSquadSuccess,
    fetchSquadFailure,
    // teams
    createTeam,
    createSuccess,
    createFailure,
    updateTeam,
    updateSuccess,
    updateFailure,
    fetchTeams,
    fetchTeamsSuccess,
    fetchTeamsFailure,
    fetchTeam,
    singleFetchSuccess,
    singleFetchFailure
  }
} = createActions({
  // squads
  [CREATE_SQUAD]: undefined,
  [CREATE_SQUAD_SUCCESS]: undefined,
  [CREATE_SQUAD_FAILURE]: undefined,
  [UPDATE_SQUAD]: undefined,
  [UPDATE_SQUAD_SUCCESS]: undefined,
  [UPDATE_SQUAD_FAILURE]: undefined,
  [FETCH_SQUADS]: undefined,
  [FETCH_SQUADS_SUCCESS]: undefined,
  [FETCH_SQUADS_FAILURE]: undefined,
  [FETCH_SQUAD]: undefined,
  [FETCH_SQUAD_SUCCESS]: undefined,
  [FETCH_SQUAD_FAILURE]: undefined,

  // teams
  [CREATE_TEAM]: undefined,
  [CREATE_SUCCESS]: undefined,
  [CREATE_FAILURE]: undefined,
  [UPDATE_TEAM]: undefined,
  [UPDATE_SUCCESS]: undefined,
  [UPDATE_FAILURE]: undefined,
  [FETCH_TEAMS]: undefined,
  [FETCH_TEAMS_SUCCESS]: undefined,
  [FETCH_TEAMS_FAILURE]: undefined,
  [FETCH_TEAM]: undefined,
  [SINGLE_FETCH_SUCCESS]: undefined,
  [SINGLE_FETCH_FAILURE]: undefined
})

const initialPaginatedData = {
  pageCount: 0,
  teamsCount: 0
}

const initialPaginatedSquadData = {
  pageCount: 0,
  squadsCount: 0
}

const initialState = {
  isLoading: null,
  isSuccess: null,
  isSubmitting: false,
  hasError: false,
  errorMessage: null,
  singleSquadData: {},
  singlePersonData: {},
  singleTeamData: {},
  squadData: [],
  teamData: [],
  teamGroupData: [],
  paginatedSquadsData: initialPaginatedSquadData,
  paginatedTeamsData: initialPaginatedData
}

export default handleActions(
  {
    [CREATE_SQUAD]: (state, { payload }) => ({
      ...state,
      ...payload,
      isLoading: false,
      isSubmitting: true
    }),
    [CREATE_SQUAD_SUCCESS]: (state, { payload }) => {
      if (!payload.isPartialUpdate) {
        UrlBuilder.displayUpdateSuccessMessage()
      }

      return { ...state, singleSquadData: payload, isLoading: false, isSubmitting: false }
    },
    [CREATE_SQUAD_FAILURE]: (state, { payload }) => ({
      ...state,
      hasError: true,
      errorMessage: payload,
      isLoading: false,
      isSubmitting: false
    }),
    [UPDATE_SQUAD]: state => ({ ...state, isLoading: false, isSubmitting: true }),
    [UPDATE_SQUAD_SUCCESS]: (state, { payload }) => {
      if (!payload.isPartialUpdate) {
        UrlBuilder.displayUpdateSuccessMessage()
      }

      const updatedData = [payload, ...state.squadData.filter(o => o.id !== payload.id)]
      return { ...state, isLoading: false, isSubmitting: false, squadData: updatedData }
    },
    [UPDATE_SQUAD_FAILURE]: state => ({ ...state, isLoading: false, isSubmitting: false }),
    [FETCH_SQUADS]: state => ({ ...state, isLoading: true }),
    [FETCH_SQUADS_SUCCESS]: (state, { payload }) => {
      const newState = {
        ...state,
        isLoading: false,
        isSuccess: true,
        hasError: false,
        squadData: payload.squadData,
        teamGroupData: payload.teamGroupData
      }

      newState.paginatedSquadsData.pageCount = payload.pageCount || 0
      newState.paginatedSquadsData.squadsCount = payload.oppsCount || 0

      return newState
    },
    [FETCH_SQUADS_FAILURE]: (state, { payload }) => ({
      ...state,
      isSuccess: false,
      isLoading: false,
      hasError: true,
      errorMessage: payload.message
    }),
    [FETCH_SQUAD]: state => ({ ...state, isLoading: true }),
    [FETCH_SQUAD_SUCCESS]: (state, { payload }) => ({
      ...state,
      isLoading: false,
      hasSubmitted: false,
      singleSquadData: payload,
      teamId: payload.id
    }),
    [FETCH_SQUAD_FAILURE]: (state, payload) => ({ ...state, ...payload }),

    // teams

    [CREATE_TEAM]: (state, { payload }) => ({
      ...state,
      ...payload,
      isLoading: false,
      isSubmitting: true
    }),
    [CREATE_SUCCESS]: (state, { payload }) => {
      if (!payload.isPartialUpdate) {
        UrlBuilder.displayUpdateSuccessMessage()
      }

      return { ...state, singleTeamData: payload, isLoading: false, isSubmitting: false }
    },
    [CREATE_FAILURE]: (state, { payload }) => ({
      ...state,
      hasError: true,
      errorMessage: payload,
      isLoading: false,
      isSubmitting: false
    }),
    [UPDATE_TEAM]: state => ({ ...state, isLoading: false, isSubmitting: true }),
    [UPDATE_SUCCESS]: (state, { payload }) => {
      if (!payload.isPartialUpdate) {
        UrlBuilder.displayUpdateSuccessMessage()
      }

      const updatedData = [payload, ...state.teamData.filter(o => o.id !== payload.id)]
      return { ...state, isLoading: false, isSubmitting: false, teamData: updatedData }
    },
    [UPDATE_FAILURE]: state => ({ ...state, isLoading: false, isSubmitting: false }),
    [FETCH_TEAMS]: state => ({ ...state, isLoading: true }),
    [FETCH_TEAMS_SUCCESS]: (state, { payload }) => {
      const newState = {
        ...state,
        isLoading: false,
        isSuccess: true,
        hasError: false,
        teamData: payload.teamData,
        teamGroupData: payload.teamGroupData
      }

      newState.paginatedTeamsData.pageCount = payload.pageCount || 0
      newState.paginatedTeamsData.teamsCount = payload.oppsCount || 0

      return newState
    },
    [FETCH_TEAMS_FAILURE]: (state, { payload }) => ({
      ...state,
      isSuccess: false,
      isLoading: false,
      hasError: true,
      errorMessage: payload.message
    }),
    [FETCH_TEAM]: state => ({ ...state, isLoading: true }),
    [SINGLE_FETCH_SUCCESS]: (state, { payload }) => ({
      ...state,
      isLoading: false,
      hasSubmitted: false,
      singleTeamData: payload,
      teamId: payload.id
    }),
    [SINGLE_FETCH_FAILURE]: (state, payload) => ({ ...state, ...payload })
  },
  initialState
)
