import styled from 'styled-components'
import th from '../../utils/themeHelper'

const LabelNote = styled.label`
  font-size: 13px;
  line-height: 30px;
  color: ${th('text.light')};

  span {
    display: block;
    line-height: 1;
    margin: 5px 0 0;
  }
`

export default LabelNote
