import { call, put, takeLatest, select, delay } from 'redux-saga/effects'
import * as campaignsModule from '../modules/campaigns'
import * as oppsModule from '../modules/opportunities'
import * as placesModule from '../modules/places'
import * as oppsApi from '../apiModules/opportunities'
import * as placesApi from '../apiModules/places'
import placesSelectors from '../selectors/places'
import logError from '../../utils/logger'

function* filterPlaces() {
  try {
    yield put(oppsModule.fetchFilteredOpps())
    const page = 1
    const place = yield select(placesSelectors.selectedPlace())
    const oppsResponse = yield call(oppsApi.fetchByLocation, place, page)

    yield put(oppsModule.fetchFilteredSuccess(oppsResponse))
    yield put(campaignsModule.updateCampaignOpps(oppsResponse))
  } catch (err) {
    logError(err)
  }
}

function* clearPlaces() {
  try {
    yield put(oppsModule.clearOppsFilters())
  } catch (err) {
    logError(err)
  }
}

function* fetchPlaces({ payload }) {
  yield delay(400)

  try {
    const placesResponse = yield call(placesApi.fetchPlaces, payload)
    yield put(placesModule.fetchSuccess(placesResponse))
  } catch (err) {
    logError(err)
  }
}

function* fetchNearbyCities({ payload }) {
  try {
    const placesResponse = yield call(placesApi.fetchNearbyCities, payload)
    yield put(placesModule.fetchNearbyCitiesSuccess(placesResponse))
  } catch (err) {
    logError(err)
  }
}

function* fetchNearbyCitiesList({ payload }) {
  try {
    const placesResponse = yield call(placesApi.fetchNearbyCitiesList, payload)
    yield put(placesModule.fetchNearbyCitiesSuccess(placesResponse))
  } catch (err) {
    logError(err)
  }
}

export const placesSagas = [
  takeLatest(placesModule.FILTER_PLACES, filterPlaces),
  takeLatest(placesModule.FETCH_NEARBY_CITIES, fetchNearbyCities),
  takeLatest(placesModule.FETCH_NEARBY_CITIES_LIST, fetchNearbyCitiesList),
  takeLatest(placesModule.FETCH_PLACES, fetchPlaces),
  takeLatest(placesModule.CLEAR_PLACES, clearPlaces)
]
