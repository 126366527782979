import React from 'react'
import styled from 'styled-components'

// utils
import th from 'utils/themeHelper'

const SingleCardContainer = styled.div`
  background: ${props => (props.dark ? th('text.dark') : props.gold ? 'gold' : 'white')};
  border-color: ${th('borders.medium')};
  border-radius: 5px;
  border-style: solid;
  border-width: ${props => (props.dark ? '0' : '1px')};
  box-shadow: ${props => (props.dark ? `none` : `0 0 10px 0 rgba(0, 0, 0, 0.15)`)};
  display: flex;
  flex-direction: column;
  height: ${props => (props.fullHeight || props.dark ? '100%' : 'unset')};
  margin-bottom: 20px;
  overflow: hidden;
  position: relative;

  &:hover {
    box-shadow: ${props =>
      props.dark
        ? `none`
        : props.hoverShadow
        ? '0 0 4px #379b75'
        : '0 0 10px 0 rgba(0, 0, 0, 0.15)'};
  }

  &:hover .card-progress-bar-is-zero-hover {
    color: white;
    background: ${th('primary.base')};
  }
`

const SingleCard = ({ children, dark, fullHeight, gold, hoverShadow }) => {
  return (
    <SingleCardContainer dark={dark} fullHeight={fullHeight} gold={gold} hoverShadow={hoverShadow}>
      {children}
    </SingleCardContainer>
  )
}

export default SingleCard
