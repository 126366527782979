import React from 'react'
import { withScriptjs, withGoogleMap, GoogleMap, Marker } from 'react-google-maps'

const Map = ({ center }) => (
  <GoogleMap defaultZoom={15} center={{ ...center }}>
    <Marker position={{ ...center }} />
  </GoogleMap>
)

export default withScriptjs(withGoogleMap(Map))
