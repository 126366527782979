import React, { Component } from 'react'
import Select, { createFilter, components } from 'react-select'
import styled from 'styled-components'

// utils
import th from 'utils/themeHelper'

// icons
import { ArrowDropDown } from '@styled-icons/material/ArrowDropDown'
import { PlusSign } from 'components/Icons/Icons'

// Load components synchronously
import { Error, Label, RequiredTag, SubLabel, Success } from 'components/InputLabels/InputLabels'
import LinkButton from 'components/SmallButtonPrimary/LinkButton'

const ComponentError = styled.div`
  font-size: 13px;
  border: 1px solid ${th('text.error')};
  color: ${th('text.error')};
  padding: 0 8px;
  margin-bottom: ${props => (props.innerLabel ? '0' : '4px')};
  font-family: ${th('fonts.bold')};
  line-height: 1.35em;
`

export const Wrapper = styled.div`
  display: flex;
  flex: ${props => (props.flex ? '1' : 'unset')};
  flex-direction: column;
  margin-right: ${props => (props.marginRight ? '16px' : '0')};
  max-width: ${props => (props.flex ? 'initial' : '500px')};
  min-width: ${props => (props.flex ? 'initial' : '300px')};
  position: relative;

  @media screen and (max-width: 600px) {
    margin-bottom: 10px;
    max-width: 100%;
    width: 100%;
  }
`

const PlusIconWrapper = styled.span`
  display: inline-block;
  margin-right: 5px;
  width: 10px;

  svg {
    margin-top: -2px;
  }
`

const DownIcon = styled(ArrowDropDown)`
  width: 30px;
  height: 30px;
  color: ${th('background.mid')};
`

const borderColorBottom = ({ requiredField, menuOpen, hasError, isDisabled, theme }) => {
  if (isDisabled) {
    return theme.borders.medium
  } else if (menuOpen) {
    return theme.borders.default
  } else if (hasError) {
    return theme.borders.error
  } else if (requiredField) {
    return theme.borders.required
  } else {
    return theme.borders.default
  }
}

const borderColor = ({ requiredField, menuOpen, hasError, isDisabled, theme }) => {
  if (isDisabled) {
    return theme.borders.medium
  } else if (menuOpen) {
    return theme.borders.success
  } else if (hasError) {
    return theme.borders.error
  } else if (requiredField) {
    return theme.borders.required
  } else {
    return theme.borders.default
  }
}

const borderWidth = ({ requiredField, menuOpen, hasError }) => {
  if (menuOpen) {
    return '1px'
  } else if (hasError) {
    return '2px'
  } else if (requiredField) {
    return '2px'
  } else {
    return '1px'
  }
}

const SingleSelectOptionIcon = ({ isDisabled, padding, src }) => {
  return (
    <SingleSelectOptionIconInner isDisabled={isDisabled}>
      <SingleSelectPaddedIcon padding={padding} src={src} />
    </SingleSelectOptionIconInner>
  )
}

const SingleSelectPaddedIcon = styled.img`
  height: 30px;
  padding: ${props => props.padding || '0'};
  width: 30px;
`

const SingleSelectOptionLabel = styled.div`
  color: ${props => (props.isDisabled ? th('text.mid') : th('text.dark'))};
`

const OptionWrapper = styled.div`
  &:hover {
    background: ${props => (props.isDisabled ? th('backgrounds.mid') : th('secondary.lightest'))};
  }
`

const SingleSelectOptionIconInner = styled.span`
  background: ${props => (props.isDisabled ? th('backgrounds.mid') : 'none')};
  border: 1px solid ${th('borders.dark')};
  border-radius: 50%;
  display: inline-block;
  filter: grayscale(${props => (props.isDisabled ? '1' : '0')});
  height: 30px;
  line-height: 1;
  margin-right: 10px;
  overflow: hidden;
  vertical-align: middle;
  width: 30px;
`

const StyledSelect = styled(Select)`
  .Select__value-container--has-value {
    line-height: 1;
    overflow: visible;
  }

  .Select__control {
    border-style: solid;
    border-width: ${borderWidth};
    border-color: ${borderColor};
    border-bottom-color: ${borderColorBottom};
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
    border-bottom-left-radius: ${({ menuOpen }) => (menuOpen ? '0px' : '3px')};
    border-bottom-right-radius: ${({ menuOpen }) => (menuOpen ? '0px' : '3px')};
    appearance: none;
    height: 45px;
    font-family: ${th('fonts.light')};

    &:hover {
      border-color: ${borderColor};
      border-bottom-color: ${borderColorBottom};
    }
  }

  .Select__control.Select__control--is-focused {
    box-shadow: none;
    border-color: ${borderColor};
    border-bottom-color: ${borderColorBottom};

    &:hover {
      border-color: ${borderColor};
      border-bottom-color: ${borderColorBottom};
    }
  }

  .Select__placeholder {
    color: ${th('text.dark')};
    margin-top: 2px;
  }

  .Select__single-value {
    align-items: center;
    color: ${th('text.dark')};
    display: flex;
  }

  .Select__dropdown-indicator {
    color: ${th('background.mid')};
    padding: 5px 8px;

    &:focus,
    &:hover {
      color: ${th('background.mid')};
    }
  }

  .Select__menu {
    box-shadow: none;
    border-style: solid;
    border-width: ${borderWidth};
    border-color: ${th('secondary.base')};
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;
    border-top: none;
    margin: 0;
    z-index: 3;
  }

  .Select__menu-list {
    padding: 0;
  }

  .Select__option {
    background: ${props => (props.isDisabled ? th('backgrounds.mid') : 'transparent')};
    display: flex;
    flex-wrap: wrap;
    align-items: center;

    &:last-child {
      border-bottom-left-radius: 3px;
      border-bottom-right-radius: 3px;
    }
  }

  .Select__clear-indicator {
    background: white;
    border-bottom-right-radius: 3px;
    border-color: ${borderColor};
    border-style: solid;
    border-top-right-radius: 3px;
    border-width: ${borderWidth};
    // below border-width for specificity
    border-left-width: 0;
    color: ${th('text.light')};
    cursor: pointer;
    height: ${props => {
      const preAdjustedWidth = borderWidth(props)
      const adjustedWidth = preAdjustedWidth === '2px' ? '4px' : '2px'
      return 'calc(100% + ' + adjustedWidth + ')'
    }};
    padding: 10px 12px 8px 8px;
    position: absolute;
    right: -2px;
    top: ${props => {
      const preAdjustedWidth = borderWidth(props)
      const offset = preAdjustedWidth === '2px' ? '-2px' : '-1px'
      return offset
    }};
    z-index: 1;

    &:hover {
      color: ${th('primary.dark')};
    }

    &:before {
      content: 'remove';
      font-size: 14px;
      margin-right: 2px;
      margin-top: 2px;
    }
  }
`
const IndicatorSeparator = () => {
  return null
}

const SelectOption = props => {
  // Disable onMouseMove and onMouseOver events for better performance when using icons/images
  // but only when over 200 items in the list
  // eslint-disable-next-line no-unused-vars
  const { onMouseMove, onMouseOver, ...rest } = props.innerProps
  const hasTooManyOptions = props.options.length > 2000
  const innerPropsToUse = hasTooManyOptions ? rest : props.innerProps

  const newProps = { ...props, innerProps: innerPropsToUse }
  const { data } = props
  const { disabled } = data
  const icon = data.icon || data.image

  const optionDisabled = disabled || data.isDisabled

  if (!icon) {
    return (
      <OptionWrapper isDisabled={disabled}>
        <components.Option {...newProps} isDisabled={optionDisabled}>
          {disabled ? `${data.label} (Not available)` : data.label}
        </components.Option>
      </OptionWrapper>
    )
  }

  return (
    <OptionWrapper isDisabled={disabled} {...data}>
      <components.Option {...newProps} isDisabled={optionDisabled} {...data}>
        <SingleSelectOptionIcon {...data} isDisabled={disabled} padding={data.padding} src={icon} />
        <SingleSelectOptionLabel {...data} isDisabled={disabled}>
          {disabled ? `${data.label} (Not available)` : data.label}
        </SingleSelectOptionLabel>
      </components.Option>
    </OptionWrapper>
  )
}

const SingleValue = props => {
  const { data } = props
  const { disabled } = data
  const icon = data.icon || data.image

  if (!icon) {
    return (
      <components.SingleValue {...props} isDisabled={disabled}>
        {data.label}
      </components.SingleValue>
    )
  }

  return (
    <components.SingleValue {...props} isDisabled={disabled}>
      <SingleSelectOptionIcon isDisabled={disabled} padding={data.padding} src={icon} />
      <SingleSelectOptionLabel isDisabled={disabled}>{data.label}</SingleSelectOptionLabel>
    </components.SingleValue>
  )
}

const HiddenDropdownIndicator = props => {
  return (
    components.DropdownIndicator && (
      <components.DropdownIndicator {...props}>
        <PlusSign />
      </components.DropdownIndicator>
    )
  )
}

const DropdownIndicator = props => {
  return (
    components.DropdownIndicator && (
      <components.DropdownIndicator {...props}>
        <DownIcon />
      </components.DropdownIndicator>
    )
  )
}

const getOptFromId = ({ opts, id }) => {
  if (!id) return null
  return opts.find(o => o.value === id)
}

const getOptFromOptionalValue = ({ opts, optionalValue }) => {
  const first = opts.find(o => o.value === optionalValue)
  if (first) return first

  const second = optionalValue && optionalValue.value ? optionalValue.value : null
  if (second) return opts.find(o => o.value === optionalValue.value)

  const third = opts.find(o => o.value === optionalValue)
  if (third) return third

  return null
}

const customStyles = {
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isDisabled ? th('backgrounds.mid') : 'transparent'
  })
}

// this isn't great
// test all <Select ... /> and component={Select} instances when refactoring this
const valueFromId = (opts, id, optionalValue) => {
  if (!opts) return null

  const optFromId = getOptFromId({ opts, id })
  const optFromOptionalValue = getOptFromOptionalValue({ opts, optionalValue })

  return optFromId || optFromOptionalValue
}

class MySelect extends Component {
  state = {
    menuOpen: false,
    isExpanded: null
  }

  // Integrate with Formik
  onChange = option => {
    // do nothing for a disabled option
    if (option.disabled) return true

    const { field, form } = this.props

    if (form) {
      form.setFieldValue(field.name, option ? option.value : null)
    }

    if (this.props.onChange) {
      this.props.onChange(option)
    }
  }

  expandTextArea = () => {
    this.setState({ isExpanded: true })
  }

  render() {
    const { isExpanded, menuOpen } = this.state
    const {
      className,
      expandable,
      expandButtonText,
      field = {},
      flex,
      form: { errors = {}, touched = {} } = {},
      label,
      marginRight,
      subLabel,
      success,
      requiredField,
      ...props
    } = this.props
    const displayableLabel = requiredField ? `${label} *` : label

    if (expandable && requiredField) {
      return (
        <ComponentError>
          TextArea component cannot accept both the <code>expandable</code> prop and the{' '}
          <code>requiredField</code> prop together.
        </ComponentError>
      )
    }

    // show expand button when needed, unless the field has a value already
    if (!isExpanded && expandable && !field.value) {
      return (
        <div>
          {!expandButtonText && <Label htmlFor={field.name}>{displayableLabel}</Label>}
          {subLabel && !expandButtonText && <SubLabel subLabel={subLabel}>{subLabel}</SubLabel>}

          <LinkButton disabled={props.disabled} onClick={() => this.expandTextArea()}>
            <PlusIconWrapper>
              <PlusSign />
            </PlusIconWrapper>

            <span>{expandButtonText}</span>
          </LinkButton>
        </div>
      )
    }

    const val = valueFromId(this.props.options, field.value, this.props.value)
    const hasError = errors[field.name] || errors[`${field.name}_collection`]

    const DropdownIndicatorToUse = props.disabled ? HiddenDropdownIndicator : DropdownIndicator

    return (
      <Wrapper className={className} flex={flex} marginRight={marginRight}>
        {hasError ? (
          <Error>{hasError}</Error>
        ) : label ? (
          <Label htmlFor={field.name}>{displayableLabel}</Label>
        ) : null}

        {subLabel && <SubLabel subLabel={subLabel}>{subLabel}</SubLabel>}

        <RequiredTag hasError={hasError} label={label} requiredField={requiredField} />

        <StyledSelect
          requiredField={requiredField}
          classNamePrefix="Select"
          isDisabled={props.disabled}
          filterOption={createFilter({ ignoreAccents: false })}
          components={{
            DropdownIndicator: DropdownIndicatorToUse,
            IndicatorSeparator,
            Option: SelectOption,
            SingleValue
          }}
          {...field}
          {...props}
          menuOpen={menuOpen}
          touched={!!touched[field.name]}
          hasError={!!hasError}
          onMenuOpen={() => this.setState({ menuOpen: true })}
          onMenuClose={() => this.setState({ menuOpen: false })}
          onChange={this.onChange}
          hideSelectedOptions={true}
          value={val || props.disabledPlaceholder || props.placeholder}
          placeholder={val ? '' : props.placeholder}
          styles={customStyles}
        />

        {success && touched[field.name] && !hasError && <Success>{success}</Success>}
      </Wrapper>
    )
  }
}

export default MySelect
