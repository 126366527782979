import { call, put, takeLatest } from 'redux-saga/effects'

import * as authenticationModule from '../modules/authentication'
import * as authApi from '../apiModules/authentication'

import * as followingModule from '../modules/followings'
import * as followingsApi from '../apiModules/followings'

function* followEntity({ payload }) {
  try {
    const response = yield call(followingsApi.followEntity, payload)
    yield put(followingModule.followEntitySuccess(response))

    const authResponse = yield call(authApi.fetchMyRoles)
    yield put(authenticationModule.fetchMyRolesSuccess(authResponse))
  } catch (err) {
    yield put(followingModule.followEntityFailure(err.response.data))
  }
}

function* unfollowEntity({ payload }) {
  try {
    const response = yield call(followingsApi.unfollowEntity, payload)
    yield put(followingModule.unfollowEntitySuccess(response))

    const authResponse = yield call(authApi.fetchMyRoles)
    yield put(authenticationModule.fetchMyRolesSuccess(authResponse))
  } catch (err) {
    yield put(followingModule.unfollowEntityFailure(err.response.data))
  }
}

export const followingsSagas = [
  takeLatest(followingModule.FOLLOW_ENTITY, followEntity),
  takeLatest(followingModule.UNFOLLOW_ENTITY, unfollowEntity)
]
