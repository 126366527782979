import React from 'react'
import styled from 'styled-components'
import { Link } from '@reach/router'

// icons
import { CheckCircleFill } from '@styled-icons/bootstrap/CheckCircleFill'
import { Label } from '@styled-icons/material/Label'
import { Settings } from 'styled-icons/ionicons-outline'

// config
import { isDgmt } from 'config/localization'

// utils
import th from 'utils/themeHelper'

// styles
import { getPillColors } from 'components/SearchResultsContainer/styles'
import DashCardWrapper from './DashCardWrapper'

// Load components synchronously
import ConditionalDisplay from 'components/ConditionalDisplay/ConditionalDisplay'

const ActionItem = styled.div`
  margin-right: 10px;

  &:last-of-type {
    margin-right: 0;
  }
`

const Actions = styled.div`
  align-items: center;
  display: flex;
  flex: 1;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin: 10px;
`

const ActionsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`

const EditStoryCardLink = styled(Link)`
  border-color: ${th('backgrounds.mid')};
  border-radius: 4px;
  border-style: solid;
  border-width: 1px;
  color: ${th('text.dark')};
  margin: 0;
  padding: 8px 12px;
  text-align: center;
  text-decoration: none;
  white-space: nowrap;

  & > svg {
    width: 15px;
    margin-right: 10px;
  }
`

const Heading = styled.h4`
  display: inline-block;
  flex: 1;
  margin: 0;
  text-decoration: underline;
  vertical-align: middle;
`

const Info = styled.div`
  display: block;
  margin: 10px 15px 0;
  flex: 1;
`

const InfoFieldLabel = styled.div`
  font-family: ${th('fonts.bold')};
  margin-right: 10px;
`

const InfoFieldValue = styled.div`
  font-family: ${th('fonts.regular')};
  margin-left: 30px;
`

const InfoFieldIcon = styled.div`
  margin-right: 10px;

  & > svg {
    height: 20px;
  }
`

const InfoGroup = styled.div`
  min-width: 30%;
`

const InfoGroupRow = styled.div`
  color: ${th('text.mid')};
  line-height: 1.25em;
  margin-bottom: 15px;
`

const InfoGroupRowInner = styled.div`
  display: flex;
  word-break: break-word;
`

const OrgMainDetailsWrapper = styled.div`
  align-items: center;
  display: flex;
  flex: 1;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin: 0 15px 10px;
`

const Pill = styled.div`
  background: ${props => props.bgColor || th('backgrounds.default')};
  color: ${props => props.fontColor || th('text.dark')};
  border-radius: 3px;
  display: inline-block;
  font-size: 9px;
  margin-top: 2px;
  padding: 2px 5px;
  text-align: center;
  text-transform: uppercase;
  width: ${props => (props.fullWidth ? '100%' : 'auto')};
`

const StoryLink = styled(Link)`
  color: ${th('text.dark')};
  margin: 0;
  text-decoration: underline;
`

const DashStoryCard = ({ id, story_status, story_target_url, story_title, story_type }) => {
  const { bgColor, color } = getPillColors({ key: 'story_status', value: story_status })

  return (
    <DashCardWrapper>
      <ActionsWrapper>
        <OrgMainDetailsWrapper>
          <StoryLink to={story_target_url || '#'}>
            <Heading>{story_title}</Heading>
          </StoryLink>
        </OrgMainDetailsWrapper>

        <Info>
          <InfoGroup>
            <InfoGroupRow>
              <InfoGroupRowInner>
                <InfoFieldIcon>
                  <Label />
                </InfoFieldIcon>
                <InfoFieldLabel>Story type</InfoFieldLabel>
              </InfoGroupRowInner>

              <InfoGroupRowInner>
                <InfoFieldValue>{story_type || 'N/A'}</InfoFieldValue>
              </InfoGroupRowInner>
            </InfoGroupRow>

            <InfoGroupRow>
              <InfoGroupRow>
                <InfoGroupRowInner>
                  <InfoFieldIcon>
                    <CheckCircleFill />
                  </InfoFieldIcon>
                  <InfoFieldLabel>Visibility</InfoFieldLabel>
                </InfoGroupRowInner>

                <InfoGroupRowInner>
                  <InfoFieldValue>
                    <Pill bgColor={bgColor} fontColor={color}>
                      {story_status}
                    </Pill>
                  </InfoFieldValue>
                </InfoGroupRowInner>
              </InfoGroupRow>
            </InfoGroupRow>
          </InfoGroup>
        </Info>

        <ConditionalDisplay displayWhen={[!isDgmt]}>
          <Actions>
            <ActionItem>
              <EditStoryCardLink to={`/dashboard/stories/${id}/`}>
                <Settings />
                <span>Edit story</span>
              </EditStoryCardLink>
            </ActionItem>
          </Actions>
        </ConditionalDisplay>
      </ActionsWrapper>
    </DashCardWrapper>
  )
}

export default DashStoryCard
