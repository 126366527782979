import { createActions, handleActions } from 'redux-actions'

export const ADD_DONATION_SUBSCRIPTION = 'donationSubscriptions/ADD_DONATION_SUBSCRIPTION'
export const ADD_DONATION_SUBSCRIPTION_FAILURE =
  'donationSubscriptions/ADD_DONATION_SUBSCRIPTION_FAILURE'
export const ADD_DONATION_SUBSCRIPTION_SUCCESS =
  'donationSubscriptions/ADD_DONATION_SUBSCRIPTION_SUCCESS'

export const FETCH_DONATION_SUBSCRIPTIONS = 'donationSubscriptions/FETCH_DONATION_SUBSCRIPTIONS'
export const FETCH_DONATION_SUBSCRIPTIONS_FAILURE =
  'donationSubscriptions/FETCH_DONATION_SUBSCRIPTIONS_FAILURE'
export const FETCH_DONATION_SUBSCRIPTIONS_SUCCESS =
  'donationSubscriptions/FETCH_DONATION_SUBSCRIPTIONS_SUCCESS'

export const UPDATE_DONATION_SUBSCRIPTIONS = 'donationSubscriptions/UPDATE_DONATION_SUBSCRIPTIONS'
export const UPDATE_DONATION_SUBSCRIPTIONS_FAILURE =
  'donationSubscriptions/UPDATE_DONATION_SUBSCRIPTIONS_FAILURE'
export const UPDATE_DONATION_SUBSCRIPTIONS_SUCCESS =
  'donationSubscriptions/UPDATE_DONATION_SUBSCRIPTIONS_SUCCESS'

export const LOGOUT_SUCCESS = 'authentication/LOGOUT_SUCCESS'

export const {
  donationSubscriptions: {
    addDonationSubscription,
    addDonationSubscriptionSuccess,
    addDonationSubscriptionFailure,
    fetchDonationSubscriptions,
    fetchDonationSubscriptionsSuccess,
    fetchDonationSubscriptionsFailure,
    updateDonationSubscriptions,
    updateDonationSubscriptionsSuccess,
    updateDonationSubscriptionsFailure
  }
} = createActions({
  [ADD_DONATION_SUBSCRIPTION]: undefined,
  [ADD_DONATION_SUBSCRIPTION_FAILURE]: undefined,
  [ADD_DONATION_SUBSCRIPTION_SUCCESS]: undefined,
  [FETCH_DONATION_SUBSCRIPTIONS]: undefined,
  [FETCH_DONATION_SUBSCRIPTIONS_FAILURE]: undefined,
  [FETCH_DONATION_SUBSCRIPTIONS_SUCCESS]: undefined,
  [UPDATE_DONATION_SUBSCRIPTIONS]: undefined,
  [UPDATE_DONATION_SUBSCRIPTIONS_FAILURE]: undefined,
  [UPDATE_DONATION_SUBSCRIPTIONS_SUCCESS]: undefined
})

const initialState = {
  isLoading: false,
  isError: false,
  isSuccess: null,
  data: [],
  donationConfig: {}
}

const deriveNewDonationOptions = ({ donationConfig, payload }) => {
  if (!payload.billing_day) return donationConfig

  return { ...donationConfig, billing_day: payload.billing_day }
}

const deriveNewData = ({ data, payload }) => {
  const { action } = payload

  if (action === 'cancel-all') {
    return data.map(d => ({ ...d, status: 'Cancelled' }))
  }

  if (action === 'pause-all') {
    return data.map(d => {
      const isActive = d.status === 'Active'
      return { ...d, status: isActive ? 'Paused' : d.status }
    })
  }

  if (action === 'resume-all') {
    return data.map(d => {
      const isPaused = d.status === 'Paused'
      return { ...d, status: isPaused ? 'Active' : d.status }
    })
  }

  if (payload.donationSubscriptionId) {
    return data.map(d => {
      if (d.id !== payload.donationSubscriptionId) return d

      // update record
      return {
        ...d,
        amount: payload.amount || d.amount,
        status: payload.subscription_status || d.status
      }
    })
  }

  return data
}

export default handleActions(
  {
    [FETCH_DONATION_SUBSCRIPTIONS]: state => ({
      ...state,
      isError: null,
      isSuccess: null,
      isLoading: true
    }),
    [FETCH_DONATION_SUBSCRIPTIONS_SUCCESS]: (state, { payload }) => ({
      ...state,
      isError: false,
      isSuccess: true,
      data: payload.data,
      donationConfig: payload.donationConfig,
      isLoading: false
    }),
    [FETCH_DONATION_SUBSCRIPTIONS_FAILURE]: state => ({
      ...state,
      isSuccess: false,
      data: [],
      isLoading: false,
      isError: true
    }),
    [ADD_DONATION_SUBSCRIPTION]: state => ({ ...state, isLoading: false, isSubmitting: true }),
    [ADD_DONATION_SUBSCRIPTION_SUCCESS]: state => {
      return {
        ...state,
        isLoading: false,
        isSubmitting: false,
        errorMessage: null
      }
    },
    [ADD_DONATION_SUBSCRIPTION_FAILURE]: state => ({
      ...state,
      isLoading: false,
      isSubmitting: false
    }),
    [UPDATE_DONATION_SUBSCRIPTIONS]: state => ({
      ...state,
      isError: null,
      isSuccess: null,
      isLoading: true
    }),
    [UPDATE_DONATION_SUBSCRIPTIONS_SUCCESS]: (state, { payload }) => {
      const data = deriveNewData({ data: state.data, payload })
      const donationConfig = deriveNewDonationOptions({
        donationConfig: state.donationConfig,
        payload
      })

      return {
        ...state,
        data,
        donationConfig,
        isError: false,
        isLoading: false,
        isSuccess: true
      }
    },
    [UPDATE_DONATION_SUBSCRIPTIONS_FAILURE]: state => ({
      ...state,
      data: [],
      isError: true,
      isLoading: false,
      isSuccess: false
    }),
    [LOGOUT_SUCCESS]: () => initialState
  },
  initialState
)
