import axios from 'axios'

// config
import baseUrl from '../config'

export const generatePaymentIdentifier = data => {
  const url = `${baseUrl}/platform-subscriptions/generate-payment-identifier`
  return axios.post(url, { data, withCredentials: true })
}

export const confirmSuccessfulPlatformPayment = data => {
  const url = `${baseUrl}/platform-subscriptions/payment-result-success`
  return axios.post(url, { data, withCredentials: true })
}

export const recordFailedPayment = data => {
  const url = `${baseUrl}/platform-subscriptions/payment-result-failure`
  return axios.post(url, { data, withCredentials: true })
}

export const cancelPlatformSubscription = data => {
  const url = `${baseUrl}/platform-subscriptions/cancel-subscription`
  return axios.post(url, { data, withCredentials: true })
}

export const fetchPlatformSubscription = async params => {
  const url = `${baseUrl}/platform-subscriptions/fetch-subscription`
  const result = await axios.get(url, { params, withCredentials: true })

  return result.data
}
