import { navigate } from '@reach/router'
import { call, put, takeLatest } from 'redux-saga/effects'

// utils
import isLoggedIn from 'utils/auth'
import UrlBuilder from 'utils/urlBuilder'
import logError from 'utils/logger'

// redux
import * as opportunitiesApi from '../apiModules/opportunities'
import * as opportunitiesModule from '../modules/opportunities'

// config
import l from 'config/localization'

function* fetchOpportunities({ payload }) {
  try {
    const response = yield call(opportunitiesApi.fetchOpportunities, payload)
    yield put(opportunitiesModule.fetchSuccess(response))
  } catch (err) {
    yield put(opportunitiesModule.fetchFailure(err))
  }
}

function* fetchOwnOpportunities({ payload }) {
  try {
    const response = yield call(opportunitiesApi.fetchOwnOpportunities, payload)
    yield put(opportunitiesModule.fetchSuccess(response))
  } catch (err) {
    yield put(opportunitiesModule.fetchFailure(err))
  }
}

function* fetchOpportunity({ payload }) {
  try {
    const response = yield call(opportunitiesApi.fetchOpportunity, payload)
    yield put(opportunitiesModule.singleFetchSuccess(response))
  } catch (err) {
    if (err.response.status === 403) {
      if (!isLoggedIn()) {
        return navigate(
          `/login?redirectingToPvt=true&redirectTo=/${l('ROUTE_OPPS')}/${payload.missionId}`
        )
      }

      yield put(
        opportunitiesModule.singleFetchFailure({
          hasError: true,
          errorCode: '403'
        })
      )
    } else if (err.response.status === 404) {
      yield put(
        opportunitiesModule.singleFetchFailure({
          hasError: true,
          errorCode: '404'
        })
      )
    } else {
      yield put(opportunitiesModule.singleFetchFailure(err))
    }
  }
}

function* fetchOppForAdmin({ payload }) {
  try {
    const response = yield call(opportunitiesApi.fetchOppForAdmin, payload)
    yield put(opportunitiesModule.singleFetchSuccess(response))
  } catch (err) {
    yield put(opportunitiesModule.singleFetchFailure(err.response.status))
  }
}

function* fetchByLocation({ payload }) {
  const page = 1
  try {
    const response = yield call(opportunitiesApi.fetchByLocation, payload, page)
    yield put(opportunitiesModule.fetchSuccess(response))
  } catch (err) {
    logError(err)
  }
}

function* createOpportunity({ payload }) {
  try {
    const formDataVals = new FormData()

    Object.keys(payload).map(value => {
      const isCustomInput = [
        'customFeedbackQuestions',
        'customSignupQuestions',
        'goodsItems'
      ].includes(value)

      if (payload[value] && isCustomInput) {
        formDataVals.append(value, JSON.stringify(payload[value]))
      } else if (payload[value]) {
        formDataVals.append(value, payload[value])
      }
    })

    const { data } = yield call(opportunitiesApi.createOpportunity, formDataVals)
    yield put(opportunitiesModule.createSuccess({ data }))
  } catch (err) {
    const errorMessage = err.response.data
    yield put(opportunitiesModule.createFailure({ errorMessage, data: payload }))
  }
}

function* challengeCheckin({ payload }) {
  try {
    const formDataVals = new FormData()

    Object.keys(payload).map(value => {
      if (payload[value]) {
        formDataVals.append(value, payload[value])
      }
    })

    const updatedOpportunity = yield call(opportunitiesApi.challengeCheckin, formDataVals)
    yield put(opportunitiesModule.challengeCheckinSuccess(updatedOpportunity))

    return navigate(`/${l('ROUTE_OPPS')}/${payload.missionId}`)
  } catch (err) {
    yield put(opportunitiesModule.challengeCheckinFailure())
  }
}

function* updateOpportunity({ payload }) {
  try {
    const values = payload.opportunity
    const formDataVals = new FormData()

    Object.keys(values).map(value => {
      const isCustomInput = [
        'customFeedbackQuestions',
        'customSignupQuestions',
        'goodsItems'
      ].includes(value)

      if (values[value] && isCustomInput) {
        formDataVals.append(value, JSON.stringify(values[value]))
      } else if (values[value]) {
        formDataVals.append(value, values[value])
      } else if (value === 'campaign_id') {
        formDataVals.append(value, values[value])
      }
    })

    const updatedOpportunity = yield call(opportunitiesApi.updateOpportunity, formDataVals)
    yield put(opportunitiesModule.updateSuccess(updatedOpportunity))
  } catch (err) {
    yield put(opportunitiesModule.updateFailure())
  }
}

function* deleteOpportunity({ payload }) {
  try {
    yield call(opportunitiesApi.deleteOpportunity, payload)
    yield put(opportunitiesModule.deleteSuccess(payload))
    UrlBuilder.displayDeleteSuccessMessage()
  } catch (err) {
    yield put(opportunitiesModule.deleteFailure())
  }
}

export const opportunitiesSagas = [
  takeLatest(opportunitiesModule.FETCH_OPPORTUNITIES, fetchOpportunities),
  takeLatest(opportunitiesModule.FETCH_OWN_OPPORTUNITIES, fetchOwnOpportunities),
  takeLatest(opportunitiesModule.FETCH_OPPORTUNITY, fetchOpportunity),
  takeLatest(opportunitiesModule.FETCH_OPP_FOR_ADMIN, fetchOppForAdmin),
  takeLatest(opportunitiesModule.CREATE_OPPORTUNITY, createOpportunity),
  takeLatest(opportunitiesModule.DELETE_OPPORTUNITY, deleteOpportunity),
  takeLatest(opportunitiesModule.UPDATE_OPPORTUNITY, updateOpportunity),
  takeLatest(opportunitiesModule.CHALLENGE_CHECKIN, challengeCheckin),
  takeLatest(opportunitiesModule.FETCH_BY_LOCATION, fetchByLocation)
]
