import { call, put, takeLatest } from 'redux-saga/effects'
import { navigate } from '@reach/router'

// redux
import * as campaignsApi from '../apiModules/campaigns'
import * as campaignsModule from '../modules/campaigns'

// utils
import UrlBuilder from 'utils/urlBuilder'
import isLoggedIn from 'utils/auth'

// config
import l from 'config/localization'

function* fetchCampaigns({ payload }) {
  try {
    const response = yield call(campaignsApi.fetchCampaigns, payload)
    yield put(campaignsModule.fetchSuccess(response))
  } catch (err) {
    yield put(campaignsModule.fetchFailure(err))
  }
}

function* fetchAllCampaigns() {
  try {
    const response = yield call(campaignsApi.fetchAllCampaigns)
    yield put(campaignsModule.fetchSuccess(response))
  } catch (err) {
    yield put(campaignsModule.fetchFailure(err))
  }
}

function* fetchOwnCampaigns({ payload }) {
  try {
    const response = yield call(campaignsApi.fetchOwnCampaigns, payload)
    yield put(campaignsModule.fetchSuccess(response))
  } catch (err) {
    yield put(campaignsModule.fetchFailure(err))
  }
}

function* fetchCampaign({ payload }) {
  try {
    const response = yield call(campaignsApi.fetchCampaign, payload)
    yield put(campaignsModule.fetchSingleSuccess(response))
  } catch (err) {
    if (err.response.status === 403) {
      if (!isLoggedIn()) {
        return navigate(`/login?redirectTo=/${l('ROUTE_CAMPS')}/${payload.campaignId}`)
      }

      yield put(
        campaignsModule.fetchSingleFailure({
          hasError: true,
          errorCode: '403'
        })
      )
    } else if (err.response.status === 404) {
      yield put(
        campaignsModule.fetchSingleFailure({
          hasError: true,
          errorCode: '404'
        })
      )
    } else {
      yield put(campaignsModule.fetchSingleFailure(err))
    }
  }
}

function* createCampaign({ payload }) {
  try {
    const formDataVals = new FormData()

    Object.keys(payload).map(value => {
      if (payload[value]) formDataVals.append(value, payload[value])
    })

    const createdCampaign = yield call(campaignsApi.createCampaign, formDataVals)
    yield put(campaignsModule.createSuccess(createdCampaign))
  } catch (err) {
    yield put(campaignsModule.createFailure(err.response.data))
  }
}

function* deleteCampaign({ payload }) {
  try {
    yield call(campaignsApi.deleteCampaign, payload)
    yield put(campaignsModule.deleteSuccess(payload))
    UrlBuilder.displayDeleteSuccessMessage()
  } catch (err) {
    yield put(campaignsModule.deleteFailure())
  }
}

function* updateCampaign({ payload }) {
  try {
    const formDataVals = new FormData()
    formDataVals.append('campaignId', payload.id)

    Object.keys(payload).map(fieldName => {
      const isFalseValue = typeof payload[fieldName] === 'boolean' && payload[fieldName] === false
      const useableValue = isFalseValue ? 'false' : payload[fieldName]

      if (useableValue) {
        formDataVals.append(fieldName, useableValue)
      }
    })

    const { campaign } = yield call(campaignsApi.updateCampaign, formDataVals)
    yield put(campaignsModule.updateSuccess(campaign))
  } catch (err) {
    yield put(campaignsModule.updateFailure())
  }
}

export const campaignSagas = [
  takeLatest(campaignsModule.CREATE_CAMPAIGN, createCampaign),
  takeLatest(campaignsModule.UPDATE_CAMPAIGN, updateCampaign),
  takeLatest(campaignsModule.FETCH_CAMPAIGN, fetchCampaign),
  takeLatest(campaignsModule.FETCH_CAMPAIGNS, fetchCampaigns),
  takeLatest(campaignsModule.FETCH_ALL_CAMPAIGNS, fetchAllCampaigns),
  takeLatest(campaignsModule.FETCH_OWN_CAMPAIGNS, fetchOwnCampaigns),
  takeLatest(campaignsModule.DELETE_CAMPAIGN, deleteCampaign)
]
