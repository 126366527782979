import React from 'react'
import styled from 'styled-components'
import { Link } from '@reach/router'

// icons
import { Barcode } from '@styled-icons/boxicons-regular/Barcode'
import { Label } from '@styled-icons/material/Label'
import { PeopleFill } from '@styled-icons/bootstrap'
import { Settings } from 'styled-icons/ionicons-outline'
import { Team } from '@styled-icons/remix-fill'
import { User as UserIcon } from '@styled-icons/boxicons-solid/User'

// assets
import { Location } from 'components/Icons/Icons'

// utils
import th from 'utils/themeHelper'

// styles
import DashCardWrapper from './DashCardWrapper'

const ActionItem = styled.div`
  margin-right: 10px;

  &:last-of-type {
    margin-right: 0;
  }
`

const Actions = styled.div`
  align-items: center;
  display: flex;
  flex: 1;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin: 10px;
`

const ActionsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`

const TeamCardLink = styled(Link)`
  border-color: ${th('backgrounds.mid')};
  border-radius: 4px;
  border-style: solid;
  border-width: 1px;
  color: ${th('text.dark')};
  margin: 0;
  padding: 8px 12px;
  text-align: center;
  text-decoration: none;
  white-space: nowrap;

  & > svg {
    width: 15px;
    margin-right: 10px;
  }
`

const Info = styled.div`
  display: block;
  margin: 10px 15px 0;
  flex: 1;
`

const InfoFieldLabel = styled.div`
  font-family: ${th('fonts.bold')};
  margin-right: 10px;
`

const InfoFieldValue = styled.div`
  font-family: ${th('fonts.regular')};
  margin-left: 30px;
`

const InfoFieldIcon = styled.div`
  margin-right: 10px;

  & > svg {
    height: 20px;
  }
`

const InfoGroup = styled.div`
  min-width: 30%;
`

const InfoGroupRow = styled.div`
  color: ${th('text.mid')};
  line-height: 1.25em;
  margin-bottom: 15px;
`

const InfoGroupRowInner = styled.div`
  display: flex;
  word-break: break-word;
`

const DashTeamCard = ({ id, member_count, name, team_code, location, team_lead_name }) => {
  return (
    <DashCardWrapper>
      <ActionsWrapper>
        <Info>
          <InfoGroup>
            <InfoGroupRow>
              <InfoGroupRowInner>
                <InfoFieldIcon>
                  <Label />
                </InfoFieldIcon>
                <InfoFieldLabel>Team name</InfoFieldLabel>
              </InfoGroupRowInner>

              <InfoGroupRowInner>
                <InfoFieldValue>{name}</InfoFieldValue>
              </InfoGroupRowInner>
            </InfoGroupRow>

            <InfoGroupRow>
              <InfoGroupRowInner>
                <InfoFieldIcon>
                  <Team />
                </InfoFieldIcon>
                <InfoFieldLabel>Members</InfoFieldLabel>
              </InfoGroupRowInner>

              <InfoGroupRowInner>
                <InfoFieldValue>{member_count}</InfoFieldValue>
              </InfoGroupRowInner>
            </InfoGroupRow>

            <InfoGroupRow>
              <InfoGroupRowInner>
                <InfoFieldIcon>
                  <Barcode />
                </InfoFieldIcon>
                <InfoFieldLabel>Team code</InfoFieldLabel>
              </InfoGroupRowInner>

              <InfoGroupRowInner>
                <InfoFieldValue>{team_code || 'N/A'}</InfoFieldValue>
              </InfoGroupRowInner>
            </InfoGroupRow>

            <InfoGroupRow>
              <InfoGroupRowInner>
                <InfoFieldIcon>
                  <UserIcon />
                </InfoFieldIcon>
                <InfoFieldLabel>Team lead</InfoFieldLabel>
              </InfoGroupRowInner>

              <InfoGroupRowInner>
                <InfoFieldValue>{team_lead_name}</InfoFieldValue>
              </InfoGroupRowInner>
            </InfoGroupRow>

            <InfoGroupRow>
              <InfoGroupRowInner>
                <InfoFieldIcon>
                  <Location />
                </InfoFieldIcon>
                <InfoFieldLabel>Location</InfoFieldLabel>
              </InfoGroupRowInner>

              <InfoGroupRowInner>
                <InfoFieldValue>{location || 'N/A'}</InfoFieldValue>
              </InfoGroupRowInner>
            </InfoGroupRow>
          </InfoGroup>
        </Info>

        <Actions>
          <ActionItem>
            <TeamCardLink to={`/dashboard/teams/${id}/`}>
              <PeopleFill />
              <span>Members</span>
            </TeamCardLink>
          </ActionItem>

          <ActionItem>
            <TeamCardLink to={`/dashboard/teams/${id}/edit`}>
              <Settings />
              <span>Manage team</span>
            </TeamCardLink>
          </ActionItem>
        </Actions>
      </ActionsWrapper>
    </DashCardWrapper>
  )
}

export default DashTeamCard
