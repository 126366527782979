import React from 'react'
import { Loader, LoaderBar, LoaderBall, LoaderWrapper, Heading, SubMessage } from './styles'

const buildHeader = ({ message }) => {
  if (message) return message

  const fallbackMessage = 'Processing your request'
  return fallbackMessage
}

const AnimatedLoader = ({ fullPage = true, message }) => {
  const header = buildHeader({ message })

  return (
    <LoaderWrapper fullPage={fullPage}>
      <Heading fullPage={fullPage}>{header}</Heading>

      <SubMessage fullPage={fullPage}>This could take a couple of seconds...</SubMessage>

      <Loader>
        <LoaderBar />
        <LoaderBar />
        <LoaderBar />
        <LoaderBar />
        <LoaderBar />
        <LoaderBall />
      </Loader>
    </LoaderWrapper>
  )
}

export default AnimatedLoader
