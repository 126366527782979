export const startLoading = state => ({
  ...state,
  isLoading: true,
  hasError: false,
  errorMessage: null
})

export const startSubmitting = state => ({
  ...state,
  isLoading: false,
  isSubmitting: true,
  hasError: false,
  errorMessage: null
})

export const loadingError = (state, { payload }) => {
  const errorMessage =
    payload && payload.errorMessage ? payload.errorMessage : 'An unexpected error occurred'

  return {
    ...state,
    isLoading: false,
    isError: true,
    errorMessage
  }
}
