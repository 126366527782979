import { call, put, takeLatest } from 'redux-saga/effects'
import { navigate } from '@reach/router'
import * as teamGroupsApi from '../apiModules/teamGroups'
import * as teamGroupsModule from '../modules/teamGroups'

function* fetchTeamGroup({ payload }) {
  try {
    const response = yield call(teamGroupsApi.fetchTeamGroup, payload)
    yield put(teamGroupsModule.singleFetchSuccess(response))
  } catch (err) {
    yield put(teamGroupsModule.singleFetchFailure(err))
  }
}

function* updateTeamGroup({ payload }) {
  try {
    const updatedTeamGroup = yield call(teamGroupsApi.updateTeamGroup, payload)
    yield put(teamGroupsModule.updateSuccess(updatedTeamGroup))
    return navigate('/dashboard/teams')
  } catch (err) {
    yield put(teamGroupsModule.updateFailure())
  }
}

function* deleteTeamGroup({ payload }) {
  try {
    yield call(teamGroupsApi.deleteTeamGroup, payload)
    yield put(teamGroupsModule.deleteSuccess(payload))
    return window.location.reload()
  } catch (err) {
    yield put(teamGroupsModule.deleteFailure())
  }
}

export const teamGroupSagas = [
  takeLatest(teamGroupsModule.UPDATE_TEAM_GROUP, updateTeamGroup),
  takeLatest(teamGroupsModule.DELETE_TEAM_GROUP, deleteTeamGroup),
  takeLatest(teamGroupsModule.FETCH_TEAM_GROUP, fetchTeamGroup)
]
