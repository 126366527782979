import { createActions, handleActions } from 'redux-actions'

// helpers
import { loadingError, startLoading } from './_helpers'

export const CREATE_PBO_APPLICATION = 'dgmt/CREATE_PBO_APPLICATION'
export const CREATE_PBO_APPLICATION_FAILURE = 'dgmt/CREATE_PBO_APPLICATION_FAILURE'
export const CREATE_PBO_APPLICATION_SUCCESS = 'dgmt/CREATE_PBO_APPLICATION_SUCCESS'
export const DELETE_PBO_APPLICATION = 'dgmt/DELETE_PBO_APPLICATION'
export const DELETE_PBO_APPLICATION_FAILURE = 'dgmt/DELETE_PBO_APPLICATION_FAILURE'
export const DELETE_PBO_APPLICATION_SUCCESS = 'dgmt/DELETE_PBO_APPLICATION_SUCCESS'
export const FETCH_DGMT_WEBSITES = 'dgmt/FETCH_DGMT_WEBSITES'
export const FETCH_DGMT_WEBSITES_FAILURE = 'dgmt/FETCH_DGMT_WEBSITES_FAILURE'
export const FETCH_DGMT_WEBSITES_SUCCESS = 'dgmt/FETCH_DGMT_WEBSITES_SUCCESS'
export const FETCH_LISTING_REQUEST = 'dgmt/FETCH_LISTING_REQUEST'
export const FETCH_LISTING_REQUEST_FAILURE = 'dgmt/FETCH_LISTING_REQUEST_FAILURE'
export const FETCH_LISTING_REQUEST_SUCCESS = 'dgmt/FETCH_LISTING_REQUEST_SUCCESS'
export const FETCH_LISTING_REQUESTS = 'dgmt/FETCH_LISTING_REQUESTS'
export const FETCH_LISTING_REQUESTS_FAILURE = 'dgmt/FETCH_LISTING_REQUESTS_FAILURE'
export const FETCH_LISTING_REQUESTS_SUCCESS = 'dgmt/FETCH_LISTING_REQUESTS_SUCCESS'
export const FETCH_MNOS = 'dgmt/FETCH_MNOS'
export const FETCH_MNOS_FAILURE = 'dgmt/FETCH_MNOS_FAILURE'
export const FETCH_MNOS_SUCCESS = 'dgmt/FETCH_MNOS_SUCCESS'
export const FETCH_PBO_APPLICATION = 'dgmt/FETCH_PBO_APPLICATION'
export const FETCH_PBO_APPLICATION_FAILURE = 'dgmt/FETCH_PBO_APPLICATION_FAILURE'
export const FETCH_PBO_APPLICATION_SUCCESS = 'dgmt/FETCH_PBO_APPLICATION_SUCCESS'
export const FETCH_PBO_APPLICATIONS = 'dgmt/FETCH_PBO_APPLICATIONS'
export const FETCH_PBO_APPLICATIONS_SUCCESS = 'dgmt/FETCH_PBO_APPLICATIONS_SUCCESS'
export const FETCH_PBO_APPLICATIONS_FAILURE = 'dgmt/FETCH_PBO_APPLICATIONS_FAILURE'
export const LOGOUT_SUCCESS = 'authentication/LOGOUT_SUCCESS'
export const SUBMIT_ADDITIONAL_WEBSITES = 'dgmt/SUBMIT_ADDITIONAL_WEBSITES'
export const SUBMIT_ADDITIONAL_WEBSITES_FAILURE = 'dgmt/SUBMIT_ADDITIONAL_WEBSITES_FAILURE'
export const SUBMIT_ADDITIONAL_WEBSITES_SUCCESS = 'dgmt/SUBMIT_ADDITIONAL_WEBSITES_SUCCESS'
export const UPDATE_LISTING_REQUEST = 'dgmt/UPDATE_LISTING_REQUEST'
export const UPDATE_LISTING_REQUEST_FAILURE = 'dgmt/UPDATE_LISTING_REQUEST_FAILURE'
export const UPDATE_LISTING_REQUEST_SUCCESS = 'dgmt/UPDATE_LISTING_REQUEST_SUCCESS'
export const UPDATE_PBO_APPLICATION = 'dgmt/UPDATE_PBO_APPLICATION'
export const UPDATE_PBO_APPLICATION_FAILURE = 'dgmt/UPDATE_PBO_APPLICATION_FAILURE'
export const UPDATE_PBO_APPLICATION_SUCCESS = 'dgmt/UPDATE_PBO_APPLICATION_SUCCESS'

export const {
  dgmt: {
    createPboApplication,
    createPboApplicationFailure,
    createPboApplicationSuccess,
    deletePboApplication,
    deletePboApplicationFailure,
    deletePboApplicationSuccess,
    fetchDgmtWebsites,
    fetchDgmtWebsitesFailure,
    fetchDgmtWebsitesSuccess,
    fetchListingRequest,
    fetchListingRequestFailure,
    fetchListingRequestSuccess,
    fetchListingRequests,
    fetchListingRequestsFailure,
    fetchListingRequestsSuccess,
    fetchMnos,
    fetchMnosFailure,
    fetchMnosSuccess,
    fetchPboApplication,
    fetchPboApplicationFailure,
    fetchPboApplicationSuccess,
    fetchPboApplications,
    fetchPboApplicationsFailure,
    fetchPboApplicationsSuccess,
    submitAdditionalWebsites,
    submitAdditionalWebsitesFailure,
    submitAdditionalWebsitesSuccess,
    updateListingRequest,
    updateListingRequestFailure,
    updateListingRequestSuccess,
    updatePboApplication,
    updatePboApplicationFailure,
    updatePboApplicationSuccess
  }
} = createActions({
  [FETCH_DGMT_WEBSITES]: undefined,
  [FETCH_DGMT_WEBSITES_FAILURE]: undefined,
  [FETCH_DGMT_WEBSITES_SUCCESS]: undefined,
  [CREATE_PBO_APPLICATION]: undefined,
  [CREATE_PBO_APPLICATION_FAILURE]: undefined,
  [CREATE_PBO_APPLICATION_SUCCESS]: undefined,
  [DELETE_PBO_APPLICATION]: undefined,
  [DELETE_PBO_APPLICATION_FAILURE]: undefined,
  [DELETE_PBO_APPLICATION_SUCCESS]: undefined,
  [FETCH_LISTING_REQUEST]: undefined,
  [FETCH_LISTING_REQUEST_FAILURE]: undefined,
  [FETCH_LISTING_REQUEST_SUCCESS]: undefined,
  [FETCH_LISTING_REQUESTS]: undefined,
  [FETCH_LISTING_REQUESTS_FAILURE]: undefined,
  [FETCH_LISTING_REQUESTS_SUCCESS]: undefined,
  [FETCH_MNOS]: undefined,
  [FETCH_MNOS_FAILURE]: undefined,
  [FETCH_MNOS_SUCCESS]: undefined,
  [FETCH_PBO_APPLICATION]: undefined,
  [FETCH_PBO_APPLICATION_FAILURE]: undefined,
  [FETCH_PBO_APPLICATION_SUCCESS]: undefined,
  [FETCH_PBO_APPLICATIONS]: undefined,
  [FETCH_PBO_APPLICATIONS_FAILURE]: undefined,
  [FETCH_PBO_APPLICATIONS_SUCCESS]: undefined,
  [SUBMIT_ADDITIONAL_WEBSITES]: undefined,
  [SUBMIT_ADDITIONAL_WEBSITES_FAILURE]: undefined,
  [SUBMIT_ADDITIONAL_WEBSITES_SUCCESS]: undefined,
  [UPDATE_LISTING_REQUEST]: undefined,
  [UPDATE_LISTING_REQUEST_FAILURE]: undefined,
  [UPDATE_LISTING_REQUEST_SUCCESS]: undefined,
  [UPDATE_PBO_APPLICATION]: undefined,
  [UPDATE_PBO_APPLICATION_FAILURE]: undefined,
  [UPDATE_PBO_APPLICATION_SUCCESS]: undefined
})

const initialState = {
  isError: false,
  isLoading: false,
  isSuccess: false,
  mnosData: [],
  paginatedDgmtWebsites: {
    dgmtWebsites: [],
    dgmtWebsitesCount: 0,
    dgmtWebsitesPageCount: 0
  },
  paginatedListingRequestsData: {
    listingRequests: [],
    listingRequestsCount: 0,
    listingRequestsPageCount: 0
  },
  paginatedPboApplicationsData: {
    pboApplications: [],
    pboApplicationsCount: 0,
    pboApplicationsPageCount: 0
  },
  singleListingRequestData: {},
  singlePboApplicationData: {}
}

export default handleActions(
  {
    [CREATE_PBO_APPLICATION]: (state, { payload }) => ({
      ...state,
      ...payload,
      isLoading: false,
      isSubmitting: true
    }),
    [CREATE_PBO_APPLICATION_FAILURE]: (state, { payload }) => ({
      ...state,
      hasError: true,
      errorMessage: payload,
      isLoading: false,
      isSubmitting: false
    }),
    [CREATE_PBO_APPLICATION_SUCCESS]: state => {
      return { ...state, isLoading: false, isSubmitting: false }
    },
    [DELETE_PBO_APPLICATION]: startLoading,
    [DELETE_PBO_APPLICATION_FAILURE]: loadingError,
    [DELETE_PBO_APPLICATION_SUCCESS]: state => {
      return { ...state, isLoading: false, isSubmitting: false }
    },
    [FETCH_DGMT_WEBSITES]: startLoading,
    [FETCH_DGMT_WEBSITES_FAILURE]: loadingError,
    [FETCH_DGMT_WEBSITES_SUCCESS]: (state, { payload }) => {
      const newState = {
        ...state,
        isError: false,
        isLoading: false,
        isSuccess: true,
        hasSubmitted: false,
        errorMessage: null
      }

      newState.paginatedDgmtWebsites.dgmtWebsitesPageCount =
        payload.dgmtWebsitesPageCount || payload.pageCount || 0
      newState.paginatedDgmtWebsites.dgmtWebsitesCount = payload.dgmtWebsitesCount || 0
      newState.paginatedDgmtWebsites.dgmtWebsites = payload.dgmtWebsites || []

      return newState
    },
    [FETCH_LISTING_REQUEST]: startLoading,
    [FETCH_LISTING_REQUEST_FAILURE]: state => ({
      ...state,
      isLoading: false,
      isError: true,
      singleListingRequestData: { ...state.singleListingRequestData, isError: true }
    }),
    [FETCH_LISTING_REQUEST_SUCCESS]: (state, { payload }) => ({
      ...state,
      singleListingRequestData: payload,
      isLoading: false,
      isError: false,
      isSuccess: true
    }),
    [FETCH_LISTING_REQUESTS]: startLoading,
    [FETCH_LISTING_REQUESTS_FAILURE]: loadingError,
    [FETCH_LISTING_REQUESTS_SUCCESS]: (state, { payload }) => {
      const newState = {
        ...state,
        isError: false,
        isLoading: false,
        isSuccess: true,
        hasSubmitted: false,
        errorMessage: null
      }

      newState.paginatedListingRequestsData.listingRequestsPageCount =
        payload.listingRequestsPageCount || payload.pageCount || 0
      newState.paginatedListingRequestsData.listingRequestsCount = payload.listingRequestsCount || 0
      newState.paginatedListingRequestsData.listingRequests = payload.listingRequests || []

      return newState
    },
    [FETCH_MNOS]: startLoading,
    [FETCH_MNOS_FAILURE]: loadingError,
    [FETCH_MNOS_SUCCESS]: (state, { payload }) => ({
      ...state,
      isError: false,
      isLoading: false,
      isSuccess: true,
      hasSubmitted: false,
      errorMessage: null,
      mnosData: payload
    }),
    [FETCH_PBO_APPLICATION]: startLoading,
    [FETCH_PBO_APPLICATION_FAILURE]: state => ({
      ...state,
      isLoading: false,
      isError: true,
      singlePboApplicationData: { ...state.singlePboApplicationData, isError: true }
    }),
    [FETCH_PBO_APPLICATION_SUCCESS]: (state, { payload }) => ({
      ...state,
      singlePboApplicationData: payload,
      isLoading: false,
      isError: false,
      isSuccess: true
    }),
    [FETCH_PBO_APPLICATIONS]: startLoading,
    [FETCH_PBO_APPLICATIONS_FAILURE]: loadingError,
    [FETCH_PBO_APPLICATIONS_SUCCESS]: (state, { payload }) => {
      const newState = {
        ...state,
        isError: false,
        isLoading: false,
        isSuccess: true,
        hasSubmitted: false,
        errorMessage: null
      }

      newState.paginatedPboApplicationsData.pboApplicationsPageCount =
        payload.pboApplicationsPageCount || payload.pageCount || 0
      newState.paginatedPboApplicationsData.pboApplicationsCount = payload.pboApplicationsCount || 0
      newState.paginatedPboApplicationsData.pboApplications = payload.pboApplications || []

      return newState
    },
    [LOGOUT_SUCCESS]: () => initialState,
    [SUBMIT_ADDITIONAL_WEBSITES]: (state, { payload }) => ({
      ...state,
      ...payload,
      isLoading: false,
      isSubmitting: true
    }),
    [SUBMIT_ADDITIONAL_WEBSITES_FAILURE]: (state, { payload }) => ({
      ...state,
      hasError: true,
      errorMessage: payload,
      isLoading: false,
      isSubmitting: false
    }),
    [SUBMIT_ADDITIONAL_WEBSITES_SUCCESS]: state => {
      return { ...state, isLoading: false, isSubmitting: false }
    },
    [UPDATE_LISTING_REQUEST]: state => {
      return {
        ...state,
        isSuccess: false,
        isLoading: false,
        isSubmitting: true
      }
    },
    [UPDATE_LISTING_REQUEST_FAILURE]: loadingError,
    [UPDATE_LISTING_REQUEST_SUCCESS]: state => {
      return {
        ...state,
        isLoading: false,
        isSubmitting: false,
        isSuccess: true
      }
    },
    [UPDATE_PBO_APPLICATION]: state => {
      return {
        ...state,
        isSuccess: false,
        isLoading: false,
        isSubmitting: true
      }
    },
    [UPDATE_PBO_APPLICATION_FAILURE]: (state, payload) => ({
      ...state,
      errorMessage: payload ? payload.errorMessage : null,
      isLoading: false,
      isSubmitting: false,
      isSuccess: false
    }),
    [UPDATE_PBO_APPLICATION_SUCCESS]: state => {
      return {
        ...state,
        isLoading: false,
        isSubmitting: false,
        isSuccess: true
      }
    }
  },
  initialState
)
