import React, { Component } from 'react'
import styled from 'styled-components'
import th from '../../utils/themeHelper'
import { removeFlashMessage, getFlashMessage } from '../../utils/localStorageManager'

removeFlashMessage

const HeaderContainer = styled.div`
  text-align: center;
  color: white;
  padding: 10px;
  background: ${th('primary.light')};

  @media screen and (min-width: 600px) {
    padding: 10px;
  }

  @media screen and (min-width: 730px) {
    padding: 10px;
  }

  @media screen and (min-width: 1024px) {
    padding: 10px 70px;
  }
`

const DismissBannerButton = styled.span`
  display: inline;
  margin-left: 20px;
  background: ${th('secondary.base')};
  padding: 5px 15px;
  border-radius: 6px;
  color: white;
  cursor: pointer;
`

class FlashMessage extends Component {
  state = {
    timestamp: new Date()
  }

  dismiss() {
    removeFlashMessage()
    this.setState({
      timestamp: new Date()
    })
  }

  render() {
    const message = getFlashMessage()

    if (!message) {
      return null
    }

    return (
      <HeaderContainer {...this.props}>
        {message}
        <DismissBannerButton onClick={() => this.dismiss()}>OK</DismissBannerButton>
      </HeaderContainer>
    )
  }
}

export default FlashMessage
