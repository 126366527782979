import styled, { css, keyframes } from 'styled-components'
import th from '../../utils/themeHelper'

const pulseAnimation = keyframes`
    0% {
        background-color: rgba(165, 165, 165, 0.1);
    }
    50% {
        background-color: rgba(165, 165, 165, 0.3);
    }
    100% {
        background-color: rgba(165, 165, 165, 0.1);
    }
}
`

const Pulse = css`
  animation-duration: 1s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: ${pulseAnimation};
  animation-timing-function: linear;
`

export const Hero = styled.div`
  height: 100vh;
  width: 100%;
  background: ${th('backgrounds.mid')};
  ${Pulse};
`
