import { createActions, handleActions } from 'redux-actions'
import UrlBuilder from 'utils/urlBuilder'

export const FETCH_MESSAGING_DATA = 'messaging/FETCH_MESSAGING_DATA'
export const FETCH_MESSAGING_DATA_SUCCESS = 'messaging/FETCH_MESSAGING_DATA_SUCCESS'
export const FETCH_MESSAGING_DATA_FAILURE = 'messaging/FETCH_MESSAGING_DATA_FAILURE'
export const TRIGGER_MESSAGE_DISPATCH = 'messaging/TRIGGER_MESSAGE_DISPATCH'
export const TRIGGER_MESSAGE_DISPATCH_SUCCESS = 'messaging/TRIGGER_MESSAGE_DISPATCH_SUCCESS'
export const TRIGGER_MESSAGE_DISPATCH_FAILURE = 'messaging/TRIGGER_MESSAGE_DISPATCH_FAILURE'
export const LOGOUT_SUCCESS = 'authentication/LOGOUT_SUCCESS'

export const {
  messaging: {
    fetchMessagingData,
    fetchMessagingDataSuccess,
    fetchMessagingDataFailure,
    triggerMessageDispatch,
    triggerMessageDispatchSuccess,
    triggerMessageDispatchFailure
  }
} = createActions({
  [FETCH_MESSAGING_DATA]: undefined,
  [FETCH_MESSAGING_DATA_SUCCESS]: undefined,
  [FETCH_MESSAGING_DATA_FAILURE]: undefined,
  [TRIGGER_MESSAGE_DISPATCH]: undefined,
  [TRIGGER_MESSAGE_DISPATCH_SUCCESS]: undefined,
  [TRIGGER_MESSAGE_DISPATCH_FAILURE]: undefined
})

const initialState = {
  tables: [],
  results: [],
  isLoading: false,
  isError: false,
  isSubmitting: null,
  isSuccess: null
}

export default handleActions(
  {
    [FETCH_MESSAGING_DATA]: state => ({
      ...state,
      isLoading: true
    }),
    [FETCH_MESSAGING_DATA_SUCCESS]: (state, { payload }) => ({
      ...state,
      results: payload.results || [],
      tables: payload.tables || [],
      isLoading: false,
      isError: false
    }),
    [FETCH_MESSAGING_DATA_FAILURE]: state => ({
      ...state,
      isLoading: false,
      isError: true
    }),
    [TRIGGER_MESSAGE_DISPATCH]: state => ({
      ...state,
      isSubmitting: true
    }),
    [TRIGGER_MESSAGE_DISPATCH_SUCCESS]: state => {
      UrlBuilder.displayUpdateSuccessMessage()

      return {
        ...state,
        isSubmitting: false,
        isError: false,
        isSuccess: true
      }
    },
    [TRIGGER_MESSAGE_DISPATCH_FAILURE]: state => ({
      ...state,
      isSubmitting: false,
      isError: true
    }),
    [LOGOUT_SUCCESS]: () => initialState
  },
  initialState
)
