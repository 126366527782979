import React, { Component } from 'react'
import { Base } from './styles'

class FieldRow extends Component {
  render() {
    return (
      <Base ref={this.ref} {...this.props}>
        {this.props.children}
      </Base>
    )
  }
}

export default FieldRow
