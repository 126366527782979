import styled from 'styled-components'

const MobileView = styled.div`
  display: none;

  @media screen and (max-width: 769px) {
    display: block;
  }
`

export default MobileView
