import React, { Fragment } from 'react'

// config
import l from 'config/localization'
import constants from 'config/constants'

// utils
import logos from 'utils/logos'

import {
  BrowniePointsLogo,
  DashboardMobileHeading,
  DashboardHomePageMobileContainer,
  DashboardSideNavOuter,
  FooterLink,
  NavItems,
  SidebarFooterLogoWrapper
} from './styles'

// icons
import { Receipt } from '@styled-icons/bootstrap'
import { DashboardSideNav, DashboardNavItem } from '../Dashboard'
import { JournalText } from '@styled-icons/bootstrap/JournalText'
import { NotificationsActive } from '@styled-icons/material/NotificationsActive'
import { Dashboard } from '@styled-icons/boxicons-solid'
import { Mobile } from '@styled-icons/entypo/Mobile'
import { TabDesktopNewPage } from '@styled-icons/fluentui-system-regular/TabDesktopNewPage'
// import { OpenBook } from '@styled-icons/entypo/OpenBook'

// icons
import { ReactComponent as OrgIcon } from 'assets/images/svgs/icon-org-page-organisation.svg'

import ConditionalDisplay from 'components/ConditionalDisplay/ConditionalDisplay'

const { dashSidebarLogo } = logos

const DgmtSidebar = ({
  fetchNotifications,
  hasFetchedRoles,
  locationInfo,
  notifications = [],
  roles = []
}) => {
  const hasAnyNotifications = notifications.filter(n => n.status === 'Unread').length
  const hasMnoRole = roles.some(role => role.role_type === constants.ROLES.DGMT_MNO_ADMIN)
  const hasGodRole = roles.some(role => role.role_type === constants.ROLES.GOD_MODE)
  const hasDgmtAdminRole = roles.find(r => {
    return r.role_type === constants.ROLES.DGMT_ADMIN && r.roleable === constants.ROLEABLES.ORG
  })

  if (!hasFetchedRoles) {
    return (
      <Fragment>
        <DashboardHomePageMobileContainer displayMobile={locationInfo.homeActive}>
          <DashboardMobileHeading>Dashboard Menu</DashboardMobileHeading>
        </DashboardHomePageMobileContainer>

        <DashboardSideNavOuter displayMobile={locationInfo.homeActive}>
          <DashboardSideNav displayMobile={locationInfo.homeActive}>
            <NavItems id="dashboard-menu-items-wrapper" displayMobile={locationInfo.homeActive}>
              <DashboardNavItem
                hiddenOnMobile={locationInfo.homeActive}
                Icon={Dashboard}
                hover="exception"
                id={locationInfo.homeActive ? 'active-dash-nav-item' : undefined}
                isActive={locationInfo.homeActive}
                label="Dashboard"
                to="/dashboard"
              />
            </NavItems>
          </DashboardSideNav>

          <SidebarFooterLogoWrapper>
            <FooterLink to="/">
              <BrowniePointsLogo src={dashSidebarLogo} />
            </FooterLink>
          </SidebarFooterLogoWrapper>
        </DashboardSideNavOuter>
      </Fragment>
    )
  }

  return (
    <Fragment>
      <DashboardHomePageMobileContainer displayMobile={locationInfo.homeActive}>
        <DashboardMobileHeading>Dashboard Menu</DashboardMobileHeading>
      </DashboardHomePageMobileContainer>

      <DashboardSideNavOuter displayMobile={locationInfo.homeActive}>
        <DashboardSideNav displayMobile={locationInfo.homeActive}>
          <NavItems id="dashboard-menu-items-wrapper" displayMobile={locationInfo.homeActive}>
            <DashboardNavItem
              hiddenOnMobile={locationInfo.homeActive}
              Icon={Dashboard}
              hover="exception"
              id={locationInfo.homeActive ? 'active-dash-nav-item' : undefined}
              isActive={locationInfo.homeActive}
              label="Dashboard"
              to="/dashboard"
            />

            <DashboardNavItem
              hover="exception"
              Icon={NotificationsActive}
              id={locationInfo.notificationsActive ? 'active-dash-nav-item' : undefined}
              isActive={locationInfo.notificationsActive}
              label="Notifications"
              onClick={() => fetchNotifications()}
              showNotificationDot={hasAnyNotifications}
              to={'/dashboard/notifications'}
            />

            <ConditionalDisplay displayWhen={[!hasMnoRole]}>
              <DashboardNavItem
                label="Applications"
                Icon={Receipt}
                to={'/dashboard/sir-applications'}
                isActive={locationInfo.sirApplicationsActive}
                hover="exception"
                id={locationInfo.sirApplicationsActive ? 'active-dash-nav-item' : undefined}
              />
            </ConditionalDisplay>

            <ConditionalDisplay displayWhen={[!hasMnoRole]}>
              <DashboardNavItem
                label="Feedback"
                Icon={JournalText}
                to={'/dashboard/feedback'}
                isActive={locationInfo.activeStoriesPath}
                id={locationInfo.activeStoriesPath ? 'active-dash-nav-item' : undefined}
              />
            </ConditionalDisplay>

            <ConditionalDisplay displayWhen={[!hasMnoRole]}>
              <DashboardNavItem
                label={`PBOs`}
                Icon={OrgIcon}
                to={`/dashboard/${l('ROUTE_ORGS')}`}
                isActive={locationInfo.organisationsActive}
                id={locationInfo.organisationsActive ? 'active-dash-nav-item' : undefined}
              />
            </ConditionalDisplay>

            <ConditionalDisplay displayWhen={[hasMnoRole || hasGodRole || hasDgmtAdminRole]}>
              <DashboardNavItem
                label="Websites"
                Icon={TabDesktopNewPage}
                to={'/dashboard/zero-rated-websites'}
                isActive={locationInfo.zeroRatedWebsitesPath}
                id={locationInfo.zeroRatedWebsitesPath ? 'active-dash-nav-item' : undefined}
              />
            </ConditionalDisplay>

            <ConditionalDisplay displayWhen={[hasGodRole || hasDgmtAdminRole]}>
              <DashboardNavItem
                label="Mobile operators"
                Icon={Mobile}
                to={'/dashboard/mnos'}
                isActive={locationInfo.mnosPath}
                id={locationInfo.mnosPath ? 'active-dash-nav-item' : undefined}
              />
            </ConditionalDisplay>

            {/* <DashboardNavItem
              label="Documentation"
              Icon={OpenBook}
              to={'/docs'}
              id={locationInfo.mnosPath ? 'active-dash-nav-item' : undefined}
            /> */}
          </NavItems>
        </DashboardSideNav>

        <SidebarFooterLogoWrapper>
          <FooterLink to="/">
            <BrowniePointsLogo src={dashSidebarLogo} />
          </FooterLink>
        </SidebarFooterLogoWrapper>
      </DashboardSideNavOuter>
    </Fragment>
  )
}

export default DgmtSidebar
