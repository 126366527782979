import React from 'react'
import styled from 'styled-components'
import { Link } from '@reach/router'

// icons
import { CalendarHeart } from '@styled-icons/boxicons-regular'

// config
import constants from 'config/constants'

// utils
import th from 'utils/themeHelper'

// styles
import DashCardWrapper from './DashCardWrapper'

const ActionItem = styled.div`
  margin-right: 10px;

  &:last-of-type {
    margin-right: 0;
  }
`

const Actions = styled.div`
  align-items: center;
  display: flex;
  flex: 1;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin: 10px;
`

const ActionsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`

const Heading = styled.h4`
  display: inline-block;
  flex: 1;
  margin: 0;
  vertical-align: middle;
`

const Info = styled.div`
  display: block;
  margin: 10px 15px 0;
  flex: 1;
`

const InfoFieldLabel = styled.div`
  font-family: ${th('fonts.bold')};
  margin-right: 10px;
`

const InfoFieldValue = styled.div`
  font-family: ${th('fonts.regular')};
  margin-left: 30px;
`

const InfoFieldIcon = styled.div`
  margin-right: 10px;

  & > svg {
    height: 20px;
  }
`

const InfoGroup = styled.div`
  min-width: 30%;
`

const InfoGroupRow = styled.div`
  color: ${th('text.mid')};
  line-height: 1.25em;
  margin-bottom: 15px;
`

const InfoGroupRowInner = styled.div`
  display: flex;
  word-break: break-word;
`

const NotificationLink = styled(Link)`
  border-color: ${th('backgrounds.mid')};
  border-radius: 4px;
  border-style: solid;
  border-width: 1px;
  color: ${th('text.dark')};
  font-size: 13px;
  margin: 0;
  padding: 8px 12px;
  text-align: center;
  text-decoration: none;
  white-space: nowrap;
`

const OrgMainDetailsWrapper = styled.div`
  align-items: center;
  display: flex;
  flex: 1;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin: 0 15px 10px;
`

const deriveLabel = ({ label, status }) => {
  const isDgmtSubmissionNotif = [
    constants.NOTIFICATION_LABELS.DGMT_SIR_APPLICATION_SUBMITTED,
    constants.NOTIFICATION_LABELS.DGMT_SIR_APPLICATION_UPDATED
  ].includes(label)
  const isTaggedNotif = label === constants.NOTIFICATION_LABELS.STORY_BENEFICIARY_TAG_REQUEST
  const isChallengeReminderNotif =
    label === constants.NOTIFICATION_LABELS.CHALLENGE_CHECKIN_REMINDER
  const isJoinInviteNotif = label === constants.NOTIFICATION_LABELS.ORG_JOIN_INVITE
  const isJoinRequestNotif = label === constants.NOTIFICATION_LABELS.ORG_JOIN_REQUEST
  const isDonoConfirmReqNotif = [
    constants.NOTIFICATION_LABELS.MONEY_DONATION_CONFIRMATION_REQUEST,
    constants.NOTIFICATION_LABELS.GOODS_DONATION_CONFIRMATION_REQUEST
  ].includes(label)

  if (isDgmtSubmissionNotif && status === 'Unread') {
    return 'Manage application'
  } else if (isChallengeReminderNotif && status === 'Unread') {
    return 'Check-in'
  } else if (isTaggedNotif && status === 'Unread') {
    return 'Manage Story'
  } else if (isDonoConfirmReqNotif && status === 'Unread') {
    return 'Confirm Donation'
  } else if (isJoinRequestNotif && status === 'Unread') {
    return 'Manage Request'
  } else if (isJoinInviteNotif && status === 'Unread') {
    return 'Manage Invite'
  } else if (status === 'Unread') {
    return 'Dismiss'
  }
}

const DashNotificationCard = ({ created_at, label, status, title, notification_path }) => {
  const notificationLabel = deriveLabel({ label, status })

  return (
    <DashCardWrapper>
      <ActionsWrapper>
        <OrgMainDetailsWrapper>
          <Heading>{title}</Heading>
        </OrgMainDetailsWrapper>

        <Info>
          <InfoGroup>
            <InfoGroupRow>
              <InfoGroupRowInner>
                <InfoFieldIcon>
                  <CalendarHeart />
                </InfoFieldIcon>
                <InfoFieldLabel>Date</InfoFieldLabel>
              </InfoGroupRowInner>

              <InfoGroupRowInner>
                <InfoFieldValue>{created_at}</InfoFieldValue>
              </InfoGroupRowInner>
            </InfoGroupRow>
          </InfoGroup>
        </Info>

        <Actions>
          <ActionItem>
            <NotificationLink to={notification_path}>{notificationLabel}</NotificationLink>
          </ActionItem>
        </Actions>
      </ActionsWrapper>
    </DashCardWrapper>
  )
}

export default DashNotificationCard
