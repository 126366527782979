/* eslint-disable */

import React, { Component, Fragment } from 'react'
import { Formik, Field } from 'formik'
import { debounce } from 'lodash'

// styles
import {
  CenteredModalHeader,
  CloseIcon,
  FormDividerLine,
  FormSectionRow,
  ModalContainer,
  ModalHeader,
  ScreenWrapper
} from './styles'

// utils and config
import constants from 'config/constants'
import l from 'config/localization'

// Load components synchronously
import ConditionalDisplay from 'components/ConditionalDisplay/ConditionalDisplay'
import FormProgressBars from 'components/FormProgressBars/FormProgressBars'
import SmallButtonPrimary from 'components/SmallButtonPrimary/SmallButtonPrimary'
import TextArea from 'components/TextArea/TextArea'

// Modal partials
import CustomTermsAndQuestionSection from 'components/Modals/_customTermsAndQuestionSection'
import DonationAmountFormSection from 'components/Modals/_donationAmountFormSection'
import DonationContextSelector from 'components/Modals/_donationContextSelector'
import DonationFinalFormSection from 'components/Modals/_donationFinalFormSection'
import DonationFrequencyFormSection from 'components/Modals/_donationFrequencyFormSection'
import GoodsAmountFormSection from 'components/Modals/_goodsAmountFormSection'
import StoryVisibilitySelector from 'components/Modals/_storyVisibilitySelector'

const validate = values => {
  const { customSignupQuestions = [], customTerms, isMoneyType, isMoneyOrGoodGivingType } = values
  const errors = {}

  customSignupQuestions.forEach(q => {
    const isDoc = [
      constants.ADDITIONAL_QUESTION_TYPES.document,
      constants.ADDITIONAL_QUESTION_TYPES.Document
    ].includes(q.request_type)
    const isQuestion = !isDoc

    const answerOrDoc = values[`custom_signup_question_${q.id}`]
    const answerIsZero = typeof answerOrDoc === 'number' && '0' === `${answerOrDoc}`
    const answerExists = answerOrDoc || answerIsZero
    const requiredQuestion = q.is_required || q.isRequired

    if (isDoc) {
      if (!answerOrDoc && requiredQuestion) {
        errors[`custom_signup_question_${q.id}`] = 'Please attach a PDF of no more than 3mb'
      } else if (answerOrDoc && answerOrDoc.size / 1024 / 1024 > 3) {
        errors[`custom_signup_question_${q.id}`] = 'PDF size is more than 3mb'
      }
    } else if (isQuestion && requiredQuestion && !answerExists) {
      errors[`custom_signup_question_${q.id}`] = 'Please answer this question'
    }
  })

  if (customTerms && !values.extra_terms) {
    errors.extra_terms = 'Please accept the additional terms first'
  }

  if (isMoneyOrGoodGivingType && !values.applicant_commitment) {
    errors.applicant_commitment = 'Please include a number you would commit to'
  }

  if (isMoneyType && !values.chosen_donation_link) {
    errors.chosen_donation_link = 'Please select how you would like to donate'
  }

  return errors
}

const deriveCustomTermModalVisibility = ({
  customTerms,
  formValues,
  goodsItemRequests,
  hasUnseenSignupQuestions,
  questions
}) => {
  // goods opportunities do not have terms or questions
  const hasGoodsItemRequests = goodsItemRequests.length > 0
  if (hasGoodsItemRequests) return false

  if (hasUnseenSignupQuestions) return true

  const hasCustomQuestions = questions.length > 0
  const hasCustomTerms = !!customTerms
  const hasAcceptedTerms = !!formValues.extra_terms

  const requiredQuestions = questions.filter(q => q.is_required || q.isRequired)
  const hasMissingRequiredQuestions = requiredQuestions.some(q => {
    return !formValues[`custom_signup_question_${q.id}`]
  })

  // No need to show modal because no custom terms or questions
  if (!hasCustomQuestions && !hasCustomTerms) return false

  // Donor must first accept custom terms
  if (hasCustomTerms && !hasAcceptedTerms) return true

  // Donor must first answer required questions
  if (hasCustomQuestions && hasMissingRequiredQuestions) return true

  // No need to show modal because donor has filled in all fields
  return false
}

const deriveStateProgress = ({ customTerms, formValues, questions }) => {
  const hasQuestions = questions.length > 0
  const hasTermsOrQuestions = hasQuestions || !!customTerms
  const isMoneyOrGoodGivingType =
    formValues.goal_type === constants.OPPORTUNITY_GOAL_TYPES.MONEY ||
    formValues.goal_type === constants.OPPORTUNITY_GOAL_TYPES.GOODS

  if (isMoneyOrGoodGivingType) {
    const isOppDonation = formValues.donation_origin === 'opportunity'

    if (isOppDonation && hasTermsOrQuestions) {
      return { formProgressActiveStep: 2, formProgressSteps: 2 }
    }

    if (isOppDonation) {
      return { formProgressActiveStep: 1, formProgressSteps: 1 }
    }

    return { formProgressActiveStep: 2, formProgressSteps: 4 }
  }

  if (hasTermsOrQuestions) {
    return { formProgressActiveStep: 1, formProgressSteps: 1 }
  }

  return { formProgressActiveStep: 1, formProgressSteps: 1 }
}

class ExtraInfoModal extends Component {
  constructor(props) {
    super(props)
    this.state = {
      showContextField: false,
      showCustomAmountField: false
    }
    this.debounceFetchPlaces = debounce(this.loadPlaces, 400)
    this.setOtherAmount = this.setOtherAmount.bind(this)
  }

  loadPlaces(value, fetchPlaces) {
    const { placesQuery } = this.props

    if (value !== placesQuery) {
      return fetchPlaces(value)
    }
  }

  setOtherAmount(value) {
    this.setState({ showCustomAmountField: value })
  }

  render() {
    const { showCustomAmountField, showContextField } = this.state

    const {
      extraInfoModalOpts = {},
      hideModal,
      fetchPlaces,
      locationIsLoading,
      places = []
    } = this.props

    const {
      advanceFromCustomQuestions,
      formValues,
      hasAnyRecurringSubscriptions,
      hasUnseenSignupQuestions,
      isSubmitting,
      opportunity = {},
      organisation = {},
      orgOpts = [],
      recurringSubscriptions = [],
      roleData,
      userData = {}
    } = extraInfoModalOpts

    const goalType = opportunity.goal_type || constants.OPPORTUNITY_GOAL_TYPES.MONEY
    const questions = opportunity.customSignupQuestions || []
    const goodsItemRequests = opportunity.goodsRequests || []
    const donationPlatformOptions = opportunity.donationOpts || organisation.donationOpts || []

    const hasDonoOpts = donationPlatformOptions.length > 0
    const isMoneyTypeWithoutDonationOpts = formValues.isMoneyType && !hasDonoOpts
    const isSuperAdmin = roleData.some(r => r.role_type === constants.ROLES.SUPERADMIN)

    const isMoneyType =
      formValues.isMoneyType || goalType === constants.OPPORTUNITY_GOAL_TYPES.MONEY

    const autoSelectDonationOption = isMoneyType && hasDonoOpts
    const chosenDonationLink = autoSelectDonationOption ? donationPlatformOptions[0].id : undefined

    // const moneyModalType = formValues.moneyModalType === 'once-off-donation'
    const isMembershipModal = formValues.moneyModalType === 'membership-donation'
    const nonProfitHasPayfast = formValues.isMoneyType && formValues.nonProfitHasPayfast
    const frequencyNotRequired = formValues.isMoneyType && !formValues.nonProfitHasPayfast
    const showAmountSelection = formValues.isMoneyType
    const showGoodsAmountSelection = goalType === constants.OPPORTUNITY_GOAL_TYPES.GOODS

    const hasSelectedFrequency = !!formValues.donation_frequency || frequencyNotRequired
    const hasSetCommitment = !!formValues.applicant_commitment
    const hasOtherCommitment = !!formValues.applicant_commitment_other
    const hasSelectedAmount = hasSetCommitment || hasOtherCommitment
    const hasCommittedGoods = !!formValues.has_confirmed_commitments

    const customTerms = opportunity.custom_terms
    const showCustomTermsOrQuestions = deriveCustomTermModalVisibility({
      customTerms,
      hasUnseenSignupQuestions,
      formValues,
      goodsItemRequests,
      questions
    })

    const isRecurringFrequency = formValues.donation_frequency === 'recurring'
    const showFinalDonationFormSection =
      hasCommittedGoods || (isMoneyType && hasSelectedAmount && hasSelectedFrequency)
    const disablePaymentMethodOptions = isMoneyType && isRecurringFrequency

    const hasPersonalSub = recurringSubscriptions.some(s => s.donor_type === 'User')

    if (isSubmitting) {
      return (
        <ScreenWrapper>
          <ModalContainer>
            <CloseIcon dark size={30} onClick={hideModal} />

            <ModalHeader>Hang on a second</ModalHeader>
            <p>
              We're submitting your form. Hopefully this doesn't take longer than a few seconds...
            </p>
          </ModalContainer>
        </ScreenWrapper>
      )
    }

    if (isMoneyTypeWithoutDonationOpts) {
      return (
        <ScreenWrapper>
          <ModalContainer>
            <CloseIcon dark size={30} onClick={hideModal} />

            <ModalHeader>Whoops! There seems to be a problem.</ModalHeader>
            <p>
              The beneficiary of this donation {l('OPP')} does not seem to have any valid donation
              options available. Please let them know, and once they add or enable one or more
              options, you'll be able to retry your donation.
            </p>
          </ModalContainer>
        </ScreenWrapper>
      )
    }

    if (nonProfitHasPayfast && isMembershipModal && !hasSelectedAmount) {
      return (
        <DonationAmountFormSection
          customTerms={customTerms}
          extraInfoModalOpts={extraInfoModalOpts}
          formValues={formValues}
          hasAnyRecurringSubscriptions={hasAnyRecurringSubscriptions}
          hideModal={hideModal}
          isRecurringFrequency={isRecurringFrequency}
          opportunity={opportunity}
          organisation={organisation}
          questions={questions}
          setOtherAmount={this.setOtherAmount}
          showCustomAmountField={showCustomAmountField}
          disablePaymentMethodOptions={disablePaymentMethodOptions}
        />
      )
    }

    // if (nonProfitHasPayfast && !hasSelectedFrequency) {
    //   return (
    //     <DonationFrequencyFormSection
    //       donationPlatformOptions={donationPlatformOptions}
    //       extraInfoModalOpts={extraInfoModalOpts}
    //       hasPersonalSub={hasPersonalSub}
    //       hideModal={hideModal}
    //       orgOpts={orgOpts}
    //       x
    //       recurringSubscriptions={recurringSubscriptions}
    //     />
    //   )
    // }

    if (showGoodsAmountSelection && !hasCommittedGoods) {
      return (
        <GoodsAmountFormSection
          extraInfoModalOpts={extraInfoModalOpts}
          hideModal={hideModal}
          opportunity={opportunity}
        />
      )
    }

    if (showAmountSelection && !hasSelectedAmount) {
      return (
        <DonationAmountFormSection
          customTerms={customTerms}
          extraInfoModalOpts={extraInfoModalOpts}
          formValues={formValues}
          hasAnyRecurringSubscriptions={hasAnyRecurringSubscriptions}
          hideModal={hideModal}
          isRecurringFrequency={isRecurringFrequency}
          opportunity={opportunity}
          organisation={organisation}
          questions={questions}
          setOtherAmount={this.setOtherAmount}
          showCustomAmountField={showCustomAmountField}
          disablePaymentMethodOptions={disablePaymentMethodOptions}
        />
      )
    }

    // Note this is only for the opportunity page
    if (showCustomTermsOrQuestions) {
      return (
        <CustomTermsAndQuestionSection
          advanceFromCustomQuestions={advanceFromCustomQuestions}
          customTerms={customTerms}
          extraInfoModalOpts={extraInfoModalOpts}
          fetchPlaces={fetchPlaces}
          formValues={formValues}
          goalType={goalType}
          hideModal={hideModal}
          isSubmitting={isSubmitting}
          isSuperAdmin={isSuperAdmin}
          locationIsLoading={locationIsLoading}
          places={places}
          questions={questions}
          setOtherAmount={this.setOtherAmount}
          setState={v => this.setState(v)}
        />
      )
    }

    if (showFinalDonationFormSection) {
      return (
        <DonationFinalFormSection
          customTerms={customTerms}
          extraInfoModalOpts={extraInfoModalOpts}
          formValues={formValues}
          hasPersonalSub={hasPersonalSub}
          hideModal={hideModal}
          isMoneyType={isMoneyType}
          isSuperAdmin={isSuperAdmin}
          organisation={organisation}
          orgOpts={orgOpts}
          questions={questions}
          setOtherAmount={this.setOtherAmount}
          setState={v => this.setState(v)}
          recurringSubscriptions={recurringSubscriptions}
          showContextField={showContextField}
        />
      )
    }

    const { formProgressActiveStep, formProgressSteps } = deriveStateProgress({
      customTerms,
      formValues,
      questions
    })

    return (
      <ScreenWrapper>
        <ModalContainer>
          <CloseIcon dark size={30} onClick={hideModal} />

          <CenteredModalHeader>
            Thank you {userData.f_name}, you're almost there.
          </CenteredModalHeader>

          <FormProgressBars active={formProgressActiveStep} steps={formProgressSteps} />

          <Formik
            initialValues={{
              ...formValues,
              chosen_donation_context: orgOpts[0].value,
              chosen_donation_link: chosenDonationLink
            }}
            enableReinitialize
            validate={validate}
            validateOnChange={false}
            validateOnBlur={false}
            onSubmit={values => {
              const opt =
                donationPlatformOptions.find(o =>
                  [o.id, o.value].includes(values.chosen_donation_link)
                ) || {}

              const vals = {
                ...values,
                chosen_donation_link_is_ozow: opt.is_ozow,
                chosen_donation_link_is_payfast: opt.is_payfast,
                chosen_donation_link_platform: opt.value,
                chosen_donation_link_id: opt.id,
                chosen_donation_link_url: opt.url
              }

              // in case there's any, remove customSignupQuestions
              delete values.customSignupQuestions

              extraInfoModalOpts.signUp({ ...vals })
            }}
            render={({ handleSubmit, setFieldValue }) => {
              const isChallengeType = goalType === constants.OPPORTUNITY_GOAL_TYPES.VOLUNTEERING
              const isMoneyOrGoodGivingType =
                goalType === constants.OPPORTUNITY_GOAL_TYPES.MONEY ||
                goalType === constants.OPPORTUNITY_GOAL_TYPES.GOODS

              const formSubmitLabel = isMoneyOrGoodGivingType
                ? 'Donate'
                : isChallengeType
                ? 'Join challenge'
                : 'Volunteer'

              return (
                <form onSubmit={handleSubmit}>
                  <Fragment>
                    <FormSectionRow>
                      <Field
                        component={TextArea}
                        label={`Donation message`}
                        maxWidth="500px"
                        name="donation_message"
                        placeholder={'Optionally add a donation message'}
                      />
                    </FormSectionRow>

                    {showContextField && <FormDividerLine />}

                    <DonationContextSelector
                      extraInfoModalOpts={extraInfoModalOpts}
                      isSuperAdmin={isSuperAdmin}
                      orgOpts={orgOpts}
                      setState={v => this.setState(v)}
                      showContextField={showContextField}
                    />

                    <StoryVisibilitySelector
                      formValues={formValues}
                      setState={v => this.setState(v)}
                      setFieldValue={setFieldValue}
                    />
                  </Fragment>

                  <ConditionalDisplay displayWhen={[opportunity.has_applicant_vetting]}>
                    <p>
                      The host of this {l('OPP')} has chosen to review and approve {l('SUPPORTERS')}{' '}
                      who sign up before they can join this {l('OPP')}. If your application is
                      accepted, you will receive an email confirmation. Thanks for your
                      understanding.
                    </p>
                  </ConditionalDisplay>

                  <FormSectionRow>
                    <SmallButtonPrimary type="submit">{formSubmitLabel}</SmallButtonPrimary>
                  </FormSectionRow>
                </form>
              )
            }}
          />
        </ModalContainer>
      </ScreenWrapper>
    )
  }
}

export default ExtraInfoModal
