import axios from 'axios'

// config
import baseUrl from '../config'

// utils

import cleanPayload from '../../utils/cleanPayload'

export const fetchOpportunities = async (payload = {}) => {
  const { campaignShareKey, campaignId } = payload

  const response = await axios(`${baseUrl}/opportunities`, {
    withCredentials: true,
    method: 'GET',
    responseType: 'json',
    params: { campaignShareKey, campaignId }
  })

  return response.data
}

export const fetchOwnOpportunities = async (payload = {}) => {
  const cleanedPayload = cleanPayload(payload)

  const response = await axios.get(`${baseUrl}/own-opportunities`, {
    withCredentials: true,
    params: { ...cleanedPayload }
  })

  return response.data
}

export const fetchOpportunity = async ({ missionId, pvtShareKey }) => {
  const response = await axios(`${baseUrl}/opportunities/${missionId}`, {
    withCredentials: true,
    method: 'GET',
    responseType: 'json',
    params: { pvtShareKey }
  })

  return response.data
}

export const fetchOppForAdmin = async missionId => {
  const response = await axios(`${baseUrl}/admin-opportunities/${missionId}`, {
    withCredentials: true,
    method: 'GET',
    responseType: 'json'
  })

  return response.data
}

export const fetchByLocation = async (location, currentPage) => {
  const encodedLocation = encodeURIComponent(location)
  const response = await axios(
    `${baseUrl}/nearby-opportunities/${encodedLocation}/${currentPage}`,
    { method: 'GET' }
  )

  return response.data
}

export const challengeCheckin = async data => {
  return await axios({
    withCredentials: true,
    method: 'post',
    url: `${baseUrl}/opportunities/${data.get('missionId')}/challenge-checkin`,
    data,
    config: { headers: { 'Content-Type': 'multipart/form-data' } }
  })
}

export const createOpportunity = async data => {
  return await axios({
    withCredentials: true,
    method: 'post',
    url: `${baseUrl}/opportunities`,
    data,
    config: { headers: { 'Content-Type': 'multipart/form-data' } }
  })
}

export const updateOpportunity = async opportunity => {
  const response = await axios({
    withCredentials: true,
    method: 'put',
    url: `${baseUrl}/opportunities/${opportunity.get('id')}`,
    data: opportunity,
    config: { headers: { 'Content-Type': 'multipart/form-data' } }
  })
  return response.data
}

export const deleteOpportunity = async missionId => {
  return await axios.delete(`${baseUrl}/opportunities/${missionId}`, {
    withCredentials: true,
    data: { missionId }
  })
}
