import { call, put, takeLatest } from 'redux-saga/effects'
import * as boostsApi from '../apiModules/boosts'
import * as boostsModule from '../modules/boosts'

function* requestCommunityBoost({ payload }) {
  try {
    const result = yield call(boostsApi.requestCommunityBoost, payload)
    yield put(boostsModule.requestCommunityBoostSuccess(result))
  } catch (err) {
    yield put(boostsModule.requestCommunityBoostFailure(err))
  }
}

export const boostsSagas = [takeLatest(boostsModule.REQUEST_COMMUNITY_BOOST, requestCommunityBoost)]
