import styled from 'styled-components'

// utils
import th from 'utils/themeHelper'

// config
import { isDgmt } from 'config/localization'

export const Wrapper = styled.div`
  display: ${props => (props.forceFullWidth ? 'block' : 'inline-block')};
  flex-direction: column;
  position: relative;
  padding: ${props => (props.innerLabel ? '10px' : '0')};
  border-radius: 3px;
  border-style: solid;
  border-color: ${({ disabled, hasError, requiredField, theme }) => {
    return hasError
      ? theme.borders.error
      : disabled
      ? theme.borders.medium
      : requiredField
      ? theme.borders.required
      : theme.borders.default
  }};

  border-width: ${({ requiredField, hasError, innerLabel }) =>
    innerLabel ? '1px' : hasError ? '2px' : requiredField ? '2px' : '0'};

  &:hover {
    border-color: ${({ disabled, hasError, theme }) =>
      disabled ? theme.borders.medium : hasError ? theme.borders.error : theme.borders.dark};
  }

  &:focus {
    border-color: ${({ disabled, hasError, theme }) =>
      disabled ? theme.borders.medium : hasError ? theme.borders.error : theme.secondary.base};
  }

  @media screen and (max-width: 600px) {
    display: ${props => (props.forceFullWidthMobile ? 'block' : 'initial')};
  }

  @media screen and (min-width: 1024px) {
    max-width: ${props => props.maxWidth || '700px'};
    min-width: ${props => {
      const minWidth = props.minWidth || '500px'
      return props.innerLabel ? '0' : props.forceFullWidth ? '100%' : minWidth
    }};
  }
`

export const Input = styled.input`
  appearance: none;
  background: ${({ disabled, theme }) => (disabled ? theme.backgrounds.light : 'white')};
  border-radius: 3px;
  border-style: solid;
  border-color: ${({ disabled, hasError, requiredField, theme }) => {
    return hasError
      ? theme.borders.error
      : disabled
      ? theme.borders.medium
      : requiredField
      ? theme.borders.required
      : theme.borders.default
  }};
  border-width: ${({ requiredField, hasError, innerLabel }) =>
    innerLabel ? '0' : hasError ? '2px' : requiredField ? '2px' : '1px'};
  color: ${th('text.dark')};
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'text')};
  font-family: ${th('fonts.light')};
  font-size: 15px;
  height: ${props => (props.innerLabel ? 'initial' : '45px')};
  margin-top: ${props => (props.innerLabel ? '5px' : '0')};
  outline: none;
  padding: ${props => (props.innerLabel ? '0' : '15px')};
  padding-left: ${props =>
    props.urlInput && props.hasPrefix
      ? '185px'
      : props.urlInput
      ? '90px'
      : props.innerLabel
      ? '0'
      : '15px'};
  width: 100%;

  &::placeholder {
    color: ${th('text.light')};
  }

  &:hover {
    border-color: ${({ disabled, hasError, theme }) =>
      disabled ? theme.backgrounds.medium : hasError ? theme.borders.error : theme.borders.dark};
  }

  &:focus {
    border-color: ${({ disabled, hasError, theme }) => {
      if (isDgmt) {
        return disabled
          ? theme.backgrounds.medium
          : hasError
          ? theme.borders.error
          : theme.borders.medium
      }

      return disabled
        ? theme.backgrounds.medium
        : hasError
        ? theme.borders.error
        : theme.secondary.base
    }};
  }
`
