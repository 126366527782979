import { call, put, takeLatest } from 'redux-saga/effects'
import { navigate } from '@reach/router'
import { parse } from 'qs'
import * as ozowModule from '../modules/ozow'
import * as ozowApi from '../apiModules/ozow'
import logError from '../../utils/logger'
import l from 'config/localization'

const deriveFailureUrl = ({ payload }) => {
  const { failReason } = parse(location.search.replace('?', ''))
  const { TransactionReference } = payload

  const hasOrgId = TransactionReference.match(/org_(\d+)/)
  const hasOppId = TransactionReference.match(/opp_(\d+)/)
  const oppId = hasOppId ? TransactionReference.match(/opp_(\d+)/)[1] : null
  const orgId = hasOrgId ? TransactionReference.match(/org_(\d+)/)[1] : null

  if (oppId) {
    return `/${l('ROUTE_OPPS')}/${oppId}?failReason=${failReason}`
  }

  if (orgId) {
    return `/${l('ROUTE_ORGS')}/${orgId}?failReason=${failReason}`
  }

  // should never get here
  return '#'
}

const deriveUrl = ({ payload }) => {
  const { Amount, TransactionReference } = payload

  const hasOrgId = TransactionReference.match(/org_(\d+)/)
  const hasOppId = TransactionReference.match(/opp_(\d+)/)
  const oppId = hasOppId ? TransactionReference.match(/opp_(\d+)/)[1] : null
  const orgId = hasOrgId ? TransactionReference.match(/org_(\d+)/)[1] : null
  const donationAmount = `${Amount}`.replace('.00', '')

  if (oppId) {
    return `/${l(
      'ROUTE_OPPS'
    )}/${oppId}?success=true&donationAmount=${donationAmount}&donationSuccess=true`
  }

  if (orgId) {
    return `/${l(
      'ROUTE_ORGS'
    )}/${orgId}?success=true&donationAmount=${donationAmount}&donationSuccess=true`
  }

  // should never get here
  return '#'
}

function* completeDonation({ payload }) {
  try {
    const response = yield call(ozowApi.completeDonationSuccess, payload)
    yield put(ozowModule.completeDonationSuccess(response.data))

    const url = deriveUrl({ payload })
    navigate(url)
  } catch (err) {
    yield put(ozowModule.completeDonationFailure())
    logError(err)
  }
}

function* failDonation({ payload }) {
  try {
    const response = yield call(ozowApi.completeDonationFail, payload)
    yield put(ozowModule.completeDonationFailure(response.data))

    const url = deriveFailureUrl({ payload })

    navigate(url)
  } catch (err) {
    yield put(ozowModule.completeDonationFailure())
    logError(err)
  }
}

export const ozowSagas = [
  takeLatest(ozowModule.COMPLETE_DONATION, completeDonation),
  takeLatest(ozowModule.FAIL_DONATION, failDonation)
]
