import { createActions, handleActions } from 'redux-actions'

export const GENERATE_PAYMENT_IDENTIFIER = 'payments/GENERATE_PAYMENT_IDENTIFIER'
export const GENERATE_PAYMENT_IDENTIFIER_SUCCESS = 'payments/GENERATE_PAYMENT_IDENTIFIER_SUCCESS'
export const GENERATE_PAYMENT_IDENTIFIER_FAILURE = 'payments/GENERATE_PAYMENT_IDENTIFIER_FAILURE'

export const RECORD_PAYMENT = 'payments/RECORD_PAYMENT'
export const RECORD_PAYMENT_FAILURE = 'payments/RECORD_PAYMENT_FAILURE'
export const RECORD_PAYMENT_SUCCESS = 'payments/RECORD_PAYMENT_SUCCESS'

export const WITHDRAW_PAYFAST_DONATION = 'payments/WITHDRAW_PAYFAST_DONATION'

export const LOGOUT_SUCCESS = 'authentication/LOGOUT_SUCCESS'
export const RESET_STATE = 'payments/RESET_STATE'

export const {
  payments: {
    generatePaymentIdentifier,
    generatePaymentIdentifierSuccess,
    generatePaymentIdentifierFailure,
    recordPayment,
    recordPaymentSuccess,
    recordPaymentFailure,
    resetState,
    withdrawPayfastDonation
  }
} = createActions({
  [GENERATE_PAYMENT_IDENTIFIER]: undefined,
  [GENERATE_PAYMENT_IDENTIFIER_SUCCESS]: undefined,
  [GENERATE_PAYMENT_IDENTIFIER_FAILURE]: undefined,
  [RECORD_PAYMENT]: undefined,
  [RECORD_PAYMENT_FAILURE]: undefined,
  [RECORD_PAYMENT_SUCCESS]: undefined,
  [WITHDRAW_PAYFAST_DONATION]: undefined,
  [RESET_STATE]: undefined
})

const initialState = {
  payfastTransactionId: null,
  transactionId: null,
  isLoading: false,
  isError: false,
  isSuccess: null,
  data: {}
}

export default handleActions(
  {
    [GENERATE_PAYMENT_IDENTIFIER]: state => ({ ...state, isLoading: true }),
    [GENERATE_PAYMENT_IDENTIFIER_SUCCESS]: (state, { payload }) => ({
      ...state,
      isError: false,
      isLoading: false,
      payfastTransactionId: payload.data.payfastTransactionIdentifier,
      transactionId: payload.data.transactionIdentifier
    }),
    [GENERATE_PAYMENT_IDENTIFIER_FAILURE]: () => ({
      isError: true,
      isLoading: false,
      payfastTransactionId: null,
      transactionId: null
    }),
    [RECORD_PAYMENT]: state => ({
      ...state,
      isError: null,
      isLoading: true,
      isSuccess: null
    }),
    [RECORD_PAYMENT_SUCCESS]: state => ({
      ...state,
      isError: false,
      isLoading: false,
      isSuccess: true,
      payfastTransactionId: null,
      transactionId: null
    }),
    [RECORD_PAYMENT_FAILURE]: state => ({
      ...state,
      isError: true,
      isLoading: false,
      isSuccess: false,
      payfastTransactionId: null,
      transactionId: null
    }),
    [WITHDRAW_PAYFAST_DONATION]: state => ({
      ...state,
      isError: null,
      isLoading: null,
      isSuccess: null
    }),
    [RESET_STATE]: () => initialState,
    [LOGOUT_SUCCESS]: () => initialState
  },
  initialState
)
