import React, { Component } from 'react'

// utils and config
import constants from 'config/constants'
import goalTypeOutcomeBuilder from 'utils/goalTypeOutcomeBuilder'

// styles
import {
  Commitment,
  CommitmentText,
  HostMessage,
  SharingInstructions,
  ModalHeader,
  ScreenWrapper,
  ModalContainer,
  SocialMediaWrapper,
  CloseIcon,
  ModalWrapper
} from './styles'

// Load components synchronously
import LinkButton from 'components/SmallButtonPrimary/LinkButton'
import SocialMediaIcons from 'components/SocialMediaIcons/SocialMediaIcons'

const getCommitmentText = ({ emailConfirmed, wasDonation, opportunity }) => {
  if (!opportunity) {
    return 'Thank you!'
  }

  if (wasDonation) {
    return emailConfirmed
      ? 'Thank you!'
      : 'Thank you! Please check your inbox or spam folder to confirm your email address.'
  }

  if (!emailConfirmed) {
    return 'Please check your inbox or spam folder to confirm your email address.'
  }

  const goalType = opportunity.goal_type
  const isMoneyGivingType = goalType === constants.OPPORTUNITY_GOAL_TYPES.MONEY
  const isGoodsGivingType = goalType === constants.OPPORTUNITY_GOAL_TYPES.GOODS

  if (isGoodsGivingType) {
    return "You'll be notified with updates so you can see how your goods are used."
  }

  if (isMoneyGivingType) {
    return "You'll be notified with updates so you can see how your money is used for good."
  }

  return "You'll be notified with updates."
}

class SignupSuccessModal extends Component {
  constructor(props) {
    super(props)
    this.escFunction = this.escFunction.bind(this)
  }

  escFunction(event) {
    if (event.keyCode === 27) {
      this.props.hideModal()
    }
  }

  componentDidMount() {
    document.addEventListener('keydown', this.escFunction, false)
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.escFunction, false)
  }

  showHeader({ formValues, wasDonation, opportunity }) {
    const goalType = opportunity.goal_type
    const applicantCommitment =
      formValues.applicant_commitment === 'other'
        ? formValues.applicant_commitment_other
        : formValues.applicant_commitment || 1
    const goodType = wasDonation ? 'donation' : 'commitment'
    const displayableOutcome = constants.OPPORTUNITY_GOAL_TYPE_DELIVERABLES[opportunity.goal_type]
    const isMoneyType = goalType === constants.OPPORTUNITY_GOAL_TYPES.MONEY
    const nonMoneyTypeHeader = 'Thanks for your commitment!'

    if (!isMoneyType) {
      return <ModalHeader>{nonMoneyTypeHeader}</ModalHeader>
    }

    return (
      <ModalHeader>
        Thank you for your {goodType} of
        <Commitment marginLeft>
          +
          {goalTypeOutcomeBuilder({
            goalType: opportunity.goal_type,
            displayableOutcome,
            value: applicantCommitment
          })}
        </Commitment>
      </ModalHeader>
    )
  }

  render() {
    const { hideModal, signupSuccessModalOpts = {} } = this.props
    const { userData, opportunity = {}, formValues = {} } = signupSuccessModalOpts
    const emailConfirmed = userData.email_confirmed
    const wasDonation = formValues.donationSuccess
    const commitmentText = getCommitmentText({ emailConfirmed, wasDonation })

    return (
      <ScreenWrapper>
        <ModalWrapper>
          <ModalContainer>
            <CloseIcon dark size={30} onClick={hideModal} />

            {this.showHeader({ formValues, wasDonation, opportunity })}

            <CommitmentText>{commitmentText}</CommitmentText>

            {opportunity.post_signup_message && (
              <div>
                <SharingInstructions>
                  Your host has provided these instructions for you:
                </SharingInstructions>
                <HostMessage>{opportunity.post_signup_message}</HostMessage>
              </div>
            )}

            <SocialMediaWrapper>
              <SharingInstructions>
                Do more good: invite your friends, family and community.
              </SharingInstructions>

              <SocialMediaIcons
                title={opportunity.title}
                fbMessage={`I just signed up to ${opportunity.title}!`}
                description={`I just signed up to ${opportunity.title}`}
                twitterMessage={`I just signed up to ${opportunity.title}! Check it out here -`}
              />
            </SocialMediaWrapper>

            <div style={{ marginTop: '20px' }} />

            <LinkButton onClick={hideModal}>Close</LinkButton>
          </ModalContainer>
        </ModalWrapper>
      </ScreenWrapper>
    )
  }
}

export default SignupSuccessModal
