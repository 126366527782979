import { call, put, takeLatest } from 'redux-saga/effects'
import * as donationSubscriptionsApi from '../apiModules/donationSubscriptions'
import * as donationSubscriptionsModule from '../modules/donationSubscriptions'

// utils
import UrlBuilder from 'utils/urlBuilder'

function* fetchDonationSubscriptions({ payload }) {
  try {
    const result = yield call(donationSubscriptionsApi.fetchDonationSubscriptions, payload)
    yield put(donationSubscriptionsModule.fetchDonationSubscriptionsSuccess(result))
  } catch (err) {
    yield put(donationSubscriptionsModule.fetchDonationSubscriptionsFailure())
  }
}

function* addDonationSubscription({ payload }) {
  try {
    const result = yield call(donationSubscriptionsApi.addDonationSubscription, payload)
    yield put(donationSubscriptionsModule.addDonationSubscriptionSuccess(result))
    UrlBuilder.displayRecurringSuccess({ payload })
  } catch (err) {
    yield put(donationSubscriptionsModule.addDonationSubscriptionFailure())
  }
}

function* updateDonationSubscriptions({ payload }) {
  try {
    yield call(donationSubscriptionsApi.updateDonationSubscriptions, payload)
    yield put(donationSubscriptionsModule.updateDonationSubscriptionsSuccess(payload))
  } catch (err) {
    yield put(donationSubscriptionsModule.updateDonationSubscriptionsFailure())
  }
}

export const donationSubscriptionsSagas = [
  takeLatest(donationSubscriptionsModule.FETCH_DONATION_SUBSCRIPTIONS, fetchDonationSubscriptions),
  takeLatest(donationSubscriptionsModule.ADD_DONATION_SUBSCRIPTION, addDonationSubscription),
  takeLatest(donationSubscriptionsModule.UPDATE_DONATION_SUBSCRIPTIONS, updateDonationSubscriptions)
]
