import axios from 'axios'

// config
import baseUrl from '../config'

export const fetchTeamGroup = async id => {
  const response = await axios.get(`${baseUrl}/team-groups/${id}`, { withCredentials: true })
  return response.data
}

export const updateTeamGroup = async data => {
  const response = await axios({
    withCredentials: true,
    method: 'put',
    url: `${baseUrl}/team-groups/${data.id}`,
    data
  })

  return response.data
}

export const deleteTeamGroup = async teamGroupId => {
  return await axios.delete(`${baseUrl}/team-groups/${teamGroupId}`, {
    withCredentials: true,
    data: { teamGroupId }
  })
}
