import styled from 'styled-components'
import th from '../../utils/themeHelper'

const Button = styled.button.attrs({
  'data-cy': 'component-button'
})`
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 1.2;
  font-family: ${th('fonts.bold')};
  color: ${({ theme, outline }) => (outline ? theme.primary.base : `white`)};
  background: ${({ theme, outline }) =>
    outline ? `none` : theme.primary.base};
  border: ${({ outline, theme }) =>
    outline ? `1px solid ${theme.primary.base}` : `none`};
  border-radius: 6px;
  font-size: 15px;
  padding: 12px 29px;
  opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
  appearance: none;
  transition: 0.2s background;

  &:hover {
    pointer-events: ${({ disabled }) => (disabled ? 'none' : 'auto')};
    cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  }

  &:focus {
    outline: none;
  }
`

export default Button
