const isProd = process.env.REDUX_ENV === 'production'

import React from 'react'
import { Formik, Field } from 'formik'

// styles
import {
  BillingNotice,
  CenteredModalHeader,
  CloseIcon,
  DonationFeeDescription,
  DonationFeeName,
  DonationFeeRow,
  DonationFeeTotal,
  DonationFeesTable,
  DonationFeesWrapper,
  FormSectionButtonWrapper,
  FormSectionRow,
  ModalContainer,
  PaymentFeesLink,
  ScreenWrapper,
  Strong
} from './styles'

// utils
import goalTypeOutcomeBuilder from 'utils/goalTypeOutcomeBuilder'
import ordinalizeBillingDay from 'utils/ordinalizeBillingDay'

// config
import constants from 'config/constants'
import l from 'config/localization'

// Load components synchronously
import CheckboxInput from 'components/CheckboxInput/CheckboxInput'
import ConditionalDisplay from 'components/ConditionalDisplay/ConditionalDisplay'
import FormProgressBars from 'components/FormProgressBars/FormProgressBars'
import NumberInput from 'components/NumberInput/NumberInput'
import PaymentOptionSelector from 'components/PaymentOptionSelector/PaymentOptionSelector'
import SmallButtonPrimary from 'components/SmallButtonPrimary/SmallButtonPrimary'
import DonationAmountSelect from 'components/Select/DonationAmountSelect'
import { Error, Label, SubLabel, SubLabelStrong } from 'components/InputLabels/InputLabels'

const validateAmount = values => {
  const errors = {}

  if (values.donation_frequency !== 'recurring' && !values.chosen_donation_link) {
    errors.chosen_donation_link = 'Please select a payment method.'
  }

  if (!values.applicant_commitment && !values.applicant_commitment_other) {
    errors.applicant_commitment = 'Please select a donation amount.'
  }

  if (values.applicant_commitment === 'other' && !values.applicant_commitment_other) {
    errors.applicant_commitment_other = 'Please add a donation amount.'
  }

  return errors
}

const formatMoneyFigure = figure => {
  const floatNumber = (Math.round(figure * 100) / 100).toFixed(2)

  return String(floatNumber)
    .split('')
    .reverse()
    .join('')
    .replace(/(\d{3}\B)/g, '$1,')
    .split('')
    .reverse()
    .join('')
}

const determineImpactUnitTotals = ({ donationFees, unit }) => {
  const feeAmounts = donationFees
    .map(fee => {
      const isPercentageBased = fee.amount_type === 'percentage'
      if (isPercentageBased) {
        return (unit.cost_per_unit * fee.amount) / 100
      } else {
        return fee.amount
      }
    })
    .reduce((acc, curr) => acc + curr, 0)

  const costPerUnit = unit.cost_per_unit
  const payfastPercentageCharge = (feeAmounts + costPerUnit) * 0.032 // 3.2%
  const totalFeesWithPayfast = payfastPercentageCharge + costPerUnit
  const totalWithAllFees = totalFeesWithPayfast + feeAmounts

  return {
    grossContributionForUnit: unit.cost_per_unit,
    totalDonationFees: feeAmounts,
    payfastPercentageCharge: payfastPercentageCharge,
    totalWithAllFees: totalWithAllFees
  }
}

const deriveTotalContributionAmountLabel = ({
  donationOpts,
  totalPayfastFees,
  totalWithAllFees,
  values
}) => {
  const payfastOpt = donationOpts.find(d => d.is_payfast)
  const hasChosenPayfast = payfastOpt ? values.chosen_donation_link === payfastOpt.id : false

  if (hasChosenPayfast) {
    return `R${parseFloat(totalWithAllFees).toFixed(2)}`
  }

  return `R${parseFloat(totalWithAllFees - totalPayfastFees).toFixed(
    2
  )} + any external payment processing fees`
}

const determineContributionAmounts = ({
  donationFees,
  isGoodEconomyCause,
  impactUnits,
  values,
  withFees
}) => {
  if (isGoodEconomyCause) {
    const impactUnitKeys = Object.keys(values).filter(k => k.includes('impact_units_covered'))
    const impactUnitValues = impactUnitKeys.map(key => {
      const impactUnitId = key.replace('impact_units_covered_', '')
      const unit = impactUnits.find(u => `${u.id}` === impactUnitId)
      const impactUnitTotals = determineImpactUnitTotals({ donationFees, unit, withFees })

      return {
        grossContributionForUnit: values[key] * impactUnitTotals.grossContributionForUnit,
        totalDonationFees: values[key] * impactUnitTotals.totalDonationFees,
        payfastPercentageCharge: values[key] * impactUnitTotals.payfastPercentageCharge,
        totalWithAllFees: values[key] * impactUnitTotals.totalWithAllFees
      }
    })

    const grossContributionForAllUnits = impactUnitValues
      .map(v => v.grossContributionForUnit)
      .reduce((a, b) => a + b, 0)

    const totalDonationFees = impactUnitValues
      .map(v => v.totalDonationFees)
      .reduce((a, b) => a + b, 0)

    const payfastPercentageCharge = impactUnitValues
      .map(v => v.payfastPercentageCharge)
      .reduce((a, b) => a + b, 0)

    const totalWithAllFees = impactUnitValues
      .map(v => v.totalWithAllFees)
      .reduce((a, b) => a + b, 0)

    // Include the R2 fee for PayFast
    // Added here not per-unit cost calculation
    return {
      grossContributionForAllUnits,
      totalDonationFees,
      totalPayfastFees: payfastPercentageCharge + 2,
      totalWithAllFees: totalWithAllFees + 2
    }
  }

  const useCustomAmount = values.applicant_commitment === 'other'

  const commitmentAmount = useCustomAmount
    ? values.applicant_commitment_other
    : values.applicant_commitment

  const grossContributionForAllUnits = commitmentAmount === 'other' ? 0 : commitmentAmount

  // 3.2% + R2
  const donorCoversFees = !!values.donor_covers_fees
  const percentageCharge = grossContributionForAllUnits * 0.032 // 3.2%
  const totalPayfastFees = donorCoversFees ? percentageCharge + 2 : 0
  const totalWithFeesCovered = donorCoversFees
    ? grossContributionForAllUnits + totalPayfastFees
    : grossContributionForAllUnits

  return {
    grossContributionForAllUnits: grossContributionForAllUnits,
    totalDonationFees: 0,
    totalPayfastFees: totalPayfastFees,
    totalWithAllFees: totalWithFeesCovered
  }
}

const determinePaymentMethodVisibility = ({ isGoodEconomyCause, values }) => {
  if (isGoodEconomyCause) {
    return !!values.applicant_commitment
  }

  return true
}

const determineFeeLadenAmount = ({ values }) => {
  const useCustomAmount = values.applicant_commitment === 'other'

  const commitmentAmount = useCustomAmount
    ? values.applicant_commitment_other
    : values.applicant_commitment
  if (!commitmentAmount) return 0

  if (!values.donor_covers_fees) {
    return commitmentAmount
  }

  if (commitmentAmount === 'other') return 0

  // 3.2% + R2
  const percentageCharge = commitmentAmount * 0.032 // 3.2%
  const totalWithFeesCovered = commitmentAmount + 2 + percentageCharge

  return formatMoneyFigure(totalWithFeesCovered)
}

const determineFeesCheckbox = ({ donationOpts, isGoodEconomyCause, values }) => {
  // TEMP - remove when implemented on backend to add difference for fee coverage
  // if (donationOpts) return false

  if (isGoodEconomyCause) return false
  if (!donationOpts) return false
  if (!donationOpts.length) return false

  const hasOneDonationOption = donationOpts.length === 1
  const onlyPayfastAvailable = hasOneDonationOption ? donationOpts[0].is_payfast : false

  if (onlyPayfastAvailable) return true

  const payfastOpt = donationOpts.find(d => d.is_payfast)
  const hasChosenPayfast = payfastOpt ? values.chosen_donation_link === payfastOpt.id : false

  return hasChosenPayfast
}

const sortDonationOptions = ({ opportunity, organisation, recurringFrequency }) => {
  const recurringOptsList = []
  const opts = opportunity.donationOpts || organisation.donationOpts || []

  // For once-off, just list them however they came
  if (!recurringFrequency) return opts

  // Only list PayFast
  const payfastOpt = opts.find(o => o.is_payfast)
  if (payfastOpt) {
    recurringOptsList.push(payfastOpt)
  }

  return recurringOptsList
}

const deriveInitialDonationOption = ({ donationOpts, isGoodEconomyCause, recurringFrequency }) => {
  // Nothing to choose from
  if (!donationOpts.length) return undefined

  if (isGoodEconomyCause) {
    const autoSelectDonationOption = donationOpts.length === 1
    const defaultInitialOption = autoSelectDonationOption ? donationOpts[0].id : undefined

    const payfastOption = donationOpts.find(o => o.label === 'Payfast')
    return payfastOption ? payfastOption.id : defaultInitialOption
  }

  // Recurring donation flow
  const payfastOpt = donationOpts.find(d => d.is_payfast)
  if (recurringFrequency && !payfastOpt) return undefined
  if (recurringFrequency && payfastOpt) return payfastOpt.id

  // If there's only one option, auto-select it
  if (donationOpts.length === 1) return donationOpts[0].id

  // Don't auto-choose for multiple options
  return undefined
}

const deriveStateProgress = ({ customTerms, isOppDonation, questions }) => {
  const hasQuestions = questions.length > 0
  const hasTermsOrQuestions = hasQuestions || !!customTerms

  if (isOppDonation && hasTermsOrQuestions) {
    return { formProgressActiveStep: 1, formProgressSteps: 3 }
  }

  if (isOppDonation) {
    return { formProgressActiveStep: 1, formProgressSteps: 2 }
  }

  // Donations via Org page cannot have custom terms or questions
  return { formProgressActiveStep: 1, formProgressSteps: 2 }
}

const determineCampaign = ({ opportunity }) => {
  if (!opportunity) return false

  const campaignId = opportunity.campaign_id
  const goodEconomyCampaignId = constants.GOOD_ECONOMY_CAMPAIGN_ID

  return campaignId === goodEconomyCampaignId
}

const deriveMinAmount = ({ isRecurringFrequency }) => {
  // Min for recurring for all environments is R20
  if (isRecurringFrequency) return '20'

  // Min on production for non-recurring is R10
  if (isProd) return '10'

  // Min on staging for non-recurring is R5
  return '5'
}

const DonationFeesBlock = ({
  donationFees,
  donationOpts = [],
  impactUnits,
  isGoodEconomyCause,
  showFeesField,
  values
}) => {
  if (!showFeesField) return null
  if (!donationFees) return null
  if (!donationFees.length) return null

  const {
    grossContributionForAllUnits,
    totalDonationFees,
    totalPayfastFees
  } = determineContributionAmounts({
    donationFees,
    isGoodEconomyCause,
    impactUnits,
    values
  })

  const chosenDonationLink = values.chosen_donation_link
  const payfastDonationOptionId = donationOpts.find(o => o.label === 'Payfast') || {}
  const hasChosenPayfast = chosenDonationLink === payfastDonationOptionId.id

  const donationFeesTotal = hasChosenPayfast
    ? parseFloat(totalDonationFees + totalPayfastFees).toFixed(2)
    : `${parseFloat(totalDonationFees).toFixed(2)} + any external payment processing fees`

  return (
    <FormSectionRow>
      <Label>Donation fees</Label>
      <SubLabel>
        The following fees will be covered by your donation. These fees are used to facilitate the
        donation process and to cover administrative work needed for the programme. Please note that
        Brownie Points does not take any fees from your donation.
      </SubLabel>

      <DonationFeesWrapper>
        <DonationFeesTable>
          {donationFees.map(fee => {
            const isPayfastFee = fee.label === 'Payment processing fees'
            const totalForFee = (grossContributionForAllUnits * fee.amount) / 100
            const feeToDisplay = isPayfastFee ? totalPayfastFees : totalForFee

            const showPayfastInfo = isPayfastFee && hasChosenPayfast
            const donationFeeTotal =
              isPayfastFee && showPayfastInfo
                ? `R${parseFloat(feeToDisplay).toFixed(2)}`
                : isPayfastFee
                ? 'N/A'
                : `R${parseFloat(feeToDisplay).toFixed(2)}`

            const feeInfo =
              isPayfastFee && showPayfastInfo
                ? fee.info
                : isPayfastFee
                ? 'A fee might be charged by the chosen payment processor.'
                : fee.info

            return (
              <DonationFeeRow>
                <DonationFeeName>{fee.label}</DonationFeeName>

                <DonationFeeDescription>{feeInfo}</DonationFeeDescription>

                <DonationFeeTotal>{donationFeeTotal}</DonationFeeTotal>
              </DonationFeeRow>
            )
          })}

          <DonationFeeRow>
            <DonationFeeName>Total fees</DonationFeeName>

            <DonationFeeDescription />

            <DonationFeeTotal>R{donationFeesTotal}</DonationFeeTotal>
          </DonationFeeRow>
        </DonationFeesTable>
      </DonationFeesWrapper>
    </FormSectionRow>
  )
}

const MissionGoalBlurb = ({ isGoodEconomyCause, opportunity, organisation }) => {
  if (organisation.id) {
    return (
      <FormSectionRow>
        <p>Please include the amount you think you'd be able to commit to.</p>
      </FormSectionRow>
    )
  }

  const goalType = opportunity.goal_type || constants.OPPORTUNITY_GOAL_TYPES.MONEY
  const displayableOutcome = constants.OPPORTUNITY_GOAL_TYPE_DELIVERABLES[goalType]

  if (isGoodEconomyCause) {
    return (
      <FormSectionRow>
        <p>
          The goal of this {l('OPP')} is{' '}
          <Strong>
            {goalTypeOutcomeBuilder({
              goalType: goalType,
              displayableOutcome,
              value: opportunity.outcome_target
            })}
          </Strong>
          . Please select the amount that you would like to sponsor.
        </p>
      </FormSectionRow>
    )
  }

  return (
    <FormSectionRow>
      <p>
        The goal of this {l('OPP')} is{' '}
        <Strong>
          {goalTypeOutcomeBuilder({
            goalType: goalType,
            displayableOutcome,
            value: opportunity.outcome_target
          })}
        </Strong>
        . Please include the amount you think you'd be able to commit to.
      </p>
    </FormSectionRow>
  )
}

const DonationAmountFormSection = ({
  customTerms,
  disablePaymentMethodOptions,
  extraInfoModalOpts,
  formValues,
  hideModal,
  isRecurringFrequency,
  opportunity = {},
  organisation = {},
  questions = [],
  setOtherAmount,
  showCustomAmountField
}) => {
  const goalType = opportunity.goal_type || constants.OPPORTUNITY_GOAL_TYPES.MONEY
  const isMoneyType = formValues.isMoneyType || goalType === constants.OPPORTUNITY_GOAL_TYPES.MONEY

  const recurringFrequency = formValues.donation_frequency === 'recurring'
  const isOppDonation = formValues.donation_origin === 'opportunity'
  const useOnceOffLabel = isOppDonation || !recurringFrequency
  const sectionHeader = useOnceOffLabel ? 'Select donation amount' : 'Select membership amount'

  const { formProgressActiveStep, formProgressSteps } = deriveStateProgress({
    customTerms,
    isOppDonation,
    questions
  })

  const isGoodEconomyCause = determineCampaign({ opportunity })
  const donationFees = opportunity.donationFees || []
  const impactUnits = opportunity.impactUnits || []

  const donationOpts = sortDonationOptions({ opportunity, organisation, recurringFrequency })
  const hasOneDonationOption = donationOpts.length === 1
  const initialDonationLink = deriveInitialDonationOption({
    donationOpts,
    isGoodEconomyCause,
    recurringFrequency
  })

  const today = new Date()
  const { recurringDonationsConfig } = formValues
  const chosenBillingDay = recurringDonationsConfig ? recurringDonationsConfig.billing_day : null
  const billingDay = recurringDonationsConfig ? chosenBillingDay : today.getDate()

  const showDateSelector = recurringFrequency && !chosenBillingDay && false
  const showBillingDateNotice = recurringFrequency && chosenBillingDay
  const paymentMethodLabel = recurringFrequency
    ? 'Payment method'
    : hasOneDonationOption
    ? 'Payment method'
    : 'Select a payment method'

  const minAmount = deriveMinAmount({ isRecurringFrequency })

  return (
    <ScreenWrapper>
      <ModalContainer>
        <Formik
          initialValues={{
            applicant_commitment: undefined,
            applicant_commitment_other: undefined,
            billing_day: billingDay,
            chosen_donation_link: initialDonationLink,
            donation_frequency: formValues.donation_frequency,
            donor_covers_fees: false
          }}
          enableReinitialize
          validate={validateAmount}
          validateOnChange={false}
          validateOnBlur={false}
          onSubmit={values => {
            const selectedDonationPlatform =
              donationOpts.find(o => [o.id, o.value].includes(values.chosen_donation_link)) || {}

            const donationAmountWithFeesCovered = determineFeeLadenAmount({ values })

            extraInfoModalOpts.updateFormValues({
              ...values,
              donation_amount_with_fees_covered: donationAmountWithFeesCovered,
              chosen_donation_link_is_ozow: selectedDonationPlatform.is_ozow,
              chosen_donation_link_is_payfast: selectedDonationPlatform.is_payfast,
              chosen_donation_link_platform: selectedDonationPlatform.value,
              chosen_donation_link_id: selectedDonationPlatform.id,
              chosen_donation_link_url: selectedDonationPlatform.url
            })
          }}
          render={({ errors, handleSubmit, setFieldValue, values }) => {
            const { ordinalized, remainingText } = ordinalizeBillingDay({
              billingDay: values.billing_day
            })
            const showCoverFeesCheckbox = determineFeesCheckbox({
              donationOpts,
              isGoodEconomyCause,
              values
            })

            const { totalPayfastFees, totalWithAllFees } = determineContributionAmounts({
              donationFees,
              isGoodEconomyCause,
              impactUnits,
              values
            })
            const showSelectPaymentMethodField = determinePaymentMethodVisibility({
              isGoodEconomyCause,
              values
            })
            const showFeesField = isGoodEconomyCause && showSelectPaymentMethodField

            const totalContributionAmount = deriveTotalContributionAmountLabel({
              donationOpts,
              totalPayfastFees,
              totalWithAllFees,
              values
            })

            return (
              <form onSubmit={handleSubmit}>
                <div>
                  <CloseIcon
                    dark
                    onClick={() => {
                      extraInfoModalOpts.updateFormValues({
                        ...extraInfoModalOpts.formValues,
                        applicant_commitment: undefined,
                        applicant_commitment_other: undefined,
                        donation_frequency: undefined
                      })
                      hideModal()
                    }}
                    size={30}
                  />

                  <CenteredModalHeader>{sectionHeader}</CenteredModalHeader>

                  <FormProgressBars
                    active={formProgressActiveStep}
                    canNavTo={[1]}
                    steps={formProgressSteps}
                  />

                  <MissionGoalBlurb
                    isGoodEconomyCause={isGoodEconomyCause}
                    opportunity={opportunity}
                    organisation={organisation}
                  />

                  <ConditionalDisplay displayWhen={[showBillingDateNotice]}>
                    <FormSectionRow>
                      <BillingNotice>
                        Your monthly donation amount will be added to your existing total amount for
                        all monthly donations.{' '}
                        <Strong>Your card will not be billed immediately</Strong> but will instead
                        be billed on the{' '}
                        <Strong>
                          {ordinalized} {remainingText}
                        </Strong>{' '}
                        in a single payment.
                      </BillingNotice>
                    </FormSectionRow>
                  </ConditionalDisplay>

                  <FormSectionRow>
                    <Field
                      component={DonationAmountSelect}
                      goalType={goalType}
                      isMoneyType={isMoneyType}
                      name="applicant_commitment"
                      onChange={e => {
                        if (e) {
                          setFieldValue(`applicant_commitment`, e.value)
                          setOtherAmount(e.value === 'other')
                        }
                      }}
                      opportunity={opportunity}
                      values={values}
                    />
                  </FormSectionRow>

                  <ConditionalDisplay displayWhen={[!!values.applicant_commitment]}>
                    <FormSectionRow>
                      <Label>Total contribution:</Label>
                      <SubLabelStrong>{totalContributionAmount}</SubLabelStrong>
                    </FormSectionRow>
                  </ConditionalDisplay>

                  <ConditionalDisplay displayWhen={[showCustomAmountField]}>
                    <FormSectionRow>
                      <Field
                        component={NumberInput}
                        label={'Add a custom commitment amount'}
                        name="applicant_commitment_other"
                        max="1000000"
                        min={minAmount}
                        placeholder={`R50`}
                        shortNumberField
                        type="number"
                      />
                    </FormSectionRow>
                  </ConditionalDisplay>

                  <ConditionalDisplay displayWhen={[showDateSelector]}>
                    <FormSectionRow>
                      <Field
                        component={NumberInput}
                        label={'Select day of each month for billing'}
                        max="31"
                        min="0"
                        name="billing_day"
                        placeholder={'Add date'}
                        shortNumberField
                      />
                    </FormSectionRow>
                  </ConditionalDisplay>

                  <DonationFeesBlock
                    donationFees={donationFees}
                    donationOpts={donationOpts}
                    impactUnits={impactUnits}
                    isGoodEconomyCause={isGoodEconomyCause}
                    showFeesField={showFeesField}
                    values={values}
                  />

                  <ConditionalDisplay displayWhen={[showSelectPaymentMethodField]}>
                    <FormSectionRow>
                      <ConditionalDisplay
                        displayWhen={[!errors.chosen_donation_link, !showBillingDateNotice]}
                      >
                        <Label>{paymentMethodLabel}</Label>
                      </ConditionalDisplay>

                      <ConditionalDisplay displayWhen={[errors.chosen_donation_link]}>
                        <Error>Please select a payment method</Error>
                      </ConditionalDisplay>

                      <ConditionalDisplay
                        displayWhen={[recurringFrequency, !showBillingDateNotice]}
                      >
                        <div>
                          <SubLabel>
                            Please note that we can only process recurring donations via PayFast.
                            Your card details will be securely tokenized and stored and your card
                            will be billed on your chosen date each month.
                          </SubLabel>
                        </div>
                      </ConditionalDisplay>

                      <ConditionalDisplay displayWhen={[!showBillingDateNotice]}>
                        <PaymentOptionSelector
                          chosenValue={values.chosen_donation_link}
                          donationOpts={donationOpts}
                          setFieldValue={setFieldValue}
                          disablePaymentMethodOptions={disablePaymentMethodOptions}
                        />
                      </ConditionalDisplay>
                    </FormSectionRow>

                    <ConditionalDisplay displayWhen={[showCoverFeesCheckbox]}>
                      <FormSectionRow>
                        <Label>Donation payment fees</Label>

                        <div>
                          <SubLabel>
                            You can opt to cover the{' '}
                            <PaymentFeesLink
                              href={'https://www.payfast.co.za/fees/'}
                              target="_blank"
                            >
                              payment processing fees
                            </PaymentFeesLink>{' '}
                            for this donation.
                          </SubLabel>
                        </div>

                        <Field
                          name="donor_covers_fees"
                          id="donor_covers_fees"
                          formCheckBoxStyle={true}
                          label={`Would you like to cover the payment processing and donation fee?`}
                          component={CheckboxInput}
                        />
                      </FormSectionRow>
                    </ConditionalDisplay>
                  </ConditionalDisplay>

                  <FormSectionButtonWrapper>
                    <SmallButtonPrimary type="submit">Continue</SmallButtonPrimary>
                  </FormSectionButtonWrapper>
                </div>
              </form>
            )
          }}
        />
      </ModalContainer>
    </ScreenWrapper>
  )
}

export default DonationAmountFormSection
