import { call, put, takeLatest } from 'redux-saga/effects'
import * as campaignPartnersApi from '../apiModules/campaignPartners'
import * as campaignPartnersModule from '../modules/campaignPartners'

function* fetchCampaignPartners({ payload }) {
  try {
    const response = yield call(campaignPartnersApi.fetchCampaignPartners, payload)
    yield put(campaignPartnersModule.fetchCampaignPartnersSuccess(response))
  } catch (err) {
    yield put(campaignPartnersModule.fetchCampaignPartnersFailure(err))
  }
}

function* removeCampaignPartner({ payload }) {
  try {
    const { campaignPartnerId, opportunityPartnerId } = payload

    if (campaignPartnerId) {
      yield call(campaignPartnersApi.removeCampaignPartner, payload)
      yield put(campaignPartnersModule.removeCampaignPartnerSuccess({ campaignPartnerId }))
    }

    if (opportunityPartnerId) {
      yield call(campaignPartnersApi.removeOpportunityPartner, payload)
      yield put(
        campaignPartnersModule.removeCampaignPartnerSuccess({
          campaignPartnerId: opportunityPartnerId
        })
      )
    }
  } catch (err) {
    yield put(campaignPartnersModule.removeCampaignPartnerFailure(err))
  }
}

export const campaignPartnersSagas = [
  takeLatest(campaignPartnersModule.FETCH_CAMPAIGN_PARTNERS, fetchCampaignPartners),
  takeLatest(campaignPartnersModule.REMOVE_CAMPAIGN_PARTNER, removeCampaignPartner)
]
