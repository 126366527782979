import axios from 'axios'

// config
import baseUrl from '../config'

export const followEntity = async data => {
  const response = await axios({
    withCredentials: true,
    method: 'post',
    url: `${baseUrl}/follow-entity`,
    data
  })

  return response.data
}

export const unfollowEntity = async data => {
  const response = await axios({
    withCredentials: true,
    method: 'post',
    url: `${baseUrl}/unfollow-entity`,
    data
  })

  return response.data
}
