import React from 'react'
import { Helmet } from 'react-helmet'

// config
import { siteName } from 'config/localization'

const getMetaTags = ({ title, description, image, url }) => {
  const metaTags = [
    { itemprop: 'name', content: title },
    { itemprop: 'description', content: description },
    { name: 'description', content: description },
    { name: 'twitter:site', content: '@browniepointsSA' },
    { name: 'twitter:title', content: `${title} | browniepoints.africa` },
    { name: 'twitter:description', content: description },
    { name: 'twitter:creator', content: '@browniepointsSA' },
    { property: 'og:title', content: `${title} | browniepoints.africa` },
    { property: 'og:type', content: 'website' },
    { property: 'og:url', content: url },
    { property: 'og:description', content: description },
    { property: 'og:site_name', content: siteName },
    { property: 'og:locale', content: 'en_US' }
  ]

  if (image) {
    metaTags.push({ itemprop: 'image', content: image })
    metaTags.push({ property: 'og:image', content: image })
    metaTags.push({ property: 'og:image:url', content: image })
    metaTags.push({ property: 'og:image:secure_url', content: image })
    metaTags.push({ property: 'og:image:width', content: '300' })
    metaTags.push({ property: 'og:image:height', content: '300' })
    metaTags.push({ name: 'twitter:image:src', content: image })
    metaTags.push({ name: 'twitter:card', content: 'summary_large_image' })
  } else {
    metaTags.push({ name: 'twitter:card', content: 'summary' })
  }

  return metaTags
}

const getHtmlAttributes = ({ schema }) => {
  let result = { lang: 'en' }

  if (schema) {
    result = {
      ...result,
      itemscope: undefined,
      itemtype: `http://schema.org/${schema}`
    }
  }

  return result
}

const Seo = ({ schema, title, description, image, url }) => (
  <Helmet
    htmlAttributes={getHtmlAttributes({ schema })}
    title={title}
    link={[{ rel: 'canonical', href: url }]}
    meta={getMetaTags({ title, description, image, url })}
  />
)

export default Seo
