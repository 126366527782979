import React from 'react'
import styled from 'styled-components'

// utils
import th from 'utils/themeHelper'

const SingleCardContainer = styled.div`
  background: white;
  border-color: ${th('borders.medium')};
  border-radius: 5px;
  border-style: solid;
  border-width: 1px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.15);
  display: block;
  margin-bottom: 10px;
  padding: 10px 0;
  position: relative;
`

const DashCardWrapper = ({ children }) => {
  return <SingleCardContainer>{children}</SingleCardContainer>
}

export default DashCardWrapper
