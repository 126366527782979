import { createActions, handleActions } from 'redux-actions'

export const FETCH_NOTIFICATIONS = 'notifications/FETCH_NOTIFICATIONS'
export const FETCH_NOTIFICATIONS_SUCCESS = 'notifications/FETCH_NOTIFICATIONS_SUCCESS'
export const FETCH_NOTIFICATIONS_FAILURE = 'notifications/FETCH_NOTIFICATIONS_FAILURE'

export const DISMISS_NOTIFICATION = 'notifications/DISMISS_NOTIFICATION'
export const DISMISS_NOTIFICATION_SUCCESS = 'notifications/DISMISS_NOTIFICATION_SUCCESS'
export const DISMISS_NOTIFICATION_FAILURE = 'notifications/DISMISS_NOTIFICATION_FAILURE'

export const LOGOUT_SUCCESS = 'authentication/LOGOUT_SUCCESS'

export const {
  notifications: {
    dismissNotification,
    dismissNotificationSuccess,
    dismissNotificationFailure,
    fetchNotifications,
    fetchNotificationsSuccess,
    fetchNotificationsFailure
  }
} = createActions({
  [FETCH_NOTIFICATIONS]: undefined,
  [FETCH_NOTIFICATIONS_SUCCESS]: undefined,
  [FETCH_NOTIFICATIONS_FAILURE]: undefined,
  [DISMISS_NOTIFICATION]: undefined,
  [DISMISS_NOTIFICATION_SUCCESS]: undefined,
  [DISMISS_NOTIFICATION_FAILURE]: undefined
})

const initialState = {
  data: [],
  isError: false,
  isLoading: false,
  isSubmitting: false,
  singleNotificationSubmitting: null
}

export default handleActions(
  {
    [FETCH_NOTIFICATIONS]: state => ({ ...state, isLoading: true }),
    [FETCH_NOTIFICATIONS_SUCCESS]: (state, { payload }) => ({
      ...state,
      data: payload,
      isLoading: false,
      isError: false
    }),
    [FETCH_NOTIFICATIONS_FAILURE]: state => ({ ...state, isLoading: false, isError: true }),

    [DISMISS_NOTIFICATION]: (state, { payload }) => ({
      ...state,
      isLoading: false,
      isSubmitting: true,
      singleNotificationSubmitting: payload.id
    }),
    [DISMISS_NOTIFICATION_SUCCESS]: (state, { payload }) => {
      const newData = [...state.data].map(n => {
        if (n.id !== payload.id) return n
        return { ...n, status: 'Read' }
      })

      return {
        ...state,
        data: newData,
        isLoading: false,
        isSubmitting: false,
        singleNotificationSubmitting: null
      }
    },
    [DISMISS_NOTIFICATION_FAILURE]: state => {
      return { ...state, isLoading: false, isSubmitting: false, singleNotificationSubmitting: null }
    },

    [LOGOUT_SUCCESS]: () => initialState
  },
  initialState
)
