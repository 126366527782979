import { createActions, handleActions } from 'redux-actions'

// helpers
import { loadingError, startLoading } from './_helpers'

export const FETCH_CATEGORIES = 'categories/FETCH_CATEGORIES'
export const FETCH_FAILURE = 'categories/FETCH_FAILURE'
export const FETCH_SUCCESS = 'categories/FETCH_SUCCESS'
export const LOGOUT_SUCCESS = 'authentication/LOGOUT_SUCCESS'

export const {
  categories: { fetchCategories, fetchSuccess, fetchFailure }
} = createActions({
  [FETCH_CATEGORIES]: undefined,
  [FETCH_FAILURE]: undefined,
  [FETCH_SUCCESS]: undefined
})

const initialState = {
  data: [],
  isError: false,
  isLoading: false
}

export default handleActions(
  {
    [FETCH_CATEGORIES]: startLoading,
    [FETCH_FAILURE]: loadingError,
    [FETCH_SUCCESS]: (state, { payload }) => ({
      ...state,
      data: payload,
      isLoading: false,
      isError: false
    }),
    [LOGOUT_SUCCESS]: () => initialState
  },
  initialState
)
