import React from 'react'
import styled from 'styled-components'
import { LoaderAlt } from '../Icons/Icons'
import { Error } from '../InputLabels/InputLabels'
import th from 'utils/themeHelper'

const Wrapper = styled.div`
  display: block;
  width: 100%;
  position: relative;
`

const Input = styled.input`
  appearance: none;
  outline: none;
  border-radius: 3px;
  border: ${props => (props.hasError ? '2px' : '1px')} solid
    ${({ hasError, theme }) => (hasError ? theme.borders.error : theme.borders.default)};
  color: ${th('text.dark')};
  background: ${({ disabled, theme }) => (disabled ? theme.backgrounds.mid : 'white')};
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'text')};
  font-size: 15px;
  width: 100%;
  height: 45px;
  padding: 15px;

  &:hover {
    border: ${props => (props.hasError ? '2px' : '1px')} solid
      ${({ disabled, hasError, theme }) =>
        hasError ? theme.borders.error : disabled ? theme.borders.default : theme.borders.dark};
  }

  &:focus {
    border: ${props => (props.hasError ? '2px' : '1px')} solid
      ${({ disabled, hasError, theme }) =>
        hasError ? theme.borders.error : disabled ? theme.borders.default : theme.secondary.base};
  }

  &::placeholder {
    color: ${th('text.light')};
  }
`
const Label = styled.div`
  color: ${th('text.dark')};
  font-family: ${th('fonts.bold')};
  font-size: 13px;
  margin-bottom: 5px;
  padding: 2px;
`

const Success = styled.div`
  font-size: 13px;
  color: ${th('text.dark')};
  padding: 2px;
  margin-top: 5px;
`

const LoadingIconWrapper = styled.div`
  color: ${th('secondary.base')};
  position: absolute;
  height: 15px;
  right: 15px;
  top: 42px;
  z-index: 1;

  -webkit-animation-name: spin;
  -webkit-animation-duration: 4000ms;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;
  -moz-animation-name: spin;
  -moz-animation-duration: 4000ms;
  -moz-animation-iteration-count: infinite;
  -moz-animation-timing-function: linear;
  -ms-animation-name: spin;
  -ms-animation-duration: 4000ms;
  -ms-animation-iteration-count: infinite;
  -ms-animation-timing-function: linear;
  -o-transition: rotate(3600deg);

  @-moz-keyframes spin {
    from {
      -moz-transform: rotate(0deg);
    }
    to {
      -moz-transform: rotate(360deg);
    }
  }

  @-webkit-keyframes spin {
    from {
      -webkit-transform: rotate(0deg);
    }
    to {
      -webkit-transform: rotate(360deg);
    }
  }

  @keyframes spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
`

const LoadingIconContainer = styled.div`
  height: 100%;
  width: 100%;
`

const LoadingIcon = ({ icon: Icon }) => {
  return (
    <LoadingIconWrapper>
      <LoadingIconContainer>
        <Icon
          style={{
            display: 'block',
            width: '100%',
            height: '100%',
            position: 'relative'
          }}
        />
      </LoadingIconContainer>
    </LoadingIconWrapper>
  )
}

const RemovableTextInput = ({
  field,
  form: { errors, touched },
  label,
  success,
  children,
  showLoadingIcon = false,
  ...props
}) => {
  return (
    <Wrapper>
      {label && <Label htmlFor={field.name}>{label}</Label>}
      {children}

      <Input
        {...field}
        {...props}
        touched={!!touched[field.name]}
        hasError={!!errors[field.name]}
      />

      {showLoadingIcon && <LoadingIcon icon={LoaderAlt} />}

      {success && touched[field.name] && !errors[field.name] && <Success>{success}</Success>}
      {errors[field.name] && <Error>{errors[field.name]}</Error>}
    </Wrapper>
  )
}

export default RemovableTextInput
