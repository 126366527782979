import browniePointsDefault from './browniePointsDefault'

const isSocialEmploymentFund =
  process.env.WHITE_LABEL === 'jicp' ||
  process.env.WHITE_LABEL === 'sef' ||
  window.location.href.indexOf('jicp.browniepoints.africa') > -1 ||
  window.location.href.indexOf('sef.browniepoints') > -1 ||
  window.location.href.indexOf('sef-develop--browniepointsafrica') > -1 ||
  window.location.href.indexOf('sef-develop.browniepoints') > -1

const localize = (string, count) => {
  try {
    return browniePointsDefault(string, count)
  } catch (err) {
    throw new Error(`Localization error for ${string}`)
  }
}

// capitalize
export const c = string => string.charAt(0).toUpperCase() + string.slice(1)

// Uppercase
export const u = string => string.toUpperCase()

// Restrict features only to SEF site
export const isSef = isSocialEmploymentFund
export const isEmploymentSite = window.location.href.indexOf('employment.browniepoints') > -1

export const isSefEmployee =
  process.env.WHITE_LABEL === 'sef' ||
  window.location.href.indexOf('sef-employees.browniepoints') > -1 ||
  window.location.href.indexOf('employment.browniepoints') > -1 ||
  window.location.href.indexOf('browniepoints.datafree.co') > -1

export const canDisplayFeature = false

export const isJicp =
  process.env.WHITE_LABEL === 'jicp' || window.location.href.indexOf('jicp.browniepoints') > -1

export const isDgmt =
  process.env.WHITE_LABEL === 'dgmt' ||
  window.location.href.indexOf('getzeroed.co.za') > -1 ||
  window.location.href.indexOf('skipdata.co.za') > -1 ||
  window.location.href.indexOf('dgmt.browniepoints') > -1 ||
  window.location.href.indexOf('dgmt-sir.browniepoints') > -1

export const isGetZeroed = window.location.href.indexOf('getzeroed.co.za') > -1
export const isSkipData = window.location.href.indexOf('skipdata.co.za') > -1
export const siteName = isGetZeroed
  ? 'Get Zeroed'
  : isSkipData
  ? 'Skip Data'
  : isDgmt
  ? 'Social Innovation Register'
  : 'Brownie Points'

export default localize
