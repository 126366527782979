import { createActions, handleActions } from 'redux-actions'

export const FOLLOW_ENTITY = 'followings/FOLLOW_ENTITY'
export const FOLLOW_ENTITY_FAILURE = 'followings/FOLLOW_ENTITY_FAILURE'
export const FOLLOW_ENTITY_SUCCESS = 'followings/FOLLOW_ENTITY_SUCCESS'
export const UNFOLLOW_ENTITY = 'followings/UNFOLLOW_ENTITY'
export const UNFOLLOW_ENTITY_FAILURE = 'followings/UNFOLLOW_ENTITY_FAILURE'
export const UNFOLLOW_ENTITY_SUCCESS = 'followings/UNFOLLOW_ENTITY_SUCCESS'
export const LOGOUT_SUCCESS = 'authentication/LOGOUT_SUCCESS'

export const {
  followings: {
    followEntity,
    followEntitySuccess,
    followEntityFailure,
    unfollowEntity,
    unfollowEntitySuccess,
    unfollowEntityFailure
  }
} = createActions({
  [FOLLOW_ENTITY]: undefined,
  [FOLLOW_ENTITY_FAILURE]: undefined,
  [FOLLOW_ENTITY_SUCCESS]: undefined,
  [UNFOLLOW_ENTITY]: undefined,
  [UNFOLLOW_ENTITY_FAILURE]: undefined,
  [UNFOLLOW_ENTITY_SUCCESS]: undefined
})

const initialState = {
  errorMessage: null,
  isLoading: false,
  isSubmitting: false,
  isError: false,
  isSuccess: null
}

export default handleActions(
  {
    [FOLLOW_ENTITY]: () => ({
      errorMessage: null,
      isLoading: false,
      isError: false,
      isSuccess: false,
      isSubmitting: true
    }),
    [FOLLOW_ENTITY_SUCCESS]: state => ({
      ...state,
      isLoading: false,
      isSubmitting: false,
      isSuccess: true,
      isError: false
    }),
    [FOLLOW_ENTITY_FAILURE]: () => ({
      errorMessage: null,
      isSubmitting: false,
      isLoading: false,
      isSuccess: false,
      isError: true
    }),
    [UNFOLLOW_ENTITY]: () => ({
      errorMessage: null,
      isLoading: false,
      isError: false,
      isSuccess: false,
      isSubmitting: true
    }),
    [UNFOLLOW_ENTITY_SUCCESS]: state => ({
      ...state,
      isLoading: false,
      isSubmitting: false,
      isSuccess: true,
      isError: false
    }),
    [UNFOLLOW_ENTITY_FAILURE]: () => ({
      errorMessage: null,
      isSubmitting: false,
      isLoading: false,
      isSuccess: false,
      isError: true
    }),

    [LOGOUT_SUCCESS]: () => initialState
  },
  initialState
)
