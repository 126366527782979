import React, { Component, Fragment } from 'react'
import styled from 'styled-components'

// utils
import th from 'utils/themeHelper'

// config
import { isDgmt, isSef } from 'config/localization'

// Load components synchronously
import ConditionalDisplay from 'components/ConditionalDisplay/ConditionalDisplay'
// import MagicButton from 'components/MagicButton'
import LinkButton from '../SmallButtonPrimary/LinkButton'

const snapAgent = navigator.userAgent === 'ReactSnap'

const HeaderBar = styled.div`
  background-color: ${th('primary.light')};
  width: 100%;
  height: 10px;

  @media screen and (min-width: 769px) {
    display: none;
  }
`

const HeaderContainerInner = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 10px;
  text-align: ${props => (props.displayFormBar ? 'center' : 'left')};

  @media screen and (min-width: 769px) {
    flex-direction: row;
  }
`

const EmailConfirmationHint = styled.span`
  margin: 0 0 10px;

  @media screen and (min-width: 769px) {
    margin: 0;
  }
`

const HeaderContainer = styled.div`
  background-color: white;
  border-color: ${th('primary.light')};
  border-style: solid;
  border-width: 2px;
  line-height: 1.25em;
  margin: ${props => (props.displayFormBar ? '0' : '20px 0 20px')};
  position: relative;
  width: 100%;
  z-index: ${props => (props.displayFormBar ? '20' : '1')};

  @media screen and (min-width: 769px) {
    margin: ${props => (props.displayFormBar ? '0 auto' : '0 0 20px 0')};
    max-width: ${props => props.maxWidth || '100%'};
  }
`

class EmailConfirmationBanner extends Component {
  state = { emailConfirmationRequested: false }

  sendConfirmationEmail() {
    this.props.requestConfirmationEmail()
    this.setState({ emailConfirmationRequested: true })
  }

  render() {
    if (isDgmt) return null
    if (isSef) return null
    if (snapAgent) return null
    const { emailConfirmationRequested } = this.state

    return (
      <HeaderContainer {...this.props}>
        <HeaderBar />

        <HeaderContainerInner {...this.props}>
          <ConditionalDisplay displayWhen={[emailConfirmationRequested]}>
            <div>You should receive an account activation email shortly.</div>
          </ConditionalDisplay>

          <ConditionalDisplay displayWhen={[!emailConfirmationRequested]}>
            <Fragment>
              <EmailConfirmationHint>
                Please activate your account by clicking the link in your email inbox (or spam
                folder).
              </EmailConfirmationHint>

              <LinkButton onClick={() => this.sendConfirmationEmail()} type="button">
                Send activation email
              </LinkButton>

              {/* <MagicButton
                label="Send activation email"
                handleClick={() => this.sendConfirmationEmail()}
                particleCount={10}
                xSize={160}
                ySize={160}
              /> */}
            </Fragment>
          </ConditionalDisplay>
        </HeaderContainerInner>
      </HeaderContainer>
    )
  }
}

export default EmailConfirmationBanner
