import axios from 'axios'
import { navigate } from '@reach/router'

// utils and config
import baseUrl from '../config'
import l from 'config/localization'

// utils
import cleanPayload from 'utils/cleanPayload'

export const fetchOrganisations = async (payload = {}) => {
  const cleanedPayload = cleanPayload(payload)

  const response = await axios.get(`${baseUrl}/organisations`, {
    withCredentials: true,
    params: { ...cleanedPayload }
  })

  return response.data
}

export const fetchOwnOrganisations = async (payload = {}) => {
  const cleanedPayload = cleanPayload(payload)

  const response = await axios.get(`${baseUrl}/own-organisations`, {
    withCredentials: true,
    params: { ...cleanedPayload }
  })

  return response.data
}

export const fetchSefOrganisations = async (payload = {}) => {
  const cleanedPayload = cleanPayload(payload)

  const response = await axios.get(`${baseUrl}/sef-organisations`, {
    withCredentials: true,
    params: { ...cleanedPayload }
  })

  return response.data
}

export const fetchOrganisation = async id => {
  const response = await axios.get(`${baseUrl}/organisations/${id}`, { withCredentials: true })

  return response.data
}

export const fetchOrgForPublicPage = async orgName => {
  const hasAlphabetCharacters = orgName.match(/\D/)
  if (hasAlphabetCharacters) {
    const response = await axios.get(`${baseUrl}/orgs/${orgName}`, { withCredentials: true })
    return response.data
  }

  const orgObject = await axios.get(`${baseUrl}/orgs/id/${orgName}`, { withCredentials: true })
  const urlName = orgObject.data.url_name

  navigate(`/${l('ROUTE_ORGS')}/${urlName}`, { replace: true })

  const response = await axios.get(`${baseUrl}/orgs/${urlName}`, { withCredentials: true })
  return response.data
}

export const createOrganisation = async data => {
  const response = await axios({
    withCredentials: true,
    method: 'post',
    url: `${baseUrl}/organisations`,
    data,
    config: { headers: { 'Content-Type': 'multipart/form-data' } }
  })

  return response.data
}

export const claimOrganisation = async data => {
  const response = await axios({
    withCredentials: true,
    method: 'post',
    url: `${baseUrl}/organisations/claim`,
    data
  })

  return response.data
}

export const joinOrganisationRequest = async data => {
  const response = await axios({
    withCredentials: true,
    method: 'post',
    url: `${baseUrl}/organisations/join`,
    data
  })

  return response.data
}

export const claimOrganisationRequest = async data => {
  const response = await axios({
    withCredentials: true,
    method: 'post',
    url: `${baseUrl}/organisations/claim-request`,
    data
  })

  return response.data
}

export const requestVerification = async data => {
  const response = await axios({
    withCredentials: true,
    method: 'put',
    url: `${baseUrl}/organisations/${data.get('id')}/verify-request`,
    data,
    config: { headers: { 'Content-Type': 'multipart/form-data' } }
  })

  return response.data
}

export const updateOrganisation = async data => {
  const response = await axios({
    withCredentials: true,
    method: 'put',
    url: `${baseUrl}/organisations/${data.get('id')}`,
    data,
    config: { headers: { 'Content-Type': 'multipart/form-data' } }
  })

  return response.data
}

export const updateBillingInfo = data => {
  return axios.put(`${baseUrl}/organisations/update-billing-info/${data.id}`, {
    data,
    withCredentials: true
  })
}

export const deleteOrganisation = async organisationId => {
  return await axios.delete(`${baseUrl}/organisations/${organisationId}`, {
    withCredentials: true,
    data: { organisationId }
  })
}

export const removeOrgAdmin = async ({
  organisationId,
  adminId,
  membershipRequestToRescind,
  memberToRequestRemoveId
}) => {
  return await axios.delete(`${baseUrl}/organisations/${organisationId}/remove-admin/${adminId}`, {
    withCredentials: true,
    data: { membershipRequestToRescind, memberToRequestRemoveId }
  })
}

export const addOrgAdmin = async data => {
  const { organisationId } = data
  return await axios.post(`${baseUrl}/organisations/${organisationId}/add-admin`, {
    data,
    withCredentials: true
  })
}

export const updateOrgAdmin = async data => {
  const { adminId, organisationId } = data
  return await axios.put(`${baseUrl}/organisations/${organisationId}/update-admin/${adminId}`, {
    data,
    withCredentials: true
  })
}

export const manageOrgMemberRequest = async data => {
  const { organisationId } = data
  return await axios.put(`${baseUrl}/organisation-member-requests/${organisationId}`, {
    data,
    withCredentials: true
  })
}

export const manageOrgMemberInvitation = async data => {
  const { organisationId } = data
  return await axios.put(`${baseUrl}/organisation-member-invitations/${organisationId}`, {
    data,
    withCredentials: true
  })
}

export const fetchOrgGoalsForOrgAdmin = async (payload = {}) => {
  const cleanedPayload = cleanPayload(payload)

  const response = await axios.get(`${baseUrl}/organisation-goals`, {
    withCredentials: true,
    params: { ...cleanedPayload }
  })

  return response.data
}

export const fetchOrgGoal = async ({ goalId }) => {
  const response = await axios.get(`${baseUrl}/organisation-goals/${goalId}`, {
    withCredentials: true
  })

  return response.data
}

export const createOrgGoal = async data => {
  const response = await axios({
    withCredentials: true,
    method: 'post',
    url: `${baseUrl}/organisation-goals`,
    data,
    config: { headers: { 'Content-Type': 'multipart/form-data' } }
  })

  return response.data
}

export const deleteOrgGoal = async goalId => {
  return await axios.delete(`${baseUrl}/organisation-goals/${goalId}`, {
    withCredentials: true,
    data: { goalId }
  })
}

export const updateOrgGoal = async data => {
  const response = await axios({
    withCredentials: true,
    method: 'put',
    url: `${baseUrl}/organisation-goals/${data.get('id')}`,
    data,
    config: { headers: { 'Content-Type': 'multipart/form-data' } }
  })

  return response.data
}

export const fetchOrgGoalMilestone = async ({ milestoneId }) => {
  const response = await axios.get(`${baseUrl}/organisation-goal-milestones/${milestoneId}`, {
    withCredentials: true
  })

  return response.data
}

export const createOrgGoalMilestone = async data => {
  const response = await axios({
    withCredentials: true,
    method: 'post',
    url: `${baseUrl}/organisation-goal-milestones`,
    data,
    config: { headers: { 'Content-Type': 'multipart/form-data' } }
  })

  return response.data
}

export const deleteOrgGoalMilestone = async milestoneId => {
  return await axios.delete(`${baseUrl}/organisation-goal-milestones/${milestoneId}`, {
    withCredentials: true,
    data: { milestoneId }
  })
}

export const updateOrgGoalMilestone = async data => {
  const response = await axios({
    withCredentials: true,
    method: 'put',
    url: `${baseUrl}/organisation-goal-milestones/${data.get('id')}`,
    data,
    config: { headers: { 'Content-Type': 'multipart/form-data' } }
  })

  return response.data
}
