import React from 'react'
import styled from 'styled-components'

// config and utils
import constants from 'config/constants'
import l from 'config/localization'
import th from 'utils/themeHelper'

// Load components synchronously
import LargeButton from 'components/LargeButton/LargeButton'
import Link from 'components/Link'

const partnerPath = constants.S3_ASSET_PATH + 'partners/'
const rcs = partnerPath + 'rcs-logo.png'
const rcsWebP = partnerPath + 'rcs-logo.webp'
const shoprite = partnerPath + 'shoprite-logo.png'
const shopriteWebP = partnerPath + 'shoprite-logo.webp'
const rmb = partnerPath + 'rand-merchant-bank-logo.png'
const rmbWebP = partnerPath + 'rand-merchant-bank-logo.webp'
const woolworths = partnerPath + 'woolworths-logo.png'
const woolworthsWebP = partnerPath + 'woolworths-logo.webp'

const SectionContainer = styled.div`
  background: ${th('backgrounds.light')};
  padding: 50px 8px;

  @media screen and (min-width: 730px) {
    padding: 50px 35px;
  }

  @media screen and (min-width: 1024px) {
    padding: 50px 70px;
  }
`
const Heading = styled.h3`
  text-align: center;
`

const PartnersContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
`

const PartnerLink = styled(Link)`
  margin: 0 30px;

  &:first-child {
    margin-left: 0;
  }

  &:last-child {
    margin-right: 0;
  }
`

const PartnerLogo = styled.img`
  cursor: pointer;
  height: 100px;
  padding: 10px 20px;
`

const CenteredParagraph = styled.p`
  margin: 0 1em;
  text-align: justify;

  @media screen and (min-width: 600px) {
    margin: 0 auto;
    text-align: center;
    max-width: 40%;
  }
`

const TypeformLink = styled(Link)`
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: 20px 0;
`

const LandingPagePartners = () => (
  <SectionContainer>
    <Heading>We've partnered with</Heading>
    <PartnersContainer>
      <PartnerLink
        to="https://www.iol.co.za/news/south-africa/western-cape/the-story-behind-the-rcs-gugs-race-18225473"
        target="_blank"
        rel="noopener"
      >
        <picture>
          <source srcSet={rcsWebP} type="image/webp" />
          <source srcSet={rcs} type="image/png" />
          <PartnerLogo src={rcs} alt="RCS logo" />
        </picture>
      </PartnerLink>

      <PartnerLink
        to="https://www.shopriteholdings.co.za/articles/CSI/csi---we-reach-out-to-communities/community-care/africas-biggest-cleanup-event.html?/"
        target="_blank"
        rel="noopener"
      >
        <picture>
          <source srcSet={shopriteWebP} type="image/webp" />
          <source srcSet={shoprite} type="image/png" />
          <PartnerLogo src={shoprite} alt="Shoprite logo" />
        </picture>
      </PartnerLink>

      <PartnerLink
        to="https://www.rmb.co.za/page/employee-engagement-in-society"
        target="_blank"
        rel="noopener"
      >
        <picture>
          <source srcSet={rmbWebP} type="image/webp" />
          <source srcSet={rmb} type="image/png" />
          <PartnerLogo src={rmb} alt="Rand Merchant Bank logo" />
        </picture>
      </PartnerLink>

      <PartnerLink
        to="https://blog.browniepoints.africa/santa-shoebox-project/"
        target="_blank"
        rel="noopener"
      >
        <picture>
          <source srcSet={woolworthsWebP} type="image/webp" />
          <source srcSet={woolworths} type="image/png" />
          <PartnerLogo src={woolworths} alt="Woolworths logo" />
        </picture>
      </PartnerLink>
    </PartnersContainer>

    <TypeformLink to="mailto:blake@browniepoints.africa" target="_blank" rel="noopener">
      <LargeButton>Engage your team and community</LargeButton>
    </TypeformLink>

    <CenteredParagraph>
      Making a difference is easy, fun and rewarding. Brownie Points engages and empowers your team
      and community through measured impact {l('CAMPS')} and {l('OPPS')}. Save time, increase
      participation, make more impact.
    </CenteredParagraph>
  </SectionContainer>
)

export default LandingPagePartners
