import { call, put, takeLatest } from 'redux-saga/effects'
import * as instagramBioLinksApi from '../apiModules/instagramBioLinks'
import * as instagramBioLinksModule from '../modules/instagramBioLinks'
import logError from '../../utils/logger'

function* fetchInstagramBioLinks({ payload }) {
  try {
    const response = yield call(instagramBioLinksApi.fetchInstagramBioLinks, payload)
    yield put(instagramBioLinksModule.fetchInstagramBioLinksSuccess(response))
  } catch (err) {
    logError(err)
    yield put(instagramBioLinksModule.fetchInstagramBioLinksFailure(err))
  }
}

function* createInstagramBioLink({ payload }) {
  try {
    const createdInstagramBio = yield call(instagramBioLinksApi.createInstagramBioLink, payload)
    yield put(instagramBioLinksModule.createInstagramBioLinkSuccess(createdInstagramBio))
  } catch (err) {
    yield put(instagramBioLinksModule.createInstagramBioLinkFailure(err.response.data))
  }
}

function* deleteInstagramBioLink({ payload }) {
  try {
    yield call(instagramBioLinksApi.deleteInstagramBioLink, payload)
    yield put(instagramBioLinksModule.deleteInstagramBioLinkSuccess(payload))
  } catch (err) {
    yield put(instagramBioLinksModule.deleteInstagramBioLinkFailure())
  }
}

export const instagramBioLinksSagas = [
  takeLatest(instagramBioLinksModule.CREATE_INSTAGRAM_BIO_LINK, createInstagramBioLink),
  takeLatest(instagramBioLinksModule.FETCH_INSTAGRAM_BIO_LINKS, fetchInstagramBioLinks),
  takeLatest(instagramBioLinksModule.DELETE_INSTAGRAM_BIO_LINK, deleteInstagramBioLink)
]
