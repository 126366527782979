import React, { Fragment } from 'react'

// styles
import {
  StoryText,
  OpportunityDescriptionNotice,
  PrivateStoryWarningMessage,
  UpdatedCardNotice
} from './styles'

// Load components synchronously
import SingleCard from 'components/SingleCard/SingleCard'

const HiddenStoryMessage = () => {
  return (
    <SingleCard gold>
      <Fragment>
        <UpdatedCardNotice>Story Hidden</UpdatedCardNotice>

        <OpportunityDescriptionNotice>
          This story will no longer be displayed on this page.
        </OpportunityDescriptionNotice>
      </Fragment>
    </SingleCard>
  )
}

const PrivateStoryMessage = () => {
  return (
    <SingleCard>
      <PrivateStoryWarningMessage>
        <TitleForStoryInfo storyTitle="Cannot Display Story" />

        <StoryText marginBottom storyText="The story that you are trying to view is private." />
      </PrivateStoryWarningMessage>
    </SingleCard>
  )
}

const VisibleStoryMessage = () => {
  return (
    <SingleCard gold>
      <Fragment>
        <UpdatedCardNotice>Story Updated</UpdatedCardNotice>

        <OpportunityDescriptionNotice>
          This story has been set to visible.
        </OpportunityDescriptionNotice>
      </Fragment>
    </SingleCard>
  )
}

export default { HiddenStoryMessage, PrivateStoryMessage, VisibleStoryMessage }
