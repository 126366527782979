import axios from 'axios'
import baseUrl from '../config'

export const fetchCategories = async () => {
  const data = await axios(`${baseUrl}/categories`, {
    method: 'GET',
    responseType: 'json'
  })

  return data
}
