import axios from 'axios'
import baseUrl from '../config'

const determinePlacesQuery = ({ input }) => {
  const inputIsObject = typeof input === 'object'
  if (!inputIsObject) return encodeURIComponent(input)

  const { onlySuburb } = input
  delete input['onlySuburb']

  const query = Object.values(input).join('')
  const cleanedInput = encodeURIComponent(query)
  if (!onlySuburb) return cleanedInput

  return `${cleanedInput}?onlySuburb=true`
}

export const fetchPlaces = async input => {
  const cleanedInput = determinePlacesQuery({ input })
  const request = await axios(`${baseUrl}/google-places-request/${cleanedInput}`, {
    method: 'GET',
    responseType: 'json'
  })

  return request.data
}

export const fetchNearbyCities = async ({ country }) => {
  const request = await axios(`${baseUrl}/fetch-nearby-cities`, {
    method: 'GET',
    responseType: 'json',
    params: { country }
  })

  return request.data
}

export const fetchNearbyCitiesList = async ({ l }) => {
  const request = await axios(`${baseUrl}/search/fetch-nearby-cities`, {
    method: 'GET',
    responseType: 'json',
    params: { l }
  })

  return request.data
}
