import styled from 'styled-components'

// config
import { isSef } from 'config/localization'

const DashboardSideNav = styled.div`
  align-content: baseline;
  display: ${props => (props.displayMobile ? 'flex' : 'none')};
  flex-wrap: wrap;
  margin-top: 0;
  padding-top: 0;
  z-index: 2;

  @media screen and (min-width: 769px) {
    overflow-y: ${props => (props.sidebarIsCollapsed ? 'visible' : 'auto')};
    display: flex;
    flex-direction: row;
    flex-wrap: no-wrap;
    margin-top: 65px;
    padding-top: 10px;
    position: ${props => (props.sidebarIsCollapsed ? 'relative' : 'fixed')};
    top: 0;
    width: ${props => (props.sidebarIsCollapsed ? '100%' : isSef ? '160px' : '200px')};

    // scrollbar hiding
    scrollbar-width: none;
    -ms-overflow-style: none;
    &::-webkit-scrollbar {
      height: 0;
      width: 0;
    }
  }
`

export default DashboardSideNav
