import { call, put, takeLatest } from 'redux-saga/effects'
import { navigate } from '@reach/router'
import * as paymentsApi from '../apiModules/payments'
import * as paymentsModule from '../modules/payments'

function* generatePaymentIdentifier({ payload }) {
  try {
    const response = yield call(paymentsApi.generatePaymentIdentifier, payload)
    yield put(paymentsModule.generatePaymentIdentifierSuccess(response))
  } catch (err) {
    yield put(paymentsModule.generatePaymentIdentifierFailure())
  }
}

function* recordPayment({ payload }) {
  try {
    yield call(paymentsApi.confirmSuccessfulPayment, payload)
    yield put(paymentsModule.recordPaymentSuccess(payload))

    if (payload.mockPayment) {
      navigate(`${window.location.search}&isSuccess=true`, { replace: true })
      window.location.reload()
    }
  } catch (err) {
    yield put(paymentsModule.generatePaymentIdentifierFailure(err))
  }
}

function* recordPaymentFailure({ payload }) {
  try {
    yield call(paymentsApi.recordFailedPayment, payload)
    yield put(paymentsModule.generatePaymentIdentifierFailure())
  } catch (err) {
    yield put(paymentsModule.generatePaymentIdentifierFailure())
  }
}

function* withdrawPayfastDonation({ payload }) {
  try {
    yield call(paymentsApi.withdrawPayfastDonation, payload)
    navigate(`/dashboard/contributions?type=money`)
  } catch (err) {}
}

export const paymentsSagas = [
  takeLatest(paymentsModule.GENERATE_PAYMENT_IDENTIFIER, generatePaymentIdentifier),
  takeLatest(paymentsModule.RECORD_PAYMENT_FAILURE, recordPaymentFailure),
  takeLatest(paymentsModule.RECORD_PAYMENT, recordPayment),
  takeLatest(paymentsModule.WITHDRAW_PAYFAST_DONATION, withdrawPayfastDonation)
]
