import React from 'react'
import { Link } from '@reach/router'
import styled from 'styled-components'
import lighten from 'polished/lib/color/lighten'

// utils
import th from 'utils/themeHelper'

// assets
import { Dot } from '@styled-icons/bootstrap'

// Load components synchronously
import Tooltip from 'components/Tooltip'

// config
import { isDgmt } from 'config/localization'

const NavItem = styled.div`
  align-items: center;
  background: ${props => (props.isActive ? th('primary.base') : 'inherit')};
  border-radius: 8px;
  color: ${props => (props.isActive ? 'white' : th('text.dark'))};
  display: flex;
  flex-direction: row;
  font-size: 13px;
  flex-wrap: nowrap;
  font-family: ${th('fonts.regular')};
  line-height: 16px;
  margin: 0;
  margin-left: ${props => props.marginLeft || 0};
  padding: ${props => (props.sidebarIsCollapsed ? '6px 10px' : '6px 16px 6px 10px')};
  position: relative;
  text-decoration: none;
  text-transform: uppercase;
  white-space: nowrap;

  @media screen and (max-width: 769px) {
    background: ${props => (props.isActive ? th('primary.base') : 'white')};
    border: 1px solid ${props => (props.isActive ? th('primary.base') : th('borders.medium'))};
    font-size: 12px;
    padding: 7px 14px 6px 8px;
  }
`

const IconContainer = styled.i`
  height: ${props => props.size || '20px'};

  & > svg {
    width: ${props => props.size || '20px'};
    height: ${props => props.size || '20px'};
    margin-right: ${props => (props.sidebarIsCollapsed ? '0' : '15px')};
    margin-top: 0;
  }

  & > svg,
  & > svg > path,
  & > svg > g > path {
    fill: ${props =>
      props.hover === 'exception' ? 'auto' : props.isActive ? 'white' : th('text.dark')};
  }

  @media screen and (max-width: 769px) {
    & > svg {
      margin-right: 5px;
      margin-top: -1px;
    }
  }
`

const Container = styled.div`
  @media screen and (max-width: 769px) {
    display: ${props => (props.hiddenOnMobile ? 'none' : 'flex')};
  }
`

const DotWrapper = styled.div`
  position: absolute;
  right: -6px;
  top: -3px;

  @media screen and (min-width: 769px) {
    right: ${isDgmt ? '35px' : '-5px'};
    top: -5px;
  }

  svg {
    color: ${th('text.error')};
    width: 30px;
  }
`

const NavItemWrapper = styled(Link)`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  margin: 0 0 1px 5px;
  padding: 0;
  position: relative;

  &:hover ${IconContainer} {
    & > svg,
    & > svg > path,
    & > svg > g > path {
      fill: ${props => (props.hover === 'exception' ? 'auto' : 'white')};
    }
  }

  @media screen and (min-width: 769px) {
    &:hover ${NavItem} {
      background: ${lighten(0.1, '#379B75')};
      color: white;
    }
  }

  @media screen and (max-width: 769px) {
    height: 34px;
    margin: 0 8px 8px 0;
  }
`

const DotIcon = ({ showNotificationDot }) => {
  if (!showNotificationDot) return null

  return (
    <DotWrapper>
      <Dot />
    </DotWrapper>
  )
}

const ItemLabel = ({ label, sidebarIsCollapsed }) => {
  if (sidebarIsCollapsed) return null
  return <div>{label}</div>
}

const InnerNavItem = ({ Icon, sidebarIsCollapsed, ...props }) => {
  if (!sidebarIsCollapsed) {
    return (
      <NavItem
        isActive={props.isActive}
        marginLeft={props.marginLeft}
        sidebarIsCollapsed={sidebarIsCollapsed}
      >
        <IconContainer isActive={props.isActive} sidebarIsCollapsed={sidebarIsCollapsed} {...props}>
          <Icon />
        </IconContainer>
        <ItemLabel label={props.label} sidebarIsCollapsed={sidebarIsCollapsed} />
      </NavItem>
    )
  }

  return (
    <Tooltip cursor="pointer" position="right" content={props.label} forceShow isWarning minWidth>
      <NavItem
        isActive={props.isActive}
        marginLeft={props.marginLeft}
        sidebarIsCollapsed={sidebarIsCollapsed}
      >
        <IconContainer isActive={props.isActive} sidebarIsCollapsed={sidebarIsCollapsed} {...props}>
          <Icon />
        </IconContainer>
        <ItemLabel label={props.label} sidebarIsCollapsed={sidebarIsCollapsed} />
      </NavItem>
    </Tooltip>
  )
}

const DashboardNavItem = ({
  children,
  hidden,
  hiddenOnMobile,
  isActive,
  label,
  marginLeft,
  showNotificationDot,
  sidebarIsCollapsed,
  Icon,
  ...props
}) => {
  if (hidden) return null

  if (Icon) {
    return (
      <Container hiddenOnMobile={hiddenOnMobile}>
        <NavItemWrapper {...props} className={isActive ? 'active' : null} tabIndex="-1">
          <InnerNavItem
            Icon={Icon}
            isActive={isActive}
            marginLeft={marginLeft}
            sidebarIsCollapsed={sidebarIsCollapsed}
            label={label}
            {...props}
          />

          {isActive && children}

          <DotIcon showNotificationDot={isActive ? false : showNotificationDot} />
        </NavItemWrapper>
      </Container>
    )
  }

  return (
    <Container hiddenOnMobile={hiddenOnMobile}>
      <NavItemWrapper {...props} className={isActive ? 'active' : null} tabIndex="-1">
        <NavItem isActive={isActive} sidebarIsCollapsed={sidebarIsCollapsed}>
          <ItemLabel label={label} sidebarIsCollapsed={sidebarIsCollapsed} />
        </NavItem>

        {isActive && children}

        <DotIcon showNotificationDot={isActive ? false : showNotificationDot} />
      </NavItemWrapper>
    </Container>
  )
}

export default DashboardNavItem
