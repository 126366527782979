import React from 'react'
import styled from 'styled-components'

// utils
import th from 'utils/themeHelper'

// Load components synchronously
import ConditionalDisplay from 'components/ConditionalDisplay/ConditionalDisplay'

const getLineWidth = ({ steps }) => {
  if (steps === 4) return '200px'
  if (steps === 3) return '150px'
  return '100px'
}

const FormProgressWrapper = styled.div`
  @media screen and (min-width: 769px) {
    margin: 10px auto;
  }
`

const FormProgressBar = styled.div`
  display: flex;
  justify-content: center;
  margin: auto;
  width: 100%;
`

const FormProgressLine = styled.div`
  height: 2px;
  width: ${getLineWidth};
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-bottom-color: rgb(217, 217, 217);
  position: absolute;
  margin-top: 8px;
`

const FormProgressList = styled.ul`
  display: flex;
  width: ${getLineWidth};
  list-style: none;
  padding: 0px;
  margin: initial;
  justify-content: space-between;
  z-index: 1;
`

const FormProgressListItem = styled.li`
  align-items: center;
  background: ${props => {
    if (props.active) return th('primary.base')
    if (props.completed) return 'white'
    if (props.unlocked) return 'white'

    return th('backgrounds.mid')
  }};
  border-color: ${props =>
    props.active ? th('text.dark') : props.unlocked ? th('text.dark') : th('borders.medium')};
  border-style: solid;
  border-width: ${props => (props.active ? '2px' : props.unlocked ? '2px' : '0')};
  border-radius: 50%;
  color: ${props => (props.active ? '#FFF' : th('text.dark'))};
  cursor: ${props => (props.disabled ? 'default' : 'pointer')};
  display: inline-flex;
  font-family: ${th('fonts.bold')};
  font-size: 12px;
  height: 20px;
  justify-content: center;
  margin-left: 0px;
  text-align: center;
  width: 20px;
`

const handleProgressNavigation = ({ handleNav, step }) => {
  if (!handleNav) return null

  handleNav(step)
}

const FormProgressBars = ({
  active,
  canNavTo = [],
  handleNav,
  steps = 3,
  unlockedBreadcrumbs = []
}) => {
  // No point showing this if there's only one step
  if (steps === 1) return null

  return (
    <FormProgressWrapper>
      <FormProgressBar>
        <FormProgressLine steps={steps} />
        <FormProgressList steps={steps}>
          <FormProgressListItem
            active={active === 1}
            completed={active > 1}
            disabled={!canNavTo.includes(1)}
            onClick={() => {
              return canNavTo.includes(1) ? handleProgressNavigation({ handleNav, step: 1 }) : null
            }}
            unlocked={unlockedBreadcrumbs.includes(1)}
          >
            1
          </FormProgressListItem>

          <ConditionalDisplay displayWhen={[steps > 1]}>
            <FormProgressListItem
              active={active === 2}
              completed={active > 2}
              disabled={!canNavTo.includes(2)}
              onClick={() => {
                return canNavTo.includes(2)
                  ? handleProgressNavigation({ handleNav, step: 2 })
                  : null
              }}
              unlocked={unlockedBreadcrumbs.includes(2)}
            >
              2
            </FormProgressListItem>
          </ConditionalDisplay>

          <ConditionalDisplay displayWhen={[steps > 2]}>
            <FormProgressListItem
              active={active === 3}
              completed={active > 3}
              disabled={!canNavTo.includes(3)}
              onClick={() => {
                return canNavTo.includes(3)
                  ? handleProgressNavigation({ handleNav, step: 3 })
                  : null
              }}
              unlocked={unlockedBreadcrumbs.includes(3)}
            >
              3
            </FormProgressListItem>
          </ConditionalDisplay>

          <ConditionalDisplay displayWhen={[steps > 3]}>
            <FormProgressListItem
              active={active === 4}
              completed={active > 4}
              disabled={!canNavTo.includes(4)}
              onClick={() => {
                return canNavTo.includes(4)
                  ? handleProgressNavigation({ handleNav, step: 4 })
                  : null
              }}
              unlocked={unlockedBreadcrumbs.includes(4)}
            >
              4
            </FormProgressListItem>
          </ConditionalDisplay>

          <ConditionalDisplay displayWhen={[steps > 4]}>
            <FormProgressListItem
              active={active === 5}
              completed={active > 5}
              disabled={!canNavTo.includes(5)}
              onClick={() => {
                return canNavTo.includes(5)
                  ? handleProgressNavigation({ handleNav, step: 5 })
                  : null
              }}
              unlocked={unlockedBreadcrumbs.includes(5)}
            >
              5
            </FormProgressListItem>
          </ConditionalDisplay>
        </FormProgressList>
      </FormProgressBar>
    </FormProgressWrapper>
  )
}

export default FormProgressBars
