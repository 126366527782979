import { createActions, handleActions } from 'redux-actions'

// utils
import UrlBuilder from 'utils/urlBuilder'

// helpers
import { loadingError, startLoading } from './_helpers'

export const CREATE_FAQ = 'faqs/CREATE_FAQ'
export const CREATE_FAQ_FAILURE = 'faqs/CREATE_FAQ_FAILURE'
export const CREATE_FAQ_SUCCESS = 'faqs/CREATE_FAQ_SUCCESS'
export const DELETE_FAILURE = 'faqs/DELETE_FAILURE'
export const DELETE_FAQ = 'faqs/DELETE_FAQ'
export const DELETE_SUCCESS = 'faqs/DELETE_SUCCESS'
export const FETCH_FAILURE = 'faqs/FETCH_FAILURE'
export const FETCH_FAQ = 'faqs/FETCH_FAQ'
export const FETCH_FAQS = 'faqs/FETCH_FAQS'
export const FETCH_FAQS_FOR_ORG_ADMIN = 'faqs/FETCH_FAQS_FOR_ORG_ADMIN'
export const FETCH_SUCCESS = 'faqs/FETCH_SUCCESS'
export const LOGOUT_SUCCESS = 'authentication/LOGOUT_SUCCESS'
export const SINGLE_FETCH_FAILURE = 'faqs/SINGLE_FETCH_FAILURE'
export const SINGLE_FETCH_SUCCESS = 'faqs/SINGLE_FETCH_SUCCESS'
export const UPDATE_FAQ = 'faqs/UPDATE_FAQ'
export const UPDATE_FAQ_FAILURE = 'faqs/UPDATE_FAQ_FAILURE'
export const UPDATE_FAQ_SUCCESS = 'faqs/UPDATE_FAQ_SUCCESS'

export const {
  faqs: {
    createFaq,
    createFaqFailure,
    createFaqSuccess,
    deleteFailure,
    deleteFaq,
    deleteSuccess,
    fetchFailure,
    fetchFaq,
    fetchFaqs,
    fetchFaqsForOrgAdmin,
    fetchSuccess,
    singleFetchFailure,
    singleFetchSuccess,
    updateFaq,
    updateFaqFailure,
    updateFaqSuccess
  }
} = createActions({
  [CREATE_FAQ]: undefined,
  [CREATE_FAQ_FAILURE]: undefined,
  [CREATE_FAQ_SUCCESS]: undefined,
  [DELETE_FAILURE]: undefined,
  [DELETE_FAQ]: undefined,
  [DELETE_SUCCESS]: undefined,
  [FETCH_FAQ]: undefined,
  [FETCH_FAQS]: undefined,
  [FETCH_FAQS_FOR_ORG_ADMIN]: undefined,
  [FETCH_FAILURE]: undefined,
  [FETCH_SUCCESS]: undefined,
  [SINGLE_FETCH_FAILURE]: undefined,
  [SINGLE_FETCH_SUCCESS]: undefined,
  [UPDATE_FAQ]: undefined,
  [UPDATE_FAQ_FAILURE]: undefined,
  [UPDATE_FAQ_SUCCESS]: undefined
})

const initialPaginatedData = {
  faqsCount: 0,
  pageCount: 0
}

const initialState = {
  data: [],
  isError: false,
  isLoading: false,
  isSubmitting: false,
  singleFaqData: {},
  paginatedFaqsData: initialPaginatedData
}

export default handleActions(
  {
    [CREATE_FAQ]: (state, { payload }) => ({
      ...state,
      ...payload,
      isLoading: false,
      isSubmitting: true
    }),
    [CREATE_FAQ_FAILURE]: (state, { payload }) => ({
      ...state,
      hasError: true,
      errorMessage: payload,
      isLoading: false,
      isSubmitting: false
    }),
    [CREATE_FAQ_SUCCESS]: (state, { payload }) => {
      if (!payload.isPartialUpdate) {
        UrlBuilder.displayUpdateSuccessMessage()
      }

      return { ...state, singleFaqData: payload, isLoading: false, isSubmitting: false }
    },
    [DELETE_FAILURE]: state => ({ ...state, isLoading: false, faqId: null }),
    [DELETE_FAQ]: (state, { payload }) => ({
      ...state,
      isLoading: true,
      faqId: payload
    }),
    [DELETE_SUCCESS]: (state, { payload }) => {
      return {
        ...state,
        isLoading: false,
        isSubmitting: false,
        data: state.data.filter(o => o.id !== payload.faqId),
        faqId: null
      }
    },
    [FETCH_FAILURE]: loadingError,
    [FETCH_FAQ]: startLoading,
    [FETCH_FAQS]: startLoading,
    [FETCH_FAQS_FOR_ORG_ADMIN]: startLoading,
    [FETCH_SUCCESS]: (state, { payload }) => ({
      ...state,
      data: payload,
      isLoading: false,
      isError: false
    }),
    [LOGOUT_SUCCESS]: () => initialState,
    [SINGLE_FETCH_FAILURE]: loadingError,
    [SINGLE_FETCH_SUCCESS]: (state, { payload }) => ({
      ...state,
      isLoading: false,
      hasSubmitted: false,
      singleFaqData: payload
    }),
    [UPDATE_FAQ]: state => ({ ...state, isLoading: false, isSubmitting: true }),
    [UPDATE_FAQ_FAILURE]: state => ({ ...state, isLoading: false, isSubmitting: false }),
    [UPDATE_FAQ_SUCCESS]: (state, { payload }) => {
      if (!payload.isPartialUpdate) {
        UrlBuilder.displayUpdateSuccessMessage()
      }

      const updatedData = [payload, ...state.data.filter(o => o.id !== payload.id)]
      return { ...state, isLoading: false, isSubmitting: false, data: updatedData }
    }
  },
  initialState
)
