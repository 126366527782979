import styled from 'styled-components'
import darken from 'polished/lib/color/darken'
import th from 'utils/themeHelper'
import constants from 'config/constants'

// Making this a big enough number for the border to be round
// If there's a better way to do this, feel free to change
const BORDER_RADIUS = '50px'

export const Strong = styled.p`
  display: inline;
  font-family: ${th('fonts.bold')};
  font-size: ${props => (props.small ? '12px' : '15px')};
  margin-bottom: 0;
`

export const CommitmentSearchNote = styled.div`
  bottom: -15px;
  color: ${th('text.dark')};
  font-size: 10px;
  left: 10px;
  position: absolute;
`

export const CommitmentGoalSubStat = styled.div`
  background: ${props => (props.active ? th('secondary.lightest') : 'white')};
  border-color: ${props => (props.active ? th('borders.dark') : th('borders.default'))};
  border-radius: 12px;
  border-style: solid;
  border-width: ${props => (props.isMoneyType ? '1px' : '0')};
  bottom: -5px;
  cursor: default;
  display: ${props => (props.isMoneyType ? 'block' : 'none')};
  font-size: ${props => {
    const moneyNoConfirmed = props.isMoneyType && props.totalConfirmedAmount === 0
    if (moneyNoConfirmed) return '15px'
    if (props.isMoneyType) return '12px'

    return '15px'
  }};
  left: 15px;
  line-height: 1em;
  min-width: max-content;
  max-width: max-content;
  padding: ${props => {
    const moneyNoConfirmed = props.isMoneyType && props.totalConfirmedAmount === 0
    if (moneyNoConfirmed) return '2px 10px'
    if (props.isMoneyType) return '2px 10px'

    return '0'
  }};
  position: relative;
  text-align: center;
`

export const ProgressBar = styled.div`
  border-color: ${th('borders.dark')};
  border-style: solid;
  border-width: ${props => (props.percentage ? '0' : '2px')};
  border-radius: ${BORDER_RADIUS};
  background: ${props => (props.noBorder ? 'white' : props.percentage ? '#BCBCBC' : 'white')};
  height: ${props => {
    if (props.searchPageFormat) return '24px'
    if (props.fullWidth) return '40px'
    if (props.percentage) return '24px'

    return '40px'
  }};

  margin-right: 0;
  max-width: 100%;
  position: relative;
`

export const ProgressBarGoalLabel = styled.div`
  display: ${props => (props.percentage ? 'block' : 'none')};
  bottom: ${props => (props.fullWidth ? '2px' : '15.5px')};
  color: ${props =>
    props.fullWidth
      ? th('text.dark')
      : props.percentage && props.percentage > 80
      ? 'white'
      : th('text.dark')};
  display: ${props => (props.displayGoalSubStat ? 'block' : 'none')};
  font-family: ${th('fonts.regular')};
  font-size: ${props => (props.fullWidth ? '15px' : '12px')};
  line-height: 1;
  margin-top: 0;
  position: ${props => (props.fullWidth ? 'absolute' : 'relative')};
  right: ${props => (props.fullWidth ? '15px' : '10px')};
  text-align: right;
  z-index: 5;

  @media screen and (min-width: 600px) {
    bottom: ${props => (props.searchPageFormat ? '5px' : props.fullWidth ? '2px' : '15.5px')};
    position: ${props => (props.fullWidth || props.searchPageFormat ? 'absolute' : 'relative')};
  }
`

export const ProgressBarWrapper = styled.div`
  flex: ${props => (props.fullWidth ? '1' : 'inherit')};
  margin: ${props => (props.fullWidth ? '0' : props.noMargin ? '0' : '0 20px 20px')};
  position: relative;

  @media screen and (max-width: 600px) {
    margin: ${props => (props.noMargin ? '0' : '0 20px 20px')};
  }
`

const donationGoalTypes = [
  constants.OPPORTUNITY_GOAL_TYPES.GOODS,
  constants.OPPORTUNITY_GOAL_TYPES.MONEY
]

export const ProgressIndicator = styled.div`
  align-items: center;
  background: ${props => {
    // default
    if (!props.barType) return 'white'

    // Has any commitments
    const hasCommitments = props.barType === 'committed' && props.percentage
    if (hasCommitments) {
      const useLightBackground = donationGoalTypes.includes(props.goalType)
      return useLightBackground ? '#379B7566' : darken(0.1, '#379B75')
    }

    // committed with no progress
    if (props.barType === 'committed') return 'white'

    // must have confirmed progress
    return darken(0.1, '#379B75')
  }};
  border-radius: ${BORDER_RADIUS};
  color: ${props => (props.percentage ? 'white' : th('text.dark'))};
  cursor: default;
  display: flex;
  font-family: ${props => {
    if (props.searchPageFormat) return th('fonts.regular')
    if (props.percentage) return th('fonts.regular')

    return th('fonts.bold')
  }};
  font-size: ${props => {
    if (!props.searchPageFormat) return '15px'

    // must be search page with progress
    if (props.percentage) return '15px'

    // search page without progress has smaller font
    return '12px'
  }};
  height: 100%;
  justify-content: center;
  line-height: ${props => {
    if (props.searchPageFormat && !props.percentage) return '1'
    return '1.25em'
  }};
  margin-left: ${props => (props.offset ? `${props.offset}%` : '0%')};
  padding-top: ${props => {
    if (props.searchPageFormat && props.percentage) return '1.5px'
    if (props.searchPageFormat) return '1px'
    if (props.fullWidth) return '3px'
    if (props.percentage) return '1.5px'

    return '0'
  }};
  position: absolute;
  text-align: center;
  width: ${({ percentage }) => percentage || 100}%;
`
