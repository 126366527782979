import styled from 'styled-components'
import { FacebookF } from '@styled-icons/fa-brands/FacebookF'
import { Twitter } from '@styled-icons/fa-brands/Twitter'
import { Instagram } from '@styled-icons/fa-brands/Instagram'
import { Whatsapp } from '@styled-icons/fa-brands/Whatsapp'
import { Youtube } from '@styled-icons/fa-brands/Youtube'

export const FacebookIcon = styled(FacebookF)`
  color: #fff;
`

export const WhatsAppIcon = styled(Whatsapp)`
  color: white;
`

export const YoutubeIcon = styled(Youtube)`
  color: white;
`

export const TwitterIcon = styled(Twitter)`
  color: #fff;
`

export const InstagramIcon = styled(Instagram)`
  color: #fff;
`

export const Outer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: ${props => props.justify || 'right'};
`

export const IconHolder = styled.a`
  background: ${props =>
    props.icon === 'facebook'
      ? '#3C5B9B'
      : props.icon === 'twitter'
      ? '#2DAAE1'
      : props.icon === 'youtube'
      ? '#ff0000'
      : props.icon === 'whatsapp'
      ? '#25d366'
      : props.icon === 'copy'
      ? '#FFF'
      : '#517FA4'};
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 1;
  margin-left: 10px;
  text-decoration: none;
  width: 30px;
  height: 30px;
  border-radius: 50%;

  &:first-child {
    margin-left: 0;
  }

  &:hover {
    border: 1px solid #ffffff70;
    cursor: pointer;
  }
`
