import { createActions, handleActions } from 'redux-actions'

export const SEARCH_OPPS = 'search/SEARCH_OPPS'
export const SEARCH_OPPS_SUCCESS = 'search/SEARCH_OPPS_SUCCESS'
export const SEARCH_OPPS_FAILURE = 'search/SEARCH_OPPS_FAILURE'
export const SEARCH_ORGS = 'search/SEARCH_ORGS'
export const SEARCH_ORGS_SUCCESS = 'search/SEARCH_ORGS_SUCCESS'
export const SEARCH_ORGS_FAILURE = 'search/SEARCH_ORGS_FAILURE'

export const {
  search: {
    searchOpps,
    searchOppsSuccess,
    searchOppsFailure,
    searchOrgs,
    searchOrgsSuccess,
    searchOrgsFailure
  }
} = createActions({
  [SEARCH_OPPS]: undefined,
  [SEARCH_OPPS_SUCCESS]: undefined,
  [SEARCH_OPPS_FAILURE]: undefined,
  [SEARCH_ORGS]: undefined,
  [SEARCH_ORGS_SUCCESS]: undefined,
  [SEARCH_ORGS_FAILURE]: undefined
})

const initialOppsPaginatedData = {
  oppsCount: 0,
  oppsData: [],
  oppsPageCount: 0
}

const initialOrgsPaginatedData = {
  orgsCount: 0,
  orgsData: [],
  orgsPageCount: 0
}

const initialState = {
  errorMessage: null,
  hasError: false,
  isLoading: false,
  isSubmitting: false,
  isSuccess: null,
  oppsData: [],
  orgsData: [],
  orgTypes: [],
  paginatedOppsData: initialOppsPaginatedData,
  paginatedOrgsData: initialOrgsPaginatedData
}

export default handleActions(
  {
    [SEARCH_OPPS]: state => ({ ...state, isLoading: true, isSuccess: null }),
    [SEARCH_OPPS_SUCCESS]: (state, { payload }) => ({
      ...state,
      isSuccess: true,
      isLoading: false,
      errorMessage: null,
      oppsData: payload.opps,
      paginatedOppsData: {
        oppsPageCount: payload.pageCount,
        oppsCount: payload.oppsCount,
        oppsData: payload.opps
      }
    }),
    [SEARCH_OPPS_FAILURE]: (state, payload) => ({
      ...state,
      ...payload,
      isSuccess: false,
      isLoading: false,
      hasError: true,
      oppsData: [],
      paginatedOppsData: {
        oppsPageCount: 0,
        oppsCount: 0,
        oppsData: []
      }
    }),
    [SEARCH_ORGS]: state => ({ ...state, isLoading: true, isSuccess: null }),
    [SEARCH_ORGS_SUCCESS]: (state, { payload }) => ({
      ...state,
      isSuccess: true,
      isLoading: false,
      errorMessage: null,
      orgsData: payload.orgs,
      orgTypes: payload.orgTypes,
      paginatedOrgsData: {
        orgsPageCount: payload.pageCount,
        orgsCount: payload.orgsCount,
        orgsData: payload.orgs
      }
    }),
    [SEARCH_ORGS_FAILURE]: (state, payload) => ({
      ...state,
      ...payload,
      isSuccess: false,
      isLoading: false,
      hasError: true,
      orgsData: [],
      paginatedOrgsData: {
        orgsPageCount: 0,
        orgsCount: 0,
        orgsData: []
      }
    })
  },
  initialState
)
