import { createStore, applyMiddleware } from 'redux'
import logger from 'redux-logger'
import createSagaMiddleware from 'redux-saga'
import { composeWithDevTools } from 'redux-devtools-extension'
import reducers from './modules'
import allSagas from './sagas'

const sagaMiddleware = createSagaMiddleware()
/* eslint-disable no-undef */
const applyableMiddleware =
  process.env.NODE_ENV === 'production' ? [sagaMiddleware] : [sagaMiddleware, logger]
/* eslint-enable no-undef */

const middleware = applyMiddleware(...applyableMiddleware)
const store = createStore(reducers, composeWithDevTools(middleware))

sagaMiddleware.run(allSagas)

export default store
