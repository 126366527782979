import styled from 'styled-components'

// TODO(Mike): this entire component seems a bit janky. Relook at use cases and clean up
export const Base = styled.div`
  display: ${props => (props.Flex ? 'flex' : 'block')};

  ${({ Flex }) => (Flex ? '& > * { flex-grow: 1; } ' : '')}
  ${({ Flex }) => (Flex ? 'flex-flow: row wrap;' : '')}
  ${({ Flex, fs }) =>
    Flex && fs ? 'justify-content: flex-start;' : Flex ? 'justify-content: space-between;' : ''}
  ${({ Flex }) => (Flex ? '& > * { margin: 0 24px; }' : '')}
  ${({ Flex }) => (Flex ? '& > div:first-child { margin-left: 0; }' : '')}
  ${({ Flex }) => (Flex ? '& > *:last-child { margin-right: 0; }' : '')}

  & + & {
    margin-top: 30px;
    margin-bottom: 35px;
  }

  @media screen and (max-width: 600px) {
    ${({ Flex }) => (Flex ? '& > * { margin: 0 0 11px 0 }' : '')}
  }
`
