import React from 'react'
import styled from 'styled-components'
import { Link } from '@reach/router'
import th from 'utils/themeHelper'
import SmallButtonPrimary from '../SmallButtonPrimary/SmallButtonPrimary'
import LinkButton from '../SmallButtonPrimary/LinkButton'

import { Supporters } from '../Icons/Icons'

import { ReactComponent as OppIcon } from 'assets/images/svgs/icon-campaign-causes-total.svg'
import { ReactComponent as Calendar } from 'assets/images/icons/opp-calendar.svg'
import { ReactComponent as Heart } from 'assets/images/icons/opp-heart.svg'

import InfoField from '../shared/InfoField'
import l, { c } from 'config/localization'

const Card = styled.div`
  background: white;
  padding: 15px;
  border-radius: 3px;
  border: 1px solid ${th('borders.default')};

  @media screen and (min-width: 600px) {
    padding: 30px;
  }
`

const Heading = styled.h4`
  margin: 0;
`

const Actions = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  @media screen and (max-width: 600px) {
    justify-content: space-between;
  }
`

const ActionLink = styled(Link)`
  text-decoration: none;
  margin-left: 15px;

  &:first-child {
    margin-left: 0;
  }

  @media screen and (max-width: 769px) {
    margin-left: 0;
    margin-bottom: 12px;
  }
`

const Info = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 30px 0;

  @media screen and (max-width: 769px) {
    flex-direction: column;
    margin: 15px 0;
  }
`

const InfoGroup = styled.div`
  min-width: 30%;
`

const LaunchWarning = styled.div`
  line-height: 1.5em;
  margin: 30px 0;
`

const CampaignCard = ({
  organisation,
  startDate,
  endDate,
  opportunityCount,
  opportunities = [],
  supporterCount,
  launchComp,
  idForCampaignLaunch,
  campLaunching,
  toggleLaunchConfirmation,
  campaign = {}
}) => {
  const { id, title } = campaign
  const isPrivateCamp = campaign.is_private_campaign
  const pathToCamp = `/${l('ROUTE_CAMPS')}/${id}?title=${campaign.short_url}`
  const shareableUrl = isPrivateCamp
    ? `${pathToCamp}&pvtShareKey=${campaign.pvt_share_key}`
    : pathToCamp

  if (idForCampaignLaunch && id === idForCampaignLaunch) {
    return (
      <Card>
        <Heading>{title}</Heading>
        <label>{organisation}</label>

        <LaunchWarning>
          Please note that when this {l('CAMP')} is launched, all relevant schools will be notified,
          and no further subject {l('CAMPS')} may be added to this provincial {l('CAMP')}. Please
          confirm that you want to proceed.
        </LaunchWarning>

        <Actions>
          <SmallButtonPrimary onClick={() => launchComp({ campaignId: id })}>
            Yes, launch competition
          </SmallButtonPrimary>

          <div style={{ marginLeft: '15px' }}>
            <LinkButton onClick={() => toggleLaunchConfirmation({ idForCampaignLaunch: null })}>
              Cancel
            </LinkButton>
          </div>
        </Actions>
      </Card>
    )
  }

  return (
    <Card>
      <Heading>{title}</Heading>
      <label>{organisation}</label>

      <Info>
        <InfoGroup>
          {startDate && endDate && (
            <InfoField icon={Calendar}>
              {startDate} - {endDate}
            </InfoField>
          )}

          <InfoField icon={OppIcon}>
            {opportunityCount} {`${opportunityCount === 1 ? l('OPP') : l('OPPS')}`}
          </InfoField>

          <InfoField icon={Heart}>{opportunities.join(', ')}</InfoField>
        </InfoGroup>

        <InfoGroup>
          <InfoField icon={Supporters}>
            {supporterCount} {l('SUPPORTERS')}
          </InfoField>
        </InfoGroup>
      </Info>

      {campLaunching ? (
        <Actions>Busy launching {l('CAMPS')}</Actions>
      ) : (
        <Actions>
          <ActionLink to={shareableUrl}>
            <LinkButton>View {c(l('CAMP'))}</LinkButton>
          </ActionLink>

          <ActionLink to={`/dashboard/${l('ROUTE_CAMPS')}/${id}`}>
            <LinkButton>Edit {c(l('CAMP'))}</LinkButton>
          </ActionLink>
        </Actions>
      )}
    </Card>
  )
}

export default CampaignCard
