import axios from 'axios'

// config
import baseUrl from '../config'

export const fetchPlatformEvents = async params => {
  const response = await axios.get(`${baseUrl}/platform-events`, { withCredentials: true, params })

  return response.data
}
