import styled from 'styled-components'
import th from '../../utils/themeHelper'

const Paragraph = styled.p`
  color: ${th('text.dark')};
  font-size: 15px;
  line-height: 20px;
  font-family: ${props =>
    props.bold ? th('fonts.bold') : th('fonts.regular')};
`

export default Paragraph
