import React from 'react'
import styled from 'styled-components'
import th from 'utils/themeHelper'
import { ArrowRight } from 'components/Icons/Icons'

const getPadding = ({ version }) => {
  if (version === 'one') return '5% 10% 5% 0'
  if (version === 'two') return '5% 0% 5% 10%'
  if (version === 'five') return '5% 0%'
  if (version === 'six') return '5% 0%'

  return '0'
}

const getAlignment = ({ version }) => {
  if (version === 'one') return 'center'
  if (version === 'two') return 'flex-start'
  if (version === 'three') return 'flex-start'

  return 'center'
}

const getFlexDirection = ({ column }) => {
  return column ? 'column' : 'row'
}

export const ImageContainer = ({ src, noPad }) => {
  if (noPad) {
    return (
      <ImageWrapper>
        <UnpaddedImage src={src} />
      </ImageWrapper>
    )
  }

  return (
    <ImageWrapper>
      <Image src={src} />
    </ImageWrapper>
  )
}

const Image = styled.img`
  background: ${props => props.src};
  max-height: 400px;
  padding: 5% 0 5% 5%;
`

const ImageWrapper = styled.div`
  display: flex;
  flex: 1;
  justify-content: ${props => getAlignment(props)};
`

export const MarketingContentSection = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: ${props => getPadding(props)};
`

export const CenterHeader = styled.h3`
  align-items: center;
  display: flex;
  flex: 1;
  font-family: ${th('fonts.bold')};
  font-size: 30px;
  line-height: 1.25em;
  margin: 0 auto;
  padding-bottom: 10px;
`

export const ComponentContainer = styled.div`
  background: white;
  display: flex;
  flex-direction: ${props => getFlexDirection(props)};
  padding: 35px 20px;
  width: 100%;

  @media screen and (min-width: 769px) {
    padding: 50px 20px;
    margin: auto;
    max-width: 1300px;
  }
`

export const FeatureItem = styled.div`
  margin-bottom: 2em;
`

export const FeatureItemFlex = styled.div`
  display: flex;
  margin-bottom: 2em;
`

export const FeatureItemFlexWrappedThird = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  flex-basis: calc(33% - 40px);
  margin: 0 20px 30px;

  @media screen and (min-width: 769px) {
    margin: 0 20px 60px;
  }
`

export const FeatureItemFlexWrappedQuarter = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  flex-basis: calc(25% - 40px);
  margin: 0 20px 30px;

  @media screen and (min-width: 769px) {
    margin: 0 20px 60px;
  }
`

export const FeatureItemFlexWrapped = styled.div`
  align-items: center;
  display: flex;
  flex-basis: calc(50% - 40px);
  margin: 0 20px 1em;
`

export const FeatureItemDetails = styled.div``

export const FeatureItems = styled.div`
  align-self: center;
  display: flex;
  flex-direction: column;
`

export const FeatureItemsWrapped = styled.div`
  align-self: center;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  width: 100%;

  @media screen and (min-width: 769px) {
    flex-direction: row;
  }
`

export const FeatureParagraph = styled.div`
  align-items: center;
  display: flex;
  flex: 1;
  font-size: 18px;
  line-height: 1.25em;
  margin: 0;
  padding-bottom: 10px;
`

export const FeatureParagraphWrapped = styled.div`
  align-items: center;
  display: flex;
  flex: 1;
  font-size: 15px;
  line-height: 1.25em;
  margin: 0 0 0 10px;
`

export const FeatureHeader = styled.h4`
  align-items: center;
  flex: 1;
  font-family: ${th('fonts.bold')};
  font-size: 18px;
  line-height: 1.25em;
  margin: 0;
  padding-bottom: 10px;
  text-align: ${props => (props.center ? 'center' : 'left')};
`

export const Header = styled.h3`
  align-items: center;
  display: flex;
  flex: 1;
  font-family: ${th('fonts.bold')};
  font-size: 30px;
  line-height: 1.25em;
  margin: -20px 0;
  padding-left: 20px;
`

export const HeartWrapper = styled.div`
  background: ${th('secondary.dark')};
  border-radius: 50%;
  color: white;
  padding: 4px;
`

export const HorizontalParagraph = styled.div`
  flex: 1;
  font-size: 18px;
  line-height: 1.5em;
  margin: 0 auto 50px;
  padding: 0;
  text-align: center;

  @media screen and (min-width: 769px) {
    width: 70%;
  }
`

export const HorizontalSection = styled.div`
  display: flex;
`

export const Paragraph = styled.div`
  flex: 1;
  font-size: 18px;
  line-height: 1.5em;
  padding-left: 20px;
`

const StepArrowWrapper = styled.div`
  align-self: center;
`

export const StepArrow = () => {
  return (
    <StepArrowWrapper>
      <ArrowRight size={30} />
    </StepArrowWrapper>
  )
}

export const StepBlock = styled.div`
  align-self: center;
  background: white;
  border: 1px solid ${th('borders.default')};
  border-radius: 8px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px -3px;
  display: flex;
  flex-direction: column;
  margin: 0 20px;
  padding: 25px;
  text-align: center;
`

export const StepBlocks = styled.div`
  align-self: center;
  display: flex;
`

export const StepDetails = styled.div`
  align-items: center;
  display: flex;
  flex: 1;
  font-size: 15px;
  line-height: 1.25em;
  margin: 0;
  padding-bottom: 10px;
`

export const StepHeader = styled.h4`
  align-items: center;
  display: block;
  font-family: ${th('fonts.bold')};
  font-size: 18px;
  line-height: 1.25em;
  margin: 0;
  padding-bottom: 20px;
  text-align: center;
`

const TheIcon = styled.div`
  align-self: center;
  background: #212121;
  background-image: url(${({ src }) => src});
  color: ${th('primary.base')};
  display: flex;
  height: 30px;
  margin-bottom: 20px;
  position: relative;
  width: 30px;
`

const UnpaddedImage = styled.img`
  background: ${props => props.src};
  max-height: 400px;
  padding: 0;
`

export const StepIcon = ({ icon }) => {
  return <TheIcon src={icon} />
}

const TheFeatureIcon = styled.div`
  align-self: center;
  background-image: url(${({ src }) => src});
  background-size: cover;
  display: flex;
  height: ${props => props.iconHeight || '80px'};
  margin-bottom: ${props => props.marginBottom || '10px'};
  position: relative;
  width: ${props => props.iconWidth || '80px'};
`

export const FeatureIcon = ({ icon, iconHeight, iconWidth, marginBottom }) => {
  return (
    <TheFeatureIcon
      src={icon}
      iconHeight={iconHeight}
      iconWidth={iconWidth}
      marginBottom={marginBottom}
    />
  )
}
