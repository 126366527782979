import { createActions, handleActions } from 'redux-actions'
import UrlBuilder from 'utils/urlBuilder'

export const LOGOUT_SUCCESS = 'authentication/LOGOUT_SUCCESS'

export const CREATE_STORY = 'stories/CREATE_STORY'
export const CREATE_STORY_SUCCESS = 'stories/CREATE_STORY_SUCCESS'
export const CREATE_STORY_FAILURE = 'stories/CREATE_STORY_FAILURE'

export const DELETE_STORY = 'stories/DELETE_STORY'
export const DELETE_STORY_SUCCESS = 'stories/DELETE_STORY_SUCCESS'
export const DELETE_STORY_FAILURE = 'stories/DELETE_STORY_FAILURE'

export const FETCH_STORIES = 'stories/FETCH_STORIES'
export const FETCH_ALL_STORIES = 'stories/FETCH_ALL_STORIES'
export const FETCH_STORIES_SUCCESS = 'stories/FETCH_STORIES_SUCCESS'
export const FETCH_STORIES_FAILURE = 'stories/FETCH_STORIES_FAILURE'

export const FETCH_STORY = 'stories/FETCH_STORY'
export const FETCH_STORY_SUCCESS = 'stories/FETCH_STORY_SUCCESS'
export const FETCH_STORY_FAILURE = 'stories/FETCH_STORY_FAILURE'

export const FETCH_STORY_LIKES = 'stories/FETCH_STORY_LIKES'
export const FETCH_STORY_LIKES_SUCCESS = 'stories/FETCH_STORY_LIKES_SUCCESS'
export const FETCH_STORY_LIKES_FAILURE = 'stories/FETCH_STORY_LIKES_FAILURE'

export const UPDATE_STORY = 'stories/UPDATE_STORY'
export const UPDATE_STORY_SUCCESS = 'stories/UPDATE_STORY_SUCCESS'
export const UPDATE_STORY_FAILURE = 'stories/UPDATE_STORY_FAILURE'

export const HANDLE_STORY_LIKE = 'stories/HANDLE_STORY_LIKE'
export const HANDLE_STORY_LIKE_SUCCESS = 'stories/HANDLE_STORY_LIKE_SUCCESS'

export const {
  stories: {
    createStory,
    createStorySuccess,
    createStoryFailure,
    updateStory,
    updateStorySuccess,
    updateStoryFailure,
    fetchStory,
    fetchStorySuccess,
    fetchStoryFailure,
    fetchStoryLikes,
    fetchStoryLikesSuccess,
    fetchStoryLikesFailure,
    deleteStory,
    deleteStorySuccess,
    deleteStoryFailure,
    fetchStories,
    fetchAllStories,
    fetchStoriesSuccess,
    fetchStoriesFailure,
    handleStoryLike,
    handleStoryLikeSuccess
  }
} = createActions({
  [CREATE_STORY]: undefined,
  [CREATE_STORY_SUCCESS]: undefined,
  [CREATE_STORY_FAILURE]: undefined,
  [UPDATE_STORY]: undefined,
  [UPDATE_STORY_SUCCESS]: undefined,
  [UPDATE_STORY_FAILURE]: undefined,
  [DELETE_STORY]: undefined,
  [DELETE_STORY_SUCCESS]: undefined,
  [DELETE_STORY_FAILURE]: undefined,
  [FETCH_STORIES]: undefined,
  [FETCH_ALL_STORIES]: undefined,
  [FETCH_STORIES_SUCCESS]: undefined,
  [FETCH_STORIES_FAILURE]: undefined,
  [FETCH_STORY]: undefined,
  [FETCH_STORY_SUCCESS]: undefined,
  [FETCH_STORY_FAILURE]: undefined,
  [FETCH_STORY_LIKES]: undefined,
  [FETCH_STORY_LIKES_SUCCESS]: undefined,
  [FETCH_STORY_LIKES_FAILURE]: undefined,
  [HANDLE_STORY_LIKE]: undefined,
  [HANDLE_STORY_LIKE_SUCCESS]: undefined
})

const initialPaginatedStoriesData = {
  pageCount: 0,
  storiesCount: 0,
  stories: []
}

const initialPaginatedStoryLikesData = {
  storyLikesPageCount: 0,
  storyLikesCount: 0,
  storyLikes: []
}

const initialState = {
  data: [],
  submissionWasSuccess: null,
  isLoading: false,
  hasError: false,
  singleStoryData: {},
  paginatedStoryLikesData: initialPaginatedStoryLikesData,
  paginatedStoriesData: initialPaginatedStoriesData,
  isSubmitting: false,
  isSuccess: false
}

export default handleActions(
  {
    [FETCH_STORY]: state => ({
      ...state,
      isLoading: false,
      isSubmitting: true
    }),
    [FETCH_STORY_SUCCESS]: (state, { payload }) => {
      return {
        ...state,
        singleStoryData: payload,
        storyId: payload.id,
        isLoading: false,
        isSubmitting: false,
        hasError: false
      }
    },
    [FETCH_STORY_FAILURE]: (state, { payload }) => ({
      ...state,
      ...payload,
      data: payload.data,
      isLoading: false,
      isSubmitting: false,
      hasError: true
    }),
    [FETCH_ALL_STORIES]: state => ({ ...state, isLoading: true }),
    [FETCH_STORIES]: state => ({ ...state, isLoading: true }),
    [FETCH_STORIES_SUCCESS]: (state, { payload }) => {
      const newState = {
        ...state,
        isLoading: false,
        hasSubmitted: false,
        isSuccess: true,
        data: payload.stories,
        errorMessage: null,
        hasError: false
      }

      newState.paginatedStoriesData.storiesPageCount =
        payload.storiesPageCount || payload.pageCount || 0
      newState.paginatedStoriesData.storiesCount = payload.storiesCount || 0
      newState.paginatedStoriesData.stories = payload.stories || []

      return newState
    },
    [FETCH_STORIES_FAILURE]: state => ({
      ...state,
      isLoading: false,
      hasError: true,
      isSuccess: false
    }),

    [FETCH_STORY_LIKES]: state => ({ ...state, isLoading: true }),
    [FETCH_STORY_LIKES_SUCCESS]: (state, { payload }) => {
      const newState = {
        ...state,
        isLoading: false,
        hasSubmitted: false,
        isSuccess: true,
        errorMessage: null,
        hasError: false
      }

      newState.paginatedStoryLikesData.storyLikesPageCount =
        payload.storyLikesPageCount || payload.pageCount || 0
      newState.paginatedStoryLikesData.storyLikesCount = payload.storyLikesCount || 0
      newState.paginatedStoryLikesData.storyLikes = payload.storyLikes || []

      return newState
    },
    [FETCH_STORY_LIKES_FAILURE]: state => ({
      ...state,
      isLoading: false,
      hasError: true,
      isSuccess: false
    }),

    [CREATE_STORY]: state => ({
      ...state,
      isLoading: false,
      isSubmitting: true,
      submissionWasSuccess: null
    }),
    [CREATE_STORY_SUCCESS]: (state, { payload }) => {
      UrlBuilder.displayUpdateSuccessMessage()

      return {
        ...state,
        singleStoryData: payload.data,
        isLoading: false,
        isSubmitting: false,
        submissionWasSuccess: true,
        hasError: false
      }
    },
    [CREATE_STORY_FAILURE]: (state, { payload }) => ({
      ...state,
      ...payload,
      data: payload.data,
      isLoading: false,
      isSubmitting: false,
      submissionWasSuccess: false,
      hasError: true
    }),
    [UPDATE_STORY]: (state, { payload }) => {
      return { ...state, singleStoryData: payload, isLoading: false, isSubmitting: true }
    },
    [UPDATE_STORY_SUCCESS]: (state, { payload }) => {
      if (payload.showSuccessDialog) {
        UrlBuilder.displayUpdateSuccessMessage()
      }

      const data = state.data.map(story => {
        if (story.id !== payload.id) return story
        return { ...story, isHiddenStory: true }
      })

      return { ...state, data, isLoading: false, singleStoryData: {}, isSubmitting: false }
    },
    [UPDATE_STORY_FAILURE]: state => ({
      ...state,
      singleStoryData: {},
      isLoading: false,
      isSubmitting: false
    }),
    [DELETE_STORY]: state => ({ ...state, isLoading: true, hasSubmitted: true }),
    [DELETE_STORY_SUCCESS]: (state, { payload }) => {
      return {
        ...state,
        isLoading: false,
        hasSubmitted: true,
        data: state.data.filter(o => o.id !== payload.storyId),
        storyId: null
      }
    },
    [DELETE_STORY_FAILURE]: () => ({ isLoading: false, hasSubmitted: true, storyId: null }),
    [HANDLE_STORY_LIKE]: state => ({ ...state }),
    [HANDLE_STORY_LIKE_SUCCESS]: (state, { payload }) => {
      const data = state.data.map(story => {
        if (story.id !== payload.id) return story

        const oneMore = (story.story_likes || 0) + 1
        const oneLess = (story.story_likes || 1) - 1
        const userLikesStory = payload.value === 'liked'
        const newLikesCount = userLikesStory ? oneMore : oneLess

        return { ...story, story_likes: newLikesCount, user_likes_story: userLikesStory }
      })

      const singleStory = state.singleStoryData || {}
      const oneMore = (singleStory.story_likes || 0) + 1
      const oneLess = (singleStory.story_likes || 1) - 1
      const userLikesStory = payload.value === 'liked'
      const newLikesCount = userLikesStory ? oneMore : oneLess
      const singleStoryData = {
        ...singleStory,
        story_likes: newLikesCount,
        user_likes_story: userLikesStory
      }

      return {
        ...state,
        data,
        isLoading: false,
        isSubmitting: false,
        singleStoryData
      }
    },
    [LOGOUT_SUCCESS]: () => initialState
  },
  initialState
)
