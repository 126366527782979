import styled from 'styled-components'
import th from 'utils/themeHelper'

export const Wrapper = styled.div`
  align-items: center;
  appearance: none;
  background: ${({ disabled, theme }) => (disabled ? theme.backgrounds.light : 'white')};
  border: 1px solid
    ${({ hasError, theme }) => (hasError ? theme.borders.error : theme.borders.default)};
  border-radius: 3px;
  color: ${th('text.dark')};
  cursor: ${({ disabled, noDate }) => (disabled ? 'not-allowed' : noDate ? 'pointer' : 'text')};
  display: flex;
  flex-direction: column;
  font-size: 15px;
  justify-content: ${props => (props.noDate ? 'center' : 'space-between')};
  min-height: 45px;
  outline: none;
  width: 100%;

  @media screen and (min-width: 1024px) {
    width: 500px;
  }

  &:hover {
    border: 1px solid
      ${({ hasError, theme }) => (hasError ? theme.borders.error : theme.borders.dark)};
  }

  &:focus {
    border: 1px solid
      ${({ hasError, theme }) => (hasError ? theme.borders.error : theme.secondary.base)};
  }

  & input {
    appearance: none;
    background: white;
    border: none;
    border-radius: 3px;
    color: ${th('text.dark')};
    font-family: ${th('fonts.regular')};
    font-size: 15px;
    height: 43px;
    outline: none;
    padding: 15px;
    width: 100%;

    @media screen and (max-width: 769px) {
      text-align: center;
    }

    &:hover,
    &:focus {
      border: none;
    }

    &::placeholder {
      color: ${th('text.light')};
    }
  }

  .Time__value-container {
    justify-content: center;
  }

  .Time__placeholder {
    margin: 0px;
  }
`

export const DateTimeRow = styled.div`
  align-items: center;
  border-bottom-color: ${th('borders.default')};
  border-bottom-style: solid;
  border-bottom-width: ${props => (props.borderBottom ? '1px' : '0')};
  display: flex;
  flex: 1;
  width: 100%;

  @media screen and (max-width: 769px) {
    flex-direction: column;
  }
`

export const DateTimeRowLabel = styled.div`
  font-family: ${th('fonts.bold')};
  padding: 0 10px;
  min-width: 90px;

  @media screen and (max-width: 769px) {
    padding: 10px 0 0;
    text-align: center;
  }
`

export const DateTimeRowItem = styled.div`
  flex: ${props => (props.noFlex ? 'none' : 1)};
`

export const EndDayPickerWrapper = styled.div`
  .DayPickerInput-Overlay {
    left: auto;
    right: 0;
  }
`

export const PlusIconWrapper = styled.span`
  display: inline-block;
  margin-right: 5px;
  width: 10px;

  svg {
    margin-top: -2px;
  }
`
