import React from 'react'
import { Link } from '@reach/router'

// utils
import webPImage from 'utils/webPImage'
import thumbSource from 'utils/thumbSource'
import stringUtils from 'utils/string'

// assets
import orgIcon from 'assets/images/icon-group.svg'
import VerifiedIcon from 'assets/images/icons/verified-org.svg'

// Load components synchronously
import { CheckCircle, XCircle } from 'components/Icons/Icons'
import ConditionalDisplay from 'components/ConditionalDisplay/ConditionalDisplay'
import ProgressBar from 'components/ProgressBar'
import SingleCard from 'components/SingleCard/SingleCard'

// config
import l from 'config/localization'
import constants from 'config/constants'

// styles
import {
  AdditionalOpportunityDetails,
  AssetWrapper,
  BeneficiaryName,
  BeneficiaryPill,
  CardContent,
  CardOverlay,
  CardLinkWrapper,
  ExpiryNotice,
  IconWrapper,
  Logo,
  OpportunityDescription,
  Title,
  UserJoinedIcon,
  UserJoinedLabel,
  VerificationWrapper,
  VerificationStatus,
  VerificationIcon
} from './styles'

const deriveCategory = ({ categories, categoryIds }) => {
  if (!categories) return null
  if (!categories.length) return null

  const categoriesisArray = Array.isArray(categories)
  const hasCategoriesAndCategoryIds = categoryIds.length

  if (hasCategoriesAndCategoryIds && categoriesisArray) {
    const cat = categories.find(c => categoryIds.includes(c.id))
    return cat ? cat.name : null
  }

  if (categories) return categories

  return null
}

class PublicOpportunityCard extends React.Component {
  deriveBeneficiaryName() {
    const { Beneficiary } = this.props
    const name = Beneficiary ? Beneficiary.name || '' : ''

    if (name.length <= 50) return name
    return `${Beneficiary.name.slice(0, 50)}...`
  }

  displayBeneficiaryVerification({ Beneficiary }) {
    if (!Beneficiary) return null
    if (!Beneficiary.is_verified) return null

    return (
      <VerificationWrapper>
        <VerificationIcon src={VerifiedIcon} />
        <VerificationStatus>Verified nonprofit</VerificationStatus>
      </VerificationWrapper>
    )
  }

  displayOrgInfoForOppCard({ isCampaignCard, Organisation }) {
    if (!isCampaignCard || !Organisation) return null

    return (
      <BeneficiaryPill>
        <BeneficiaryName>{Organisation.name}</BeneficiaryName>
        <AssetWrapper>
          <Logo src={Organisation.logo_image || orgIcon} size={24} />
        </AssetWrapper>
      </BeneficiaryPill>
    )
  }

  displayBeneficiaryInfo({ Beneficiary }) {
    if (!Beneficiary) {
      return (
        <BeneficiaryPill personal>
          <BeneficiaryName personal>Personal</BeneficiaryName>
        </BeneficiaryPill>
      )
    }

    const beneficiaryLogo = Beneficiary ? Beneficiary.logo_image : null

    return (
      <BeneficiaryPill>
        <BeneficiaryName>{this.deriveBeneficiaryName()}</BeneficiaryName>
        <AssetWrapper>
          <Logo src={beneficiaryLogo || orgIcon} size={24} />
        </AssetWrapper>
      </BeneficiaryPill>
    )
  }

  displayUserJoinedInfo({ userApplications }) {
    if (!userApplications.length) return null

    const hasAnyActiveOrCompleted = userApplications.some(app => {
      return [
        constants.APPLICATION_STATUS_NAMES.APPLIED,
        constants.APPLICATION_STATUS_NAMES.NOTIFIED,
        constants.APPLICATION_STATUS_NAMES.CHECKIN_SENT,
        constants.APPLICATION_STATUS_NAMES.PROVIDED_FEEDBACK,
        constants.APPLICATION_STATUS_NAMES.CONFIRMED,
        constants.APPLICATION_STATUS_NAMES.PENDING_DONATION,
        constants.APPLICATION_STATUS_NAMES.PENDING_DONATION_APPROVAL
      ].includes(app.status)
    })

    if (hasAnyActiveOrCompleted) {
      return (
        <UserJoinedIcon>
          <CheckCircle />
          <UserJoinedLabel>Joined</UserJoinedLabel>
        </UserJoinedIcon>
      )
    }

    const didWithdrawCompletely = userApplications.some(app => {
      return [
        constants.APPLICATION_STATUS_NAMES.MARKED_AS_NON_ATTENDED,
        constants.APPLICATION_STATUS_NAMES.WITHDREW
      ].includes(app.status)
    })

    if (didWithdrawCompletely) {
      return (
        <UserJoinedIcon withdrew>
          <XCircle withdrew />
          <UserJoinedLabel>Withdrew</UserJoinedLabel>
        </UserJoinedIcon>
      )
    }

    return null
  }

  render() {
    const {
      id,
      title,
      description,
      location,
      is_expired: isExpired,
      image_url: imgSrc,
      short_url: shortUrl,
      goal_type: goalType,
      committedPerc,
      confirmedPerc,
      start_date: startDate,
      end_date: endDate,
      pvt_share_key: pvtShareKey,
      outcome_target: outcomeTarget,
      doesSupportWebP,
      Application = [],
      Beneficiary,
      Organisation,
      isCampaignCard = false,
      categories = [],
      categoryIds = [],
      challengeFrequency,
      userData = {}
    } = this.props

    const imageUrl = doesSupportWebP ? webPImage(imgSrc) : imgSrc
    const thumbnail = thumbSource(imageUrl) || orgIcon
    const { splicedString } = stringUtils.trimText({ string: description, wordLimit: 40 })
    const oppUrl = pvtShareKey
      ? `/${l('ROUTE_OPPS')}/${id}?title=${shortUrl}&pvtShareKey=${pvtShareKey}`
      : `/${l('ROUTE_OPPS')}/${id}?title=${shortUrl}`

    const categoryName = deriveCategory({ categories, categoryIds })

    const userDataId = parseInt(`${userData.id}`)

    // get all applications for current user, could be multiple if goods or money types
    const userApplications = Application.filter(a => a.user_id === userDataId)

    const isChallengeOpp = goalType === constants.OPPORTUNITY_GOAL_TYPES.CHALLENGE
    const frequency = challengeFrequency ? challengeFrequency.frequency : null

    return (
      <Link to={oppUrl}>
        <SingleCard fullHeight hoverShadow>
          <ExpiryNotice expired={isExpired}>
            <CardOverlay src={thumbnail}>
              {this.displayOrgInfoForOppCard({ isCampaignCard, Organisation })}
              {this.displayBeneficiaryVerification({ Beneficiary })}
              {this.displayBeneficiaryInfo({ Beneficiary })}
              {this.displayUserJoinedInfo({ userApplications })}
            </CardOverlay>
          </ExpiryNotice>

          <CardLinkWrapper expired={isExpired}>
            <CardContent>
              <Title>{title}</Title>
              <OpportunityDescription description={splicedString} />

              <AdditionalOpportunityDetails goalType={goalType} />

              <ConditionalDisplay displayWhen={[!isChallengeOpp]}>
                <IconWrapper infoType={'Date'} value={startDate} endDate={endDate} />
              </ConditionalDisplay>

              <ConditionalDisplay displayWhen={[isChallengeOpp]}>
                <IconWrapper infoType={'Frequency'} value={frequency} />
              </ConditionalDisplay>

              <ConditionalDisplay displayWhen={[!isCampaignCard]}>
                <IconWrapper infoType={'Location'} value={location} />
              </ConditionalDisplay>

              <ConditionalDisplay displayWhen={[categoryName]}>
                <IconWrapper infoType={'Categories'} value={categoryName} />
              </ConditionalDisplay>
            </CardContent>

            <ProgressBar
              committedPerc={committedPerc}
              confirmedPerc={confirmedPerc}
              goalType={goalType}
              outcomeTarget={outcomeTarget}
              searchPageFormat
            />
          </CardLinkWrapper>
        </SingleCard>
      </Link>
    )
  }
}

export default PublicOpportunityCard
