import React from 'react'
import {
  CheckboxInput,
  Wrapper,
  OptionsWrapper,
  SubLabel,
  Title,
  RadioOptionText,
  RadioButtonLabel,
  RadioOption,
  ActionLink,
  ExternalLink
} from './styles'
import { Error, RequiredTag } from '../InputLabels/InputLabels'
import constants from '../../config/constants'

const getAnchor = opt => {
  if (opt.id === constants.DONATION_LINK_TYPES.OZOW) {
    return '#ozow'
  }

  if (opt.id === constants.DONATION_LINK_TYPES.EXTERNAL) {
    return '#donations'
  }

  return null
}

const CheckBoxGroup = ({ field, onChange, options, requiredField, subLabel, title, ...props }) => {
  const error = props.form.errors[field.name]
  const displayableLabel = requiredField ? `${title} *` : title

  return (
    <Wrapper>
      {error && <Error>{error}</Error>}

      <Title>{displayableLabel}</Title>

      {subLabel && <SubLabel>{subLabel}</SubLabel>}

      <OptionsWrapper>
        {options.map(opt => {
          const { disabled, newTab, showFallbackText, text, id, order, label } = opt

          return (
            <RadioOption key={`radio_opt_${id || order}`}>
              <RadioButtonLabel disabled={disabled}>
                <CheckboxInput
                  {...field}
                  {...props}
                  disabled={disabled}
                  onChange={() => onChange(opt.value)}
                />
                {label}
              </RadioButtonLabel>

              <RequiredTag hasError={error} label={label} requiredField={requiredField} />

              {showFallbackText && (
                <RadioOptionText disabled={disabled}>
                  {text}

                  {showFallbackText && ' - '}
                  {showFallbackText && !newTab && (
                    <ActionLink to={`/dashboard/account${getAnchor(opt)}`}>
                      {'click here to set it up'}
                    </ActionLink>
                  )}
                  {showFallbackText && newTab && (
                    <ExternalLink href={`/dashboard/account${getAnchor(opt)}`} target="_blank">
                      {'click here to set it up'}
                    </ExternalLink>
                  )}
                </RadioOptionText>
              )}
            </RadioOption>
          )
        })}
      </OptionsWrapper>
    </Wrapper>
  )
}

export default CheckBoxGroup
