import React from 'react'
import styled from 'styled-components'
import { Link } from '@reach/router'

// assets
import DefaultLogo from 'assets/images/icon-group.svg'
import DonationIcon from 'assets/images/cause-type-icons/money_icon_dark.svg'

// icons
import { CheckCircleFill } from '@styled-icons/bootstrap/CheckCircleFill'
import { Settings } from 'styled-icons/ionicons-outline'

// utils
import stringUtils from 'utils/string'
import th from 'utils/themeHelper'

// styles
import { getPillColors } from 'components/SearchResultsContainer/styles'
import DashCardWrapper from './DashCardWrapper'

const ActionItem = styled.div`
  margin-right: 10px;

  &:last-of-type {
    margin-right: 0;
  }
`

const Actions = styled.div`
  align-items: center;
  display: flex;
  flex: 1;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin: 10px;
`

const ActionsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`

const EditStoryCardLink = styled(Link)`
  border-color: ${th('backgrounds.mid')};
  border-radius: 4px;
  border-style: solid;
  border-width: 1px;
  color: ${th('text.dark')};
  margin: 0;
  padding: 8px 12px;
  text-align: center;
  text-decoration: none;
  white-space: nowrap;

  & > svg {
    width: 15px;
    margin-right: 10px;
  }
`

const Heading = styled.h4`
  display: inline-block;
  flex: 1;
  margin: 0;
  text-decoration: underline;
  vertical-align: middle;
`

const Info = styled.div`
  display: block;
  margin: 10px 15px 0;
  flex: 1;
`

const InfoFieldLabel = styled.div`
  font-family: ${th('fonts.bold')};
  margin-right: 10px;
`

const InfoFieldValue = styled.div`
  font-family: ${th('fonts.regular')};
  margin-left: 30px;
`

const InfoFieldIcon = styled.div`
  margin-right: 10px;

  & > svg {
    height: 20px;
  }
`

const InfoFieldImage = styled.img`
  height: 20px;
`

const InfoGroup = styled.div`
  min-width: 30%;
`

const InfoGroupRow = styled.div`
  color: ${th('text.mid')};
  line-height: 1.25em;
  margin-bottom: 15px;
`

const InfoGroupRowInner = styled.div`
  display: flex;
  word-break: break-word;
`

const Logo = styled.div`
  background-image: url(${({ src }) => src});
  background-size: cover;
  background-position: center center;
  border: 1px solid ${th('borders.darkPill')};
  border-radius: 50%;
  height: 50px;
  margin-right: 15px;
  width: 50px;
`

const OrgMainDetailsWrapper = styled.div`
  align-items: center;
  display: flex;
  flex: 1;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin: 10px 15px 15px;
`

const Pill = styled.div`
  background: ${props => props.bgColor || th('backgrounds.default')};
  color: ${props => props.fontColor || th('text.dark')};
  border-radius: 3px;
  display: inline-block;
  font-size: 9px;
  margin-top: 2px;
  padding: 2px 5px;
  text-align: center;
  text-transform: uppercase;
  width: ${props => (props.fullWidth ? '100%' : 'auto')};
`

const DashMembershipCard = ({ amount, avatar, link, name, subscription_status }) => {
  const { bgColor, color } = getPillColors({
    key: 'subscription_status',
    value: subscription_status
  })

  return (
    <DashCardWrapper>
      <ActionsWrapper>
        <OrgMainDetailsWrapper>
          <Logo src={stringUtils.isBlank(avatar) ? DefaultLogo : avatar} />
          <Heading>{name}</Heading>
        </OrgMainDetailsWrapper>

        <Info>
          <InfoGroup>
            <InfoGroupRow>
              <InfoGroupRowInner>
                <InfoFieldIcon>
                  <InfoFieldImage src={DonationIcon} />
                </InfoFieldIcon>
                <InfoFieldLabel>Donation amount</InfoFieldLabel>
              </InfoGroupRowInner>

              <InfoGroupRowInner>
                <InfoFieldValue>{amount} / month</InfoFieldValue>
              </InfoGroupRowInner>
            </InfoGroupRow>

            <InfoGroupRow>
              <InfoGroupRow>
                <InfoGroupRowInner>
                  <InfoFieldIcon>
                    <CheckCircleFill />
                  </InfoFieldIcon>
                  <InfoFieldLabel>Membership status</InfoFieldLabel>
                </InfoGroupRowInner>

                <InfoGroupRowInner>
                  <InfoFieldValue>
                    <Pill bgColor={bgColor} fontColor={color}>
                      {subscription_status}
                    </Pill>
                  </InfoFieldValue>
                </InfoGroupRowInner>
              </InfoGroupRow>
            </InfoGroupRow>
          </InfoGroup>
        </Info>

        <Actions>
          <ActionItem>
            <EditStoryCardLink to={link}>
              <Settings />
              <span>Manage membership</span>
            </EditStoryCardLink>
          </ActionItem>
        </Actions>
      </ActionsWrapper>
    </DashCardWrapper>
  )
}

export default DashMembershipCard
