import React, { Component } from 'react'
import styled from 'styled-components'

// components
import SearchFilter from '../SearchFilter/SearchFilter'
import StaticSelect from '../Select/StaticSelect'

// utils
import spacing from 'utils/spacing'

const SelectContainer = styled.div`
  margin-top: 10px;

  @media screen and (min-width: 769px) {
    margin-left: 10px;
    margin-top: 0;
  }
`

const Outer = styled.div`
  background: white;
  border-radius: 3px;

  margin-bottom: ${spacing.default}px;
  padding: ${spacing.default}px;
  width: 70%;

  @media screen and (min-width: 769px) {
    display: flex;
  }

  @media screen and (max-width: 1108px) {
    width: 100%;
    padding: ${spacing.small}px;
  }
`

class CampaignPageOpportunitiesFilter extends Component {
  render() {
    const { categoryOpts, categoryValue, onChange, placeholder } = this.props

    const chosenOpt = categoryValue ? categoryOpts.find(c => c.value === categoryValue) : null

    return (
      <Outer>
        <SearchFilter
          flex={1}
          onChange={e => onChange({ text: e.target.value })}
          placeholder={placeholder}
        />

        <SelectContainer>
          <StaticSelect
            marginBottom="0"
            onChange={selection => onChange({ categoryId: selection.value })}
            options={categoryOpts}
            placeholder={`Filter by category`}
            value={chosenOpt}
          />
        </SelectContainer>
      </Outer>
    )
  }
}

export default CampaignPageOpportunitiesFilter
