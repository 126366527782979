const isDev = process.env.NODE_ENV === 'development'

import React, { Fragment } from 'react'
import { useMedia } from 'react-use'

// assets
import { Settings } from 'styled-icons/ionicons-outline'

// styles
import {
  getPillColors,
  BorderWrapper,
  CardWrapper,
  DashHomeStatsTable,
  DashHomeStatsTableRow,
  DashStatTableHeading,
  DashStatTableValue,
  DashStatTableValueInner,
  EditIconWrapper,
  LoadingMessageWrapper,
  NoResultsButtonWrapper,
  NoResultsMessageHeader,
  NoResultsImage,
  NoResultsImageWrapper,
  NoResultsMessage,
  NoResultsMessageWrapper,
  ResultsWrapper,
  TableCellButtonWrapper,
  ExternalLinkButton
} from './styles'

// Load components synchronously
import ConditionalDisplay from 'components/ConditionalDisplay/ConditionalDisplay'
import DashboardOpportunityCard from 'components/OpportunityCard/DashboardOpportunityCard'
import DeleteButton from 'components/DeleteButton/DeleteButton'
import ErrorMessage from 'components/ErrorMessage'
import LinkButton from 'components/SmallButtonPrimary/LinkButton'
import DashChallengeCard from 'components/Dashboard/SearchResultsCards/DashChallengeCard'
import DashCampaignCard from 'components/Dashboard/SearchResultsCards/DashCampaignCard'
import DashContributionCard from 'components/Dashboard/SearchResultsCards/DashContributionCard'
import DashLikeCard from 'components/Dashboard/SearchResultsCards/DashLikeCard'
import DashMembershipCard from 'components/Dashboard/SearchResultsCards/DashMembershipCard'
import DashNotificationCard from 'components/Dashboard/SearchResultsCards/DashNotificationCard'
import DashOppCard from 'components/Dashboard/SearchResultsCards/DashOppCard'
import DashOrganisationCard from 'components/Dashboard/SearchResultsCards/DashOrganisationCard'
import DashPersonCard from 'components/Dashboard/SearchResultsCards/DashPersonCard'
import DashStoryCard from 'components/Dashboard/SearchResultsCards/DashStoryCard'
import DashTeamCard from 'components/Dashboard/SearchResultsCards/DashTeamCard'
import PboApplicationCard from 'components/PboApplicationCard/PboApplicationCard'
import OrganisationCard from 'components/OrganisationCard/OrganisationCard'
import PublicOpportunityCard from 'components/OpportunityCard/PublicOpportunityCard'
import Tooltip from 'components/Tooltip'

const RenderCard = ({ card, cardType, hasGodAdminRole, userData }) => {
  if (cardType === 'DashChallengeCard') {
    return <DashChallengeCard {...card} hasGodAdminRole={hasGodAdminRole} />
  }

  if (cardType === 'DashCampaignCard') {
    return <DashCampaignCard {...card} hasGodAdminRole={hasGodAdminRole} />
  }

  if (cardType === 'DashContributionCard') {
    return <DashContributionCard {...card} hasGodAdminRole={hasGodAdminRole} />
  }

  if (cardType === 'DashLikeCard') {
    return <DashLikeCard {...card} hasGodAdminRole={hasGodAdminRole} />
  }

  if (cardType === 'DashMembershipCard') {
    return <DashMembershipCard {...card} hasGodAdminRole={hasGodAdminRole} />
  }

  if (cardType === 'DashNotificationCard') {
    return <DashNotificationCard {...card} hasGodAdminRole={hasGodAdminRole} />
  }

  if (cardType === 'DashOppCard') {
    return <DashOppCard {...card} hasGodAdminRole={hasGodAdminRole} />
  }

  if (cardType === 'DashOrganisationCard') {
    return <DashOrganisationCard {...card} hasGodAdminRole={hasGodAdminRole} />
  }

  if (cardType === 'DashPersonCard') {
    return <DashPersonCard {...card} hasGodAdminRole={hasGodAdminRole} />
  }

  if (cardType === 'DashStoryCard') {
    return <DashStoryCard {...card} hasGodAdminRole={hasGodAdminRole} />
  }

  if (cardType === 'DashTeamCard') {
    return <DashTeamCard {...card} hasGodAdminRole={hasGodAdminRole} />
  }

  if (cardType === 'PublicOpportunityCard') {
    return <PublicOpportunityCard {...card} hasGodAdminRole={hasGodAdminRole} userData={userData} />
  }

  if (cardType === 'OrganisationCard') {
    return <OrganisationCard {...card} hasGodAdminRole={hasGodAdminRole} userData={userData} />
  }

  if (cardType === 'DashboardOpportunityCard') {
    return <DashboardOpportunityCard {...card} hasGodAdminRole={hasGodAdminRole} />
  }

  if (cardType === 'PboApplicationCard') {
    return <PboApplicationCard {...card} hasGodAdminRole={hasGodAdminRole} />
  }
}

const navTo = ({ action, row }) => {
  if (!action) return '#'
  if (!action.link) return '#'

  return action.link
    .replace('$TABLE_ACTION_ROW_OPP_ID', row.opportunity_id)
    .replace('$TABLE_ACTION_ROW_ID', row.id)
    .replace('$TABLE_ACTION_ROW_TARGET_URL', row.story_target_url || row.url_name || row.id)
}

const buildRowActions = ({ actions, row }) => {
  const rowActions = [...actions]

  if (row.rowActions) {
    row.rowActions.map(rowAction => {
      const alreadyIncluded = rowActions.map(r => r.name).includes(rowAction.name)
      if (alreadyIncluded) return true

      if (rowAction.position === 'before-actions') {
        rowActions.unshift(rowAction)
      } else {
        rowActions.push(rowAction)
      }
    })
  }

  return rowActions
}

const deriveRowValue = ({ c, row }) => {
  const fallbackNoIconKey = (c.key || '').replace('_icon', '')
  const value = row[c.key] || row[fallbackNoIconKey]

  return value
}

const deriveCellIcon = ({ c, row }) => {
  const iconColumns = [
    'donation_platform_icon',
    'goal_type_icon',
    'opp_category_icon',
    'challenge_category_icon',
    'story_category_icon',
    'story_type_icon',
    'org_verified_icon'
  ]
  const isIconAvailableCell = iconColumns.includes(c.key)
  if (!isIconAvailableCell) return null

  const iconKey = iconColumns.find(i => i === c.key)

  return row[iconKey]
}

const DisplayAction = ({ action, actionIcon: ActionIcon, isEditAction, navTo, row }) => {
  const isDeleteAction = action.buttonStyle === 'DeleteButton'
  const isNonDeleteAction = !isDeleteAction && !!action.action
  const actionIsLink = !isDeleteAction && !action.action

  if (isEditAction) {
    return (
      <LinkButton
        disabled={action.disabled}
        hasIcon
        overrideBg={action.overrideBg}
        overrideBgHover={action.overrideBgHover}
        overrideColor={action.overrideColor}
        overrideColorHover={action.overrideColorHover}
        showQuestion // padding hack
        to={navTo({ action, row })}
      >
        <EditIconWrapper>
          <Settings />
        </EditIconWrapper>
      </LinkButton>
    )
  }

  if (action.actionLink) {
    return (
      <ExternalLinkButton
        disabled={action.disabled}
        overrideBg={action.overrideBg}
        overrideBgHover={action.overrideBgHover}
        overrideColor={action.overrideColor}
        overrideColorHover={action.overrideColorHover}
        href={action.actionLink}
        target="_blank"
      >
        <EditIconWrapper>
          <ActionIcon />
        </EditIconWrapper>
      </ExternalLinkButton>
    )
  }

  // blank action
  if (!action.name) {
    return <div style={{ width: '41px' }} />
  }

  if (isDeleteAction) {
    return (
      <DeleteButton
        disabled={action.disabled}
        onClick={() => action.action({ entityId: action.entityId, recordId: row.id })}
        type="button"
      >
        {action.name}
      </DeleteButton>
    )
  }

  if (actionIsLink && ActionIcon) {
    return (
      <LinkButton
        disabled={action.disabled}
        hasIcon
        showQuestion // padding hack
        to={navTo({ action, row })}
        type="button"
      >
        <EditIconWrapper>
          <ActionIcon />
        </EditIconWrapper>
      </LinkButton>
    )
  }

  if (actionIsLink) {
    return (
      <LinkButton
        disabled={action.disabled}
        overrideBg={action.overrideBg}
        overrideBgHover={action.overrideBgHover}
        overrideColor={action.overrideColor}
        overrideColorHover={action.overrideColorHover}
        to={navTo({ action, row })}
      >
        {action.name}
      </LinkButton>
    )
  }

  if (isNonDeleteAction && ActionIcon) {
    return (
      <LinkButton
        disabled={action.disabled}
        hasIcon
        showQuestion // padding hack
        onClick={() => action.action({ action, row })}
        overrideBg={action.overrideBg}
        overrideBgHover={action.overrideBgHover}
        overrideColor={action.overrideColor}
        overrideColorHover={action.overrideColorHover}
        type="button"
      >
        <EditIconWrapper>
          <ActionIcon />
        </EditIconWrapper>
      </LinkButton>
    )
  }

  if (isNonDeleteAction) {
    return (
      <LinkButton
        disabled={action.disabled}
        onClick={() => action.action({ action, row })}
        overrideBg={action.overrideBg}
        overrideBgHover={action.overrideBgHover}
        overrideColor={action.overrideColor}
        overrideColorHover={action.overrideColorHover}
        type="button"
      >
        {action.name}
      </LinkButton>
    )
  }
}

const DashStatTableValueCell = ({ action, actionIdx, isEditAction, navTo, row }) => {
  if (!action.tooltip) {
    return (
      <TableCellButtonWrapper
        display={action.display}
        key={`row_${row.id}_action_col_${actionIdx}`}
      >
        <DisplayAction
          actionIcon={action.icon}
          action={action}
          isEditAction={isEditAction}
          navTo={navTo}
          row={row}
        />
      </TableCellButtonWrapper>
    )
  }

  return (
    <Tooltip position="left" content={action.tooltip} forceShow minWidth>
      <TableCellButtonWrapper
        display={action.display}
        key={`row_${row.id}_action_col_${actionIdx}`}
      >
        <DisplayAction
          actionIcon={action.icon}
          action={action}
          isEditAction={isEditAction}
          navTo={navTo}
          row={row}
        />
      </TableCellButtonWrapper>
    </Tooltip>
  )
}

const TableContainer = ({
  actions,
  hasGodAdminRole,
  hasSearchTerm,
  isLoading,
  noResultsButtonLabel,
  noResultsButtonLink,
  noResultsImage,
  noResultsImageWidth,
  noResultsHeader,
  noResultsText,
  searchResults,
  tableColumns
}) => {
  const rowsHaveActions = searchResults.some(r => r.rowActions && r.rowActions.length)
  const hasAnyActions = actions.length || rowsHaveActions

  if (isLoading && !hasSearchTerm) {
    return (
      <LoadingMessageWrapper>
        <NoResultsMessageHeader>Fetching data</NoResultsMessageHeader>

        <NoResultsMessage>Please wait for your results to load</NoResultsMessage>
      </LoadingMessageWrapper>
    )
  }

  if (!searchResults.length && noResultsText) {
    return (
      <NoResultsMessageWrapper>
        <NoResultsMessageHeader>{noResultsHeader}</NoResultsMessageHeader>

        {noResultsImage && (
          <NoResultsImageWrapper>
            <NoResultsImage src={noResultsImage} width={noResultsImageWidth} />
          </NoResultsImageWrapper>
        )}

        <NoResultsMessage>{noResultsText}</NoResultsMessage>

        <ConditionalDisplay displayWhen={[noResultsButtonLabel, noResultsButtonLink]}>
          <NoResultsButtonWrapper>
            <LinkButton to={noResultsButtonLink}>{noResultsButtonLabel}</LinkButton>
          </NoResultsButtonWrapper>
        </ConditionalDisplay>
      </NoResultsMessageWrapper>
    )
  }

  return (
    <Fragment>
      <BorderWrapper>
        <DashHomeStatsTable>
          <DashHomeStatsTableRow isSticky>
            {tableColumns.map((col, colIdx) => (
              <DashStatTableHeading
                cellAlign={col.cellAlign}
                center={col.center}
                collapse={col.collapse}
                headerFontSize={col.headerFontSize}
                key={`${col.name}_column_${colIdx}`}
                minWidth={col.minWidth}
                padding={col.padding}
                primaryCell={col.primaryCell}
                size={col.size}
              >
                {col.name}
              </DashStatTableHeading>
            ))}

            <ConditionalDisplay displayWhen={[hasAnyActions]}>
              <DashStatTableHeading />
            </ConditionalDisplay>
          </DashHomeStatsTableRow>

          {searchResults.map((row, rowIndex) => {
            const rowActions = buildRowActions({ actions, row })

            return (
              <DashHomeStatsTableRow key={`${rowIndex}_school_row_${row.id}`}>
                {tableColumns.map(c => {
                  const { bgColor, color } = getPillColors({ key: c.key, value: row[c.key] })
                  const hoverLabelColumn = c['hoverLabel']
                  const hoverLabel = hoverLabelColumn ? row[hoverLabelColumn] : null

                  return (
                    <DashStatTableValue
                      cellAlign={c.cellAlign}
                      center={c.center}
                      collapse={c.collapse}
                      key={`school_row_${row.id}_col_${c.key}`}
                      minWidth={c.minWidth}
                      primaryCell={c.primaryCell}
                      size={c.size}
                      smallIcon={c.smallIcon}
                      value={deriveRowValue({ c, row })}
                    >
                      <DashStatTableValueInner
                        avatarStyles={row.avatarStyles}
                        bgColor={bgColor}
                        canEditCheckbox={row.canEditCheckbox}
                        color={color}
                        columnKey={c.key}
                        cursor={c.cursor}
                        fontBold={c.fontBold}
                        fullWidth={c.fullWidth}
                        hoverLabel={hoverLabel}
                        isLink={c.isLink}
                        icon={deriveCellIcon({ c, row })}
                        id={row.id}
                        image={c.isImageColumn ? row.avatar : null}
                        isCustomStatusPill={c.isCustomStatusPill}
                        isCheckbox={c.isCheckbox}
                        isMultiStatusPill={c.isMultiStatusPill}
                        isStatusPill={c.isStatusPill}
                        link={row.link || row[c.link_key]}
                        nullIcon={c.nullIcon}
                        nullPillText={c.nullPillText}
                        nullText={c.nullText}
                        nullTextAlign={c.nullTextAlign}
                        pillWidth={c.pillWidth}
                        showCheckbox={row.showCheckbox}
                        smallIcon={c.smallIcon}
                        toggleCheckbox={c.toggleCheckbox}
                        tooltipPosition={c.tooltipPosition}
                        uppercase={c.uppercase}
                        value={deriveRowValue({ c, row })}
                      />
                    </DashStatTableValue>
                  )
                })}

                <ConditionalDisplay displayWhen={[hasAnyActions]}>
                  <DashStatTableValue nowrap>
                    {rowActions.map((action, actionIdx) => {
                      const canEditRow = hasGodAdminRole || row.can_edit
                      const isEditAction = action.name === 'Edit'
                      const isPrivilidgedAction = action.privileged
                      const displayAction = isEditAction
                        ? canEditRow
                        : isPrivilidgedAction
                        ? canEditRow
                        : true

                      return (
                        <ConditionalDisplay
                          displayWhen={[displayAction]}
                          key={`con_disp_row_${row.id}_action_col_${actionIdx}`}
                        >
                          <DashStatTableValueCell
                            action={action}
                            actionIdx={actionIdx}
                            isEditAction={isEditAction}
                            navTo={navTo}
                            row={row}
                          />
                        </ConditionalDisplay>
                      )
                    })}
                  </DashStatTableValue>
                </ConditionalDisplay>
              </DashHomeStatsTableRow>
            )
          })}
        </DashHomeStatsTable>
      </BorderWrapper>
    </Fragment>
  )
}

const SearchResultsContainer = ({
  actions = [],
  cardType,
  dashboard,
  displayTable,
  hasGodAdminRole,
  hasSearchTerm,
  isLoading,
  noResultsButtonLabel,
  noResultsButtonLink,
  noResultsHeader,
  noResultsImage,
  noResultsImageWidth,
  noResultsText,
  searchResults = [],
  showFilters,
  tableColumns = [],
  userData
}) => {
  const isWide = useMedia('(min-width: 769px)')

  if (displayTable && isWide) {
    return (
      <TableContainer
        actions={actions}
        hasGodAdminRole={hasGodAdminRole}
        hasSearchTerm={hasSearchTerm}
        isLoading={isLoading}
        noResultsButtonLabel={noResultsButtonLabel}
        noResultsButtonLink={noResultsButtonLink}
        noResultsHeader={noResultsHeader}
        noResultsImage={noResultsImage}
        noResultsImageWidth={noResultsImageWidth}
        noResultsText={noResultsText}
        searchResults={searchResults}
        tableColumns={tableColumns}
      />
    )
  }

  if (!cardType) {
    return (
      <ErrorMessage
        text={
          isDev
            ? 'Please specify a cardType for this SearchResultsContainer component.'
            : 'Sorry! A mobile version of this page is being worked on.'
        }
      />
    )
  }

  const noResults = searchResults.length === 0

  return (
    <ResultsWrapper cardType={cardType} dashboard={dashboard} id="results" noResults={noResults}>
      {searchResults.map((card, cardIndex) => (
        <CardWrapper cardType={cardType} showFilters={showFilters} key={`${cardIndex}_${card.key}`}>
          <RenderCard
            cardType={cardType}
            card={card}
            hasGodAdminRole={hasGodAdminRole}
            userData={userData}
          />
        </CardWrapper>
      ))}

      <ConditionalDisplay displayWhen={[noResults, noResultsText]}>
        <NoResultsMessageWrapper sideMargin>
          <NoResultsMessageHeader>{noResultsHeader}</NoResultsMessageHeader>

          {noResultsImage && (
            <NoResultsImageWrapper>
              <NoResultsImage src={noResultsImage} />
            </NoResultsImageWrapper>
          )}

          <NoResultsMessage>{noResultsText}</NoResultsMessage>
        </NoResultsMessageWrapper>
      </ConditionalDisplay>
    </ResultsWrapper>
  )
}

export default SearchResultsContainer
