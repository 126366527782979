import React from 'react'
import styled from 'styled-components'

// utils
import th from 'utils/themeHelper'

const ButtonBase = styled.button`
  align-items: center;
  background: ${props =>
    props.disabled
      ? th('backgrounds.mid')
      : props.warning
      ? th('backgrounds.warning')
      : props.background
      ? props.background
      : props.secondary
      ? th('secondary.base')
      : th('primary.base')};
  border: none;
  border-radius: 3px;
  color: ${props => (props.disabled ? th('text.dark') : props.color || 'white')};
  cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};
  display: ${props => (props.hasIcon ? 'flex' : 'block')};
  font-family: ${th('fonts.bold')};
  font-size: 15px;
  justify-content: center;
  outline: none;
  padding-bottom: ${props => (props.hasIcon ? '10px' : th('spacing.small', 'px'))};
  padding-left: ${props =>
    props.hasIcon ? th('spacing.small', 'px') : th('spacing.default', 'px')};
  padding-right: ${th('spacing.default', 'px')};
  padding-top: ${props => (props.hasIcon ? '10px' : th('spacing.small', 'px'))};
  width: ${props => (props.wide ? '100%' : 'auto')};

  @media screen and (max-width: 769px) {
    max-width: ${props => (props.wide ? '100%' : 'max-content')};
  }

  &:hover {
    box-shadow: inset 0 -4px 0 0 rgba(0, 0, 0, 0.2);
  }

  &:focus {
    background: ${props =>
      props.disabled
        ? th('backgrounds.mid')
        : props.warning
        ? th('backgrounds.error')
        : th('secondary.dark')};
    box-shadow: none;
  }
`

const ButtonIconContainer = styled.i`
  line-height: 20px;
  margin-bottom: 1px;
  margin-top: -1px;
  margin-right: ${th('spacing.tiny', 'px')};

  & > svg {
    margin-top: ${props => props.iconMarginTop || '0px'};
    width: ${props => props.iconSize || '20px'};
    height: ${props => props.iconSize || '20px'};
  }
`

const ChildrenWrapper = styled.div`
  text-align: center;
`

const LinkButtonBase = styled.a`
  background: ${props =>
    props.disabled
      ? th('backgrounds.mid')
      : props.secondary
      ? th('primary.base')
      : props.warning
      ? th('backgrounds.warning')
      : th('secondary.base')};
  border: none;
  border-radius: 3px;
  color: ${props => (props.disabled ? th('text.dark') : 'white')};
  cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};
  display: flex;
  font-family: ${th('fonts.bold')};
  font-size: 15px;
  justify-content: center;
  outline: none;
  padding: ${th('spacing.small', 'px')} ${th('spacing.default', 'px')};
  width: ${props => (props.wide ? '100%' : 'auto')};

  &:hover {
    box-shadow: inset 0 -4px 0 0 rgba(0, 0, 0, 0.2);
  }

  &:focus {
    background: ${props =>
      props.disabled
        ? th('backgrounds.mid')
        : props.warning
        ? th('backgrounds.error')
        : th('secondary.dark')};
    box-shadow: none;
  }
`

const LargeButton = ({ children, icon: Icon, ...props }) => {
  if (props.href) {
    return (
      <LinkButtonBase hasIcon={Icon} {...props}>
        {Icon && (
          <ButtonIconContainer {...props}>
            <Icon />
          </ButtonIconContainer>
        )}

        <ChildrenWrapper>{children}</ChildrenWrapper>
      </LinkButtonBase>
    )
  }

  return (
    <ButtonBase hasIcon={Icon} {...props}>
      {Icon && (
        <ButtonIconContainer {...props}>
          <Icon />
        </ButtonIconContainer>
      )}

      <ChildrenWrapper>{children}</ChildrenWrapper>
    </ButtonBase>
  )
}

export default LargeButton
