import { call, put, takeLatest } from 'redux-saga/effects'
import { navigate } from '@reach/router'
import qs from 'qs'

// redux
import * as authenticationModule from '../modules/authentication'
import * as authApi from '../apiModules/authentication'

// utils
import { setLocalStorageValues, clearLocalStorageValues } from '../../utils/localStorageManager'

// config
import l, { isDgmt, isSef, isSefEmployee } from 'config/localization'
import constants from '../../config/constants'

const derivePayload = payload => {
  const { password, urlParams, f_name, l_name, email, mobile_number, school_id } = payload
  const token = urlParams.token

  return { password, token, f_name, l_name, email, mobile_number, school_id }
}

function* sendResetLink({ payload }) {
  const { email, isSefAdminReminder } = payload

  if (isSefAdminReminder) {
    yield call(authApi.requestSefAdminInvite, { isSefAdminReminder, email, ...payload })
    yield put(authenticationModule.sendResetLinkSuccess())
    return true
  }

  const result = yield call(authApi.requestPasswordReset, { email })

  if (result.status === 200) {
    yield put(authenticationModule.sendResetLinkSuccess())
  } else {
    authenticationModule.sendResetLinkFailure()
  }
}

function* sendMagicLink({ payload }) {
  const { email } = payload
  const result = yield call(authApi.requestMagicLink, { email })

  if (result.status === 200) {
    yield put(authenticationModule.sendMagicLinkSuccess())
  } else {
    authenticationModule.sendMagicLinkFailure()
  }
}

function* requestConfirmationEmail({ payload }) {
  const { email } = payload
  const result = yield call(authApi.requestConfirmationEmail, { email })

  if (result.status === 200) {
    yield put(authenticationModule.requestConfirmationEmailSuccess())
  } else {
    authenticationModule.requestConfirmationEmailFailure()
  }
}

function* confirmEmail({ payload }) {
  const { token, action, orgId } = payload
  const response = yield call(authApi.confirmEmail, { token })
  const navigatePath = ['adminInvite', 'memberInvite'].includes(action)
    ? `/join?action=${action}&orgId=${orgId}`
    : action === 'joinedFromApplication'
    ? '/dashboard/account?emailConfirmed=true'
    : action === 'isCreatingOrg'
    ? '/dashboard/organisations/new?action=premiumPlan'
    : action === 'premiumPlan'
    ? '/pricing/nonprofits?billing=annual'
    : action === 'goodDeed'
    ? '/dashboard/stories/new?eventType=GoodDeed&chooseTemplate'
    : '/dashboard?emailConfirmed=true'

  if (response.data && response.data.justConfirmed) {
    yield setLocalStorageValues(response)
    yield put(authenticationModule.loginSuccess(response.data))
    return navigate(navigatePath)
  } else {
    yield put(authenticationModule.confirmationFailure())
  }
}

function* register({ payload }) {
  const { action } = payload
  const navigatePath = ['adminInvite', 'memberInvite'].includes(action)
    ? `/${l('ROUTE_ORGS')}/${payload.orgId}/manage-invite`
    : action === 'premiumPlan'
    ? '/dashboard/organisations/new?action=premiumPlan'
    : action === 'goodDeed'
    ? '/dashboard/stories/new?eventType=GoodDeed&chooseTemplate'
    : '/onboarding'
  const response = yield call(authApi.register, payload)

  if (response.data) {
    yield setLocalStorageValues(response)
    yield put(authenticationModule.loginSuccess(response.data))

    return navigate(navigatePath)
  } else {
    yield put(authenticationModule.loginFailure(response.response.data.errorMessage))
  }
}

function* login({ payload }) {
  const { email, password, redirectTo, urlParams } = payload
  let redirectableUrl = '/dashboard'

  // needs fixing but no time to test + afraid to break things
  const redirectToAddress =
    redirectTo || urlParams.redirectingToPvt
      ? urlParams.redirectTo.replace(/.+redirectTo=/, '') ||
        urlParams.redirectingToPvt.replace(/.+redirectTo=/, '')
      : null

  if (urlParams && urlParams.action === 'premiumPlan') {
    redirectableUrl = '/process-payment?bill=annual'
  } else if (urlParams && urlParams.redirectingToPvt) {
    redirectableUrl = redirectToAddress
  } else if (redirectToAddress) {
    const stringifiedParams = qs.stringify({ ...urlParams })
    redirectableUrl = stringifiedParams.replace(/redirectTo.+(%3F)?/, `${redirectToAddress}`)
  }

  if (email && password) {
    yield clearLocalStorageValues()
    const loginResponse = yield call(authApi.login, { email, password })

    if (loginResponse.data) {
      yield setLocalStorageValues(loginResponse)
      yield put(authenticationModule.loginSuccess(loginResponse.data))
      return navigate(redirectableUrl)
    } else {
      if (loginResponse.response.status === 401) {
        yield put(authenticationModule.loginFailure(loginResponse.message))
      } else {
        yield put(authenticationModule.loginFailure('Please check your connection'))
      }
    }
  } else {
    yield put(authenticationModule.loginFailure('Please fill out the form'))
  }
}

function* loginAsTimekeeper({ payload }) {
  const { id_number: idNumber, mobile_number: mobileNumber } = payload

  if (idNumber && mobileNumber) {
    yield clearLocalStorageValues()
    const loginResponse = yield call(authApi.loginAsTimekeeper, payload)

    if (loginResponse.data) {
      yield setLocalStorageValues(loginResponse)
      yield put(authenticationModule.loginAsTimekeeperSuccess(loginResponse.data))
      return navigate('/dashboard/participants')
    } else {
      if (loginResponse.response.status === 401) {
        yield put(authenticationModule.loginAsTimekeeperFailure(loginResponse.message))
      } else {
        yield put(authenticationModule.loginAsTimekeeperFailure('Please check your connection'))
      }
    }
  } else {
    yield put(authenticationModule.loginAsTimekeeperFailure('Please fill out the form'))
  }
}

function* generateApiKey() {
  try {
    const response = yield call(authApi.generateApiKey)
    yield put(authenticationModule.generateApiKeySuccess(response.data))
  } catch (e) {
    yield put(authenticationModule.generateApiKeyFailure())
  }
}

function* acceptHostAgreement() {
  try {
    const response = yield call(authApi.acceptHostAgreement)
    yield put(authenticationModule.acceptHostAgreementSuccess(response.data))
  } catch (e) {
    yield put(authenticationModule.acceptHostAgreementFailure())
  }
}

function* attemptAutoLogin({ payload }) {
  const { signInToken } = payload

  if (signInToken && signInToken.length > 0) {
    const loginResponse = yield call(authApi.magicLogin, { signInToken })
    if (loginResponse && loginResponse.data) {
      yield setLocalStorageValues(loginResponse)
      yield put(authenticationModule.loginSuccess(loginResponse.data))
      window.location.reload()
    }
  }
}

function* resetPassword({ payload }) {
  const { urlParams } = payload
  const permittedParams = derivePayload(payload)
  const resetResponse = yield call(authApi.resetPassword, { ...permittedParams })

  if (resetResponse.data) {
    const roleStatus = resetResponse.data.role_status
    const redirectToDashboard = [constants.ROLES.SUPERADMIN, constants.ROLES.ADMIN].includes(
      roleStatus
    )

    yield setLocalStorageValues(resetResponse)
    yield put(authenticationModule.loginSuccess(resetResponse.data))

    if (isDgmt) {
      return navigate('/dashboard')
    } else if (isSef || isSefEmployee) {
      return navigate('/dashboard')
    } else if (urlParams && urlParams.redirectToForm) {
      return navigate(`/dashboard/${l('ROUTE_OPPS')}/new?${qs.stringify(urlParams)}`)
    } else if (urlParams && (urlParams.principal || urlParams.mentor)) {
      return navigate('/dashboard')
    } else if (urlParams) {
      return navigate(`/dashboard/${l('ROUTE_OPPS')}`)
    } else if (redirectToDashboard) {
      return navigate('/dashboard')
    } else {
      return navigate('/')
    }
  } else {
    if (resetResponse.response.status === 401) {
      yield put(authenticationModule.resetPasswordFailure(resetResponse.response.data))
    } else {
      const authError = resetResponse.response.data || 'Please check your connection'
      yield put(authenticationModule.loginFailure(authError))
    }
  }
}

function* dismissOnboardingChallenge({ payload }) {
  try {
    const response = yield call(authApi.dismissOnboardingChallenge, payload)
    yield put(authenticationModule.dismissOnboardingChallengeSuccess(response))

    const latestResponse = yield call(authApi.fetchOnboardingChallenges)
    yield put(authenticationModule.fetchOnboardingChallengesSuccess(latestResponse))
  } catch (err) {
    yield put(authenticationModule.dismissOnboardingChallengeFailure(err))
  }
}

function* fetchOnboardingChallenges() {
  try {
    const response = yield call(authApi.fetchOnboardingChallenges)
    yield put(authenticationModule.fetchOnboardingChallengesSuccess(response))
  } catch (err) {
    yield put(authenticationModule.fetchOnboardingChallengesFailure(err))
  }
}

function* fetchMyRoles({ payload }) {
  try {
    const response = yield call(authApi.fetchMyRoles, payload)
    yield put(authenticationModule.fetchMyRolesSuccess(response))
  } catch (err) {
    yield put(authenticationModule.fetchMyRolesFailure(err))
  }
}

function* logout() {
  yield call(authApi.logout)
  yield clearLocalStorageValues()
  yield put(authenticationModule.logoutSuccess())
  yield navigate('/login')
}

export const authenticationSagas = [
  takeLatest(authenticationModule.REQUEST_CONFIRMATION_EMAIL, requestConfirmationEmail),
  takeLatest(authenticationModule.CONFIRM_EMAIL, confirmEmail),
  takeLatest(authenticationModule.RESET_PASSWORD, resetPassword),
  takeLatest(authenticationModule.SEND_MAGIC_LINK, sendMagicLink),
  takeLatest(authenticationModule.SEND_RESET_LINK, sendResetLink),
  takeLatest(authenticationModule.FETCH_MY_ROLES, fetchMyRoles),
  takeLatest(authenticationModule.DISMISS_ONBOARDING_CHALLENGE, dismissOnboardingChallenge),
  takeLatest(authenticationModule.FETCH_ONBOARDING_CHALLENGES, fetchOnboardingChallenges),
  takeLatest(authenticationModule.LOGIN, login),
  takeLatest(authenticationModule.LOGIN_AS_TIMEKEEPER, loginAsTimekeeper),
  takeLatest(authenticationModule.ATTEMPT_AUTO_LOGIN, attemptAutoLogin),
  takeLatest(authenticationModule.REGISTER, register),
  takeLatest(authenticationModule.GENERATE_API_KEY, generateApiKey),
  takeLatest(authenticationModule.ACCEPT_HOST_AGREEMENT, acceptHostAgreement),
  takeLatest(authenticationModule.LOGOUT, logout)
]
