import React from 'react'
import styled from 'styled-components'
import { Link } from '@reach/router'

// assets
import { Favorite, LinkIcon, Location, Supporters } from '../Icons/Icons'
import DefaultLogo from 'assets/images/icon-group.svg'
import VerifiedIcon from 'assets/images/icons/verified-org.svg'

// config
import constants from 'config/constants'
import l from 'config/localization'

// utils
import stringUtils from 'utils/string'
import th from 'utils/themeHelper'

// Load components synchronously
import ConditionalDisplay from 'components/ConditionalDisplay/ConditionalDisplay'
import InfoField from 'components/shared/InfoField'
import SingleCard from 'components/SingleCard/SingleCard'

const Heading = styled.h4`
  display: inline-block;
  margin: 0;
  vertical-align: middle;
`

const ActionsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`

const CardInnerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`

const SingleCardLinkWrapper = styled(Link)`
  color: ${th('text.dark')};
`

const Info = styled.div`
  align-items: flex-end;
  display: flex;
  flex: 1;
  margin: 10px 15px;
`

const InfoGroup = styled.div`
  min-width: 30%;
`

const InfoGroupRow = styled.div`
  color: ${th('text.mid')};
  line-height: 1.25em;
  margin-bottom: 15px;
  max-width: 350px;
  word-break: break-word;
`

const InactiveOrgLogo = styled.div`
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.65) 0%, rgba(0, 0, 0, 0.65) 100%),
    url(${({ src }) => src});
  background-position: center center;
  background-size: cover;
  height: 150px;
  width: 100%;
`

const Logo = styled.div`
  background-image: url(${({ src }) => src});
  background-size: cover;
  background-position: center center;
  border-radius: 4px 4px 0 0;
  width: 100%;
  height: 150px;
`

const OrgDescription = styled.div`
  display: -webkit-box;
  font-size: 12px;
  font-style: ${props => (props.italic ? 'italic' : 'normal')};
  height: 32.4px;
  line-height: 1.35;
  margin: 10px 15px;
  overflow: hidden;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
`

const HorizontalLayout = styled.div`
  display: block;
  line-height: 1.25em;
  width: 100%;
`

const TitleWrapper = styled.div`
  margin: 15px 15px 0 15px;
`

const VerificationWrapper = styled.div`
  display: inline-block;
  margin-right: 5px;
  vertical-align: middle;
`

const VerificationIcon = styled.img`
  display: inline-block;
  width: 25px;
  height: 25px;
  vertical-align: middle;
`

const InactiveOrg = styled.div`
  background: ${th('primary.dark')};
  color: white;
  right: 10px;
  top: 10px;
  padding: 8px 16px;
  position: absolute;
`

const OrganisationCard = ({
  name,
  description,
  url_name,
  url,
  logo_image,
  org_logo_image,
  status,
  org_type_name,
  location,
  is_verified,
  prefCategory
}) => {
  const visibleUrl = url && url.length > 50 ? `${url.slice(0, 40)}...` : url ? url : null
  const isInactive = status === constants.ORG_STATUSES.INACTIVE
  const preferredCategoryName = prefCategory ? prefCategory.name : null
  const shortOrgType = stringUtils.shortVersionOf(org_type_name)
  const logoImage = logo_image || org_logo_image

  return (
    <SingleCardLinkWrapper to={`/${l('ROUTE_ORGS')}/${url_name}`}>
      <SingleCard fullHeight hoverShadow>
        <ActionsWrapper>
          <ConditionalDisplay displayWhen={[!isInactive]}>
            <Logo src={stringUtils.isBlank(logoImage) ? DefaultLogo : logoImage} />
          </ConditionalDisplay>

          <ConditionalDisplay displayWhen={[isInactive]}>
            <InactiveOrgLogo src={stringUtils.isBlank(logoImage) ? DefaultLogo : logoImage} />
            <InactiveOrg>Inactive {l('ORG')}</InactiveOrg>
          </ConditionalDisplay>

          <CardInnerWrapper>
            <HorizontalLayout>
              <TitleWrapper>
                <ConditionalDisplay displayWhen={[is_verified]}>
                  <VerificationWrapper>
                    <VerificationIcon src={VerifiedIcon} />
                  </VerificationWrapper>
                </ConditionalDisplay>

                <Heading>{name}</Heading>
              </TitleWrapper>

              <ConditionalDisplay displayWhen={[description]}>
                <OrgDescription>{description}</OrgDescription>
              </ConditionalDisplay>

              <ConditionalDisplay displayWhen={[!description]}>
                <OrgDescription italic>No description available</OrgDescription>
              </ConditionalDisplay>
            </HorizontalLayout>

            <Info>
              <InfoGroup>
                <InfoGroupRow>
                  <InfoField icon={Supporters}>{shortOrgType}</InfoField>
                </InfoGroupRow>

                <ConditionalDisplay displayWhen={[prefCategory]}>
                  <InfoGroupRow>
                    <InfoField icon={Favorite}>{preferredCategoryName}</InfoField>
                  </InfoGroupRow>
                </ConditionalDisplay>

                <InfoGroupRow>
                  <InfoField icon={Location} isItalic={!location}>
                    {location || 'Location not specified'}
                  </InfoField>
                </InfoGroupRow>

                <ConditionalDisplay displayWhen={[visibleUrl]}>
                  <InfoField breakAnywhere icon={LinkIcon}>
                    {visibleUrl}
                  </InfoField>
                </ConditionalDisplay>
              </InfoGroup>
            </Info>
          </CardInnerWrapper>
        </ActionsWrapper>
      </SingleCard>
    </SingleCardLinkWrapper>
  )
}

export default OrganisationCard
