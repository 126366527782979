import { createActions, handleActions } from 'redux-actions'

export const FETCH_INSTAGRAM_BIO_LINKS = 'instagramBioLinks/FETCH_INSTAGRAM_BIO_LINKS'
export const FETCH_INSTAGRAM_BIO_LINKS_SUCCESS =
  'instagramBioLinks/FETCH_INSTAGRAM_BIO_LINKS_SUCCESS'
export const FETCH_INSTAGRAM_BIO_LINKS_FAILURE =
  'instagramBioLinks/FETCH_INSTAGRAM_BIO_LINKS_FAILURE'
export const CREATE_INSTAGRAM_BIO_LINK = 'instagramBioLinks/CREATE_INSTAGRAM_BIO_LINK'
export const CREATE_INSTAGRAM_BIO_LINK_SUCCESS =
  'instagramBioLinks/CREATE_INSTAGRAM_BIO_LINK_SUCCESS'
export const CREATE_INSTAGRAM_BIO_LINK_FAILURE =
  'instagramBioLinks/CREATE_INSTAGRAM_BIO_LINK_FAILURE'
export const DELETE_INSTAGRAM_BIO_LINK = 'instagramBioLinks/DELETE_INSTAGRAM_BIO_LINK'
export const DELETE_INSTAGRAM_BIO_LINK_SUCCESS =
  'instagramBioLinks/DELETE_INSTAGRAM_BIO_LINK_SUCCESS'
export const DELETE_INSTAGRAM_BIO_LINK_FAILURE =
  'instagramBioLinks/DELETE_INSTAGRAM_BIO_LINK_FAILURE'

export const {
  instagramBioLinks: {
    fetchInstagramBioLinks,
    fetchInstagramBioLinksSuccess,
    fetchInstagramBioLinksFailure,
    createInstagramBioLink,
    createInstagramBioLinkSuccess,
    createInstagramBioLinkFailure,
    deleteInstagramBioLink,
    deleteInstagramBioLinkSuccess,
    deleteInstagramBioLinkFailure
  }
} = createActions({
  [CREATE_INSTAGRAM_BIO_LINK]: undefined,
  [CREATE_INSTAGRAM_BIO_LINK_SUCCESS]: undefined,
  [CREATE_INSTAGRAM_BIO_LINK_FAILURE]: undefined,
  [FETCH_INSTAGRAM_BIO_LINKS_SUCCESS]: undefined,
  [FETCH_INSTAGRAM_BIO_LINKS_FAILURE]: undefined,
  [FETCH_INSTAGRAM_BIO_LINKS]: undefined,
  [DELETE_INSTAGRAM_BIO_LINK_SUCCESS]: undefined,
  [DELETE_INSTAGRAM_BIO_LINK_FAILURE]: undefined,
  [DELETE_INSTAGRAM_BIO_LINK]: undefined
})

const initialState = {
  hasError: null,
  isLoading: null,
  isSubmitting: null,
  isSuccess: null,
  data: []
}

export default handleActions(
  {
    [CREATE_INSTAGRAM_BIO_LINK]: (state, { payload }) => ({
      ...state,
      ...payload,
      isLoading: false,
      isSubmitting: true
    }),
    [CREATE_INSTAGRAM_BIO_LINK_SUCCESS]: (state, { payload }) => ({
      ...state,
      data: [...state.data, payload],
      isLoading: false,
      isSubmitting: false
    }),
    [CREATE_INSTAGRAM_BIO_LINK_FAILURE]: state => ({
      ...state,
      hasError: true,
      isLoading: false,
      isSubmitting: false
    }),
    [FETCH_INSTAGRAM_BIO_LINKS]: (state, { payload }) => ({
      ...state,
      ...payload,
      isLoading: false,
      isSubmitting: true
    }),
    [FETCH_INSTAGRAM_BIO_LINKS_SUCCESS]: (state, { payload }) => ({
      ...state,
      data: payload,
      isLoading: false,
      isSubmitting: false
    }),
    [FETCH_INSTAGRAM_BIO_LINKS_FAILURE]: state => ({
      ...state,
      hasError: true,
      isLoading: false,
      isSubmitting: false
    }),
    [DELETE_INSTAGRAM_BIO_LINK]: (state, { payload }) => ({
      ...state,
      ...payload,
      isLoading: false,
      isSubmitting: true
    }),
    [DELETE_INSTAGRAM_BIO_LINK_SUCCESS]: (state, { payload }) => ({
      ...state,
      data: state.data.filter(d => d.id !== payload),
      isLoading: false,
      isSubmitting: false
    }),
    [DELETE_INSTAGRAM_BIO_LINK_FAILURE]: state => ({
      ...state,
      hasError: true,
      isLoading: false,
      isSubmitting: false
    })
  },
  initialState
)
