import React from 'react'
import styled from 'styled-components'

// utils
import th from 'utils/themeHelper'
import spacing from 'utils/spacing'

// assets
import { Dot } from '@styled-icons/bootstrap'
import { Close } from '@styled-icons/evil/Close'

export const ButtonWrapper = styled.div`
  margin-right: 10px;
`

export const ButtonsWrapper = styled.div`
  display: flex;
`

export const FormDividerLine = styled.div`
  background: ${th('borders.default')};
  display: block;
  margin: 30px auto;
  padding: 1px 0;
  width: 70%;
`

export const FormSectionButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin: 30px auto 0;
  width: 100%;

  @media screen and (max-width: 769px) {
    width: 70%;
  }
`

export const LabelWrapper = styled.div`
  margin: 0 auto 5px;
  width: 100%;

  @media screen and (min-width: 769px) {
    width: 70%;
  }
`

export const FullWidthModalFieldRow = styled.div`
  display: block;
  margin-bottom: ${props => props.marginBottom || '20px'};
  margin-top: ${props => props.marginTop || '20px'};
`

export const FormSectionRow = styled.div`
  display: block;
  margin: 20px auto;
  position: relative;
  width: 100%;

  @media screen and (min-width: 769px) {
    width: 70%;
  }
`

export const Strong = styled.span`
  font-family: ${th('fonts.bold')};
`

export const PaymentFeesLink = styled.a`
  color: ${th('text.light')};
  font-size: 12px;
  line-height: 1.25em;
  text-decoration: underline;
`

export const DonationFeeDescription = styled.div`
  flex: 2;
  font-size: 12px;
  margin-left: 5px;
  margin-right: 10px;
`

export const DonationFeeName = styled.div`
  flex: 1;
  font-family: ${th('fonts.bold')};
  font-size: 12px;
`

export const DonationFeeRow = styled.div`
  border-bottom: 1px solid ${th('borders.default')};
  display: flex;
  padding: 10px;
`

export const DonationFeeTotal = styled.div`
  font-family: ${th('fonts.bold')};
  font-size: 12px;
`

export const DonationFeesTable = styled.div`
  border-left: 1px solid ${th('borders.default')};
  border-right: 1px solid ${th('borders.default')};
  border-top: 1px solid ${th('borders.default')};
`

export const DonationFeesWrapper = styled.div`
  margin-right: 10px;
`

export const ScreenWrapper = styled.div`
  background: rgba(0, 0, 0, 0.6);
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 5;
  padding: 0;
  margin: 0;
  filter: blur(0) !important;
  height: 100vh;
  overflow-y: auto;
`

export const TermsContainer = styled.div`
  background: white;
  height: 200px;
  overflow: scroll;
  padding: 15px;
  border-radius: 4px;
  border: 1px solid ${th('borders.default')};
  margin-bottom: 10px;
`

export const ModalContainer = styled.div`
  background: white;
  border: 1px solid ${th('borders.default')};
  padding: 35px 25px 50px;
  border-radius: 4px;
  margin: 80px auto 0;
  width: 55%;
  z-index: 999;
  position: relative;
  overflow-y: auto;
  height: auto;
  max-height: calc(100vh - 100px);

  @media screen and (max-width: 800px) {
    width: 90%;
  }
`

export const MenuButtonName = styled.h4`
  font-family: ${th('fonts.regular')};
  font-size: 15px;
  margin: 0 0 0 25px;

  @media screen and (min-width: 769px) {
    font-size: 18px;
    margin: 0 0 15px;
  }
`

export const MenuButtonHint = styled.div`
  color: ${props => (props.isRed ? th('text.error') : th('text.dark'))};
  display: none;
  font-size: 12px;
  line-height: 1.25em;

  @media screen and (min-width: 769px) {
    display: block;
    font-size: 15px;
  }
`

export const MenuButton = styled.div`
  background: white;
  border: 1px solid ${th('borders.default')};
  border-radius: 4px;
  margin: 0 8px 16px;
  padding: 10px;
  position: relative;
  z-index: 999;

  &:focus,
  &:hover {
    box-shadow: 0 0 25px rgba(255, 255, 255, 0.85);
    cursor: pointer;
  }

  @media screen and (min-width: 769px) {
    flex: 0 1 46%;
    margin: 0 15px 15px 0;
    max-width: 250px;
    padding: 15px;
    width: 250px;
  }
`

export const KeyboardTipContainer = styled.div`
  color: white;
  text-align: center;

  @media screen and (max-width: 769px) {
    padding-bottom: 20px;
  }
`

export const ModalRowHeader = styled.h3`
  color: white;
  font-family: ${th('fonts.bold')};
  font-size: 20px;
  margin: 10px 0 20px 8px;

  @media screen and (min-width: 769px) {
    margin: 15px 0;
  }
`

export const ModalRowWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
`

const DotWrapper = styled.div`
  position: absolute;

  @media screen and (max-width: 769px) {
    right: -10px;
    top: -5px;
  }

  @media screen and (min-width: 769px) {
    left: 98px;
    top: 5px;
  }

  svg {
    color: ${th('text.error')};
    width: 30px;
  }
`

export const DotIcon = ({ showNotificationDot }) => {
  if (!showNotificationDot) return null

  return (
    <DotWrapper>
      <Dot />
    </DotWrapper>
  )
}

export const FullScreenModal = styled.div`
  background: ${th('text.dark')};
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  height: calc(100vh - 20px);
  margin: 0;
  padding: 70px 10px;
  position: relative;
  top: 0;
  width: 100%;
  z-index: 9998;

  @media screen and (min-width: 769px) {
    padding: 50px;
  }
`

export const IconContainer = styled.i`
  color: ${props => props.color || th('text.dark')};
  display: block;
  left: 7px;
  opacity: 0.5;
  position: absolute;
  top: 7px;

  @media screen and (min-width: 769px) {
    left: unset;
    right: 5px;
  }

  & > svg {
    height: ${props => props.size || '40px'};
    margin-right: 0;
    margin-top: 0;
    width: ${props => props.size || '40px'};

    @media screen and (max-width: 769px) {
      height: 20px;
      width: 20px;
    }
  }

  @media screen and (max-width: 769px) {
    & > svg {
      margin-top: 0;
    }
  }
`

export const FullScreenModalButtonsContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  position: relative;

  @media screen and (min-width: 769px) {
    justify-content: center;
  }
`

export const ArrowTab = styled.div`
  border: 1px solid ${th('borders.default')};
  border-radius: 4px;
  display: inline-block;
  font-family: ${th('fonts.bold')};
  font-size: 10px;
  line-height: 10px;
  margin-left: 3px;
  margin-right: 3px;
  min-width: 18px;
  padding: 3px 4px;
  text-align: center;
`

export const OptionArrowWrapper = styled.div`
  bottom: 5px;
  font-size: 12px;
  line-height: 12px;
  position: absolute;
  right: 5px;

  @media screen and (max-width: 769px) {
    display: none;
  }
`

export const ArrowWrapper = styled.div`
  font-size: 12px;
  line-height: 12px;

  @media screen and (max-width: 769px) {
    margin-top: 15px;
  }
`

export const ExternalLink = styled.a`
  display: inline-block;
  text-decoration: none;
`

export const CommitmentText = styled.p`
  max-width: 80%;
  line-height: 1.5em;
`

export const BillingNotice = styled.div`
  background: ${th('backgrounds.notice')};
  border: 2px solid ${th('borders.dark')};
  line-height: 1.25em;
  margin-right: 10px;
  padding: 10px;
`

export const ModalWrapper = styled.div``

export const SocialMediaWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-top: ${spacing.default}px;

  strong {
    margin: 0 ${spacing.default}px 0 0;
    line-height: 1;
  }

  @media screen and (min-width: 769px) {
    margin-left: 0;
  }
`

export const ThanksHeader = styled.h3`
  margin: 0 0 25px;
`

export const CloseIcon = styled(Close)`
  float: right;
  color: ${props => (props.dark ? th('text.dark') : 'white')};
  cursor: pointer;
  margin-right: -15px;
  margin-top: -25px;
`

export const ModalHeader = styled.h3`
  margin-top: 0;
  line-height: 1em;
`

export const CenteredModalHeader = styled.h3`
  font-size: 20px;
  line-height: 1em;
  margin-bottom: 30px;
  margin-top: 0;
  text-align: center;

  @media screen and (min-width: 769px) {
    font-size: 27px;
  }
`

export const HorizontalSpacer = styled.div`
  margin-right: 10px;
`

export const CloseModalShortWrapper = styled.div`
  display: inline-block;
  margin-top: 10px;
`

export const Commitment = styled.span`
  color: white;
  height: 35px;
  padding: 5px 8px;
  margin-left: ${props => (props.marginLeft ? '10px' : '0')};
  margin-right: 10px;
  font-size: 17px;
  vertical-align: middle;
  border-radius: 50px;
  background: ${th('primary.light')};
`

export const SharingInstructions = styled.p`
  font-family: ${th('fonts.bold')};
  line-height: 1.5em;
  margin: 0 24px 0 0;
  width: 100%;
`

export const HostMessage = styled.div`
  background: ${th('backgrounds.light')};
  border: 1px solid ${th('borders.default')};
  line-height: 1.25em;
  margin-top: 10px;
  padding: 10px;
`
