import React from 'react'
import styled from 'styled-components'
import constants from '../../config/constants'
import th from '../../utils/themeHelper'

const HeroContainer = styled.div`
  padding: 50px 20px;
  background: linear-gradient(to right, rgba(0, 0, 0, 0.6) 0%, rgba(0, 0, 0, 0.25) 100%),
    url(${props => props.src});
  background-color: ${th('primary.base')};
  background-size: cover;
  background-position: center center;

  @media screen and (min-width: 730px) {
    padding: 50px;
  }

  @media screen and (min-width: 1024px) {
    margin-top: 65px;
    padding: 90px;
  }
`

const TitlesContainer = styled.div`
  width: 100%;

  @media screen and (min-width: 730px) {
    width: 50%;
    margin-top: 100px;
    padding-bottom: 200px;
  }

  @media screen and (min-width: 1024px) {
    width: 40%;
  }
`

const Title = styled.h2`
  color: white;
  margin-top: 0;

  @media screen and (min-width: 1024px) {
    display: none;
  }
`

const Subtitle = styled.h4`
  color: white;
  margin-bottom: 0;

  @media screen and (min-width: 1024px) {
    display: none;
  }
`

const TitleLarge = styled.h1`
  display: none;
  color: white;
  margin-top: 0;
  line-height: 1.2em;

  @media screen and (min-width: 1024px) {
    display: block;
  }
`

const SubtitleLarge = styled.h3`
  display: none;
  color: white;
  margin-bottom: 0;

  @media screen and (min-width: 1024px) {
    display: block;
  }
`

const LandingPageHero = ({ title, subtitle, doesSupportWebP }) => {
  const heroImage = constants.S3_ASSET_PATH + 'landing-page-hero.jpg'
  const heroImageWebP = constants.S3_ASSET_PATH + 'landing-page-hero.webp'
  const imageUrl = doesSupportWebP ? heroImageWebP : heroImage

  return (
    <HeroContainer src={imageUrl}>
      <TitlesContainer>
        <Title>{title}</Title>
        <Subtitle>{subtitle}</Subtitle>

        <TitleLarge>{title}</TitleLarge>
        <SubtitleLarge>{subtitle}</SubtitleLarge>
      </TitlesContainer>
    </HeroContainer>
  )
}

export default LandingPageHero
