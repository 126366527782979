import axios from 'axios'

// config
import baseUrl from '../config'

// utils

import cleanPayload from 'utils/cleanPayload'

export const fetchAllStories = async (payload = {}) => {
  const cleanedPayload = cleanPayload(payload)

  return await axios(`${baseUrl}/all-stories`, {
    withCredentials: true,
    method: 'GET',
    responseType: 'json',
    params: { ...cleanedPayload }
  })
}

export const fetchStoryLikes = async (payload = {}) => {
  const cleanedPayload = cleanPayload(payload)

  return await axios(`${baseUrl}/story-likes`, {
    withCredentials: true,
    method: 'GET',
    responseType: 'json',
    params: { ...cleanedPayload }
  })
}

export const fetchStories = async (payload = {}) => {
  const cleanedPayload = cleanPayload(payload)

  return await axios(`${baseUrl}/stories`, {
    withCredentials: true,
    method: 'GET',
    responseType: 'json',
    params: { ...cleanedPayload }
  })
}

export const createStory = async data => {
  return await axios({
    withCredentials: true,
    method: 'post',
    url: `${baseUrl}/stories`,
    data,
    config: { headers: { 'Content-Type': 'multipart/form-data' } }
  })
}

export const fetchStory = async ({ storyId }) => {
  const response = await axios(`${baseUrl}/stories/${storyId}`, {
    withCredentials: true,
    method: 'GET',
    responseType: 'json'
  })

  return response.data
}

export const updateStory = async story => {
  const response = await axios({
    withCredentials: true,
    method: 'put',
    url: `${baseUrl}/stories/${story.get('storyId')}`,
    data: story,
    config: { headers: { 'Content-Type': 'multipart/form-data' } }
  })
  return response.data
}

export const deleteStory = async storyId => {
  return await axios.delete(`${baseUrl}/stories`, { data: { storyId }, withCredentials: true })
}

export const likeStory = async data => {
  return await axios({
    withCredentials: true,
    method: 'post',
    url: `${baseUrl}/stories/like`,
    data
  })
}

export const unlikeStory = async ({ id }) => {
  return await axios.delete(`${baseUrl}/stories/unlike`, { data: { id }, withCredentials: true })
}
