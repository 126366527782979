import React from 'react'
import styled from 'styled-components'
import th from '../../utils/themeHelper'

const CampaignsContainer = styled.div`
  background: ${th('backgrounds.light')};
  padding: 30px 0 0;

  @media screen and (min-width: 730px) {
    padding: 50px 35px;
  }

  @media screen and (min-width: 1024px) {
    padding: 50px 70px;
  }
`

const Heading = styled.h3`
  margin-top: 0;
  margin-left: 15px;
`

const Cards = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
`

const CardWrapper = styled.div`
  flex-basis: 100%;
  padding: 0 8px;

  @media screen and (min-width: 600px) {
    padding: 15px;
  }

  @media screen and (min-width: 730px) {
    flex-basis: 50%;
  }

  @media screen and (min-width: 1024px) {
  }
`

const LandingPageCurrentCampaigns = ({ title, children = [] }) => (
  <CampaignsContainer>
    <Heading>{title}</Heading>
    <Cards>
      {React.Children.map(children, card => (
        <CardWrapper key={card.key}>{card}</CardWrapper>
      ))}
    </Cards>
  </CampaignsContainer>
)

export default LandingPageCurrentCampaigns
