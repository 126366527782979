import { call, put, takeLatest } from 'redux-saga/effects'
import * as platformEventsApi from '../apiModules/platformEvents'
import * as platformEventsModule from '../modules/platformEvents'

function* fetchPlatformEvents({ payload }) {
  try {
    const response = yield call(platformEventsApi.fetchPlatformEvents, payload)
    yield put(platformEventsModule.fetchPlatformEventsSuccess(response))
  } catch (err) {
    yield put(platformEventsModule.fetchPlatformEventsFailure(err))
  }
}

export const platformEventsSagas = [
  takeLatest(platformEventsModule.FETCH_PLATFORM_EVENTS, fetchPlatformEvents)
]
