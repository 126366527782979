import React from 'react'
import styled from 'styled-components'
import { Link } from '@reach/router'
import { parse } from 'qs'

// assets
// import DefaultLogo from 'assets/images/icon-group.svg'
import DonationIcon from 'assets/images/cause-type-icons/money_icon_dark.svg'
import { Location } from 'components/Icons/Icons'

// icons
import { CalendarHeart } from '@styled-icons/boxicons-regular'
import { CheckCircleFill } from '@styled-icons/bootstrap/CheckCircleFill'
import { Label } from '@styled-icons/material/Label'
import { SolidHeart } from 'components/Icons/Icons'

// utils
// import stringUtils from 'utils/string'
import th from 'utils/themeHelper'

// config
import l, { c } from 'config/localization'
import constants from 'config/constants'

// styles
import { getPillColors } from 'components/SearchResultsContainer/styles'
import DashCardWrapper from './DashCardWrapper'

// Load components synchronously
import ConditionalDisplay from 'components/ConditionalDisplay/ConditionalDisplay'

const ActionItem = styled.div`
  margin-right: 10px;

  &:last-of-type {
    margin-right: 0;
  }
`

const Actions = styled.div`
  align-items: center;
  display: flex;
  flex: 1;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin: 5px 0 10px 10px;
`

const ActionsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`

const EditStoryCardLink = styled(Link)`
  border-color: ${th('backgrounds.mid')};
  border-radius: 4px;
  border-style: solid;
  border-width: 1px;
  color: ${th('text.dark')};
  margin: 0;
  padding: 8px 12px;
  text-align: center;
  text-decoration: none;
  white-space: nowrap;

  & > svg {
    width: 15px;
    margin-right: 10px;
  }
`

const GoodsContributionActionsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`

const Info = styled.div`
  display: block;
  margin: 10px 15px 0;
  flex: 1;
`

const InfoFieldLabel = styled.div`
  font-family: ${th('fonts.bold')};
  margin-right: 10px;
`

const InfoFieldValue = styled.div`
  font-family: ${th('fonts.regular')};
  margin-left: 30px;
`

const InfoFieldIcon = styled.div`
  margin-right: 10px;

  & > svg {
    height: 20px;
  }
`

const InfoFieldImage = styled.img`
  height: 20px;
`

const InfoGroup = styled.div`
  min-width: 30%;
`

const InfoGroupRow = styled.div`
  color: ${th('text.mid')};
  line-height: 1.25em;
  margin-bottom: 15px;
`

const InfoGroupRowInner = styled.div`
  display: flex;
  word-break: break-word;
`

// const Logo = styled.div`
//   background-image: url(${({ src }) => src});
//   background-size: cover;
//   background-position: center center;
//   border: 1px solid ${th('borders.darkPill')};
//   border-radius: 50%;
//   height: 50px;
//   margin-right: 15px;
//   width: 50px;
// `

const Pill = styled.div`
  background: ${props => props.bgColor || th('backgrounds.default')};
  color: ${props => props.fontColor || th('text.dark')};
  border-radius: 3px;
  display: inline-block;
  font-size: 9px;
  margin-top: 2px;
  padding: 2px 5px;
  text-align: center;
  text-transform: uppercase;
  width: ${props => (props.fullWidth ? '100%' : 'auto')};
`

const DashContributionCard = ({
  amount,
  avatar,
  // beneficiary_avatar,
  beneficiary_name,
  // beneficiary_url,
  confirm_donation_path,
  contribution_date,
  contribution_status,
  opportunity_date,
  opportunity_name,
  opportunity_location,
  opp_path,
  status,
  visibility_status
}) => {
  const contributionStatusColors = getPillColors({
    key: 'contribution_status',
    value: contribution_status
  })
  const contributionVisibilityColors = getPillColors({
    key: 'visibility_status',
    value: visibility_status
  })
  const { bgColor, color } = getPillColors({ key: 'status', value: status })
  const { type: contributionType } = parse(location.search.replace('?', ''))

  const isGoodsType = contributionType === 'goods'
  const isMoneyType = contributionType === 'money'
  const isVolType = contributionType === 'vol'

  if (isVolType) {
    return (
      <DashCardWrapper>
        <ActionsWrapper>
          <Info>
            <InfoGroupRow>
              <InfoGroupRow>
                <InfoGroupRowInner>
                  <InfoFieldIcon>
                    <SolidHeart />
                  </InfoFieldIcon>
                  <InfoFieldLabel>Beneficiary</InfoFieldLabel>
                </InfoGroupRowInner>

                <InfoGroupRowInner>
                  <InfoFieldValue>{beneficiary_name}</InfoFieldValue>
                </InfoGroupRowInner>
              </InfoGroupRow>
            </InfoGroupRow>

            <InfoGroupRow>
              <InfoGroupRow>
                <InfoGroupRowInner>
                  <InfoFieldIcon>
                    <Label />
                  </InfoFieldIcon>
                  <InfoFieldLabel>{c(l('OPP'))}</InfoFieldLabel>
                </InfoGroupRowInner>

                <InfoGroupRowInner>
                  <InfoFieldValue>{opportunity_name}</InfoFieldValue>
                </InfoGroupRowInner>
              </InfoGroupRow>
            </InfoGroupRow>

            <InfoGroupRow>
              <InfoGroupRowInner>
                <InfoFieldIcon>
                  <CheckCircleFill />
                </InfoFieldIcon>
                <InfoFieldLabel>Status</InfoFieldLabel>
              </InfoGroupRowInner>

              <InfoGroupRowInner>
                <InfoFieldValue>
                  <Pill bgColor={bgColor} fontColor={color}>
                    {status}
                  </Pill>
                </InfoFieldValue>
              </InfoGroupRowInner>
            </InfoGroupRow>

            <InfoGroup>
              <InfoGroupRow>
                <InfoGroupRowInner>
                  <InfoFieldIcon>
                    <CalendarHeart />
                  </InfoFieldIcon>
                  <InfoFieldLabel>Date</InfoFieldLabel>
                </InfoGroupRowInner>

                <InfoGroupRowInner>
                  <InfoFieldValue>{opportunity_date || 'Any time'}</InfoFieldValue>
                </InfoGroupRowInner>
              </InfoGroupRow>
            </InfoGroup>

            <InfoGroup>
              <InfoGroupRow>
                <InfoGroupRowInner>
                  <InfoFieldIcon>
                    <Location />
                  </InfoFieldIcon>
                  <InfoFieldLabel>Location</InfoFieldLabel>
                </InfoGroupRowInner>

                <InfoGroupRowInner>
                  <InfoFieldValue>{opportunity_location || 'Remote'}</InfoFieldValue>
                </InfoGroupRowInner>
              </InfoGroupRow>
            </InfoGroup>
          </Info>

          <Actions>
            <ActionItem>
              <EditStoryCardLink to={opp_path || '#'}>
                <span>View {l('OPP')}</span>
              </EditStoryCardLink>
            </ActionItem>
          </Actions>
        </ActionsWrapper>
      </DashCardWrapper>
    )
  }

  if (isGoodsType) {
    const canConfirmGoodsDonation =
      contribution_status === constants.APPLICATION_STATUS_NAMES.PENDING_DONATION

    return (
      <DashCardWrapper>
        <GoodsContributionActionsWrapper>
          <Info>
            <InfoGroupRow>
              <InfoGroupRow>
                <InfoGroupRowInner>
                  <InfoFieldIcon>
                    <SolidHeart />
                  </InfoFieldIcon>
                  <InfoFieldLabel>Beneficiary</InfoFieldLabel>
                </InfoGroupRowInner>

                <InfoGroupRowInner>
                  <InfoFieldValue>{beneficiary_name}</InfoFieldValue>
                </InfoGroupRowInner>
              </InfoGroupRow>
            </InfoGroupRow>

            <InfoGroupRow>
              <InfoGroupRow>
                <InfoGroupRowInner>
                  <InfoFieldIcon>
                    <Label />
                  </InfoFieldIcon>
                  <InfoFieldLabel>{c(l('OPP'))}</InfoFieldLabel>
                </InfoGroupRowInner>

                <InfoGroupRowInner>
                  <InfoFieldValue>{opportunity_name}</InfoFieldValue>
                </InfoGroupRowInner>
              </InfoGroupRow>
            </InfoGroupRow>

            <InfoGroupRow>
              <InfoGroupRowInner>
                <InfoFieldIcon>
                  <CheckCircleFill />
                </InfoFieldIcon>
                <InfoFieldLabel>Amount</InfoFieldLabel>
              </InfoGroupRowInner>

              <InfoGroupRowInner>
                <InfoFieldValue>{amount}</InfoFieldValue>
              </InfoGroupRowInner>
            </InfoGroupRow>

            <InfoGroupRow>
              <InfoGroupRowInner>
                <InfoFieldIcon>
                  <CheckCircleFill />
                </InfoFieldIcon>
                <InfoFieldLabel>Status</InfoFieldLabel>
              </InfoGroupRowInner>

              <InfoGroupRowInner>
                <InfoFieldValue>
                  <Pill
                    bgColor={contributionStatusColors.bgColor}
                    fontColor={contributionStatusColors.color}
                  >
                    {contribution_status}
                  </Pill>
                </InfoFieldValue>
              </InfoGroupRowInner>
            </InfoGroupRow>

            <InfoGroupRow>
              <InfoGroupRowInner>
                <InfoFieldIcon>
                  <CheckCircleFill />
                </InfoFieldIcon>
                <InfoFieldLabel>Visibility</InfoFieldLabel>
              </InfoGroupRowInner>

              <InfoGroupRowInner>
                <InfoFieldValue>
                  <Pill
                    bgColor={contributionVisibilityColors.bgColor}
                    fontColor={contributionVisibilityColors.color}
                  >
                    {visibility_status}
                  </Pill>
                </InfoFieldValue>
              </InfoGroupRowInner>
            </InfoGroupRow>

            <InfoGroup>
              <InfoGroupRow>
                <InfoGroupRowInner>
                  <InfoFieldIcon>
                    <CalendarHeart />
                  </InfoFieldIcon>
                  <InfoFieldLabel>Contribution date</InfoFieldLabel>
                </InfoGroupRowInner>

                <InfoGroupRowInner>
                  <InfoFieldValue>{contribution_date}</InfoFieldValue>
                </InfoGroupRowInner>
              </InfoGroupRow>
            </InfoGroup>

            <InfoGroupRow>
              <InfoGroupRow>
                <InfoGroupRowInner>
                  <InfoFieldIcon>
                    <InfoFieldImage src={avatar} />
                  </InfoFieldIcon>
                  <InfoFieldLabel>Donor</InfoFieldLabel>
                </InfoGroupRowInner>

                <InfoGroupRowInner>
                  <InfoFieldValue>donor</InfoFieldValue>
                </InfoGroupRowInner>
              </InfoGroupRow>
            </InfoGroupRow>
          </Info>

          <ConditionalDisplay displayWhen={[canConfirmGoodsDonation]}>
            <Actions>
              <ActionItem>
                <EditStoryCardLink to={confirm_donation_path}>
                  <span>Confirm Donation</span>
                </EditStoryCardLink>
              </ActionItem>
            </Actions>
          </ConditionalDisplay>
        </GoodsContributionActionsWrapper>
      </DashCardWrapper>
    )
  }

  if (isMoneyType) {
    return (
      <DashCardWrapper>
        <ActionsWrapper>
          <Info>
            <InfoGroupRow>
              <InfoGroupRow>
                <InfoGroupRowInner>
                  <InfoFieldIcon>
                    <SolidHeart />
                  </InfoFieldIcon>
                  <InfoFieldLabel>Beneficiary</InfoFieldLabel>
                </InfoGroupRowInner>

                <InfoGroupRowInner>
                  <InfoFieldValue>{beneficiary_name}</InfoFieldValue>
                </InfoGroupRowInner>
              </InfoGroupRow>
            </InfoGroupRow>

            <ConditionalDisplay displayWhen={[opportunity_name]}>
              <InfoGroupRow>
                <InfoGroupRow>
                  <InfoGroupRowInner>
                    <InfoFieldIcon>
                      <Label />
                    </InfoFieldIcon>
                    <InfoFieldLabel>{c(l('OPP'))}</InfoFieldLabel>
                  </InfoGroupRowInner>

                  <InfoGroupRowInner>
                    <InfoFieldValue>{opportunity_name}</InfoFieldValue>
                  </InfoGroupRowInner>
                </InfoGroupRow>
              </InfoGroupRow>
            </ConditionalDisplay>

            <InfoGroupRow>
              <InfoGroupRowInner>
                <InfoFieldIcon>
                  <InfoFieldImage src={DonationIcon} />
                </InfoFieldIcon>
                <InfoFieldLabel>Amount</InfoFieldLabel>
              </InfoGroupRowInner>

              <InfoGroupRowInner>
                <InfoFieldValue>{amount}</InfoFieldValue>
              </InfoGroupRowInner>
            </InfoGroupRow>

            <InfoGroupRow>
              <InfoGroupRowInner>
                <InfoFieldIcon>
                  <CheckCircleFill />
                </InfoFieldIcon>
                <InfoFieldLabel>Visibility</InfoFieldLabel>
              </InfoGroupRowInner>

              <InfoGroupRowInner>
                <InfoFieldValue>
                  <Pill
                    bgColor={contributionVisibilityColors.bgColor}
                    fontColor={contributionVisibilityColors.color}
                  >
                    {visibility_status}
                  </Pill>
                </InfoFieldValue>
              </InfoGroupRowInner>
            </InfoGroupRow>

            <InfoGroupRow>
              <InfoGroupRowInner>
                <InfoFieldIcon>
                  <CheckCircleFill />
                </InfoFieldIcon>
                <InfoFieldLabel>Status</InfoFieldLabel>
              </InfoGroupRowInner>

              <InfoGroupRowInner>
                <InfoFieldValue>
                  <Pill
                    bgColor={contributionStatusColors.bgColor}
                    fontColor={contributionStatusColors.color}
                  >
                    {contribution_status}
                  </Pill>
                </InfoFieldValue>
              </InfoGroupRowInner>
            </InfoGroupRow>

            <InfoGroup>
              <InfoGroupRow>
                <InfoGroupRowInner>
                  <InfoFieldIcon>
                    <CalendarHeart />
                  </InfoFieldIcon>
                  <InfoFieldLabel>Contribution date</InfoFieldLabel>
                </InfoGroupRowInner>

                <InfoGroupRowInner>
                  <InfoFieldValue>{contribution_date}</InfoFieldValue>
                </InfoGroupRowInner>
              </InfoGroupRow>
            </InfoGroup>

            <InfoGroupRow>
              <InfoGroupRow>
                <InfoGroupRowInner>
                  <InfoFieldIcon>
                    <InfoFieldImage src={avatar} />
                  </InfoFieldIcon>
                  <InfoFieldLabel>Donor</InfoFieldLabel>
                </InfoGroupRowInner>

                <InfoGroupRowInner>
                  <InfoFieldValue>donor</InfoFieldValue>
                </InfoGroupRowInner>
              </InfoGroupRow>
            </InfoGroupRow>
          </Info>

          {/* <Actions>
            <ActionItem>
              <EditStoryCardLink to={opp_path || '#'}>
                <span>View {l('OPP')}</span>
              </EditStoryCardLink>
            </ActionItem>
          </Actions> */}
        </ActionsWrapper>
      </DashCardWrapper>
    )
  }

  return <div />
}

export default DashContributionCard
