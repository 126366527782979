import React from 'react'
import PhoneInput from 'react-phone-number-input'

// styles
import { Wrapper, PhoneNumberInputLabel } from './styles'
import { Error, RequiredTag, SubLabel, Success } from '../InputLabels/InputLabels'

// config
import { isDgmt } from 'config/localization'

// external styles
import 'react-phone-number-input/style.css'

const supportedCountries = isDgmt
  ? ['ZA']
  : ['ZA', 'NG', 'ZM', 'LS', 'AO', 'NA', 'BW', 'SZ', 'UG', 'GH', 'MU', 'MZ', 'KE', 'MG', 'ML', 'TZ']

const PhoneNumberInput = ({
  field,
  disabled,
  form: { errors, touched, setFieldValue, handleBlur, ...formProps },
  label,
  subLabel,
  success,
  requiredField,
  fullWidth,
  ...props
}) => {
  const displayableLabel = requiredField ? `${label} *` : label
  const hasError = !!errors[field.name]

  return (
    <Wrapper
      disabled={disabled}
      hasError={hasError}
      touched={!!touched[field.name]}
      requiredField={requiredField}
      fullWidth={fullWidth}
    >
      {label && (
        <PhoneNumberInputLabel htmlFor={field.name}>{displayableLabel}</PhoneNumberInputLabel>
      )}
      {subLabel && <SubLabel subLabel={subLabel}>{subLabel}</SubLabel>}

      <RequiredTag hasError={hasError} label={label} requiredField={requiredField} />

      <PhoneInput
        {...field}
        {...props}
        addInternationalOption={false}
        disabled={disabled}
        defaultCountry="ZA"
        international={false}
        onBlur={() => handleBlur(field.name)}
        onChange={value => {
          setFieldValue(field.name, value)

          if (value && props.validateOnChange) {
            props.validateField({ value: value })
          }

          if (formProps.handleChange) {
            formProps.handleChange(value || '')
          }
        }}
      />
      {success && touched[field.name] && !errors[field.name] && <Success>{success}</Success>}
      {errors[field.name] && <Error>{errors[field.name]}</Error>}
    </Wrapper>
  )
}

PhoneNumberInput.defaultProps = {
  countries: supportedCountries
}

export default PhoneNumberInput
