import { createActions, handleActions } from 'redux-actions'

export const FETCH_PLATFORM_EVENTS = 'platformEvents/FETCH_PLATFORM_EVENTS'
export const FETCH_PLATFORM_EVENTS_SUCCESS = 'platformEvents/FETCH_PLATFORM_EVENTS_SUCCESS'
export const FETCH_PLATFORM_EVENTS_FAILURE = 'platformEvents/FETCH_PLATFORM_EVENTS_FAILURE'

export const {
  platformEvents: { fetchPlatformEvents, fetchPlatformEventsSuccess, fetchPlatformEventsFailure }
} = createActions({
  [FETCH_PLATFORM_EVENTS]: undefined,
  [FETCH_PLATFORM_EVENTS_SUCCESS]: undefined,
  [FETCH_PLATFORM_EVENTS_FAILURE]: undefined
})

const initialState = {
  isLoading: null,
  isSubmitting: false,
  hasError: false,
  errorMessage: null,
  data: []
}

export default handleActions(
  {
    [FETCH_PLATFORM_EVENTS]: state => {
      return { ...state, isLoading: true, hasError: false }
    },
    [FETCH_PLATFORM_EVENTS_SUCCESS]: (state, { payload }) => ({
      ...state,
      isLoading: false,
      hasSubmitted: false,
      hasError: false,
      data: payload
    }),
    [FETCH_PLATFORM_EVENTS_FAILURE]: state => ({ ...state, hasError: true })
  },
  initialState
)
