import React, { Component } from 'react'
import styled from 'styled-components'

// assets
import { ReactComponent as PrivateIcon } from 'assets/images/svgs/icon-private-opp.svg'
import { ReactComponent as PublicIcon } from 'assets/images/svgs/icon-public-opp.svg'

// utils
import th from 'utils/themeHelper'

// config
import constants from 'config/constants'

// Load components synchronously
import LinkButton from 'components/SmallButtonPrimary/LinkButton'
import SmallButtonPrimary from 'components/SmallButtonPrimary/SmallButtonPrimary'
import SwipeSelect from 'components/Select/SwipeSelect'

const storyStateOpts = [
  {
    label: 'Public',
    value: constants.STORY_STATES.PUBLIC,
    Icon: PublicIcon,
    iconPadding: '8px 0 3px'
  },
  {
    label: 'Hidden',
    value: constants.STORY_STATES.ADMIN_HIDDEN,
    Icon: PrivateIcon,
    iconPadding: '8px 0 3px'
  }
]

const ButtonWrapper = styled.div`
  margin-bottom: 10px;
  margin-right: 10px;

  @media screen and (min-width: 769px) {
    margin-bottom: 0;
  }
`

const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: ${props => props.justify || 'flex-start'};

  @media screen and (max-width: 600px) {
    flex-direction: column;
    max-width: max-content;
  }
`

const EditStatusLabel = styled.div`
  color: ${th('text.dark')};
  font-family: ${th('fonts.bold')};
  margin-bottom: 15px;
  margin-left: 2px;
`

const FullWidth = styled.div`
  width: 100%;
`

export const HeartIconWrapper = styled.div`
  svg {
    color: ${props => props.color || th('text.dark')};
  }
`

const StoryPillContainer = styled.div`
  position: ${props => (props.open ? 'absolute' : 'relative')};
  bottom: ${props => (props.open ? '10px' : '0')};
  margin-right: 8px;
  width: ${props =>
    props.open && props.context === 'StoryEditIcon' ? 'calc(100% - 20px)' : 'auto'};
  z-index: ${props => (props.open ? 10 : 1)};
`

const StoryPillInner = styled.div`
  align-items: ${props => (props.open && props.fullSize ? 'flex-start' : 'center')};
  background: white;
  border: 1px solid ${th('borders.medium')};
  border-radius: 4px;
  display: flex;
  justify-content: center;
  line-height: 1.35;
  min-height: 37px;
  overflow-y: auto;
  padding: 7px 8px;

  @media screen and (min-width: 769px) {
    min-height: 30px;
    min-width: 32px;
    padding: 15px;
  }

  svg {
    margin-right: ${props => (props.open ? '4px' : '0')};
    margin-top: ${props => (props.open ? '-3px' : props.marginTop || '-2px')};
  }
`

class EditStoryCard extends Component {
  constructor(props) {
    super(props)

    this.state = { open: false, status: props.storyStatus }
  }

  render() {
    const { status } = this.state
    const {
      handleStoryEditToggle,
      hasGodRole,
      id,
      isFeatureStory,
      storyStatus,
      updateStory,
      updateStoryStatus
    } = this.props

    const showEditButton = hasGodRole
    if (!showEditButton || !updateStory) return null
    if (isFeatureStory) return null

    const defaultStoryStatus = storyStatus
      ? storyStateOpts.find(o => o.value === storyStatus)
      : null
    const defaultStatus = defaultStoryStatus ? defaultStoryStatus.value : null

    return (
      <StoryPillContainer context="StoryEditIcon" fullSize left={'15px'}>
        <StoryPillInner fullSize open>
          <FullWidth>
            <EditStatusLabel>Manage Story</EditStatusLabel>

            <FullWidth>
              <SwipeSelect
                defaultValue={defaultStatus}
                flex
                forceFullWidth
                forceShowOptions
                label="Update the visibilty for this story"
                marginRight
                onChange={selection => {
                  if (selection) {
                    this.setState({ status: selection.value })
                  }
                }}
                options={storyStateOpts}
                shortOptions
                value={status || defaultStatus}
              />
            </FullWidth>

            <ButtonsWrapper>
              <ButtonWrapper>
                <SmallButtonPrimary
                  type="button"
                  onClick={() => {
                    updateStory({ id, status })
                    handleStoryEditToggle({ isEditingStory: false })
                    updateStoryStatus(status)
                  }}
                >
                  Update
                </SmallButtonPrimary>
              </ButtonWrapper>

              <LinkButton onClick={() => handleStoryEditToggle({ isEditingStory: false })}>
                Cancel
              </LinkButton>
            </ButtonsWrapper>
          </FullWidth>
        </StoryPillInner>
      </StoryPillContainer>
    )
  }
}

export default EditStoryCard
