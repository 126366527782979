import React from 'react'
import styled from 'styled-components'

// utils and config
import th from 'utils/themeHelper'
import constants from 'config/constants'

// Load components synchronously
import ConditionalDisplay from 'components/ConditionalDisplay/ConditionalDisplay'

const ActivityList = styled.div`
  margin-top: 4em;
`

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: left;
  justify-content: space-between;
  border-bottom: 1px solid ${th('borders.default')};
`

const ActivityColumn = styled.div`
  display: flex;
  flex-direction: column;
  width: 33.33%;
`

const LeftColumn = styled(ActivityColumn)`
  align-items: flex-start;
`

const MiddleColumn = styled(ActivityColumn)`
  align-items: flex-start;
`

const RightColumn = styled(ActivityColumn)`
  align-items: flex-end;
`

const ContactName = styled.p`
  margin: 0;
`

const NoInvitesMessage = styled.div`
  margin: 3em auto 0;
  width: 60%;
  text-align: center;
`

const ContactItem = styled.label`
  color: ${th('text.light')};
`

const DashboardActivityRow = ({ person, apps = [] }) => {
  const { f_name, l_name, email, mobile_number } = person
  const app = apps.find(app => app.f_name && app.l_name) || {}

  const firstName = f_name || app.f_name
  const lastName = l_name || app.l_name
  return (
    <Wrapper>
      <LeftColumn>
        <ContactName>{firstName ? `${firstName} ${lastName}` : email}</ContactName>
        <ContactItem>{email}</ContactItem>

        <ConditionalDisplay displayWhen={[mobile_number]}>
          <ContactItem>{mobile_number}</ContactItem>
        </ConditionalDisplay>
      </LeftColumn>

      <MiddleColumn>
        {apps.map(app => (
          <p key={app.opportunity_id}>{app.opportunity_title}</p>
        ))}
      </MiddleColumn>

      <RightColumn>
        {apps.map(app => (
          <p key={app.opportunity_id}>{constants.APPLICATION_STATUSES[app.status]}</p>
        ))}
      </RightColumn>
    </Wrapper>
  )
}

const DashboardActivityList = ({ people = [], applications = [] }) => {
  const hasNoInvites = people.length < 2

  return (
    <ActivityList>
      {people.map(person => {
        const apps = applications.filter(a => a.email === person.email)
        return <DashboardActivityRow key={person.email} person={person} apps={apps} />
      })}

      {hasNoInvites && (
        <NoInvitesMessage>
          There's no one else on your team yet! Invite people to your team on Brownie Points to
          track their goods deeds and activity here.
        </NoInvitesMessage>
      )}
    </ActivityList>
  )
}

export default DashboardActivityList
