import React, { Component } from 'react'
import { navigate } from '@reach/router'

// Load components synchronously
import LargeButton from 'components/LargeButton/LargeButton'
import LinkButton from 'components/SmallButtonPrimary/LinkButton'

// Assets - icons
import { MessageSquareCheck } from 'components/Icons/Icons'
import { Alarm } from '@styled-icons/bootstrap/Alarm'

// styles
import {
  CommitmentText,
  CloseModalShortWrapper,
  CloseIcon,
  HorizontalSpacer,
  ModalContainer,
  ModalWrapper,
  ModalHeader,
  ScreenWrapper
} from './styles'

class PostRedirectModal extends Component {
  constructor(props) {
    super(props)
    this.escFunction = this.escFunction.bind(this)
  }

  escFunction(event) {
    if (event.keyCode === 27) {
      this.props.hideModal()
    }
  }

  componentDidMount() {
    document.addEventListener('keydown', this.escFunction, false)
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.escFunction, false)
  }

  render() {
    const { extraInfoModalOpts, hideModal } = this.props
    const { donationDetailId } = extraInfoModalOpts

    const currentPath = window.location.pathname.replace(/\/$/, '')
    const confirmPath = donationDetailId
      ? `${currentPath}/donations/${donationDetailId}`
      : `/dashboard/contributions?type=money`

    return (
      <ScreenWrapper>
        <ModalWrapper>
          <ModalContainer>
            <CloseIcon dark size={30} onClick={hideModal} />

            <ModalHeader>Next steps</ModalHeader>

            <CommitmentText>
              Please note that your donation will not automatically reflect as confirmed on your
              Brownie Points profile. This donation will only reflect as confirmed when the
              beneficiary confirms it using whichever reference number/code you may have provided
              them with.
            </CommitmentText>

            <CloseModalShortWrapper>
              <LargeButton
                onClick={() => navigate(confirmPath)}
                icon={MessageSquareCheck}
                type="button"
              >
                Confirm Donation Now
              </LargeButton>
            </CloseModalShortWrapper>

            <HorizontalSpacer />

            <CloseModalShortWrapper>
              <LinkButton hasIcon icon={Alarm} onClick={hideModal}>
                I will do this later
              </LinkButton>
            </CloseModalShortWrapper>
          </ModalContainer>
        </ModalWrapper>
      </ScreenWrapper>
    )
  }
}

export default PostRedirectModal
