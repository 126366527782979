import { call, put, takeLatest } from 'redux-saga/effects'
import { navigate } from '@reach/router'

import * as opportunitiesModule from '../modules/opportunities'
import * as orgsModule from '../modules/organisations'
import * as peopleModule from '../modules/people'
import * as storiesModule from '../modules/stories'
import * as storiesApi from '../apiModules/stories'

// config and utils
import isLoggedIn from 'utils/auth'
import { getLocalStorageValue } from 'utils/localStorageManager'
import UrlBuilder from 'utils/urlBuilder'
import constants from 'config/constants'
import l from 'config/localization'

const deriveStoryPath = ({ payload, story }) => {
  const userId = getLocalStorageValue('user_id')
  const username = getLocalStorageValue('username')

  const goodDeedStory = [
    constants.STORY_TYPES.GOOD_DEED,
    constants.STORY_TYPES.GOOD_DEED_TEMPLATE
  ].includes(payload.story_type)
  const isPublicStory =
    payload.status === constants.STORY_STATES.PUBLIC &&
    payload.public_status === constants.STORY_STATES.PUBLIC

  if (goodDeedStory) {
    const profilePath = username ? `/@${username}` : `/users/${userId}`
    return isPublicStory ? `/stories/${story.id}?share=true` : profilePath
  }

  const isOppStory = [constants.STORY_TYPES.OPP_FEATURE, constants.STORY_TYPES.OPP_UPDATE].includes(
    payload.story_type
  )

  const isOrgStory = [
    constants.STORY_TYPES.ORG_FEATURE,
    constants.STORY_TYPES.ORG_UPDATE,
    constants.STORY_TYPES.NON_PROFIT_DONATION_FEEDBACK
  ].includes(payload.story_type)

  const isCampStory = [
    constants.STORY_TYPES.CAMP_FEATURE,
    constants.STORY_TYPES.CAMP_UPDATE
  ].includes(payload.story_type)

  if (isCampStory) return `/${l('ROUTE_CAMPS')}/${payload.entity_id}`
  if (isOppStory) return `/${l('ROUTE_OPPS')}/${payload.entity_id}`
  if (isOrgStory) return `/${l('ROUTE_ORGS')}/${payload.chosenOrgUrlName}/activity`
}

function* fetchStories({ payload }) {
  try {
    const response = yield call(storiesApi.fetchStories, payload)
    yield put(storiesModule.fetchStoriesSuccess(response.data))
  } catch (err) {
    yield put(storiesModule.fetchStoriesFailure())
  }
}

function* fetchAllStories({ payload }) {
  try {
    const response = yield call(storiesApi.fetchAllStories, payload)
    yield put(storiesModule.fetchStoriesSuccess(response.data))
  } catch (err) {
    yield put(storiesModule.fetchStoriesFailure())
  }
}

function* fetchStoryLikes({ payload }) {
  try {
    const response = yield call(storiesApi.fetchStoryLikes, payload)
    yield put(storiesModule.fetchStoryLikesSuccess(response.data))
  } catch (err) {
    yield put(storiesModule.fetchStoryLikesFailure())
  }
}

function* fetchStory({ payload }) {
  try {
    const response = yield call(storiesApi.fetchStory, payload)
    yield put(storiesModule.fetchStorySuccess(response))
  } catch (err) {
    yield put(storiesModule.fetchStoryFailure(err))
  }
}

function* createStory({ payload }) {
  try {
    const formDataVals = new FormData()
    Object.keys(payload).map(value => {
      if (payload[value]) {
        formDataVals.append(value, payload[value])
      }
    })

    const { data } = yield call(storiesApi.createStory, formDataVals)
    yield put(storiesModule.createStorySuccess({ data }))

    const storyPath = deriveStoryPath({ payload, story: data })
    navigate(storyPath)
  } catch (err) {
    yield put(storiesModule.createStoryFailure())
  }
}

function* updateStory({ payload }) {
  try {
    const { showSuccessDialog } = payload

    const formDataVals = new FormData()
    formDataVals.append('storyId', payload.id)

    Object.keys(payload).map(fieldName => {
      const isFalseValue = typeof payload[fieldName] === 'boolean' && payload[fieldName] === false
      const useableValue = isFalseValue ? 'false' : payload[fieldName]

      if (useableValue) {
        formDataVals.append(fieldName, useableValue)
      }
    })

    const response = yield call(storiesApi.updateStory, formDataVals)
    yield put(storiesModule.updateStorySuccess({ response, showSuccessDialog }))
  } catch (err) {
    yield put(storiesModule.updateStoryFailure())
  }
}

function* deleteStory({ payload }) {
  try {
    yield call(storiesApi.deleteStory, payload)
    yield put(storiesModule.deleteStorySuccess(payload))
    UrlBuilder.displayDeleteSuccessMessage()
  } catch (err) {
    yield put(storiesModule.deleteStoryFailure())
  }
}

function* handleStoryLike({ payload }) {
  const loggedIn = isLoggedIn()
  if (!loggedIn) return true

  const { value } = payload
  if (value === 'liked') {
    yield call(storiesApi.likeStory, payload)
    yield put(storiesModule.handleStoryLikeSuccess(payload))
    yield put(peopleModule.updateUserStories(payload))
    yield put(opportunitiesModule.updateOppStories(payload))
    yield put(orgsModule.updateOrgStories(payload))
  } else if (value === 'unliked') {
    yield call(storiesApi.unlikeStory, payload)
    yield put(storiesModule.handleStoryLikeSuccess(payload))
    yield put(peopleModule.updateUserStories(payload))
    yield put(opportunitiesModule.updateOppStories(payload))
    yield put(orgsModule.updateOrgStories(payload))
  }
}

export const storiesSagas = [
  takeLatest(storiesModule.FETCH_ALL_STORIES, fetchAllStories),
  takeLatest(storiesModule.FETCH_STORIES, fetchStories),
  takeLatest(storiesModule.FETCH_STORY_LIKES, fetchStoryLikes),
  takeLatest(storiesModule.UPDATE_STORY, updateStory),
  takeLatest(storiesModule.FETCH_STORY, fetchStory),
  takeLatest(storiesModule.DELETE_STORY, deleteStory),
  takeLatest(storiesModule.CREATE_STORY, createStory),
  takeLatest(storiesModule.HANDLE_STORY_LIKE, handleStoryLike)
]
