import React from 'react'

// utils
import deriveGoalLabel, { deriveGoalSlotLabel } from 'utils/deriveGoalLabel'

// config
import constants from 'config/constants'

// Load components synchronously
import ConditionalDisplay from 'components/ConditionalDisplay/ConditionalDisplay'

// styles
import {
  CommitmentGoalSubStat,
  CommitmentSearchNote,
  Strong,
  ProgressBar,
  ProgressBarGoalLabel,
  ProgressBarWrapper,
  ProgressIndicator
} from './styles'

const determineRemainingAmount = ({
  goalType,
  isMoneyType,
  outcomeTarget = 0,
  totalConfirmedAmount = 0
}) => {
  // Calculate how much is left until target amount
  const remainingAmount = outcomeTarget - totalConfirmedAmount

  // Once target is exceeded, don't show negative counter
  if (remainingAmount < 1) return null

  // If target is not yet reached, determine label and remaining count
  const remainingSlotLabel = deriveGoalSlotLabel({ goalType, outcomeTarget })

  return isMoneyType
    ? `R${Math.floor(remainingAmount).toLocaleString('en-US')}`
    : `${remainingAmount} ${remainingSlotLabel}`
}

const SearchPageCommittedNote = ({ committedPerc, confirmedPerc, goalType }) => {
  if (!committedPerc || confirmedPerc) return null
  if (goalType === constants.OPPORTUNITY_GOAL_TYPES.CHALLENGE) return null
  if (goalType === constants.OPPORTUNITY_GOAL_TYPES.VOLUNTEERING) return null

  return <CommitmentSearchNote>Commitments to be confirmed</CommitmentSearchNote>
}

const determineLabel = ({
  goalType,
  outcomeTarget,
  percentage,
  searchPageFormat,
  skipGoalPrefix
}) => {
  if (!outcomeTarget) return '0%'
  if (percentage) return `${Math.floor(percentage).toLocaleString('en-US')}%`

  const thresholdGoal = Math.floor(outcomeTarget / 10)
  const incrementalGoal = deriveGoalLabel({
    goalType,
    outcomeTarget: thresholdGoal,
    skipGoalPrefix
  })

  const isChallenge = goalType === constants.OPPORTUNITY_GOAL_TYPES.CHALLENGE
  if (isChallenge) return `Be the first to join challenge`

  if (searchPageFormat) return `Be the first to contribute`

  return `Contribute to an initial goal of ${incrementalGoal}`
}

const deriveProgessBarPercentWidth = ({ percentage }) => {
  if (!percentage) return 0

  // 15% is a nice enough width for the text to not look squashed
  if (percentage < 15) return 15

  // Don't bleed over 100%
  if (percentage > 100) return 100

  return percentage
}

const deriveWidthToUse = ({ committedPerc, confirmedPerc }) => {
  if (!committedPerc && !confirmedPerc) return { barType: null, percentage: null }

  if (confirmedPerc) return { barType: 'confirmed', percentage: confirmedPerc }
  if (committedPerc) return { barType: 'committed', percentage: committedPerc }

  return { barType: null, percentage: null }
}

const determineAdditionSign = ({ barType, percentage }) => {
  if (barType === 'confirmed' && percentage) return '+ '

  return null
}

const deriveIndicatorClassName = ({ barType, percentage }) => {
  // no type specified, assume no confirmed progress
  if (!barType) return 'card-progress-bar-is-zero-hover'

  // confirmed and has progress, no hover needed
  if (barType === 'confirmed' && percentage) return null

  // confirmed without any progress, hover needed
  if (barType === 'confirmed') return 'card-progress-bar-is-zero-hover'

  // committed, hover needed
  if (barType === 'committed') return 'card-progress-bar-is-zero-hover'

  // fallback
  return null
}

const buildLabel = ({ goalType, totalCommittedAmount }) => {
  const isMoneyType = goalType === constants.OPPORTUNITY_GOAL_TYPES.MONEY

  if (isMoneyType) return `R${totalCommittedAmount.toLocaleString('en-US')}`

  return totalCommittedAmount
}

const ProgressBarComponent = ({
  committedPerc = 0,
  confirmedPerc = 0,
  fullWidth = false,
  goalType,
  hideProgressBar = false,
  noMargin = false,
  outcomeTarget = 0,
  searchPageFormat = false,
  totalConfirmedAmount = 0,
  totalCommittedAmount = 0
}) => {
  if (hideProgressBar) return <ProgressBar noBorder />

  const isMoneyType = goalType === constants.OPPORTUNITY_GOAL_TYPES.MONEY

  const { barType, percentage } = deriveWidthToUse({ committedPerc, confirmedPerc })
  const widthToUse = deriveProgessBarPercentWidth({ barType, percentage })
  const adjustedPercent = Math.floor(percentage).toLocaleString('en-US')
  const additionSign = determineAdditionSign({ barType, percentage })

  const progressDisplayLabel = determineLabel({
    goalType,
    outcomeTarget,
    percentage,
    searchPageFormat,
    skipGoalPrefix: true
  })

  const totalCommittedLabel = buildLabel({ goalType, totalCommittedAmount })
  const className = deriveIndicatorClassName({ barType, percentage })
  const goal = deriveGoalLabel({ goalType, outcomeTarget })
  const amountRemaining = determineRemainingAmount({
    goalType,
    isMoneyType,
    outcomeTarget,
    totalConfirmedAmount
  })

  if (searchPageFormat) {
    return (
      <ProgressBarWrapper fullWidth={fullWidth} noMargin={noMargin}>
        <ProgressBar fullWidth={fullWidth} percentage={widthToUse} searchPageFormat>
          <ProgressIndicator
            barType={barType}
            className={className}
            fullWidth={fullWidth}
            goalType={goalType}
            percentage={widthToUse}
            searchPageFormat
          >
            <div>{progressDisplayLabel}</div>
          </ProgressIndicator>

          <SearchPageCommittedNote
            committedPerc={committedPerc}
            confirmedPerc={confirmedPerc}
            goalType={goalType}
          />
        </ProgressBar>

        <ProgressBarGoalLabel
          fullWidth={fullWidth}
          isMoneyType={isMoneyType}
          percentage={widthToUse}
          searchPageFormat
        >
          {goal}
        </ProgressBarGoalLabel>
      </ProgressBarWrapper>
    )
  }

  const displayGoalSubStat = !!isMoneyType && !!totalCommittedAmount

  return (
    <ProgressBarWrapper fullWidth={fullWidth} noMargin={noMargin}>
      <ProgressBar fullWidth={fullWidth} percentage={widthToUse}>
        <ProgressIndicator
          barType={barType}
          className={className}
          goalType={goalType}
          percentage={widthToUse}
        >
          {adjustedPercent}%
        </ProgressIndicator>
      </ProgressBar>

      <CommitmentGoalSubStat displayGoalSubStat={displayGoalSubStat} isMoneyType={isMoneyType}>
        {additionSign}
        <Strong>{totalCommittedLabel}</Strong> committed
      </CommitmentGoalSubStat>

      <ConditionalDisplay displayWhen={[amountRemaining]}>
        <ProgressBarGoalLabel
          barType={barType}
          fullWidth={fullWidth}
          displayGoalSubStat={displayGoalSubStat}
          percentage={widthToUse}
        >
          {amountRemaining} to target
        </ProgressBarGoalLabel>
      </ConditionalDisplay>
    </ProgressBarWrapper>
  )
}

export default ProgressBarComponent
