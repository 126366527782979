import axios from 'axios'

// config
import baseUrl from '../config'

// utils

import cleanPayload from 'utils/cleanPayload'

export const fetchMembers = async (payload = {}) => {
  const cleanedPayload = cleanPayload(payload)

  const response = await axios.get(`${baseUrl}/people`, {
    withCredentials: true,
    params: { ...cleanedPayload }
  })

  return response.data
}

export const fetchMember = async id => {
  const response = await axios.get(`${baseUrl}/people/${id}`, { withCredentials: true })

  return response.data
}

export const fetchParticipant = async id => {
  const response = await axios.get(`${baseUrl}/participants/${id}`, { withCredentials: true })

  return response.data
}

export const fetchPublicUser = async ({ userId, username }) => {
  const response = await axios.get(`${baseUrl}/public-people`, {
    withCredentials: true,
    params: { userId, username }
  })
  return response.data
}

export const checkRole = async payload => {
  const { email, id } = payload

  const response = await axios.get(`${baseUrl}/check-role/${email}`, {
    withCredentials: true,
    params: { missionId: id }
  })

  return response.data
}

export const deleteMember = async personId => {
  return await axios.delete(`${baseUrl}/people/${personId}`, {
    withCredentials: true,
    data: { personId }
  })
}

export const removePreferredCategory = async ({ categoryId }) => {
  return await axios.delete(`${baseUrl}/people/remove-pref-category/${categoryId}`, {
    withCredentials: true,
    data: { categoryId }
  })
}

export const sendInvitation = async data => {
  const response = await axios({
    withCredentials: true,
    method: 'post',
    url: `${baseUrl}/invite-member`,
    data
  })

  return response.data
}

export const updateUser = async userData => {
  const response = await axios({
    withCredentials: true,
    method: 'put',
    url: `${baseUrl}/users`,
    data: userData,
    config: { headers: { 'Content-Type': 'multipart/form-data' } }
  })

  return response.data
}

export const updateUserAsGodAdmin = async data => {
  const response = await axios({
    withCredentials: true,
    method: 'put',
    url: `${baseUrl}/update-user/${data.user_id}`,
    data
  })

  return response.data
}
