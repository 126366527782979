import { call, put, takeLatest } from 'redux-saga/effects'
import { navigate } from '@reach/router'

import * as faqsModule from '../modules/faqs'
import * as faqsApi from '../apiModules/faqs'
import logError from 'utils/logger'
import l from 'config/localization'

function* fetchFaq({ payload }) {
  try {
    const response = yield call(faqsApi.fetchFaq, payload)
    yield put(faqsModule.singleFetchSuccess(response))
  } catch (err) {
    if (err.response.status === 401) {
      logError(err)
    } else {
      yield put(faqsModule.singleFetchFailure(err))
    }
  }
}

function* fetchFaqsForOrgAdmin({ payload }) {
  try {
    const response = yield call(faqsApi.fetchFaqsForOrgAdmin, payload)
    yield put(faqsModule.fetchSuccess(response.data))
  } catch (err) {
    logError(err)
  }
}

function* fetchFaqs({ payload }) {
  try {
    const response = yield call(faqsApi.fetchFaqs, payload)
    yield put(faqsModule.fetchSuccess(response.data))
  } catch (err) {
    logError(err)
  }
}

function* createFaq({ payload }) {
  try {
    const orgId = payload.organisation_id || payload.organisationId
    const createdFaq = yield call(faqsApi.createFaq, payload)
    yield put(faqsModule.createFaqSuccess(createdFaq))

    navigate(`/dashboard/${l('ROUTE_ORGS')}/${orgId}/faqs`)
  } catch (err) {
    yield put(faqsModule.createFaqFailure(err.response.data))
  }
}

function* updateFaq({ payload }) {
  try {
    const orgId = payload.organisation_id || payload.organisationId
    const updatedFaq = yield call(faqsApi.updateFaq, payload)
    yield put(faqsModule.updateFaqSuccess(updatedFaq))

    navigate(`/dashboard/${l('ROUTE_ORGS')}/${orgId}/faqs`)
  } catch (err) {
    yield put(faqsModule.updateFaqFailure())
  }
}

function* deleteFaq({ payload }) {
  try {
    const { faqId } = payload
    const orgId = payload.organisation_id || payload.organisationId
    yield call(faqsApi.deleteFaq, { faqId })
    yield put(faqsModule.deleteSuccess(payload))

    navigate(`/dashboard/${l('ROUTE_ORGS')}/${orgId}/faqs`)
  } catch (err) {
    yield put(faqsModule.deleteFailure())
  }
}

export const faqsSagas = [
  takeLatest(faqsModule.CREATE_FAQ, createFaq),
  takeLatest(faqsModule.UPDATE_FAQ, updateFaq),
  takeLatest(faqsModule.DELETE_FAQ, deleteFaq),
  takeLatest(faqsModule.FETCH_FAQ, fetchFaq),
  takeLatest(faqsModule.FETCH_FAQS_FOR_ORG_ADMIN, fetchFaqsForOrgAdmin),
  takeLatest(faqsModule.FETCH_FAQS, fetchFaqs)
]
