import axios from 'axios'

// config
import baseUrl from '../config'

// utils
import cleanPayload from 'utils/cleanPayload'

export const searchOpps = async (payload = {}) => {
  const cleanedPayload = cleanPayload(payload)

  const response = await axios(`${baseUrl}/search/opps`, {
    withCredentials: true,
    method: 'GET',
    responseType: 'json',
    params: { ...cleanedPayload }
  })

  return response.data
}

export const searchOrgs = async (payload = {}) => {
  const cleanedPayload = cleanPayload(payload)

  const response = await axios(`${baseUrl}/search/orgs`, {
    withCredentials: true,
    method: 'GET',
    responseType: 'json',
    params: { ...cleanedPayload }
  })

  return response.data
}
