import React from 'react'

// styles
import { Outer, HiddenInput, Checked, Unchecked, InputWrapper, SubLabel } from './styles'

// Load components synchronously
import ConditionalDisplay from 'components/ConditionalDisplay/ConditionalDisplay'
import { Error, Label, CheckboxLabel } from 'components/InputLabels/InputLabels'

const toggle = (setFieldValue, fieldName, value, disabled) => {
  if (!disabled) {
    setFieldValue(fieldName, !value)
  }
}

const CheckboxInput = ({
  field,
  linkUrl,
  linkText,
  labelHasLink,
  form: { errors, setFieldValue },
  label,
  subLabel,
  disabled,
  size,
  ...props
}) => {
  const displayableLabel = labelHasLink
    ? label.replace(`${linkText}`, `<a style='font-size: 13px' href="${linkUrl}">${linkText}</a>`)
    : label

  return (
    <Outer disabled={disabled}>
      {errors[field.name] && <Error>{errors[field.name]}</Error>}

      {/* if there's a sublabel, show the label here and the sublabel next to the input */}
      {subLabel && <Label disabled={disabled}>{label}</Label>}

      <InputWrapper htmlFor={props.id || props.name}>
        <ConditionalDisplay displayWhen={[field.value]}>
          <ConditionalDisplay displayWhen={[!disabled]}>
            <Checked
              onKeyPress={() => toggle(setFieldValue, field.name, field.value, disabled)}
              tabIndex="0"
              size={size}
            />
          </ConditionalDisplay>

          <ConditionalDisplay displayWhen={[disabled]}>
            <Checked disabled tabIndex="0" size={size} />
          </ConditionalDisplay>
        </ConditionalDisplay>

        <ConditionalDisplay displayWhen={[!field.value]}>
          <ConditionalDisplay displayWhen={[!disabled]}>
            <Unchecked
              onKeyPress={() => toggle(setFieldValue, field.name, field.value, disabled)}
              tabIndex="0"
              size={size}
            />
          </ConditionalDisplay>

          <ConditionalDisplay displayWhen={[disabled]}>
            <Unchecked disabled tabIndex="0" size={size} />
          </ConditionalDisplay>
        </ConditionalDisplay>

        {labelHasLink && (
          <CheckboxLabel
            disabled={disabled}
            dangerouslySetInnerHTML={{ __html: displayableLabel }}
          />
        )}

        {/* if there's a sublabel show it, otherwise fall back to the label */}
        {!labelHasLink && <SubLabel disabled={disabled}>{subLabel || label}</SubLabel>}

        <HiddenInput {...field} {...props} id={props.id || props.name} type="checkbox" />
      </InputWrapper>
    </Outer>
  )
}

export default CheckboxInput
