import { createActions, handleActions } from 'redux-actions'
import UrlBuilder from 'utils/urlBuilder'

export const LOGOUT_SUCCESS = 'authentication/LOGOUT_SUCCESS'

export const CREATE_PERSONAL_GOAL = 'personalGoals/CREATE_PERSONAL_GOAL'
export const CREATE_PERSONAL_GOAL_SUCCESS = 'personalGoals/CREATE_PERSONAL_GOAL_SUCCESS'
export const CREATE_PERSONAL_GOAL_FAILURE = 'personalGoals/CREATE_PERSONAL_GOAL_FAILURE'

export const DELETE_PERSONAL_GOAL = 'personalGoals/DELETE_PERSONAL_GOAL'
export const DELETE_PERSONAL_GOAL_SUCCESS = 'personalGoals/DELETE_PERSONAL_GOAL_SUCCESS'
export const DELETE_PERSONAL_GOAL_FAILURE = 'personalGoals/DELETE_PERSONAL_GOAL_FAILURE'

export const FETCH_PERSONAL_GOALS = 'personalGoals/FETCH_PERSONAL_GOALS'
export const FETCH_PERSONAL_GOALS_SUCCESS = 'personalGoals/FETCH_PERSONAL_GOALS_SUCCESS'
export const FETCH_PERSONAL_GOALS_FAILURE = 'personalGoals/FETCH_PERSONAL_GOALS_FAILURE'

export const FETCH_PERSONAL_GOAL = 'personalGoals/FETCH_PERSONAL_GOAL'
export const FETCH_PERSONAL_GOAL_SUCCESS = 'personalGoals/FETCH_PERSONAL_GOAL_SUCCESS'
export const FETCH_PERSONAL_GOAL_FAILURE = 'personalGoals/FETCH_PERSONAL_GOAL_FAILURE'

export const UPDATE_PERSONAL_GOAL = 'personalGoals/UPDATE_PERSONAL_GOAL'
export const UPDATE_PERSONAL_GOAL_SUCCESS = 'personalGoals/UPDATE_PERSONAL_GOAL_SUCCESS'
export const UPDATE_PERSONAL_GOAL_FAILURE = 'personalGoals/UPDATE_PERSONAL_GOAL_FAILURE'

export const {
  personalGoals: {
    createPersonalGoal,
    createPersonalGoalSuccess,
    createPersonalGoalFailure,
    updatePersonalGoal,
    updatePersonalGoalSuccess,
    updatePersonalGoalFailure,
    fetchPersonalGoal,
    fetchPersonalGoalSuccess,
    fetchPersonalGoalFailure,
    deletePersonalGoal,
    deletePersonalGoalSuccess,
    deletePersonalGoalFailure,
    fetchPersonalGoals,
    fetchPersonalGoalsSuccess,
    fetchPersonalGoalsFailure
  }
} = createActions({
  [CREATE_PERSONAL_GOAL]: undefined,
  [CREATE_PERSONAL_GOAL_SUCCESS]: undefined,
  [CREATE_PERSONAL_GOAL_FAILURE]: undefined,
  [UPDATE_PERSONAL_GOAL]: undefined,
  [UPDATE_PERSONAL_GOAL_SUCCESS]: undefined,
  [UPDATE_PERSONAL_GOAL_FAILURE]: undefined,
  [DELETE_PERSONAL_GOAL]: undefined,
  [DELETE_PERSONAL_GOAL_SUCCESS]: undefined,
  [DELETE_PERSONAL_GOAL_FAILURE]: undefined,
  [FETCH_PERSONAL_GOALS]: undefined,
  [FETCH_PERSONAL_GOALS_SUCCESS]: undefined,
  [FETCH_PERSONAL_GOALS_FAILURE]: undefined,
  [FETCH_PERSONAL_GOAL]: undefined,
  [FETCH_PERSONAL_GOAL_SUCCESS]: undefined,
  [FETCH_PERSONAL_GOAL_FAILURE]: undefined
})

const initialPaginatedData = {
  pageCount: 0,
  personalGoalsCount: 0,
  personalGoals: []
}

const initialState = {
  data: [],
  submissionWasSuccess: null,
  isLoading: false,
  hasError: false,
  singlePersonalGoalData: {},
  paginatedPersonalGoalsData: initialPaginatedData,
  filteredData: [],
  isSubmitting: false,
  isSuccess: false
}

export default handleActions(
  {
    [FETCH_PERSONAL_GOAL]: state => ({
      ...state,
      isLoading: false,
      isSubmitting: true
    }),
    [FETCH_PERSONAL_GOAL_SUCCESS]: (state, { payload }) => {
      return {
        ...state,
        singlePersonalGoalData: payload,
        personalGoalId: payload.id,
        isLoading: false,
        isSubmitting: false,
        hasError: false
      }
    },
    [FETCH_PERSONAL_GOAL_FAILURE]: (state, { payload }) => ({
      ...state,
      ...payload,
      data: payload.data,
      isLoading: false,
      isSubmitting: false,
      hasError: true
    }),

    [FETCH_PERSONAL_GOALS]: state => ({ ...state, isLoading: true }),
    [FETCH_PERSONAL_GOALS_SUCCESS]: (state, { payload }) => {
      const newState = {
        ...state,
        isLoading: false,
        hasSubmitted: false,
        isSuccess: true,
        data: payload.personalGoals,
        errorMessage: null,
        hasError: false
      }

      newState.paginatedPersonalGoalsData.personalGoalsPageCount =
        payload.personalGoalsPageCount || payload.pageCount || 0
      newState.paginatedPersonalGoalsData.personalGoalsCount = payload.personalGoalsCount || 0
      newState.paginatedPersonalGoalsData.personalGoals = payload.personalGoals || []

      return newState
    },
    [FETCH_PERSONAL_GOALS_FAILURE]: state => ({
      ...state,
      isLoading: false,
      hasError: true,
      isSuccess: false
    }),
    [CREATE_PERSONAL_GOAL]: state => ({
      ...state,
      isLoading: false,
      isSubmitting: true,
      submissionWasSuccess: null
    }),
    [CREATE_PERSONAL_GOAL_SUCCESS]: (state, { payload }) => {
      UrlBuilder.displayUpdateSuccessMessage()

      return {
        ...state,
        singlePersonalGoalData: payload.data,
        isLoading: false,
        isSubmitting: false,
        submissionWasSuccess: true,
        hasError: false
      }
    },
    [CREATE_PERSONAL_GOAL_FAILURE]: (state, { payload }) => ({
      ...state,
      ...payload,
      data: payload.data,
      isLoading: false,
      isSubmitting: false,
      submissionWasSuccess: false,
      hasError: true
    }),
    [UPDATE_PERSONAL_GOAL]: (state, { payload }) => {
      return { ...state, singlePersonalGoalData: payload, isLoading: false, isSubmitting: true }
    },
    [UPDATE_PERSONAL_GOAL_SUCCESS]: (state, { payload }) => {
      UrlBuilder.displayUpdateSuccessMessage()

      const data = state.data.map(personalGoal => {
        if (personalGoal.id !== payload.id) return personalGoal
        return { ...personalGoal, isHiddenPersonalGoal: true }
      })

      return { ...state, data, isLoading: false, singlePersonalGoalData: {}, isSubmitting: false }
    },
    [UPDATE_PERSONAL_GOAL_FAILURE]: state => ({
      ...state,
      singlePersonalGoalData: {},
      isLoading: false,
      isSubmitting: false
    }),
    [DELETE_PERSONAL_GOAL]: state => ({ ...state, isLoading: true, hasSubmitted: true }),
    [DELETE_PERSONAL_GOAL_SUCCESS]: (state, { payload }) => {
      return {
        ...state,
        isLoading: false,
        hasSubmitted: true,
        data: state.data.filter(o => o.id !== payload.personalGoalId),
        personalGoalId: null
      }
    },
    [DELETE_PERSONAL_GOAL_FAILURE]: () => ({
      isLoading: false,
      hasSubmitted: true,
      personalGoalId: null
    }),
    [LOGOUT_SUCCESS]: () => initialState
  },
  initialState
)
