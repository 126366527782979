import React from 'react'
import styled, { css } from 'styled-components'
import Link from 'components/Link'
import { useMedia } from 'react-use'

// utils and config
import l, { isDgmt, isSef, isSefEmployee } from 'config/localization'
import th from 'utils/themeHelper'
import isLoggedIn from 'utils/auth'

// assets
import dgmtLogo from 'assets/images/dgmt_logo_blue.jpg'
import bpIcon from 'assets/images/bp-logo.png'

// icons
import { FacebookF } from '@styled-icons/fa-brands/FacebookF'
import { Instagram } from '@styled-icons/fa-brands/Instagram'
import { Whatsapp } from '@styled-icons/fa-brands/Whatsapp'

const Text = css`
  color: ${props => props.color || '#ffffff'};
  font-family: ${th('fonts.bold')};
  font-size: ${props => `${props.size}px` || 'initial'};
  line-height: 1.2em;
  margin: 0 0 0.25em;
  padding: 0;
`

export const FooterEmail = styled.p`
  ${Text}
  font-family: ${th('fonts.regular')};
  font-size: 18px;
  line-height: 1.35em;
  text-align: left;

  @media screen and (min-width: 769px) {
    text-align: right;
  }
`

const FooterLink = styled(Link)`
  display: block;
  max-width: max-content;
`

const DgmtFooterLink = styled.a`
  cursor: pointer;
  display: block;
  margin: 0 auto;
  max-width: 100%;
  text-align: center;

  & > p {
    text-decoration: underline;
  }
`

const IconStyle = css`
  background-color: #f3f3f3;
  border-radius: 50%;
  color: ${th('text.dark')};
  height: 35px;
  margin-right: 10px;
  padding: 6px;
  width: 35px;

  @media screen and (min-width: 769px) {
    margin-left: 10px;
    margin-right: 0;
  }
`

export const BPLogo = styled.img`
  height: 50px;
  margin: 10px;
  width: 35px;
`

export const DgmtLogo = styled.img`
  height: 50px;
  margin: 10px;
  width: 50px;
`

export const FacebookIcon = styled(FacebookF)`
  ${IconStyle}
`

export const WhatsAppIcon = styled(Whatsapp)`
  ${IconStyle}
`

export const InstagramIcon = styled(Instagram)`
  ${IconStyle}
`

export const Divider = styled.div`
  border-top: solid 2px #f3f3f3;
  height: ${props => (props.height ? `${props.height}px` : '16px')};
  max-width: 1260px;
  width: 100%;
`

export const Spacer = styled.div`
  height: ${props => (props.height ? `${props.height}px` : '16px')};
`

export const FooterSectionContainer = styled.div`
  display: flex;
  flex-flow: column wrap;
  justify-content: center;
  margin: 30px;
  width: 100%;

  @media screen and (min-width: 769px) {
    flex-direction: row;
    margin: ${props => (props.padded ? '60px 30px' : '30px')};
    max-width: 1300px;
    padding: 0 20px;
  }
`

export const FooterSection = styled.div`
  align-items: center;
  background-color: ${th('text.dark')};
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px -3px;
  color: #ffffff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 10px 20px;

  @media screen and (min-width: 769px) {
    padding: 0 60px 30px;
  }
`

export const FooterSectionCell = styled.div`
  flex: 1 1 auto;

  margin: 0;
  padding: 8px 0;
  text-align: left;

  @media screen and (min-width: 769px) {
    flex-basis: unset;
    text-align: ${props => (props.center ? 'center' : props.rightAlign ? 'right' : 'left')};
  }

  a:hover {
    color: white;
    text-decoration: underline;
  }
`

export const FooterItem = styled.p`
  ${Text}
  display: block;
  font-family: ${th('fonts.regular')};
  font-size: 16px;
  line-height: 1.35em;
  text-align: center;

  @media screen and (min-width: 769px) {
    font-size: 18px;
    text-align: ${props => (props.center ? 'center' : 'left')};
  }

  a {
    color: inherit;
    font-size: inherit;
  }
`

export const FooterText = styled.p`
  ${Text}
  font-family: ${th('fonts.regular')};
  font-size: 18px;
  line-height: 1.35em;
  text-align: center;

  a {
    color: inherit;
    font-size: inherit;
    text-decoration: underline;
  }
`

export const FooterSectionCellHeader = styled.p`
  ${Text}
  font-family: ${th('fonts.bold')};
  font-size: 18px;
  line-height: 1.35em;
  margin-bottom: 24px;
  text-align: ${props => (props.center ? 'center' : 'left')};
  
  a {
    color: inherit;
    font-size: inherit;
  }
  
  @media screen and (min-width: 769px) {
    text-align: ${props => (props.right ? 'right' : props.center ? 'center' : 'left')};
  }
`

export const FlexRow = styled.div`
  display: flex;
  align-items: center;
`

export const PipeSeparator = styled.div`
  margin: 0 4px;
  color: white;
  @media screen and (max-width: 600px) {
    display: none;
  }
`
const Footer = () => {
  const isWide = useMedia('(min-width: 1020px)')
  const loggedIn = isLoggedIn()

  if (isDgmt) {
    return (
      <FooterSection>
        <FooterSectionContainer>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column'
            }}
          >
            <FooterSectionCellHeader center>TAKE ACTION</FooterSectionCellHeader>

            <DgmtFooterLink href={loggedIn ? '/new-website' : `/new-pbo`}>
              <FooterItem>{loggedIn ? 'Submit a website' : 'Apply as PBO'}</FooterItem>
            </DgmtFooterLink>

            <DgmtFooterLink href={`/discover`}>
              <FooterItem>Discover zero-rated websites</FooterItem>
            </DgmtFooterLink>

            <DgmtFooterLink href={`/login`}>
              <FooterItem>Login</FooterItem>
            </DgmtFooterLink>
          </div>
        </FooterSectionContainer>

        <Divider />

        <DgmtLogo src={dgmtLogo} alt="DGMT footer logo" />

        <FooterText>
          © 2023 Brownie Points (Pty) Ltd{isWide ? ' · ' : <br />}
          <a href="/privacy">Privacy</a>
          {isWide ? ' · ' : <br />}
          <a href="/terms">Terms</a>
        </FooterText>
      </FooterSection>
    )
  }

  if (isSef || isSefEmployee) {
    return (
      <FooterSection topPadding>
        <BPLogo src={bpIcon} alt="Brownie Points footer logo" />

        <FooterText>
          © 2023 Brownie Points (Pty) Ltd{isWide ? ' · ' : <br />}
          <a href="/privacy">Privacy</a>
          {isWide ? ' · ' : <br />}
          <a href="/terms">Terms</a>
        </FooterText>
      </FooterSection>
    )
  }

  return (
    <FooterSection>
      <FooterSectionContainer>
        <FooterSectionCell>
          <FooterSectionCellHeader>TAKE ACTION</FooterSectionCellHeader>

          <FooterLink
            to={loggedIn ? `/dashboard/stories/new?eventType=GoodDeed&chooseTemplate` : `/feed`}
          >
            <FooterItem>Share a story</FooterItem>
          </FooterLink>

          {/* <FooterLink to={`/search/nonprofits`}>
            <FooterItem>Become a Champion</FooterItem>
          </FooterLink> */}

          <FooterLink to={`/search/${l('ROUTE_OPPS')}?gt=Challenges`}>
            <FooterItem>Join challenges</FooterItem>
          </FooterLink>

          <FooterLink to={`/search/${l('ROUTE_OPPS')}?gt=Money`}>
            <FooterItem>Donate money</FooterItem>
          </FooterLink>

          <FooterLink to={`/search/${l('ROUTE_OPPS')}?gt=Goods`}>
            <FooterItem>Donate goods</FooterItem>
          </FooterLink>

          <FooterLink to={`/search/${l('ROUTE_OPPS')}?gt=Volunteering`}>
            <FooterItem>Volunteer</FooterItem>
          </FooterLink>
        </FooterSectionCell>

        <FooterSectionCell>
          <FooterSectionCellHeader>COMMUNITY</FooterSectionCellHeader>

          <FooterLink to={`/feed`}>
            <FooterItem>Explore stories</FooterItem>
          </FooterLink>

          <FooterLink to={`/search/${l('ROUTE_CAMPS')}`}>
            <FooterItem>Discover {l('CAMPS')}</FooterItem>
          </FooterLink>

          <FooterLink to={`/search/challenges`}>
            <FooterItem>Search challenges</FooterItem>
          </FooterLink>

          <FooterLink to={`/search/${l('ROUTE_OPPS')}`}>
            <FooterItem>Search {l('OPPS')}</FooterItem>
          </FooterLink>

          <FooterLink to={`/search/nonprofits`}>
            <FooterItem>Search nonprofits</FooterItem>
          </FooterLink>

          <FooterLink to={`/search/companies`}>
            <FooterItem>Search companies</FooterItem>
          </FooterLink>

          <FooterLink to={`/extras`}>
            <FooterItem>Extras</FooterItem>
          </FooterLink>
        </FooterSectionCell>

        <FooterSectionCell>
          <FooterSectionCellHeader>ABOUT</FooterSectionCellHeader>

          <FooterLink to="/about-us">
            <FooterItem>Our Story</FooterItem>
          </FooterLink>

          <FooterLink to={'https://blog.browniepoints.africa'}>
            <FooterItem>Blog</FooterItem>
          </FooterLink>

          <FooterLink to="http://browniepoints.freshdesk.com/">
            <FooterItem>Help</FooterItem>
          </FooterLink>
        </FooterSectionCell>

        <FooterSectionCell>
          <FooterSectionCellHeader>SOLUTIONS</FooterSectionCellHeader>

          <FooterLink to="/pricing/nonprofits">
            <FooterItem>Nonprofits</FooterItem>
          </FooterLink>

          <FooterLink to="/pricing/business">
            <FooterItem>Business</FooterItem>
          </FooterLink>

          <FooterLink to="/pricing/education">
            <FooterItem>Education</FooterItem>
          </FooterLink>
        </FooterSectionCell>

        <FooterSectionCell rightAlign>
          <FooterSectionCellHeader right>CONNECT</FooterSectionCellHeader>

          <a href="https://www.facebook.com/browniepointsSA" target="_blank">
            <FacebookIcon />
          </a>

          <a
            href="https://api.whatsapp.com/send?text=%0A%0Ahttps%3A%2F%2Fbrowniepoints.africa%2F"
            target="_blank"
          >
            <WhatsAppIcon />
          </a>

          <a href="https://www.instagram.com/browniepointssa/" target="_blank">
            <InstagramIcon />
          </a>

          <Spacer />

          <a href="mailto:hi@browniepoints.africa">
            <FooterEmail>hi@browniepoints.africa</FooterEmail>
          </a>
        </FooterSectionCell>
      </FooterSectionContainer>

      <Divider />

      <BPLogo src={bpIcon} alt="Brownie Points footer logo" />

      <FooterText>
        © 2023 Brownie Points (Pty) Ltd{isWide ? ' · ' : <br />}
        <a href="/privacy">Privacy</a>
        {isWide ? ' · ' : <br />}
        <a href="/terms">Terms</a>
      </FooterText>
    </FooterSection>
  )
}

export default Footer
