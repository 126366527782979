import styled, { css, keyframes } from 'styled-components'

const shimmerAnimation = keyframes`
  0%{
      background-position: -490px 0
  }
  100%{
      background-position: 490px 0
  }
}
`

const placeHolderShimmer = css`
  animation-duration: 1s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: ${shimmerAnimation};
  animation-timing-function: linear;
`

export const DashboardWrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`

export const ContentContainer = styled.div`
  height: calc(100vh - 150px);
  background: #f6f7f8;
  background-image: -webkit-gradient(
    linear,
    left center,
    right center,
    from(#f6f7f8),
    color-stop(0.2, #edeef1),
    color-stop(0.4, #f6f7f8),
    to(#f6f7f8)
  );
  background-image: -webkit-linear-gradient(
    left,
    #f6f7f8 0%,
    #edeef1 20%,
    #f6f7f8 40%,
    #f6f7f8 100%
  );
  background-size: 980px 898px;
  position: relative;
  ${placeHolderShimmer};

  @media screen and (max-width: 769px) {
    flex-direction: column;
  }
`
