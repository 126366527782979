import { call, put, takeLatest } from 'redux-saga/effects'
import * as teamsApi from '../apiModules/teams'
import * as teamsModule from '../modules/teams'
import logError from '../../utils/logger'

function* fetchSquads({ payload }) {
  try {
    const response = yield call(teamsApi.fetchSquads, payload)
    yield put(teamsModule.fetchSquadsSuccess(response))
  } catch (err) {
    logError(err)
    yield put(teamsModule.fetchSquadsFailure(err))
  }
}

function* fetchSquad({ payload }) {
  try {
    const response = yield call(teamsApi.fetchSquad, payload)
    yield put(teamsModule.fetchSquadSuccess(response))
  } catch (err) {
    if (err.response.status === 401) {
      logError(err)
    } else {
      yield put(teamsModule.fetchSquadFailure(err))
    }
  }
}

function* createSquad({ payload }) {
  try {
    const createdSquad = yield call(teamsApi.createSquad, payload)
    yield put(teamsModule.createSquadSuccess(createdSquad))
  } catch (err) {
    yield put(teamsModule.createSquadFailure(err.response.data))
  }
}

function* updateSquad({ payload }) {
  try {
    const updatedSquad = yield call(teamsApi.updateSquad, payload)
    yield put(teamsModule.updateSquadSuccess(updatedSquad))
  } catch (err) {
    yield put(teamsModule.updateSquadFailure())
  }
}

// teams

function* fetchTeams({ payload }) {
  try {
    const response = yield call(teamsApi.fetchTeams, payload)
    yield put(teamsModule.fetchTeamsSuccess(response))
  } catch (err) {
    logError(err)
    yield put(teamsModule.fetchTeamsFailure(err))
  }
}

function* fetchTeam({ payload }) {
  try {
    const response = yield call(teamsApi.fetchTeam, payload)
    yield put(teamsModule.singleFetchSuccess(response))
  } catch (err) {
    if (err.response.status === 401) {
      logError(err)
    } else {
      yield put(teamsModule.singleFetchFailure(err))
    }
  }
}

function* createTeam({ payload }) {
  try {
    const createdTeam = yield call(teamsApi.createTeam, payload)
    yield put(teamsModule.createSuccess(createdTeam))
  } catch (err) {
    yield put(teamsModule.createFailure(err.response.data))
  }
}

function* updateTeam({ payload }) {
  try {
    const updatedTeam = yield call(teamsApi.updateTeam, payload)
    yield put(teamsModule.updateSuccess(updatedTeam))
  } catch (err) {
    yield put(teamsModule.updateFailure())
  }
}

export const teamSagas = [
  // squads
  takeLatest(teamsModule.CREATE_SQUAD, createSquad),
  takeLatest(teamsModule.UPDATE_SQUAD, updateSquad),
  takeLatest(teamsModule.FETCH_SQUADS, fetchSquads),
  takeLatest(teamsModule.FETCH_SQUAD, fetchSquad),
  // teams
  takeLatest(teamsModule.CREATE_TEAM, createTeam),
  takeLatest(teamsModule.UPDATE_TEAM, updateTeam),
  takeLatest(teamsModule.FETCH_TEAMS, fetchTeams),
  takeLatest(teamsModule.FETCH_TEAM, fetchTeam)
]
