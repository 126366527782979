import axios from 'axios'

// redux
import baseUrl from '../config'

export const fetchSquads = async params => {
  const response = await axios.get(`${baseUrl}/squads`, { withCredentials: true, params })
  return response.data
}

export const fetchSquad = async id => {
  const response = await axios.get(`${baseUrl}/squads/${id}`, { withCredentials: true })
  return response.data
}

export const createSquad = async data => {
  const response = await axios({
    withCredentials: true,
    method: 'post',
    url: `${baseUrl}/squads`,
    data
  })

  return response.data
}

export const updateSquad = async data => {
  const response = await axios({
    withCredentials: true,
    method: 'put',
    url: `${baseUrl}/squads/${data.id}`,
    data
  })

  return response.data
}

export const fetchTeams = async params => {
  const response = await axios.get(`${baseUrl}/teams`, { withCredentials: true, params })
  return response.data
}

export const fetchTeam = async id => {
  const response = await axios.get(`${baseUrl}/teams/${id}`, { withCredentials: true })
  return response.data
}

export const createTeam = async data => {
  const response = await axios({
    withCredentials: true,
    method: 'post',
    url: `${baseUrl}/teams`,
    data
  })

  return response.data
}

export const updateTeam = async data => {
  const response = await axios({
    withCredentials: true,
    method: 'put',
    url: `${baseUrl}/teams/${data.id}`,
    data
  })

  return response.data
}
