import { createActions, handleActions } from 'redux-actions'

// helpers
import { loadingError, startLoading } from './_helpers'

export const FETCH_STATS = 'stats/FETCH_STATS'
export const FETCH_FAILURE = 'stats/FETCH_FAILURE'
export const FETCH_SUCCESS = 'stats/FETCH_SUCCESS'
export const LOGOUT_SUCCESS = 'authentication/LOGOUT_SUCCESS'

export const {
  stats: { fetchStats, fetchSuccess, fetchFailure }
} = createActions({
  [FETCH_STATS]: undefined,
  [FETCH_FAILURE]: undefined,
  [FETCH_SUCCESS]: undefined
})

const initialState = {
  data: [],
  isLoading: false,
  isError: false
}

export default handleActions(
  {
    [FETCH_STATS]: startLoading,
    [FETCH_FAILURE]: loadingError,
    [FETCH_SUCCESS]: (state, { payload }) => ({
      ...state,
      data: payload,
      isLoading: false,
      isError: false
    }),
    [LOGOUT_SUCCESS]: () => initialState
  },
  initialState
)
