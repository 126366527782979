import { call, put, takeLatest } from 'redux-saga/effects'
import { navigate } from '@reach/router'

// redux
import * as organisationsApi from '../apiModules/organisations'
import * as authApi from '../apiModules/authentication'
import * as authenticationModule from '../modules/authentication'
import * as organisationsModule from '../modules/organisations'

// utils
import UrlBuilder from 'utils/urlBuilder'
import { updateOrgDetails } from 'utils/localStorageManager'

function* fetchOrganisations({ payload }) {
  try {
    const response = yield call(organisationsApi.fetchOrganisations, payload)
    yield put(organisationsModule.fetchSuccess({ ...response, ...payload }))
  } catch (err) {
    yield put(organisationsModule.fetchFailure(err))
  }
}

function* fetchOwnOrganisations({ payload = {} }) {
  try {
    if (payload.onlySefPartners) {
      const response = yield call(organisationsApi.fetchSefOrganisations, payload)
      yield put(organisationsModule.fetchOwnOrganisationsSuccess(response))
    } else {
      const response = yield call(organisationsApi.fetchOwnOrganisations, payload)
      yield put(organisationsModule.fetchOwnOrganisationsSuccess(response))
    }
  } catch (err) {
    yield put(organisationsModule.fetchOwnOrganisationsFailure(err))
  }
}

function* fetchOrganisation({ payload }) {
  try {
    const response = yield call(organisationsApi.fetchOrganisation, payload)
    yield put(organisationsModule.singleFetchSuccess(response))
  } catch (err) {
    yield put(
      organisationsModule.singleFetchFailure({
        hasError: true,
        errorCode: `${err.response.status}`
      })
    )
  }
}

function* fetchOrgForPublicPage({ payload }) {
  try {
    const response = yield call(organisationsApi.fetchOrgForPublicPage, payload)
    yield put(organisationsModule.singleFetchSuccess(response))
  } catch (err) {
    yield put(organisationsModule.singleFetchFailure(err))
  }
}

function* fetchOrgGoalsForOrgAdmin({ payload }) {
  try {
    const response = yield call(organisationsApi.fetchOrgGoalsForOrgAdmin, payload)
    yield put(organisationsModule.fetchOrgGoalsForOrgAdminSuccess(response))
  } catch (err) {
    yield put(organisationsModule.fetchOrgGoalsForOrgAdminFailure(err))
  }
}

function* fetchOrgGoal({ payload }) {
  try {
    const response = yield call(organisationsApi.fetchOrgGoal, payload)
    yield put(organisationsModule.fetchOrgGoalSuccess(response))
  } catch (err) {
    yield put(organisationsModule.fetchOrgGoalFailure(err))
  }
}

function* createOrgGoal({ payload }) {
  try {
    const formData = new FormData()

    Object.keys(payload).map(prop => {
      const value = payload[prop]
      if (value) {
        formData.append(prop, value)
      }
    })

    const result = yield call(organisationsApi.createOrgGoal, formData)
    yield put(organisationsModule.createOrgGoalSuccess({ ...payload, ...result }))

    const orgId = payload.organisation_id
    navigate(`/dashboard/organisations/${orgId}/goals`)
  } catch (err) {
    yield put(organisationsModule.createOrgGoalFailure())
  }
}

function* updateOrgGoal({ payload }) {
  try {
    const formData = new FormData()

    Object.keys(payload).map(prop => {
      const value = payload[prop]
      if (value) {
        formData.append(prop, value)
      }
    })

    const result = yield call(organisationsApi.updateOrgGoal, formData)
    yield put(organisationsModule.updateOrgGoalSuccess({ ...payload, ...result }))

    const orgId = payload.organisation_id
    navigate(`/dashboard/organisations/${orgId}/goals`)
  } catch (err) {
    yield put(organisationsModule.updateOrgGoalFailure())
  }
}

function* deleteOrgGoal({ payload }) {
  try {
    yield call(organisationsApi.deleteOrgGoal, payload)
    yield put(organisationsModule.deleteSuccess(payload))
    UrlBuilder.displayDeleteSuccessMessage()
  } catch (err) {
    yield put(organisationsModule.deleteFailure())
  }
}

function* fetchOrgGoalMilestone({ payload }) {
  try {
    const response = yield call(organisationsApi.fetchOrgGoalMilestone, payload)
    yield put(organisationsModule.fetchOrgGoalMilestoneSuccess(response))
  } catch (err) {
    yield put(organisationsModule.fetchOrgGoalMilestoneFailure(err))
  }
}

function* createOrgGoalMilestone({ payload }) {
  try {
    const formData = new FormData()

    Object.keys(payload).map(prop => {
      const value = payload[prop]
      if (value) {
        formData.append(prop, value)
      }
    })

    const result = yield call(organisationsApi.createOrgGoalMilestone, formData)
    yield put(organisationsModule.createOrgGoalMilestoneSuccess({ ...payload, ...result }))

    const orgId = payload.organisation_id
    navigate(`/dashboard/organisations/${orgId}/goals`)
  } catch (err) {
    yield put(organisationsModule.createOrgGoalMilestoneFailure())
  }
}

function* updateOrgGoalMilestone({ payload }) {
  try {
    const formData = new FormData()

    Object.keys(payload).map(prop => {
      const value = payload[prop]
      if (value) {
        formData.append(prop, value)
      }
    })

    const result = yield call(organisationsApi.updateOrgGoalMilestone, formData)
    yield put(organisationsModule.updateOrgGoalMilestoneSuccess({ ...payload, ...result }))

    const orgId = payload.organisation_id
    navigate(`/dashboard/organisations/${orgId}/goals`)
  } catch (err) {
    yield put(organisationsModule.updateOrgGoalMilestoneFailure())
  }
}

function* deleteOrgGoalMilestone({ payload }) {
  try {
    yield call(organisationsApi.deleteOrgGoalMilestone, payload)
    yield put(organisationsModule.deleteSuccess(payload))
    UrlBuilder.displayDeleteSuccessMessage()
  } catch (err) {
    yield put(organisationsModule.deleteFailure())
  }
}

function* createOrganisation({ payload }) {
  try {
    const formData = new FormData()
    const { urlAction } = payload

    Object.keys(payload).map(prop => {
      const value = payload[prop]
      if (value) {
        formData.append(prop, value)
      }
    })

    const response = yield call(organisationsApi.createOrganisation, formData)
    yield put(organisationsModule.createSuccess(response))

    const authResponse = yield call(authApi.fetchMyRoles)
    yield put(authenticationModule.fetchMyRolesSuccess(authResponse))
    yield updateOrgDetails(authResponse.orgDetails)

    if (urlAction === 'premiumPlan') {
      navigate(`/process-payment?bill=annual&orgId=${response.id}`)
    }
  } catch (err) {
    yield put(organisationsModule.createFailure(err.response.data))
  }
}

function* addOrgAdmin({ payload }) {
  try {
    const result = yield call(organisationsApi.addOrgAdmin, payload)
    yield put(organisationsModule.addOrgAdminSuccess({ ...payload, ...result }))
  } catch (err) {
    yield put(organisationsModule.addOrgAdminFailure())
  }
}

function* requestVerification({ payload }) {
  try {
    const formData = new FormData()

    Object.keys(payload).map(prop => {
      const value = payload[prop]
      if (value) {
        formData.append(prop, value)
      }
    })

    yield call(organisationsApi.requestVerification, formData)
    yield put(organisationsModule.requestVerificationSuccess(payload))
  } catch (err) {
    yield put(organisationsModule.requestVerificationFailure())
  }
}

function* updateOrgAdmin({ payload }) {
  try {
    yield call(organisationsApi.updateOrgAdmin, payload)
    yield put(organisationsModule.updateOrgAdminSuccess(payload))
  } catch (err) {
    yield put(organisationsModule.updateOrgAdminFailure())
  }
}

function* manageOrgMemberRequest({ payload }) {
  try {
    yield call(organisationsApi.manageOrgMemberRequest, payload)
    yield put(organisationsModule.manageOrgMemberRequestSuccess(payload))
  } catch (err) {
    yield put(organisationsModule.manageOrgMemberRequestFailure())
  }
}

function* manageOrgMemberInvitation({ payload }) {
  try {
    yield call(organisationsApi.manageOrgMemberInvitation, payload)
    yield put(organisationsModule.manageOrgMemberInvitationSuccess(payload))

    // re-fetch org roles
    const authResponse = yield call(authApi.fetchMyRoles)
    yield put(authenticationModule.fetchMyRolesSuccess(authResponse))
    yield updateOrgDetails(authResponse.orgDetails)

    const newUrl = UrlBuilder.url(window.location, { inviteStatus: payload.invitation_status })
    navigate(newUrl)
  } catch (err) {
    yield put(organisationsModule.manageOrgMemberInvitationFailure())
  }
}

function* removeOrgAdmin({ payload }) {
  try {
    const { recordId, entityId, memberToInviteRescindId, memberToRequestRemoveId } = payload

    yield call(organisationsApi.removeOrgAdmin, {
      adminId: recordId,
      memberToInviteRescindId,
      memberToRequestRemoveId,
      organisationId: entityId
    })
    yield put(organisationsModule.removeOrgAdminSuccess(payload))
  } catch (err) {
    yield put(organisationsModule.removeOrgAdminFailure())
  }
}

function* updateBillingInfo({ payload }) {
  try {
    yield call(organisationsApi.updateBillingInfo, payload)
    if (payload.urlAction === 'premiumPlan') {
      navigate(`/process-payment?bill=annual&orgId=${payload.id}`)
      window.location.hash = ''
      window.location.reload()
    } else {
      window.location.reload()
    }
  } catch (err) {
    yield put(organisationsModule.updateFailure())
  }
}

function* updateOrganisation({ payload }) {
  try {
    const formData = new FormData()
    const { urlAction } = payload

    Object.keys(payload).map(prop => {
      const value = payload[prop]
      if (value && prop !== 'orgNames') {
        formData.append(prop, value)
      }
    })

    const updatedOrg = yield call(organisationsApi.updateOrganisation, formData)
    yield put(organisationsModule.updateSuccess(updatedOrg))

    // re-fetch orgs because there's a bug and data isn't updating properly on the orgs index
    const response = yield call(organisationsApi.fetchOrganisations)
    yield put(organisationsModule.fetchSuccess(response))

    const authResponse = yield call(authApi.fetchMyRoles)
    yield put(authenticationModule.fetchMyRolesSuccess(authResponse))
    yield updateOrgDetails(authResponse.orgDetails)

    if (urlAction === 'premiumPlan') {
      return navigate(`/process-payment?bill=annual&orgId=${payload.id}`)
    }

    if (updatedOrg.windowHash) {
      window.location.hash = updatedOrg.windowHash
    }
  } catch (err) {
    yield put(organisationsModule.updateFailure())
  }
}

function* joinOrganisationRequest({ payload }) {
  try {
    yield call(organisationsApi.joinOrganisationRequest, payload)
    yield put(organisationsModule.joinOrganisationRequestSuccess(payload))
  } catch (err) {
    yield put(organisationsModule.joinOrganisationRequestFailure())
  }
}

function* claimOrganisation({ payload }) {
  try {
    yield call(organisationsApi.claimOrganisation, payload)
    yield put(organisationsModule.claimOrganisationSuccess(payload))
  } catch (err) {
    yield put(organisationsModule.claimOrganisationFailure())
  }
}

function* claimOrganisationRequest({ payload }) {
  try {
    yield call(organisationsApi.claimOrganisationRequest, payload)
    yield put(organisationsModule.claimOrganisationRequestSuccess(payload))
  } catch (err) {
    yield put(organisationsModule.claimOrganisationRequestFailure())
  }
}

function* deleteOrganisation({ payload }) {
  try {
    yield call(organisationsApi.deleteOrganisation, payload)
    yield put(organisationsModule.deleteSuccess(payload))
    UrlBuilder.displayDeleteSuccessMessage()
  } catch (err) {
    yield put(organisationsModule.deleteFailure())
  }
}

export const organisationsSagas = [
  takeLatest(organisationsModule.CREATE_ORGANISATION, createOrganisation),
  takeLatest(organisationsModule.UPDATE_ORGANISATION, updateOrganisation),
  takeLatest(organisationsModule.UPDATE_BILLING_INFO, updateBillingInfo),
  takeLatest(organisationsModule.REQUEST_VERIFICATION, requestVerification),
  takeLatest(organisationsModule.JOIN_ORGANISATION_REQUEST, joinOrganisationRequest),
  takeLatest(organisationsModule.CLAIM_ORGANISATION, claimOrganisation),
  takeLatest(organisationsModule.CLAIM_ORGANISATION_REQUEST, claimOrganisationRequest),
  takeLatest(organisationsModule.DELETE_ORGANISATION, deleteOrganisation),
  takeLatest(organisationsModule.FETCH_ORGANISATIONS, fetchOrganisations),
  takeLatest(organisationsModule.FETCH_OWN_ORGANISATIONS, fetchOwnOrganisations),
  takeLatest(organisationsModule.FETCH_ORG_FOR_PUBLIC_PAGE, fetchOrgForPublicPage),
  takeLatest(organisationsModule.FETCH_ORG_GOALS_FOR_ORG_ADMIN, fetchOrgGoalsForOrgAdmin),
  takeLatest(organisationsModule.CREATE_ORG_GOAL, createOrgGoal),
  takeLatest(organisationsModule.DELETE_ORG_GOAL, deleteOrgGoal),
  takeLatest(organisationsModule.UPDATE_ORG_GOAL, updateOrgGoal),
  takeLatest(organisationsModule.FETCH_ORG_GOAL, fetchOrgGoal),
  takeLatest(organisationsModule.CREATE_ORG_GOAL_MILESTONE, createOrgGoalMilestone),
  takeLatest(organisationsModule.DELETE_ORG_GOAL_MILESTONE, deleteOrgGoalMilestone),
  takeLatest(organisationsModule.UPDATE_ORG_GOAL_MILESTONE, updateOrgGoalMilestone),
  takeLatest(organisationsModule.FETCH_ORG_GOAL_MILESTONE, fetchOrgGoalMilestone),
  takeLatest(organisationsModule.ADD_ORG_ADMIN, addOrgAdmin),
  takeLatest(organisationsModule.MANAGE_ORG_MEMBER_REQUEST, manageOrgMemberRequest),
  takeLatest(organisationsModule.MANAGE_ORG_MEMBER_INVITATION, manageOrgMemberInvitation),
  takeLatest(organisationsModule.UPDATE_ORG_ADMIN, updateOrgAdmin),
  takeLatest(organisationsModule.REMOVE_ORG_ADMIN, removeOrgAdmin),
  takeLatest(organisationsModule.FETCH_ORGANISATION, fetchOrganisation)
]
