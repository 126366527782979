import React from 'react'
import { Formik, Field } from 'formik'

// styles
import {
  CenteredModalHeader,
  CloseIcon,
  FormSectionButtonWrapper,
  FormSectionRow,
  ModalContainer,
  ScreenWrapper
} from './styles'

// Load components synchronously
import FormProgressBars from 'components/FormProgressBars/FormProgressBars'
import SmallButtonPrimary from 'components/SmallButtonPrimary/SmallButtonPrimary'
import TextArea from 'components/TextArea/TextArea'

// partials
import DonationContextSelector from 'components/Modals/_donationContextSelector'
import StoryVisibilitySelector from 'components/Modals/_storyVisibilitySelector'

const filterDonationOptions = ({
  extraInfoModalOpts,
  hasPersonalSub,
  isRecurringDonation,
  recurringSubscriptions
}) => {
  const { orgOpts } = extraInfoModalOpts
  if (!isRecurringDonation) return orgOpts

  const filteredOpts = hasPersonalSub ? orgOpts.filter(o => o.value !== 'personal') : orgOpts
  const orgRecurringSubIds = recurringSubscriptions
    .filter(s => s.donor_type === 'Organisation')
    .map(s => s.donor_id)

  return filteredOpts.filter(opt => {
    const hasSub = orgRecurringSubIds.includes(opt.value)

    return !hasSub
  })
}

const deriveStateProgress = ({ customTerms, formValues, isMoneyType, questions }) => {
  const isOppDonation = formValues.donation_origin === 'opportunity'
  const hasQuestions = questions.length > 0
  const hasTermsOrQuestions = hasQuestions || !!customTerms

  if (isOppDonation && isMoneyType && hasTermsOrQuestions) {
    return { formProgressActiveStep: 3, formProgressSteps: 3 }
  }

  if (isOppDonation && hasTermsOrQuestions) {
    return { formProgressActiveStep: 2, formProgressSteps: 2 }
  }

  if (isOppDonation && isMoneyType) {
    return { formProgressActiveStep: 2, formProgressSteps: 2 }
  }

  if (isOppDonation) {
    return { formProgressActiveStep: 2, formProgressSteps: 2 }
  }

  // For org page
  return { formProgressActiveStep: 2, formProgressSteps: 2 }
}

const DonationFinalFormSection = ({
  customTerms,
  extraInfoModalOpts,
  formValues,
  hasPersonalSub,
  hideModal,
  isMoneyType,
  isSuperAdmin,
  recurringSubscriptions,
  questions = [],
  setState,
  showContextField
}) => {
  const { formProgressActiveStep, formProgressSteps } = deriveStateProgress({
    customTerms,
    formValues,
    isMoneyType,
    questions
  })

  // Last option is for good type donations
  const isRecurringDonation = formValues.donation_frequency === 'recurring'

  const submitDisabled =
    isRecurringDonation && hasPersonalSub && !formValues.chosen_donation_context
  const submitLabel = submitDisabled
    ? 'Select organisation'
    : isRecurringDonation
    ? 'Donate'
    : isMoneyType
    ? 'Continue'
    : 'Donate'

  // don't show for orgs that are already subscribed
  const filteredDonationContextOpts = filterDonationOptions({
    extraInfoModalOpts,
    hasPersonalSub,
    isRecurringDonation,
    recurringSubscriptions
  })

  return (
    <ScreenWrapper>
      <ModalContainer>
        <Formik
          initialValues={{ donation_frequency: undefined }}
          enableReinitialize
          validateOnChange={false}
          validateOnBlur={false}
          onSubmit={values => extraInfoModalOpts.signUp({ ...formValues, ...values })}
          render={({ handleSubmit, setFieldValue }) => {
            return (
              <form onSubmit={handleSubmit}>
                <div>
                  <CloseIcon
                    dark
                    onClick={() => {
                      if (submitDisabled) return true

                      extraInfoModalOpts.updateFormValues({
                        ...extraInfoModalOpts.formValues,
                        applicant_commitment: undefined,
                        applicant_commitment_other: undefined,
                        donation_frequency: undefined
                      })
                      hideModal()
                    }}
                    size={30}
                  />

                  <CenteredModalHeader>Final touches</CenteredModalHeader>

                  <FormProgressBars active={formProgressActiveStep} steps={formProgressSteps} />

                  <FormSectionRow>
                    <Field
                      component={TextArea}
                      label={`Donation message`}
                      maxWidth="500px"
                      name="donation_message"
                      placeholder={'Optionally add a donation message'}
                    />
                  </FormSectionRow>

                  <DonationContextSelector
                    extraInfoModalOpts={extraInfoModalOpts}
                    formValues={formValues}
                    hasPersonalSub={hasPersonalSub}
                    isSuperAdmin={isSuperAdmin}
                    orgOpts={filteredDonationContextOpts}
                    setState={v => setState(v)}
                    showContextField={showContextField}
                  />

                  <StoryVisibilitySelector
                    formValues={formValues}
                    setState={v => setState(v)}
                    setFieldValue={setFieldValue}
                  />

                  <FormSectionButtonWrapper>
                    <SmallButtonPrimary disabled={submitDisabled} type="submit">
                      {submitLabel}
                    </SmallButtonPrimary>
                  </FormSectionButtonWrapper>
                </div>
              </form>
            )
          }}
        />
      </ModalContainer>
    </ScreenWrapper>
  )
}

export default DonationFinalFormSection
