import { navigate } from '@reach/router'
import { call, put, takeLatest } from 'redux-saga/effects'
import * as donationsModule from '../modules/donations'
import * as authenticationModule from '../modules/authentication'
import { donateToOrganisation as donateToOrg } from '../apiModules/donations'

// utils
import { setLocalStorageValues } from 'utils/localStorageManager'
import UrlBuilder from 'utils/urlBuilder'

const determineIfOzowUrl = url => {
  if (!url) return false
  return url.match(/^https?\:\/\/pay\.ozow\.com/)
}

const determineIfPayfastPayment = url => {
  if (!url) return false

  // development environment URL
  if (process.env.NODE_ENV === 'development') {
    return url.match(/^https?\:\/\/localhost:3000\/process-donation/)
  }

  // staging and production environment URLs
  return url.match(/^https\:\/\/(www|develop)?(\.)?browniepoints.africa\/process-donation/)
}

const attemptRedirect = ({ isOzowUrl, isPayfastPayment, postDonationRedirectUrl }) => {
  if (!postDonationRedirectUrl) return true
  if (!isOzowUrl && !isPayfastPayment) return true

  navigate(postDonationRedirectUrl)
}

function* donateToOrganisation({ payload }) {
  try {
    const response = yield call(donateToOrg, payload)
    const { postDonationRedirectUrl } = response.data
    const isOzowUrl = determineIfOzowUrl(postDonationRedirectUrl)
    const isPayfastPayment = determineIfPayfastPayment(postDonationRedirectUrl)

    yield put(donationsModule.donateToOrganisationSuccess(response.data))
    if (postDonationRedirectUrl) {
      UrlBuilder.displayPendingMessage()
    } else {
      UrlBuilder.displayUpdateSuccessMessage()
    }

    if (response.data.isNewUser) {
      yield put(authenticationModule.loginSuccess(response.data))
      yield setLocalStorageValues(response)
    }

    yield put({ type: 'authentication/FETCH_MY_ROLES' })

    attemptRedirect({ isOzowUrl, isPayfastPayment, postDonationRedirectUrl })
  } catch (err) {
    if (err.response.status === 401) {
      yield put(
        donationsModule.donateToOrganisationFailure({
          errorMessage: 'Please ensure that your email and password are correct.'
        })
      )
    } else {
      yield put(donationsModule.donateToOrganisationFailure(true))
    }
  }
}

export const donationsSagas = [
  takeLatest(donationsModule.DONATE_TO_ORGANISATION, donateToOrganisation)
]
