import React, { Component } from 'react'
import styled from 'styled-components'
import { Field } from 'formik'

// assets
import { ReactComponent as GoodsIcon } from 'assets/images/cause-type-icons/goods_icon.svg'

// styles
import { Label, SubLabel } from '../InputLabels/InputLabels'

// utils
import th from 'utils/themeHelper'

// Load components synchronously
import ConditionalDisplay from 'components/ConditionalDisplay/ConditionalDisplay'
import NumberInput from 'components/NumberInput/NumberInput'

const borderColor = ({ active, hasError, theme }) => {
  if (active) {
    return th('primary.base')
  }

  if (hasError) {
    return theme.borders.error
  } else {
    return theme.borders.medium
  }
}

const borderWidth = ({ active, hasError }) => {
  if (active) {
    return '1px'
  } else if (hasError) {
    return '2px'
  } else {
    return '1px'
  }
}

const Wrapper = styled.div`
  position: relative;
`

const SmallImage = styled.div`
  background-color: white;
  background-image: url(${props => props.src});
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 50%;
  border-style: solid;
  border-width: 1px;
  height: 30px;
  width: 30px;
`

const ShortSmallImage = styled.div`
  background-color: white;
  background-image: url(${props => props.src});
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  border-color: ${props => (props.active ? 'white' : th('text.light'))};
  border-radius: 50%;
  border-style: solid;
  border-width: 1px;
  height: 30px;
  width: 30px;
`

const ShortIconWrapper = styled.div`
  background-color: white;
  border-radius: 50%;
  border-style: solid;
  border-width: 1px;
  border-color: ${props => (props.active ? 'white' : th('text.light'))};
  height: 30px;
  padding: ${props => props.padding || '0'};
  width: 30px;

  > svg {
    fill: ${props => props.fill || th('text.dark')};
    height: 100%;
    width: 100%;
  }
`

const ShortSwipeOptionImage = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  padding: 5px 0 5px 8px;
`

const ShortSwipeOptionsWrapper = styled.div`
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  padding: 8px;
`

const ShortSwipeOptionLabel = styled.div`
  color: ${th('text.mid')};
  font-size: 12px;
  padding: 0;

  @media screen and (min-width: 769px) {
    font-size: 15px;
  }
`

const ShortSwipeOptionDetails = styled.div`
  border-radius: 3px;
  display: flex;
  flex-direction: row;
  height: 100%;
  width: 100%;
`

const SelectComponentContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin: 0 0 -10px;
  overflow-x: auto;
  position: relative;
  width: 100%;

  scrollbar-width: none;
  -ms-overflow-style: none;

  &::-webkit-scrollbar {
    display: none; /* Safari and Chrome */
  }
`

const ShortSelectComponentOption = styled.div`
  background: ${props => (props.active ? '#2A90443C' : 'white')};
  border-radius: 5px;
  border-style: solid;
  border-width: ${borderWidth};
  border-color: ${borderColor};
  color: ${th('text.dark')};
  display: flex;
  flex-basis: calc(50% - 10px);
  font-family: ${th('fonts.bold')};
  line-height: 1;
  margin: 0 10px 10px 0;
  padding: 0;

  &:hover {
    background: ${props => (props.active ? '#2A90443C' : th('backgrounds.light'))};
    cursor: pointer;
  }

  &:hover ${SmallImage} {
    border-color: ${props => (props.active ? 'white' : th('text.dark'))};
  }

  &:hover ${ShortSmallImage} {
    border-color: ${props => (props.active ? 'white' : th('text.dark'))};
  }

  @media screen and (min-width: 969px) {
    flex-basis: calc(33% - 10px);
  }

  @media screen and (min-width: 1200px) {
    padding: 5px 10px;
  }
`

const isActive = ({ goodsRequest, values }) => {
  const goodsCommitments = Object.keys(values)
    .filter(k => k.match(/is_donating_goods_request_/))
    .map(k => k.replace(/is_donating_goods_request_/, ''))

  return goodsCommitments.map(id => parseInt(id)).includes(goodsRequest.id)
}

const ImageForShortSwipeOption = ({ active, fill, iconPadding, Icon }) => {
  return (
    <ShortSwipeOptionImage active={active}>
      <ShortIconWrapper active={active} fill={fill} padding={iconPadding}>
        <Icon />
      </ShortIconWrapper>
    </ShortSwipeOptionImage>
  )
}

class GoodsDonationAmountSelect extends Component {
  setOption = ({ goodsRequest, setFieldValue }) => {
    setFieldValue(`is_donating_goods_request_${goodsRequest.id}`, true)
  }

  render() {
    const { errors, opportunity, setFieldValue, touched, values } = this.props
    const goodsRequests = opportunity.goodsRequests || []

    return (
      <Wrapper>
        {goodsRequests.map((goodsRequest, idx) => {
          const active = isActive({ goodsRequest, values })

          return (
            <div style={{ marginTop: idx ? '20px' : '0' }}>
              <Label htmlFor={`applicant_commitment_${goodsRequest.id}`}>
                {goodsRequest.label}
              </Label>

              <div>
                <SubLabel>{goodsRequest.notes || 'Select an amount'}</SubLabel>
              </div>

              <ConditionalDisplay displayWhen={[active]}>
                <div style={{ marginTop: '15px' }}>
                  <Field
                    component={NumberInput}
                    label={'Add a custom commitment amount'}
                    name={`applicant_commitment_${goodsRequest.id}`}
                    max="1000000"
                    min={1}
                    placeholder={`50 items`}
                    shortNumberField
                    type="number"
                  />
                </div>
              </ConditionalDisplay>

              <ConditionalDisplay displayWhen={[!active]}>
                <SelectComponentContainer
                  hasError={!!errors[`applicant_commitment_${goodsRequest.id}`]}
                  touched={!!touched[`applicant_commitment_${goodsRequest.id}`]}
                >
                  <ShortSelectComponentOption
                    active={active}
                    onClick={() => this.setOption({ goodsRequest, setFieldValue })}
                  >
                    <ShortSwipeOptionDetails active={active}>
                      <ImageForShortSwipeOption
                        action={active}
                        iconPadding={'5px'}
                        Icon={GoodsIcon}
                      />
                      <ShortSwipeOptionsWrapper>
                        <ShortSwipeOptionLabel active={active}>Add item(s)</ShortSwipeOptionLabel>
                      </ShortSwipeOptionsWrapper>
                    </ShortSwipeOptionDetails>
                  </ShortSelectComponentOption>
                </SelectComponentContainer>
              </ConditionalDisplay>
            </div>
          )
        })}
      </Wrapper>
    )
  }
}

export default GoodsDonationAmountSelect
