import axios from 'axios'

// config
import baseUrl from '../config'

//
// Personal capacity donations
//

// Fetch goods confirmation request info for personal donor
export const fetchGoodsDonationInfo = async params => {
  const response = await axios.get(`${baseUrl}/fetch-goods-donation-info`, {
    withCredentials: true,
    params
  })
  return response.data
}

// Submit goods confirmation request for personal donor
export const goodsDonationConfirmationRequest = async data => {
  const response = await axios({
    withCredentials: true,
    method: 'post',
    url: `${baseUrl}/goods-donation-confirmation-request`,
    data
  })
  return response.data
}

// Fetch money confirmation request info for personal donor
export const fetchMoneyDonationInfo = async params => {
  const response = await axios.get(`${baseUrl}/fetch-donation-info`, {
    withCredentials: true,
    params
  })
  return response.data
}

// Submit money confirmation request for personal donor
export const donationConfirmationRequest = async data => {
  const response = await axios({
    withCredentials: true,
    method: 'post',
    url: `${baseUrl}/donation-confirmation-request`,
    data
  })
  return response.data
}

//
// Org capacity donations
//

// Fetch goods confirmation request info for org donor
export const fetchOrgGoodsDonationInfo = async params => {
  const response = await axios.get(`${baseUrl}/fetch-org-goods-donation-info`, {
    withCredentials: true,
    params
  })
  return response.data
}

// Submit goods confirmation request by org donor
export const goodsOrgDonationConfirmationRequest = async data => {
  const response = await axios({
    withCredentials: true,
    method: 'post',
    url: `${baseUrl}/goods-org-donation-confirmation-request`,
    data
  })
  return response.data
}

// Fetch money confirmation request info for org donor
export const fetchOrgMoneyDonationInfo = async params => {
  const response = await axios.get(`${baseUrl}/fetch-org-money-donation-info`, {
    withCredentials: true,
    params
  })
  return response.data
}

// Submit money confirmation request by org donor
export const requestOrgDonationConfirmation = async data => {
  const response = await axios({
    withCredentials: true,
    method: 'post',
    url: `${baseUrl}/org-donation-confirmation-request`,
    data
  })
  return response.data
}

//
// Beneficiary org capacity
// For managing personal capacity donations
//

// fetch goods donation confirmation for personal capacity donor
export const fetchGoodsDonationConfirmation = async ({ missionId, confirmationId }) => {
  const response = await axios(
    `${baseUrl}/opportunities/${missionId}/goods-donation-confirmations/${confirmationId}`,
    {
      withCredentials: true,
      method: 'GET',
      responseType: 'json'
    }
  )

  return response.data
}

// approve or reject goods donation confirmation for personal donor
export const handleGoodsDonationConfirmation = async data => {
  const response = await axios({
    withCredentials: true,
    method: 'put',
    url: `${baseUrl}/opportunities/${data.opportunity_id}/goods-donation-confirmations/${data.confirmation_id}`,
    data
  })
  return response.data
}

// fetch money donation confirmation for personal capacity donor
export const fetchDonationConfirmation = async ({ missionId, confirmationId }) => {
  const response = await axios(
    `${baseUrl}/opportunities/${missionId}/donation-confirmations/${confirmationId}`,
    {
      withCredentials: true,
      method: 'GET',
      responseType: 'json'
    }
  )

  return response.data
}

// approve or reject money donation confirmation for personal donor
export const handleDonationConfirmation = async data => {
  const response = await axios({
    withCredentials: true,
    method: 'put',
    url: `${baseUrl}/opportunities/${data.opportunity_id}/donation-confirmations/${data.confirmation_id}`,
    data
  })
  return response.data
}

//
// Beneficiary org capacity
// For managing org capacity donations
//

// fetch goods donation confirmation for org capacity donor
export const fetchOrgGoodsDonationConfirmation = async ({ missionId, confirmationId }) => {
  const response = await axios(
    `${baseUrl}/opportunities/${missionId}/org-goods-donation-confirmations/${confirmationId}`,
    {
      withCredentials: true,
      method: 'GET',
      responseType: 'json'
    }
  )

  return response.data
}

// approve or reject goods donation confirmation for org donor
export const handleOrgGoodsDonationConfirmation = async data => {
  const response = await axios({
    withCredentials: true,
    method: 'put',
    url: `${baseUrl}/opportunities/${data.opportunity_id}/org-goods-donation-confirmations/${data.confirmation_id}`,
    data
  })
  return response.data
}

// fetch money donation confirmation for org capacity donor
export const fetchOrgDonationConfirmation = async ({ missionId, confirmationId }) => {
  const response = await axios(
    `${baseUrl}/opportunities/${missionId}/org-donation-confirmations/${confirmationId}`,
    {
      withCredentials: true,
      method: 'GET',
      responseType: 'json'
    }
  )

  return response.data
}

// approve or reject money donation confirmation for personal donor
export const handleOrgDonationConfirmation = async data => {
  const response = await axios({
    withCredentials: true,
    method: 'put',
    url: `${baseUrl}/opportunities/${data.opportunity_id}/org-donation-confirmations/${data.confirmation_id}`,
    data
  })
  return response.data
}
