import { createActions, handleActions } from 'redux-actions'

export const FETCH_CONTRIBUTIONS = 'accountActivity/FETCH_CONTRIBUTIONS'
export const FETCH_CONTRIBUTIONS_FAILURE = 'accountActivity/FETCH_CONTRIBUTIONS_FAILURE'
export const FETCH_CONTRIBUTIONS_SUCCESS = 'accountActivity/FETCH_CONTRIBUTIONS_SUCCESS'
export const LOGOUT_SUCCESS = 'authentication/LOGOUT_SUCCESS'

export const {
  accountActivity: { fetchContributions, fetchContributionsSuccess, fetchContributionsFailure }
} = createActions({
  [FETCH_CONTRIBUTIONS]: undefined,
  [FETCH_CONTRIBUTIONS_FAILURE]: undefined,
  [FETCH_CONTRIBUTIONS_SUCCESS]: undefined
})

const initialState = {
  contributionsData: [],
  errorMessage: '',
  isLoading: false,
  isSuccess: false
}

export default handleActions(
  {
    [FETCH_CONTRIBUTIONS]: state => ({
      ...state,
      errorMessage: null,
      isLoading: true,
      isSuccess: false
    }),
    [FETCH_CONTRIBUTIONS_FAILURE]: (state, { payload }) => ({
      ...state,
      errorMessage: payload || 'An unknown error occurred',
      isLoading: false,
      isSuccess: false
    }),
    [FETCH_CONTRIBUTIONS_SUCCESS]: (state, { payload }) => ({
      ...state,
      errorMessage: null,
      isLoading: false,
      isSuccess: true,
      contributionsData: payload
    }),
    [LOGOUT_SUCCESS]: () => initialState
  },
  initialState
)
