import styled from 'styled-components'
import th from 'utils/themeHelper'
import { Location } from '@styled-icons/octicons/Location'
import { Spinner } from '@styled-icons/evil/Spinner'

const borderColor = ({ requiredField, hasError, theme }) => {
  if (hasError) {
    return theme.borders.error
  } else if (requiredField) {
    return theme.borders.required
  } else {
    return theme.borders.default
  }
}

const borderWidth = ({ requiredField, hasError }) => {
  if (hasError) {
    return '2px'
  } else if (requiredField) {
    return '2px'
  } else {
    return '1px'
  }
}

export const Outer = styled.div.attrs({
  'data-cy': 'component-search-input'
})`
  position: relative;
  display: inline-block;
  width: 100%;

  @media screen and (min-width: 769px) {
    width: 500px;
  }

  input {
    appearance: none;
    background: ${props => (props.disabled ? th('backgrounds.light') : 'white')};
    border-radius: 3px;
    padding: 12px 36px 12px 18px;
    font-size: 14px;
    color: ${th('text.dark')};
    cursor: ${props => (props.disabled ? 'not-allowed' : 'text')};
    font-family: ${th('fonts.light')};
    width: 100%;
    outline: none;
    border-style: solid;
    border-width: ${borderWidth};
    border-color: ${borderColor};
    transition: 0.3s border-color;

    &:hover {
      border-color: ${({ hasError, theme }) =>
        hasError ? theme.borders.error : theme.borders.dark};
    }

    &:focus {
      border-color: ${th('borders.success')};
      outline: none;
    }

    &::placeholder {
      color: ${th('text.dark')};
    }

    &.react-autosuggest__input--open {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
      border-color: ${th('borders.success')};
      border-bottom-color: ${th('borders.default')};
    }
  }
`

export const SuggestionContainer = styled.div`
  display: none;
  border: 1px solid red;
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
  position: absolute;
  z-index: 1000;
  width: 100%;
  background: white;

  &.react-autosuggest__suggestions-container--open {
    display: block;
    border-style: solid;
    border-color: ${th('secondary.base')};
    border-width: 0 1px 1px 1px;
  }

  ul {
    padding: 0;
    margin: 0;
  }

  li {
    margin: 0;
    padding: 0;
    list-style: none;
  }
`

export const Icon = styled(Location)`
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
`

export const SuggestionItem = styled.div`
  padding: 10px;
  background: ${({ isHighlighted, theme }) =>
    isHighlighted ? th('secondary.lightest')({ theme }) : ''};
  cursor: pointer;
`

export const PlusIconWrapper = styled.span`
  display: inline-block;
  margin-right: 5px;
  width: 10px;

  svg {
    margin-top: -2px;
  }
`

export const SearchInputLabel = styled.label`
  display: block;
  font-size: 13px;
  color: ${th('backgrounds.dark')};
  font-family: ${th('fonts.bold')};
  padding: ${props => (props.innerLabel ? '0 2px 2px 0' : '2px')};
  margin-bottom: ${props => (props.innerLabel ? '0' : '4px')};
  width: max-content;
`

export const SpinnerIcon = styled(Spinner)`
  width: 30px;
  height: 30px;
  position: absolute;
  top: 50%;
  margin-top: -15px;
  right: 7px;

  -webkit-animation-name: spin;
  -webkit-animation-duration: 4000ms;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;
  -moz-animation-name: spin;
  -moz-animation-duration: 4000ms;
  -moz-animation-iteration-count: infinite;
  -moz-animation-timing-function: linear;
  -ms-animation-name: spin;
  -ms-animation-duration: 4000ms;
  -ms-animation-iteration-count: infinite;
  -ms-animation-timing-function: linear;
  -o-transition: rotate(3600deg);

  @-moz-keyframes spin {
    from {
      -moz-transform: rotate(0deg);
    }
    to {
      -moz-transform: rotate(360deg);
    }
  }

  @-webkit-keyframes spin {
    from {
      -webkit-transform: rotate(0deg);
    }
    to {
      -webkit-transform: rotate(360deg);
    }
  }

  @keyframes spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
`
