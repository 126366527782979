import React, { Fragment } from 'react'
import { Link } from '@reach/router'
import Rater from 'react-rater'

// config
import constants from 'config/constants'
import l from 'config/localization'

// utils
import stringUtils from 'utils/string'
import {
  deriveAttribution,
  deriveStoryAction,
  deterimeBeneficiaryRendering
} from 'utils/deriveStoryDetails'

// assets
import { ReactComponent as OrgIcon } from 'assets/images/svgs/icon-org-page-organisation.svg'
import { ReactComponent as OppIcon } from 'assets/images/svgs/icon-org-page-opportunity.svg'
import { ReactComponent as DonationIcon } from 'assets/images/cause-type-icons/money_icon_dark.svg'
import { ReactComponent as GoodsIcon } from 'assets/images/cause-type-icons/goods_icon_dark.svg'
import { ReactComponent as ChallengeIcon } from 'assets/images/cause-type-icons/challenge_icon_dark.svg'
import { ReactComponent as StoryFeaturedIcon } from 'assets/images/svgs/story-featured.svg'
import { ReactComponent as StoryGoodDeedIcon } from 'assets/images/svgs/story-good-deed.svg'
import { ReactComponent as StoryUpdateIcon } from 'assets/images/svgs/story-update.svg'
import { ReactComponent as VolunteerIcon } from 'assets/images/cause-type-icons/volunteer_icon_dark.svg'

import { CalendarEventFill } from '@styled-icons/bootstrap/CalendarEventFill'

import orgIcon from 'assets/images/icon-group.svg'

// styles
import {
  Avatar,
  AvatarImage,
  AdditionalOpportunityDetailsWrapper,
  AdditionalOpportunityDetailsLabel,
  AdditionalOpportunityDetailsLabelDetails,
  GoalTypeLabel,
  CampFeatureWrapper,
  UpdatedCardNotice,
  OpportunityDescriptionNotice,
  FeatureStoryInnerWrapper,
  BeneficiaryLabel,
  PrivateStoryNote,
  FeedbackImage,
  FeedbackImages,
  FeaturedStoryImage,
  FeatureStoryCardHeader,
  FeatureStoryShowMoreButtonPadder,
  StoryCardHeader,
  StoryOwnerLabel,
  StoryDetailsRow,
  KeyStoryDetails,
  KeyStoryDetailsWrapper,
  StoryText,
  CardLink,
  CardLinkWrapper,
  IconWrapper,
  PrivateStoryWarningMessage,
  ShowMoreButtonPadder,
  StoryBeneficiaryPreposition,
  StoryVideoWrapper,
  RatingRow,
  RatingLabel,
  RatingsContainer,
  SentenceTerminationPeriod,
  StoryDateWrapperOuter,
  StoryOwnerLink,
  UpdateStoryCardHeader,
  UpdateStoryDateWrapperOuter,
  UpdateStoryInnerWrapper,
  UpdateStoryOuterWrapper,
  UpdateStoryShowMoreButtonPadder
} from './styles'

// Load components synchronously
import ConditionalDisplay from 'components/ConditionalDisplay/ConditionalDisplay'
import LinkButton from 'components/SmallButtonPrimary/LinkButton'
import MobileView from 'components/MobileView/MobileView'
import SingleCard from 'components/SingleCard/SingleCard'

// story card templates
import StoryPills from './StoryPills'
import OrgInviteStoryCard from './_OrgInviteStoryCard'
import OrgUpdateStoryCard from './_OrgUpdateStoryCard'

const isMoneyDonation = ({ storyType }) => {
  return [
    constants.STORY_TYPES.DONATION_TO_ORG,
    constants.STORY_TYPES.DONATION_TO_ORG_CONFIRMED,
    constants.STORY_TYPES.NON_PROFIT_MEMBERSHIP_ADDED,
    constants.STORY_TYPES.OPP_SIGN_UP_MONEY,
    constants.STORY_TYPES.OPP_SIGN_UP_MONEY_CONFIRMED,
    constants.STORY_TYPES.OPP_SIGN_UP_MONEY_CORPORATE,
    constants.STORY_TYPES.OPP_SIGN_UP_MONEY_CORPORATE_CONFIRMED
  ].includes(storyType)
}

const isSignupStory = ({ storyType }) => {
  return [
    constants.STORY_TYPES.OPP_SIGN_UP,
    constants.STORY_TYPES.OPP_SIGN_UP_CHALLENGE,
    constants.STORY_TYPES.OPP_SIGN_UP_VOLUNTEER,
    constants.STORY_TYPES.OPP_SIGN_UP_GOODS,
    constants.STORY_TYPES.OPP_SIGN_UP_MONEY,
    constants.STORY_TYPES.OPP_SIGN_UP_MONEY_CONFIRMED
  ].includes(storyType)
}

const AvatarToShow = ({ isFeatureStory, storyOwnerAvatar, storyType }) => {
  if (isFeatureStory) {
    return (
      <Avatar background="white" svgMarginLeft={'-1px'} svgMarginTop={'-2px'}>
        <StoryFeaturedIcon />
      </Avatar>
    )
  }

  if (!storyType) return null

  if (storyOwnerAvatar) {
    return (
      <Avatar svgWidth={'35px'} svgHeight={'35px'} svgMarginLeft={'-3px'} svgMarginTop={'-4px'}>
        <AvatarImage src={storyOwnerAvatar} />
      </Avatar>
    )
  }

  switch (storyType) {
    case constants.STORY_TYPES.CAMP_UPDATE:
      return (
        <Avatar svgWidth={'34px'} svgHeight={'34px'} svgMarginLeft={'-3px'} svgMarginTop={'-3px'}>
          <StoryUpdateIcon />
        </Avatar>
      )
    case constants.STORY_TYPES.CHALLENGE_CREATED:
      return (
        <Avatar svgWidth={'18px'} svgHeight={'18px'} svgMarginLeft={'5px'} svgMarginTop={'5px'}>
          <OppIcon />
        </Avatar>
      )
    case constants.STORY_TYPES.GOOD_DEED:
      return (
        <Avatar svgWidth={'30px'} svgHeight={'30px'} svgMarginLeft={'-1px'} svgMarginTop={'-1px'}>
          <StoryGoodDeedIcon />
        </Avatar>
      )
    case constants.STORY_TYPES.GOOD_DEED_TEMPLATE:
      return (
        <Avatar svgWidth={'30px'} svgHeight={'30px'} svgMarginLeft={'-1px'} svgMarginTop={'-1px'}>
          <StoryGoodDeedIcon />
        </Avatar>
      )
    case constants.STORY_TYPES.NON_PROFIT_MEMBERSHIP_ADDED:
      return (
        <Avatar svgWidth={'18px'} svgHeight={'18px'} svgMarginLeft={'5px'} svgMarginTop={'5px'}>
          <CalendarEventFill />
        </Avatar>
      )
    case constants.STORY_TYPES.DONATION_TO_ORG:
      return (
        <Avatar svgWidth={'18px'} svgHeight={'18px'} svgMarginLeft={'5px'} svgMarginTop={'5px'}>
          <DonationIcon />
        </Avatar>
      )
    case constants.STORY_TYPES.DONATION_TO_ORG_CONFIRMED:
      return (
        <Avatar svgWidth={'18px'} svgHeight={'18px'} svgMarginLeft={'5px'} svgMarginTop={'5px'}>
          <DonationIcon />
        </Avatar>
      )
    case constants.STORY_TYPES.OPP_SIGN_UP:
      return (
        <Avatar svgWidth={'30px'} svgHeight={'30px'} svgMarginLeft={'-1px'} svgMarginTop={'-1px'}>
          <StoryGoodDeedIcon />
        </Avatar>
      )
    case constants.STORY_TYPES.OPP_SIGN_UP_CHALLENGE:
      return (
        <Avatar svgWidth={'25px'} svgHeight={'25px'} svgMarginLeft={'1.5px'} svgMarginTop={'2px'}>
          <ChallengeIcon />
        </Avatar>
      )
    case constants.STORY_TYPES.OPP_SIGN_UP_VOLUNTEER:
      return (
        <Avatar svgWidth={'20px'} svgHeight={'20px'} svgMarginLeft={'4.5px'} svgMarginTop={'4px'}>
          <VolunteerIcon />
        </Avatar>
      )
    case constants.STORY_TYPES.OPP_SIGN_UP_GOODS:
      return (
        <Avatar svgWidth={'22px'} svgHeight={'22px'} svgMarginLeft={'3px'} svgMarginTop={'4px'}>
          <GoodsIcon />
        </Avatar>
      )
    case constants.STORY_TYPES.OPP_SIGN_UP_MONEY:
      return (
        <Avatar svgWidth={'18px'} svgHeight={'18px'} svgMarginLeft={'5px'} svgMarginTop={'5px'}>
          <DonationIcon />
        </Avatar>
      )
    case constants.STORY_TYPES.OPP_SIGN_UP_MONEY_CONFIRMED:
      return (
        <Avatar svgWidth={'18px'} svgHeight={'18px'} svgMarginLeft={'5px'} svgMarginTop={'5px'}>
          <DonationIcon />
        </Avatar>
      )
    case constants.STORY_TYPES.OPP_SIGN_UP_MONEY_CORPORATE:
      return (
        <Avatar svgWidth={'18px'} svgHeight={'18px'} svgMarginLeft={'5px'} svgMarginTop={'5px'}>
          <DonationIcon />
        </Avatar>
      )
    case constants.STORY_TYPES.OPP_SIGN_UP_MONEY_CORPORATE_CONFIRMED:
      return (
        <Avatar svgWidth={'18px'} svgHeight={'18px'} svgMarginLeft={'5px'} svgMarginTop={'5px'}>
          <DonationIcon />
        </Avatar>
      )
    case constants.STORY_TYPES.OPP_HOST_FEEDBACK:
      return (
        <Avatar svgWidth={'34px'} svgHeight={'34px'} svgMarginLeft={'-3px'} svgMarginTop={'-3px'}>
          <StoryUpdateIcon />
        </Avatar>
      )
    case constants.STORY_TYPES.OPP_FEEDBACK:
      return (
        <Avatar svgWidth={'30px'} svgHeight={'30px'} svgMarginLeft={'-1px'} svgMarginTop={'-1px'}>
          <StoryGoodDeedIcon />
        </Avatar>
      )
    case constants.STORY_TYPES.OPP_FEATURE:
      return (
        <Avatar svgWidth={'35px'} svgHeight={'35px'} svgMarginLeft={'-3px'} svgMarginTop={'-4px'}>
          <StoryFeaturedIcon />
        </Avatar>
      )
    case constants.STORY_TYPES.OPP_CREATED:
      return (
        <Avatar svgWidth={'18px'} svgHeight={'18px'} svgMarginLeft={'5px'} svgMarginTop={'5px'}>
          <OppIcon />
        </Avatar>
      )
    case constants.STORY_TYPES.OPP_UPDATE:
      return (
        <Avatar svgWidth={'34px'} svgHeight={'34px'} svgMarginLeft={'-3px'} svgMarginTop={'-3px'}>
          <StoryUpdateIcon />
        </Avatar>
      )
    case constants.STORY_TYPES.ORG_FEATURE:
      return (
        <Avatar svgWidth={'35px'} svgHeight={'35px'} svgMarginLeft={'-3px'} svgMarginTop={'-4px'}>
          <StoryFeaturedIcon />
        </Avatar>
      )
    case constants.STORY_TYPES.ORG_INVITE:
      return (
        <Avatar svgWidth={'22px'} svgHeight={'22px'} svgMarginLeft={'3px'} svgMarginTop={'3px'}>
          <OrgIcon />
        </Avatar>
      )
    case constants.STORY_TYPES.ORG_PROFILE_COMPLETE:
      return (
        <Avatar svgWidth={'22px'} svgHeight={'22px'} svgMarginLeft={'3px'} svgMarginTop={'3px'}>
          <OrgIcon />
        </Avatar>
      )
    case constants.STORY_TYPES.ORG_UPDATE:
      return (
        <Avatar svgWidth={'34px'} svgHeight={'34px'} svgMarginLeft={'-3px'} svgMarginTop={'-3px'}>
          <StoryUpdateIcon />
        </Avatar>
      )
    case constants.STORY_TYPES.CHALLENGE_CHECKIN:
      return (
        <Avatar svgWidth={'25px'} svgHeight={'25px'} svgMarginLeft={'1.5px'} svgMarginTop={'2px'}>
          <ChallengeIcon />
        </Avatar>
      )
    default:
      return null
  }
}

const KeyDetails = props => {
  const {
    isOppMasonry,
    isUserProfile,
    did_withdraw: applicationWithdrawn,
    owner_link: storyOwnerLink,
    owner_name: storyOwnerName,
    owner_avatar: storyOwnerAvatar,
    story_outcome_status: storyOutcomeStatus,
    story_outcome_amount: storyAmount,
    story_type: storyType,
    story_beneficiary: storyBeneficiary,
    goodsDonationsForStory,
    orgGoodsDonationsForStory
  } = props

  const { beneficiaryName, beneficiaryLink } = deriveAttribution({ storyBeneficiary })
  const actionDescription = deriveStoryAction({
    applicationWithdrawn,
    beneficiaryName,
    goodsDonationsForStory,
    orgGoodsDonationsForStory,
    isOppMasonry,
    storyAmount,
    storyOutcomeStatus,
    storyType
  })
  const displayBeneficiary = deterimeBeneficiaryRendering({ beneficiaryName, storyType })

  if (isUserProfile) {
    return (
      <StoryDetailsRow>
        <AdditionalOpportunityDetailsWrapper>
          <AdditionalOpportunityDetailsLabel>
            <AvatarToShow storyType={storyType} />

            <AdditionalOpportunityDetailsLabelDetails>
              <StoryOwnerLabel>{storyOwnerName}</StoryOwnerLabel>
              <GoalTypeLabel>{actionDescription}</GoalTypeLabel>

              <ConditionalDisplay displayWhen={[displayBeneficiary]}>
                <StoryBeneficiaryPreposition>{'for'}</StoryBeneficiaryPreposition>

                <CardLink isSmallText to={beneficiaryLink}>
                  <BeneficiaryLabel>{beneficiaryName}</BeneficiaryLabel>
                </CardLink>
              </ConditionalDisplay>

              <SentenceTerminationPeriod />
            </AdditionalOpportunityDetailsLabelDetails>
          </AdditionalOpportunityDetailsLabel>
        </AdditionalOpportunityDetailsWrapper>
      </StoryDetailsRow>
    )
  }

  if (!storyOwnerLink || !storyOwnerName) {
    return (
      <StoryDetailsRow>
        <AdditionalOpportunityDetailsWrapper>
          <AdditionalOpportunityDetailsLabel isOppMasonry={isOppMasonry}>
            <AvatarToShow storyType={storyType} />

            <AdditionalOpportunityDetailsLabelDetails>
              <StoryOwnerLabel>An anonymous philanthropist</StoryOwnerLabel>
              <GoalTypeLabel>{actionDescription}</GoalTypeLabel>

              <ConditionalDisplay displayWhen={[displayBeneficiary]}>
                <StoryBeneficiaryPreposition>{'for'}</StoryBeneficiaryPreposition>

                <CardLink isSmallText to={beneficiaryLink}>
                  <BeneficiaryLabel>{beneficiaryName}</BeneficiaryLabel>
                </CardLink>
              </ConditionalDisplay>

              <SentenceTerminationPeriod />
            </AdditionalOpportunityDetailsLabelDetails>
          </AdditionalOpportunityDetailsLabel>
        </AdditionalOpportunityDetailsWrapper>
      </StoryDetailsRow>
    )
  }

  return (
    <StoryDetailsRow>
      <AdditionalOpportunityDetailsWrapper>
        <AdditionalOpportunityDetailsLabel isOppMasonry={isOppMasonry}>
          <StoryOwnerLink href={storyOwnerLink}>
            <AvatarToShow storyOwnerAvatar={storyOwnerAvatar} storyType={storyType} />
          </StoryOwnerLink>

          <AdditionalOpportunityDetailsLabelDetails>
            <StoryOwnerLink display={'inline'} href={storyOwnerLink}>
              <StoryOwnerLabel>{storyOwnerName}</StoryOwnerLabel>
            </StoryOwnerLink>

            <GoalTypeLabel>{actionDescription}</GoalTypeLabel>

            <ConditionalDisplay displayWhen={[displayBeneficiary]}>
              <StoryBeneficiaryPreposition>{'for'}</StoryBeneficiaryPreposition>

              <CardLink isSmallText to={beneficiaryLink}>
                <BeneficiaryLabel>{beneficiaryName}</BeneficiaryLabel>
              </CardLink>
            </ConditionalDisplay>

            <SentenceTerminationPeriod />
          </AdditionalOpportunityDetailsLabelDetails>
        </AdditionalOpportunityDetailsLabel>
      </AdditionalOpportunityDetailsWrapper>
    </StoryDetailsRow>
  )
}

const StoryRating = ({ label, rating }) => {
  if (!rating) return null

  return (
    <RatingsContainer>
      <RatingRow>
        <RatingLabel>{label}</RatingLabel>
        <Rater total={5} rating={rating} interactive={false} />{' '}
      </RatingRow>
    </RatingsContainer>
  )
}

const StoryVideo = ({ video }) => {
  if (!video) return null

  const isGoogleDriveVideo = video.match(/^http(s)?:\/\/drive.google.com/)

  if (isGoogleDriveVideo) {
    return (
      <StoryVideoWrapper>
        <iframe src={video} width="640" height="480" allow="autoplay"></iframe>
      </StoryVideoWrapper>
    )
  }

  return (
    <StoryVideoWrapper>
      <iframe
        width="100%"
        src={`https://www.youtube-nocookie.com/embed/${video}?autoplay=0&showinfo=0&controls=0`}
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      />
    </StoryVideoWrapper>
  )
}

const FeedbackImageContainer = ({ images = [], hideCardImages, isOrgInviteStory }) => {
  if (isOrgInviteStory && !images.length) {
    return (
      <FeedbackImages>
        <FeedbackImage src={orgIcon} />
      </FeedbackImages>
    )
  }

  if (hideCardImages) return null
  if (!images.length) return null

  return (
    <FeedbackImages>
      {images.map((img, idx) => {
        return <FeedbackImage src={img.src} key={`feedback_img_${idx}`} />
      })}
    </FeedbackImages>
  )
}

const FeaturedStoryMedia = ({
  displayFullStory,
  path,
  src,
  storyText,
  userRequestedFullStory,
  videoSrc
}) => {
  if (!src && !videoSrc) return null
  if (videoSrc) {
    return <StoryVideo video={videoSrc} />
  }

  // Derive a name to display and shorten the story text for the card
  // spliced string is 41 tokens for some reason
  const paddedWordLimit = 41

  const wordCount = storyText.trim().split(' ').length
  const isSpliced = displayFullStory ? false : wordCount > paddedWordLimit
  const displayShowMoreButton = userRequestedFullStory ? false : isSpliced

  if (!path) {
    return (
      <a href={src} target="_blank">
        <FeaturedStoryImage src={src} hasShowMoreButton={displayShowMoreButton} />
      </a>
    )
  }

  return (
    <Link to={path}>
      <FeaturedStoryImage src={src} hasShowMoreButton={displayShowMoreButton} />
    </Link>
  )
}

const FeaturedStoryInfo = ({
  dark,
  displayFullStory,
  setState,
  status,
  storyHasText,
  storyText = '',
  storyTitle,
  userRequestedFullStory
}) => {
  // Do not render any story info for action-only stories
  if (status === constants.STORY_STATES.ACTION_ONLY) return null

  // Only show the title for title-only stories
  if (status === constants.STORY_STATES.TITLE_ONLY) {
    return <FeatureStoryCardHeader dark={dark}>{storyTitle}</FeatureStoryCardHeader>
  }

  // If user hasn't provided a text to show, then display nothing
  if (!storyHasText) return null

  // Derive a name to display and shorten the story text for the card
  // spliced string is 41 tokens for some reason
  const wordLimit = 40
  const paddedWordLimit = 41
  const { splicedString } = stringUtils.trimText({ string: storyText, wordLimit })
  const visibleStoryText = displayFullStory
    ? storyText
    : userRequestedFullStory
    ? storyText
    : splicedString

  const wordCount = storyText.trim().split(' ').length
  const isSpliced = displayFullStory ? false : wordCount > paddedWordLimit
  const displayShowMoreButton = userRequestedFullStory ? false : isSpliced

  return (
    <Fragment>
      <StoryText
        dark={dark}
        displayFullStory={displayFullStory}
        isFeatureStory
        marginBottom={displayShowMoreButton}
        storyText={visibleStoryText}
      />

      <ConditionalDisplay displayWhen={[displayShowMoreButton]}>
        <FeatureStoryShowMoreButtonPadder dark={dark}>
          <LinkButton onClick={() => setState({ userRequestedFullStory: true })}>
            Show more
          </LinkButton>
        </FeatureStoryShowMoreButtonPadder>
      </ConditionalDisplay>
    </Fragment>
  )
}

const TitleForStoryInfo = ({ isOppMasonry, path, storyTitle, titleIsLink }) => {
  if (!storyTitle) return null
  if (isOppMasonry) return null

  if (!titleIsLink || !path) {
    return <StoryCardHeader>{storyTitle}</StoryCardHeader>
  }

  return (
    <CardLink to={path}>
      <StoryCardHeader>{storyTitle}</StoryCardHeader>
    </CardLink>
  )
}

const determineStoryDateLink = ({ path, storyId, storyType }) => {
  if (!storyType) return null

  // story path is same as current path
  if (path === window.location.pathname) return null

  switch (storyType) {
    case constants.STORY_TYPES.OPP_SIGN_UP_CHALLENGE:
      return path
    default:
      return `/stories/${storyId}`
  }
}

const StoryInfo = ({
  displayFullStory,
  isGoodDeedStory,
  isOppMasonry,
  path = '',
  setState,
  status,
  storyText = '',
  storyTitle,
  storyType,
  titleIsLink,
  userRequestedFullStory
}) => {
  // Do not render any story info for action-only stories
  if (status === constants.STORY_STATES.ACTION_ONLY) return null

  // Only show the title for title-only stories
  if (status === constants.STORY_STATES.TITLE_ONLY) {
    return <StoryCardHeader>{storyTitle}</StoryCardHeader>
  }

  // Derive a name to display and shorten the story text for the card
  // spliced string is 41 tokens for some reason
  const wordLimit = 40
  const paddedWordLimit = 41
  const { splicedString } = stringUtils.trimText({ string: storyText, wordLimit })
  const visibleStoryText = displayFullStory
    ? storyText
    : userRequestedFullStory
    ? storyText
    : splicedString

  const wordCount = storyText.trim().split(' ').length
  const isSpliced = displayFullStory ? false : wordCount > paddedWordLimit
  const displayShowMoreButton = userRequestedFullStory ? false : isGoodDeedStory ? isSpliced : false

  const storyDateLink = determineStoryDateLink({ path, storyType })

  return (
    <Fragment>
      <TitleForStoryInfo
        isOppMasonry={isOppMasonry}
        path={storyDateLink}
        storyTitle={storyTitle}
        titleIsLink={titleIsLink}
      />

      <StoryText
        displayFullStory={displayFullStory}
        marginBottom={displayShowMoreButton}
        storyText={visibleStoryText}
      />

      <ConditionalDisplay displayWhen={[displayShowMoreButton]}>
        <ShowMoreButtonPadder>
          <LinkButton onClick={() => setState({ userRequestedFullStory: true })}>
            Show more
          </LinkButton>
        </ShowMoreButtonPadder>
      </ConditionalDisplay>
    </Fragment>
  )
}

const deriveStoryCategoryName = ({ categories, storyCategory }) => {
  const category = categories.find(c => c.id === storyCategory)
  if (!category) return { storyCategoryId: null, storyCategoryName: null }

  return { storyCategoryId: category.id, storyCategoryName: category.name }
}

class StoryCard extends React.Component {
  state = { userRequestedFullStory: false }

  displayShowMoreButton() {
    this.setState({ userRequestedFullStory: true })
  }

  render() {
    const { userRequestedFullStory } = this.state
    const {
      categories = [],
      dark,
      display_full_story: displayFullStory,
      displayPrivateStoryMessage,
      experience_rating: experienceRating,
      forceDisplayStory,
      hasGodRole,
      hideCardImages,
      host_rating: hostRating,
      isOppMasonry,
      isOrgMasonry,
      isUserProfile,
      id: storyId,
      isHiddenStory,
      is_own_story: isOwnStory,
      status,
      story_beneficiary_logo: storyBeneficiaryLogo,
      story_beneficiary_name: storyBeneficiaryName,
      story_beneficiary_status: storyBeneficiaryStatus,
      story_beneficiary_url_name: storyBeneficiaryPath,
      story_category_id: storyCategory,
      story_date: storyDate,
      story_target_url: storyTarget,
      story_end_date: storyEndDate,
      story_images: storyImages,
      story_likes: storyLikesCount,
      story_points: storyPoints,
      story_points_status: storyPointsStatus,
      story_status: storyStatus,
      story_text: storyText,
      story_title: storyTitle,
      story_type: storyType,
      story_video: storyVideo,
      user_likes_story: userLikesStory
    } = this.props

    if (storyType === constants.STORY_TYPES.ORG_INVITE) {
      return <OrgInviteStoryCard {...this.props} />
    }

    if (storyType === constants.STORY_TYPES.ORG_UPDATE) {
      return <OrgUpdateStoryCard {...this.props} />
    }

    const hasStoryImages = storyImages && storyImages.length > 0
    const hasMedia = !!storyVideo || hasStoryImages

    if (displayPrivateStoryMessage) {
      return (
        <SingleCard>
          <PrivateStoryWarningMessage>
            <TitleForStoryInfo storyTitle="Cannot Display Story" />

            <StoryText marginBottom storyText="The story that you are trying to view is private." />
          </PrivateStoryWarningMessage>
        </SingleCard>
      )
    }

    if (isHiddenStory) {
      return (
        <SingleCard gold>
          <Fragment>
            <UpdatedCardNotice>Story Updated</UpdatedCardNotice>

            <OpportunityDescriptionNotice>
              This story status has been updated and will no longer show up on this page.
            </OpportunityDescriptionNotice>
          </Fragment>
        </SingleCard>
      )
    }

    const safeStoryText = storyText || ''
    const { storyCategoryId, storyCategoryName } = deriveStoryCategoryName({
      categories,
      storyCategory
    })
    const categoryLink =
      storyType === constants.STORY_TYPES.OPP_SIGN_UP_CHALLENGE
        ? `/search/challenges/?ct=${storyCategoryId}`
        : `/search/${l('ROUTE_OPPS')}/?ct=${storyCategoryId}`

    const isOppSignupStory = isSignupStory({ storyType })
    const moneyDonation = isMoneyDonation({ storyType })
    const isOrgInviteStory = storyType === constants.STORY_TYPES.ORG_INVITE
    const isGoodDeedStory = [
      constants.STORY_TYPES.GOOD_DEED,
      constants.STORY_TYPES.GOOD_DEED_TEMPLATE
    ].includes(storyType)
    const isUpdateStory = [
      constants.STORY_TYPES.CAMP_UPDATE,
      constants.STORY_TYPES.OPP_UPDATE,
      constants.STORY_TYPES.ORG_UPDATE
    ].includes(storyType)
    const isFeatureStory = [
      constants.STORY_TYPES.CAMP_FEATURE,
      constants.STORY_TYPES.OPP_FEATURE,
      constants.STORY_TYPES.ORG_FEATURE
    ].includes(storyType)

    // const isDonationByCompanyStory = [constants.STORY_CONTEXT_TYPES.ORG].includes(this.props.owner_type)

    const hasStoryImage = storyImages && storyImages[0]
    const storyImageSrc = hasStoryImage ? storyImages[0].src : null
    const storyHasText = moneyDonation
      ? !!safeStoryText
      : isOppSignupStory
      ? storyTitle
      : isOrgInviteStory
      ? storyTitle
      : this.props.story_has_text

    if (isFeatureStory) {
      const paddedWordLimit = 41
      const wordCount = safeStoryText.trim().split(' ').length
      const isSpliced = displayFullStory ? false : wordCount > paddedWordLimit

      return (
        <SingleCard dark={dark}>
          <CardLinkWrapper dark={dark}>
            <FeaturedStoryMedia
              displayFullStory={displayFullStory || forceDisplayStory}
              path={storyTarget}
              src={storyImageSrc}
              storyText={safeStoryText}
              videoSrc={storyVideo}
              userRequestedFullStory={userRequestedFullStory}
            />

            <CampFeatureWrapper dark={dark} isSpliced={isSpliced} isOrgMasonry={isOrgMasonry}>
              <FeatureStoryInnerWrapper dark={dark}>
                <AvatarToShow isFeatureStory storyType={storyType} />

                <ConditionalDisplay displayWhen={[storyTitle, !storyTarget]}>
                  <FeatureStoryCardHeader dark={dark}>{storyTitle}</FeatureStoryCardHeader>
                </ConditionalDisplay>

                <ConditionalDisplay displayWhen={[storyTitle, storyTarget]}>
                  <CardLink flex={1} to={storyTarget}>
                    <FeatureStoryCardHeader dark={dark}>{storyTitle}</FeatureStoryCardHeader>
                  </CardLink>
                </ConditionalDisplay>
              </FeatureStoryInnerWrapper>

              <FeaturedStoryInfo
                dark={dark}
                displayFullStory={displayFullStory || forceDisplayStory}
                path={storyTarget}
                setState={() => this.displayShowMoreButton()}
                status={status}
                storyHasText={storyHasText}
                storyText={safeStoryText}
                storyTitle={storyTitle}
                userRequestedFullStory={userRequestedFullStory}
              />
            </CampFeatureWrapper>
          </CardLinkWrapper>

          <StoryPills
            dark={dark}
            isFeatureStory
            hasGodRole={hasGodRole}
            hasMedia={hasMedia}
            isOrgMasonry={isOrgMasonry}
            isUserProfile={isUserProfile}
            storyBeneficiaryLogo={storyBeneficiaryLogo}
            storyBeneficiaryName={storyBeneficiaryName}
            storyBeneficiaryPath={storyBeneficiaryPath}
            storyBeneficiaryStatus={storyBeneficiaryStatus}
            storyPoints={storyPoints}
            storyPointsStatus={storyPointsStatus}
            storyStatus={storyStatus}
            storyLikesCount={storyLikesCount}
            userLikesStory={userLikesStory}
            storyId={storyId}
            {...this.props}
          />
        </SingleCard>
      )
    }

    if (isUpdateStory) {
      // Derive a name to display and shorten the story text for the card
      // spliced string is 41 tokens for some reason
      const wordLimit = 40
      const paddedWordLimit = 41
      const { splicedString } = stringUtils.trimText({
        string: safeStoryText,
        wordLimit: wordLimit
      })
      const visibleStoryText =
        displayFullStory || forceDisplayStory
          ? safeStoryText
          : userRequestedFullStory
          ? safeStoryText
          : splicedString

      const wordCount = safeStoryText.trim().split(' ').length
      const isSpliced = displayFullStory || forceDisplayStory ? false : wordCount > paddedWordLimit
      const displayShowMoreButton = userRequestedFullStory ? false : isSpliced
      const hasAnyText = visibleStoryText.length
      const hasVisibleImage = storyImages.length && !hideCardImages

      return (
        <SingleCard>
          <CardLinkWrapper>
            <KeyStoryDetailsWrapper
              hasAnyText={hasAnyText}
              icons={[storyCategoryName, storyDate]}
              isUpdateStory
            >
              <UpdateStoryOuterWrapper hasVisibleImage={hasVisibleImage}>
                <UpdateStoryInnerWrapper paddingRight={!!storyDate}>
                  <AvatarToShow storyType={storyType} />
                  <UpdateStoryCardHeader>{storyTitle}</UpdateStoryCardHeader>
                </UpdateStoryInnerWrapper>

                <UpdateStoryDateWrapperOuter>
                  <Link to={`/stories/${storyId}`}>
                    <IconWrapper infoType={'Date'} value={storyDate} endDate={storyEndDate} />
                  </Link>
                </UpdateStoryDateWrapperOuter>
              </UpdateStoryOuterWrapper>

              <StoryText
                dark={dark}
                displayFullStory={displayFullStory || forceDisplayStory}
                isUpdateStory
                marginBottom={displayShowMoreButton}
                storyText={visibleStoryText}
              />

              <ConditionalDisplay displayWhen={[displayShowMoreButton]}>
                <UpdateStoryShowMoreButtonPadder>
                  <LinkButton onClick={() => this.displayShowMoreButton()}>Show more</LinkButton>
                </UpdateStoryShowMoreButtonPadder>
              </ConditionalDisplay>
            </KeyStoryDetailsWrapper>

            <StoryVideo video={storyVideo} />
            <FeedbackImageContainer
              hideCardImages={hideCardImages}
              isOrgInviteStory={isOrgInviteStory}
              images={storyImages}
            />
          </CardLinkWrapper>

          <StoryPills
            hasGodRole={hasGodRole}
            hasMedia={hasMedia}
            isOrgMasonry={isOrgMasonry}
            isUserProfile={isUserProfile}
            position="absolute"
            storyBeneficiaryLogo={storyBeneficiaryLogo}
            storyBeneficiaryName={storyBeneficiaryName}
            storyBeneficiaryPath={storyBeneficiaryPath}
            storyBeneficiaryStatus={storyBeneficiaryStatus}
            storyPoints={storyPoints}
            storyPointsStatus={storyPointsStatus}
            storyStatus={storyStatus}
            storyLikesCount={storyLikesCount}
            userLikesStory={userLikesStory}
            storyId={storyId}
            {...this.props}
          />
        </SingleCard>
      )
    }

    // Derive a name to display and shorten the story text for the card
    // spliced string is 41 tokens for some reason
    const wordLimit = 40
    const { splicedString } = stringUtils.trimText({
      string: safeStoryText,
      wordLimit: wordLimit
    })
    const visibleStoryText =
      displayFullStory || forceDisplayStory
        ? safeStoryText
        : userRequestedFullStory
        ? safeStoryText
        : splicedString
    const hasAnyText = visibleStoryText.length

    const isPrivate = storyStatus === constants.STORY_STATES.PRIVATE
    const privateStoryText =
      moneyDonation && isUserProfile
        ? 'This story is only visible to you.'
        : moneyDonation
        ? 'This donation shows as anonymous to everyone except you.'
        : 'This story is only visible to you.'

    const storyDateLink = determineStoryDateLink({ path: storyTarget, storyId, storyType })

    return (
      <SingleCard>
        <CardLinkWrapper>
          <KeyStoryDetailsWrapper icons={[storyCategoryName, storyDate]}>
            <KeyStoryDetails
              isOppMasonry={isOppMasonry}
              icons={[storyCategoryName, storyDate]}
              hasAnyText={hasAnyText}
            >
              <KeyDetails isUserProfile={isUserProfile} {...this.props} />

              <StoryDateWrapperOuter hideMobile>
                {storyDateLink && (
                  <Link to={storyDateLink}>
                    <IconWrapper infoType={'Date'} value={storyDate} endDate={storyEndDate} />
                  </Link>
                )}

                {!storyDateLink && (
                  <IconWrapper infoType={'Date'} value={storyDate} endDate={storyEndDate} />
                )}

                <ConditionalDisplay displayWhen={[!isOppMasonry, storyCategoryName]}>
                  <Link to={categoryLink}>
                    <IconWrapper infoType={'Category'} value={storyCategoryName} />
                  </Link>
                </ConditionalDisplay>
              </StoryDateWrapperOuter>
            </KeyStoryDetails>

            <StoryInfo
              displayFullStory={displayFullStory || forceDisplayStory}
              isGoodDeedStory={isGoodDeedStory}
              isOppMasonry={isOppMasonry}
              titleIsLink={!isGoodDeedStory && storyDateLink}
              path={storyDateLink}
              setState={() => this.displayShowMoreButton()}
              status={status}
              storyType={storyType}
              storyHasText={storyHasText}
              storyText={safeStoryText}
              storyTitle={storyTitle}
              storyId={storyId}
              userRequestedFullStory={userRequestedFullStory}
            />

            <MobileView>
              <StoryDetailsRow flexDirection={'row'}>
                <ConditionalDisplay displayWhen={[isPrivate, isOwnStory]}>
                  <PrivateStoryNote>{privateStoryText}</PrivateStoryNote>
                </ConditionalDisplay>

                <Link to={`/stories/${storyId}`}>
                  <IconWrapper infoType={'Date'} value={storyDate} endDate={storyEndDate} />
                </Link>

                <ConditionalDisplay displayWhen={[!isOppMasonry, storyCategoryName]}>
                  <Link to={categoryLink}>
                    <IconWrapper infoType={'Category'} value={storyCategoryName} />
                  </Link>
                </ConditionalDisplay>
              </StoryDetailsRow>
            </MobileView>
          </KeyStoryDetailsWrapper>

          <StoryRating label="Experience rating" rating={experienceRating} />
          <StoryRating label="Host rating" rating={hostRating} />

          <StoryVideo video={storyVideo} />

          <FeedbackImageContainer
            hideCardImages={hideCardImages}
            isGoodDeedStory={isGoodDeedStory}
            isOrgInviteStory={isOrgInviteStory}
            images={storyImages}
            path={storyTarget}
          />

          <ConditionalDisplay displayWhen={[isPrivate, isOwnStory]}>
            <PrivateStoryNote>{privateStoryText}</PrivateStoryNote>
          </ConditionalDisplay>
        </CardLinkWrapper>

        <StoryPills
          hasGodRole={hasGodRole}
          hasMedia={hasMedia}
          isOrgMasonry={isOrgMasonry}
          isUserProfile={isUserProfile}
          storyBeneficiaryLogo={storyBeneficiaryLogo}
          storyBeneficiaryName={storyBeneficiaryName}
          storyBeneficiaryPath={storyBeneficiaryPath}
          storyBeneficiaryStatus={storyBeneficiaryStatus}
          storyPoints={storyPoints}
          storyPointsStatus={storyPointsStatus}
          storyStatus={storyStatus}
          storyLikesCount={storyLikesCount}
          userLikesStory={userLikesStory}
          storyId={storyId}
          {...this.props}
        />
      </SingleCard>
    )
  }
}

export default StoryCard
