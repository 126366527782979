import styled, { css, keyframes } from 'styled-components'

const pulseAnimation = keyframes`
  0% {
    background-color: rgba(165, 165, 165, 0.1);
  }
  50% {
    background-color: rgba(165, 165, 165, 0.3);
  }
  100% {
    background-color: rgba(165, 165, 165, 0.1);
  }
}
`

const Pulse = css`
  animation-duration: 2s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: ${pulseAnimation};
  animation-timing-function: linear;
`

const ShortPulse = css`
  animation-duration: 1.6s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: ${pulseAnimation};
  animation-timing-function: linear;
`

export const DashboardWrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`

export const DashboardContainer = styled.div`
  display: flex;
  position: relative;
  align-items: flex-start;
  flex: 1;
  flex-wrap: nowrap;

  @media screen and (max-width: 769px) {
    flex-direction: column;
  }
`

export const DashboardContentContainer = styled.div`
  padding: 0 15px;
  max-width: 1024px;
  flex: 1 1 auto;

  @media screen and (max-width: 600px) {
    padding: 50px 40px;
  }
`

export const ShortPulseTitleSkeleton = styled.div`
  width: 100%;
  height: 26px;
  margin-top: 2px;
  border-radius: 7px;
  ${ShortPulse};

  @media screen and (max-width: 769px) {
    width: 100%;
    max-width: 100%;
  }
`

export const TitleSkeleton = styled.div`
  width: 200px;
  height: 26px;
  margin-top: 2px;
  border-radius: 7px;
  ${Pulse};

  @media screen and (max-width: 769px) {
    width: 100%;
    max-width: 100%;
  }
`

export const ContentSkeleton = styled.div`
  width: 100%;
  height: 70vh;
  margin-top: 12px;
  border-radius: 7px;
  ${Pulse};

  @media screen and (max-width: 769px) {
    width: 100%;
    max-width: 100%;
  }
`
