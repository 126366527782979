import React, { Fragment } from 'react'
import { Link } from '@reach/router'
import styled, { css } from 'styled-components'

// config
import constants from 'config/constants'

// assets
import { Favorite } from '@styled-icons/material-twotone'
import { CalendarHeart } from '@styled-icons/boxicons-regular'
import { ReactComponent as HasLocationIcon } from 'assets/images/icons/opp-location.svg'
import { ReactComponent as NoLocationIcon } from 'assets/images/icons/opp-no-location.svg'
import { ReactComponent as DonationIcon } from 'assets/images/cause-type-icons/money_icon.svg'
import { ReactComponent as GoodsIcon } from 'assets/images/cause-type-icons/goods_icon_brown.svg'
import { ReactComponent as ChallengeIcon } from 'assets/images/cause-type-icons/challenge_icon.svg'
import { ReactComponent as VolunteerIcon } from 'assets/images/cause-type-icons/volunteer_icon.svg'

// utils
import th from 'utils/themeHelper'

// Making this a big enough number for the border to be round
// If there's a better way to do this, feel free to change
const BORDER_RADIUS = '50px'

const getGoalLabel = goalType => {
  if (goalType === 'Money') return 'Donate money'
  if (goalType === 'Goods') return 'Donate goods'
  if (goalType === 'Challenge') return 'Join challenge'
  if (goalType === 'Volunteering') return 'Volunteer your time'

  return goalType
}

const GoalTypeIcon = ({ goalType }) => {
  if (!goalType) return null

  switch (goalType) {
    case constants.OPPORTUNITY_GOAL_TYPES.MONEY:
      return <DonationIcon fill={'#eecb0e'} />
    case constants.OPPORTUNITY_GOAL_TYPES.GOODS:
      return <GoodsIcon />
    case constants.OPPORTUNITY_GOAL_TYPES.VOLUNTEERING:
      return <VolunteerIcon />
    case constants.OPPORTUNITY_GOAL_TYPES.CHALLENGE:
      return <ChallengeIcon />

    default:
      return null
  }
}

export const CardOverlay = styled.div`
  height: 150px;
  background-image: url(${({ src }) => src});
  background-size: cover;
  background-position: center center;
`

export const HasAppliedNotice = styled.div`
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  height: 150px;
  width: 100%;
`

export const HasAppliedNoticeWrapper = styled.div`
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  height: 100%;
  background-color: ${th('text.dark')};
  opacity: 0.75;
  width: 100%;
  display: flex;
  align-items: center;
`

export const ActionLink = styled(Link)`
  text-decoration: none;
  margin-bottom: 15px;
`

export const Actions = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  margin: 0 20px;
`

export const CardBannerWrapper = styled.div`
  color: ${th('secondary.base')};
  width: 100%;
  height: 35%;
  margin: 0 auto;
`

export const CardBannerWrapperAbsolute = styled.div`
  top: 0%;
  width: 100%;
  position: absolute;
  height: 150px;
  display: flex;
  flex-direction: column;
  justify-content: center;
`

export const CardBannerContainer = styled.div`
  height: 100%;
  margin: 0 auto;
  display: flex;
  justify-content: center;
`

export const CardContent = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px;
  position: relative;
  flex: 1;

  h4 {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
`

export const CardLinkWrapper = styled.div`
  background: white;
  color: inherit;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  max-height: calc(100% - 150px);
  text-decoration: none;
  width: 100%;
`

export const Title = styled.h4`
  line-height: 1.35em;
  margin: 0 0 10px 3px;
  color: ${th('text.dark')};
`

export const CardBannerLabel = styled.h5`
  font-size: 13px;
  line-height: 13px;
`

export const Row = styled.div`
  margin-top: 0.5em;
  display: flex;
  align-items: center;
  position: relative;
`

export const BeneficiaryPill = styled.div`
  align-items: center;
  background: ${props => (props.personal ? '#E0F3BB' : '#00000099')};
  border-radius: ${BORDER_RADIUS};
  display: flex;
  margin-right: 0.5em;
  margin-top: 0.5em;
  padding: 5px 15px;
  position: relative;
  float: right;
`

export const SpacedRow = styled(Row)`
  justify-content: space-between;
`

export const Icon = styled.img`
  height: ${({ size }) => size || 20}px;
  width: ${({ size }) => size || 20}px;
`

export const AssetWrapper = styled.div`
  width: 20px;
  height: 20px;
  display: inline-block;
  align-items: center;
  justify-content: center;
`

const IconFillProperty = css`
  fill: ${props => props.fill};
`

const TheIcon = styled.div`
  color: ${props => props.color || th('primary.base')};
  display: flex;
  height: 30px;
  margin-right: 10px;
  position: relative;
  width: 30px;

  & > svg {
    ${props => (props.fill ? IconFillProperty : null)};
    height: 30px;
    width: 30px;
  }
`

const DateLocationWrapper = styled.div`
  align-items: center;
  display: flex;
  justify-content: flex-start;
  margin-top: 0.25em;
`

const AdditionalOpportunityDetailsWrapper = styled.div`
  align-items: center;
  display: flex;
  justify-content: flex-start;
`

const AdditionalOpportunityDetailsLabel = styled.div`
  color: ${th('text.dark')};
  display: block;
  font-size: 12px;
  margin: 1em 0;
`

const IconLabel = styled.div`
  color: ${th('text.dark')};
  display: block;
  font-size: 12px;
  margin: 1em 0;
`

export const CategoryTypeWrapper = styled.div`
  margin: 20px;
  padding: 1em;
`

const OpportunityDescriptionWrapper = styled.div`
  line-height: 1.25em;
  color: ${th('text.dark')};
  display: block;
  font-size: 12px;
  margin: 0 3px 8px;

  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
`

const GoalTypeLabel = styled.div`
  font-family: ${th('fonts.bold')};
`

const ExpiryWrapper = styled.div`
  display: block;
  position: relative;
`

const ExpiryMessage = styled.div`
  align-items: center;
  background: #333333cc;
  color: white;
  display: flex;
  font-family: ${th('fonts.bold')};
  font-size: 15px;
  height: 100%;
  justify-content: center;
  padding: 1em;
  position: absolute;
  width: 100%;
`

export const UserJoinedLabel = styled.div`
  display: inline-block;
  margin-left: 10px;
  vertical-align: middle;
`

export const UserJoinedIcon = styled.div`
  background: #000000aa;
  border-radius: ${BORDER_RADIUS};
  color: ${props => (props.withdrew ? th('text.error') : 'gold')};
  display: block;
  left: calc(50% - 54px);
  position: absolute;
  padding: 5px 15px;
  top: 58px;

  svg {
    stroke-width: 3px;
    color: ${props => (props.withdrew ? th('text.error') : 'gold')};
    height: 24px;
    width: 24px;
  }
`

export const VerificationWrapper = styled.div`
  align-items: center;
  background: #00000099;
  border-radius: ${BORDER_RADIUS};
  display: block;
  margin-left: 0.5em;
  margin-top: 0.5em;
  padding: 5px 15px;
  position: relative;
  float: left;
`

export const OppStatusPillWrapper = styled.div`
  align-items: center;
  background: #00000099;
  border-radius: 3px;
  display: block;
  margin-right: 0.5em;
  margin-top: 0.5em;
  padding: 5px 15px;
  position: relative;
  float: right;
`

export const OppStatusPill = styled.div`
  color: white;
  display: inline-block;
  font-size: 10px;
  vertical-align: middle;
`

export const VerificationStatus = styled.div`
  color: white;
  display: inline-block;
  font-size: 10px;
  margin-left: 5px;
  vertical-align: middle;
`

export const VerificationIcon = styled.img`
  display: inline-block;
  width: 20px;
  height: 20px;
  vertical-align: middle;
`

export const OpportunityDescription = ({ description }) => {
  if (!description) return null

  return <OpportunityDescriptionWrapper>{description}</OpportunityDescriptionWrapper>
}

export const ExpiryNotice = ({ children, expired }) => {
  if (!expired) return <Fragment>{children}</Fragment>

  return (
    <ExpiryWrapper>
      <ExpiryMessage>PAST EVENT</ExpiryMessage>
      <Fragment>{children}</Fragment>
    </ExpiryWrapper>
  )
}

export const AdditionalOpportunityDetails = ({ goalType }) => {
  return (
    <AdditionalOpportunityDetailsWrapper>
      <TheIcon>
        <GoalTypeIcon goalType={goalType} />
      </TheIcon>

      <AdditionalOpportunityDetailsLabel>
        <GoalTypeLabel>{getGoalLabel(goalType)}</GoalTypeLabel>
      </AdditionalOpportunityDetailsLabel>
    </AdditionalOpportunityDetailsWrapper>
  )
}

export const IconWrapper = ({ infoType, value, endDate }) => {
  if (infoType === 'Frequency') {
    const label = value ? constants.CHALLENGE_FREQUENCIES_REVERSED[value] : null
    const frequencyLabel = label ? `Participate ${label.toLowerCase()}` : 'Participate at any time'

    return (
      <DateLocationWrapper>
        <TheIcon>
          <CalendarHeart />
        </TheIcon>

        <IconLabel>{frequencyLabel}</IconLabel>
      </DateLocationWrapper>
    )
  }

  const multiDayOpp = infoType === 'Date' && value && endDate && value !== endDate

  if (multiDayOpp) {
    return (
      <DateLocationWrapper>
        <TheIcon>
          <CalendarHeart />
        </TheIcon>

        <IconLabel>
          {value.replace(/(\d\d?)th|st/, '$1')} - {endDate.replace(/(\d\d?)th|st/, '$1')}
        </IconLabel>
      </DateLocationWrapper>
    )
  }

  if (infoType === 'Date') {
    const dateLabel = value || 'Participate at any time'

    return (
      <DateLocationWrapper>
        <TheIcon>
          <CalendarHeart />
        </TheIcon>

        <IconLabel>{dateLabel.replace(/(\d\d?)th|st/, '$1')}</IconLabel>
      </DateLocationWrapper>
    )
  }

  if (infoType === 'Location') {
    const LocationIcon = value ? HasLocationIcon : NoLocationIcon
    const locationLabel = value || 'Participate from anywhere'

    return (
      <DateLocationWrapper>
        <TheIcon fill="#379b75">
          <LocationIcon />
        </TheIcon>

        <IconLabel>{locationLabel}</IconLabel>
      </DateLocationWrapper>
    )
  }

  if (infoType === 'Categories') {
    return (
      <DateLocationWrapper>
        <TheIcon>
          <Favorite />
        </TheIcon>
        <IconLabel>{value}</IconLabel>
      </DateLocationWrapper>
    )
  }

  return null
}

export const Logo = styled.img`
  object-fit: cover;
  height: 20px;
  width: 20px;
  border-radius: 50%;
  border: 1px solid ${th('borders.default')};
`

export const BeneficiaryName = styled.p`
  color: ${props => (props.personal ? th('text.dark') : 'white')};
  display: inline-block;
  font-family: ${th('fonts.bold')};
  font-size: 10px;
  margin: ${props => (props.personal ? '0' : '0 8px 0 0')};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`

export const IconButton = styled.button`
  border: none;
  outline: none;
  background-color: transparent;
`
