import React from 'react'
import styled from 'styled-components'

// utils and config
import th from 'utils/themeHelper'
import constants from 'config/constants'

// Load components synchronously
import ConditionalDisplay from 'components/ConditionalDisplay/ConditionalDisplay'
import { CheckCircle, Clock } from 'components/Icons/Icons'

const InviteList = styled.div`
  margin-top: 4em;
`

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  height: 50px;
  border-bottom: 1px solid ${th('borders.default')};
`

const ActionLabel = styled.label`
  cursor: pointer;
`

const PendingLabel = styled.label`
  font-family: 'ProximaNovaRegularIt';
  color: ${th('text.light')};
`

const InfoField = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: baseline;
  margin: 1em 0;
`

const CheckIcon = styled(CheckCircle)`
  width: 25px;
  height: 25px;
  color: ${th('secondary.base')};
  margin-top: -3px;
  margin-right: 1em;
`

const ClockIcon = styled(Clock)`
  width: 25px;
  height: 25px;
  color: ${th('borders.default')};
  margin-top: -3px;
  margin-right: 1em;
`

const Title = styled.h4``

const DashboardInviteRow = ({ id, email, status, onDelete }) => (
  <Wrapper>
    <InfoField>
      {status === constants.USER_STATUSES.ACCEPTED ? <CheckIcon /> : <ClockIcon />}
      {email}
    </InfoField>

    {status === constants.USER_STATUSES.ACCEPTED ? (
      <ActionLabel onClick={() => onDelete(id)}>remove</ActionLabel>
    ) : (
      <PendingLabel>Not responded</PendingLabel>
    )}
  </Wrapper>
)

const DashboardInviteList = ({ people, onDelete }) => (
  <InviteList>
    <Title>Who has been invited?</Title>

    {people.map(person => (
      <DashboardInviteRow key={person.email} {...person} onDelete={onDelete} />
    ))}

    <ConditionalDisplay displayWhen={[people.length === 0]}>
      <p>You haven't invited anyone yet.</p>
    </ConditionalDisplay>
  </InviteList>
)

export default DashboardInviteList
