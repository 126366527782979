import React, { Fragment } from 'react'
import styled from 'styled-components'

// utils
import th from 'utils/themeHelper'
import UrlBuilder from 'utils/urlBuilder'

const DismissButton = styled.div`
  border: 1px solid ${th('borders.default')};
  border-radius: 4px;
  cursor: pointer;
  margin: 10px 0 0;
  padding: 5px 10px;
  white-space: nowrap;

  @media screen and (min-width: 769px) {
    margin: 0;
  }

  &:hover {
    border: 1px solid ${th('borders.dark')};
  }
`

const HeaderBar = styled.div`
  background-color: ${th('primary.light')};
  width: 100%;
  height: 10px;

  @media screen and (min-width: 769px) {
    display: none;
  }
`

const HeaderContainerInner = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 10px;
  text-align: ${props => (props.displayFormBar ? 'center' : 'left')};

  @media screen and (min-width: 769px) {
    flex-direction: row;
  }
`

const HeaderContainer = styled.div`
  background-color: white;
  border-color: ${th('primary.light')};
  border-style: solid;
  border-width: 2px;
  line-height: 1.25em;
  margin: ${props => (props.displayFormBar ? '0' : '20px 0 20px')};
  max-width: ${props => props.maxWidth || '100%'};
  position: ${props => (props.displayFormBar ? 'fixed' : 'relative')};
  top: ${props => (props.displayFormBar ? '65px' : '50px')};
  width: 100%;
  z-index: ${props => (props.displayFormBar ? '9999' : '1')};

  @media screen and (min-width: 769px) {
    margin: ${props => (props.displayFormBar ? '0' : '0 0 20px 0')};
    top: ${props => (props.displayFormBar ? '65px' : '0')};
  }
`

const EmailConfirmedBanner = props => {
  return (
    <HeaderContainer {...props}>
      <HeaderBar />

      <HeaderContainerInner {...props}>
        <Fragment>
          <span>Thank you for confirming your email address.</span>

          <DismissButton onClick={() => UrlBuilder.dissmissEmailConfirmedMessage()}>
            Dismiss
          </DismissButton>
        </Fragment>
      </HeaderContainerInner>
    </HeaderContainer>
  )
}

export default EmailConfirmedBanner
