import { call, put, takeLatest } from 'redux-saga/effects'
import * as emailActivityApi from '../apiModules/emailActivity'
import * as emailActivityModule from '../modules/emailActivity'

function* fetchEmailActivity({ payload }) {
  try {
    const response = yield call(emailActivityApi.fetchEmailActivity, payload)
    const result = { ...response, forceRefresh: payload.forceRefresh }
    yield put(emailActivityModule.fetchEmailActivitySuccess(result))
  } catch (err) {
    yield put(emailActivityModule.fetchEmailActivityFailure(err))
  }
}

export const emailActivitySagas = [
  takeLatest(emailActivityModule.FETCH_EMAIL_ACTIVITY, fetchEmailActivity)
]
