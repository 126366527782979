import axios from 'axios'

// config
import baseUrl from '../config'

export const fetchEmailActivity = async params => {
  const response = await axios.get(`${baseUrl}/email-activity`, { withCredentials: true, params })

  return response.data
}
