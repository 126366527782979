import LoadableVisibility from 'react-loadable-visibility/react-loadable'

// Do not use AppLoadable inside of any component that itself was loaded with AppLoadable
// Recursive async loading can introduce scrolling and other bugs

export default function AppLoadable(opts) {
  return LoadableVisibility(
    Object.assign(
      {
        loading: () => null,
        delay: 200,
        timeout: 10000
      },
      opts
    )
  )
}
