import styled from 'styled-components'
import lighten from 'polished/lib/color/lighten'

const Error = styled.div.attrs({
  'data-cy': 'component-error'
})`
  font-size: 12px;
  color: #db3a1b;
  background: ${lighten(0.45, '#db3a1b')};
  padding: 8px;
  border: 1px solid #db3a1b;
`

export default Error
