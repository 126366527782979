import axios from 'axios'

// redux
import baseUrl from '../config'

// utils
import cleanPayload from 'utils/cleanPayload'

export const fetchCampaigns = async params => {
  const response = await axios.get(`${baseUrl}/campaigns`, { withCredentials: true, params })
  return response.data
}

export const fetchAllCampaigns = async () => {
  const response = await axios.get(`${baseUrl}/all-campaigns`, { withCredentials: true })

  return response.data
}

export const fetchOwnCampaigns = async (payload = {}) => {
  const cleanedPayload = cleanPayload(payload)

  const response = await axios.get(`${baseUrl}/own-campaigns`, {
    withCredentials: true,
    params: { ...cleanedPayload }
  })

  return response.data
}

export const fetchCampaign = async ({ campaignId, pvtShareKey }) => {
  const response = await axios(`${baseUrl}/campaigns/${campaignId}`, {
    withCredentials: true,
    method: 'GET',
    responseType: 'json',
    params: { pvtShareKey }
  })

  return response.data
}

export const createCampaign = async data => {
  return await axios({
    withCredentials: true,
    method: 'post',
    url: `${baseUrl}/campaigns`,
    data,
    config: { headers: { 'Content-Type': 'multipart/form-data' } }
  })
}

export const updateCampaign = async campaign => {
  const response = await axios({
    withCredentials: true,
    method: 'put',
    url: `${baseUrl}/campaigns/${campaign.get('campaignId')}`,
    data: campaign,
    config: { headers: { 'Content-Type': 'multipart/form-data' } }
  })
  return response.data
}

export const deleteCampaign = async campaignId => {
  return await axios.delete(`${baseUrl}/campaigns/${campaignId}`, {
    withCredentials: true,
    data: { campaignId }
  })
}
