import styled from 'styled-components'
import { Link } from '@reach/router'
import th from '../../utils/themeHelper'

const FooterColumnLink = styled(Link)`
  color: white;
  line-height: 30px;

  &:hover {
    transition: 0.3s color;
    color: ${th('secondary.base')};
  }
`

export default FooterColumnLink
