import React from 'react'
import styled from 'styled-components'
import { Field } from 'formik'

// utils
import th from 'utils/themeHelper'

// config
import l from 'config/localization'

// styles
import { FormSectionRow } from './styles'

// Load components synchronously
import StaticSelect from 'components/Select/StaticSelect'

// icons
import { Settings } from 'styled-icons/ionicons-outline'

const FieldRowInner = styled.div`
  display: flex;
`

const Label = styled.div`
  font-size: 13px;
  color: ${props => (props.disabled ? th('text.light') : th('backgrounds.dark'))};
  padding: ${props => (props.innerLabel ? '0 2px 2px 0' : '0 2px')};
  margin-bottom: ${props => (props.innerLabel ? '0' : '2px')};
  font-family: ${th('fonts.bold')};
  position: relative;
  line-height: 1.25em;
  text-decoration: ${props => (props.nonUnderlined ? 'non' : 'underline')};
  width: max-content;

  @media screen and (max-width: 769px) {
    max-width: 100%;
  }

  &:hover {
    cursor: ${props => (props.nonUnderlined ? 'default' : 'pointer')};
  }
`

const SubLabel = styled.span`
  font-size: 12px;
  color: ${th('text.light')};
  padding: 0;
  position: relative;
  margin: -2px 2px 6px;
  font-family: ${th('fonts.light')};
  line-height: 1.25em;
`

const ExpandIcon = styled.div`
  display: inline-block;
  margin-right: 8px;
  margin-top: 2px;
  vertical-align: top;

  svg {
    border: 1px solid ${th('borders.default')};
    border-radius: 4px;
    height: 30px;
    padding: 2px;
    width: 30px;

    &:hover {
      border: 1px solid ${th('borders.dark')};
      cursor: pointer;
    }
  }
`

const DonationContextSection = ({
  extraInfoModalOpts,
  formValues,
  hasPersonalSub,
  isSuperAdmin,
  orgOpts,
  setFieldValue,
  setState,
  showContextField
}) => {
  if (!isSuperAdmin) return null

  const labelText = hasPersonalSub ? 'Select an organisation' : "You're making a personal donation"
  const labelSubText = hasPersonalSub
    ? 'You already have a personal donation.'
    : `To donate on behalf of one of your ${l('ORGS')}, click the settings icon.`

  // recurring donations from orgs needs work on the back-end
  const isRecurringDonation = formValues.donation_frequency === 'recurring'
  if (isRecurringDonation) {
    return (
      <FormSectionRow>
        <Label nonUnderlined>You're making a personal donation</Label>

        <div>
          <SubLabel>Recurring donations from organisations are coming soon.</SubLabel>
        </div>
      </FormSectionRow>
    )
  }

  if (!showContextField) {
    return (
      <FieldRowInner>
        <ExpandIcon>
          <Settings onClick={() => setState({ showContextField: true })} />
        </ExpandIcon>

        <div style={{ display: 'inline-block' }}>
          <Label onClick={() => setState({ showContextField: true })}>{labelText}</Label>

          <SubLabel>{labelSubText}</SubLabel>
        </div>
      </FieldRowInner>
    )
  }

  return (
    <Field
      component={StaticSelect}
      forceLabel
      label="In which capacity are you making this donation?"
      name="chosen_donation_context"
      onChange={e => {
        if (extraInfoModalOpts) {
          extraInfoModalOpts.updateFormValues({ ...formValues, chosen_donation_context: e.value })
        }

        if (setFieldValue) {
          setFieldValue({ chosen_donation_context: e.value })
        }
      }}
      options={orgOpts}
      placeholder="Select an option"
      subLabel="You can either donate in a personal capacity or you can make a donation on behalf of any of your organisations"
    />
  )
}

export default DonationContextSection
