import styled from 'styled-components'

export const ContentContainer = styled.div`
  padding: 0;
`

export const FieldRow = styled.div`
  display: block;
  margin-bottom: 2em;
`

export const IconButton = styled.button`
  position: fixed;
  right: 10px;
  top: 10px;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: #379b75;
  color: white;
  z-index: 9999;
  display: none;

  &,
  &:active,
  &:hover {
    outline: none;
    border: none;
  }

  @media screen and (max-width: 769px) {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`

export const OnboardingContainer = styled.div`
  background: whitesmoke;
`
