import React, { Component } from 'react'
import { connect } from 'react-redux'
import { navigate } from '@reach/router'

// assets
import { ReactComponent as OrgIcon } from 'assets/images/svgs/icon-org-page-organisation.svg'
import { CalendarHeart, Dashboard } from '@styled-icons/boxicons-solid'
import { Calendar2Event as Calendar } from '@styled-icons/bootstrap'
import { Settings, LogOut, HelpBuoy } from 'styled-icons/ionicons-outline'
import { AccountBox, Login } from '@styled-icons/material'
import { Gem } from '@styled-icons/fa-regular/Gem'
import { Profile } from '@styled-icons/remix-fill/Profile'

// config
import l, { isDgmt, isSef } from 'config/localization'

// redux
import * as authModule from '../../redux/modules/authentication'

// styles
import {
  ArrowTab,
  ArrowWrapper,
  DotIcon,
  FullScreenModal,
  FullScreenModalButtonsContainer,
  IconContainer,
  KeyboardTipContainer,
  MenuButton,
  MenuButtonHint,
  MenuButtonName,
  ModalRowHeader,
  ModalRowWrapper,
  OptionArrowWrapper
} from './styles'

// Load components synchronously
import ConditionalDisplay from 'components/ConditionalDisplay/ConditionalDisplay'

const isTouchDevice = () => {
  return 'ontouchstart' in window || navigator.maxTouchPoints > 0 || navigator.msMaxTouchPoints > 0
}

const SecondaryKeyboardTip = ({ value }) => {
  const hideKeyboardTip = isTouchDevice()
  if (hideKeyboardTip) return null

  return (
    <OptionArrowWrapper>
      <ArrowTab>{value}</ArrowTab>
    </OptionArrowWrapper>
  )
}

const KeyboardTip = () => {
  const hideKeyboardTip = isTouchDevice()
  if (hideKeyboardTip) return null

  return (
    <KeyboardTipContainer>
      <ArrowWrapper>
        Hit <ArrowTab>ESC</ArrowTab> to toggle this menu.
      </ArrowWrapper>
    </KeyboardTipContainer>
  )
}

const countUnreadNotifications = ({ notifications }) => {
  if (!notifications) return 0
  return notifications.filter(n => n.status === 'Unread').length
}

const deriveDashboardCardText = ({ unreadNotifsCount }) => {
  const fallback = `Visit your dashboard where you can create and manage ${l('OPPS')}, ${l(
    'ORGS'
  )} and more.`

  if (!unreadNotifsCount) return fallback

  return `You have ${unreadNotifsCount} unread notifications.`
}

const DgmtMenuModal = ({ closeMenu, loggedIn, logout }) => {
  return (
    <FullScreenModal tabIndex="-1" ref={input => input && input.focus()}>
      <FullScreenModalButtonsContainer>
        <ModalRowHeader>Quick shortcuts</ModalRowHeader>

        <ModalRowWrapper>
          <ConditionalDisplay displayWhen={[!loggedIn]}>
            <MenuButton
              onClick={() => {
                closeMenu()
                navigate(`/login`)
              }}
            >
              <IconContainer color="blue">
                <Login />
              </IconContainer>

              <MenuButtonName>Log in</MenuButtonName>
              <MenuButtonHint>
                If you already have a Brownie Points account, click here to log in.
              </MenuButtonHint>

              <SecondaryKeyboardTip value={'1'} />
            </MenuButton>
          </ConditionalDisplay>

          <ConditionalDisplay displayWhen={[loggedIn]}>
            <MenuButton
              onClick={() => {
                closeMenu()
                navigate('/dashboard')
              }}
            >
              <IconContainer color="blue">
                <Dashboard />
              </IconContainer>

              <MenuButtonName>Dashboard</MenuButtonName>
              <MenuButtonHint>Go to dashboard</MenuButtonHint>

              <SecondaryKeyboardTip value={'1'} />
            </MenuButton>
          </ConditionalDisplay>

          <MenuButton
            onClick={() => {
              closeMenu()
              navigate(loggedIn ? '/new-website' : `/new-pbo`)
            }}
          >
            <IconContainer color="blue">
              <AccountBox />
            </IconContainer>

            <MenuButtonName>{loggedIn ? 'Submit website' : 'Apply as PBO'}</MenuButtonName>
            <MenuButtonHint>Submit an application to zero-rate your websites.</MenuButtonHint>
          </MenuButton>

          <MenuButton
            onClick={() => {
              closeMenu()
              navigate(`/discover`)
            }}
          >
            <IconContainer color="blue">
              <Settings />
            </IconContainer>

            <MenuButtonName>Discover</MenuButtonName>
            <MenuButtonHint>Find zero-rated websites.</MenuButtonHint>
          </MenuButton>

          <ConditionalDisplay displayWhen={[loggedIn]}>
            <MenuButton
              onClick={() => {
                closeMenu()
                logout()
                navigate(`/login`)
              }}
            >
              <IconContainer color="blue">
                <LogOut />
              </IconContainer>

              <MenuButtonName>Log out</MenuButtonName>
              <MenuButtonHint>Log out of this session.</MenuButtonHint>
            </MenuButton>
          </ConditionalDisplay>
        </ModalRowWrapper>

        <ModalRowHeader />
      </FullScreenModalButtonsContainer>

      <KeyboardTip />
    </FullScreenModal>
  )
}

class MenuModal extends Component {
  closeMenu() {
    this.props.toggleMenu({ displayMenu: false })
  }

  render() {
    const { displayMenu, loggedIn, notifications, userData } = this.props

    if (!displayMenu) return null

    if (isDgmt) {
      return (
        <DgmtMenuModal {...this.props} closeMenu={() => this.closeMenu()} loggedIn={loggedIn} />
      )
    }

    const unreadNotifsCount = countUnreadNotifications({ notifications })
    const dashboardCardText = deriveDashboardCardText({ unreadNotifsCount })

    return (
      <FullScreenModal tabIndex="-1" ref={input => input && input.focus()}>
        <FullScreenModalButtonsContainer>
          <ConditionalDisplay displayWhen={[loggedIn]}>
            <ModalRowHeader>Quick shortcuts</ModalRowHeader>

            <ModalRowWrapper>
              <MenuButton
                onClick={() => {
                  this.closeMenu()
                  navigate(unreadNotifsCount ? '/dashboard/notifications' : '/dashboard')
                }}
              >
                <IconContainer color="blue">
                  <Dashboard />
                </IconContainer>

                <DotIcon showNotificationDot={unreadNotifsCount} />
                <MenuButtonName>Dashboard</MenuButtonName>
                <MenuButtonHint isRed={unreadNotifsCount}>{dashboardCardText}</MenuButtonHint>

                <SecondaryKeyboardTip value={'1'} />
              </MenuButton>

              <ConditionalDisplay displayWhen={[!isSef]}>
                <MenuButton
                  onClick={() => {
                    this.closeMenu()
                    const profilePath = userData.username
                      ? `/@${userData.username}`
                      : `/users/${userData.id}`
                    navigate(profilePath)
                  }}
                >
                  <IconContainer color="blue">
                    <AccountBox />
                  </IconContainer>

                  <MenuButtonName>Profile</MenuButtonName>
                  <MenuButtonHint>
                    Your very own profile page where you can see your activity and the {l('OPPS')}{' '}
                    you've joined.
                  </MenuButtonHint>

                  <SecondaryKeyboardTip value={'2'} />
                </MenuButton>
              </ConditionalDisplay>

              <MenuButton
                onClick={() => {
                  this.closeMenu()
                  navigate(`/dashboard/account`)
                }}
              >
                <IconContainer color="blue">
                  <Settings />
                </IconContainer>

                <MenuButtonName>Account</MenuButtonName>
                <MenuButtonHint>
                  Manage your account details, profile picture, as well as your preferred
                  {l('CATS')}.
                </MenuButtonHint>
              </MenuButton>

              <ConditionalDisplay displayWhen={[!isSef]}>
                <MenuButton
                  onClick={() => {
                    this.closeMenu()
                    navigate(`/`)
                  }}
                >
                  <IconContainer color="blue">
                    <Settings />
                  </IconContainer>

                  <MenuButtonName>Home</MenuButtonName>
                  <MenuButtonHint>Go to the Brownie Points homepage</MenuButtonHint>
                </MenuButton>
              </ConditionalDisplay>

              <MenuButton
                onClick={() => {
                  this.closeMenu()
                  this.props.logout()
                  navigate(`/login`)
                }}
              >
                <IconContainer color="blue">
                  <LogOut />
                </IconContainer>

                <MenuButtonName>Log out</MenuButtonName>
                <MenuButtonHint>Log out of this session.</MenuButtonHint>
              </MenuButton>
            </ModalRowWrapper>
          </ConditionalDisplay>

          <ConditionalDisplay displayWhen={[!loggedIn]}>
            <ModalRowHeader>Join us</ModalRowHeader>

            <ModalRowWrapper>
              <MenuButton
                onClick={() => {
                  this.closeMenu()
                  navigate(`/login`)
                }}
              >
                <IconContainer color="blue">
                  <Login />
                </IconContainer>

                <MenuButtonName>Log in</MenuButtonName>
                <MenuButtonHint>
                  If you already have a Brownie Points account, click here to log in.
                </MenuButtonHint>

                <SecondaryKeyboardTip value={'1'} />
              </MenuButton>

              <MenuButton
                onClick={() => {
                  this.closeMenu()
                  navigate(`/join`)
                }}
              >
                <IconContainer color="blue">
                  <Dashboard />
                </IconContainer>

                <MenuButtonName>Sign up</MenuButtonName>
                <MenuButtonHint>
                  Join Brownie Points and start with your philanthropy adventure.
                </MenuButtonHint>
              </MenuButton>
            </ModalRowWrapper>
          </ConditionalDisplay>

          <ConditionalDisplay displayWhen={[false, isSef]}>
            <ModalRowHeader>Actions</ModalRowHeader>

            <ModalRowWrapper>
              <MenuButton
                onClick={() => {
                  this.closeMenu()
                  navigate(`/dashboard/suppliers`)
                }}
              >
                <IconContainer color="#ED9920" size="40px">
                  <CalendarHeart />
                </IconContainer>

                <MenuButtonName>Suppliers</MenuButtonName>
                <MenuButtonHint>Add or explore suppliers.</MenuButtonHint>
              </MenuButton>

              <ConditionalDisplay displayWhen={[false, loggedIn]}>
                <MenuButton
                  onClick={() => {
                    this.closeMenu()
                    navigate(`/dashboard/purchase-requests`)
                  }}
                >
                  <IconContainer color="#ED9920">
                    <CalendarHeart />
                  </IconContainer>

                  <MenuButtonName>Purchase Requests</MenuButtonName>
                  <MenuButtonHint>
                    Submit a new purchase request or search existing requests.
                  </MenuButtonHint>
                </MenuButton>
              </ConditionalDisplay>
            </ModalRowWrapper>
          </ConditionalDisplay>

          <ConditionalDisplay displayWhen={[!isSef]}>
            <ModalRowHeader>Community</ModalRowHeader>

            <ModalRowWrapper>
              <MenuButton
                onClick={() => {
                  this.closeMenu()
                  navigate(`/feed`)
                }}
              >
                <IconContainer color="#ED9920" size="40px">
                  <CalendarHeart />
                </IconContainer>

                <MenuButtonName>Explore stories</MenuButtonName>
                <MenuButtonHint>Explore good deeds on Brownie Points.</MenuButtonHint>
              </MenuButton>

              <MenuButton
                onClick={() => {
                  this.closeMenu()
                  navigate(`/search/challenges`)
                }}
              >
                <IconContainer color="#ED9920">
                  <CalendarHeart />
                </IconContainer>

                <MenuButtonName>Search challenges</MenuButtonName>
                <MenuButtonHint>
                  Find challenges that you can join to earn Brownie Points.
                </MenuButtonHint>

                <SecondaryKeyboardTip value={'3'} />
              </MenuButton>

              <MenuButton
                onClick={() => {
                  this.closeMenu()
                  navigate(`/search/${l('ROUTE_OPPS')}`)
                }}
              >
                <IconContainer color="#ED9920" size="40px">
                  <Calendar />
                </IconContainer>

                <MenuButtonName>Search {l('OPPS')}</MenuButtonName>
                <MenuButtonHint>Find opportunities where you can help.</MenuButtonHint>

                <SecondaryKeyboardTip value={'4'} />
              </MenuButton>

              <MenuButton
                onClick={() => {
                  this.closeMenu()
                  navigate(`/search/nonprofits`)
                }}
              >
                <IconContainer color="#ED9920">
                  <OrgIcon fill="#ED9920" />
                </IconContainer>

                <MenuButtonName>Search nonprofits</MenuButtonName>
                <MenuButtonHint>
                  Discover nonprofits close to you, or those that need your help.
                </MenuButtonHint>

                <SecondaryKeyboardTip value={'5'} />
              </MenuButton>

              <MenuButton
                onClick={() => {
                  this.closeMenu()
                  navigate(`/search/companies`)
                }}
              >
                <IconContainer color="#ED9920">
                  <OrgIcon fill="#ED9920" />
                </IconContainer>

                <MenuButtonName>Search companies</MenuButtonName>
                <MenuButtonHint>Discover companies that are doing good things.</MenuButtonHint>

                <SecondaryKeyboardTip value={'6'} />
              </MenuButton>
            </ModalRowWrapper>

            <ModalRowHeader>Brownie Points</ModalRowHeader>

            <ModalRowWrapper>
              <MenuButton
                onClick={() => {
                  window.open('https://browniepoints.freshdesk.com', '_blank').focus()
                }}
              >
                <IconContainer color="red">
                  <HelpBuoy />
                </IconContainer>

                <MenuButtonName>Help</MenuButtonName>
                <MenuButtonHint>
                  Confused about anything? Our help desk might be able to assist you.
                </MenuButtonHint>
              </MenuButton>

              <MenuButton
                onClick={() => {
                  this.closeMenu()
                  navigate(`/about-us`)
                }}
              >
                <IconContainer color="red">
                  <Profile />
                </IconContainer>

                <MenuButtonName>Our story</MenuButtonName>
                <MenuButtonHint>
                  Read about the Brownie Points mission and our goal of 1 million good deeds.
                </MenuButtonHint>
              </MenuButton>

              <ConditionalDisplay displayWhen={[!userData.part_of_subscribed_org]}>
                <MenuButton
                  onClick={() => {
                    this.closeMenu()
                    navigate(`/pricing`)
                  }}
                >
                  <IconContainer color="red">
                    <Gem />
                  </IconContainer>

                  <MenuButtonName>Pricing</MenuButtonName>
                  <MenuButtonHint>
                    Discover how your {l('ORG')} can do even more good with our premium features.
                  </MenuButtonHint>
                </MenuButton>
              </ConditionalDisplay>
            </ModalRowWrapper>
          </ConditionalDisplay>

          <ModalRowHeader />
        </FullScreenModalButtonsContainer>

        <KeyboardTip />
      </FullScreenModal>
    )
  }
}

const mapDispatchToProps = dispatch => ({
  logout: () => dispatch(authModule.logout())
})

const mapStateToProps = state => ({
  places: state.places.data.map(d => d.description),
  userData: state.authentication.userData,
  locationIsLoading: state.places.isLoading,
  anythingIsLoading:
    state.applications.isLoading ||
    state.campaigns.isLoading ||
    state.opportunities.isLoading ||
    state.organisations.isLoading ||
    state.people.isLoading,
  anythingIsSubmitting:
    state.applications.isSubmitting ||
    state.campaigns.isSubmitting ||
    state.opportunities.isSubmitting ||
    state.organisations.isSubmitting ||
    state.people.isSubmitting
})

export default connect(mapStateToProps, mapDispatchToProps)(MenuModal)
