import React, { Component } from 'react'

// styles
import {
  ScreenWrapper,
  ModalContainer,
  SocialMediaWrapper,
  CloseIcon,
  ModalHeader,
  ModalWrapper
} from './styles'

// config
import l from 'config/localization'
import constants from 'config/constants'

// Load components synchronously
import SocialMediaIcons from 'components/SocialMediaIcons/SocialMediaIcons'

class ShareModal extends Component {
  render() {
    const {
      shareModalOppTitle,
      shortDescription,
      shareModalHostName,
      hideModal,
      oppStatus
    } = this.props

    const isDraft = oppStatus === constants.OPPORTUNITY_STATUSES.DRAFT
    const isActive = oppStatus === constants.OPPORTUNITY_STATUSES.ACTIVE
    const statusLabel = isDraft ? 'drafted' : 'created'

    return (
      <ScreenWrapper>
        <ModalWrapper>
          <ModalContainer>
            <CloseIcon dark size={30} onClick={hideModal} />

            <ModalHeader>
              Nice one, {shareModalHostName}. Your {l('OPP')} has been {statusLabel}!
            </ModalHeader>

            {isActive && (
              <p>
                <strong>Now, make sure your {l('OPP')} is a success</strong>: share it with you
                friends, family or co-workers to reach more {l('SUPPORTERS')} and to make the most
                impact.
              </p>
            )}

            <p>
              Your {l('OPP')} details can be edited at any time. Make sure you keep the information
              up to date for your {l('SUPPORTERS')} as they will be notified of any changes.
            </p>

            <SocialMediaWrapper>
              <strong>Share this {l('OPP')}</strong>
              <SocialMediaIcons title={shareModalOppTitle} description={shortDescription} />
            </SocialMediaWrapper>
          </ModalContainer>
        </ModalWrapper>
      </ScreenWrapper>
    )
  }
}

export default ShareModal
