import React from 'react'
import { connect } from 'react-redux'
import { Redirect } from '@reach/router'
import * as authModule from '../../redux/modules/authentication'

import { ContentContainer, OnboardingContainer } from './styles'
import isLoggedIn from '../../utils/auth'

class OnboardingLayout extends React.Component {
  componentDidMount() {
    if (isLoggedIn()) {
      this.props.fetchMyRoles()
    }
  }

  render() {
    const { children } = this.props

    if (!isLoggedIn()) {
      const redirectPath = `/login?redirectTo=${location.href}`
      return <Redirect to={redirectPath} noThrow />
    }

    return (
      <OnboardingContainer>
        <ContentContainer>{children}</ContentContainer>
      </OnboardingContainer>
    )
  }
}

const mapDispatchToProps = dispatch => ({
  fetchMyRoles: () => dispatch(authModule.fetchMyRoles())
})

const mapStateToProps = state => ({
  userData: state.authentication.userData,
  roleData: state.authentication.roleData
})

export default connect(mapStateToProps, mapDispatchToProps)(OnboardingLayout)
