import { call, put, takeLatest } from 'redux-saga/effects'

import * as searchApi from '../apiModules/search'
import * as searchModule from '../modules/search'

function* searchOpps({ payload }) {
  try {
    const response = yield call(searchApi.searchOpps, payload)
    yield put(searchModule.searchOppsSuccess(response))
  } catch (err) {
    yield put(searchModule.searchOppsFailure(err))
  }
}

function* searchOrgs({ payload }) {
  try {
    const response = yield call(searchApi.searchOrgs, payload)
    yield put(searchModule.searchOrgsSuccess(response))
  } catch (err) {
    yield put(searchModule.searchOrgsFailure(err))
  }
}

export const searchSagas = [
  takeLatest(searchModule.SEARCH_OPPS, searchOpps),
  takeLatest(searchModule.SEARCH_ORGS, searchOrgs)
]
