import { createActions, handleActions } from 'redux-actions'
import UrlBuilder from 'utils/urlBuilder'

export const FETCH_OPPORTUNITY = 'opportunities/FETCH_OPPORTUNITY'
export const FETCH_OPP_FOR_ADMIN = 'opportunities/FETCH_OPP_FOR_ADMIN'
export const FETCH_OPPORTUNITIES = 'opportunities/FETCH_OPPORTUNITIES'
export const FETCH_OWN_OPPORTUNITIES = 'opportunities/FETCH_OWN_OPPORTUNITIES'
export const FETCH_BY_LOCATION = 'opportunities/FETCH_BY_LOCATION'
export const SINGLE_FETCH_SUCCESS = 'opportunities/SINGLE_FETCH_SUCCESS'
export const SINGLE_FETCH_FAILURE = 'opportunities/SINGLE_FETCH_FAILURE'
export const FETCH_FILTERED_OPPS = 'opportunities/FETCH_FILTERED_OPPS'
export const FETCH_FILTERED_SUCCESS = 'opportunities/FETCH_FILTERED_SUCCESS'
export const FETCH_SUCCESS = 'opportunities/FETCH_SUCCESS'
export const FETCH_FAILURE = 'opportunities/FETCH_FAILURE'

export const CREATE_OPPORTUNITY = 'opportunities/CREATE_OPPORTUNITY'
export const CREATE_SUCCESS = 'opportunities/CREATE_SUCCESS'
export const CREATE_FAILURE = 'opportunities/CREATE_FAILURE'

export const CLEAR_OPPS_FILTERS = 'opportunities/CLEAR_OPPS_FILTERS'

export const CANCEL_OPPORTUNITY = 'opportunities/CANCEL_OPPORTUNITY'
export const CANCEL_SUCCESS = 'opportunities/CANCEL_SUCCESS'
export const CANCEL_FAILURE = 'opportunities/CANCEL_FAILURE'

export const CHALLENGE_CHECKIN = 'opportunities/CHALLENGE_CHECKIN'
export const CHALLENGE_CHECKIN_SUCCESS = 'opportunities/CHALLENGE_CHECKIN_SUCCESS'
export const CHALLENGE_CHECKIN_FAILURE = 'opportunities/CHALLENGE_CHECKIN_FAILURE'

export const UPDATE_OPPORTUNITY = 'opportunities/UPDATE_OPPORTUNITY'
export const UPDATE_SUCCESS = 'opportunities/UPDATE_SUCCESS'
export const UPDATE_FAILURE = 'opportunities/UPDATE_FAILURE'

export const DELETE_OPPORTUNITY = 'opportunities/DELETE_OPPORTUNITY'
export const DELETE_SUCCESS = 'opportunities/DELETE_SUCCESS'
export const DELETE_FAILURE = 'opportunities/DELETE_FAILURE'

export const UPDATE_SINGLE_OPP_STATS = 'opportunities/UPDATE_SINGLE_OPP_STATS'
export const UPDATE_OPP_STORIES = 'opportunities/UPDATE_OPP_STORIES'

export const LOGOUT_SUCCESS = 'authentication/LOGOUT_SUCCESS'

export const {
  opportunities: {
    fetchOpportunity,
    fetchOppForAdmin,
    fetchOpportunities,
    fetchOwnOpportunities,
    fetchByLocation,
    fetchFilteredOpps,
    fetchFilteredSuccess,
    fetchSuccess,
    fetchFailure,
    singleFetchSuccess,
    singleFetchFailure,
    clearOppsFilters,
    createOpportunity,
    createSuccess,
    createFailure,
    updateOpportunity,
    updateSuccess,
    updateFailure,
    deleteOpportunity,
    deleteSuccess,
    deleteFailure,
    updateSingleOppStats,
    updateOppStories,
    challengeCheckin,
    challengeCheckinSuccess,
    challengeCheckinFailure
  }
} = createActions({
  [FETCH_OPPORTUNITY]: undefined,
  [FETCH_OPP_FOR_ADMIN]: undefined,
  [FETCH_OPPORTUNITIES]: undefined,
  [FETCH_OWN_OPPORTUNITIES]: undefined,
  [FETCH_BY_LOCATION]: undefined,
  [FETCH_FILTERED_OPPS]: undefined,
  [FETCH_FILTERED_SUCCESS]: undefined,
  [FETCH_SUCCESS]: undefined,
  [FETCH_FAILURE]: undefined,
  [CLEAR_OPPS_FILTERS]: undefined,
  [SINGLE_FETCH_SUCCESS]: undefined,
  [SINGLE_FETCH_FAILURE]: undefined,
  [CREATE_OPPORTUNITY]: undefined,
  [CREATE_SUCCESS]: undefined,
  [CREATE_FAILURE]: undefined,
  [UPDATE_OPPORTUNITY]: opportunity => ({ opportunity }),
  [UPDATE_SUCCESS]: updatedOpp => updatedOpp,
  [UPDATE_FAILURE]: undefined,
  [DELETE_OPPORTUNITY]: undefined,
  [DELETE_SUCCESS]: oppId => ({ oppId }),
  [DELETE_FAILURE]: undefined,
  [UPDATE_OPP_STORIES]: undefined,
  [UPDATE_SINGLE_OPP_STATS]: undefined,
  [CHALLENGE_CHECKIN]: undefined,
  [CHALLENGE_CHECKIN_SUCCESS]: undefined,
  [CHALLENGE_CHECKIN_FAILURE]: undefined
})

const initialPaginatedData = {
  pageCount: 0,
  oppsCount: 0,
  filteredCampsCount: 0,
  filteredApps: [],
  opps: [],
  filteredHostCheckIns: []
}

const initialState = {
  isLoading: false,
  isSubmitting: false,
  data: [],
  paginatedOppsData: initialPaginatedData,
  customFields: [],
  filteredData: [],
  singleOpportunityData: {},
  missionId: null,
  errorMessage: null,
  hasError: false,
  isSuccess: false
}

export default handleActions(
  {
    [FETCH_OPP_FOR_ADMIN]: state => ({
      ...state,
      hasSubmitted: false,
      isLoading: true,
      hasError: false,
      errorMessage: null,
      isSuccess: false
    }),
    [FETCH_OPPORTUNITY]: (state, payload) => ({
      ...state,
      errorMessage: null,
      hasError: false,
      isLoading: true,
      isSubmitting: false,
      isSuccess: null,
      ...payload
    }),
    [FETCH_OPPORTUNITIES]: (state, payload) => ({ ...state, ...payload, isLoading: true }),
    [FETCH_OWN_OPPORTUNITIES]: (state, payload) => ({ ...state, ...payload, isLoading: true }),
    [FETCH_BY_LOCATION]: (state, payload) => ({ ...state, ...payload }),
    [FETCH_SUCCESS]: (state, { payload }) => {
      const newState = {
        ...state,
        isLoading: false,
        hasSubmitted: false,
        isSuccess: true,
        data: payload.opps,
        missionId: payload.id,
        customFields: payload.customFields,
        errorMessage: null
      }

      newState.paginatedOppsData.oppsPageCount = payload.oppsPageCount || payload.pageCount || 0
      newState.paginatedOppsData.oppsCount = payload.oppsCount || 0
      newState.paginatedOppsData.filteredCampsCount = payload.filteredCampsCount || 0
      newState.paginatedOppsData.filteredApps = payload.filteredApps || []
      newState.paginatedOppsData.opps = payload.opps || []
      newState.paginatedOppsData.filteredHostCheckIns = payload.filteredHostCheckIns || []

      return newState
    },
    [FETCH_FILTERED_OPPS]: state => ({ ...state, isLoading: true }),
    [FETCH_FILTERED_SUCCESS]: (state, { payload }) => {
      const newState = { ...state, isLoading: false, errorMessage: null }

      newState.paginatedOppsData.opps = payload

      return newState
    },
    [CLEAR_OPPS_FILTERS]: state => {
      const newState = { ...state }
      newState.paginatedOppsData.opps = state.data
      return newState
    },
    [FETCH_FAILURE]: (state, payload) => ({
      ...state,
      ...payload,
      isSuccess: false,
      isLoading: false,
      hasError: true
    }),
    [SINGLE_FETCH_SUCCESS]: (state, { payload }) => ({
      ...state,
      isLoading: false,
      hasSubmitted: false,
      isSuccess: true,
      singleOpportunityData: payload,
      missionId: payload.id,
      errorMessage: null,
      hasError: false
    }),

    [UPDATE_SINGLE_OPP_STATS]: (state, { payload }) => {
      const oppData = state.singleOpportunityData
      const isOtherCommitment = payload.applicant_commitment === 'other'
      const newCommitment = isOtherCommitment
        ? payload.applicant_commitment_other
        : payload.applicant_commitment
      const newCommitmentTotal = newCommitment || 1
      const targetAmount = oppData.outcome_target || 1
      const marginalPercentIncrease = newCommitmentTotal / targetAmount
      const roundedIncreaseFigure = Math.floor(marginalPercentIncrease * 100)
      const existingSupporterCount = oppData.supporterOrDonationsCount || 0
      const newCommittedPerc = oppData.committedPerc + roundedIncreaseFigure

      const responseData = payload.responseData || {}
      const { story } = responseData
      const stories = story ? [story].concat(oppData.stories || []) : oppData.stories || []

      return {
        ...state,
        singleOpportunityData: {
          ...oppData,
          stories,
          supporterOrDonationsCount: existingSupporterCount + 1,
          committedPerc: newCommittedPerc,
          totalCommittedAmount: oppData.totalCommittedAmount + newCommitmentTotal
        }
      }
    },
    [UPDATE_OPP_STORIES]: (state, { payload }) => {
      const { singleOpportunityData } = state
      const stories = singleOpportunityData.stories || []
      if (!stories.length) return state

      const newOppData = {
        ...singleOpportunityData,
        stories: stories.map(s => {
          if (s.id === payload.id) {
            const oneMore = (s.story_likes || 0) + 1
            const oneLess = (s.story_likes || 1) - 1
            const userLikesStory = payload.value === 'liked'
            const newLikesCount = userLikesStory ? oneMore : oneLess

            return { ...s, story_likes: newLikesCount, user_likes_story: userLikesStory }
          }

          return s
        })
      }

      return { ...state, singleOpportunityData: newOppData }
    },
    [SINGLE_FETCH_FAILURE]: (state, { payload }) => ({
      ...state,
      errorMessage: payload === 403 ? 'You are not permitted to do that.' : 'Something went wrong.',
      hasError: true,
      isLoading: false,
      isSuccess: false
    }),
    [CREATE_OPPORTUNITY]: state => ({ ...state, isLoading: false, isSubmitting: true }),
    [CREATE_SUCCESS]: (state, { payload }) => {
      if (!payload.isPartialUpdate) {
        UrlBuilder.displayUpdateSuccessMessage()
      }

      return {
        ...state,
        isLoading: false,
        isSubmitting: false,
        errorMessage: null,
        payload: {},
        singleOpportunityData: payload.data
      }
    },
    [CREATE_FAILURE]: (state, { payload }) => ({
      ...state,
      ...payload,
      singleOpportunityData: payload.data,
      data: [],
      isLoading: false,
      isSubmitting: false,
      errorMessage: payload.errorMessage
    }),
    [CHALLENGE_CHECKIN]: state => ({ ...state, isLoading: false, isSubmitting: true }),
    [CHALLENGE_CHECKIN_SUCCESS]: state => {
      return {
        ...state,
        isLoading: false,
        isSubmitting: false,
        errorMessage: null
      }
    },
    [CHALLENGE_CHECKIN_FAILURE]: state => ({
      ...state,
      isLoading: false,
      isSubmitting: false,
      errorMessage: 'error'
    }),
    [UPDATE_OPPORTUNITY]: state => ({ ...state, isLoading: false, isSubmitting: true }),
    [UPDATE_SUCCESS]: (state, { payload }) => {
      const { opportunity } = payload
      const updatedData = [opportunity, ...state.data.filter(o => o.id !== opportunity.id)]

      if (!payload.isPartialUpdate) {
        UrlBuilder.displayUpdateSuccessMessage()
      }

      return {
        ...state,
        data: updatedData,
        isLoading: false,
        isSubmitting: false,
        payload: {},
        singleOpportunityData: opportunity
      }
    },
    [UPDATE_FAILURE]: state => ({ ...state, isLoading: false, isSubmitting: false }),
    [DELETE_OPPORTUNITY]: (state, { payload }) => ({
      ...state,
      isLoading: true,
      hasSubmitted: true,
      missionId: payload
    }),
    [DELETE_SUCCESS]: (state, { payload }) => {
      return {
        ...state,
        isLoading: false,
        hasSubmitted: true,
        data: state.data.filter(o => o.id !== payload.oppId),
        missionId: null
      }
    },
    [DELETE_FAILURE]: () => ({ isLoading: false, hasSubmitted: true, missionId: null }),
    [LOGOUT_SUCCESS]: () => initialState
  },
  initialState
)
