import React, { Component } from 'react'
import styled from 'styled-components'

// utils
import th from 'utils/themeHelper'

// components
import CampaignSignupModal from './CampaignSignupModal'
import ExtraInfoModal from './ExtraInfoModal'
import MenuModal from './MenuModal'
import PendingModal from './PendingModal'
import ChallengeDetailsModal from './ChallengeDetailsModal'
import NotificationsModal from './NotificationsModal'
import PostRedirectModal from './PostRedirectModal'
import RecurSignupSuccessModal from './RecurSignupSuccessModal'
import ShareModal from './ShareModal'
import SignupSuccessModal from './SignupSuccessModal'

const ModalWrapper = styled.div`
  background: ${props => (props.transparent ? 'transparent' : th('text.dark'))};
  display: ${props => (props.displayWrapper ? 'block' : 'none')};
  height: 100%;
  overflow: scroll;
  position: absolute;
  padding-bottom: 20px;
  top: 0;
  transition: 0.3s height;
  width: 100%;
  z-index: 21;
`

class ModalsCanvas extends Component {
  render() {
    const {
      closeAllModals,
      campaignApplicationOpts,
      extraInfoModalOpts,
      fetchPlaces,
      locationIsLoading,
      notifications,
      places,
      signupSuccessModalOpts,
      shareModalHostName,
      shareModalOppTitle,
      shareModalDescription,
      oppStatus,
      userData,
      loggedIn,
      visibleModal
    } = this.props

    const displayMenuModal = visibleModal === 'menu-modal'
    const displayNotifsModal = visibleModal === 'notifications-modal'
    const displayCampaignSignupModal = visibleModal === 'campaign-signup-modal'
    const displayExtraInfoModal = visibleModal === 'extra-info-modal'
    const displayChallengeDetailsModal = visibleModal === 'challenge-details-modal'
    const displayRecurringSuccess = visibleModal === 'recur-signup-success-modal'
    const displayPendingModal = visibleModal === 'pending-modal'
    const displayPostRedirectModal = visibleModal === 'post-redirect-modal'
    const displaySignupSuccessModal = visibleModal === 'signup-success-modal'
    const displayShareModal = visibleModal === 'share-modal'

    if (displayNotifsModal) {
      return (
        <ModalWrapper displayWrapper>
          <NotificationsModal
            userData={userData}
            loggedIn={loggedIn}
            notifications={notifications}
            displayMenu={true}
            toggleMenu={closeAllModals}
          />
        </ModalWrapper>
      )
    }

    if (displayMenuModal) {
      return (
        <ModalWrapper displayWrapper>
          <MenuModal
            userData={userData}
            loggedIn={loggedIn}
            notifications={notifications}
            displayMenu={true}
            toggleMenu={closeAllModals}
          />
        </ModalWrapper>
      )
    }

    if (displayChallengeDetailsModal) {
      return (
        <ModalWrapper displayWrapper transparent>
          <ChallengeDetailsModal
            hideModal={closeAllModals}
            extraInfoModalOpts={extraInfoModalOpts}
          />
        </ModalWrapper>
      )
    }

    if (displayCampaignSignupModal) {
      return (
        <ModalWrapper displayWrapper transparent>
          <CampaignSignupModal
            campaignApplicationOpts={campaignApplicationOpts}
            hideModal={closeAllModals}
          />
        </ModalWrapper>
      )
    }

    if (displayPendingModal) {
      return (
        <ModalWrapper displayWrapper transparent>
          <PendingModal hideModal={closeAllModals} extraInfoModalOpts={extraInfoModalOpts} />
        </ModalWrapper>
      )
    }

    if (displayPostRedirectModal) {
      return (
        <ModalWrapper displayWrapper transparent>
          <PostRedirectModal hideModal={closeAllModals} extraInfoModalOpts={extraInfoModalOpts} />
        </ModalWrapper>
      )
    }

    if (displayRecurringSuccess) {
      return (
        <ModalWrapper displayWrapper transparent>
          <RecurSignupSuccessModal
            hideModal={closeAllModals}
            signupSuccessModalOpts={signupSuccessModalOpts}
          />
        </ModalWrapper>
      )
    }

    if (displaySignupSuccessModal) {
      return (
        <ModalWrapper displayWrapper transparent>
          <SignupSuccessModal
            hideModal={closeAllModals}
            signupSuccessModalOpts={signupSuccessModalOpts}
          />
        </ModalWrapper>
      )
    }

    if (displayExtraInfoModal) {
      return (
        <ModalWrapper displayWrapper transparent>
          <ExtraInfoModal
            hideModal={closeAllModals}
            locationIsLoading={locationIsLoading}
            extraInfoModalOpts={extraInfoModalOpts}
            fetchPlaces={fetchPlaces}
            places={places}
          />
        </ModalWrapper>
      )
    }

    if (displayShareModal) {
      return (
        <ModalWrapper displayWrapper transparent>
          <ShareModal
            shareModalHostName={shareModalHostName}
            shareModalOppTitle={shareModalOppTitle}
            shareModalDescription={shareModalDescription}
            oppStatus={oppStatus}
            hideModal={closeAllModals}
          />
        </ModalWrapper>
      )
    }

    return <ModalWrapper />
  }
}

export default ModalsCanvas
