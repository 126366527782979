import React, { Fragment, Component } from 'react'
import 'react-day-picker/lib/style.css'
import DayPickerInput from 'react-day-picker/DayPickerInput'

// Load components synchronously
import LinkButton from 'components/SmallButtonPrimary/LinkButton'
import { Error, Label, SubLabel } from '../InputLabels/InputLabels'
import TimePicker from './time-picker'

// icons
import { PlusSign } from 'components/Icons/Icons'

// styles
import {
  DateTimeRow,
  DateTimeRowItem,
  DateTimeRowLabel,
  PlusIconWrapper,
  Wrapper,
  EndDayPickerWrapper
} from './styles'

const DATE_FORMAT = 'DD-MM-YYYY'

const formatDate = date => {
  const day = date.getDate()
  const month = date.getMonth() + 1
  const year = date.getFullYear()
  const paddedDay = day.length < 2 ? `0${day}` : day
  const paddedMonth = month.length < 2 ? `0${month}` : month

  return `${year}-${paddedMonth}-${paddedDay}`
}

const determineNewEndTime = ({ date, existingEndDate, existingStartTime, existingEndTime }) => {
  date.setHours(0, 0, 0, 0)
  existingEndDate.setHours(0, 0, 0, 0)

  const startTimeHourString = existingStartTime.replace(/:\d\d/, '')
  const startTimeHour = parseInt(startTimeHourString)
  const endTimeHourString = existingEndTime.replace(/:\d\d/, '')
  const endTimeHour = parseInt(endTimeHourString)

  if (endTimeHour > startTimeHour) return existingEndTime

  const startTimeMinuteString = existingStartTime.replace(/\d\d:/, '')
  const startTimeMinute = parseInt(startTimeMinuteString)
  const endTimeMinuteString = existingEndTime.replace(/\d\d:/, '')
  const endTimeMinute = parseInt(endTimeMinuteString)
  const timesHaveSameHour = endTimeHour === startTimeHour
  const endMinutesAreLater = endTimeMinute > startTimeMinute

  if (timesHaveSameHour && endMinutesAreLater) return existingEndTime

  return existingStartTime
}

class DateTimeRangePicker extends Component {
  timeInMinutes = time => {
    const hours = time.substring(0, 2)
    const minutes = time.substring(3, 5)
    return parseInt(hours) * 60 + parseInt(minutes)
  }

  handleChangeTime = (attr, time) => {
    const { value, onChange } = this.props
    if (typeof onChange !== 'function') return true

    const today = new Date()
    today.setHours(time.replace(/:\d\d/, ''))
    today.setMinutes(time.replace(/\d\d:/, ''))

    const hasStartDate = !!value.start_date
    const hasEndDate = !!value.end_date

    const startDate = hasStartDate ? new Date(value.start_date) : today
    const endDate = hasEndDate ? new Date(value.end_date) : today

    const valueStartDate = hasStartDate ? value.start_date : formatDate(today)
    const valueEndDate = hasEndDate ? value.end_date : formatDate(today)

    const updatingStartTimeButHasNoEndTime = attr === 'start_time' && !value.end_time
    const updatingEndTimeButHasNoStartTime = attr === 'end_time' && !value.start_time

    if (updatingStartTimeButHasNoEndTime || updatingEndTimeButHasNoStartTime) {
      return onChange({
        start_date: valueStartDate,
        end_date: valueEndDate,
        start_time: time,
        end_time: time
      })
    }

    if (attr === 'start_time') {
      const newHourValue = time.replace(/:\d\d/, '')
      const newMinuteValue = time.replace(/\d\d:/, '')
      const existingEndTimeHourValue = value.end_time.replace(/:\d\d/, '')
      const existingEndTimeMinuteValue = value.end_time.replace(/\d\d:/, '')

      startDate.setHours(newHourValue)
      startDate.setMinutes(newMinuteValue)

      endDate.setHours(existingEndTimeHourValue)
      endDate.setMinutes(existingEndTimeMinuteValue)

      const updatedStartDateTimePreceedsEndDateTime = new Date(startDate) < new Date(endDate)
      const newEndTime = updatedStartDateTimePreceedsEndDateTime ? value.end_time : time

      return onChange({
        start_date: valueStartDate,
        end_date: valueEndDate,
        start_time: time,
        end_time: newEndTime
      })
    }

    // Below is for scenario where attr === 'end_time'

    const newHourValue = time.replace(/:\d\d/, '')
    const newMinuteValue = time.replace(/\d\d:/, '')
    const existingStartTimeHourValue = value.start_time.replace(/:\d\d/, '')
    const existingStartTimeMinuteValue = value.start_time.replace(/\d\d:/, '')

    startDate.setHours(existingStartTimeHourValue)
    startDate.setMinutes(existingStartTimeMinuteValue)

    endDate.setHours(newHourValue)
    endDate.setMinutes(newMinuteValue)

    const updatedEndDateTimePreceedsStartDateTime = new Date(endDate) < new Date(startDate)
    const newStartDate = updatedEndDateTimePreceedsStartDateTime
      ? formatDate(endDate)
      : valueStartDate
    const newStartTime = updatedEndDateTimePreceedsStartDateTime ? time : value.start_time

    return onChange({
      start_date: newStartDate,
      end_date: valueEndDate,
      start_time: newStartTime,
      end_time: time
    })
  }

  handleChangeDate = (attr, day) => {
    const { value, onChange } = this.props

    const existingStartDate = value.start_date
    const replaceStartDate = !existingStartDate || new Date(existingStartDate) > day

    if (replaceStartDate && attr === 'end_date') {
      return onChange({ ...value, start_date: day, end_date: day })
    }

    return onChange({ ...value, [attr]: day })
  }

  handleStartDateChange = date => {
    const { value, onChange } = this.props
    if (typeof onChange !== 'function') return true

    const existingStartTime = value.start_time || '09:00'
    const existingEndTime = value.end_time || '17:00'
    const existingEndDate = value.end_date
    const existingStartDate = value.start_date
    const newStartDateIsAfterExistingEndDate = new Date(date) > new Date(existingEndDate)
    const newEndDateMustBeAdjusted = new Date(value.end_date) < new Date(date)
    const newEndDate = newEndDateMustBeAdjusted ? date : existingEndDate

    if (!existingEndDate || !existingStartDate) {
      return onChange({
        ...value,
        start_date: date,
        end_date: date,
        start_time: existingStartTime,
        end_time: existingEndTime
      })
    }

    if (newStartDateIsAfterExistingEndDate) {
      const newDeterminedTime = determineNewEndTime({
        date,
        existingEndDate,
        existingStartTime,
        existingEndTime
      })

      return onChange({
        ...value,
        start_date: date,
        end_date: date,
        start_time: existingStartTime,
        end_time: newDeterminedTime
      })
    }

    return onChange({
      ...value,
      start_date: date,
      end_date: newEndDate,
      start_time: existingStartTime,
      end_time: existingEndTime
    })
  }

  render() {
    const {
      disabled,
      expandable,
      expandButtonText,
      field,
      form: { errors },
      label,
      subLabel,
      value
    } = this.props
    const dateTimeValues = value || {}
    const { start_date, start_time, end_date, end_time } = dateTimeValues
    const error = errors[field.name]
    const today = new Date()

    if (expandable && !start_date) {
      return (
        <div>
          {label && <Label htmlFor={field.name}>{label}</Label>}
          {subLabel && <SubLabel subLabel={subLabel}>{subLabel}</SubLabel>}

          <LinkButton disabled={disabled} onClick={() => this.handleStartDateChange(today)}>
            <PlusIconWrapper>
              <PlusSign />
            </PlusIconWrapper>

            <span>{expandButtonText}</span>
          </LinkButton>
        </div>
      )
    }

    const startDate = start_date || ''
    const endDate = end_date || ''

    return (
      <Fragment>
        {label && <Label htmlFor={field.name}>{label}</Label>}
        {subLabel && <SubLabel subLabel={subLabel}>{subLabel}</SubLabel>}

        <Wrapper hasError={!!error}>
          <DateTimeRow borderBottom>
            <DateTimeRowLabel>Start date</DateTimeRowLabel>

            <DateTimeRowItem>
              <DayPickerInput
                format={DATE_FORMAT}
                placeholder={DATE_FORMAT}
                dayPickerProps={{ disabledDays: { before: today } }}
                value={startDate}
                onDayChange={day => this.handleStartDateChange(day)}
              />
            </DateTimeRowItem>

            <DateTimeRowLabel>Start time</DateTimeRowLabel>

            <DateTimeRowItem noFlex>
              <TimePicker
                time_range_edge={'start_time'}
                value={start_time}
                onChange={time => this.handleChangeTime('start_time', time)}
              />
            </DateTimeRowItem>
          </DateTimeRow>

          <DateTimeRow>
            <DateTimeRowLabel>End date</DateTimeRowLabel>

            <DateTimeRowItem>
              <EndDayPickerWrapper>
                <DayPickerInput
                  format={DATE_FORMAT}
                  placeholder={DATE_FORMAT}
                  value={endDate}
                  dayPickerProps={{ disabledDays: { before: today } }}
                  onDayChange={day => this.handleChangeDate('end_date', day)}
                />
              </EndDayPickerWrapper>
            </DateTimeRowItem>

            <DateTimeRowLabel>End time</DateTimeRowLabel>

            <DateTimeRowItem noFlex>
              <TimePicker
                time_range_edge={'end_time'}
                value={end_time}
                onChange={time => this.handleChangeTime('end_time', time)}
              />
            </DateTimeRowItem>
          </DateTimeRow>
        </Wrapper>
        {error && <Error>{error}</Error>}
      </Fragment>
    )
  }
}

export default DateTimeRangePicker
