import React, { Component } from 'react'
import qs from 'qs'

// styles
import {
  CommitmentText,
  SharingInstructions,
  ModalHeader,
  ScreenWrapper,
  ModalContainer,
  SocialMediaWrapper,
  CloseIcon,
  ModalWrapper
} from './styles'

// Load components synchronously
import LinkButton from 'components/SmallButtonPrimary/LinkButton'
import SocialMediaIcons from 'components/SocialMediaIcons/SocialMediaIcons'

class RecurSignupSuccessModal extends Component {
  constructor(props) {
    super(props)
    this.escFunction = this.escFunction.bind(this)
  }

  escFunction(event) {
    if (event.keyCode === 27) {
      this.props.hideModal()
    }
  }

  componentDidMount() {
    document.addEventListener('keydown', this.escFunction, false)
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.escFunction, false)
  }

  getAmount({ amount, donationSubscriptions }) {
    const subscriptionsTotal = donationSubscriptions
      .filter(s => s.status === 'Active')
      .reduce((sum, accumulator) => {
        return sum + accumulator.amount || 0
      }, 0)

    return (subscriptionsTotal + parseInt(amount)).toLocaleString('en-US')
  }

  render() {
    const { amount = 1 } = qs.parse(location.search.replace('?', ''))
    const { hideModal, signupSuccessModalOpts = {} } = this.props
    const { donationSubscriptions = [], organisation = {} } = signupSuccessModalOpts
    const total = this.getAmount({ amount, donationSubscriptions })

    return (
      <ScreenWrapper>
        <ModalWrapper>
          <ModalContainer>
            <CloseIcon dark size={30} onClick={hideModal} />

            <ModalHeader>Your donation subscription has been saved.</ModalHeader>

            <CommitmentText>
              You will be billed a total of R{total}.00 on your chosen billing date. This is the
              total of your current donation memberships plus this new donation amount.
            </CommitmentText>

            <SocialMediaWrapper>
              <SharingInstructions>
                Do more good: invite your friends, family and community.
              </SharingInstructions>

              <SocialMediaIcons
                title={organisation.name}
                fbMessage={`I am supporting ${organisation.name}!`}
                description={`I am supporting ${organisation.name}`}
                twitterMessage={`I am supporting ${organisation.name}! Check it out here -`}
              />
            </SocialMediaWrapper>

            <div style={{ marginTop: '20px' }} />

            <LinkButton onClick={hideModal}>Close</LinkButton>
          </ModalContainer>
        </ModalWrapper>
      </ScreenWrapper>
    )
  }
}

export default RecurSignupSuccessModal
