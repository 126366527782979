import React from 'react'
import styled from 'styled-components'

// utils
import th from 'utils/themeHelper'
import logos from 'utils/logos'

const { smallLogoPath } = logos

const Nav = styled.div`
  height: 140px;
  padding: 20px 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid ${th('borders.default')};

  @media screen and (min-width: 480px) {
    flex-direction: row;
    height: 80px;
  }

  @media screen and (min-width: 730px) {
    padding: 20px 50px;
  }

  @media screen and (min-width: 1024px) {
    padding: 20px 90px;
  }
`

const NavLinks = styled.div`
  height: 60px;
  padding: 0 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
`

const NavLink = styled.a`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 12.5px;
  color: ${th('text.dark')};
  cursor: pointer;

  &:hover {
    transition: 0.3s color;
    color: ${th('secondary.base')};
  }

  @media screen and (min-width: 730px) {
    padding: 0 20px;
  }
`

const CopyrightContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`

const Logo = styled.img`
  margin-right: 20px;
  height: 40px;
`

const DashboardFooter = () => {
  return (
    <Nav>
      <CopyrightContainer>
        <Logo src={smallLogoPath} />
        <label>&copy; {new Date().getFullYear()} Brownie Points (Pty) Ltd.</label>
      </CopyrightContainer>

      <NavLinks>
        <NavLink href="/terms" target="_blank" rel="noopener">
          Terms
        </NavLink>
        <NavLink href={'mailto:hi@browniepoints.africa'}>Contact us</NavLink>
      </NavLinks>
    </Nav>
  )
}

export default DashboardFooter
