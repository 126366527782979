import React from 'react'
import styled from 'styled-components'

const StyledCardsContainer = styled.div`
  margin-bottom: ${props => props.marginBottom || '0'};
  margin-top: ${props => props.marginTop || '10px'};
  min-height: ${props => props.minHeight || ''};
  position: relative;

  & > * {
    margin-top: 10px;
  }

  @media screen and (min-width: 600px) {
    margin-top: ${props => props.marginTop || '30px'};

    & > * {
      margin-top: 10px;

      &:first-of-type {
        margin-top: 0;
      }
    }
  }
`

const CardsContainer = ({ children, marginBottom, marginTop, minHeight }) => {
  return (
    <StyledCardsContainer
      marginBottom={marginBottom}
      marginTop={marginTop}
      minHeight={minHeight}
      id="results"
    >
      {children}
    </StyledCardsContainer>
  )
}

export default CardsContainer
