import React from 'react'
import { Field } from 'formik'

// config
import constants from 'config/constants'

// Load components synchronously
import SwipeSelect from 'components/Select/SwipeSelect'

// assets
import { ReactComponent as privateIcon } from 'assets/images/svgs/icon-public-opp.svg'
import { ReactComponent as publicIcon } from 'assets/images/svgs/icon-private-opp.svg'

const determineStoryTypeOpts = actionType => {
  if (actionType === 'donation') {
    return [
      {
        label: 'Visible',
        value: constants.STORY_STATES.PUBLIC,
        iconPadding: '5px',
        Icon: publicIcon
      },
      {
        label: 'Anonymous',
        value: constants.STORY_STATES.ANON_DONATION,
        iconPadding: '5px',
        Icon: privateIcon
      }
    ]
  }

  return [
    {
      label: 'Visible',
      value: constants.STORY_STATES.PUBLIC,
      iconPadding: '5px',
      Icon: publicIcon
    },
    {
      label: 'Private',
      value: constants.STORY_STATES.PRIVATE,
      iconPadding: '5px',
      Icon: privateIcon
    }
  ]
}

const StoryVisibilitySection = ({
  actionType,
  collapseOptions,
  formValues,
  setFieldValue,
  setState,
  subLabel
}) => {
  const storyStatusOpts = determineStoryTypeOpts(actionType)

  return (
    <div>
      <Field
        customSwipeSelect
        collapseOptions={collapseOptions}
        component={SwipeSelect}
        floatExpandButton
        forceFullWidth
        label="Story visibility"
        name="story_privacy"
        onChange={e => {
          if (e) {
            setFieldValue(`story_privacy`, e.value)
          }
        }}
        openButtonLabel={'Edit'}
        options={storyStatusOpts}
        setState={setState}
        shortOptions
        subLabel={
          subLabel ||
          'Donations made via Brownie Points are shared in various places on the platform. If you prefere to donate anonymously, you can choose to keep your name private. Private stories are also hidden from others on your user profile.'
        }
        value={formValues.story_privacy}
      />
    </div>
  )
}

export default StoryVisibilitySection
