import React from 'react'
import { Link } from '@reach/router'
import styled from 'styled-components'

export const Main = styled.main`
  background-color: ${props => (props.bgColor ? props.bgColor : 'white')};
  display: flex;
  flex-direction: column;

  /* subtract footer height */
  min-height: calc(100vh - 482px);

  @media screen and (min-width: 600px) {
    min-height: calc(100vh - 282px);
  }

  @media screen and (min-width: 1024px) {
    min-height: calc(100vh - 322px);
  }

  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    height: 2000px;
  }

  @supports (-ms-accelerator: true) {
    height: 2000px;
  }
`

export const Wrapper = styled.div`
  position: relative;
  height: ${props => (props.visibleModal ? '100vh' : 'initial')};
  overflow: ${props => (props.visibleModal ? 'hidden' : 'scroll')};
`

export const FeedbackIconLink = styled(Link)`
  display: block;
  text-decoration: none;
`

const SiteDownImage = styled.img`
  margin: 0 auto;
  padding: 20px;
  width: 300px;

  @media screen and (min-width: 769px) {
    width: 500px;
  }
`

const SiteDownNote = styled.p`
  font-size: 18px;

  @media screen and (min-width: 769px) {
    margin: 0 auto;
    width: 80%;
  }
`

const SiteDownContainer = styled.div`
  height: 100vh;
  padding: 20px;
  text-align: center;
  width: 100%;
`

export const SiteDownNotice = () => {
  return (
    <SiteDownContainer>
      <h2>We'll be right back</h2>

      <SiteDownNote>
        It looks like our servers are undergoing some surprise maintenance.
      </SiteDownNote>

      <SiteDownImage src={'https://cdn.browniepoints.africa/assets/bpimg_1u60jp3dh92czvc.png'} />
    </SiteDownContainer>
  )
}
