import styled from 'styled-components'
import th from 'utils/themeHelper'

export const ButtonBase = styled.label`
  box-sizing: border-box;
  outline: none;
  background: ${props =>
    props.disabled ? th('backgrounds.mid') : props.whiteButton ? 'white' : th('secondary.base')};
  font-size: 12px;
  font-family: ${th('fonts.bold')};
  color: ${props => (props.whiteButton ? th('text.dark') : 'white')};
  display: inline-block;
  border-radius: 4px;
  padding: 0 ${th('spacing.default', 'px')};
  transition: 0.3s background 0.3s border;
  border-width: 1px;
  border-style: solid;
  border-color: ${props =>
    props.disabled
      ? th('backgrounds.mid')
      : props.whiteButton
      ? th('borders.medium')
      : th('secondary.base')};
  cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};
  width: ${props => (props.wide ? '100%' : 'auto')};
  height: ${props =>
    props.fileUpload && props.requiredField ? '31px' : props.fileUpload ? '33px' : '34px'};
  line-height: ${props =>
    props.fileUpload && props.requiredField ? '31px' : props.fileUpload ? '33px' : '34px'};

  &:hover,
  &:focus {
    background: ${props =>
      props.disabled ? th('backgrounds.mid') : props.whiteButton ? 'white' : th('secondary.dark')};
    border-color: ${props => (props.disabled ? th('backgrounds.mid') : th('secondary.dark'))};
    transition: 0.3s background 0.3s border;
  }
`
