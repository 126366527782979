import axios from 'axios'

// config
import baseUrl from '../config'

export const fetchCampaignPartners = async ({ partnerType }) => {
  if (partnerType === 'campaign') {
    const response = await axios.get(`${baseUrl}/campaign-partners`, { withCredentials: true })
    return response.data
  }

  if (partnerType === 'opportunity') {
    const response = await axios.get(`${baseUrl}/opportunity-partners`, { withCredentials: true })
    return response.data
  }
}

export const removeCampaignPartner = async ({ campaignId, campaignPartnerId }) => {
  return await axios.delete(`${baseUrl}/campaign-partners/remove/${campaignId}`, {
    withCredentials: true,
    data: { campaignPartnerId }
  })
}

export const removeOpportunityPartner = async ({ missionId, opportunityPartnerId }) => {
  return await axios.delete(`${baseUrl}/opportunity-partners/remove/${missionId}`, {
    withCredentials: true,
    data: { opportunityPartnerId }
  })
}
