import styled from 'styled-components'

// utils
import th from 'utils/themeHelper'

// Load components synchronously
import Wrapper from 'components/Wrapper/Wrapper'

export const BeneficiaryDetailsWrapper = styled.div`
  align-items: center;
  display: flex;
  margin-bottom: 10px;
  margin-top: -10px;

  @media screen and (min-width: 769px) {
    display: none;
    margin-top: 0;
    white-space: nowrap;
  }
`

export const BeneficiaryLogoWrapper = styled.div`
  background: white;
  border: 1px solid ${th('borders.default')};
  border-radius: 50%;
  display: inline-block;
  margin-right: 10px;
  overflow: hidden;
  vertical-align: middle;
  width: 30px;
  height: 30px;
  position: relative;
`

export const BeneficiaryNameLink = styled.a`
  cursor: pointer;
  text-decoration: underline;
`

export const BeneficiaryNameWrapper = styled.span``

export const HostOrgDetails = styled.div``

export const MastheadWrapper = styled(Wrapper)`
  background: linear-gradient(rgba(19, 18, 18, 0.6), rgba(19, 18, 18, 0.4)),
    url(${props => (props.image ? props.image : '')});
  background-size: cover;
  background-position: 50%;
  border: 1px solid ${th('borders.dark')};
  display: flex;
  justify-content: start;
  flex-direction: column;
  padding: 0;
  height: auto;
  min-width: 100%;
  min-height: ${props => (props.isHostFeedback ? '100px' : '50vw')};
  align-items: ${props => props.align || 'initial'};

  @media screen and (min-width: 769px) {
    border-radius: ${props => (props.roundBorders ? '4px' : 0)};
    min-height: ${props => (props.isHostFeedback ? '100px' : '600px')};
  }
`

export const MastheadWrapperInner = styled(Wrapper)`
  align-items: ${props => props.align || 'initial'};
  display: flex;
  flex-direction: column;
  height: auto;
  justify-content: space-between;
  max-width: 1300px;
  min-height: 200px;
  padding: 20px;
  width: 80%;

  @media screen and (max-width: 600px) {
    padding: 70px 0 20px;
  }

  @media screen and (min-width: 769px) {
    padding: 50px;
    flex-direction: row;
    align-items: center;
  }

  @media screen and (min-width: 1024px) {
    padding: ${props => (props.isHostFeedback ? '0 20px' : '90px 20px 0')};
  }
`

export const OpportunityInner = styled.div`
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 0;
  position: relative;

  @media screen and (min-width: 769px) {
    align-items: ${props => (props.beneficiaryOrg ? 'flex-end' : 'center')};
    flex-direction: row;
  }
`

export const OpportunityOuter = styled.section`
  background-size: cover;
  height: auto;

  @media screen and (min-width: 769px) {
    flex: 3;
    margin: 0;
    max-width: ${props => (props.signUpFormOpen ? 'calc(100% - 550px)' : '975px')};
  }
`

export const OpportunityPartnerDetails = styled.div`
  padding: 40px 50px;
  width: 100%;

  @media screen and (min-width: 1024px) {
    padding: 40px 90px;
  }

  @media screen and (max-width: 769px) {
    padding: 50px 20px 20px;
  }
`

export const OpportunityPartnersHeading = styled.div`
  margin-bottom: 1em;
  text-transform: uppercase;
  color: #fff;
`

export const OpportunityPartnersWrapper = styled.div`
  flex-wrap: wrap;
  display: flex;
`

export const OrgLink = styled.a`
  color: ${props => (props.ishost ? th('text.dark') : '#FFF')};
  cursor: pointer;
`

export const OrgLogo = styled.img`
  background: white;
  height: 100%;
  left: 50%;
  object-fit: cover;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);

  // Make it slightly bigger than the 50px wrapper width to hide any ugly trimmings of
  // oddly shaped image uploads
  width: 54px;
`

export const OrgLogoWrapper = styled.div`
  background: white;
  border: 1px solid ${th('borders.default')};
  border-radius: 50%;
  display: inline-block;
  margin-right: 20px;
  overflow: hidden;
  vertical-align: middle;
  width: 50px;
  height: 50px;
  position: relative;
`

export const MobileOpportunityTitle = styled.h1`
  font-size: 30px;
  line-height: 1.1em;
  margin: 10px 0;
  padding: 0 10px;
  width: 100%;

  @media screen and (min-width: 769px) {
    display: none;
  }
`

export const OpportunityTitle = styled.h1`
  display: none;

  @media screen and (min-width: 769px) {
    background: #21212199;
    color: white;
    display: block;
    font-size: 30px;
    line-height: 1.1em;
    margin: 0;
    padding: 10px;
    width: 100%;
  }

  @media screen and (min-width: 1300px) {
    font-size: 35px;
  }
`

export const OrgNameWrapper = styled.span`
  color: white;
`

export const Outer = styled.section`
  background-size: cover;
  height: auto;

  @media screen and (min-width: 600px) {
    flex: 3;
    padding-top: 65px;
  }
`

export const SingleOpportunityPartner = styled.div`
  margin-bottom: 1em;
  margin-right: 3em;
  a {
    color: #fff;
  }
`

export const SocialMediaWrapper = styled.div`
  align-items: center;
  bottom: -43px;
  color: ${th('text.dark')};
  display: flex;
  flex-direction: row;
  height: 37.5px;
  margin: 0;
  position: absolute;
  right: 10px;
`

export const VerificationIcon = styled.img`
  display: inline-block;
  width: 20px;
  height: 20px;
  vertical-align: middle;
`

export const VerificationStatus = styled.div`
  display: inline-block;
  font-size: 10px;
  margin-left: 5px;
  vertical-align: middle;
`

export const VerificationWrapper = styled.div`
  align-items: center;
  border-radius: 50px;
  display: inline-block;
  margin-left: 0.5em;
  margin-top: 0;
  padding: 5px 15px;
  position: relative;

  @media screen and (min-width: 769px) {
    margin-top: 0.5em;
  }
`
