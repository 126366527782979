import React from 'react'
import styled from 'styled-components'
import { Link } from '@reach/router'

// icons
import { User as UserIcon } from '@styled-icons/boxicons-solid/User'
import { CalendarHeart } from '@styled-icons/boxicons-regular'

// utils
import th from 'utils/themeHelper'

// styles
import DashCardWrapper from './DashCardWrapper'

const ActionsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`

const Heading = styled.h4`
  display: inline-block;
  flex: 1;
  margin: 0;
  vertical-align: middle;
`

const Info = styled.div`
  display: block;
  margin: 10px 15px 0;
  flex: 1;
`

const InfoFieldLabel = styled.div`
  font-family: ${th('fonts.bold')};
  margin-right: 10px;
`

const InfoFieldValue = styled.div`
  font-family: ${th('fonts.regular')};
  margin-left: 30px;
`

const InfoFieldIcon = styled.div`
  margin-right: 10px;

  & > svg {
    height: 20px;
  }
`

const InfoGroup = styled.div`
  min-width: 30%;
`

const InfoGroupRow = styled.div`
  color: ${th('text.mid')};
  line-height: 1.25em;
  margin-bottom: 15px;
`

const InfoGroupRowInner = styled.div`
  display: flex;
  word-break: break-word;
`

const OrgMainDetailsWrapper = styled.div`
  align-items: center;
  display: flex;
  flex: 1;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin: 0 15px 10px;
`

const UserProfileLink = styled(Link)`
  color: ${th('text.dark')};
  margin: 0;
  text-decoration: underline;
`

const DashLikeCard = ({ liked_at, liked_by, story_title, user_profile }) => {
  return (
    <DashCardWrapper>
      <ActionsWrapper>
        <OrgMainDetailsWrapper>
          <Heading>{story_title}</Heading>
        </OrgMainDetailsWrapper>

        <Info>
          <InfoGroup>
            <InfoGroupRow>
              <InfoGroupRowInner>
                <InfoFieldIcon>
                  <CalendarHeart />
                </InfoFieldIcon>
                <InfoFieldLabel>Date</InfoFieldLabel>
              </InfoGroupRowInner>

              <InfoGroupRowInner>
                <InfoFieldValue>{liked_at}</InfoFieldValue>
              </InfoGroupRowInner>
            </InfoGroupRow>

            <InfoGroupRow>
              <InfoGroupRowInner>
                <InfoFieldIcon>
                  <UserIcon />
                </InfoFieldIcon>
                <InfoFieldLabel>User</InfoFieldLabel>
              </InfoGroupRowInner>

              <InfoGroupRowInner>
                <UserProfileLink to={user_profile}>
                  <InfoFieldValue>{liked_by}</InfoFieldValue>
                </UserProfileLink>
              </InfoGroupRowInner>
            </InfoGroupRow>
          </InfoGroup>
        </Info>
      </ActionsWrapper>
    </DashCardWrapper>
  )
}

export default DashLikeCard
