import React from 'react'
import styled from 'styled-components'
import { Link } from '@reach/router'

// icons
import { CalendarHeart } from '@styled-icons/boxicons-regular'
import { ListStars } from '@styled-icons/bootstrap/ListStars'
import { ListUl } from '@styled-icons/bootstrap/ListUl'
import { CheckCircleFill } from '@styled-icons/bootstrap/CheckCircleFill'

// config
import l from 'config/localization'
import constants from 'config/constants'

// utils
import th from 'utils/themeHelper'

// styles
import { getPillColors } from 'components/SearchResultsContainer/styles'
import DashCardWrapper from './DashCardWrapper'

const buildStreakSuffix = ({ frequency }) => {
  switch (frequency) {
    case constants.CHALLENGE_FREQUENCIES.ONCE_OFF:
      return { streakSuffixSingular: 'N/A', streakSuffixPlural: 'N/A' }
    case constants.CHALLENGE_FREQUENCIES.WEEKLY:
      return { streakSuffixSingular: 'week', streakSuffixPlural: 'weeks' }
    case constants.CHALLENGE_FREQUENCIES.MONTHLY:
      return { streakSuffixSingular: 'month', streakSuffixPlural: 'months' }
    case constants.CHALLENGE_FREQUENCIES.DAILY:
      return { streakSuffixSingular: 'day', streakSuffixPlural: 'days' }
    default:
      return { streakSuffixSingular: 'day', streakSuffixPlural: 'days' }
  }
}

const Actions = styled.div`
  align-items: center;
  display: flex;
  flex: 1;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin: 0 10px 10px;
`

const ActionsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`

const Heading = styled.h4`
  display: inline-block;
  flex: 1;
  margin: 0;
  vertical-align: middle;
`

const Info = styled.div`
  display: block;
  margin: 10px 15px;
  flex: 1;
`

const InfoFieldLabel = styled.div`
  font-family: ${th('fonts.bold')};
  margin-right: 10px;
`

const InfoFieldValue = styled.div`
  font-family: ${th('fonts.regular')};
  margin-left: 30px;
`

const InfoFieldIcon = styled.div`
  margin-right: 10px;

  & > svg {
    height: 20px;
  }
`

const InfoGroup = styled.div`
  min-width: 30%;
`

const InfoGroupRow = styled.div`
  color: ${th('text.mid')};
  line-height: 1.25em;
  margin-bottom: 15px;
`

const InfoGroupRowInner = styled.div`
  display: flex;
  word-break: break-word;
`

const CheckinLink = styled(Link)`
  background: ${th('primary.base')};
  border-radius: 4px;
  color: white;
  margin: 0 0 10px 30px;
  padding: 8px 12px;
  text-align: center;
  text-decoration: none;
  white-space: nowrap;

  & > svg {
    width: 15px;
    margin-right: 10px;
  }
`

const OrgMainDetailsWrapper = styled.div`
  align-items: center;
  display: flex;
  flex: 1;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin: 0 15px 10px;
`

const Pill = styled.div`
  background: ${props => props.bgColor || th('backgrounds.default')};
  color: ${props => props.fontColor || th('text.dark')};
  border-radius: 3px;
  display: inline-block;
  font-size: 9px;
  margin-top: 2px;
  padding: 2px 5px;
  text-align: center;
  text-transform: uppercase;
  width: ${props => (props.fullWidth ? '100%' : 'auto')};
`

const DashChallengeCard = ({
  best_streak_days,
  current_streak_days,
  frequency,
  next_checkin_date,
  opportunity_id,
  opportunity_name,
  status
}) => {
  const { bgColor, color } = getPillColors({ key: 'status', value: status })
  const { streakSuffixSingular, streakSuffixPlural } = buildStreakSuffix({ frequency })

  return (
    <DashCardWrapper>
      <ActionsWrapper>
        <OrgMainDetailsWrapper>
          <Heading>{opportunity_name}</Heading>
        </OrgMainDetailsWrapper>

        <Info>
          <InfoGroup>
            <InfoGroupRow>
              <InfoGroupRowInner>
                <InfoFieldIcon>
                  <CheckCircleFill />
                </InfoFieldIcon>
                <InfoFieldLabel>Participation status</InfoFieldLabel>
              </InfoGroupRowInner>

              <InfoGroupRowInner>
                <InfoFieldValue>
                  <Pill bgColor={bgColor} fontColor={color}>
                    {status}
                  </Pill>
                </InfoFieldValue>
              </InfoGroupRowInner>
            </InfoGroupRow>

            <InfoGroupRow>
              <InfoGroupRowInner>
                <InfoFieldIcon>
                  <CalendarHeart />
                </InfoFieldIcon>
                <InfoFieldLabel>Next checkin</InfoFieldLabel>
              </InfoGroupRowInner>

              <InfoGroupRowInner>
                <InfoFieldValue>{next_checkin_date || 'N/A'}</InfoFieldValue>
              </InfoGroupRowInner>
            </InfoGroupRow>

            <InfoGroupRow>
              <InfoGroupRowInner>
                <InfoFieldIcon>
                  <CalendarHeart />
                </InfoFieldIcon>
                <InfoFieldLabel>Checkin frequency</InfoFieldLabel>
              </InfoGroupRowInner>

              <InfoGroupRowInner>
                <InfoFieldValue>{frequency}</InfoFieldValue>
              </InfoGroupRowInner>
            </InfoGroupRow>

            <InfoGroupRow>
              <InfoGroupRowInner>
                <InfoFieldIcon>
                  <ListUl />
                </InfoFieldIcon>
                <InfoFieldLabel>Current streak</InfoFieldLabel>
              </InfoGroupRowInner>

              <InfoGroupRowInner>
                <InfoFieldValue>
                  {current_streak_days}{' '}
                  {current_streak_days === 1 ? streakSuffixSingular : streakSuffixPlural}
                </InfoFieldValue>
              </InfoGroupRowInner>
            </InfoGroupRow>

            <InfoGroupRow>
              <InfoGroupRowInner>
                <InfoFieldIcon>
                  <ListStars />
                </InfoFieldIcon>
                <InfoFieldLabel>Best streak</InfoFieldLabel>
              </InfoGroupRowInner>

              <InfoGroupRowInner>
                <InfoFieldValue>
                  {best_streak_days}{' '}
                  {best_streak_days === 1 ? streakSuffixSingular : streakSuffixPlural}
                </InfoFieldValue>
              </InfoGroupRowInner>
            </InfoGroupRow>
          </InfoGroup>
        </Info>

        <Actions>
          <CheckinLink to={`/${l('ROUTE_CAUSES')}/${opportunity_id}/checkin`}>Check-in</CheckinLink>
        </Actions>
      </ActionsWrapper>
    </DashCardWrapper>
  )
}

export default DashChallengeCard
