import React from 'react'

// assets
import feature from 'assets/images/marketing/feature.png'

// styles
import {
  CenterHeader,
  ComponentContainer,
  FeatureHeader,
  FeatureIcon,
  FeatureItem,
  FeatureItems,
  FeatureItemsWrapped,
  FeatureItemDetails,
  FeatureItemFlex,
  FeatureItemFlexWrapped,
  FeatureItemFlexWrappedThird,
  FeatureItemFlexWrappedQuarter,
  FeatureParagraph,
  FeatureParagraphWrapped,
  Header,
  HeartWrapper,
  HorizontalParagraph,
  HorizontalSection,
  ImageContainer,
  MarketingContentSection,
  Paragraph,
  StepArrow,
  StepBlock,
  StepBlocks,
  StepDetails,
  StepHeader,
  StepIcon
} from './styles'

// components
import { Heart } from 'components/Icons/Icons'
import ConditionalDisplay from 'components/ConditionalDisplay/ConditionalDisplay'
import LinkButton from 'components/SmallButtonPrimary/LinkButton'

const MarketingComponents = ({
  featureOneHeader,
  featureOneParagraph,
  featureTwoHeader,
  featureTwoParagraph,
  featureThreeHeader,
  featureThreeParagraph,
  featureFourHeader,
  featureFourParagraph,
  features = [],
  header,
  iconOne,
  iconTwo,
  iconThree,
  iconFour,
  paragraph,
  stepOneHeader,
  stepOneParagraph,
  stepTwoHeader,
  stepTwoParagraph,
  stepThreeHeader,
  stepThreeParagraph,
  version
}) => {
  if (version === 'one') {
    return (
      <ComponentContainer>
        <ImageContainer src={feature} />
        <MarketingContentSection version={version}>
          <Header>{header}</Header>
          <Paragraph>{paragraph}</Paragraph>
        </MarketingContentSection>
      </ComponentContainer>
    )
  }

  if (version === 'two') {
    return (
      <ComponentContainer>
        <MarketingContentSection version={version}>
          <Header>{header}</Header>
          <Paragraph>{paragraph}</Paragraph>
        </MarketingContentSection>
        <ImageContainer src={feature} />
      </ComponentContainer>
    )
  }

  if (version === 'three') {
    return (
      <ComponentContainer column>
        <MarketingContentSection version={version}>
          <CenterHeader>{header}</CenterHeader>
          <HorizontalParagraph>{paragraph}</HorizontalParagraph>
        </MarketingContentSection>

        <HorizontalSection>
          <FeatureItems>
            <FeatureItem>
              <FeatureHeader>{featureOneHeader}</FeatureHeader>
              <FeatureParagraph>{featureOneParagraph}</FeatureParagraph>
            </FeatureItem>

            <FeatureItem>
              <FeatureHeader>{featureTwoHeader}</FeatureHeader>
              <FeatureParagraph>{featureTwoParagraph}</FeatureParagraph>
            </FeatureItem>
          </FeatureItems>

          <ImageContainer src={feature} version={version} />
        </HorizontalSection>
      </ComponentContainer>
    )
  }

  if (version === 'four') {
    return (
      <ComponentContainer column>
        <MarketingContentSection version={version}>
          <CenterHeader>{header}</CenterHeader>
          <HorizontalParagraph>{paragraph}</HorizontalParagraph>
        </MarketingContentSection>

        <HorizontalSection>
          <StepBlocks>
            <StepBlock>
              <StepIcon icon={iconOne} />
              <StepHeader>{stepOneHeader}</StepHeader>
              <StepDetails>{stepOneParagraph}</StepDetails>
            </StepBlock>

            <StepArrow />

            <StepBlock>
              <StepIcon icon={iconTwo} />
              <StepHeader>{stepTwoHeader}</StepHeader>
              <StepDetails>{stepTwoParagraph}</StepDetails>
            </StepBlock>

            <StepArrow />

            <StepBlock>
              <StepIcon icon={iconThree} />
              <StepHeader>{stepThreeHeader}</StepHeader>
              <StepDetails>{stepThreeParagraph}</StepDetails>
            </StepBlock>
          </StepBlocks>
        </HorizontalSection>
      </ComponentContainer>
    )
  }

  if (version === 'five') {
    return (
      <ComponentContainer column>
        <MarketingContentSection version={version}>
          <CenterHeader>{header}</CenterHeader>
        </MarketingContentSection>

        <HorizontalSection>
          <FeatureItems>
            <FeatureItemFlex>
              <FeatureIcon icon={iconOne} />
              <FeatureItemDetails>
                <FeatureHeader>{featureOneHeader}</FeatureHeader>
                <FeatureParagraph>{featureOneParagraph}</FeatureParagraph>
              </FeatureItemDetails>
            </FeatureItemFlex>

            <FeatureItemFlex>
              <FeatureIcon icon={iconTwo} />
              <FeatureItemDetails>
                <FeatureHeader>{featureTwoHeader}</FeatureHeader>
                <FeatureParagraph>{featureTwoParagraph}</FeatureParagraph>
              </FeatureItemDetails>
            </FeatureItemFlex>

            <FeatureItemFlex>
              <FeatureIcon icon={iconThree} />
              <FeatureItemDetails>
                <FeatureHeader>{featureThreeHeader}</FeatureHeader>
                <FeatureParagraph>{featureThreeParagraph}</FeatureParagraph>
              </FeatureItemDetails>
            </FeatureItemFlex>

            <FeatureItemFlex>
              <FeatureIcon icon={iconFour} />
              <FeatureItemDetails>
                <FeatureHeader>{featureFourHeader}</FeatureHeader>
                <FeatureParagraph>{featureFourParagraph}</FeatureParagraph>
              </FeatureItemDetails>
            </FeatureItemFlex>
          </FeatureItems>
        </HorizontalSection>
      </ComponentContainer>
    )
  }

  if (version === 'six') {
    return (
      <ComponentContainer column>
        <MarketingContentSection version={version}>
          <CenterHeader>{header}</CenterHeader>
        </MarketingContentSection>

        <HorizontalSection>
          <FeatureItemsWrapped>
            {features.map(feature => {
              return (
                <FeatureItemFlexWrapped>
                  <HeartWrapper>
                    <Heart size={20} />
                  </HeartWrapper>
                  <FeatureParagraphWrapped>{feature}</FeatureParagraphWrapped>
                </FeatureItemFlexWrapped>
              )
            })}
          </FeatureItemsWrapped>
        </HorizontalSection>
      </ComponentContainer>
    )
  }

  if (version === 'seven') {
    return (
      <ComponentContainer column>
        <MarketingContentSection version={version}>
          <CenterHeader>{header}</CenterHeader>
          <HorizontalParagraph>{paragraph}</HorizontalParagraph>
        </MarketingContentSection>

        <HorizontalSection>
          <FeatureItemsWrapped>
            {features.map((feature, idx) => {
              return (
                <FeatureItemFlexWrappedThird key={idx}>
                  <FeatureIcon
                    icon={feature.icon}
                    iconHeight={feature.iconHeight}
                    iconWidth={feature.iconWidth}
                    marginBottom={feature.marginBottom}
                  />
                  <FeatureItemDetails>
                    <FeatureHeader center>{feature.title}</FeatureHeader>
                    <FeatureParagraph>{feature.text}</FeatureParagraph>

                    <ConditionalDisplay displayWhen={[feature.button]}>
                      <div style={{ display: 'flex', justifyContent: 'center' }}>
                        <LinkButton
                          onClick={() => window.open(feature.button ? feature.button.target : null)}
                        >
                          {feature.button ? feature.button.label : null}
                        </LinkButton>
                      </div>
                    </ConditionalDisplay>
                  </FeatureItemDetails>
                </FeatureItemFlexWrappedThird>
              )
            })}
          </FeatureItemsWrapped>
        </HorizontalSection>
      </ComponentContainer>
    )
  }

  if (version === 'eight') {
    return (
      <ComponentContainer column>
        <MarketingContentSection version={version}>
          <CenterHeader>{header}</CenterHeader>
          <HorizontalParagraph>{paragraph}</HorizontalParagraph>
        </MarketingContentSection>

        <HorizontalSection>
          <FeatureItemsWrapped>
            {features.map((feature, idx) => {
              return (
                <FeatureItemFlexWrappedQuarter key={idx}>
                  <FeatureIcon
                    icon={feature.icon}
                    iconHeight={feature.iconHeight}
                    iconWidth={feature.iconWidth}
                    marginBottom={feature.marginBottom}
                  />
                  <FeatureItemDetails>
                    <FeatureHeader center>{feature.title}</FeatureHeader>
                    <FeatureParagraph>{feature.text}</FeatureParagraph>

                    <ConditionalDisplay displayWhen={[feature.button]}>
                      <div style={{ display: 'flex', justifyContent: 'center' }}>
                        <LinkButton
                          onClick={() => window.open(feature.button ? feature.button.target : null)}
                        >
                          {feature.button ? feature.button.label : null}
                        </LinkButton>
                      </div>
                    </ConditionalDisplay>
                  </FeatureItemDetails>
                </FeatureItemFlexWrappedQuarter>
              )
            })}
          </FeatureItemsWrapped>
        </HorizontalSection>
      </ComponentContainer>
    )
  }

  return (
    <ComponentContainer>
      <MarketingContentSection version={version}>
        <Header>Choose a marketing component version</Header>
      </MarketingContentSection>
    </ComponentContainer>
  )
}

export default MarketingComponents
