import React from 'react'
import styled from 'styled-components'
import { Link } from '@reach/router'

// config
import constants from 'config/constants'

// Load components synchronously
import LargeButton from 'components/LargeButton/LargeButton'

const HeroContainer = styled.div`
  padding: 50px 20px;
  background: linear-gradient(to right, rgba(0, 0, 0, 0.6) 0%, rgba(0, 0, 0, 0.25) 100%),
    url(${props => props.src});
  background-size: cover;
  background-position: center center;

  @media screen and (min-width: 730px) {
    padding: 50px;
  }

  @media screen and (min-width: 1024px) {
    padding: 90px;
  }
`

const TitlesContainer = styled.div`
  width: 100%;
  margin-bottom: 30px;

  @media screen and (min-width: 1024px) {
    width: 50%;
  }
`

const Title = styled.h2`
  color: white;
  margin-top: 0;
  margin-bottom: 30px;
`

const Subtitle = styled.h4`
  color: white;
  margin-bottom: 0;
`

const LandingPageNeedVolunteers = ({ title, subtitle, buttonLabel, doesSupportWebP }) => {
  const heroImage = constants.S3_ASSET_PATH + 'landing-page-need-volunteers.jpg'
  const heroImageWebP = constants.S3_ASSET_PATH + 'landing-page-need-volunteers.webp'
  const imageUrl = doesSupportWebP ? heroImageWebP : heroImage

  return (
    <HeroContainer src={imageUrl}>
      <TitlesContainer>
        <Title>{title}</Title>
        <Subtitle>{subtitle}</Subtitle>
      </TitlesContainer>

      <Link to={'/join'} target="_blank" rel="noopener">
        <LargeButton>{buttonLabel}</LargeButton>
      </Link>
    </HeroContainer>
  )
}

export default LandingPageNeedVolunteers
