import React from 'react'
import styled from 'styled-components'
import { Link } from '@reach/router'

// icons
import { CheckCircleFill } from '@styled-icons/bootstrap/CheckCircleFill'
import { PeopleFill } from '@styled-icons/bootstrap'
import { Settings } from 'styled-icons/ionicons-outline'

// assets
import { Envelope } from 'components/Icons/Icons'

// utils
import th from 'utils/themeHelper'

// styles
import { getPillColors } from 'components/SearchResultsContainer/styles'
import DashCardWrapper from './DashCardWrapper'

const ActionItem = styled.div`
  margin-right: 10px;

  &:last-of-type {
    margin-right: 0;
  }
`

const Actions = styled.div`
  align-items: center;
  display: flex;
  flex: 1;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin: 10px;
`

const ActionsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`

const ManagePersonLink = styled(Link)`
  border-color: ${th('backgrounds.mid')};
  border-radius: 4px;
  border-style: solid;
  border-width: 1px;
  color: ${th('text.dark')};
  margin: 0;
  padding: 8px 12px;
  text-align: center;
  text-decoration: none;
  white-space: nowrap;

  & > svg {
    width: 15px;
    margin-right: 10px;
  }
`

const Info = styled.div`
  display: block;
  margin: 10px 15px 0;
  flex: 1;
`

const InfoFieldLabel = styled.div`
  font-family: ${th('fonts.bold')};
  margin-right: 10px;
`

const InfoFieldValue = styled.div`
  font-family: ${th('fonts.regular')};
  margin-left: 30px;
`

const InfoFieldIcon = styled.div`
  margin-right: 10px;

  & > svg {
    height: 20px;
  }
`

const InfoGroup = styled.div`
  min-width: 30%;
`

const InfoGroupRow = styled.div`
  color: ${th('text.mid')};
  line-height: 1.25em;
  margin-bottom: 15px;
`

const InfoGroupRowInner = styled.div`
  display: flex;
  word-break: break-word;
`

const Pill = styled.div`
  background: ${props => props.bgColor || th('backgrounds.default')};
  color: ${props => props.fontColor || th('text.dark')};
  border-radius: 3px;
  display: inline-block;
  font-size: 9px;
  margin-top: 2px;
  padding: 2px 5px;
  text-align: center;
  text-transform: uppercase;
  width: ${props => (props.fullWidth ? '100%' : 'auto')};
`

const DashPersonCard = ({ id, is_onboarded, member_email, name }) => {
  const { bgColor, color } = getPillColors({ key: 'is_onboarded', value: is_onboarded })

  return (
    <DashCardWrapper>
      <ActionsWrapper>
        <Info>
          <InfoGroup>
            <InfoGroupRow>
              <InfoGroupRowInner>
                <InfoFieldIcon>
                  <PeopleFill />
                </InfoFieldIcon>
                <InfoFieldLabel>Name</InfoFieldLabel>
              </InfoGroupRowInner>

              <InfoGroupRowInner>
                <InfoFieldValue>{name}</InfoFieldValue>
              </InfoGroupRowInner>
            </InfoGroupRow>

            <InfoGroupRow>
              <InfoGroupRowInner>
                <InfoFieldIcon>
                  <Envelope />
                </InfoFieldIcon>
                <InfoFieldLabel>Email</InfoFieldLabel>
              </InfoGroupRowInner>

              <InfoGroupRowInner>
                <InfoFieldValue>{member_email}</InfoFieldValue>
              </InfoGroupRowInner>
            </InfoGroupRow>

            <InfoGroupRow>
              <InfoGroupRow>
                <InfoGroupRowInner>
                  <InfoFieldIcon>
                    <CheckCircleFill />
                  </InfoFieldIcon>
                  <InfoFieldLabel>Onboarded status</InfoFieldLabel>
                </InfoGroupRowInner>

                <InfoGroupRowInner>
                  <InfoFieldValue>
                    <Pill bgColor={bgColor} fontColor={color}>
                      {is_onboarded}
                    </Pill>
                  </InfoFieldValue>
                </InfoGroupRowInner>
              </InfoGroupRow>
            </InfoGroupRow>
          </InfoGroup>
        </Info>

        <Actions>
          <ActionItem>
            <ManagePersonLink to={`/dashboard/manage-person/${id}`}>
              <Settings />
              <span>Manage person</span>
            </ManagePersonLink>
          </ActionItem>
        </Actions>
      </ActionsWrapper>
    </DashCardWrapper>
  )
}

export default DashPersonCard
