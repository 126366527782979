import { call, put, takeLatest } from 'redux-saga/effects'
import * as categoriesModule from '../modules/categories'
import * as categoriesApi from '../apiModules/categories'
import logError from '../../utils/logger'

function* fetchCategories({ payload }) {
  try {
    const response = yield call(categoriesApi.fetchCategories, payload)
    yield put(categoriesModule.fetchSuccess(response.data))
  } catch (err) {
    logError(err)
  }
}

export const categoriesSagas = [takeLatest(categoriesModule.FETCH_CATEGORIES, fetchCategories)]
