import { createActions, handleActions } from 'redux-actions'

export const DONATE_TO_ORGANISATION = 'donations/DONATE_TO_ORGANISATION'
export const DONATE_TO_ORGANISATION_SUCCESS = 'donations/DONATE_TO_ORGANISATION_SUCCESS'
export const DONATE_TO_ORGANISATION_FAILURE = 'donations/DONATE_TO_ORGANISATION_FAILURE'

export const LOGOUT_SUCCESS = 'authentication/LOGOUT_SUCCESS'

export const {
  donations: { donateToOrganisation, donateToOrganisationSuccess, donateToOrganisationFailure }
} = createActions({
  [DONATE_TO_ORGANISATION]: undefined,
  [DONATE_TO_ORGANISATION_SUCCESS]: undefined,
  [DONATE_TO_ORGANISATION_FAILURE]: undefined
})

const initialState = {
  errorMessage: null,
  isLoading: null,
  isSubmitting: null,
  isSuccess: null,
  postDonationRedirectUrl: null
}

export default handleActions(
  {
    [DONATE_TO_ORGANISATION]: state => ({
      ...state,
      errorMessage: null,
      isSubmitting: true,
      isSuccess: false
    }),
    [DONATE_TO_ORGANISATION_SUCCESS]: (state, { payload }) => ({
      ...state,
      errorMessage: null,
      isSubmitting: false,
      isSuccess: true,
      postDonationRedirectUrl: payload.postDonationRedirectUrl
    }),
    [DONATE_TO_ORGANISATION_FAILURE]: state => ({
      ...state,
      errorMessage: 'error',
      isSubmitting: false,
      isSuccess: false,
      postDonationRedirectUrl: null
    }),

    [LOGOUT_SUCCESS]: () => initialState
  },
  initialState
)
