import { createActions, handleActions } from 'redux-actions'

export const FETCH_GOODS_DONATION_INFO = 'donationConfirmations/FETCH_GOODS_DONATION_INFO'
export const FETCH_GOODS_DONATION_INFO_SUCCESS =
  'donationConfirmations/FETCH_GOODS_DONATION_INFO_SUCCESS'
export const FETCH_GOODS_DONATION_INFO_FAILURE =
  'donationConfirmations/FETCH_GOODS_DONATION_INFO_FAILURE'

export const GOODS_DONATION_CONFIRMATION_REQUEST =
  'donationConfirmations/GOODS_DONATION_CONFIRMATION_REQUEST'
export const GOODS_DONATION_CONFIRMATION_REQUEST_SUCCESS =
  'donationConfirmations/GOODS_DONATION_CONFIRMATION_REQUEST_SUCCESS'
export const GOODS_DONATION_CONFIRMATION_REQUEST_FAILURE =
  'donationConfirmations/GOODS_DONATION_CONFIRMATION_REQUEST_FAILURE'

export const FETCH_MONEY_DONATION_INFO = 'donationConfirmations/FETCH_MONEY_DONATION_INFO'
export const FETCH_MONEY_DONATION_INFO_SUCCESS =
  'donationConfirmations/FETCH_MONEY_DONATION_INFO_SUCCESS'
export const FETCH_MONEY_DONATION_INFO_FAILURE =
  'donationConfirmations/FETCH_MONEY_DONATION_INFO_FAILURE'

export const DONATION_CONFIRMATION_REQUEST = 'donationConfirmations/DONATION_CONFIRMATION_REQUEST'
export const DONATION_CONFIRMATION_REQUEST_SUCCESS =
  'donationConfirmations/DONATION_CONFIRMATION_REQUEST_SUCCESS'
export const DONATION_CONFIRMATION_REQUEST_FAILURE =
  'donationConfirmations/DONATION_CONFIRMATION_REQUEST_FAILURE'

export const FETCH_ORG_GOODS_DONATION_INFO = 'donationConfirmations/FETCH_ORG_GOODS_DONATION_INFO'
export const FETCH_ORG_GOODS_DONATION_INFO_SUCCESS =
  'donationConfirmations/FETCH_ORG_GOODS_DONATION_INFO_SUCCESS'
export const FETCH_ORG_GOODS_DONATION_INFO_FAILURE =
  'donationConfirmations/FETCH_ORG_GOODS_DONATION_INFO_FAILURE'

export const GOODS_ORG_DONATION_CONFIRMATION_REQUEST =
  'donationConfirmations/GOODS_ORG_DONATION_CONFIRMATION_REQUEST'
export const GOODS_ORG_DONATION_CONFIRMATION_REQUEST_SUCCESS =
  'donationConfirmations/GOODS_ORG_DONATION_CONFIRMATION_REQUEST_SUCCESS'
export const GOODS_ORG_DONATION_CONFIRMATION_REQUEST_FAILURE =
  'donationConfirmations/GOODS_ORG_DONATION_CONFIRMATION_REQUEST_FAILURE'

export const FETCH_ORG_MONEY_DONATION_INFO = 'donationConfirmations/FETCH_ORG_MONEY_DONATION_INFO'
export const FETCH_ORG_MONEY_DONATION_INFO_SUCCESS =
  'donationConfirmations/FETCH_ORG_MONEY_DONATION_INFO_SUCCESS'
export const FETCH_ORG_MONEY_DONATION_INFO_FAILURE =
  'donationConfirmations/FETCH_ORG_MONEY_DONATION_INFO_FAILURE'

export const REQUEST_ORG_DONATION_CONFIRMATION =
  'donationConfirmations/REQUEST_ORG_DONATION_CONFIRMATION'
export const REQUEST_ORG_DONATION_CONFIRMATION_SUCCESS =
  'donationConfirmations/REQUEST_ORG_DONATION_CONFIRMATION_SUCCESS'
export const REQUEST_ORG_DONATION_CONFIRMATION_FAILURE =
  'donationConfirmations/REQUEST_ORG_DONATION_CONFIRMATION_FAILURE'

export const FETCH_GOODS_DONATION_CONFIRMATION =
  'donationConfirmations/FETCH_GOODS_DONATION_CONFIRMATION'
export const FETCH_GOODS_DONATION_CONFIRMATION_SUCCESS =
  'donationConfirmations/FETCH_GOODS_DONATION_CONFIRMATION_SUCCESS'
export const FETCH_GOODS_DONATION_CONFIRMATION_FAILURE =
  'donationConfirmations/FETCH_GOODS_DONATION_CONFIRMATION_FAILURE'

export const HANDLE_GOODS_DONATION_CONFIRMATION =
  'donationConfirmations/HANDLE_GOODS_DONATION_CONFIRMATION'
export const HANDLE_GOODS_DONATION_CONFIRMATION_SUCCESS =
  'donationConfirmations/HANDLE_GOODS_DONATION_CONFIRMATION_SUCCESS'
export const HANDLE_GOODS_DONATION_CONFIRMATION_FAILURE =
  'donationConfirmations/HANDLE_GOODS_DONATION_CONFIRMATION_FAILURE'

export const FETCH_DONATION_CONFIRMATION = 'donationConfirmations/FETCH_DONATION_CONFIRMATION'
export const FETCH_DONATION_CONFIRMATION_SUCCESS =
  'donationConfirmations/FETCH_DONATION_CONFIRMATION_SUCCESS'
export const FETCH_DONATION_CONFIRMATION_FAILURE =
  'donationConfirmations/FETCH_DONATION_CONFIRMATION_FAILURE'

export const HANDLE_DONATION_CONFIRMATION = 'donationConfirmations/HANDLE_DONATION_CONFIRMATION'
export const HANDLE_DONATION_CONFIRMATION_SUCCESS =
  'donationConfirmations/HANDLE_DONATION_CONFIRMATION_SUCCESS'
export const HANDLE_DONATION_CONFIRMATION_FAILURE =
  'donationConfirmations/HANDLE_DONATION_CONFIRMATION_FAILURE'

export const FETCH_ORG_GOODS_DONATION_CONFIRMATION =
  'donationConfirmations/FETCH_ORG_GOODS_DONATION_CONFIRMATION'
export const FETCH_ORG_GOODS_DONATION_CONFIRMATION_SUCCESS =
  'donationConfirmations/FETCH_ORG_GOODS_DONATION_CONFIRMATION_SUCCESS'
export const FETCH_ORG_GOODS_DONATION_CONFIRMATION_FAILURE =
  'donationConfirmations/FETCH_ORG_GOODS_DONATION_CONFIRMATION_FAILURE'

export const HANDLE_ORG_GOODS_DONATION_CONFIRMATION =
  'donationConfirmations/HANDLE_ORG_GOODS_DONATION_CONFIRMATION'
export const HANDLE_ORG_GOODS_DONATION_CONFIRMATION_SUCCESS =
  'donationConfirmations/HANDLE_ORG_GOODS_DONATION_CONFIRMATION_SUCCESS'
export const HANDLE_ORG_GOODS_DONATION_CONFIRMATION_FAILURE =
  'donationConfirmations/HANDLE_ORG_GOODS_DONATION_CONFIRMATION_FAILURE'

export const FETCH_ORG_DONATION_CONFIRMATION =
  'donationConfirmations/FETCH_ORG_DONATION_CONFIRMATION'
export const FETCH_ORG_DONATION_CONFIRMATION_SUCCESS =
  'donationConfirmations/FETCH_ORG_DONATION_CONFIRMATION_SUCCESS'
export const FETCH_ORG_DONATION_CONFIRMATION_FAILURE =
  'donationConfirmations/FETCH_ORG_DONATION_CONFIRMATION_FAILURE'

export const HANDLE_ORG_DONATION_CONFIRMATION =
  'donationConfirmations/HANDLE_ORG_DONATION_CONFIRMATION'
export const HANDLE_ORG_DONATION_CONFIRMATION_SUCCESS =
  'donationConfirmations/HANDLE_ORG_DONATION_CONFIRMATION_SUCCESS'
export const HANDLE_ORG_DONATION_CONFIRMATION_FAILURE =
  'donationConfirmations/HANDLE_ORG_DONATION_CONFIRMATION_FAILURE'

export const LOGOUT_SUCCESS = 'authentication/LOGOUT_SUCCESS'

export const {
  donationConfirmations: {
    fetchGoodsDonationInfo,
    fetchGoodsDonationInfoSuccess,
    fetchGoodsDonationInfoFailure,
    goodsDonationConfirmationRequest,
    goodsDonationConfirmationRequestSuccess,
    goodsDonationConfirmationRequestFailure,
    fetchMoneyDonationInfo,
    fetchMoneyDonationInfoSuccess,
    fetchMoneyDonationInfoFailure,
    donationConfirmationRequest,
    donationConfirmationRequestSuccess,
    donationConfirmationRequestFailure,
    fetchOrgGoodsDonationInfo,
    fetchOrgGoodsDonationInfoSuccess,
    fetchOrgGoodsDonationInfoFailure,
    goodsOrgDonationConfirmationRequest,
    goodsOrgDonationConfirmationRequestSuccess,
    goodsOrgDonationConfirmationRequestFailure,
    fetchOrgMoneyDonationInfo,
    fetchOrgMoneyDonationInfoSuccess,
    fetchOrgMoneyDonationInfoFailure,
    requestOrgDonationConfirmation,
    requestOrgDonationConfirmationSuccess,
    requestOrgDonationConfirmationFailure,
    fetchGoodsDonationConfirmation,
    fetchGoodsDonationConfirmationSuccess,
    fetchGoodsDonationConfirmationFailure,
    handleGoodsDonationConfirmation,
    handleGoodsDonationConfirmationSuccess,
    handleGoodsDonationConfirmationFailure,
    fetchDonationConfirmation,
    fetchDonationConfirmationSuccess,
    fetchDonationConfirmationFailure,
    handleDonationConfirmation,
    handleDonationConfirmationSuccess,
    handleDonationConfirmationFailure,
    fetchOrgGoodsDonationConfirmation,
    fetchOrgGoodsDonationConfirmationSuccess,
    fetchOrgGoodsDonationConfirmationFailure,
    handleOrgGoodsDonationConfirmation,
    handleOrgGoodsDonationConfirmationSuccess,
    handleOrgGoodsDonationConfirmationFailure,
    fetchOrgDonationConfirmation,
    fetchOrgDonationConfirmationSuccess,
    fetchOrgDonationConfirmationFailure,
    handleOrgDonationConfirmation,
    handleOrgDonationConfirmationSuccess,
    handleOrgDonationConfirmationFailure
  }
} = createActions({
  [FETCH_GOODS_DONATION_INFO]: undefined,
  [FETCH_GOODS_DONATION_INFO_SUCCESS]: undefined,
  [FETCH_GOODS_DONATION_INFO_FAILURE]: undefined,
  [GOODS_DONATION_CONFIRMATION_REQUEST]: undefined,
  [GOODS_DONATION_CONFIRMATION_REQUEST_SUCCESS]: undefined,
  [GOODS_DONATION_CONFIRMATION_REQUEST_FAILURE]: undefined,
  [FETCH_MONEY_DONATION_INFO]: undefined,
  [FETCH_MONEY_DONATION_INFO_SUCCESS]: undefined,
  [FETCH_MONEY_DONATION_INFO_FAILURE]: undefined,
  [DONATION_CONFIRMATION_REQUEST]: undefined,
  [DONATION_CONFIRMATION_REQUEST_SUCCESS]: undefined,
  [DONATION_CONFIRMATION_REQUEST_FAILURE]: undefined,
  [FETCH_ORG_GOODS_DONATION_INFO]: undefined,
  [FETCH_ORG_GOODS_DONATION_INFO_SUCCESS]: undefined,
  [FETCH_ORG_GOODS_DONATION_INFO_FAILURE]: undefined,
  [GOODS_ORG_DONATION_CONFIRMATION_REQUEST]: undefined,
  [GOODS_ORG_DONATION_CONFIRMATION_REQUEST_SUCCESS]: undefined,
  [GOODS_ORG_DONATION_CONFIRMATION_REQUEST_FAILURE]: undefined,
  [FETCH_ORG_MONEY_DONATION_INFO]: undefined,
  [FETCH_ORG_MONEY_DONATION_INFO_SUCCESS]: undefined,
  [FETCH_ORG_MONEY_DONATION_INFO_FAILURE]: undefined,
  [REQUEST_ORG_DONATION_CONFIRMATION]: undefined,
  [REQUEST_ORG_DONATION_CONFIRMATION_SUCCESS]: undefined,
  [REQUEST_ORG_DONATION_CONFIRMATION_FAILURE]: undefined,
  [FETCH_GOODS_DONATION_CONFIRMATION]: undefined,
  [FETCH_GOODS_DONATION_CONFIRMATION_SUCCESS]: undefined,
  [FETCH_GOODS_DONATION_CONFIRMATION_FAILURE]: undefined,
  [HANDLE_GOODS_DONATION_CONFIRMATION]: undefined,
  [HANDLE_GOODS_DONATION_CONFIRMATION_SUCCESS]: undefined,
  [HANDLE_GOODS_DONATION_CONFIRMATION_FAILURE]: undefined,
  [FETCH_DONATION_CONFIRMATION]: undefined,
  [FETCH_DONATION_CONFIRMATION_SUCCESS]: undefined,
  [FETCH_DONATION_CONFIRMATION_FAILURE]: undefined,
  [HANDLE_DONATION_CONFIRMATION]: undefined,
  [HANDLE_DONATION_CONFIRMATION_SUCCESS]: undefined,
  [HANDLE_DONATION_CONFIRMATION_FAILURE]: undefined,
  [FETCH_ORG_GOODS_DONATION_CONFIRMATION]: undefined,
  [FETCH_ORG_GOODS_DONATION_CONFIRMATION_SUCCESS]: undefined,
  [FETCH_ORG_GOODS_DONATION_CONFIRMATION_FAILURE]: undefined,
  [HANDLE_ORG_GOODS_DONATION_CONFIRMATION]: undefined,
  [HANDLE_ORG_GOODS_DONATION_CONFIRMATION_SUCCESS]: undefined,
  [HANDLE_ORG_GOODS_DONATION_CONFIRMATION_FAILURE]: undefined,
  [FETCH_ORG_DONATION_CONFIRMATION]: undefined,
  [FETCH_ORG_DONATION_CONFIRMATION_SUCCESS]: undefined,
  [FETCH_ORG_DONATION_CONFIRMATION_FAILURE]: undefined,
  [HANDLE_ORG_DONATION_CONFIRMATION]: undefined,
  [HANDLE_ORG_DONATION_CONFIRMATION_SUCCESS]: undefined,
  [HANDLE_ORG_DONATION_CONFIRMATION_FAILURE]: undefined
})

const initialState = {
  isLoading: false,
  donationConfirmations: null,
  isError: false,
  isSuccess: null,
  isSubmitting: null,
  opportunity: {},
  goodsDonation: {},
  moneyDonation: {},
  orgGoodsDonation: {},
  orgMoneyDonation: {},
  donationConfirmation: {},
  orgDonationConfirmation: {}
}

export default handleActions(
  {
    [DONATION_CONFIRMATION_REQUEST]: (state, { payload }) => ({
      ...state,
      donationConfirmations: payload.donationConfirmations,
      isLoading: true,
      errorMessage: null
    }),
    [DONATION_CONFIRMATION_REQUEST_SUCCESS]: (state, { payload }) => ({
      ...state,
      moneyDonation: { ...state.moneyDonation, donation_status: payload.status },
      isLoading: false,
      isError: false,
      isSuccess: true,
      errorMessage: null
    }),
    [DONATION_CONFIRMATION_REQUEST_FAILURE]: state => ({
      ...state,
      isLoading: false,
      donationConfirmations: null,
      isError: true,
      isSuccess: false,
      errorMessage:
        'Something went wrong with your donationConfirmations submission. Please try reloading the page.'
    }),
    [GOODS_DONATION_CONFIRMATION_REQUEST]: (state, { payload }) => ({
      ...state,
      donationConfirmations: payload.donationConfirmations,
      isLoading: true,
      errorMessage: null
    }),
    [GOODS_DONATION_CONFIRMATION_REQUEST_SUCCESS]: (state, { payload }) => ({
      ...state,
      goodsDonation: { ...state.goodsDonation, donation_status: payload.status },
      isLoading: false,
      isError: false,
      isSuccess: true,
      errorMessage: null
    }),
    [GOODS_DONATION_CONFIRMATION_REQUEST_FAILURE]: state => ({
      ...state,
      isLoading: false,
      donationConfirmations: null,
      isError: true,
      isSuccess: false,
      errorMessage:
        'Something went wrong with your donationConfirmations submission. Please try reloading the page.'
    }),
    [GOODS_ORG_DONATION_CONFIRMATION_REQUEST]: (state, { payload }) => ({
      ...state,
      orgGoodsDonation: payload,
      isLoading: true,
      isSubmitting: true,
      errorMessage: null
    }),
    [GOODS_ORG_DONATION_CONFIRMATION_REQUEST_SUCCESS]: (state, { payload }) => ({
      ...state,
      orgGoodsDonation: { ...state.orgGoodsDonation, donation_status: payload.status },
      isLoading: false,
      isError: false,
      isSubmitting: false,
      isSuccess: true,
      errorMessage: null
    }),
    [GOODS_ORG_DONATION_CONFIRMATION_REQUEST_FAILURE]: state => ({
      ...state,
      isLoading: false,
      donationConfirmations: null,
      isError: true,
      isSuccess: false,
      isSubmitting: false,
      errorMessage:
        'Something went wrong with your donationConfirmations submission. Please try reloading the page.'
    }),
    [FETCH_GOODS_DONATION_INFO]: state => ({
      ...state,
      isLoading: true,
      errorMessage: null
    }),
    [FETCH_GOODS_DONATION_INFO_SUCCESS]: (state, { payload }) => ({
      ...state,
      isLoading: false,
      isError: false,
      isSuccess: null,
      goodsDonation: payload,
      errorMessage: null
    }),
    [FETCH_GOODS_DONATION_INFO_FAILURE]: () => ({
      isLoading: false,
      isError: true,
      errorMessage: 'Whoops, this seems to be a bad URL.'
    }),
    [FETCH_MONEY_DONATION_INFO]: state => ({
      ...state,
      isLoading: true,
      errorMessage: null
    }),
    [FETCH_MONEY_DONATION_INFO_SUCCESS]: (state, { payload }) => ({
      ...state,
      isLoading: false,
      isError: false,
      isSuccess: null,
      moneyDonation: payload,
      errorMessage: null
    }),
    [FETCH_MONEY_DONATION_INFO_FAILURE]: () => ({
      isLoading: false,
      isError: true,
      errorMessage: 'Whoops, this seems to be a bad URL.'
    }),
    [FETCH_ORG_GOODS_DONATION_INFO]: state => ({
      ...state,
      isLoading: true,
      errorMessage: null
    }),
    [FETCH_ORG_GOODS_DONATION_INFO_SUCCESS]: (state, { payload }) => ({
      ...state,
      isLoading: false,
      isError: false,
      isSuccess: null,
      orgGoodsDonation: payload,
      errorMessage: null
    }),
    [FETCH_ORG_GOODS_DONATION_INFO_FAILURE]: () => ({
      isLoading: false,
      isError: true,
      errorMessage: 'Whoops, this seems to be a bad URL.'
    }),
    [FETCH_ORG_MONEY_DONATION_INFO]: state => ({
      ...state,
      isLoading: true,
      errorMessage: null
    }),
    [FETCH_ORG_MONEY_DONATION_INFO_SUCCESS]: (state, { payload }) => ({
      ...state,
      isLoading: false,
      isError: false,
      isSuccess: null,
      orgMoneyDonation: payload,
      errorMessage: null
    }),
    [FETCH_ORG_MONEY_DONATION_INFO_FAILURE]: () => ({
      isLoading: false,
      isError: true,
      errorMessage: 'Whoops, this seems to be a bad URL.'
    }),
    [REQUEST_ORG_DONATION_CONFIRMATION]: (state, { payload }) => ({
      ...state,
      donationConfirmations: payload.donationConfirmations,
      isLoading: true,
      isSubmitting: true,
      errorMessage: null
    }),
    [REQUEST_ORG_DONATION_CONFIRMATION_SUCCESS]: state => ({
      ...state,
      errorMessage: null,
      donationConfirmations: null,
      isError: false,
      isLoading: false,
      isSubmitting: false,
      isSuccess: true
    }),
    [REQUEST_ORG_DONATION_CONFIRMATION_FAILURE]: state => ({
      ...state,
      isLoading: false,
      isSubmitting: false,
      donationConfirmations: null,
      isError: true,
      isSuccess: false,
      errorMessage:
        'Something went wrong with your donationConfirmations submission. Please try reloading the page.'
    }),
    [FETCH_DONATION_CONFIRMATION]: state => ({
      ...state,
      errorMessage: null,
      hasError: false,
      isLoading: true,
      isSubmitting: false,
      isSuccess: null
    }),
    [FETCH_DONATION_CONFIRMATION_FAILURE]: state => ({
      ...state,
      isSuccess: false,
      isLoading: false,
      hasError: true
    }),
    [FETCH_DONATION_CONFIRMATION_SUCCESS]: (state, { payload }) => ({
      ...state,
      isLoading: false,
      hasSubmitted: false,
      isSuccess: true,
      donationConfirmation: payload,
      errorMessage: null,
      hasError: false
    }),
    [FETCH_ORG_DONATION_CONFIRMATION]: state => ({
      ...state,
      errorMessage: null,
      hasError: false,
      isLoading: true,
      isSubmitting: false,
      isSuccess: null
    }),
    [FETCH_ORG_DONATION_CONFIRMATION_FAILURE]: state => ({
      ...state,
      isSuccess: false,
      isLoading: false,
      hasError: true
    }),
    [FETCH_ORG_DONATION_CONFIRMATION_SUCCESS]: (state, { payload }) => ({
      ...state,
      isLoading: false,
      hasSubmitted: false,
      isSuccess: true,
      orgDonationConfirmation: payload,
      errorMessage: null,
      hasError: false
    }),
    [FETCH_GOODS_DONATION_CONFIRMATION]: state => ({
      ...state,
      errorMessage: null,
      hasError: false,
      isLoading: true,
      isSubmitting: false,
      isSuccess: null
    }),
    [FETCH_GOODS_DONATION_CONFIRMATION_FAILURE]: state => ({
      ...state,
      isSuccess: false,
      isLoading: false,
      hasError: true
    }),
    [FETCH_GOODS_DONATION_CONFIRMATION_SUCCESS]: (state, { payload }) => ({
      ...state,
      isLoading: false,
      hasSubmitted: false,
      isSuccess: true,
      donationConfirmation: payload,
      errorMessage: null,
      hasError: false
    }),
    [FETCH_ORG_GOODS_DONATION_CONFIRMATION]: state => ({
      ...state,
      errorMessage: null,
      hasError: false,
      isLoading: true,
      isSubmitting: false,
      isSuccess: null
    }),
    [FETCH_ORG_GOODS_DONATION_CONFIRMATION_FAILURE]: state => ({
      ...state,
      isSuccess: false,
      isLoading: false,
      hasError: true
    }),
    [FETCH_ORG_GOODS_DONATION_CONFIRMATION_SUCCESS]: (state, { payload }) => ({
      ...state,
      isLoading: false,
      hasSubmitted: false,
      isSuccess: true,
      orgGoodsDonation: payload,
      errorMessage: null,
      hasError: false
    }),
    [HANDLE_DONATION_CONFIRMATION]: state => ({
      ...state,
      hasSubmitted: false,
      isLoading: false,
      isSubmitting: true
    }),
    [HANDLE_DONATION_CONFIRMATION_SUCCESS]: state => {
      return {
        ...state,
        hasSubmitted: true,
        isLoading: false,
        isSubmitting: false,
        isSuccess: true
      }
    },
    [HANDLE_DONATION_CONFIRMATION_FAILURE]: state => ({
      ...state,
      hasSubmitted: false,
      isLoading: false,
      isSubmitting: false
    }),
    [HANDLE_ORG_DONATION_CONFIRMATION]: state => ({
      ...state,
      hasSubmitted: false,
      isLoading: false,
      isSubmitting: true
    }),
    [HANDLE_ORG_DONATION_CONFIRMATION_SUCCESS]: state => {
      return {
        ...state,
        hasSubmitted: true,
        isLoading: false,
        isSubmitting: false,
        isSuccess: true
      }
    },
    [HANDLE_ORG_DONATION_CONFIRMATION_FAILURE]: state => ({
      ...state,
      hasSubmitted: false,
      isLoading: false,
      isSubmitting: false
    }),
    [HANDLE_ORG_GOODS_DONATION_CONFIRMATION]: state => ({
      ...state,
      hasSubmitted: false,
      isLoading: false,
      isSubmitting: true
    }),
    [HANDLE_ORG_GOODS_DONATION_CONFIRMATION_SUCCESS]: state => {
      return {
        ...state,
        hasSubmitted: true,
        isLoading: false,
        isSubmitting: false,
        isSuccess: true
      }
    },
    [HANDLE_ORG_GOODS_DONATION_CONFIRMATION_FAILURE]: state => ({
      ...state,
      hasSubmitted: false,
      isLoading: false,
      isSubmitting: false
    }),
    [LOGOUT_SUCCESS]: () => initialState
  },
  initialState
)
