import { createActions, handleActions } from 'redux-actions'
import UrlBuilder from 'utils/urlBuilder'

export const CREATE_ORGANISATION = 'organisations/CREATE_ORGANISATION'
export const CREATE_SUCCESS = 'organisations/CREATE_SUCCESS'
export const CREATE_FAILURE = 'organisations/CREATE_FAILURE'

export const UPDATE_ORGANISATION = 'organisations/UPDATE_ORGANISATION'
export const UPDATE_BILLING_INFO = 'organisations/UPDATE_BILLING_INFO'
export const UPDATE_SUCCESS = 'organisations/UPDATE_SUCCESS'
export const UPDATE_FAILURE = 'organisations/UPDATE_FAILURE'

export const DELETE_ORGANISATION = 'organisations/DELETE_ORGANISATION'
export const DELETE_SUCCESS = 'organisations/DELETE_SUCCESS'
export const DELETE_FAILURE = 'organisations/DELETE_FAILURE'

export const CLAIM_ORGANISATION_REQUEST = 'organisations/CLAIM_ORGANISATION_REQUEST'
export const CLAIM_ORGANISATION_REQUEST_SUCCESS = 'organisations/CLAIM_ORGANISATION_REQUEST_SUCCESS'
export const CLAIM_ORGANISATION_REQUEST_FAILURE = 'organisations/CLAIM_ORGANISATION_REQUEST_FAILURE'

export const CLAIM_ORGANISATION = 'organisations/CLAIM_ORGANISATION'
export const CLAIM_ORGANISATION_SUCCESS = 'organisations/CLAIM_ORGANISATION_SUCCESS'
export const CLAIM_ORGANISATION_FAILURE = 'organisations/CLAIM_ORGANISATION_FAILURE'

export const JOIN_ORGANISATION_REQUEST = 'organisations/JOIN_ORGANISATION_REQUEST'
export const JOIN_ORGANISATION_REQUEST_SUCCESS = 'organisations/JOIN_ORGANISATION_REQUEST_SUCCESS'
export const JOIN_ORGANISATION_REQUEST_FAILURE = 'organisations/JOIN_ORGANISATION_REQUEST_FAILURE'

export const FETCH_ORGANISATIONS = 'organisations/FETCH_ORGANISATIONS'

export const FETCH_ORG_FOR_PUBLIC_PAGE = 'organisations/FETCH_ORG_FOR_PUBLIC_PAGE'
export const FETCH_SUCCESS = 'organisations/FETCH_SUCCESS'
export const FETCH_PUBLIC_ORGS_SUCCESS = 'organisations/FETCH_PUBLIC_ORGS_SUCCESS'
export const FETCH_FAILURE = 'organisations/FETCH_FAILURE'

export const FETCH_OWN_ORGANISATIONS = 'organisations/FETCH_OWN_ORGANISATIONS'
export const FETCH_OWN_ORGANISATIONS_SUCCESS = 'organisations/FETCH_OWN_ORGANISATIONS_SUCCESS'
export const FETCH_OWN_ORGANISATIONS_FAILURE = 'organisations/FETCH_OWN_ORGANISATIONS_FAILURE'

export const ADD_ORG_ADMIN = 'organisations/ADD_ORG_ADMIN'
export const ADD_ORG_ADMIN_SUCCESS = 'organisations/ADD_ORG_ADMIN_SUCCESS'
export const ADD_ORG_ADMIN_FAILURE = 'organisations/ADD_ORG_ADMIN_FAILURE'

export const MANAGE_ORG_MEMBER_REQUEST = 'organisations/MANAGE_ORG_MEMBER_REQUEST'
export const MANAGE_ORG_MEMBER_REQUEST_SUCCESS = 'organisations/MANAGE_ORG_MEMBER_REQUEST_SUCCESS'
export const MANAGE_ORG_MEMBER_REQUEST_FAILURE = 'organisations/MANAGE_ORG_MEMBER_REQUEST_FAILURE'

export const MANAGE_ORG_MEMBER_INVITATION = 'organisations/MANAGE_ORG_MEMBER_INVITATION'
export const MANAGE_ORG_MEMBER_INVITATION_SUCCESS =
  'organisations/MANAGE_ORG_MEMBER_INVITATION_SUCCESS'
export const MANAGE_ORG_MEMBER_INVITATION_FAILURE =
  'organisations/MANAGE_ORG_MEMBER_INVITATION_FAILURE'

export const UPDATE_ORG_ADMIN = 'organisations/UPDATE_ORG_ADMIN'
export const UPDATE_ORG_ADMIN_SUCCESS = 'organisations/UPDATE_ORG_ADMIN_SUCCESS'
export const UPDATE_ORG_ADMIN_FAILURE = 'organisations/UPDATE_ORG_ADMIN_FAILURE'

export const REQUEST_VERIFICATION = 'organisations/REQUEST_VERIFICATION'
export const REQUEST_VERIFICATION_SUCCESS = 'organisations/REQUEST_VERIFICATION_SUCCESS'
export const REQUEST_VERIFICATION_FAILURE = 'organisations/REQUEST_VERIFICATION_FAILURE'

export const FETCH_ORG_GOALS_FOR_ORG_ADMIN = 'organisations/FETCH_ORG_GOALS_FOR_ORG_ADMIN'
export const FETCH_ORG_GOALS_FOR_ORG_ADMIN_SUCCESS =
  'organisations/FETCH_ORG_GOALS_FOR_ORG_ADMIN_SUCCESS'
export const FETCH_ORG_GOALS_FOR_ORG_ADMIN_FAILURE =
  'organisations/FETCH_ORG_GOALS_FOR_ORG_ADMIN_FAILURE'

export const FETCH_ORG_GOAL = 'organisations/FETCH_ORG_GOAL'
export const FETCH_ORG_GOAL_SUCCESS = 'organisations/FETCH_ORG_GOAL_SUCCESS'
export const FETCH_ORG_GOAL_FAILURE = 'organisations/FETCH_ORG_GOAL_FAILURE'

export const CREATE_ORG_GOAL = 'organisations/CREATE_ORG_GOAL'
export const CREATE_ORG_GOAL_SUCCESS = 'organisations/CREATE_ORG_GOAL_SUCCESS'
export const CREATE_ORG_GOAL_FAILURE = 'organisations/CREATE_ORG_GOAL_FAILURE'

export const DELETE_ORG_GOAL = 'organisations/DELETE_ORG_GOAL'
export const DELETE_ORG_GOAL_SUCCESS = 'organisations/DELETE_ORG_GOAL_SUCCESS'
export const DELETE_ORG_GOAL_FAILURE = 'organisations/DELETE_ORG_GOAL_FAILURE'

export const UPDATE_ORG_GOAL = 'organisations/UPDATE_ORG_GOAL'
export const UPDATE_ORG_GOAL_SUCCESS = 'organisations/UPDATE_ORG_GOAL_SUCCESS'
export const UPDATE_ORG_GOAL_FAILURE = 'organisations/UPDATE_ORG_GOAL_FAILURE'

export const FETCH_ORG_GOAL_MILESTONE = 'organisations/FETCH_ORG_GOAL_MILESTONE'
export const FETCH_ORG_GOAL_MILESTONE_SUCCESS = 'organisations/FETCH_ORG_GOAL_MILESTONE_SUCCESS'
export const FETCH_ORG_GOAL_MILESTONE_FAILURE = 'organisations/FETCH_ORG_GOAL_MILESTONE_FAILURE'

export const CREATE_ORG_GOAL_MILESTONE = 'organisations/CREATE_ORG_GOAL_MILESTONE'
export const CREATE_ORG_GOAL_MILESTONE_SUCCESS = 'organisations/CREATE_ORG_GOAL_MILESTONE_SUCCESS'
export const CREATE_ORG_GOAL_MILESTONE_FAILURE = 'organisations/CREATE_ORG_GOAL_MILESTONE_FAILURE'

export const DELETE_ORG_GOAL_MILESTONE = 'organisations/DELETE_ORG_GOAL_MILESTONE'
export const DELETE_ORG_GOAL_MILESTONE_SUCCESS = 'organisations/DELETE_ORG_GOAL_MILESTONE_SUCCESS'
export const DELETE_ORG_GOAL_MILESTONE_FAILURE = 'organisations/DELETE_ORG_GOAL_MILESTONE_FAILURE'

export const UPDATE_ORG_GOAL_MILESTONE = 'organisations/UPDATE_ORG_GOAL_MILESTONE'
export const UPDATE_ORG_GOAL_MILESTONE_SUCCESS = 'organisations/UPDATE_ORG_GOAL_MILESTONE_SUCCESS'
export const UPDATE_ORG_GOAL_MILESTONE_FAILURE = 'organisations/UPDATE_ORG_GOAL_MILESTONE_FAILURE'

export const REMOVE_ORG_ADMIN = 'organisations/REMOVE_ORG_ADMIN'
export const REMOVE_ORG_ADMIN_SUCCESS = 'organisations/REMOVE_ORG_ADMIN_SUCCESS'
export const REMOVE_ORG_ADMIN_FAILURE = 'organisations/REMOVE_ORG_ADMIN_FAILURE'

export const UPDATE_ORG_STORIES = 'organisations/UPDATE_ORG_STORIES'

export const FETCH_ORGANISATION = 'organisations/FETCH_ORGANISATION'
export const SINGLE_FETCH_SUCCESS = 'organisations/SINGLE_FETCH_SUCCESS'
export const SINGLE_FETCH_FAILURE = 'organisations/SINGLE_FETCH_FAILURE'

export const LOGOUT_SUCCESS = 'authentication/LOGOUT_SUCCESS'

export const {
  organisations: {
    createOrganisation,
    createSuccess,
    createFailure,

    updateOrganisation,
    updateBillingInfo,
    updateSuccess,
    updateFailure,

    deleteOrganisation,
    deleteSuccess,
    deleteFailure,

    claimOrganisation,
    claimOrganisationSuccess,
    claimOrganisationFailure,

    claimOrganisationRequest,
    claimOrganisationRequestSuccess,
    claimOrganisationRequestFailure,

    joinOrganisationRequest,
    joinOrganisationRequestSuccess,
    joinOrganisationRequestFailure,

    fetchOrganisations,
    fetchSuccess,
    fetchPublicOrgsSuccess,
    fetchFailure,

    fetchOwnOrganisations,
    fetchOwnOrganisationsSuccess,
    fetchOwnOrganisationsFailure,

    fetchOrgGoalsForOrgAdmin,
    fetchOrgGoalsForOrgAdminSuccess,
    fetchOrgGoalsForOrgAdminFailure,

    fetchOrgGoal,
    fetchOrgGoalSuccess,
    fetchOrgGoalFailure,

    createOrgGoal,
    createOrgGoalSuccess,
    createOrgGoalFailure,

    deleteOrgGoal,
    deleteOrgGoalSuccess,
    deleteOrgGoalFailure,

    updateOrgGoal,
    updateOrgGoalSuccess,
    updateOrgGoalFailure,

    fetchOrgGoalMilestone,
    fetchOrgGoalMilestoneSuccess,
    fetchOrgGoalMilestoneFailure,

    createOrgGoalMilestone,
    createOrgGoalMilestoneSuccess,
    createOrgGoalMilestoneFailure,

    deleteOrgGoalMilestone,
    deleteOrgGoalMilestoneSuccess,
    deleteOrgGoalMilestoneFailure,

    updateOrgGoalMilestone,
    updateOrgGoalMilestoneSuccess,
    updateOrgGoalMilestoneFailure,

    addOrgAdmin,
    addOrgAdminSuccess,
    addOrgAdminFailure,

    manageOrgMemberRequest,
    manageOrgMemberRequestSuccess,
    manageOrgMemberRequestFailure,

    manageOrgMemberInvitation,
    manageOrgMemberInvitationSuccess,
    manageOrgMemberInvitationFailure,

    requestVerification,
    requestVerificationSuccess,
    requestVerificationFailure,

    updateOrgAdmin,
    updateOrgAdminSuccess,
    updateOrgAdminFailure,

    removeOrgAdmin,
    removeOrgAdminSuccess,
    removeOrgAdminFailure,

    updateOrgStories,

    fetchOrganisation,
    fetchOrgForPublicPage,
    singleFetchSuccess,
    singleFetchFailure
  }
} = createActions({
  [CREATE_ORGANISATION]: undefined,
  [CREATE_SUCCESS]: undefined,
  [CREATE_FAILURE]: undefined,

  [UPDATE_ORGANISATION]: undefined,
  [UPDATE_BILLING_INFO]: undefined,
  [UPDATE_SUCCESS]: undefined,
  [UPDATE_FAILURE]: undefined,

  [DELETE_ORGANISATION]: undefined,
  [DELETE_SUCCESS]: organisationId => ({ organisationId }),
  [DELETE_FAILURE]: undefined,

  [CLAIM_ORGANISATION]: undefined,
  [CLAIM_ORGANISATION_SUCCESS]: undefined,
  [CLAIM_ORGANISATION_FAILURE]: undefined,

  [JOIN_ORGANISATION_REQUEST]: undefined,
  [JOIN_ORGANISATION_REQUEST_SUCCESS]: undefined,
  [JOIN_ORGANISATION_REQUEST_FAILURE]: undefined,

  [CLAIM_ORGANISATION_REQUEST]: undefined,
  [CLAIM_ORGANISATION_REQUEST_SUCCESS]: undefined,
  [CLAIM_ORGANISATION_REQUEST_FAILURE]: undefined,

  [FETCH_ORGANISATIONS]: undefined,
  [FETCH_SUCCESS]: undefined,
  [FETCH_PUBLIC_ORGS_SUCCESS]: undefined,
  [FETCH_FAILURE]: undefined,

  [FETCH_OWN_ORGANISATIONS]: undefined,
  [FETCH_OWN_ORGANISATIONS_SUCCESS]: undefined,
  [FETCH_OWN_ORGANISATIONS_FAILURE]: undefined,

  [FETCH_ORG_GOALS_FOR_ORG_ADMIN]: undefined,
  [FETCH_ORG_GOALS_FOR_ORG_ADMIN_SUCCESS]: undefined,
  [FETCH_ORG_GOALS_FOR_ORG_ADMIN_FAILURE]: undefined,

  [FETCH_ORG_GOAL]: undefined,
  [FETCH_ORG_GOAL_SUCCESS]: undefined,
  [FETCH_ORG_GOAL_FAILURE]: undefined,

  [CREATE_ORG_GOAL]: undefined,
  [CREATE_ORG_GOAL_SUCCESS]: undefined,
  [CREATE_ORG_GOAL_FAILURE]: undefined,

  [DELETE_ORG_GOAL]: undefined,
  [DELETE_ORG_GOAL_SUCCESS]: undefined,
  [DELETE_ORG_GOAL_FAILURE]: undefined,

  [UPDATE_ORG_GOAL]: undefined,
  [UPDATE_ORG_GOAL_SUCCESS]: undefined,
  [UPDATE_ORG_GOAL_FAILURE]: undefined,

  [FETCH_ORG_GOAL_MILESTONE]: undefined,
  [FETCH_ORG_GOAL_MILESTONE_SUCCESS]: undefined,
  [FETCH_ORG_GOAL_MILESTONE_FAILURE]: undefined,

  [CREATE_ORG_GOAL_MILESTONE]: undefined,
  [CREATE_ORG_GOAL_MILESTONE_SUCCESS]: undefined,
  [CREATE_ORG_GOAL_MILESTONE_FAILURE]: undefined,

  [DELETE_ORG_GOAL_MILESTONE]: undefined,
  [DELETE_ORG_GOAL_MILESTONE_SUCCESS]: undefined,
  [DELETE_ORG_GOAL_MILESTONE_FAILURE]: undefined,

  [UPDATE_ORG_GOAL_MILESTONE]: undefined,
  [UPDATE_ORG_GOAL_MILESTONE_SUCCESS]: undefined,
  [UPDATE_ORG_GOAL_MILESTONE_FAILURE]: undefined,

  [ADD_ORG_ADMIN]: undefined,
  [ADD_ORG_ADMIN_SUCCESS]: undefined,
  [ADD_ORG_ADMIN_FAILURE]: undefined,

  [MANAGE_ORG_MEMBER_REQUEST]: undefined,
  [MANAGE_ORG_MEMBER_REQUEST_SUCCESS]: undefined,
  [MANAGE_ORG_MEMBER_REQUEST_FAILURE]: undefined,

  [MANAGE_ORG_MEMBER_INVITATION]: undefined,
  [MANAGE_ORG_MEMBER_INVITATION_SUCCESS]: undefined,
  [MANAGE_ORG_MEMBER_INVITATION_FAILURE]: undefined,

  [UPDATE_ORG_ADMIN]: undefined,
  [UPDATE_ORG_ADMIN_SUCCESS]: undefined,
  [UPDATE_ORG_ADMIN_FAILURE]: undefined,

  [REMOVE_ORG_ADMIN]: undefined,
  [REMOVE_ORG_ADMIN_SUCCESS]: undefined,
  [REMOVE_ORG_ADMIN_FAILURE]: undefined,

  [REQUEST_VERIFICATION]: undefined,
  [REQUEST_VERIFICATION_SUCCESS]: undefined,
  [REQUEST_VERIFICATION_FAILURE]: undefined,

  [UPDATE_ORG_STORIES]: undefined,
  [FETCH_ORG_FOR_PUBLIC_PAGE]: undefined,
  [FETCH_ORGANISATION]: undefined,
  [SINGLE_FETCH_SUCCESS]: undefined,
  [SINGLE_FETCH_FAILURE]: undefined
})

const initialState = {
  isLoading: false,
  isLoadingViaOrgId: false,
  isSubmitting: false,
  isSuccess: false,
  hasError: false,
  hasSingleOrgError: false,
  errorMessage: null,
  orgGoalCategories: [],
  orgGoalMilestones: [],
  orgGoals: [],
  ownOrgsData: [],
  ownOrgsPageCount: 1,
  data: [],
  pageCount: 1,
  orgTypes: [],
  customFields: [],
  singleOrganisationData: {},
  singleOrgGoal: {},
  singleOrgGoalMilestone: {},
  organisationId: null,
  errorType: null
}

export default handleActions(
  {
    [CREATE_ORGANISATION]: (state, { payload }) => ({
      ...state,
      ...payload,
      isLoading: false,
      isSubmitting: true
    }),
    [CREATE_SUCCESS]: (state, { payload = {} }) => {
      if (!payload.isPartialUpdate) {
        UrlBuilder.displayUpdateSuccessMessage()
      }

      return {
        ...state,
        singleOrganisationData: payload,
        isLoading: false,
        isSubmitting: false,
        data: [payload, ...state.data.filter(o => o.id !== payload.id)]
      }
    },
    [CREATE_FAILURE]: (state, { payload }) => ({
      ...state,
      hasError: true,
      errorMessage: payload,
      isLoading: false,
      isSubmitting: false
    }),
    [UPDATE_ORGANISATION]: state => ({ ...state, isLoading: false, isSubmitting: true }),
    [UPDATE_BILLING_INFO]: state => ({ ...state, isLoading: false, isSubmitting: true }),
    [UPDATE_SUCCESS]: (state, { payload }) => {
      const updatedData = [payload, ...state.data.filter(o => o.id !== payload.id)]

      if (!payload.isPartialUpdate) {
        UrlBuilder.displayUpdateSuccessMessage()
      }

      return {
        ...state,
        isLoading: false,
        isSubmitting: false,
        singleOrganisationData: payload,
        data: updatedData
      }
    },
    [UPDATE_FAILURE]: state => ({ ...state, isLoading: false, isSubmitting: false }),
    [DELETE_ORGANISATION]: (state, { payload }) => ({
      ...state,
      isLoading: true,
      missionId: payload
    }),
    [DELETE_SUCCESS]: (state, { payload }) => {
      return {
        ...state,
        isLoading: false,
        isSubmitting: false,
        data: state.data.filter(o => o.id !== payload.organisationId),
        missionId: null
      }
    },
    [DELETE_FAILURE]: state => ({ ...state, isLoading: false, missionId: null }),
    [JOIN_ORGANISATION_REQUEST]: (state, { payload }) => ({
      ...state,
      isLoading: true,
      organistionId: payload,
      submissionWasSuccess: null
    }),
    [JOIN_ORGANISATION_REQUEST_SUCCESS]: state => {
      return {
        ...state,
        isLoading: false,
        submissionWasSuccess: true
      }
    },
    [JOIN_ORGANISATION_REQUEST_FAILURE]: state => ({
      ...state,
      isLoading: false,
      submissionWasSuccess: false,
      hasError: true
    }),
    [CLAIM_ORGANISATION]: (state, { payload }) => ({
      ...state,
      isLoading: true,
      organistionId: payload,
      submissionWasSuccess: null
    }),
    [CLAIM_ORGANISATION_SUCCESS]: state => {
      return {
        ...state,
        isLoading: false,
        submissionWasSuccess: true
      }
    },
    [CLAIM_ORGANISATION_FAILURE]: state => ({
      ...state,
      isLoading: false,
      submissionWasSuccess: false,
      hasError: true
    }),

    [FETCH_ORG_GOALS_FOR_ORG_ADMIN]: state => ({
      ...state,
      errorMessage: false,
      errorType: null,
      hasError: false,
      isLoading: true,
      isSubmitting: false,
      isSuccess: null
    }),
    [FETCH_ORG_GOALS_FOR_ORG_ADMIN_SUCCESS]: (state, { payload }) => {
      const { orgGoalCategories, orgGoalMilestones, orgGoals } = payload

      return {
        ...state,
        orgGoalMilestones,
        orgGoals,
        orgGoalCategories,
        isLoading: false,
        hasError: false,
        isSuccess: true
      }
    },
    [FETCH_ORG_GOALS_FOR_ORG_ADMIN_FAILURE]: (state, payload) => ({
      ...state,
      ...payload,
      isLoading: false,
      isSuccess: false
    }),
    [FETCH_ORG_GOAL]: state => ({
      ...state,
      errorMessage: false,
      errorType: null,
      hasError: false,
      isLoading: true,
      isSubmitting: false,
      isSuccess: null
    }),
    [FETCH_ORG_GOAL_SUCCESS]: (state, { payload }) => {
      const { orgGoalCategories, orgGoal } = payload

      return {
        ...state,
        orgGoalCategories,
        singleOrgGoal: orgGoal,
        isLoading: false,
        hasError: false,
        isSuccess: true
      }
    },
    [FETCH_ORG_GOAL_FAILURE]: (state, payload) => ({
      ...state,
      ...payload,
      isLoading: false,
      isSuccess: false
    }),

    [CREATE_ORG_GOAL]: state => ({
      ...state,
      isSubmitting: true,
      submissionWasSuccess: null
    }),
    [CREATE_ORG_GOAL_SUCCESS]: state => {
      return {
        ...state,
        isSubmitting: false,
        submissionWasSuccess: true
      }
    },
    [CREATE_ORG_GOAL_FAILURE]: state => ({
      ...state,
      isSubmitting: false,
      submissionWasSuccess: false,
      hasError: true
    }),
    [DELETE_ORG_GOAL]: state => ({
      ...state,
      isSubmitting: true,
      submissionWasSuccess: null
    }),
    [DELETE_ORG_GOAL_SUCCESS]: state => {
      return {
        ...state,
        isSubmitting: false,
        submissionWasSuccess: true
      }
    },
    [DELETE_ORG_GOAL_FAILURE]: state => ({
      ...state,
      isSubmitting: false,
      submissionWasSuccess: false,
      hasError: true
    }),
    [UPDATE_ORG_GOAL]: state => ({
      ...state,
      isSubmitting: true,
      submissionWasSuccess: null
    }),
    [UPDATE_ORG_GOAL_SUCCESS]: state => {
      return {
        ...state,
        isSubmitting: false,
        submissionWasSuccess: true
      }
    },
    [UPDATE_ORG_GOAL_FAILURE]: state => ({
      ...state,
      isSubmitting: false,
      submissionWasSuccess: false,
      hasError: true
    }),
    [FETCH_ORG_GOAL_MILESTONE]: state => ({
      ...state,
      errorMessage: false,
      errorType: null,
      hasError: false,
      isLoading: true,
      isSubmitting: false,
      isSuccess: null
    }),
    [FETCH_ORG_GOAL_MILESTONE_SUCCESS]: (state, { payload }) => {
      return {
        ...state,
        singleOrgGoalMilestone: payload,
        isLoading: false,
        hasError: false,
        isSuccess: true
      }
    },
    [FETCH_ORG_GOAL_MILESTONE_FAILURE]: (state, payload) => ({
      ...state,
      ...payload,
      isLoading: false,
      isSuccess: false
    }),

    [CREATE_ORG_GOAL_MILESTONE]: state => ({
      ...state,
      isSubmitting: true,
      submissionWasSuccess: null
    }),
    [CREATE_ORG_GOAL_MILESTONE_SUCCESS]: state => {
      return {
        ...state,
        isSubmitting: false,
        submissionWasSuccess: true
      }
    },
    [CREATE_ORG_GOAL_MILESTONE_FAILURE]: state => ({
      ...state,
      isSubmitting: false,
      submissionWasSuccess: false,
      hasError: true
    }),
    [DELETE_ORG_GOAL_MILESTONE]: state => ({
      ...state,
      isSubmitting: true,
      submissionWasSuccess: null
    }),
    [DELETE_ORG_GOAL_MILESTONE_SUCCESS]: state => {
      return {
        ...state,
        isSubmitting: false,
        submissionWasSuccess: true
      }
    },
    [DELETE_ORG_GOAL_MILESTONE_FAILURE]: state => ({
      ...state,
      isSubmitting: false,
      submissionWasSuccess: false,
      hasError: true
    }),
    [UPDATE_ORG_GOAL_MILESTONE]: state => ({
      ...state,
      isSubmitting: true,
      submissionWasSuccess: null
    }),
    [UPDATE_ORG_GOAL_MILESTONE_SUCCESS]: state => {
      return {
        ...state,
        isSubmitting: false,
        submissionWasSuccess: true
      }
    },
    [UPDATE_ORG_GOAL_MILESTONE_FAILURE]: state => ({
      ...state,
      isSubmitting: false,
      submissionWasSuccess: false,
      hasError: true
    }),
    [CLAIM_ORGANISATION_REQUEST]: (state, { payload }) => ({
      ...state,
      isLoading: true,
      organistionId: payload,
      submissionWasSuccess: null
    }),
    [CLAIM_ORGANISATION_REQUEST_SUCCESS]: state => {
      return {
        ...state,
        isLoading: false,
        submissionWasSuccess: true
      }
    },
    [CLAIM_ORGANISATION_REQUEST_FAILURE]: state => ({
      ...state,
      isLoading: false,
      submissionWasSuccess: false,
      hasError: true
    }),

    [FETCH_ORGANISATIONS]: state => ({
      ...state,
      errorMessage: false,
      errorType: null,
      hasError: false,
      hasSingleOrgError: false,
      isLoading: true,
      isSubmitting: false,
      isSuccess: null
    }),
    [FETCH_OWN_ORGANISATIONS]: state => ({
      ...state,
      errorMessage: false,
      errorType: null,
      hasError: false,
      hasSingleOrgError: false,
      isLoading: true,
      isSubmitting: false,
      isSuccess: null
    }),
    [FETCH_OWN_ORGANISATIONS_SUCCESS]: (state, { payload }) => {
      const { orgData, orgTypes, customFields, pageCount } = payload

      return {
        ...state,
        ownOrgsData: orgData,
        ownOrgsPageCount: pageCount,
        orgTypes,
        customFields,
        isLoading: false,
        hasError: false,
        isSuccess: true
      }
    },
    [FETCH_OWN_ORGANISATIONS_FAILURE]: (state, payload) => ({
      ...state,
      ...payload,
      isLoading: false,
      isSuccess: false
    }),

    [FETCH_PUBLIC_ORGS_SUCCESS]: (state, { payload }) => {
      const { orgData, orgTypes } = payload

      return {
        ...state,
        data: orgData,
        orgTypes,
        isLoading: false,
        hasError: false
      }
    },
    [FETCH_SUCCESS]: (state, { payload }) => {
      const { orgData, orgTypes, customFields, pageCount } = payload

      return {
        ...state,
        data: orgData,
        pageCount,
        orgTypes,
        customFields,
        isLoading: false,
        hasError: false,
        isSuccess: true
      }
    },
    [FETCH_FAILURE]: (state, payload) => ({
      ...state,
      ...payload,
      isLoading: false,
      isSuccess: false
    }),
    [FETCH_ORG_FOR_PUBLIC_PAGE]: state => ({
      ...state,
      hasError: false,
      isLoading: true,
      isLoadingViaOrgId: true
    }),
    [FETCH_ORGANISATION]: state => {
      return { ...state, isLoading: true, hasSingleOrgError: false, hasError: false }
    },
    [SINGLE_FETCH_SUCCESS]: (state, { payload }) => ({
      ...state,
      isLoading: false,
      isLoadingViaOrgId: false,
      hasSubmitted: false,
      singleOrganisationData: payload,
      organisationId: payload.id,
      hasSingleOrgError: false,
      isSuccess: true,
      hasError: false
    }),
    [SINGLE_FETCH_FAILURE]: (state, { payload }) => ({
      ...state,
      ...payload,
      isLoading: false,
      isLoadingViaOrgId: false,
      hasSingleOrgError: true,
      isSuccess: false,
      hasError: true
    }),

    [UPDATE_ORG_STORIES]: (state, { payload }) => {
      const { singleOrganisationData } = state
      const stories = singleOrganisationData.stories || []
      if (!stories.length) return state

      const newOrgData = {
        ...singleOrganisationData,
        stories: stories.map(s => {
          if (s.id === payload.id) {
            const oneMore = (s.story_likes || 0) + 1
            const oneLess = (s.story_likes || 1) - 1
            const userLikesStory = payload.value === 'liked'
            const newLikesCount = userLikesStory ? oneMore : oneLess

            return { ...s, story_likes: newLikesCount, user_likes_story: userLikesStory }
          }

          return s
        })
      }

      return { ...state, singleOrganisationData: newOrgData }
    },

    [ADD_ORG_ADMIN]: state => ({ ...state, isLoading: true, hasSubmitted: true }),
    [ADD_ORG_ADMIN_SUCCESS]: (state, { payload }) => {
      const singleOrganisationData = state.singleOrganisationData
        ? { ...state.singleOrganisationData }
        : {}

      const orgMembers = singleOrganisationData.org_members || []

      const addedExistingMember = orgMembers.find(m => m.email === payload.member_email)
      const newOrgMembers = addedExistingMember
        ? [
            ...orgMembers.filter(m => m.email !== payload.member_email),
            {
              avatar_url: payload.avatar_url,
              email: payload.member_email,
              membership_status: addedExistingMember.membership_status,
              f_name: payload.member_f_name,
              id: payload.id,
              l_name: payload.member_l_name,
              mobile: payload.member_mobile,
              role: payload.member_role,
              status: payload.status
            }
          ]
        : [
            ...orgMembers,
            {
              avatar_url: payload.avatar_url,
              email: payload.member_email,
              membership_status: 'Invited',
              f_name: payload.member_f_name,
              id: payload.id,
              l_name: payload.member_l_name,
              mobile: payload.member_mobile,
              role: payload.member_role,
              status: payload.status
            }
          ]

      const updatedSingleOrganisationData = {
        ...singleOrganisationData,
        org_members: newOrgMembers
      }

      return {
        ...state,
        isLoading: false,
        hasSubmitted: true,
        hasError: false,
        singleOrganisationData: updatedSingleOrganisationData
      }
    },
    [ADD_ORG_ADMIN_FAILURE]: state => ({
      ...state,
      isLoading: false,
      hasSubmitted: true,
      hasError: true
    }),

    [MANAGE_ORG_MEMBER_REQUEST]: state => ({ ...state, isLoading: true, hasSubmitted: true }),
    [MANAGE_ORG_MEMBER_REQUEST_SUCCESS]: (state, { payload }) => {
      const singleOrganisationData = state.singleOrganisationData
        ? { ...state.singleOrganisationData }
        : {}

      const orgMembers = singleOrganisationData.org_members || []
      const otherMembers = orgMembers.filter(m => m.id !== payload.memberId)
      const updatedMember = orgMembers.find(m => m.id === payload.memberId)
      const updatedSingleOrganisationData = {
        ...singleOrganisationData,
        org_members: [
          ...otherMembers,
          {
            ...updatedMember,
            membership_status:
              payload.request_status === 'RequestAccepted' ? 'Approved' : 'Rejected',
            role: payload.request_status === 'RequestAccepted' ? 'Member' : null,
            status: payload.request_status === 'RequestAccepted' ? 'Approved' : 'Rejected'
          }
        ]
      }

      return {
        ...state,
        isLoading: false,
        hasSubmitted: true,
        hasError: false,
        singleOrganisationData: updatedSingleOrganisationData
      }
    },
    [MANAGE_ORG_MEMBER_REQUEST_FAILURE]: state => ({
      ...state,
      isLoading: false,
      hasSubmitted: true,
      hasError: true
    }),
    [MANAGE_ORG_MEMBER_INVITATION]: state => ({ ...state, isLoading: true, hasSubmitted: true }),
    [MANAGE_ORG_MEMBER_INVITATION_SUCCESS]: (state, { payload }) => {
      const singleOrganisationData = state.singleOrganisationData
        ? { ...state.singleOrganisationData }
        : {}

      const orgMembers = singleOrganisationData.org_members || []
      const otherMembers = orgMembers.filter(m => m.id !== payload.memberId)
      const updatedMember = orgMembers.find(m => m.id === payload.memberId)
      const updatedSingleOrganisationData = {
        ...singleOrganisationData,
        org_members: [
          ...otherMembers,
          {
            ...updatedMember,
            membership_status:
              payload.request_status === 'RequestAccepted' ? 'Approved' : 'Rejected',
            role: payload.request_status === 'RequestAccepted' ? 'Member' : null,
            status: payload.request_status === 'RequestAccepted' ? 'Approved' : 'Rejected'
          }
        ]
      }

      return {
        ...state,
        isLoading: false,
        hasSubmitted: true,
        hasError: false,
        singleOrganisationData: updatedSingleOrganisationData
      }
    },
    [MANAGE_ORG_MEMBER_INVITATION_FAILURE]: state => ({
      ...state,
      isLoading: false,
      hasSubmitted: true,
      hasError: true
    }),

    [UPDATE_ORG_ADMIN]: state => ({ ...state, isLoading: true, hasSubmitted: true }),
    [UPDATE_ORG_ADMIN_SUCCESS]: (state, { payload }) => {
      const deletedAdminId = parseInt(payload.adminId)
      const updatedAdmins = state.singleOrganisationData.admins.filter(a => a.id !== deletedAdminId)
      const updatedSingleOrganisationData = {
        ...state.singleOrganisationData,
        admins: updatedAdmins
      }

      return {
        ...state,
        isLoading: false,
        hasSubmitted: true,
        hasError: false,
        singleOrganisationData: updatedSingleOrganisationData
      }
    },
    [UPDATE_ORG_ADMIN_FAILURE]: state => ({
      ...state,
      isLoading: false,
      hasSubmitted: true,
      hasError: true
    }),

    [REQUEST_VERIFICATION]: state => ({ ...state, isSubmitting: true }),
    [REQUEST_VERIFICATION_SUCCESS]: state => {
      UrlBuilder.displayUpdateSuccessMessage()

      return {
        ...state,
        isLoading: false,
        isSubmitting: false,
        hasError: false
      }
    },
    [REQUEST_VERIFICATION_FAILURE]: state => ({
      ...state,
      isLoading: false,
      isSubmitting: false,
      hasError: true
    }),

    [REMOVE_ORG_ADMIN]: state => ({ ...state, isLoading: true, hasSubmitted: true }),
    [REMOVE_ORG_ADMIN_SUCCESS]: (state, { payload }) => {
      const removedAdmin = state.singleOrganisationData.org_members.find(
        a => a.id === parseInt(payload.recordId)
      )
      const updatedRemovedAdmin = {
        ...removedAdmin,
        membership_status: 'Removed',
        role: null,
        status: 'Removed'
      }
      const otherMembers = state.singleOrganisationData.org_members.filter(
        a => a.id !== parseInt(payload.recordId)
      )
      const updatedAdmins = payload.memberToInviteRescindId
        ? [updatedRemovedAdmin, ...otherMembers]
        : payload.memberToRequestRemoveId
        ? [updatedRemovedAdmin, ...otherMembers]
        : otherMembers

      const updatedSingleOrganisationData = {
        ...state.singleOrganisationData,
        org_members: updatedAdmins
      }

      return {
        ...state,
        isLoading: false,
        hasSubmitted: true,
        hasError: false,
        singleOrganisationData: updatedSingleOrganisationData
      }
    },
    [REMOVE_ORG_ADMIN_FAILURE]: state => ({
      ...state,
      isLoading: false,
      hasSubmitted: true,
      hasError: true
    })
  },
  initialState
)
