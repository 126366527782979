import axios from 'axios'

// config
import baseUrl from '../config'

export const completeDonationSuccess = async data => {
  const response = await axios({
    withCredentials: true,
    method: 'post',
    url: `${baseUrl}/complete-ozow-donation/success`,
    data
  })

  return response.data
}

export const completeDonationFail = async data => {
  const response = await axios({
    withCredentials: true,
    method: 'post',
    url: `${baseUrl}/complete-ozow-donation/fail`,
    data
  })

  return response.data
}
