import { createActions, handleActions } from 'redux-actions'

export const LOGOUT_SUCCESS = 'authentication/LOGOUT_SUCCESS'
export const REQUEST_COMMUNITY_BOOST = 'boosts/REQUEST_COMMUNITY_BOOST'
export const REQUEST_COMMUNITY_BOOST_FAILURE = 'boosts/REQUEST_COMMUNITY_BOOST_FAILURE'
export const REQUEST_COMMUNITY_BOOST_SUCCESS = 'boosts/REQUEST_COMMUNITY_BOOST_SUCCESS'

export const {
  boosts: { requestCommunityBoost, requestCommunityBoostSuccess, requestCommunityBoostFailure }
} = createActions({
  [REQUEST_COMMUNITY_BOOST]: undefined,
  [REQUEST_COMMUNITY_BOOST_FAILURE]: undefined,
  [REQUEST_COMMUNITY_BOOST_SUCCESS]: undefined
})

const initialState = {
  errorMessage: null,
  isError: false,
  isLoading: false,
  isSubmitting: false,
  isSuccess: false
}

export default handleActions(
  {
    [LOGOUT_SUCCESS]: () => initialState,
    [REQUEST_COMMUNITY_BOOST]: () => ({
      errorMessage: null,
      isLoading: false,
      isError: false,
      isSuccess: false,
      isSubmitting: true
    }),
    [REQUEST_COMMUNITY_BOOST_FAILURE]: () => ({
      errorMessage: null,
      isSubmitting: false,
      isLoading: false,
      isSuccess: false,
      isError: true
    }),
    [REQUEST_COMMUNITY_BOOST_SUCCESS]: (state, { payload }) => ({
      ...state,
      errorMessage: payload.data.result,
      payload: payload,
      isLoading: false,
      isSubmitting: false,
      isSuccess: payload.data.success,
      isError: !payload.data.success
    })
  },
  initialState
)
