import React, { Component, Fragment } from 'react'
import { Formik } from 'formik'

// styles
import {
  CenteredModalHeader,
  CloseIcon,
  FormSectionRow,
  ModalContainer,
  ModalHeader,
  ScreenWrapper
} from './styles'

// config
import l from 'config/localization'

// Load components synchronously
import ConditionalDisplay from 'components/ConditionalDisplay/ConditionalDisplay'
import FormProgressBars from 'components/FormProgressBars/FormProgressBars'
import SmallButtonPrimary from 'components/SmallButtonPrimary/SmallButtonPrimary'

// Modal partials
import ChallengeCheckinFrequencySection from 'components/Modals/_challengeCheckinFrequencySection'
import StoryVisibilitySelector from 'components/Modals/_storyVisibilitySelector'

const validate = () => {
  const errors = {}

  return errors
}

class ChallengeDetailsModal extends Component {
  constructor(props) {
    super(props)

    this.setOtherAmount = this.setOtherAmount.bind(this)
  }

  setOtherAmount(value) {
    this.setState({ showCustomAmountField: value })
  }

  render() {
    const { extraInfoModalOpts = {}, hideModal } = this.props
    const { formValues, isSubmitting, opportunity = {}, userData = {} } = extraInfoModalOpts

    const hasSelectedFrequency = !!formValues.challenge_checkin_frequency

    if (isSubmitting) {
      return (
        <ScreenWrapper>
          <ModalContainer>
            <CloseIcon dark size={30} onClick={hideModal} />

            <ModalHeader>Hang on a second</ModalHeader>
            <p>
              We're submitting your form. Hopefully this doesn't take longer than a few seconds...
            </p>
          </ModalContainer>
        </ScreenWrapper>
      )
    }

    if (!hasSelectedFrequency) {
      return (
        <ChallengeCheckinFrequencySection
          extraInfoModalOpts={extraInfoModalOpts}
          hideModal={hideModal}
        />
      )
    }

    return (
      <ScreenWrapper>
        <ModalContainer>
          <CloseIcon dark size={30} onClick={hideModal} />

          <CenteredModalHeader>
            Thank you {userData.f_name}, you're almost there
          </CenteredModalHeader>

          <FormProgressBars active={2} steps={2} />

          <Formik
            initialValues={formValues}
            enableReinitialize
            validate={validate}
            validateOnChange={false}
            validateOnBlur={false}
            onSubmit={values => extraInfoModalOpts.signUp(values)}
            render={({ handleSubmit, setFieldValue }) => {
              return (
                <form onSubmit={handleSubmit}>
                  <Fragment>
                    <StoryVisibilitySelector
                      actionType={'challenge'}
                      formValues={formValues}
                      setState={v => this.setState(v)}
                      setFieldValue={setFieldValue}
                      subLabel={
                        'Challenges joined via Brownie Points are shared in various places on the platform. If you prefer to track your challenges anonymously, you can choose to make them private. Private stories are also hidden from others on your user profile.'
                      }
                    />
                  </Fragment>

                  <ConditionalDisplay displayWhen={[opportunity.has_applicant_vetting]}>
                    <p>
                      The host of this {l('OPP')} has chosen to review and approve {l('SUPPORTERS')}{' '}
                      who sign up before they can join this {l('OPP')}. If your application is
                      accepted, you will receive an email confirmation. Thanks for your
                      understanding.
                    </p>
                  </ConditionalDisplay>

                  <FormSectionRow>
                    <SmallButtonPrimary type="submit">Join</SmallButtonPrimary>
                  </FormSectionRow>
                </form>
              )
            }}
          />
        </ModalContainer>
      </ScreenWrapper>
    )
  }
}

export default ChallengeDetailsModal
