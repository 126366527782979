import { createActions, handleActions } from 'redux-actions'

export const HOST_FEEDBACK = 'feedback/HOST_FEEDBACK'
export const HOST_FEEDBACK_SUCCESS = 'feedback/HOST_FEEDBACK_SUCCESS'
export const HOST_FEEDBACK_FAILURE = 'feedback/HOST_FEEDBACK_FAILURE'

export const FETCH_APPLICATION_INFO = 'feedback/FETCH_APPLICATION_INFO'
export const FETCH_APPLICATION_INFO_SUCCESS = 'feedback/FETCH_APPLICATION_INFO_SUCCESS'
export const FETCH_APPLICATION_INFO_FAILURE = 'feedback/FETCH_APPLICATION_INFO_FAILURE'

export const FETCH_HOST_CHECK_IN_INFO = 'feedback/FETCH_HOST_CHECK_IN_INFO'
export const FETCH_HOST_CHECK_IN_INFO_SUCCESS = 'feedback/FETCH_HOST_CHECK_IN_INFO_SUCCESS'
export const FETCH_HOST_CHECK_IN_INFO_FAILURE = 'feedback/FETCH_HOST_CHECK_IN_INFO_FAILURE'

export const VOLUNTEER_FEEDBACK = 'feedback/VOLUNTEER_FEEDBACK'
export const VOLUNTEER_FEEDBACK_SUCCESS = 'feedback/VOLUNTEER_FEEDBACK_SUCCESS'
export const VOLUNTEER_FEEDBACK_FAILURE = 'feedback/VOLUNTEER_FEEDBACK_FAILURE'

export const LOGOUT_SUCCESS = 'authentication/LOGOUT_SUCCESS'

export const {
  feedback: {
    hostFeedback,
    hostFeedbackSuccess,
    hostFeedbackFailure,
    fetchApplicationInfo,
    fetchApplicationInfoSuccess,
    fetchApplicationInfoFailure,
    fetchHostCheckInInfo,
    fetchHostCheckInInfoSuccess,
    fetchHostCheckInInfoFailure,
    volunteerFeedback,
    volunteerFeedbackSuccess,
    volunteerFeedbackFailure
  }
} = createActions({
  [HOST_FEEDBACK]: undefined,
  [HOST_FEEDBACK_SUCCESS]: undefined,
  [HOST_FEEDBACK_FAILURE]: undefined,
  [FETCH_APPLICATION_INFO]: undefined,
  [FETCH_APPLICATION_INFO_SUCCESS]: undefined,
  [FETCH_APPLICATION_INFO_FAILURE]: undefined,
  [FETCH_HOST_CHECK_IN_INFO]: undefined,
  [FETCH_HOST_CHECK_IN_INFO_SUCCESS]: undefined,
  [FETCH_HOST_CHECK_IN_INFO_FAILURE]: undefined,
  [VOLUNTEER_FEEDBACK]: feedback => ({ feedback }),
  [VOLUNTEER_FEEDBACK_SUCCESS]: undefined,
  [VOLUNTEER_FEEDBACK_FAILURE]: undefined
})

const initialState = {
  isLoading: false,
  feedback: null,
  isError: false,
  isSuccess: null,
  isSubmitting: null,
  opportunity: {}
}

export default handleActions(
  {
    [HOST_FEEDBACK]: (state, { payload }) => ({
      ...state,
      feedback: payload.feedback,
      isLoading: true,
      errorMessage: null
    }),
    [HOST_FEEDBACK_SUCCESS]: state => ({
      ...state,
      isLoading: false,
      isError: false,
      isSuccess: true,
      errorMessage: null
    }),
    [HOST_FEEDBACK_FAILURE]: state => ({
      ...state,
      isLoading: false,
      feedback: null,
      isError: true,
      isSuccess: false,
      errorMessage:
        'Something went wrong with your feedback submission. Please try reloading the page.'
    }),
    [FETCH_APPLICATION_INFO]: state => ({
      ...state,
      isLoading: true,
      errorMessage: null
    }),
    [FETCH_APPLICATION_INFO_SUCCESS]: (state, { payload }) => ({
      ...state,
      isLoading: false,
      isError: false,
      isSuccess: null,
      opportunity: payload,
      errorMessage: null
    }),
    [FETCH_APPLICATION_INFO_FAILURE]: () => ({
      isLoading: false,
      isError: true,
      errorMessage: 'Whoops, this seems to be a bad URL.'
    }),
    [FETCH_HOST_CHECK_IN_INFO]: state => ({
      ...state,
      isLoading: true,
      errorMessage: null
    }),
    [FETCH_HOST_CHECK_IN_INFO_SUCCESS]: (state, { payload }) => ({
      ...state,
      isLoading: false,
      isError: false,
      opportunity: payload,
      errorMessage: null
    }),
    [FETCH_HOST_CHECK_IN_INFO_FAILURE]: (state, { payload }) => ({
      ...state,
      isLoading: false,
      isError: true,
      errorMessage: payload.errorMessage
    }),
    [VOLUNTEER_FEEDBACK]: (state, { payload }) => ({
      ...state,
      feedback: payload.feedback,
      isLoading: true,
      isSubmitting: true,
      errorMessage: null
    }),
    [VOLUNTEER_FEEDBACK_SUCCESS]: state => ({
      ...state,
      errorMessage: null,
      feedback: null,
      isError: false,
      isLoading: false,
      isSubmitting: false,
      isSuccess: true,
      opportunity: { ...state.opportunity, alreadyProvidedFeedback: true }
    }),
    [VOLUNTEER_FEEDBACK_FAILURE]: state => ({
      ...state,
      isLoading: false,
      isSubmitting: false,
      feedback: null,
      isError: true,
      isSuccess: false,
      errorMessage:
        'Something went wrong with your feedback submission. Please try reloading the page.'
    }),

    [LOGOUT_SUCCESS]: () => initialState
  },
  initialState
)
