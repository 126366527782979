import React from 'react'
import { Helmet } from 'react-helmet'

const PayfastPaymentScript = ({ includePayfastScript }) => {
  if (!includePayfastScript) return null

  return (
    <Helmet>
      <script src="https://www.payfast.co.za/onsite/engine.js"></script>
    </Helmet>
  )
}

export default PayfastPaymentScript
