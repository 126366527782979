import React, { Component } from 'react'
import styled from 'styled-components'
import { Field } from 'formik'

// assets
import { ReactComponent as DonationIcon } from 'assets/images/cause-type-icons/money_icon.svg'
import { ReactComponent as GoodsIcon } from 'assets/images/cause-type-icons/goods_icon.svg'

// styles
import { Error, Label, SubLabel, Success } from '../InputLabels/InputLabels'

// utils
import th from 'utils/themeHelper'

// config
import constants from 'config/constants'

// Load components synchronously
import ConditionalDisplay from 'components/ConditionalDisplay/ConditionalDisplay'
import NumberInput from 'components/NumberInput/NumberInput'

const borderColor = ({ active, hasError, theme }) => {
  if (active) {
    return th('primary.base')
  }
  if (hasError) {
    return theme.borders.error
  } else {
    return theme.borders.medium
  }
}

const borderWidth = ({ active, hasError }) => {
  if (active) {
    return '1px'
  } else if (hasError) {
    return '2px'
  } else {
    return '1px'
  }
}

const Wrapper = styled.div`
  position: relative;
`

const SmallImage = styled.div`
  background-color: white;
  background-image: url(${props => props.src});
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 50%;
  border-style: solid;
  border-width: 1px;
  height: 30px;
  width: 30px;
`

const ShortSmallImage = styled.div`
  background-color: white;
  background-image: url(${props => props.src});
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  border-color: ${props => (props.active ? 'white' : th('text.light'))};
  border-radius: 50%;
  border-style: solid;
  border-width: 1px;
  height: 30px;
  width: 30px;
`

const ShortIconWrapper = styled.div`
  background-color: white;
  border-radius: 50%;
  border-style: solid;
  border-width: 1px;
  border-color: ${props => (props.active ? 'white' : th('text.light'))};
  height: 30px;
  padding: ${props => props.padding || '0'};
  width: 30px;

  > svg {
    fill: ${props => props.fill || th('text.dark')};
    height: 100%;
    width: 100%;
  }
`

const ShortSwipeOptionImage = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  padding: 5px 0 5px 8px;
`

const ShortSwipeOptionsWrapper = styled.div`
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  padding: 8px;
`

const ShortSwipeOptionLabel = styled.div`
  color: ${th('text.mid')};
  font-size: 12px;
  padding: 0 0 ${props => (props.hasSubLabel ? '3px' : '0')};

  @media screen and (min-width: 769px) {
    font-size: 15px;
  }
`

const ShortSwipeOptionSubLabel = styled.div`
  color: ${th('text.mid')};
  font-family: ${th('fonts.light')};
  font-size: 12px;
  padding: 0;
`

const ShortSwipeOptionDetails = styled.div`
  border-radius: 3px;
  display: flex;
  flex-direction: row;
  height: 100%;
  width: 100%;
`

const SelectComponentContainer = styled.div`
  display: flex;
  flex-direction: ${props => (props.isGoodEconomyCause ? 'column' : 'row')};
  flex-wrap: wrap;
  justify-content: ${props => (props.isMoneyType ? 'space-evenly' : 'flex-start')};
  margin: 0 0 -10px;
  overflow-x: auto;
  position: relative;
  width: 100%;

  scrollbar-width: none;
  -ms-overflow-style: none;

  &::-webkit-scrollbar {
    display: none; /* Safari and Chrome */
  }
`

const ShortSelectComponentOption = styled.div`
  background: ${props => (props.active ? '#2A90443C' : 'white')};
  border-radius: 5px;
  border-style: solid;
  border-width: ${borderWidth};
  border-color: ${borderColor};
  color: ${th('text.dark')};
  display: flex;
  flex-basis: calc(50% - 10px);
  font-family: ${th('fonts.bold')};
  line-height: 1;
  margin: 0 10px 10px 0;
  padding: 0;

  &:hover {
    background: ${props => (props.active ? '#2A90443C' : th('backgrounds.light'))};
    cursor: pointer;
  }

  &:hover ${SmallImage} {
    border-color: ${props => (props.active ? 'white' : th('text.dark'))};
  }

  &:hover ${ShortSmallImage} {
    border-color: ${props => (props.active ? 'white' : th('text.dark'))};
  }

  @media screen and (min-width: 969px) {
    flex-basis: calc(33% - 10px);
  }

  @media screen and (min-width: 1200px) {
    padding: 5px 10px;
  }
`

const buildCommitmentOpts = ({ goalType, isMoneyType, isGoodEconomyCause, opportunity }) => {
  if (isMoneyType && isGoodEconomyCause) {
    const impactUnits = opportunity.impactUnits || []

    return impactUnits.map(unit => {
      const label = unit.label
      const subLabel = `R${unit.cost_per_unit} per work day, excluding fees`

      return {
        id: unit.id,
        label: label,
        subLabel: subLabel,
        value: unit.cost_per_unit,
        iconPadding: '5px',
        Icon: DonationIcon
      }
    })
  }

  const deliverable = constants.OPPORTUNITY_GOAL_TYPE_DELIVERABLES[goalType]
  const singleDeliverable = deliverable.replace(/s$/, '')

  if (isMoneyType) {
    return [
      { label: 'R50', value: 50, iconPadding: '5px', Icon: DonationIcon },
      { label: 'R100', value: 100, iconPadding: '5px', Icon: DonationIcon },
      { label: 'R250', value: 250, iconPadding: '5px', Icon: DonationIcon },
      { label: 'R500', value: 500, iconPadding: '5px', Icon: DonationIcon },
      { label: 'R1,000', value: 1000, iconPadding: '5px', Icon: DonationIcon },
      { label: 'Other', value: 'other', iconPadding: '5px', Icon: DonationIcon }
    ]
  }

  return [
    {
      label: `1 ${singleDeliverable}`,
      value: 1,
      iconPadding: '5px',
      Icon: GoodsIcon
    },
    {
      label: `2 ${deliverable}`,
      value: 2,
      iconPadding: '5px',
      Icon: GoodsIcon
    },
    {
      label: `5 ${deliverable}`,
      value: 5,
      iconPadding: '5px',
      Icon: GoodsIcon
    },
    {
      label: `10 ${deliverable}`,
      value: 10,
      iconPadding: '5px',
      Icon: GoodsIcon
    },
    { label: 'Other', value: 'other', iconPadding: '5px', Icon: GoodsIcon }
  ]
}

const valueFromId = (opts, id) => {
  const opt = opts.find(o => o.value === id)

  return opt || ''
}

const isActive = ({ field, isGoodEconomyCause, opt, valueFromProps }) => {
  // impact units can be multiple so don't provide any single active option
  if (isGoodEconomyCause) return false

  const value = field.value
  const deepValue = value ? value.value : null
  const selectedValue = deepValue || value || valueFromProps

  return selectedValue === opt.value
}

const ImageForShortSwipeOption = ({ active, fill, iconPadding, Icon }) => {
  if (!Icon) return null

  return (
    <ShortSwipeOptionImage active={active}>
      <ShortIconWrapper active={active} fill={fill} padding={iconPadding}>
        <Icon />
      </ShortIconWrapper>
    </ShortSwipeOptionImage>
  )
}

const OptionValueSelector = ({ form, isGoodEconomyCause, opt, values }) => {
  if (!isGoodEconomyCause) return null
  if (!form) return null

  return (
    <Field
      changeValue={({ value }) => {
        const impactUnitsCovered = values[`impact_units_covered_${opt.id}`] || 0
        if (!impactUnitsCovered && value === -1) return null

        form.setFieldValue(`impact_units_covered_${opt.id}`, impactUnitsCovered + value)
      }}
      component={NumberInput}
      name={`impact_units_covered_${opt.id}`}
      max="1000"
      min={0}
      placeholder={`0`}
      type="number"
      withButtons
    />
  )
}

const deriveCommitmentSubLabel = ({ goalType, isGoodEconomyCause, isMoneyType }) => {
  if (isGoodEconomyCause) return null

  if (isMoneyType) return `Amount in ${constants.OPPORTUNITY_GOAL_TYPE_DELIVERABLES[goalType]}`

  return `Amount of ${constants.OPPORTUNITY_GOAL_TYPE_DELIVERABLES[goalType]}`
}

const determineCampaign = ({ opportunity }) => {
  if (!opportunity) return false

  const campaignId = opportunity.campaign_id
  const goodEconomyCampaignId = constants.GOOD_ECONOMY_CAMPAIGN_ID

  return campaignId === goodEconomyCampaignId
}

class DonationAmountSelect extends Component {
  setOption = ({ active, field, form, onChange, opt }) => {
    if (typeof onChange === 'function') {
      const val = active ? null : opt
      onChange(val)
      return true
    }

    if (form) {
      const val = active ? null : opt ? opt.value : null
      form.setFieldValue(field.name, val)
    }
  }

  showOptions({ isSubmitting }) {
    const val = isSubmitting ? false : true
    this.setState({ showOptions: val })
  }

  render() {
    const {
      field = {},
      goalType,
      isMoneyType,
      onChange,
      opportunity,
      success,
      values,
      ...props
    } = this.props

    const form = props.form || {}
    const errors = form.errors || {}
    const touched = form.touched || {}

    const isGoodEconomyCause = determineCampaign({ opportunity })
    const commitmentOpts = buildCommitmentOpts({
      goalType,
      isMoneyType,
      isGoodEconomyCause,
      opportunity
    })
    const valueFromProps = field.value || this.props.value
    const val = valueFromId(commitmentOpts, valueFromProps)
    const hasError = errors[field.name]

    const commitmentSubLabel = deriveCommitmentSubLabel({
      goalType,
      isGoodEconomyCause,
      isMoneyType
    })

    return (
      <Wrapper>
        {hasError ? (
          <Error>{hasError}</Error>
        ) : (
          <Label htmlFor={field.name}>Select your commitment</Label>
        )}

        <div>
          <SubLabel>{commitmentSubLabel}</SubLabel>
        </div>

        <SelectComponentContainer
          {...field}
          hasError={!!hasError}
          isGoodEconomyCause={isGoodEconomyCause}
          touched={!!touched[field.name]}
          value={val}
          {...props}
        >
          {commitmentOpts.map(({ fill, Icon, iconPadding, ...opt }) => {
            const active = isActive({ field, isGoodEconomyCause, opt, valueFromProps })

            return (
              <ShortSelectComponentOption
                active={active}
                key={opt.value}
                onClick={() => this.setOption({ active, field, form, onChange, opt })}
              >
                <ShortSwipeOptionDetails active={active}>
                  <ConditionalDisplay displayWhen={[!Icon]}>
                    <ShortSwipeOptionImage>
                      <ShortSmallImage active={active} src={opt.image} />
                    </ShortSwipeOptionImage>
                  </ConditionalDisplay>

                  <ImageForShortSwipeOption
                    action={active}
                    fill={fill}
                    iconPadding={iconPadding}
                    Icon={Icon}
                  />
                  <ShortSwipeOptionsWrapper>
                    <ShortSwipeOptionLabel active={active} hasSubLabel={opt.subLabel}>
                      {opt.label}
                    </ShortSwipeOptionLabel>

                    <ConditionalDisplay displayWhen={[opt.subLabel]}>
                      <ShortSwipeOptionSubLabel active={active}>
                        {opt.subLabel}
                      </ShortSwipeOptionSubLabel>
                    </ConditionalDisplay>
                  </ShortSwipeOptionsWrapper>
                </ShortSwipeOptionDetails>

                <OptionValueSelector
                  form={form}
                  isGoodEconomyCause={isGoodEconomyCause}
                  opt={opt}
                  values={values}
                />
              </ShortSelectComponentOption>
            )
          })}
        </SelectComponentContainer>

        {success && touched[field.name] && !hasError && <Success>{success}</Success>}
      </Wrapper>
    )
  }
}

export default DonationAmountSelect
