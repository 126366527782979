import { createActions, handleActions } from 'redux-actions'

// utils
import UrlBuilder from 'utils/urlBuilder'

// helpers
import { loadingError, startLoading, startSubmitting } from './_helpers'

export const CREATE_CAMPAIGN = 'campaigns/CREATE_CAMPAIGN'
export const CREATE_FAILURE = 'campaigns/CREATE_FAILURE'
export const CREATE_SUCCESS = 'campaigns/CREATE_SUCCESS'
export const DELETE_CAMPAIGN = 'campaigns/DELETE_CAMPAIGN'
export const DELETE_FAILURE = 'campaigns/DELETE_FAILURE'
export const DELETE_SUCCESS = 'campaigns/DELETE_SUCCESS'
export const FETCH_ALL_CAMPAIGNS = 'campaigns/FETCH_ALL_CAMPAIGNS'
export const FETCH_CAMPAIGN = 'campaigns/FETCH_CAMPAIGN'
export const FETCH_CAMPAIGNS = 'campaigns/FETCH_CAMPAIGNS'
export const FETCH_FAILURE = 'campaigns/FETCH_FAILURE'
export const FETCH_OWN_CAMPAIGNS = 'campaigns/FETCH_OWN_CAMPAIGNS'
export const FETCH_SINGLE_SUCCESS = 'campaigns/FETCH_SINGLE_SUCCESS'
export const FETCH_SINGLE_FAILURE = 'campaigns/FETCH_SINGLE_FAILURE'
export const FETCH_SUCCESS = 'campaigns/FETCH_SUCCESS'
export const LOGOUT_SUCCESS = 'authentication/LOGOUT_SUCCESS'
export const UPDATE_CAMPAIGN = 'campaigns/UPDATE_CAMPAIGN'
export const UPDATE_CAMPAIGN_OPPS = 'campaigns/UPDATE_CAMPAIGN_OPPS'
export const UPDATE_FAILURE = 'campaigns/UPDATE_FAILURE'
export const UPDATE_SUCCESS = 'campaigns/UPDATE_SUCCESS'

export const {
  campaigns: {
    createCampaign,
    createFailure,
    createSuccess,
    deleteCampaign,
    deleteFailure,
    deleteSuccess,
    fetchAllCampaigns,
    fetchCampaign,
    fetchCampaigns,
    fetchFailure,
    fetchOwnCampaigns,
    fetchSingleFailure,
    fetchSingleSuccess,
    fetchSuccess,
    updateCampaign,
    updateCampaignOpps,
    updateFailure,
    updateSuccess
  }
} = createActions({
  [CREATE_CAMPAIGN]: undefined,
  [CREATE_FAILURE]: undefined,
  [CREATE_SUCCESS]: undefined,
  [DELETE_CAMPAIGN]: undefined,
  [DELETE_FAILURE]: undefined,
  [DELETE_SUCCESS]: campaignId => ({ campaignId }),
  [FETCH_ALL_CAMPAIGNS]: undefined,
  [FETCH_CAMPAIGN]: undefined,
  [FETCH_CAMPAIGNS]: undefined,
  [FETCH_FAILURE]: undefined,
  [FETCH_OWN_CAMPAIGNS]: undefined,
  [FETCH_SINGLE_FAILURE]: undefined,
  [FETCH_SINGLE_SUCCESS]: undefined,
  [FETCH_SUCCESS]: undefined,
  [UPDATE_CAMPAIGN]: undefined,
  [UPDATE_CAMPAIGN_OPPS]: undefined,
  [UPDATE_FAILURE]: undefined,
  [UPDATE_SUCCESS]: undefined
})

const initialState = {
  isLoading: false,
  isSubmitting: false,
  isSuccess: false,
  hasError: false,
  errorMessage: null,
  data: [],
  pageCount: 1,
  customFields: [],
  singleCampaignData: {}
}

export default handleActions(
  {
    [CREATE_CAMPAIGN]: startSubmitting,
    [CREATE_FAILURE]: (state, { payload }) => ({
      ...state,
      hasError: true,
      errorMessage: payload.message,
      isLoading: false,
      isSubmitting: false
    }),
    [CREATE_SUCCESS]: (state, { payload }) => {
      if (!payload.isPartialUpdate) {
        UrlBuilder.displayUpdateSuccessMessage()
      }

      return { ...state, isLoading: false, isSubmitting: false, singleCampaignData: payload.data }
    },
    [DELETE_CAMPAIGN]: startSubmitting,
    [DELETE_FAILURE]: loadingError,
    [DELETE_SUCCESS]: (state, { payload }) => ({
      ...state,
      isLoading: false,
      isSubmitting: true,
      data: state.data.filter(c => c.id !== payload.campaignId)
    }),
    [FETCH_ALL_CAMPAIGNS]: startLoading,
    [FETCH_CAMPAIGN]: startLoading,
    [FETCH_CAMPAIGNS]: startLoading,
    [FETCH_FAILURE]: loadingError,
    [FETCH_OWN_CAMPAIGNS]: startLoading,
    [FETCH_SINGLE_SUCCESS]: (state, { payload }) => ({
      ...state,
      isLoading: false,
      hasError: false,
      errorMessage: null,
      singleCampaignData: payload,
      isSuccess: true
    }),
    [FETCH_SINGLE_FAILURE]: loadingError,
    [FETCH_SUCCESS]: (state, { payload }) => ({
      ...state,
      isLoading: false,
      hasError: false,
      isSuccess: true,
      errorMessage: null,
      data: payload.campaigns || [],
      pageCount: payload.pageCount,
      customFields: payload.customFields || []
    }),
    [LOGOUT_SUCCESS]: () => initialState,
    [UPDATE_CAMPAIGN]: state => ({
      ...state,
      isLoading: false,
      isSubmitting: true
    }),
    [UPDATE_FAILURE]: loadingError,
    [UPDATE_SUCCESS]: (state, { payload }) => {
      if (!payload.isPartialUpdate) {
        UrlBuilder.displayUpdateSuccessMessage()
      }

      const updatedData = [payload, ...state.data.filter(o => o.id !== payload.id)]
      return { ...state, isLoading: false, isSubmitting: false, data: updatedData }
    },
    [UPDATE_CAMPAIGN_OPPS]: state => state
  },
  initialState
)
