import { createActions, handleActions } from 'redux-actions'

// helpers
import { loadingError, startLoading } from './_helpers'

export const FETCH_CAMPAIGN_PARTNERS = 'campaignPartners/FETCH_CAMPAIGN_PARTNERS'
export const FETCH_CAMPAIGN_PARTNERS_FAILURE = 'campaignPartners/FETCH_CAMPAIGN_PARTNERS_FAILURE'
export const FETCH_CAMPAIGN_PARTNERS_SUCCESS = 'campaignPartners/FETCH_CAMPAIGN_PARTNERS_SUCCESS'
export const REMOVE_CAMPAIGN_PARTNER = 'campaignPartners/REMOVE_CAMPAIGN_PARTNER'
export const REMOVE_CAMPAIGN_PARTNER_FAILURE = 'campaignPartners/REMOVE_CAMPAIGN_PARTNER_FAILURE'
export const REMOVE_CAMPAIGN_PARTNER_SUCCESS = 'campaignPartners/REMOVE_CAMPAIGN_PARTNER_SUCCESS'

export const {
  campaignPartners: {
    fetchCampaignPartners,
    fetchCampaignPartnersFailure,
    fetchCampaignPartnersSuccess,
    removeCampaignPartner,
    removeCampaignPartnerFailure,
    removeCampaignPartnerSuccess
  }
} = createActions({
  [FETCH_CAMPAIGN_PARTNERS]: undefined,
  [FETCH_CAMPAIGN_PARTNERS_FAILURE]: undefined,
  [FETCH_CAMPAIGN_PARTNERS_SUCCESS]: undefined,
  [REMOVE_CAMPAIGN_PARTNER]: undefined,
  [REMOVE_CAMPAIGN_PARTNER_FAILURE]: undefined,
  [REMOVE_CAMPAIGN_PARTNER_SUCCESS]: undefined
})

const initialState = {
  isLoading: false,
  isSubmitting: false,
  hasError: false,
  errorMessage: null,
  data: []
}

export default handleActions(
  {
    [FETCH_CAMPAIGN_PARTNERS]: startLoading,
    [FETCH_CAMPAIGN_PARTNERS_SUCCESS]: (state, { payload }) => ({
      ...state,
      isLoading: false,
      hasSubmitted: false,
      hasError: false,
      data: payload
    }),
    [FETCH_CAMPAIGN_PARTNERS_FAILURE]: loadingError,
    [REMOVE_CAMPAIGN_PARTNER]: state => {
      return { ...state, isLoading: true, hasError: false }
    },
    [REMOVE_CAMPAIGN_PARTNER_SUCCESS]: (state, { payload }) => ({
      ...state,
      isLoading: false,
      hasSubmitted: false,
      hasError: false,
      data: state.data.filter(o => o.id !== payload.campaignPartnerId)
    }),
    [REMOVE_CAMPAIGN_PARTNER_FAILURE]: loadingError
  },
  initialState
)
