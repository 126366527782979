import React from 'react'
import styled from 'styled-components'
import { Link } from '@reach/router'
import th from '../../utils/themeHelper'
import webPImage from '../../utils/webPImage'
import thumbSource from '../../utils/thumbSource'
import l from '../../config/localization'
import { Calendar, Heart } from '../Icons/Icons'
import InfoField from '../shared/InfoField'
import { HandsHelping } from '@styled-icons/fa-solid'

const Card = styled.div`
  border: 1px solid ${th('borders.default')};
  border-radius: 5px;
  background: white;
  width: 100%;

  &:hover {
    box-shadow: 1px 1px 0 rgba(0, 0, 0, 0.1);
  }

  @media screen and (max-width: 600px) {
    margin-bottom: 15px;
  }

  /* Landscape */
  @media only screen and (min-device-width: 320px) and (max-device-width: 812px) and (orientation: landscape) {
    display: flex;
  }
`
const CardBanner = styled.div`
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  height: 200px;
  background-color: lightgray;
  background-image: url(${({ src }) => src});
  background-size: cover;
  background-position: center center;

  /* Landscape */
  @media only screen and (min-device-width: 320px) and (max-device-width: 812px) and (orientation: landscape) {
    width: 40%;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    border-top-right-radius: 0px;
    height: auto;
  }
`
const CardContent = styled.div`
  padding: 30px;
  /* Landscape */
  @media only screen and (min-device-width: 320px) and (max-device-width: 812px) and (orientation: landscape) {
    width: 60%;
  }
`

const CardLinkWrapper = styled(Link)`
  text-decoration: none;
  color: inherit;
`

const Title = styled.h2`
  margin: 0;
  overflow-wrap: break-word;
  word-wrap: break-word;
  hyphens: auto;
`

const LandingPageCampaignCard = ({
  id,
  title,
  shortUrl,
  description,
  imgSrc,
  opportunities,
  opportunityCount,
  endDate,
  pvtShareKey,
  doesSupportWebP
}) => {
  const thumbnail = thumbSource(imgSrc)
  const imageToUse = window.innerWidth > 600 ? imgSrc : thumbnail
  const imageUrl = doesSupportWebP ? webPImage(imageToUse) : imageToUse
  const campUrl = pvtShareKey
    ? `${l('ROUTE_CAMPS')}/${id}?title=${shortUrl}&pvtShareKey=${pvtShareKey}`
    : `${l('ROUTE_CAMPS')}/${id}?title=${shortUrl}`

  return (
    <CardLinkWrapper to={campUrl}>
      <Card>
        <CardBanner src={imageUrl} />
        <CardContent>
          <InfoField icon={Heart}>{`Supporting ${opportunities} `}</InfoField>
          <Title>{title}</Title>
          <p>{description}</p>

          <InfoField icon={HandsHelping}>
            {`${opportunityCount} ${l('OPP', opportunityCount)}`}
          </InfoField>

          <InfoField icon={Calendar}>{endDate ? `Ends ${endDate}` : 'Ongoing'}</InfoField>
        </CardContent>
      </Card>
    </CardLinkWrapper>
  )
}

export default LandingPageCampaignCard
