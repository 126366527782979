import axios from 'axios'

// config
import baseUrl from '../config'

export const generatePaymentIdentifier = (data = {}) => {
  return axios.post(`${baseUrl}/payments/generate-payment-identifier`, {
    data,
    withCredentials: true
  })
}

export const confirmSuccessfulPayment = (data = {}) => {
  return axios.post(`${baseUrl}/payments/confirm-donation`, { data, withCredentials: true })
}

export const recordFailedPayment = (data = {}) => {
  return axios.post(`${baseUrl}/payments/donation-failure`, { data, withCredentials: true })
}

export const withdrawPayfastDonation = (data = {}) => {
  return axios.post(`${baseUrl}/payments/withdraw-payment`, { data, withCredentials: true })
}
