import React from 'react'
import { Formik } from 'formik'
import styled from 'styled-components'

// icons
import { Check } from '@styled-icons/material/Check'
import { CalendarEventFill } from '@styled-icons/bootstrap/CalendarEventFill'

// styles
import { Error, Label } from 'components/InputLabels/InputLabels'
import {
  CenteredModalHeader,
  CloseIcon,
  FormSectionButtonWrapper,
  LabelWrapper,
  ModalContainer,
  FullWidthModalFieldRow,
  ScreenWrapper
} from './styles'

// utils
import th from 'utils/themeHelper'

// Load components synchronously
import ConditionalDisplay from 'components/ConditionalDisplay/ConditionalDisplay'
import FormProgressBars from 'components/FormProgressBars/FormProgressBars'
import SmallButtonPrimary from 'components/SmallButtonPrimary/SmallButtonPrimary'

const PaymentFrequencySelectorWrapper = styled.div`
  margin: 0 auto;
  width: 70%;

  @media screen and (max-width: 769px) {
    width: 100%;
  }
`

const SelectedFrequencyIconWrapper = styled.div`
  background: #fff;
  border: 1px solid ${th('borders.dark')};
  position: absolute;
  border-radius: 50%;
  display: ${props => (props.active ? 'block' : 'none')};
  padding: 10px;
  right: -20px;

  svg {
    height: 20px;
    width: 20px;
  }
`

const FrequencyIconWrapper = styled.div`
  margin-right: 15px;

  svg {
    height: 50px;
    width: 50px;
  }
`

const InnerWrapper = styled.div`
  align-items: center;
  display: flex;
`

const ChallengeFrequencyOption = styled.div`
  background: ${props =>
    props.active ? '#2A90443C' : props.disabled ? th('backgrounds.light') : 'white'};
  border-color: ${props => (props.active ? th('primary.base') : th('borders.default'))};
  border-style: solid;
  border-radius: 4px;
  border-width: 2px;
  display: flex;
  margin-bottom: 15px;
  max-width: 500px;
  padding: 10px;
  position: relative;

  &:hover {
    border-color: ${props =>
      props.active
        ? th('primary.base')
        : props.disabled
        ? th('borders.default')
        : th('primary.base')};
    cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};
  }
`

const ChallengeFrequencyLabel = styled.div`
  font-family: ${th('fonts.bold')};
  font-size: 18px;
  margin-bottom: 8px;
`

const ChallengeFrequencySubLabel = styled.div`
  color: ${th('text.mid')};
  font-size: 14px;
  line-height: 1.35em;
  max-width: 700px;
`

const frequencyOpts = [
  { frequency: 'once_off', label: 'Once off', subLabel: 'You will only be asked to check in once' },
  { frequency: 'daily', label: 'Daily', subLabel: 'You will be asked to check in once a day' },
  { frequency: 'weekly', label: 'Weekly', subLabel: 'You will be asked to check in once a week' },
  { frequency: 'monthly', label: 'Monthly', subLabel: 'You will be asked to check in once a month' }
]

const validateFrequency = values => {
  const errors = {}

  if (!values.challenge_checkin_frequency) {
    errors.challenge_checkin_frequency = 'Please select a check-in frequency.'
  }

  return errors
}

const ChallengeCheckinFrequencySection = ({ extraInfoModalOpts, hideModal }) => {
  const { opportunity } = extraInfoModalOpts
  const { challengeFrequency } = opportunity

  if (!challengeFrequency || challengeFrequency === 'self_choice') {
    return (
      <ScreenWrapper>
        <ModalContainer>
          <Formik
            initialValues={{ challenge_checkin_frequency: undefined }}
            enableReinitialize
            validate={validateFrequency}
            validateOnChange={false}
            validateOnBlur={false}
            onSubmit={values => extraInfoModalOpts.updateFormValues(values)}
            render={({ errors, handleSubmit, setFieldValue, values }) => {
              const chosenValue = values.challenge_checkin_frequency

              return (
                <form onSubmit={handleSubmit}>
                  <div>
                    <CloseIcon
                      dark
                      onClick={() => {
                        setFieldValue('challenge_checkin_frequency', undefined)
                        hideModal()
                      }}
                      size={30}
                    />

                    <CenteredModalHeader>Select a check-in frequency</CenteredModalHeader>

                    <FormProgressBars active={1} canNavTo={[]} steps={2} />

                    <FullWidthModalFieldRow marginTop="30px">
                      <LabelWrapper>
                        <ConditionalDisplay displayWhen={[!errors.challenge_checkin_frequency]}>
                          <Label>How often would you like to check-in for this challenge?</Label>
                        </ConditionalDisplay>

                        <ConditionalDisplay displayWhen={[errors.challenge_checkin_frequency]}>
                          <Error>Please select a check-in frequency</Error>
                        </ConditionalDisplay>
                      </LabelWrapper>

                      <PaymentFrequencySelectorWrapper>
                        {frequencyOpts.map((frequencyOption, idx) => {
                          return (
                            <ChallengeFrequencyOption
                              active={chosenValue === frequencyOption.frequency}
                              key={`frequency_opt_${idx}`}
                              onClick={() =>
                                setFieldValue(
                                  'challenge_checkin_frequency',
                                  frequencyOption.frequency
                                )
                              }
                            >
                              <InnerWrapper>
                                <SelectedFrequencyIconWrapper
                                  active={chosenValue === frequencyOption.frequency}
                                >
                                  <Check />
                                </SelectedFrequencyIconWrapper>

                                <FrequencyIconWrapper>
                                  <CalendarEventFill />
                                </FrequencyIconWrapper>

                                <div>
                                  <ChallengeFrequencyLabel>
                                    {frequencyOption.label}
                                  </ChallengeFrequencyLabel>
                                  <ChallengeFrequencySubLabel>
                                    {frequencyOption.subLabel}
                                  </ChallengeFrequencySubLabel>
                                </div>
                              </InnerWrapper>
                            </ChallengeFrequencyOption>
                          )
                        })}
                      </PaymentFrequencySelectorWrapper>
                    </FullWidthModalFieldRow>

                    <FormSectionButtonWrapper>
                      <SmallButtonPrimary disabled={!chosenValue} type="submit">
                        Continue
                      </SmallButtonPrimary>
                    </FormSectionButtonWrapper>
                  </div>
                </form>
              )
            }}
          />
        </ModalContainer>
      </ScreenWrapper>
    )
  }

  return (
    <ScreenWrapper>
      <ModalContainer>
        <Formik
          initialValues={{ challenge_checkin_frequency: challengeFrequency }}
          enableReinitialize
          validateOnChange={false}
          validateOnBlur={false}
          onSubmit={values => extraInfoModalOpts.updateFormValues(values)}
          render={({ handleSubmit }) => {
            const setFrequency = frequencyOpts.find(o => o.frequency === challengeFrequency)

            return (
              <form onSubmit={handleSubmit}>
                <div>
                  <CloseIcon dark onClick={() => hideModal()} size={30} />

                  <CenteredModalHeader>This challenge has a set frequency</CenteredModalHeader>

                  <FormProgressBars active={1} canNavTo={[]} steps={2} />

                  <FullWidthModalFieldRow marginTop="30px">
                    <PaymentFrequencySelectorWrapper>
                      <ChallengeFrequencyOption active>
                        <InnerWrapper>
                          <SelectedFrequencyIconWrapper active>
                            <Check />
                          </SelectedFrequencyIconWrapper>

                          <FrequencyIconWrapper>
                            <CalendarEventFill />
                          </FrequencyIconWrapper>

                          <div>
                            <ChallengeFrequencyLabel>{setFrequency.label}</ChallengeFrequencyLabel>

                            <ChallengeFrequencySubLabel>
                              {setFrequency.subLabel}
                            </ChallengeFrequencySubLabel>
                          </div>
                        </InnerWrapper>
                      </ChallengeFrequencyOption>
                    </PaymentFrequencySelectorWrapper>
                  </FullWidthModalFieldRow>

                  <FormSectionButtonWrapper>
                    <SmallButtonPrimary type="submit">Continue</SmallButtonPrimary>
                  </FormSectionButtonWrapper>
                </div>
              </form>
            )
          }}
        />
      </ModalContainer>
    </ScreenWrapper>
  )
}

export default ChallengeCheckinFrequencySection
