import styled from 'styled-components'
import th from 'utils/themeHelper'

const FormRow = styled.div`
  align-items: flex-start;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  position: relative;

  & > * {
    flex: 1 1 auto;
  }

  & > * + * {
    margin-left: ${th('spacing.small', 'px')};
  }

  & + & {
    margin-top: ${th('spacing.small', 'px')};
  }
`

export default FormRow
