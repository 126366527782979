import styled from 'styled-components'

export const ThumbImg = styled.img`
  margin-top: 15px;
  display: block;
  width: 100%;
`

export const Warning = styled.div`
  background: #fbe5e0;
  color: #db3a1b;
  padding: 8px;
  font-size: 12px;
  border: 1px solid #db3a1b;
  margin-top: 15px;
`
