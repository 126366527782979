import React from 'react'

// styles
import { Outer, IconHolder, FacebookIcon, TwitterIcon, InstagramIcon, WhatsAppIcon } from './styles'

// utils
import uuid from 'utils/uuid'
import UrlBuilder from 'utils/urlBuilder'

// config
import l from 'config/localization'

// components
import { CopyIcon } from '../Icons/Icons'
import Tooltip from '../Tooltip'

const shareTwitter = ({ e, footer, title, description, shareUrl, twitterMessage }) => {
  if (footer) return true

  e.preventDefault()

  const constructedTitle = description ? `${title} - ${description}` : title
  const text = twitterMessage || constructedTitle
  const pageUrl = UrlBuilder.url(shareUrl || document.location.href, {
    cb: uuid()
  })
  const twitterUrl = UrlBuilder.url('https://twitter.com/share', {
    url: pageUrl,
    text
  })

  window.open(twitterUrl, 'twitter-popup', 'height=350,width=600')
}

const shareFacebook = ({ e, footer, title, description, shareUrl, fbMessage }) => {
  if (footer) return true

  e.preventDefault()

  const constructedTitle = description ? `${title} - ${description}` : title
  const pageUrl = UrlBuilder.url(shareUrl || document.location.href, {
    cb: uuid()
  })

  const facebookUrl = UrlBuilder.url('https://www.facebook.com/sharer/sharer.php', {
    'p[title]': fbMessage || constructedTitle,
    u: pageUrl
  })
  window.open(facebookUrl, 'facebook-popup', 'height=350,width=600')
}

const copyLink = ({ shareUrl }) => {
  const urlToShare = shareUrl || location.href
  navigator.clipboard.writeText(urlToShare)
}

const SocialMediaIcons = ({
  footer,
  title,
  justify,
  description,
  short_description,
  shareUrl,
  twitterMessage,
  fbMessage,
  isShareModal
}) => {
  const fbUrl = footer ? l('FACEBOOK_LINK') : '#'
  const twUrl = footer ? l('TWITTER_LINK') : '#'

  const whatsappCopyToEncode =
    (title || '').replace(/\\n.+/, '') +
    `\n\n` +
    (short_description || description || '').replace(/\\n.+/, '') +
    `\n\n` +
    (shareUrl || document.location.href)
  const whatsAppText = encodeURIComponent(whatsappCopyToEncode)
  const whatsAppLink = `https://api.whatsapp.com/send?text=${whatsAppText}`
  const igLink = 'https://www.instagram.com/browniepointssa/'

  return (
    <Outer justify={justify}>
      {isShareModal && (
        <Tooltip content="Copied link!" minWidth position="top">
          <IconHolder
            onClick={() => copyLink({ shareUrl })}
            icon="copy"
            aria-label="Copy link to Brownie Points page"
          >
            <CopyIcon width="20" color="black" />
          </IconHolder>
        </Tooltip>
      )}

      <IconHolder
        onClick={e => shareFacebook({ e, footer, title, description, shareUrl, fbMessage })}
        icon="facebook"
        href={fbUrl}
        target="_blank"
        rel="noopener"
        aria-label="Link to Brownie Points Facebook account"
      >
        <FacebookIcon size={17} />
      </IconHolder>

      <IconHolder
        onClick={e =>
          shareTwitter({
            e,
            footer,
            title,
            description: short_description || description,
            shareUrl,
            twitterMessage
          })
        }
        icon="twitter"
        href={twUrl}
        target="_blank"
        rel="noopener"
        aria-label="Link to Brownie Points Twitter account"
      >
        <TwitterIcon size={17} />
      </IconHolder>

      <IconHolder
        icon="whatsapp"
        href={whatsAppLink}
        target="_blank"
        rel="noopener"
        aria-label="Share to WhatsApp link"
      >
        <WhatsAppIcon size={20} />
      </IconHolder>

      {footer && (
        <IconHolder
          icon="instagram"
          href={igLink}
          target="_blank"
          rel="noopener"
          aria-label="Link to Brownie Points Instagram account"
        >
          <InstagramIcon size={18} />
        </IconHolder>
      )}
    </Outer>
  )
}

export default SocialMediaIcons
