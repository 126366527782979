import React from 'react'
import { Formik } from 'formik'

// styles
import {
  CenteredModalHeader,
  CloseIcon,
  FormSectionButtonWrapper,
  FormSectionRow,
  ModalContainer,
  ScreenWrapper,
  Strong
} from './styles'

// utils
import goalTypeOutcomeBuilder from 'utils/goalTypeOutcomeBuilder'

// config
import constants from 'config/constants'
import l from 'config/localization'

// Load components synchronously
import ConditionalDisplay from 'components/ConditionalDisplay/ConditionalDisplay'
import FormProgressBars from 'components/FormProgressBars/FormProgressBars'
import SmallButtonPrimary from 'components/SmallButtonPrimary/SmallButtonPrimary'
import GoodsDonationAmountSelect from 'components/Select/GoodsDonationAmountSelect'

const validateAmount = values => {
  const errors = {}

  const goodsCommitments = Object.keys(values).filter(k => k.match(/is_donating_goods_request_/))

  goodsCommitments.map(commitment => {
    const id = commitment.replace(/is_donating_goods_request_/, '')
    if (!values[`applicant_commitment_${id}`]) {
      errors[`applicant_commitment_${id}`] = 'Please select a donation amount.'
    }
  })

  return errors
}

const DonationAmountFormSection = ({ extraInfoModalOpts, hideModal, opportunity = {} }) => {
  const goalType = opportunity.goal_type || constants.OPPORTUNITY_GOAL_TYPES.MONEY
  const displayableOutcome = constants.OPPORTUNITY_GOAL_TYPE_DELIVERABLES[goalType]

  return (
    <ScreenWrapper>
      <ModalContainer>
        <Formik
          initialValues={{}}
          enableReinitialize
          validate={validateAmount}
          validateOnChange={false}
          validateOnBlur={false}
          onSubmit={values => extraInfoModalOpts.updateFormValues(values)}
          render={({ errors, handleSubmit, setFieldValue, touched, values }) => {
            const hasCommittedAnything = Object.keys(values).some(k =>
              k.match(/is_donating_goods_request_/)
            )

            return (
              <form onSubmit={handleSubmit}>
                <div>
                  <CloseIcon
                    dark
                    onClick={() => {
                      extraInfoModalOpts.updateFormValues(extraInfoModalOpts.formValues)
                      hideModal()
                    }}
                    size={30}
                  />

                  <CenteredModalHeader>Select donation amount</CenteredModalHeader>

                  <FormProgressBars active={1} canNavTo={[1]} steps={2} />

                  <ConditionalDisplay displayWhen={[opportunity.id]}>
                    <FormSectionRow>
                      <p>
                        The goal of this {l('OPP')} is{' '}
                        <Strong>
                          {goalTypeOutcomeBuilder({
                            goalType: goalType,
                            displayableOutcome,
                            value: opportunity.outcome_target
                          })}
                        </Strong>
                        . Please include the amount of each item that you think you'd be able to
                        commit to.
                      </p>
                    </FormSectionRow>
                  </ConditionalDisplay>

                  <FormSectionRow>
                    <GoodsDonationAmountSelect
                      errors={errors}
                      opportunity={opportunity}
                      setFieldValue={setFieldValue}
                      touched={touched}
                      values={values}
                    />
                  </FormSectionRow>

                  <FormSectionButtonWrapper>
                    <SmallButtonPrimary
                      disabled={!hasCommittedAnything}
                      onClick={() => setFieldValue('has_confirmed_commitments', true)}
                      type="submit"
                    >
                      Continue
                    </SmallButtonPrimary>
                  </FormSectionButtonWrapper>
                </div>
              </form>
            )
          }}
        />
      </ModalContainer>
    </ScreenWrapper>
  )
}

export default DonationAmountFormSection
