import { connect } from 'react-redux'
import * as placesModule from '../../redux/modules/places'

const PlacesContainer = ({ children, ...props }) => children(props)

const mapStateToProps = state => ({
  places: state.places.data,
  searchQuery: state.places.searchQuery
})

const mapDispatchToProps = dispatch => ({
  fetchPlaces: input => dispatch(placesModule.fetchPlaces(input)),
  clearPlaces: () => dispatch(placesModule.clearPlaces())
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PlacesContainer)
