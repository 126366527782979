import React, { Component } from 'react'
import styled from 'styled-components'
import { navigate } from '@reach/router'

// assets
import { ReactComponent as LockIcon } from 'assets/images/svgs/icon-public-opp.svg'
import { ReactComponent as PrivateIcon } from 'assets/images/svgs/icon-private-opp.svg'
import { ReactComponent as PublicIcon } from 'assets/images/svgs/icon-public-opp.svg'
import bpIcon from 'assets/images/bp-logo.png'
import orgIcon from 'assets/images/icon-group.svg'

// icons
import { EllipsisHorizontal } from '@styled-icons/ionicons-outline'
import { Flag, FlagFill } from '@styled-icons/bootstrap'
import { Heart, SolidHeart, Wrench } from 'components/Icons/Icons'

// config
import l from 'config/localization'
import constants from 'config/constants'

// utils
import th from 'utils/themeHelper'
import isLoggedIn from 'utils/auth'

// Load components synchronously
import LinkButton from 'components/SmallButtonPrimary/LinkButton'
import SmallButtonPrimary from 'components/SmallButtonPrimary/SmallButtonPrimary'
import SwipeSelect from 'components/Select/SwipeSelect'
import Tooltip from 'components/Tooltip'

const ButtonWrapper = styled.div`
  margin-bottom: 10px;
  margin-right: 10px;

  @media screen and (min-width: 769px) {
    margin-bottom: 0;
  }
`

const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: ${props => props.justify || 'flex-start'};

  @media screen and (max-width: 600px) {
    flex-direction: column;
    max-width: max-content;
  }
`

const EditStatusLabel = styled.div`
  color: ${th('text.dark')};
  font-family: ${th('fonts.bold')};
  margin-bottom: 15px;
  margin-left: 2px;
`

export const HeartIconWrapper = styled.div`
  svg {
    color: ${props => props.color || th('text.dark')};
  }
`

const BeneficiaryTagIcon = styled.img`
  filter: grayscale(${props => (props.isConfirmed ? '0' : '1')});
  height: 25px;
  width: 25px;
  border-radius: 50%;
  padding: 2px;
`

const PointsIconImage = styled.img`
  filter: grayscale(${props => (props.isConfirmed ? '0' : '1')});
  height: 18px;
  margin-right: 5px;
  width: 13px;
`

const PointsLabel = styled.div`
  margin-bottom: -1px;
  margin-left: 2px;
  margin-right: 5px;
`

const StoryLikesCounterLabel = styled.div`
  color: ${th('text.dark')};
  font-family: ${th('fonts.regular')};
  font-size: 15px;
  font-weight: normal;
  margin: 2px 5px 0;
`

const StoryPillContainer = styled.div`
  position: ${props => (props.open ? 'absolute' : 'relative')};
  bottom: ${props => (props.open ? '10px' : '0')};
  margin-right: 8px;
  width: ${props =>
    props.open && props.context === 'StoryEditIcon' ? 'calc(100% - 20px)' : 'auto'};
  z-index: ${props => (props.open ? 10 : 1)};
`

const StoryPillInner = styled.div`
  align-items: ${props => (props.open && props.fullSize ? 'flex-start' : 'center')};
  background: ${props => props.background || 'white'};
  border: 1px solid ${th('borders.medium')};
  border-radius: 4px;
  display: flex;
  justify-content: center;
  line-height: 1.35;
  min-height: 37px;
  overflow-y: auto;
  padding: 7px 8px;
  width: auto;

  @media screen and (min-width: 769px) {
    min-height: 30px;
    min-width: 32px;
    padding: ${props => (props.open && props.fullSize ? '20px' : '0 5px')};
  }

  svg {
    margin-right: ${props => (props.open ? '4px' : '0')};
    margin-top: ${props => (props.open ? '-3px' : props.marginTop || '-2px')};
  }

  &:hover {
    background: ${props => (props.open ? 'white' : props.hoverBackgroundColor || '#8cca11')};
    border: 1px solid ${props => (props.disabled ? th('borders.medium') : th('borders.dark'))};
    cursor: ${props => props.cursor || 'help'};
  }

  &:hover ${HeartIconWrapper} {
    svg {
      color: ${props => (props.disabled ? 'initial' : props.svgHoverColor)};
    }
  }

  &:focus {
    background: white;
    border: 2px solid ${th('borders.dark')};
  }
`

const StoryPillsWrapper = styled.div`
  position: ${props =>
    props.forceRelative
      ? 'relative'
      : props.hasMedia
      ? 'absolute'
      : props.position === 'absolute'
      ? 'absolute'
      : 'relative'};
  bottom: 0;
  display: flex;
  min-height: ${props => (props.editStoryConfigOpen ? '200px' : 'unset')};
  padding: ${props => (props.dark && props.isFeatureStory ? '10px' : '5px 10px 10px')};
  width: ${props => (props.editStoryConfigOpen ? '100%' : 'auto')};

  @media screen and (min-width: 769px) {
    padding: ${props => (props.dark && props.isFeatureStory ? '5px 0 0' : '5px 10px 10px')};
  }
`

const StoryReportedLabel = styled.div`
  color: ${th('text.dark')};
  display: ${props => (props.justReported ? 'block' : 'none')};
  font-size: 12px;
  margin-left: 5px;
`

const determineLikeStatus = ({ storyLikeStatus, userLikesStory }) => {
  // When the story has a like since after page render
  if (storyLikeStatus === 'liked') return true
  if (storyLikeStatus === 'unliked') return false

  return userLikesStory
}

const StoryLikes = ({ storyLikesCount }) => {
  if (!storyLikesCount) return null

  return <StoryLikesCounterLabel>{storyLikesCount}</StoryLikesCounterLabel>
}

class LikeStoryIcon extends Component {
  state = { storyLikeStatus: null }

  render() {
    const heartColor = '#EB4525'
    const { storyLikeStatus } = this.state
    const {
      disabled,
      isFeatureStory,
      isOrgMasonry,
      isUserProfile,
      handleStoryLike,
      storyId,
      storyLikesCount,
      userLikesStory
    } = this.props

    if (isFeatureStory) return null

    const likeIsVisible = determineLikeStatus({ storyLikeStatus, userLikesStory })
    const isNullLike = !storyLikeStatus && !userLikesStory
    const isUnlike = storyLikeStatus === 'liked'
    const newValue = isNullLike
      ? 'liked'
      : isUnlike
      ? 'unliked'
      : userLikesStory
      ? 'unliked'
      : 'liked'

    return (
      <StoryPillContainer
        context="LikeStoryIcon"
        isOrgMasonry={isOrgMasonry}
        isUserProfile={isUserProfile}
        onClick={() => {
          if (disabled) return true

          this.setState({ storyLikeStatus: newValue })
          handleStoryLike({ id: storyId, value: newValue })
        }}
      >
        <StoryPillInner
          cursor={disabled ? 'default' : 'pointer'}
          disabled={disabled}
          hoverBackgroundColor="white"
          marginTop="0"
          svgHoverColor={heartColor}
        >
          {likeIsVisible ? (
            <HeartIconWrapper color={heartColor}>
              <SolidHeart width="20px" height="20px" />
            </HeartIconWrapper>
          ) : (
            <HeartIconWrapper color="#464646">
              <Heart width="20px" height="20px" />
            </HeartIconWrapper>
          )}
          <StoryLikes storyLikesCount={storyLikesCount} />
        </StoryPillInner>
      </StoryPillContainer>
    )
  }
}

class PrivateStoryHint extends Component {
  render() {
    const { hasMedia, isOrgMasonry, isUserProfile, storyStatus } = this.props

    const showLockIcon = isUserProfile || isOrgMasonry
    if (!showLockIcon) return null

    if (storyStatus === constants.STORY_STATES.PUBLIC) return null

    const anonTooltipContent = isUserProfile
      ? "This story's details are only visible to you."
      : isOrgMasonry
      ? "This story's details are only visible to the donor."
      : null

    return (
      <StoryPillContainer
        onClick={() => this.setState({ open: !open })}
        isOrgMasonry={isOrgMasonry}
        isUserProfile={isUserProfile}
        storyStatus={storyStatus}
        hasMedia={hasMedia}
      >
        <Tooltip content={anonTooltipContent} displayFlex forceShow minWidth position="top">
          <StoryPillInner>
            <LockIcon width="20px" height="20px" />
          </StoryPillInner>
        </Tooltip>
      </StoryPillContainer>
    )
  }
}

class StoryBeneficiaryIcon extends Component {
  render() {
    const {
      storyBeneficiaryLogo,
      storyBeneficiaryName,
      storyBeneficiaryStatus,
      storyBeneficiaryPath
    } = this.props
    if (!storyBeneficiaryName) return null

    const logo = storyBeneficiaryLogo || orgIcon
    const isConfirmed = storyBeneficiaryStatus === 'Confirmed'

    return (
      <StoryPillContainer onClick={() => navigate(`/${l('ROUTE_ORGS')}/${storyBeneficiaryPath}`)}>
        <Tooltip
          content={
            isConfirmed
              ? `Beneficiary: ${storyBeneficiaryName} - confirmed`
              : `Beneficiary: ${storyBeneficiaryName} - confirmation pending`
          }
          displayFlex
          forceShow
          minWidth
          position="top"
        >
          <StoryPillInner cursor="pointer" hoverBackgroundColor="white" marginTop="0">
            <BeneficiaryTagIcon isConfirmed={isConfirmed} src={logo} />
          </StoryPillInner>
        </Tooltip>
      </StoryPillContainer>
    )
  }
}

class PointsIcon extends Component {
  render() {
    const storyPoints = this.props.storyPoints || this.props.story_points
    const storyPointsStatus = this.props.storyPointsStatus || this.props.story_points_status

    if (!storyPoints || !storyPointsStatus) return null
    const isConfirmed = storyPointsStatus === 'Confirmed'

    return (
      <StoryPillContainer>
        <Tooltip
          content={
            isConfirmed
              ? `${storyPoints} brownie points earned`
              : `${storyPoints} brownie points pending`
          }
          displayFlex
          forceShow
          minWidth
          position="top"
        >
          <StoryPillInner cursor="help" hoverBackgroundColor="white" marginTop="0">
            <PointsIconImage isConfirmed={isConfirmed} src={bpIcon} />
            <PointsLabel>{storyPoints}</PointsLabel>
          </StoryPillInner>
        </Tooltip>
      </StoryPillContainer>
    )
  }
}

class MoreOptionsIcon extends Component {
  state = {
    open: false
  }

  render() {
    const { open } = this.state
    const { hasMedia } = this.props

    // TODO enable flagging
    const isDisabledTemporarily = true
    if (isDisabledTemporarily) {
      return null
    }

    if (!open) {
      return (
        <Tooltip position="top" content={'More options'} forceShow minWidth>
          <StoryPillContainer
            context="MoreOptionsIcon"
            onClick={() => this.setState({ open: !open })}
            open={open}
            hasMedia={hasMedia}
          >
            <StoryPillInner cursor={'pointer'} hoverBackgroundColor="white" marginTop="0">
              <EllipsisHorizontal width="15px" height="15px" />
            </StoryPillInner>
          </StoryPillContainer>
        </Tooltip>
      )
    }

    return <FlagIcon {...this.props} />
  }
}

class FlagIcon extends Component {
  state = {
    flagged: false,
    justReported: false
  }

  render() {
    const { flagged, justReported } = this.state
    const { hasMedia } = this.props

    if (flagged) {
      return (
        <StoryPillContainer context="FlagIcon" flagged hasMedia={hasMedia}>
          <StoryPillInner cursor={'default'} hoverBackgroundColor="white" marginTop="0">
            <FlagFill fill={justReported ? '#EB4525' : '#484848'} width="15px" height="15px" />
            <StoryReportedLabel justReported={justReported}>Story reported</StoryReportedLabel>
          </StoryPillInner>
        </StoryPillContainer>
      )
    }

    return (
      <Tooltip position="top" content={'Report story'} forceShow minWidth>
        <StoryPillContainer
          context="FlagIcon"
          onClick={() => this.setState({ flagged: true, justReported: true })}
          hasMedia={hasMedia}
        >
          <StoryPillInner cursor={'pointer'} hoverBackgroundColor="white" marginTop="0">
            <Flag width="15px" height="15px" />
          </StoryPillInner>
        </StoryPillContainer>
      </Tooltip>
    )
  }
}

class StoryEditIcon extends Component {
  state = { open: false, status: null }

  render() {
    const { open, status } = this.state
    const {
      hasGodRole,
      hasMedia,
      id,
      isFeatureStory,
      storyStatus,
      closeEditStoryConfig,
      updateStory
    } = this.props
    const isAnon = storyStatus === constants.STORY_STATES.ANON_DONATION

    const showEditButton = hasGodRole
    if (!showEditButton || !updateStory) return null
    if (isFeatureStory) return null

    const storyStateOpts = [
      {
        label: 'Public',
        value: constants.STORY_STATES.PUBLIC,
        Icon: PublicIcon,
        iconPadding: '8px 0 3px'
      },
      {
        label: 'Hidden',
        value: constants.STORY_STATES.ADMIN_HIDDEN,
        Icon: PrivateIcon,
        iconPadding: '8px 0 3px'
      }
    ]

    if (open) {
      const defaultStoryStatus = storyStatus
        ? storyStateOpts.find(o => o.value === storyStatus)
        : null
      const defaultStatus = defaultStoryStatus ? defaultStoryStatus.value : null

      return (
        <StoryPillContainer
          context="StoryEditIcon"
          fullSize
          left={open ? '15px' : isAnon ? '55px' : '15px'}
          open={open}
        >
          <StoryPillInner fullSize open={open}>
            <div style={{ width: '100%' }}>
              <EditStatusLabel>Manage Story</EditStatusLabel>

              <div style={{ width: '100%' }}>
                <SwipeSelect
                  defaultValue={defaultStatus}
                  flex
                  forceFullWidth
                  forceShowOptions
                  label="Update this story's status"
                  marginRight
                  onChange={selection => {
                    if (selection) {
                      this.setState({ status: selection.value })
                    }
                  }}
                  options={storyStateOpts}
                  shortOptions
                  value={status || defaultStatus}
                />
              </div>

              <ButtonsWrapper>
                <ButtonWrapper>
                  <SmallButtonPrimary
                    type="button"
                    onClick={() => {
                      updateStory({ id, status })
                      closeEditStoryConfig()
                      this.setState({ open: false })
                    }}
                  >
                    Update
                  </SmallButtonPrimary>
                </ButtonWrapper>

                <LinkButton
                  onClick={() => {
                    closeEditStoryConfig()
                    this.setState({ open: false })
                  }}
                >
                  Close
                </LinkButton>
              </ButtonsWrapper>
            </div>
          </StoryPillInner>
        </StoryPillContainer>
      )
    }

    return (
      <StoryPillContainer
        context="StoryEditIcon"
        hasMedia={hasMedia}
        left={isAnon ? '55px' : '15px'}
        onClick={() => {
          closeEditStoryConfig()
          this.setState({ open: true })
        }}
      >
        <StoryPillInner cursor={'pointer'}>
          <Wrench color="#464646" width="20px" height="20px" />
        </StoryPillInner>
      </StoryPillContainer>
    )
  }
}

class StoryPills extends Component {
  state = { editStoryConfigOpen: false }

  render() {
    const { editStoryConfigOpen } = this.state
    const {
      dark,
      hasMedia,
      handleStoryEditToggle,
      isFeatureStory,
      isOppMasonry,
      position,
      storyBeneficiaryLogo,
      storyBeneficiaryName,
      storyBeneficiaryPath,
      storyBeneficiaryStatus,
      storyLikesCount,
      userLikesStory
    } = this.props

    // do not force relative even in opp masonry when explicitly provided an absolute position
    const forceRelative = position === 'absolute' ? false : isOppMasonry || this.props.forceRelative

    const loggedIn = isLoggedIn()
    if (!loggedIn) {
      return (
        <StoryPillsWrapper
          dark={dark}
          forceRelative={forceRelative}
          hasMedia={hasMedia}
          isFeatureStory={isFeatureStory}
          position={position}
        >
          <Tooltip
            content={'Login to like stories'}
            displayFlex
            forceShow
            minWidth
            position="right"
            tinyTooltip
          >
            <LikeStoryIcon
              {...this.props}
              disabled
              storyLikesCount={storyLikesCount}
              userLikesStory={userLikesStory}
            />
          </Tooltip>

          <PointsIcon {...this.props} />

          <StoryBeneficiaryIcon
            storyBeneficiaryLogo={storyBeneficiaryLogo}
            storyBeneficiaryName={storyBeneficiaryName}
            storyBeneficiaryPath={storyBeneficiaryPath}
            storyBeneficiaryStatus={storyBeneficiaryStatus}
          />
        </StoryPillsWrapper>
      )
    }

    const editToggleValue = !editStoryConfigOpen

    return (
      <StoryPillsWrapper
        dark={dark}
        editStoryConfigOpen={editStoryConfigOpen}
        forceRelative={forceRelative}
        hasMedia={hasMedia}
        isFeatureStory={isFeatureStory}
        position={position}
      >
        <LikeStoryIcon
          {...this.props}
          storyLikesCount={storyLikesCount}
          userLikesStory={userLikesStory}
        />

        <PrivateStoryHint {...this.props} />

        <StoryEditIcon
          closeEditStoryConfig={() => {
            if (handleStoryEditToggle) {
              handleStoryEditToggle({ isEditingStory: editToggleValue })
            }

            return this.setState({ editStoryConfigOpen: editToggleValue })
          }}
          isFeatureStory={isFeatureStory}
          {...this.props}
        />

        <PointsIcon {...this.props} />

        <StoryBeneficiaryIcon
          storyBeneficiaryLogo={storyBeneficiaryLogo}
          storyBeneficiaryName={storyBeneficiaryName}
          storyBeneficiaryPath={storyBeneficiaryPath}
          storyBeneficiaryStatus={storyBeneficiaryStatus}
        />

        <MoreOptionsIcon {...this.props} />
      </StoryPillsWrapper>
    )
  }
}

export default StoryPills
