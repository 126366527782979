// env
const prodUrl = 'https://api.browniepoints.africa'
const stagingUrl = 'https://staging-api.browniepoints.africa'
const devUrl = process.env.BACKEND_URL
const env = process.env.REDUX_ENV || process.env.NODE_ENV

function determineBaseUrl() {
  switch (env) {
    case 'development':
      return devUrl

    case 'staging':
      return stagingUrl

    case 'production':
      // hack for Netlify, otherwise it attempts to fetch prod DB for a staging ENV
      return window.location.href.indexOf('sef-develop') > -1 ? stagingUrl : prodUrl

    default:
      return devUrl
  }
}

const baseUrl = determineBaseUrl()

export default baseUrl
