import { createActions, handleActions } from 'redux-actions'

// helpers
import { startLoading } from './_helpers'

export const CREATE = 'applications/CREATE'
export const CREATE_FAILURE = 'applications/CREATE_FAILURE'
export const CREATE_SUCCESS = 'applications/CREATE_SUCCESS'
export const CREATE_FOR_CAMPAIGN = 'applications/CREATE_FOR_CAMPAIGN'
export const CREATE_FOR_CAMPAIGN_FAILURE = 'applications/CREATE_FOR_CAMPAIGN_FAILURE'
export const CREATE_FOR_CAMPAIGN_SUCCESS = 'applications/CREATE_FOR_CAMPAIGN_SUCCESS'
export const ENROL_FOR_CAMPAIGN = 'applications/ENROL_FOR_CAMPAIGN'
export const ENROL_FOR_CAMPAIGN_FAILURE = 'applications/ENROL_FOR_CAMPAIGN_FAILURE'
export const ENROL_FOR_CAMPAIGN_SUCCESS = 'applications/ENROL_FOR_CAMPAIGN_SUCCESS'
export const FETCH_APPLICATION = 'applications/FETCH_APPLICATION'
export const FETCH_APPLICATION_FAILURE = 'applications/FETCH_APPLICATION_FAILURE'
export const FETCH_APPLICATION_SUCCESS = 'applications/FETCH_APPLICATION_SUCCESS'
export const FETCH_APPLICATIONS = 'applications/FETCH_APPLICATIONS'
export const FETCH_OWN_APPLICATIONS = 'applications/FETCH_OWN_APPLICATIONS'
export const FETCH_FAILURE = 'applications/FETCH_FAILURE'
export const FETCH_SUCCESS = 'applications/FETCH_SUCCESS'
export const LOGOUT_SUCCESS = 'authentication/LOGOUT_SUCCESS'

export const {
  applications: {
    create,
    createSuccess,
    createFailure,
    createForCampaign,
    createForCampaignSuccess,
    createForCampaignFailure,
    enrolForCampaign,
    enrolForCampaignSuccess,
    enrolForCampaignFailure,
    fetchApplication,
    fetchApplicationSuccess,
    fetchApplicationFailure,
    fetchApplications,
    fetchOwnApplications,
    fetchSuccess,
    fetchFailure
  }
} = createActions({
  [CREATE]: undefined,
  [CREATE_FAILURE]: undefined,
  [CREATE_SUCCESS]: undefined,
  [CREATE_FOR_CAMPAIGN]: undefined,
  [CREATE_FOR_CAMPAIGN_FAILURE]: undefined,
  [CREATE_FOR_CAMPAIGN_SUCCESS]: undefined,
  [ENROL_FOR_CAMPAIGN]: undefined,
  [ENROL_FOR_CAMPAIGN_FAILURE]: undefined,
  [ENROL_FOR_CAMPAIGN_SUCCESS]: undefined,
  [FETCH_APPLICATION]: undefined,
  [FETCH_APPLICATION_FAILURE]: undefined,
  [FETCH_APPLICATION_SUCCESS]: undefined,
  [FETCH_APPLICATIONS]: undefined,
  [FETCH_OWN_APPLICATIONS]: undefined,
  [FETCH_SUCCESS]: undefined,
  [FETCH_FAILURE]: undefined
})

const initialState = {
  data: [],
  donationDetailId: null,
  errorMessage: null,
  hasError: false,
  isLoading: false,
  isSubmitting: false,
  isSuccess: false,
  postApplicationRedirectUrl: null,
  singleApplicationData: {}
}

export default handleActions(
  {
    [CREATE]: () => ({
      errorMessage: null,
      hasError: false,
      isLoading: true,
      isSubmitting: true,
      isSuccess: false,
      donationDetailId: null,
      postApplicationRedirectUrl: null
    }),
    [CREATE_SUCCESS]: (state, { payload }) => ({
      ...state,
      hasError: false,
      errorMessage: null,
      isLoading: false,
      isSubmitting: false,
      isSuccess: true,
      donationDetailId: payload.donationDetailId,
      postApplicationRedirectUrl: payload.postApplicationRedirectUrl
    }),
    [CREATE_FAILURE]: (state, { payload }) => ({
      ...state,
      errorMessage: payload.errorMessage,
      hasError: true,
      isLoading: false,
      isSubmitting: false,
      isSuccess: false,
      donationDetailId: null,
      postApplicationRedirectUrl: null
    }),
    [CREATE_FOR_CAMPAIGN]: () => ({
      isLoading: true,
      hasError: false,
      isSubmitting: true,
      isSuccess: false,
      errorMessage: null
    }),
    [CREATE_FOR_CAMPAIGN_FAILURE]: (state, { payload }) => ({
      ...state,
      isLoading: false,
      hasError: true,
      isSuccess: false,
      isSubmitting: false,
      errorMessage: payload.errorMessage
    }),
    [CREATE_FOR_CAMPAIGN_SUCCESS]: state => ({
      ...state,
      isLoading: false,
      hasError: false,
      isSuccess: true,
      isSubmitting: false,
      errorMessage: null
    }),
    [ENROL_FOR_CAMPAIGN]: () => ({
      isLoading: true,
      hasError: false,
      isSubmitting: true,
      isSuccess: false,
      errorMessage: null
    }),
    [ENROL_FOR_CAMPAIGN_FAILURE]: state => ({
      ...state,
      isLoading: false,
      hasError: true,
      isSubmitting: false,
      isSuccess: false
    }),
    [ENROL_FOR_CAMPAIGN_SUCCESS]: state => ({
      ...state,
      isLoading: false,
      hasError: false,
      isSubmitting: false,
      isSuccess: true,
      errorMessage: null
    }),
    [FETCH_APPLICATION]: startLoading,
    [FETCH_APPLICATION_FAILURE]: state => ({
      ...state,
      singleApplicationData: {},
      isLoading: false,
      hasError: true,
      isSuccess: false,
      errorMessage: 'whoops'
    }),
    [FETCH_APPLICATION_SUCCESS]: (state, { payload }) => ({
      ...state,
      singleApplicationData: payload,
      isLoading: false,
      hasError: false,
      isSuccess: true,
      errorMessage: null
    }),
    [FETCH_APPLICATIONS]: () => startLoading,
    [FETCH_OWN_APPLICATIONS]: () => startLoading,
    [FETCH_FAILURE]: state => ({
      ...state,
      isLoading: false,
      hasError: true,
      data: []
    }),
    [FETCH_SUCCESS]: (state, { payload }) => ({
      ...state,
      isLoading: false,
      hasError: false,
      data: payload
    }),
    [LOGOUT_SUCCESS]: () => initialState
  },
  initialState
)
