import React from 'react'
import styled from 'styled-components'
import { Link } from '@reach/router'

// utils
import th from 'utils/themeHelper'

// config
import l from 'config/localization'

// assets
import userIcon from 'assets/images/user.svg'

// Load components synchronously
import ConditionalDisplay from 'components/ConditionalDisplay/ConditionalDisplay'
import LinkButton from 'components/SmallButtonPrimary/LinkButton'

const Card = styled.div`
  background: white;
  padding: 15px;
  border-radius: 3px;
  border: 1px solid ${th('borders.default')};
  margin-bottom: 15px;
`

const Heading = styled.h4`
  margin: 0;
`

const Actions = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding-top: 1em;
`

const ActionLink = styled(Link)`
  text-decoration: none;
  margin-left: 15px;

  &:first-child {
    margin-left: 0;
  }

  @media screen and (max-width: 769px) {
    margin-bottom: 12px;
  }
`

const Info = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 10px 0;

  @media screen and (max-width: 769px) {
    flex-direction: column;
  }
`

const InfoGroup = styled.div`
  flex: 4;
  margin-top: 20px;

  @media screen and (min-width: 769px) {
    margin: 0;
  }
`

const TeamAndContactInfo = styled.div`
  flex: 1;
`

const TeamLeadInfoWrapper = styled.div`
  color: ${props => props.theme.text.light};
  font-size: 12px;
`

const TeamLeadInfoHeader = styled.div`
  color: ${props => props.theme.secondary.base};
  margin-bottom: 6px;
`

const TeamleadInfoValue = styled.div`
  margin: 0 0 4px;
`

const StatsWrapper = styled.div`
  display: flex;
  margin: 10px 0;
`

const StatWrapper = styled.div`
  margin-right: 15px;
`

const Stat = styled.span`
  color: ${props => props.theme.secondary.base};
  font-family: ${th('fonts.bold')};
`

export const AvatarWrapper = styled.div`
  background: ${props => props.theme.backgrounds.dark};
  border: 1px solid ${th('borders.default')};
  border-radius: 50%;
  display: inline-block;
  margin-right: 20px;
  overflow: hidden;
  width: 100px;
  height: 100px;
  position: relative;
`

export const Avatar = styled.img`
  height: 100%;
  width: 100px;
  background: ${props => (props.hasAvatar ? th('backgrounds.dark') : 'white')};
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  object-fit: cover;
  color: white;
  text-align: center;
  font-size: 12px;
  line-height: 100px;
`

const PersonCard = ({ person, team }) => {
  const {
    id,
    f_name,
    l_name,
    opportunity_ids_of_attended = [],
    checked_in_count,
    team_name,
    email,
    mobile_number,
    avatar_url,
    roleType
  } = person

  const isTeamLead = team ? team.team_lead_id === person.id : false
  const roleName = isTeamLead ? 'Team lead' : roleType

  const opps = opportunity_ids_of_attended.length
  const displayableName = f_name ? `${f_name} ${l_name}` : email

  return (
    <Card>
      <Info>
        <AvatarWrapper>
          <Avatar src={avatar_url || userIcon} hasAvatar={avatar_url} alt="member avatar" />
        </AvatarWrapper>

        <InfoGroup>
          <Heading>{displayableName}</Heading>

          <StatsWrapper>
            <StatWrapper>
              <Stat>{opps}</Stat> {opps === 1 ? l('OPP') : l('OPPS')}
            </StatWrapper>

            <StatWrapper>
              <Stat>{checked_in_count}</Stat> {checked_in_count === 1 ? 'good deed' : 'good deeds'}
            </StatWrapper>
          </StatsWrapper>

          <Actions>
            <ActionLink to={`/dashboard/people/${id}`}>
              <LinkButton>Activity</LinkButton>
            </ActionLink>

            <ActionLink to={`/dashboard/manage-person/${id}`}>
              <LinkButton>Edit details</LinkButton>
            </ActionLink>
          </Actions>
        </InfoGroup>

        <TeamAndContactInfo>
          <TeamLeadInfoWrapper>
            <ConditionalDisplay displayWhen={[roleType]}>
              <TeamLeadInfoHeader>{roleName}</TeamLeadInfoHeader>
            </ConditionalDisplay>

            <ConditionalDisplay displayWhen={[team_name]}>
              <TeamLeadInfoHeader>Team {team_name}</TeamLeadInfoHeader>
            </ConditionalDisplay>

            <TeamleadInfoValue>{email}</TeamleadInfoValue>
            <TeamleadInfoValue>{mobile_number}</TeamleadInfoValue>
          </TeamLeadInfoWrapper>
        </TeamAndContactInfo>
      </Info>
    </Card>
  )
}

export default PersonCard
