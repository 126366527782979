import styled from 'styled-components'

// utils
import th from 'utils/themeHelper'

export default styled.div`
  .react-rater-star {
    font-size: 50px;
    margin-right: 10px;

    &.will-be-active {
      color: ${th('secondary.base')} !important;
    }

    &.is-active {
      color: ${th('primary.base')} !important;
    }
  }
`
