import React from 'react'
import { useMedia } from 'react-use'
import styled from 'styled-components'
import { debounce } from 'lodash'

// config
import { isDgmt } from 'config/localization'

// utils
import th from '../../utils/themeHelper'

// Icons
import { Search } from '../Icons/Icons'
import { Spinner } from '@styled-icons/evil/Spinner'

export const Wrapper = styled.div`
  display: flex;
  flex: ${props => props.flex || 'inherit'};
  flex-direction: row;
  margin-bottom: ${props => (props.marginBottom ? '10px' : 'initial')};
  position: relative;

  @media screen and (max-width: 769px) {
    flex: ${props => props.flex || '1'};
  }

  @media screen and (min-width: 700px) {
    margin-right: ${props => (props.marginRight ? '10px' : '0')};
    min-width: ${props => (props.minWidth ? '330px' : 'none')};
  }

  @media screen and (min-width: 900px) {
    min-width: ${props => (props.minWidth ? '400px' : 'none')};
  }
`

export const Input = styled.input`
  appearance: none;
  background: ${props => (props.disabled ? th('backgrounds.mid') : 'white')};
  border: 1px solid
    ${({ touched, hasError, theme }) => {
      if (isDgmt) {
        return hasError ? theme.borders.error : touched ? theme.borders.dark : theme.borders.medium
      }

      return hasError ? theme.borders.error : touched ? theme.borders.success : theme.borders.medium
    }};
  border-radius: 3px;
  color: ${th('text.dark')};
  font-family: ${th('fonts.light')};
  font-size: 14px;
  height: 45px;
  outline: none;
  padding: 8px;
  width: 100%;

  @media screen and (min-width: 600px) {
    font-size: 15px;
    padding: 15px;
  }

  &:hover {
    border: 1px solid
      ${({ hasError, theme }) => (hasError ? theme.borders.error : theme.borders.dark)};
  }

  &:focus {
    border: 1px solid
      ${({ hasError, theme }) => {
        if (isDgmt) {
          return hasError ? theme.borders.error : theme.borders.dark
        }

        return hasError ? theme.borders.error : theme.secondary.base
      }};
  }

  &::placeholder {
    color: ${th('text.mid')};
    white-space: pre-line;
  }
`

const SearchIcon = styled(Search)`
  background: ${props => (props.disabled ? th('backgrounds.mid') : 'white')};
  color: ${th('borders.default')};
  height: 30px;
  margin-top: -15px;
  position: absolute;
  right: 7px;
  top: 50%;
  width: 30px;
`

const SpinnerIcon = styled(Spinner)`
  background: ${props => (props.disabled ? th('backgrounds.mid') : 'white')};
  height: 30px;
  margin-top: -15px;
  position: absolute;
  right: 7px;
  top: 50%;
  width: 30px;

  -webkit-animation-name: spin;
  -webkit-animation-duration: 4000ms;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;
  -moz-animation-name: spin;
  -moz-animation-duration: 4000ms;
  -moz-animation-iteration-count: infinite;
  -moz-animation-timing-function: linear;
  -ms-animation-name: spin;
  -ms-animation-duration: 4000ms;
  -ms-animation-iteration-count: infinite;
  -ms-animation-timing-function: linear;
  -o-transition: rotate(3600deg);

  @-moz-keyframes spin {
    from {
      -moz-transform: rotate(0deg);
    }
    to {
      -moz-transform: rotate(360deg);
    }
  }

  @-webkit-keyframes spin {
    from {
      -webkit-transform: rotate(0deg);
    }
    to {
      -webkit-transform: rotate(360deg);
    }
  }

  @keyframes spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
`

export const SearchFilterLabel = styled.label`
  display: none;
`

const SearchFilter = ({
  className,
  disabled,
  flex,
  isLoading,
  marginBottom,
  marginRight,
  minWidth,
  onChange,
  placeholder,
  ...props
}) => {
  const autoFocus = useMedia('(min-width: 769px)')

  return (
    <Wrapper
      className={className}
      flex={flex}
      marginBottom={marginBottom}
      marginRight={marginRight}
      minWidth={minWidth}
    >
      <SearchFilterLabel>Search</SearchFilterLabel>
      <Input
        disabled={disabled}
        placeholder={placeholder || `Type to search ${props.label}`}
        name="searchFilter"
        type="text"
        onChange={debounce(onChange, 400)}
        {...props}
        autoFocus={autoFocus && props.autoFocus}
      />

      {!isLoading && <SearchIcon disabled={disabled} />}
      {isLoading && <SpinnerIcon disabled={disabled} />}
    </Wrapper>
  )
}

export default SearchFilter
