import React from 'react'

// Styles
import {
  CloseMenuIcon,
  MenuLabel,
  NavNotificationCount,
  NavNotificationWrapper,
  ProfileDropdown,
  ProfileDropdownSeparator,
  ProfileMenuWrapper,
  ProfilePicture,
  ProfileWrapper
} from './styles'

// utils
import { getLocalStorageValues } from 'utils/localStorageManager'

// icons
import { BellFill } from '@styled-icons/bootstrap/BellFill'
import { TrophyFill } from '@styled-icons/bootstrap/TrophyFill'

// Load components synchronously
import ConditionalDisplay from 'components/ConditionalDisplay/ConditionalDisplay'

const NotificationComponent = ({ notificationsCount }) => {
  if (!notificationsCount) return null

  return (
    <NavNotificationWrapper>
      <BellFill />
      <NavNotificationCount>{notificationsCount}</NavNotificationCount>
    </NavNotificationWrapper>
  )
}

const ChallengesComponent = ({ challengeCount }) => {
  if (!challengeCount) return null

  return (
    <NavNotificationWrapper>
      <TrophyFill />
      <NavNotificationCount>{challengeCount}</NavNotificationCount>
    </NavNotificationWrapper>
  )
}

const determineNotifications = ({ notifications }) => {
  if (!notifications) return 0
  if (!notifications.length) return 0

  return notifications.filter(n => n.status === 'Unread').length
}

const ProfileMenu = ({
  menuModalIsOpen,
  notifsModalIsOpen,
  notifications,
  toggleMenuModal,
  toggleNotifsModal,
  userData = {}
}) => {
  const user = getLocalStorageValues()
  const notificationsCount = determineNotifications({ notifications })

  const dashboardConfig = userData.dashboardConfig || {}
  const showNotifs = !!dashboardConfig.dash_sidenav_notifs
  const challengeCount = userData.availableCheckinsCount || 0

  const eitherMenuOpen = notifsModalIsOpen || menuModalIsOpen

  if (notifsModalIsOpen) {
    return (
      <ProfileMenuWrapper>
        <ProfileDropdown aria-label="Profile Dropdown" onClick={toggleMenuModal}>
          <MenuLabel menuModalIsOpen>{'CLOSE'}</MenuLabel>

          <ProfileWrapper>
            <CloseMenuIcon />
          </ProfileWrapper>
        </ProfileDropdown>
      </ProfileMenuWrapper>
    )
  }

  return (
    <ProfileMenuWrapper>
      <ConditionalDisplay displayWhen={[challengeCount || showNotifs]}>
        <ProfileDropdown
          aria-label="Profile Dropdown"
          firstSibling
          hasNotifications={challengeCount || notificationsCount}
          onClick={toggleNotifsModal}
        >
          {challengeCount && (
            <ChallengesComponent
              challengeCount={challengeCount}
              notificationsCount={notificationsCount}
            />
          )}

          {showNotifs && (
            <NotificationComponent
              challengeCount={challengeCount}
              notificationsCount={notificationsCount}
            />
          )}
        </ProfileDropdown>

        <ProfileDropdownSeparator />
      </ConditionalDisplay>

      <ProfileDropdown
        aria-label="Profile Dropdown"
        hasNotifications={challengeCount || notificationsCount}
        hasSibling={challengeCount || notificationsCount}
        onClick={toggleMenuModal}
      >
        <MenuLabel menuModalIsOpen={eitherMenuOpen}>{eitherMenuOpen ? 'CLOSE' : 'MENU'}</MenuLabel>

        <ProfileWrapper>
          {eitherMenuOpen ? <CloseMenuIcon /> : <ProfilePicture user={user} />}
        </ProfileWrapper>
      </ProfileDropdown>
    </ProfileMenuWrapper>
  )
}

export default ProfileMenu
