import React from 'react'
import styled from 'styled-components'
import th from '../../utils/themeHelper'
import logos from '../../utils/logos'
import Link from '../Link'
import isLoggedIn from '../../utils/auth'

const { smallLogoPath } = logos

const Nav = styled.div`
  height: 60px;
  padding: 0 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid ${th('borders.default')};

  @media screen and (min-width: 730px) {
    padding: 0 50px;
  }

  @media screen and (min-width: 1024px) {
    padding: 0 90px;
  }
`

const NavLink = styled.a`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 12.5px;
  color: ${th('text.dark')};
  cursor: pointer;

  &:hover {
    transition: 0.3s color;
    color: ${th('secondary.base')};
  }

  @media screen and (min-width: 730px) {
    padding: 0 20px;
  }
`

const Logo = styled.img`
  cursor: pointer;
  width: 150px;

  @media screen and (min-width: 480px) {
    height: 40px;
    width: auto;
  }
`

const DashboardTopNav = ({ logout }) => {
  const loggedIn = isLoggedIn()
  return (
    <Nav>
      <Link to="/">
        <Logo src={smallLogoPath} />
      </Link>

      {loggedIn && <NavLink onClick={() => logout()}>Sign out</NavLink>}
    </Nav>
  )
}

export default DashboardTopNav
