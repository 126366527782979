import React, { Component } from 'react'
import { connect } from 'react-redux'

// config
import constants from 'config/constants'

// redux
import * as authModule from '../../redux/modules/authentication'

// styles
import {
  ArrowTab,
  ArrowWrapper,
  FullScreenModal,
  FullScreenModalButtonsContainer,
  KeyboardTipContainer
} from './styles'

// assets
import noNotifsScene from 'assets/images/scenes/org-join-notify.svg'
import orgIcon from 'assets/images/icon-group.svg'
import userIcon from 'assets/images/user.svg'
import { CheckCircle } from 'components/Icons/Icons'
import { Close } from '@styled-icons/evil/Close'

// Load components synchronously
import SearchResultsContainer from 'components/SearchResultsContainer/SearchResultsContainer'

const isTouchDevice = () => {
  return 'ontouchstart' in window || navigator.maxTouchPoints > 0 || navigator.msMaxTouchPoints > 0
}

const KeyboardTip = () => {
  const hideKeyboardTip = isTouchDevice()
  if (hideKeyboardTip) return null

  return (
    <KeyboardTipContainer>
      <ArrowWrapper>
        Hit <ArrowTab>ESC</ArrowTab> to close this menu.
      </ArrowWrapper>
    </KeyboardTipContainer>
  )
}

const deriveNotificationAvatar = notification => {
  const isApprovedDono = [
    constants.NOTIFICATION_LABELS.GOODS_DONATION_CONFIRMATION_APPROVED,
    constants.NOTIFICATION_LABELS.MONEY_DONATION_CONFIRMATION_APPROVED
  ].includes(notification.label)
  if (isApprovedDono) {
    const avatarStyles = {
      background: '#379B7566',
      borderColor: 'none',
      borderRadius: '50%',
      borderWidth: '0',
      padding: '4px',
      height: '30px',
      width: '30px'
    }
    return { avatar: CheckCircle, avatarStyles }
  }

  const isRejectedDono = [
    constants.NOTIFICATION_LABELS.GOODS_DONATION_CONFIRMATION_REJECTED,
    constants.NOTIFICATION_LABELS.MONEY_DONATION_CONFIRMATION_REJECTED
  ].includes(notification.label)
  if (isRejectedDono) {
    const avatarStyles = {
      background: '#EB452599',
      borderColor: '#EB452599',
      borderRadius: '50%',
      borderWidth: '1px',
      height: '30px',
      width: '30px'
    }
    return { avatar: Close, avatarStyles }
  }

  const notifAvatar = notification.avatar || notification.target_avatar

  if (notification.is_org_notification) {
    return { avatar: notifAvatar || orgIcon }
  }

  return { avatar: notifAvatar || userIcon }
}

const buildSearchResults = ({ dismissNotification, notifications }) => {
  const tableColumns = [
    {
      collapse: true,
      name: 'Date',
      key: 'created_at',
      padding: '15px 10px',
      size: 'small'
    },
    {
      collapse: true,
      isImageColumn: true,
      key: 'avatar',
      name: ''
    },
    {
      isLink: true,
      key: 'title',
      link_key: 'notification_path',
      name: 'Notification',
      padding: '15px 10px',
      primaryCell: true
    }
  ]

  const searchResults = notifications.map(notification => {
    const rowActions = []

    const isDgmtSubmissionNotif =
      notification.label === constants.NOTIFICATION_LABELS.DGMT_NOTIFY_MNO_FOR_LISTING_REQUEST
    const isDgmtMnoListingRequest = [
      constants.NOTIFICATION_LABELS.DGMT_SIR_APPLICATION_SUBMITTED,
      constants.NOTIFICATION_LABELS.DGMT_SIR_APPLICATION_UPDATED
    ].includes(notification.label)
    const isDgmtPboVettingReminder =
      notification.label === constants.NOTIFICATION_LABELS.DGMT_PBO_VETTING_REMINDER

    const isTaggedNotif =
      notification.label === constants.NOTIFICATION_LABELS.STORY_BENEFICIARY_TAG_REQUEST
    const isChallengeReminderNotif =
      notification.label === constants.NOTIFICATION_LABELS.CHALLENGE_CHECKIN_REMINDER
    const isJoinInviteNotif = notification.label === constants.NOTIFICATION_LABELS.ORG_JOIN_INVITE
    const isJoinRequestNotif = notification.label === constants.NOTIFICATION_LABELS.ORG_JOIN_REQUEST
    const isDonoConfirmReqNotif = [
      constants.NOTIFICATION_LABELS.MONEY_DONATION_CONFIRMATION_REQUEST,
      constants.NOTIFICATION_LABELS.GOODS_DONATION_CONFIRMATION_REQUEST
    ].includes(notification.label)

    if (isDgmtPboVettingReminder && notification.status === 'Unread') {
      rowActions.push({
        name: 'Manage Verification',
        isNonDeleteAction: true,
        link: notification.notification_path
      })
    } else if (isDgmtMnoListingRequest && notification.status === 'Unread') {
      rowActions.push({
        name: 'Manage Request',
        isNonDeleteAction: true,
        link: notification.notification_path
      })
    } else if (isDgmtSubmissionNotif && notification.status === 'Unread') {
      rowActions.push({
        isNonDeleteAction: true,
        name: 'Manage application',
        link: notification.notification_path
      })
    } else if (isChallengeReminderNotif && notification.status === 'Unread') {
      rowActions.push({
        entityId: notification.id,
        name: 'Check-in',
        link: notification.notification_path
      })
    } else if (isTaggedNotif && notification.status === 'Unread') {
      rowActions.push({
        name: 'Manage Story',
        isNonDeleteAction: true,
        link: notification.notification_path
      })
    } else if (isDonoConfirmReqNotif && notification.status === 'Unread') {
      rowActions.push({
        name: 'Confirm Donation',
        isNonDeleteAction: true,
        link: notification.notification_path
      })
    } else if (isJoinRequestNotif && notification.status === 'Unread') {
      rowActions.push({
        name: 'Manage Request',
        isNonDeleteAction: true,
        link: notification.notification_path
      })
    } else if (isJoinInviteNotif && notification.status === 'Unread') {
      rowActions.push({
        name: 'Manage Invite',
        isNonDeleteAction: true,
        link: notification.notification_path
      })
    } else if (notification.status === 'Unread') {
      rowActions.push({
        action: dismissNotification,
        entityId: notification.id,
        name: 'Dismiss',
        isNonDeleteAction: true,
        link: `/dashboard/stories/$TABLE_ACTION_ROW_ID`
      })
    }

    const { avatar, avatarStyles } = deriveNotificationAvatar(notification)

    const notificationObject = {
      id: notification.id,
      key: notification.id,
      label: notification.label,
      status: notification.status,
      can_edit: true,
      created_at: notification.created_at,
      initiator_profile_url: notification.initiator_profile_url,
      title: notification.title,
      avatar,
      avatarStyles,
      notification_path: notification.notification_path,
      initiator: notification.initiator,
      rowActions
    }

    return notificationObject
  })

  return { searchResults, tableColumns }
}

class MenuModal extends Component {
  constructor(props) {
    super(props)

    this.dismissNotification = this.dismissNotification.bind(this)
  }

  closeMenu() {
    this.props.toggleMenu({ displayMenu: false })
  }

  dismissNotification = ({ row }) => {
    this.props.dismissNotification({ id: row.id })
  }

  render() {
    const { displayMenu, loggedIn, notifications, userData } = this.props

    if (!loggedIn) return null
    if (!displayMenu) return null
    if (!userData) return null

    const { actions, tableColumns, searchResults } = buildSearchResults({
      dismissNotification: this.dismissNotification,
      notifications
    })

    return (
      <FullScreenModal tabIndex="-1" ref={input => input && input.focus()}>
        <FullScreenModalButtonsContainer>
          <SearchResultsContainer
            actions={actions}
            cardType="DashNotificationCard"
            dashboard
            displayTable
            noResultsHeader={'You have no notifications that require further action.'}
            noResultsImage={noNotifsScene}
            noResultsImageWidth="30%"
            noResultsText={'When you receive notifications, they will appear here.'}
            searchResults={searchResults}
            tableColumns={tableColumns}
          />
        </FullScreenModalButtonsContainer>

        <KeyboardTip />
      </FullScreenModal>
    )
  }
}

const mapDispatchToProps = dispatch => ({
  logout: () => dispatch(authModule.logout())
})

const mapStateToProps = state => ({
  places: state.places.data.map(d => d.description),
  userData: state.authentication.userData,
  locationIsLoading: state.places.isLoading,
  anythingIsLoading:
    state.applications.isLoading ||
    state.campaigns.isLoading ||
    state.opportunities.isLoading ||
    state.organisations.isLoading ||
    state.people.isLoading,
  anythingIsSubmitting:
    state.applications.isSubmitting ||
    state.campaigns.isSubmitting ||
    state.opportunities.isSubmitting ||
    state.organisations.isSubmitting ||
    state.people.isSubmitting
})

export default connect(mapStateToProps, mapDispatchToProps)(MenuModal)
