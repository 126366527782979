import { call, put, takeLatest } from 'redux-saga/effects'
import { navigate } from '@reach/router'

import * as personalGoalsModule from '../modules/personalGoals'
import * as personalGoalsApi from '../apiModules/personalGoals'

// config and utils
import UrlBuilder from 'utils/urlBuilder'

function* fetchPersonalGoals({ payload }) {
  try {
    const response = yield call(personalGoalsApi.fetchPersonalGoals, payload)
    yield put(personalGoalsModule.fetchPersonalGoalsSuccess(response.data))
  } catch (err) {
    yield put(personalGoalsModule.fetchPersonalGoalsFailure())
  }
}

function* fetchPersonalGoal({ payload }) {
  try {
    const response = yield call(personalGoalsApi.fetchPersonalGoal, payload)
    yield put(personalGoalsModule.fetchPersonalGoalSuccess(response))
  } catch (err) {
    yield put(personalGoalsModule.fetchPersonalGoalFailure(err))
  }
}

function* createPersonalGoal({ payload }) {
  try {
    const formDataVals = new FormData()
    Object.keys(payload).map(value => {
      if (payload[value]) {
        formDataVals.append(value, payload[value])
      }
    })

    const { data } = yield call(personalGoalsApi.createPersonalGoal, formDataVals)
    yield put(personalGoalsModule.createPersonalGoalSuccess({ data }))

    navigate('/dashboard/goals')
  } catch (err) {
    yield put(personalGoalsModule.createPersonalGoalFailure())
  }
}

function* updatePersonalGoal({ payload }) {
  try {
    const formDataVals = new FormData()
    formDataVals.append('personalGoalId', payload.id)

    Object.keys(payload).map(fieldName => {
      const isFalseValue = typeof payload[fieldName] === 'boolean' && payload[fieldName] === false
      const useableValue = isFalseValue ? 'false' : payload[fieldName]

      if (useableValue) {
        formDataVals.append(fieldName, useableValue)
      }
    })

    const response = yield call(personalGoalsApi.updatePersonalGoal, formDataVals)
    yield put(personalGoalsModule.updatePersonalGoalSuccess(response))
  } catch (err) {
    yield put(personalGoalsModule.updatePersonalGoalFailure())
  }
}

function* deletePersonalGoal({ payload }) {
  try {
    yield call(personalGoalsApi.deletePersonalGoal, payload)
    yield put(personalGoalsModule.deletePersonalGoalSuccess(payload))
    UrlBuilder.displayDeleteSuccessMessage()
  } catch (err) {
    yield put(personalGoalsModule.deletePersonalGoalFailure())
  }
}

export const personalGoalsSagas = [
  takeLatest(personalGoalsModule.FETCH_PERSONAL_GOALS, fetchPersonalGoals),
  takeLatest(personalGoalsModule.UPDATE_PERSONAL_GOAL, updatePersonalGoal),
  takeLatest(personalGoalsModule.FETCH_PERSONAL_GOAL, fetchPersonalGoal),
  takeLatest(personalGoalsModule.DELETE_PERSONAL_GOAL, deletePersonalGoal),
  takeLatest(personalGoalsModule.CREATE_PERSONAL_GOAL, createPersonalGoal)
]
