import React from 'react'
import styled from 'styled-components'
import { Link } from '@reach/router'

// asssets
import { Location } from 'components/Icons/Icons'
import { SolidHeart } from 'components/Icons/Icons'

// icons
import { CalendarHeart } from '@styled-icons/boxicons-regular'
import { CheckCircleFill } from '@styled-icons/bootstrap/CheckCircleFill'
import { Label } from '@styled-icons/material/Label'
import { ListStars } from '@styled-icons/bootstrap/ListStars'
import { Percent } from '@styled-icons/bootstrap/Percent'
import { Settings } from 'styled-icons/ionicons-outline'

// config
import l from 'config/localization'

// utils
import th from 'utils/themeHelper'

// styles
import { getPillColors } from 'components/SearchResultsContainer/styles'
import DashCardWrapper from './DashCardWrapper'

// Load components synchronously
import ConditionalDisplay from 'components/ConditionalDisplay/ConditionalDisplay'
import ProgressBar from 'components/ProgressBar'

const ActionItem = styled.div`
  margin-right: 10px;

  &:last-of-type {
    margin-right: 0;
  }
`

const Actions = styled.div`
  align-items: center;
  display: flex;
  flex: 1;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin: 15px 10px 10px;
`

const ActionsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`

const OppCardLink = styled(Link)`
  border-color: ${th('backgrounds.mid')};
  border-radius: 4px;
  border-style: solid;
  border-width: 1px;
  color: ${th('text.dark')};
  margin: 0;
  padding: 8px 12px;
  text-align: center;
  text-decoration: none;
  white-space: nowrap;

  & > svg {
    width: 15px;
    margin-right: 10px;
  }
`

const Heading = styled.h4`
  display: inline-block;
  flex: 1;
  margin: 0;
  text-decoration: underline;
  vertical-align: middle;
`

const Info = styled.div`
  display: block;
  margin: 10px 15px 0;
  flex: 1;
`

const InfoFieldLabel = styled.div`
  font-family: ${th('fonts.bold')};
  margin-right: 10px;
`

const InfoFieldValue = styled.div`
  font-family: ${th('fonts.regular')};
  margin-left: 30px;
`

const InfoFieldIcon = styled.div`
  margin-right: 10px;

  & > svg {
    height: 20px;
  }
`

const InfoGroup = styled.div`
  min-width: 30%;
`

const InfoGroupRow = styled.div`
  color: ${th('text.mid')};
  line-height: 1.25em;
  margin-bottom: 15px;
`

const InfoGroupRowInner = styled.div`
  display: flex;
  word-break: break-word;
`

const OrgMainDetailsWrapper = styled.div`
  align-items: center;
  display: flex;
  flex: 1;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin: 0 15px 10px;
`

const Pill = styled.div`
  background: ${props => props.bgColor || th('backgrounds.default')};
  color: ${props => props.fontColor || th('text.dark')};
  border-radius: 3px;
  display: inline-block;
  font-size: 9px;
  margin-top: 2px;
  padding: 2px 5px;
  text-align: center;
  text-transform: uppercase;
  width: ${props => (props.fullWidth ? '100%' : 'auto')};
`

const ProgressBarWrapper = styled.div`
  margin: 5px 0 0 30px;
  width: 100%;
`

const DashOppCard = ({
  achieved_amount,
  beneficiary,
  goal_type,
  hasGodAdminRole,
  id,
  location,
  opp_category,
  outcome_target,
  start_date_iso,
  status,
  superAdminIds,
  title,
  userData
}) => {
  const canEdit = hasGodAdminRole
    ? true
    : superAdminIds && superAdminIds.length
    ? superAdminIds.includes(userData.id)
    : false

  const { bgColor, color } = getPillColors({
    key: 'status',
    value: status
  })

  const progressPercent = (achieved_amount / outcome_target) * 100

  return (
    <DashCardWrapper>
      <ActionsWrapper>
        <OrgMainDetailsWrapper>
          <Heading>{title}</Heading>
        </OrgMainDetailsWrapper>

        <Info>
          <InfoGroup>
            <InfoGroupRow>
              <InfoGroupRowInner>
                <InfoFieldIcon>
                  <Label />
                </InfoFieldIcon>
                <InfoFieldLabel>Goal type</InfoFieldLabel>
              </InfoGroupRowInner>

              <InfoGroupRowInner>
                <InfoFieldValue>{goal_type || 'N/A'}</InfoFieldValue>
              </InfoGroupRowInner>
            </InfoGroupRow>

            <InfoGroupRow>
              <InfoGroupRowInner>
                <InfoFieldIcon>
                  <SolidHeart />
                </InfoFieldIcon>
                <InfoFieldLabel>Beneficiary</InfoFieldLabel>
              </InfoGroupRowInner>

              <InfoGroupRowInner>
                <InfoFieldValue>{beneficiary || 'N/A'}</InfoFieldValue>
              </InfoGroupRowInner>
            </InfoGroupRow>

            <InfoGroupRow>
              <InfoGroupRowInner>
                <InfoFieldIcon>
                  <CheckCircleFill />
                </InfoFieldIcon>
                <InfoFieldLabel>Category</InfoFieldLabel>
              </InfoGroupRowInner>

              <InfoGroupRowInner>
                <InfoFieldValue>{opp_category || 'N/A'}</InfoFieldValue>
              </InfoGroupRowInner>
            </InfoGroupRow>

            <InfoGroupRow>
              <InfoGroupRowInner>
                <InfoFieldIcon>
                  <CalendarHeart />
                </InfoFieldIcon>
                <InfoFieldLabel>Date</InfoFieldLabel>
              </InfoGroupRowInner>

              <InfoGroupRowInner>
                <InfoFieldValue>{start_date_iso || 'N/A'}</InfoFieldValue>
              </InfoGroupRowInner>
            </InfoGroupRow>

            <InfoGroupRow>
              <InfoGroupRowInner>
                <InfoFieldIcon>
                  <Location />
                </InfoFieldIcon>
                <InfoFieldLabel>Location</InfoFieldLabel>
              </InfoGroupRowInner>

              <InfoGroupRowInner>
                <InfoFieldValue>{location || 'N/A'}</InfoFieldValue>
              </InfoGroupRowInner>
            </InfoGroupRow>

            <InfoGroupRow>
              <InfoGroupRow>
                <InfoGroupRowInner>
                  <InfoFieldIcon>
                    <CheckCircleFill />
                  </InfoFieldIcon>
                  <InfoFieldLabel>Status</InfoFieldLabel>
                </InfoGroupRowInner>

                <InfoGroupRowInner>
                  <InfoFieldValue>
                    <Pill bgColor={bgColor} fontColor={color}>
                      {status}
                    </Pill>
                  </InfoFieldValue>
                </InfoGroupRowInner>
              </InfoGroupRow>
            </InfoGroupRow>

            <InfoGroupRow>
              <InfoGroupRowInner>
                <InfoFieldIcon>
                  <Percent />
                </InfoFieldIcon>
                <InfoFieldLabel>Progress</InfoFieldLabel>
              </InfoGroupRowInner>

              <InfoGroupRowInner>
                <ProgressBarWrapper>
                  <ProgressBar committedPerc={progressPercent} noMargin />
                </ProgressBarWrapper>
              </InfoGroupRowInner>
            </InfoGroupRow>
          </InfoGroup>
        </Info>

        <Actions>
          <ActionItem>
            <OppCardLink to={`/dashboard/${l('ROUTE_OPPS')}/${id}/stories`}>
              <ListStars />
              <span>Stories</span>
            </OppCardLink>
          </ActionItem>

          <ConditionalDisplay displayWhen={[canEdit]}>
            <ActionItem>
              <OppCardLink to={`/dashboard/${l('ROUTE_OPPS')}/${id}/`}>
                <Settings />
                <span>Edit</span>
              </OppCardLink>
            </ActionItem>
          </ConditionalDisplay>
        </Actions>
      </ActionsWrapper>
    </DashCardWrapper>
  )
}

export default DashOppCard
