import React from 'react'

// config
import l from 'config/localization'
import constants from 'config/constants'

// assets
import orgIcon from 'assets/images/icon-group.svg'
import VerifiedIcon from 'assets/images/icons/verified-org.svg'

// styles
import {
  ActionLink,
  Actions,
  BeneficiaryPill,
  BeneficiaryName,
  Logo,
  Title,
  CardOverlay,
  CardContent,
  AssetWrapper,
  CardLinkWrapper,
  IconWrapper,
  ExpiryNotice,
  OpportunityDescription,
  OppStatusPill,
  OppStatusPillWrapper,
  VerificationWrapper,
  VerificationStatus,
  VerificationIcon
} from './styles'

// utils
import webPImage from 'utils/webPImage'
import thumbSource from 'utils/thumbSource'
import stringUtils from 'utils/string'

// Load components synchronously
import ConditionalDisplay from 'components/ConditionalDisplay/ConditionalDisplay'
import LinkButton from 'components/SmallButtonPrimary/LinkButton'
import SingleCard from 'components/SingleCard/SingleCard'

// imported constants
const userCtx = constants.CREATOR_CONTEXTS.USER

const determineEditLinkVisibility = ({
  benefactorCtx,
  oppAdmins,
  oppHosts,
  userId,
  hasGodAdminRole
}) => {
  if (hasGodAdminRole) return true

  const isUserCtx = benefactorCtx === userCtx
  if (isUserCtx) return true

  return [...oppAdmins, ...oppHosts].some(a => a.id === userId)
}

class DashboardOpportunityCard extends React.Component {
  deriveBeneficiaryName() {
    const { Beneficiary } = this.props
    const name = Beneficiary ? Beneficiary.name || '' : ''

    if (name.length <= 50) return name
    return `${Beneficiary.name.slice(0, 50)}...`
  }

  displayBeneficiaryVerification({ Beneficiary }) {
    if (!Beneficiary) return null
    if (!Beneficiary.is_verified) return null

    return (
      <VerificationWrapper>
        <VerificationIcon src={VerifiedIcon} />
        <VerificationStatus>Verified nonprofit</VerificationStatus>
      </VerificationWrapper>
    )
  }
  displayStatusPills({ status }) {
    const isDraft = status === constants.OPPORTUNITY_STATUSES.DRAFT
    const isCompleted = status === constants.OPPORTUNITY_STATUSES.COMPLETED

    if (isDraft) {
      return (
        <OppStatusPillWrapper>
          <OppStatusPill>DRAFT</OppStatusPill>
        </OppStatusPillWrapper>
      )
    }

    if (isCompleted) {
      return (
        <OppStatusPillWrapper>
          <OppStatusPill>COMPLETED</OppStatusPill>
        </OppStatusPillWrapper>
      )
    }
  }

  displayOrgInfoForOppCard({ Organisation, orgIcon }) {
    if (!Organisation) return null

    return (
      <BeneficiaryPill>
        <BeneficiaryName>{Organisation.name}</BeneficiaryName>
        <AssetWrapper>
          <Logo src={Organisation.logo_image || orgIcon} size={24} />
        </AssetWrapper>
      </BeneficiaryPill>
    )
  }

  displayBeneficiaryInfo({ Beneficiary }) {
    if (!Beneficiary) return null

    const beneficiaryLogo = Beneficiary ? Beneficiary.logo_image : null

    return (
      <BeneficiaryPill>
        <BeneficiaryName>{this.deriveBeneficiaryName()}</BeneficiaryName>
        <AssetWrapper>
          <Logo src={beneficiaryLogo || orgIcon} size={24} />
        </AssetWrapper>
      </BeneficiaryPill>
    )
  }

  render() {
    const {
      id,
      title,
      description,
      location,
      is_expired: isExpired,
      image_url: imgSrc,
      short_url: shortUrl,
      start_date: startDate,
      end_date: endDate,
      pvt_share_key: pvtShareKey,
      doesSupportWebP,
      Beneficiary,
      Organisation,
      oppAdmins = [],
      oppHosts = [],
      benefactorCtx,
      categories,
      userData,
      hasGodAdminRole,
      status
    } = this.props

    const userId = userData.id
    const imageUrl = doesSupportWebP ? webPImage(imgSrc) : imgSrc
    const thumbnail = thumbSource(imageUrl) || orgIcon
    const { splicedString } = stringUtils.trimText({ string: description, wordLimit: 40 })
    const oppUrl = pvtShareKey
      ? `/${l('ROUTE_OPPS')}/${id}?title=${shortUrl}&pvtShareKey=${pvtShareKey}`
      : `/${l('ROUTE_OPPS')}/${id}?title=${shortUrl}`

    const showEditLink = determineEditLinkVisibility({
      benefactorCtx,
      oppAdmins,
      oppHosts,
      userId,
      hasGodAdminRole
    })

    return (
      <SingleCard fullHeight>
        <ExpiryNotice expired={isExpired}>
          <CardOverlay src={thumbnail}>
            {this.displayStatusPills({ status })}
            {this.displayOrgInfoForOppCard({ Organisation, orgIcon })}
            {this.displayBeneficiaryVerification({ Beneficiary })}
            {this.displayBeneficiaryInfo({ Beneficiary })}
          </CardOverlay>
        </ExpiryNotice>

        <CardLinkWrapper noHoverEffect>
          <CardContent>
            <Title>{title}</Title>
            <OpportunityDescription description={splicedString} />

            <IconWrapper infoType={'Date'} value={startDate || 'Anytime'} endDate={endDate} />
            <IconWrapper infoType={'Location'} value={location || 'Anywhere'} dashboard />
            <IconWrapper infoType={'Categories'} value={categories} />
          </CardContent>

          <Actions>
            <ActionLink to={`/dashboard/${l('ROUTE_OPPS')}/${id}/stories`}>
              <LinkButton wide>Stories</LinkButton>
            </ActionLink>

            <ActionLink to={oppUrl}>
              <LinkButton wide>View {l('OPP')}</LinkButton>
            </ActionLink>

            <ConditionalDisplay displayWhen={[showEditLink]}>
              <ActionLink to={`/dashboard/${l('ROUTE_OPPS')}/${id}`}>
                <LinkButton wide>Edit details</LinkButton>
              </ActionLink>
            </ConditionalDisplay>
          </Actions>
        </CardLinkWrapper>
      </SingleCard>
    )
  }
}

export default DashboardOpportunityCard
