import React, { Fragment } from 'react'
import { Formik, Field } from 'formik'

// config
import constants from 'config/constants'
import l from 'config/localization'

// styles
import {
  CenteredModalHeader,
  CloseIcon,
  FormSectionButtonWrapper,
  FormSectionRow,
  ModalContainer,
  ScreenWrapper,
  TermsContainer
} from './styles'

// Load components synchronously
import CheckBoxGroup from 'components/CheckBoxGroup/CheckBoxGroup'
import CheckboxInput from 'components/CheckboxInput'
import ConditionalDisplay from 'components/ConditionalDisplay/ConditionalDisplay'
import FieldRow from 'components/FieldRow/FieldRow'
import FileUpload from 'components/FileUpload/FileUpload'
import FormProgressBars from 'components/FormProgressBars/FormProgressBars'
import NumberInput from 'components/NumberInput/NumberInput'
import PlacesContainer from 'components/PlacesContainer'
import RadioGroup from 'components/RadioGroup/RadioGroup'
import SearchInput from 'components/SearchInput/SearchInput'
import SmallButtonPrimary from 'components/SmallButtonPrimary/SmallButtonPrimary'
import TextInput from 'components/TextInput/TextInput'

const TermsLabel = () => <Fragment>I accept the additional terms & conditions</Fragment>

const deriveStateProgress = ({ customTerms, formValues, goalType, questions }) => {
  const hasQuestions = questions.length > 0
  const hasTermsOrQuestions = hasQuestions || !!customTerms
  const isMoneyOrGoodGivingType =
    goalType === constants.OPPORTUNITY_GOAL_TYPES.MONEY ||
    goalType === constants.OPPORTUNITY_GOAL_TYPES.GOODS

  if (isMoneyOrGoodGivingType) {
    const isOppDonation = formValues.donation_origin === 'opportunity'

    if (isOppDonation && hasTermsOrQuestions) {
      return { formProgressActiveStep: 2, formProgressSteps: 3 }
    }

    if (isOppDonation && hasTermsOrQuestions) {
      return { formProgressActiveStep: 1, formProgressSteps: 2 }
    }

    if (isOppDonation) {
      return { formProgressActiveStep: 1, formProgressSteps: 1 }
    }

    if (hasTermsOrQuestions) {
      return { formProgressActiveStep: 2, formProgressSteps: 3 }
    }

    return { formProgressActiveStep: 2, formProgressSteps: 3 }
  }

  if (hasTermsOrQuestions) {
    return { formProgressActiveStep: 1, formProgressSteps: 1 }
  }

  return { formProgressActiveStep: 1, formProgressSteps: 1 }
}

const deriveCallToActionLabel = ({ formProgressSteps, goalType }) => {
  if (formProgressSteps > 1) return 'Continue'
  if (goalType === constants.OPPORTUNITY_GOAL_TYPES.MONEY) return 'Continue'
  if (goalType === constants.OPPORTUNITY_GOAL_TYPES.GOODS) return 'Continue'

  if (goalType === constants.OPPORTUNITY_GOAL_TYPES.VOLUNTEERING) return 'Volunteer'
  if (goalType === constants.OPPORTUNITY_GOAL_TYPES.CHALLENGE) return 'Join'

  // Should never reach here
  return 'Continue'
}

const validate = values => {
  const errors = {}
  const { customTerms, questions } = values

  if (customTerms && !values.extra_terms) {
    errors.extra_terms = 'Please accept the additional terms first'
  }

  questions.forEach(q => {
    const isDoc = [
      constants.ADDITIONAL_QUESTION_TYPES.document,
      constants.ADDITIONAL_QUESTION_TYPES.Document
    ].includes(q.request_type)
    const isQuestion = !isDoc

    const answerOrDoc = values[`custom_signup_question_${q.id}`]
    const answerIsZero = typeof answerOrDoc === 'number' && '0' === `${answerOrDoc}`
    const answerExists = answerOrDoc || answerIsZero
    const requiredQuestion = q.is_required || q.isRequired

    if (isDoc) {
      if (!answerOrDoc && requiredQuestion) {
        errors[`custom_signup_question_${q.id}`] = 'Please attach a PDF of no more than 3mb'
      } else if (answerOrDoc && answerOrDoc.size / 1024 / 1024 > 3) {
        errors[`custom_signup_question_${q.id}`] = 'PDF size is more than 3mb'
      }
    } else if (isQuestion && requiredQuestion && !answerExists) {
      errors[`custom_signup_question_${q.id}`] = 'Please answer this question'
    }
  })

  return errors
}

const updateProcession = ({ questions, values }) => {
  // False because no need to update the state
  if (!questions) return false
  if (!questions.length) return false

  const requiredQuestions = questions.filter(q => q.is_required || q.isRequired)

  // True because no need to force user to answer unrequired questions
  if (!requiredQuestions.length) return true

  const hasMissingRequiredQuestions = requiredQuestions
    .map(q => values[`custom_signup_question_${q.id}`])
    .some(v => !v)

  // Prevent advancing if person has not answered all required questions
  if (hasMissingRequiredQuestions) return false

  // True because person has answered all required questions
  return true
}

const CustomTermsAndQuestionSection = ({
  advanceFromCustomQuestions,
  customTerms,
  extraInfoModalOpts,
  fetchPlaces,
  formValues,
  goalType,
  hideModal,
  isSubmitting,
  locationIsLoading,
  places,
  questions = []
}) => {
  const { formProgressActiveStep, formProgressSteps } = deriveStateProgress({
    customTerms,
    formValues,
    goalType,
    questions
  })

  const callToActionLabel = deriveCallToActionLabel({ formProgressSteps, goalType })

  const shouldSubmitForm = [
    constants.OPPORTUNITY_GOAL_TYPES.CHALLENGE,
    constants.OPPORTUNITY_GOAL_TYPES.VOLUNTEERING
  ].includes(goalType)

  return (
    <ScreenWrapper>
      <ModalContainer>
        <Formik
          initialValues={{ customTerms, formValues, questions }}
          enableReinitialize
          validate={validate}
          validateOnChange={false}
          validateOnBlur={false}
          onSubmit={values => {
            const updates = {
              extra_terms: values.extra_terms
            }

            questions.map(n => {
              updates[`custom_signup_question_${n.id}`] = values[`custom_signup_question_${n.id}`]
            })

            const shouldUpdateCustomQuestionProcession = updateProcession({ questions, values })

            if (shouldUpdateCustomQuestionProcession) {
              advanceFromCustomQuestions()
            }

            // For challenge and volunteer opps, there is no next modal so just submit form
            if (shouldSubmitForm) {
              extraInfoModalOpts.signUp({ ...formValues, ...updates })
            } else {
              extraInfoModalOpts.updateFormValues({ ...formValues, ...updates })
            }
          }}
          render={({ errors, handleSubmit, setFieldError, setFieldValue, values }) => {
            return (
              <form onSubmit={handleSubmit}>
                <div>
                  <CloseIcon
                    dark
                    onClick={() => {
                      const updates = {
                        extra_terms: undefined
                      }

                      Object.keys(formValues)
                        .filter(n => n.match('custom_signup_question_'))
                        .map(n => {
                          updates[n] = undefined
                        })

                      extraInfoModalOpts.updateFormValues({
                        ...extraInfoModalOpts.formValues,
                        ...updates
                      })
                      hideModal()
                    }}
                    size={30}
                  />

                  <CenteredModalHeader>Additional touches</CenteredModalHeader>

                  <FormProgressBars active={formProgressActiveStep} steps={formProgressSteps} />

                  <FormSectionRow>
                    {questions.map((q, idx) => {
                      const requiredQuestion = q.is_required || q.isRequired
                      const isDoc = [
                        constants.ADDITIONAL_QUESTION_TYPES.document,
                        constants.ADDITIONAL_QUESTION_TYPES.Document
                      ].includes(q.request_type)
                      const isLocation =
                        q.request_type === constants.ADDITIONAL_QUESTION_TYPES.Location
                      const isSingleChoiceQuestion =
                        q.request_type === constants.ADDITIONAL_QUESTION_TYPES.SingleChoice
                      const isMultipleChoiceQuestion =
                        q.request_type === constants.ADDITIONAL_QUESTION_TYPES.MultipleChoice

                      const QuestionComponent = isDoc
                        ? FileUpload
                        : q.request_type === constants.ADDITIONAL_QUESTION_TYPES.Text
                        ? TextInput
                        : q.request_type === constants.ADDITIONAL_QUESTION_TYPES.Integer
                        ? NumberInput
                        : q.request_type === constants.ADDITIONAL_QUESTION_TYPES.integer_question
                        ? NumberInput
                        : TextInput

                      if (isMultipleChoiceQuestion) {
                        return (
                          <FieldRow key={q.id}>
                            <Field
                              requiredField={requiredQuestion}
                              name={`custom_signup_question_${q.id}`}
                              onChange={val => {
                                const existingVals = values[`custom_signup_question_${q.id}`] || []
                                const newVals = [...existingVals, val]
                                setFieldValue(`custom_signup_question_${q.id}`, newVals)
                              }}
                              options={q.options}
                              component={CheckBoxGroup}
                              title={q.label}
                            />
                          </FieldRow>
                        )
                      }

                      if (isSingleChoiceQuestion) {
                        return (
                          <FieldRow key={q.id}>
                            <Field
                              requiredField={requiredQuestion}
                              name={`custom_signup_question_${q.id}`}
                              onChange={val => setFieldValue(`custom_signup_question_${q.id}`, val)}
                              options={q.options}
                              component={RadioGroup}
                              title={q.label}
                            />
                          </FieldRow>
                        )
                      }

                      if (isLocation) {
                        return (
                          <FieldRow key={q.id}>
                            <PlacesContainer>
                              {() => (
                                <SearchInput
                                  suggestions={places}
                                  field={{ name: `custom_signup_question_${q.id}` }}
                                  form={{ errors: errors }}
                                  name={`custom_signup_question_${q.id}`}
                                  isLoading={locationIsLoading}
                                  locationValue={values[`custom_signup_question_${q.id}`]}
                                  locationFieldName={values[`custom_signup_question_${q.id}`]}
                                  clearLocation={() =>
                                    setFieldValue(`custom_signup_question_${q.id}`, '')
                                  }
                                  label={q.label}
                                  placeholder="Type and select an address or place"
                                  value={values[`custom_signup_question_${q.id}_input`]}
                                  onSuggestionsFetchRequested={({ value }) => fetchPlaces(value)}
                                  onSuggestionsClearRequested={() => null}
                                  getSuggestionValue={value => value}
                                  onSuggestionSelected={(_event, { suggestion }) => {
                                    setFieldValue(`custom_signup_question_${q.id}_input`, '')
                                    setFieldValue(
                                      `custom_signup_question_${q.id}`,
                                      suggestion || ''
                                    )
                                    setFieldError(`custom_signup_question_${q.id}`, null)
                                  }}
                                  onChange={(_e, { method, newValue }) => {
                                    if (method === 'type') {
                                      setFieldValue(
                                        `custom_signup_question_${q.id}_input`,
                                        newValue
                                      )
                                    }
                                  }}
                                  requiredField={requiredQuestion}
                                />
                              )}
                            </PlacesContainer>
                          </FieldRow>
                        )
                      }

                      if (isDoc) {
                        return (
                          <FieldRow key={q.id}>
                            <Field
                              accept="application/pdf"
                              buttonText="Choose file"
                              component={QuestionComponent}
                              cropOpts={{ aspect: 'none' }}
                              disabled={isSubmitting}
                              index={idx}
                              keepLabelVisible
                              label={q.label}
                              name={`custom_signup_question_${q.id}`}
                              onChange={event => {
                                setFieldValue(
                                  `custom_signup_question_${q.id}`,
                                  event.target.files[0]
                                )
                              }}
                              placeholder="Upload a document (PDF only)"
                              requiredField={requiredQuestion}
                              setFieldError={setFieldError}
                              setFieldValue={setFieldValue}
                            />
                          </FieldRow>
                        )
                      }

                      return (
                        <FieldRow key={q.id}>
                          <Field
                            component={QuestionComponent}
                            index={idx}
                            keepLabelVisible
                            label={q.label}
                            maxLength="360"
                            name={`custom_signup_question_${q.id}`}
                            placeholder="Your answer"
                            requiredField={requiredQuestion}
                            type={q.request_type}
                          />
                        </FieldRow>
                      )
                    })}
                  </FormSectionRow>

                  <ConditionalDisplay displayWhen={[customTerms]}>
                    <FormSectionRow>
                      <p>This {l('OPP')} has additional terms and conditions.</p>
                      <TermsContainer>{customTerms}</TermsContainer>
                      <Field name="extra_terms" label={<TermsLabel />} component={CheckboxInput} />
                    </FormSectionRow>
                  </ConditionalDisplay>

                  <FormSectionButtonWrapper>
                    <SmallButtonPrimary type="submit">{callToActionLabel}</SmallButtonPrimary>
                  </FormSectionButtonWrapper>
                </div>
              </form>
            )
          }}
        />
      </ModalContainer>
    </ScreenWrapper>
  )
}

export default CustomTermsAndQuestionSection
