import axios from 'axios'

// config
import baseUrl from '../config'

// utils
import cleanPayload from 'utils/cleanPayload'

export const fetchMessagingData = async (payload = {}) => {
  const cleanedPayload = cleanPayload(payload)
  const response = await axios(`${baseUrl}/mc`, {
    withCredentials: true,
    method: 'GET',
    responseType: 'json',
    params: { ...cleanedPayload }
  })

  return response.data
}

export const triggerMessageDispatch = async data => {
  const response = await axios({
    withCredentials: true,
    method: 'post',
    url: `${baseUrl}/trigger-message`,
    data
  })

  return response.data
}
