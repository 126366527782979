import { createSelector } from 'reselect'

const selectData = state => state.places
const selectedPlace = () =>
  createSelector(
    selectData,
    state => state.selectedPlace
  )

export const placesSelectors = {
  selectData,
  selectedPlace
}

export default placesSelectors
