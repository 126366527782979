import React from 'react'
import { withTheme } from 'styled-components'
import { InformationBoxOuter, IconWrapper } from './styles'

const InformationBox = ({ children, color, fill, icon: Icon, isCalendar, theme, ...restProps }) => (
  <InformationBoxOuter {...restProps}>
    {!!Icon && (
      <IconWrapper fill={fill} isCalendar={isCalendar}>
        <Icon height={30} width={30} color={color || theme.secondary.base} />
      </IconWrapper>
    )}
    {children}
  </InformationBoxOuter>
)

export default withTheme(InformationBox)
