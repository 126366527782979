import React, { Component } from 'react'
import styled from 'styled-components'

// icons
import { PlusSign } from 'components/Icons/Icons'

// config
import { Error, Label, RequiredTag, SubLabel, Success } from '../InputLabels/InputLabels'
import th from 'utils/themeHelper'

// Load components synchronously
import ConditionalDisplay from 'components/ConditionalDisplay/ConditionalDisplay'
import LinkButton from 'components/SmallButtonPrimary/LinkButton'

const borderColor = ({ requiredField, hasError, theme }) => {
  if (hasError) {
    return theme.borders.error
  } else if (requiredField) {
    return theme.borders.required
  } else {
    return theme.borders.medium
  }
}

const borderWidth = ({ requiredField, active, hasError }) => {
  if (active) {
    return '1px'
  } else if (hasError) {
    return '2px'
  } else if (requiredField) {
    return '2px'
  } else {
    return '1px'
  }
}

const paddingWidth = ({ active, hasError }) => {
  if (active) {
    return '10px 5px 0'
  } else if (hasError) {
    return '10px 5px 0'
  } else {
    return '10px 5px 0'
  }
}

const Wrapper = styled.div`
  min-width: ${props => (props.forceFullWidth ? '100%' : '500px')};
  position: relative;

  /* Prevent blue highlighting of page numbers from multiple clicking in browsers */
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
`

const LabelWrapper = styled.div``

const Image = styled.div`
  background-image: url(${props => props.src});
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
  height: 50px;
  width: 100%;
`

const SmallImage = styled.div`
  background-color: white;
  background-image: url(${props => props.src});
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 50%;
  border-style: solid;
  border-width: 1px;
  height: 30px;
  width: 30px;
`

const SlideArrow = styled.a`
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  font-size: 26px;
  padding: 10px;
  position: relative;
  z-index: 3;
`

const SlideArrowsWrapper = styled.div`
  align-items: center;
  bottom: 0;
  display: flex;
  height: ${props => (props.shortOptions ? '72px' : '97px')};
  justify-content: space-between;
  position: absolute;
  width: 100%;
`

const IconWrapper = styled.div`
  height: 50px;
  padding: 0;
  width: 50px;

  > svg {
    fill: ${th('text.dark')};
    height: 100%;
    width: 100%;
  }
`

const ShortSmallImage = styled.div`
  background-color: white;
  background-image: url(${props => props.src});
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  border-color: ${props => (props.active ? 'white' : th('text.light'))};
  border-radius: 50%;
  border-style: solid;
  border-width: 1px;
  height: 30px;
  width: 30px;
`

const ShortIconWrapper = styled.div`
  background-color: ${props => (props.noBorder ? 'none' : 'white')};
  border-radius: 50%;
  border-style: solid;
  border-width: ${props => (props.noBorder ? '0' : '1px')};
  border-color: ${props => (props.active ? 'white' : th('text.light'))};
  height: 30px;
  padding: ${props => props.padding || '0'};
  width: 30px;

  > svg {
    fill: ${props => props.fill || th('text.dark')};
    height: 100%;
    width: 100%;
  }
`

const ShortSwipeOptionImage = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  padding: 5px 0 5px 8px;
`

const SwipeOptionImage = styled.div`
  align-items: center;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  display: flex;
  flex: 1;
  justify-content: center;
  margin-bottom: 5px;
  padding: ${paddingWidth};
`

const SwipeOptionLabel = styled.div`
  align-items: center;
  display: flex;
  flex: 1;
  font-size: 12px;
  justify-content: center;
  padding: 0 5px 5px;

  @media screen and (max-width: 600px) {
    font-size: 12px;
  }
`

const ShortSwipeOptionsWrapper = styled.div`
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  padding: 8px;
`

const ShortSwipeOptionLabel = styled.div`
  color: ${th('text.mid')};
  font-size: 12px;
  padding: 0 0 ${props => (props.hasSubLabel ? '3px' : '0')};

  @media screen and (min-width: 769px) {
    font-size: 15px;
  }
`

const ShortSwipeOptionSubLabel = styled.div`
  color: ${th('text.mid')};
  font-family: ${th('fonts.light')};
  font-size: 12px;
  padding: 0;
`

const ShortSwipeOptionDetails = styled.div`
  border-radius: 3px;
  display: flex;
  flex-direction: row;
  height: 100%;
  width: 100%;
`

const SwipeOptionDetails = styled.div`
  border-radius: 3px;
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
`

const SwipeSelectContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: ${props => (props.mobileWrapped ? 'wrap' : 'no-wrap')};
  margin: 10px 0;
  overflow-x: auto;
  position: relative;
  width: 100%;

  @media screen and (min-width: 769px) {
    flex-direction: row;
  }

  scrollbar-width: none;
  -ms-overflow-style: none;

  &::-webkit-scrollbar {
    display: none; /* Safari and Chrome */
  }
`

const SwipeSelectOption = styled.div`
  background: ${props => (props.active ? '#8CCA1177' : 'white')};
  border-radius: 3px;
  border-style: solid;
  border-width: ${borderWidth};
  border-color: ${borderColor};
  color: ${th('text.dark')};
  display: flex;
  flex: 1 0 10%;
  font-family: ${th('fonts.bold')};
  margin-right: 10px;
  text-align: center;

  &:hover {
    cursor: pointer;
  }

  &:last-of-type {
    margin-right: 0;
  }

  @media screen and (max-width: 600px) {
    flex: 1 0 24%;
    padding: ${props => (props.active ? '4px 1px' : '5px 2px')};
  }
`

const PlusIconWrapper = styled.span`
  display: inline-block;
  margin-right: 5px;
  width: 10px;

  svg {
    margin-top: -2px;
  }
`

const ShortSwipeSelectOption = styled.div`
  background: ${props => {
    if (props.disabled) return th('backgrounds.mid')
    if (props.isRed && props.active) return '#EB452577'
    if (props.active) return '#8CCA1177'

    return 'white'
  }};
  border-radius: 0;
  border-style: solid;
  border-width: ${borderWidth};
  // define after border-width property to override it
  border-right-width: 0;
  border-color: ${borderColor};
  color: ${th('text.dark')};
  display: flex;
  font-family: ${th('fonts.bold')};
  line-height: 1;
  margin: 10px 0;
  min-width: 100px;
  max-width: max-content;
  padding: 0 5px 0 0;

  &:hover {
    background: ${props => {
      if (props.disabled) return th('backgrounds.mid')

      if (props.showHoverColor) {
        if (props.isRed && props.active) return '#EB452577'
        if (props.isRed) return '#EB452544'
        if (props.active) return '#8CCA1177'

        return '#8CCA1144'
      }

      if (props.isRed && props.active) return '#EB452577'
      if (props.active) return '#8CCA1177'

      return 'white'
    }};

    cursor: ${props => (props.disabled ? 'default' : 'pointer')};
  }

  &:hover ${SmallImage} {
    border-color: ${props => (props.active ? 'white' : th('text.dark'))};
  }

  &:hover ${ShortSmallImage} {
    border-color: ${props => (props.active ? 'white' : th('text.dark'))};
  }

  &:first-of-type {
    border-bottom-left-radius: 5px;
    border-top-left-radius: 5px;
  }

  &:last-of-type {
    border-bottom-right-radius: 5px;
    border-right-width: 1px;
    border-top-right-radius: 5px;
  }

  @media screen and (min-width: 769px) {
    padding: 5px 10px;
  }

  @media screen and (max-width: 600px) {
    flex: 1 0 24%;
  }
`

const DisabledInput = styled.input`
  background-color: ${th('backgrounds.light')};
  color: ${th('text.light')};
  font-family: ${th('fonts.light')};
  border-style: solid;
  border-width: ${borderWidth};
  border-color: ${borderColor};
  font-size: 15px;
  height: 45px;
  padding: 15px;
  cursor: not-allowed;
`

export const Strong = styled.span`
  font-family: ${th('fonts.bold')};
`

const ExpandButtonWrapper = styled.div`
  position: ${props => (props.floatExpandButton ? 'absolute' : 'relative')};
  right: 0;
  top: 0;
`

const valueFromId = (opts, id) => {
  const opt = opts.find(o => o.value === id)

  return opt || ''
}

const isActive = ({ field, opt, valueFromProps }) => {
  const value = field.value
  const deepValue = value ? value.value : null
  const selectedValue = deepValue || value || valueFromProps

  return selectedValue === opt.value
}

const ImageForShortSwipeOption = ({ active, fill, iconPadding, Icon, noBorder }) => {
  if (!Icon) return null

  return (
    <ShortSwipeOptionImage active={active}>
      <ShortIconWrapper active={active} fill={fill} noBorder={noBorder} padding={iconPadding}>
        <Icon />
      </ShortIconWrapper>
    </ShortSwipeOptionImage>
  )
}

class SwipeSelect extends Component {
  state = { showOptions: false }

  setOption = ({ active, field, form, onChange, opt }) => {
    if (typeof onChange === 'function') {
      const val = active ? null : opt
      onChange(val)
      return true
    }

    if (form) {
      const val = active ? null : opt ? opt.value : null
      form.setFieldValue(field.name, val)
    }
  }

  showOptions({ isSubmitting }) {
    const val = isSubmitting ? false : true
    this.setState({ showOptions: val })
  }

  render() {
    const { showOptions } = this.state
    const {
      className,
      collapseOptions,
      customSwipeSelect,
      displayArrows,
      field = {},
      floatExpandButton,
      forceFullWidth,
      forceShowOptions,
      isSubmitting,
      label,
      onChange,
      openButtonLabel,
      subLabel,
      success,
      requiredField,
      setState,
      shortOptions,
      ...props
    } = this.props
    const form = props.form || {}
    const errors = form.errors || {}
    const touched = form.touched || {}

    const displayableLabel = requiredField ? `${label} *` : label
    const valueFromProps = field.value || this.props.value
    const val = valueFromId(this.props.options, valueFromProps)
    const hasError = errors[field.name]
    const showOptionsExpandButton = !showOptions && !valueFromProps
    const hasOverThreeOptions = props.options.length > 3

    if (!forceShowOptions && customSwipeSelect && collapseOptions) {
      return (
        <Wrapper forceFullWidth={forceFullWidth}>
          {label ? (
            <LabelWrapper>
              <Label htmlFor={field.name}>{displayableLabel}</Label>
            </LabelWrapper>
          ) : null}

          {subLabel && (
            <LabelWrapper>
              <SubLabel subLabel={subLabel}>
                We'll share a standard donation story to your profile. It's visibility will be:{' '}
                <Strong>{valueFromProps}</Strong>.
              </SubLabel>
            </LabelWrapper>
          )}

          <ExpandButtonWrapper floatExpandButton={floatExpandButton}>
            <LinkButton
              disabled={isSubmitting || props.disabled}
              onClick={() => setState({ collapseOptions: false })}
              type="button"
            >
              <span>{openButtonLabel || 'View options'}</span>
            </LinkButton>
          </ExpandButtonWrapper>
        </Wrapper>
      )
    }

    // Do not show the list of options. Instead show a button to expand list
    if (!forceShowOptions && showOptionsExpandButton) {
      return (
        <Wrapper forceFullWidth={forceFullWidth}>
          {hasError ? <Error>{hasError}</Error> : null}

          <LinkButton
            disabled={isSubmitting || props.disabled}
            onClick={() => this.showOptions({ isSubmitting })}
          >
            <PlusIconWrapper>
              <PlusSign />
            </PlusIconWrapper>

            <span>{openButtonLabel || 'View options'}</span>
          </LinkButton>
        </Wrapper>
      )
    }

    // Do not show the list of options for disabled SwipeSelect
    if (props.disabled && !shortOptions) {
      const value = valueFromId(this.props.options, valueFromProps) || {}

      return (
        <Wrapper className={className}>
          {label && <Label htmlFor={field.name}>{displayableLabel}</Label>}
          {subLabel && <SubLabel subLabel={subLabel}>{subLabel}</SubLabel>}

          <DisabledInput value={value.label || props.placeholder} setOption={() => {}} />
        </Wrapper>
      )
    }

    // Show small tabs instead of full blocks with icons
    if (shortOptions) {
      return (
        <Wrapper forceFullWidth={forceFullWidth}>
          {hasError ? (
            <Error>{hasError}</Error>
          ) : label ? (
            <LabelWrapper>
              <Label htmlFor={field.name}>{displayableLabel}</Label>
            </LabelWrapper>
          ) : null}

          {subLabel && (
            <LabelWrapper>
              <SubLabel subLabel={subLabel}>{subLabel}</SubLabel>
            </LabelWrapper>
          )}

          <RequiredTag hasError={hasError} label={label} requiredField={requiredField} />

          <ConditionalDisplay displayWhen={[displayArrows, hasOverThreeOptions]}>
            <SlideArrowsWrapper shortOptions>
              <SlideArrow>&#10094;</SlideArrow>
              <SlideArrow>&#10095;</SlideArrow>
            </SlideArrowsWrapper>
          </ConditionalDisplay>

          <SwipeSelectContainer
            requiredField={requiredField}
            {...field}
            touched={!!touched[field.name]}
            hasError={!!hasError}
            value={val}
            {...props}
          >
            {props.options.map(({ fill, Icon, iconPadding, noBorder, ...opt }) => {
              const active = isActive({ field, opt, valueFromProps })

              return (
                <ShortSwipeSelectOption
                  active={active}
                  disabled={props.disabled}
                  isRed={opt.isRed}
                  showHoverColor={opt.showHoverColor}
                  key={opt.value}
                  marginLeft={props.mobileWrapped ? '0' : '5px'}
                  marginTop={props.mobileWrapped ? '0' : '10px'}
                  onClick={() => {
                    if (props.disabled) return null
                    this.setOption({ active, field, form, onChange, opt })
                  }}
                >
                  <ShortSwipeOptionDetails active={active}>
                    <ConditionalDisplay displayWhen={[!Icon]}>
                      <ShortSwipeOptionImage>
                        <ShortSmallImage active={active} src={opt.image} />
                      </ShortSwipeOptionImage>
                    </ConditionalDisplay>

                    <ImageForShortSwipeOption
                      action={active}
                      fill={fill}
                      iconPadding={iconPadding}
                      Icon={Icon}
                      noBorder={noBorder}
                    />
                    <ShortSwipeOptionsWrapper>
                      <ShortSwipeOptionLabel active={active} hasSubLabel={opt.subLabel}>
                        {opt.label}
                      </ShortSwipeOptionLabel>

                      <ConditionalDisplay displayWhen={[opt.subLabel]}>
                        <ShortSwipeOptionSubLabel active={active}>
                          {opt.subLabel}
                        </ShortSwipeOptionSubLabel>
                      </ConditionalDisplay>
                    </ShortSwipeOptionsWrapper>
                  </ShortSwipeOptionDetails>
                </ShortSwipeSelectOption>
              )
            })}
          </SwipeSelectContainer>

          {success && touched[field.name] && !hasError && <Success>{success}</Success>}
        </Wrapper>
      )
    }

    return (
      <Wrapper forceFullWidth={forceFullWidth}>
        {hasError ? (
          <Error>{hasError}</Error>
        ) : label ? (
          <LabelWrapper>
            <Label htmlFor={field.name}>{displayableLabel}</Label>
          </LabelWrapper>
        ) : null}

        {subLabel && (
          <LabelWrapper>
            <SubLabel subLabel={subLabel}>{subLabel}</SubLabel>
          </LabelWrapper>
        )}

        <RequiredTag hasError={hasError} label={label} requiredField={requiredField} />

        <ConditionalDisplay displayWhen={[displayArrows, hasOverThreeOptions]}>
          <SlideArrowsWrapper>
            <SlideArrow>&#10094;</SlideArrow>
            <SlideArrow>&#10095;</SlideArrow>
          </SlideArrowsWrapper>
        </ConditionalDisplay>

        <SwipeSelectContainer
          hasError={!!hasError}
          requiredField={requiredField}
          touched={!!touched[field.name]}
          value={val}
          {...field}
          {...props}
        >
          {props.options.map(({ Icon, ...opt }) => {
            const active = isActive({ field, opt, valueFromProps })

            return (
              <SwipeSelectOption
                active={active}
                key={opt.value}
                onClick={() => this.setOption({ active, field, form, onChange, opt })}
              >
                <SwipeOptionDetails active={active}>
                  <ConditionalDisplay displayWhen={[!Icon]}>
                    <SwipeOptionImage active={active}>
                      <Image src={opt.image} />
                    </SwipeOptionImage>
                  </ConditionalDisplay>

                  <ConditionalDisplay displayWhen={[Icon]}>
                    <SwipeOptionImage active={active}>
                      <IconWrapper active={active}>
                        <Icon />
                      </IconWrapper>
                    </SwipeOptionImage>
                  </ConditionalDisplay>

                  <SwipeOptionLabel>{opt.label}</SwipeOptionLabel>
                </SwipeOptionDetails>
              </SwipeSelectOption>
            )
          })}
        </SwipeSelectContainer>

        {success && touched[field.name] && !hasError && <Success>{success}</Success>}
      </Wrapper>
    )
  }
}

export default SwipeSelect
