import styled from 'styled-components'
import { CheckBox, CheckBoxOutlineBlank } from '@styled-icons/material'
import th from 'utils/themeHelper'

export const Outer = styled.label`
  align-items: flex-start;
  cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  pointer-events: ${props => (props.disabled ? 'none' : 'auto')};
`

export const Checked = styled(CheckBox)`
  color: ${props => (props.disabled ? th('backgrounds.mid') : th('secondary.base'))};
  width: ${props => (props.size ? `${props.size}px` : '26px')};
  height: ${props => (props.size ? `${props.size}px` : '26px')};
`

export const Unchecked = styled(CheckBoxOutlineBlank)`
  color: ${props => (props.disabled ? th('backgrounds.mid') : th('secondary.base'))};
  width: ${props => (props.size ? `${props.size}px` : '26px')};
  height: ${props => (props.size ? `${props.size}px` : '26px')};
`

export const InputWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
`

export const SubLabel = styled.span`
  color: ${props => (props.disabled ? th('text.light') : th('text.dark'))};
  display: block;
  line-height: 1;
  font-size: 13px;
  margin-left: 8px;
  margin-top: 2px;
`

export const HiddenInput = styled.input`
  display: none;
`
