import { createActions, handleActions } from 'redux-actions'

export const GENERATE_PAYMENT_IDENTIFIER = 'platformSubscriptions/GENERATE_PAYMENT_IDENTIFIER'
export const GENERATE_PAYMENT_IDENTIFIER_SUCCESS =
  'platformSubscriptions/GENERATE_PAYMENT_IDENTIFIER_SUCCESS'
export const GENERATE_PAYMENT_IDENTIFIER_FAILURE =
  'platformSubscriptions/GENERATE_PAYMENT_IDENTIFIER_FAILURE'

export const RECORD_PAYMENT = 'platformSubscriptions/RECORD_PAYMENT'
export const RECORD_PAYMENT_FAILURE = 'platformSubscriptions/RECORD_PAYMENT_FAILURE'
export const RECORD_PAYMENT_SUCCESS = 'platformSubscriptions/RECORD_PAYMENT_SUCCESS'

export const CANCEL_PLATFORM_SUBSCRIPTION = 'platformSubscriptions/CANCEL_PLATFORM_SUBSCRIPTION'
export const CANCEL_PLATFORM_SUBSCRIPTION_FAILURE =
  'platformSubscriptions/CANCEL_PLATFORM_SUBSCRIPTION_FAILURE'
export const CANCEL_PLATFORM_SUBSCRIPTION_SUCCESS =
  'platformSubscriptions/CANCEL_PLATFORM_SUBSCRIPTION_SUCCESS'

export const FETCH_PLATFORM_SUBSCRIPTION = 'platformSubscriptions/FETCH_PLATFORM_SUBSCRIPTION'
export const FETCH_PLATFORM_SUBSCRIPTION_FAILURE =
  'platformSubscriptions/FETCH_PLATFORM_SUBSCRIPTION_FAILURE'
export const FETCH_PLATFORM_SUBSCRIPTION_SUCCESS =
  'platformSubscriptions/FETCH_PLATFORM_SUBSCRIPTION_SUCCESS'

export const LOGOUT_SUCCESS = 'authentication/LOGOUT_SUCCESS'

export const {
  platformSubscriptions: {
    generatePaymentIdentifier,
    generatePaymentIdentifierSuccess,
    generatePaymentIdentifierFailure,
    recordPayment,
    recordPaymentSuccess,
    recordPaymentFailure,
    cancelPlatformSubscription,
    cancelPlatformSubscriptionSuccess,
    cancelPlatformSubscriptionFailure,
    fetchPlatformSubscription,
    fetchPlatformSubscriptionSuccess,
    fetchPlatformSubscriptionFailure
  }
} = createActions({
  [GENERATE_PAYMENT_IDENTIFIER]: undefined,
  [GENERATE_PAYMENT_IDENTIFIER_SUCCESS]: undefined,
  [GENERATE_PAYMENT_IDENTIFIER_FAILURE]: undefined,
  [RECORD_PAYMENT]: undefined,
  [RECORD_PAYMENT_FAILURE]: undefined,
  [RECORD_PAYMENT_SUCCESS]: undefined,
  [CANCEL_PLATFORM_SUBSCRIPTION]: undefined,
  [CANCEL_PLATFORM_SUBSCRIPTION_FAILURE]: undefined,
  [CANCEL_PLATFORM_SUBSCRIPTION_SUCCESS]: undefined,
  [FETCH_PLATFORM_SUBSCRIPTION]: undefined,
  [FETCH_PLATFORM_SUBSCRIPTION_FAILURE]: undefined,
  [FETCH_PLATFORM_SUBSCRIPTION_SUCCESS]: undefined
})

const initialState = {
  transactionId: null,
  isLoading: false,
  isError: false,
  isSuccess: null,
  data: {}
}

export default handleActions(
  {
    [GENERATE_PAYMENT_IDENTIFIER]: state => ({
      ...state,
      isLoading: true
    }),
    [GENERATE_PAYMENT_IDENTIFIER_SUCCESS]: (state, { payload }) => ({
      ...state,
      transactionId: payload.data,
      isLoading: false,
      isError: false
    }),
    [GENERATE_PAYMENT_IDENTIFIER_FAILURE]: () => ({
      transactionId: null,
      isLoading: false,
      isError: true
    }),
    [RECORD_PAYMENT]: state => ({
      ...state,
      isError: null,
      isSuccess: null,
      isLoading: true
    }),
    [RECORD_PAYMENT_SUCCESS]: state => ({
      ...state,
      isError: false,
      isSuccess: true,
      isLoading: false
    }),
    [RECORD_PAYMENT_FAILURE]: state => ({
      ...state,
      isSuccess: false,
      isLoading: false,
      isError: true
    }),
    [CANCEL_PLATFORM_SUBSCRIPTION]: state => ({
      ...state,
      isError: null,
      isSuccess: null,
      isSubmitting: true
    }),
    [CANCEL_PLATFORM_SUBSCRIPTION_SUCCESS]: state => ({
      ...state,
      isError: false,
      isSuccess: true,
      isSubmitting: false,
      isLoading: false
    }),
    [CANCEL_PLATFORM_SUBSCRIPTION_FAILURE]: state => ({
      ...state,
      isSuccess: false,
      isSubmitting: false,
      isLoading: false,
      isError: true
    }),
    [FETCH_PLATFORM_SUBSCRIPTION]: state => ({
      ...state,
      isError: null,
      isSuccess: null,
      isLoading: true
    }),
    [FETCH_PLATFORM_SUBSCRIPTION_SUCCESS]: (state, { payload }) => ({
      ...state,
      isError: false,
      isSuccess: true,
      data: payload,
      isLoading: false
    }),
    [FETCH_PLATFORM_SUBSCRIPTION_FAILURE]: state => ({
      ...state,
      isSuccess: false,
      data: {},
      isLoading: false,
      isError: true
    }),
    [LOGOUT_SUCCESS]: () => initialState
  },
  initialState
)
