import React from 'react'
import styled from 'styled-components'

const FooterColumnTitle = styled.h5`
  color: white;
  text-transform: uppercase;
  margin-top: 0;
`

const FooterColumnContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 40px;

  @media screen and (min-width: 600px) {
    width: 50%;
  }

  @media screen and (min-width: 730px) {
    width: 25%;
  }
`

const FooterColumn = ({ title, children }) => (
  <FooterColumnContainer>
    <FooterColumnTitle>{title}</FooterColumnTitle>
    {children}
  </FooterColumnContainer>
)

export default FooterColumn
