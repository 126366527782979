import React from 'react'
import styled from 'styled-components'

// assets
import externalLinkIcon from 'assets/images/payment-method-icons/external_link_icon.png'
import ozowIcon from 'assets/images/payment-method-icons/ozow_logo_vector_small.png'
import payfastIcon from 'assets/images/payment-method-icons/payfast_logo_vector_small.png'
import { Check } from '@styled-icons/material/Check'

// config and utils
import th from 'utils/themeHelper'

// Load components synchronously
import ConditionalDisplay from 'components/ConditionalDisplay/ConditionalDisplay'

const PaymentOptionSelectorWrapper = styled.div`
  @media screen and (max-width: 769px) {
    margin-top: 5px;
  }
`

const PaymentOptionSelectorWrapperInner = styled.div`
  align-items: center;
  display: flex;
`

const SelectedPlatformIconWrapper = styled.div`
  background: #fff;
  border: 1px solid ${th('borders.dark')};
  position: absolute;
  border-radius: 50%;
  display: ${props => (props.active ? 'block' : 'none')};
  padding: 10px;
  right: -20px;

  svg {
    height: 20px;
    width: 20px;
  }
`

const PaymentOption = styled.div`
  background: ${props =>
    props.active ? '#2A90443C' : props.disabled ? th('backgrounds.light') : 'white'};
  border-color: ${props => (props.active ? th('primary.base') : th('borders.default'))};
  border-style: solid;
  border-radius: 4px;
  border-width: 2px;
  display: flex;
  margin-bottom: 15px;
  max-width: 500px;
  padding: 10px;
  position: relative;

  &:hover {
    border-color: ${props =>
      props.active
        ? th('primary.base')
        : props.disabled
        ? th('borders.default')
        : th('primary.base')};
    cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};
  }
`

const PaymentOptionLabel = styled.div`
  font-family: ${th('fonts.bold')};
  font-size: 18px;
  margin-bottom: 8px;
`

const PaymentOptionText = styled.div`
  color: ${th('text.mid')};
  font-size: 14px;
  line-height: 1.35em;
  max-width: 700px;
`

const PaymentOptionLogo = styled.img`
  margin-right: 15px;
  width: 50px;
`

const deriveIcon = ({ donationOption }) => {
  if (donationOption.is_ozow) return ozowIcon
  if (donationOption.is_payfast) return payfastIcon

  return externalLinkIcon
}

const PaymentOptionSelector = ({
  chosenValue,
  disablePaymentMethodOptions,
  donationOpts,
  scrollOnSelect,
  setFieldValue
}) => {
  return (
    <PaymentOptionSelectorWrapper>
      {donationOpts.map((donationOption, idx) => {
        const disablePaymentMethod = disablePaymentMethodOptions && !donationOption.is_payfast
        const isActive = chosenValue === donationOption.id
        const paymentIcon = deriveIcon({ donationOption })
        const isExternalLink = donationOption.is_external
        const donationOptionLabel = isExternalLink
          ? 'External Payment Platform'
          : donationOption.label

        return (
          <PaymentOption
            active={isActive}
            disabled={disablePaymentMethod}
            key={`donation_option_${idx}`}
            onClick={() => {
              // Do not update when this option is disabled
              if (disablePaymentMethod) return true

              setFieldValue('chosen_donation_link', donationOption.id)

              if (scrollOnSelect) {
                scrollOnSelect()
              }
            }}
          >
            <PaymentOptionSelectorWrapperInner>
              <SelectedPlatformIconWrapper active={isActive}>
                <Check />
              </SelectedPlatformIconWrapper>

              <div>
                <PaymentOptionLogo src={paymentIcon} />
              </div>

              <div>
                <PaymentOptionLabel>{donationOptionLabel}</PaymentOptionLabel>

                <ConditionalDisplay displayWhen={[isExternalLink]}>
                  <div style={{ marginBottom: '10px' }}>
                    <PaymentOptionText>{donationOption.label}</PaymentOptionText>
                  </div>
                </ConditionalDisplay>

                <ConditionalDisplay displayWhen={[donationOption.text]}>
                  <PaymentOptionText>{donationOption.text}</PaymentOptionText>
                </ConditionalDisplay>
              </div>
            </PaymentOptionSelectorWrapperInner>
          </PaymentOption>
        )
      })}
    </PaymentOptionSelectorWrapper>
  )
}

export default PaymentOptionSelector
