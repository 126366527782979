const SINGULAR = 0
const PLURAL = 1

const specialCasesGlossary = {
  STORY: ['story', 'stories'],
  STORYS: ['story', 'stories']
}

const glossary = {
  // ensure non-spacing for URLs
  ROUTE_CAMPS: ['campaign', 'campaigns'],
  ROUTE_OPPS: ['mission', 'missions'],
  ROUTE_OPPS_DEPRECATED: ['opportunity', 'opportunities'],
  ROUTE_CAUSES: ['mission', 'missions'],
  ROUTE_ORGS: ['organisation', 'organisations'],
  ROUTE_TEAMS: ['team', 'teams'],
  // rest
  GOAL_TYPES: ['goal type', 'goal types'],
  OPPS: ['mission', 'missions'],
  CAMPS: ['campaign', 'campaigns'],
  CATS: ['category', 'categories'],
  ORGS: ['organisation', 'organisations'],
  TEAMS: ['team', 'teams'],
  USERS: ['user', 'users'],
  APPS: ['application', 'applications'],
  SUPPORTERS: ['supporter', 'supporters'],
  FACEBOOK_LINK: [
    'https://www.facebook.com/browniepointsSA',
    'https://www.facebook.com/browniepointsSA'
  ],
  TWITTER_LINK: [
    'https://www.twitter.com/browniepointsSA',
    'https://www.twitter.com/browniepointsSA'
  ],
  CONTACT_EMAIL: ['hi@browniepoints.africa', 'hi@browniepoints.africa'],
  BILLING_CONTACT_EMAIL: [
    'hi@browniepoints.africa?subject=Billing Query',
    'hi@browniepoints.africa?subject=Billing Query'
  ],
  PHYSICAL_ADDRESS: [
    'Ideas Cartel, The Old Foundry, Cnr Ebenezer &, Sand Hill Road, Green Point, Cape Town, 8001, South Africa',
    'Ideas Cartel, The Old Foundry, Cnr Ebenezer &, Sand Hill Road, Green Point, Cape Town, 8001, South Africa'
  ],
  ACCOUNT_CATEGORIES_LABEL: ['What are you passionate about?', 'What are you passionate about?'],
  ACCOUNT_CATEGORIES_SUBLABEL: [
    'Adding categories that interest you will help us show you relevant content',
    'Adding categories that interest you will help us show you relevant content'
  ],
  ORG_LOGO_LABEL: ['Logo', 'Logo'],
  GOAL_TYPE_LABEL: ['What support do you need?', 'What support do you need?'],
  GOAL_TYPE_SUBLABEL: [`Pick a goal type for your cause`, `Pick a goal type for your cause`],
  OUTCOME_TARGET_LABEL: ['What is your goal?', 'What is your goal?']
}

const singularGlossary = key => {
  return glossary[`${key}S`]
}

const localize = (string, count = null) => {
  const tuple = glossary[string] || singularGlossary(string) || ''
  const specialCase = specialCasesGlossary[string] || []
  const pluralized = specialCase[PLURAL] || tuple[PLURAL] || ''

  // e.g 0 ducks
  if (count === 0) return pluralized

  // e.g 1 duck
  if (count === 1) {
    const singleTupleLookup = singularGlossary(string) || specialCase
    return singleTupleLookup[SINGULAR]
  }

  if (!count) {
    // glossary["DUCKS"] => ["duck", "ducks"]
    // glossary["DUCK"] => null
    const pluralTuple = glossary[string]

    // glossary["DUCK"] => singularGlossary() => glossary["DUCKS"] => ["duck", "ducks"]
    // glossary["DUCKS"] => singularGlossary() => glossary["DUCKSS"] => null
    const singlularTuple = singularGlossary(string)

    return pluralTuple
      ? pluralTuple[PLURAL]
      : singlularTuple
      ? singlularTuple[SINGULAR]
      : pluralized || ''
  }

  // e.g x amount of ducks
  return pluralized
}

export default localize
